/* eslint-disable import/no-named-as-default */
import API_TYPES from "./api.types";

const initialState = {
  googleKey: "",
  cdnToken: "",
  isLogin: false,
};

function APIReducer(state = initialState, action) {
  switch (action.type) {
    case API_TYPES.SET_API:
      return {
        ...state,
        ...action.payload,
      };
    case API_TYPES.SET_LOGIN_STATE:
      return {
        ...state,
        isLogin: action.payload,
      };
    case API_TYPES.SET_CDN_TOKEN:
      return {
        ...state,
        cdnToken: action.payload,
      };
    case API_TYPES.SET_PERMISSION:
      return {
        ...state,
        subscriptionDetails: action.payload,
      };
    default:
      return state;
  }
}

export default APIReducer;
