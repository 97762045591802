import React from "react";

import { plans } from "../../../utils/constants/constants";

const CodeDataWithAmountNotFree = ({ codeData, taxAmount, discountAmount }) => {
  const prefixText = `First 
    ${codeData?.duration_in_months > 1 ? codeData?.duration_in_months : ""}
    ${codeData?.duration_in_months === 1 ? "Month" : "Months"}`;
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <span>Plan Price:</span>
        <div className="pricetotal">
          <div className="free-trial-price-lineThrough">
            ${plans[localStorage.getItem("planName")]?.planPrice}{" "}
            {/* <sub style={{ fontSize: "8px" }}>/Mo</sub> */}
          </div>
        </div>
      </div>
      <div>
        {/*  <div>
      Original Price $
      {
        plans[localStorage.getItem("planName")]
          ?.planPrice
      }
      /Mo
    </div> */}
        <div className="d-flex align-items-center justify-content-between">
          {codeData?.discountType === "amount" && (
            <>
              <span> Discount Amount: </span>
              <div className="estimated-price">
                -${(codeData?.discount).toFixed(2)} {/* <span> /Mo </span> */}
              </div>
            </>
          )}
        </div>
        {taxAmount?.taxPercentage && (
          <div className="d-flex align-items-center justify-content-between">
            <span>
              {" "}
              First Month Estimated Tax ({parseInt(
                taxAmount?.taxPercentage,
              )}%):{" "}
            </span>
            <div className="estimated-price">
              ${(taxAmount?.taxAmount).toFixed(2)} {/* <span> /Mo </span> */}
            </div>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between pt-2">
          <span> Due Today ({prefixText}):</span>
          <div className="estimated-price">
            {(
              parseFloat(plans[localStorage.getItem("planName")]?.planPrice) -
              (discountAmount || 0) +
              (taxAmount?.taxAmount || 0)
            )?.toFixed(2) > 0 ? (
              <h2>
                $
                {(
                  parseFloat(
                    plans[localStorage.getItem("planName")]?.planPrice,
                  ) -
                  discountAmount +
                  (taxAmount?.taxAmount || 0)
                )?.toFixed(2)}
              </h2>
            ) : (
              <h2>$0.00</h2>
            )}{" "}
            {/* <span> /Mo </span> */}
          </div>
        </div>
        {(
          parseFloat(plans[localStorage.getItem("planName")]?.planPrice) -
          discountAmount +
          taxAmount?.taxAmount
        )?.toFixed(2) < 0 && (
          <div className="d-flex align-items-center justify-content-between pt-2">
            <span> Credit Towards Future Bill(s):</span>
            <div className="estimated-price">
              +$
              {Math.abs(
                parseFloat(plans[localStorage.getItem("planName")]?.planPrice) -
                  discountAmount +
                  (taxAmount?.taxAmount || 0.0),
              )?.toFixed(2)}{" "}
              {/* <span> /Mo </span> */}
            </div>
          </div>
        )}

        {(
          parseFloat(plans[localStorage.getItem("planName")]?.planPrice) -
          discountAmount +
          taxAmount?.taxAmount
        )?.toFixed(2) > 0 && (
          <div className="d-flex align-items-center justify-content-between pt-2">
            <span>
              {(
                parseFloat(plans[localStorage.getItem("planName")]?.planPrice) -
                discountAmount +
                taxAmount?.taxAmount
              )?.toFixed(2) < 0
                ? "Subscription Price After Credit:"
                : "Subscription Price (After 1st Mo):"}{" "}
            </span>

            <>
              ${plans[localStorage.getItem("planName")]?.planPrice}
              <sub style={{ fontSize: "8px" }}>/Mo + Tax</sub>
            </>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-between pt-2">
        <div className="payment-right-para"> {codeData?.description}</div>
      </div>
    </>
  );
};

export default CodeDataWithAmountNotFree;
