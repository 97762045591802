import "./profileInfo.css";

import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useDispatch } from "react-redux";

// import GoogleSearchInput from "../../../googleSearchInput";
import ModalDailog from "../../../../common/components/ModalDialogue";
import Facebook from "../../../../images/affiliateImages/facebook-profile.png";
import Instagram from "../../../../images/affiliateImages/instagram-profile.png";
import TikTok from "../../../../images/affiliateImages/tiktok-profile.png";
import Web from "../../../../images/affiliateImages/web-profile.svg";
import Youtube from "../../../../images/affiliateImages/youtube-profile.png";
import { ReactComponent as LockOutlined } from "../../../../images/lock.svg";
import { superAdminModalAction } from "../../../../redux/affiliate/affiliate.action";
import { contactFormat } from "../../../../utils/javascript";
import SmallLoader from "../../../loader/smallLoader";
import { profileInfoContainer } from "./container";
import UpdateEmail from "./modals/UpdateEmail";
import UpdatePassword from "./modals/UpdatePassword";

const { Title, Text } = Typography;

const ProfileInfo = ({ isSuperAdmin = false }) => {
  const dispatch = useDispatch();
  const socialMedia = [
    {
      icon: <img alt="instagram-logo" src={Instagram} />,
      name: "instagram",
      placeholder: "Instagram Username",
    },
    {
      icon: <img alt="instagram-logo" src={Facebook} />,
      name: "facebook",
      placeholder: "Facebook URL",
    },
    {
      icon: <img alt="instagram-logo" src={Youtube} />,
      name: "youtube",
      placeholder: "YouTube URL",
    },
    {
      icon: <img alt="instagram-logo" src={TikTok} />,
      name: "tiktok",
      placeholder: "Tik Tok Username",
    },
    {
      icon: <img alt="instagram-logo" src={Web} />,
      name: "web",
      placeholder: "Website URL",
    },
  ];

  const {
    form,
    loading,
    updateEmail,
    updatePassword,
    googleKey,
    profile,
    formData,
    setUpdateEmail,
    setUpdatePassword,
    handleFieldsChange,
    handleCancel,
    handleMediaChange,
    handleAddrChange,
    handlePhoneChange,
    getSocialMediaLinks,
    error,
  } = profileInfoContainer(isSuperAdmin);

  const rejected = profile?.status === "Rejected";

  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <SmallLoader />
    </div>
  );

  const superAdminModal = (modalKey) => {
    dispatch(superAdminModalAction({ [modalKey]: true }));
  };

  return (
    <>
      <Title level={4} style={{ marginBottom: "0px", color: "#484D59" }}>
        Profile Info
      </Title>
      <Text type="secondary">Update your personal details here.</Text>
      <Divider />
      {loading ? (
        <Loader />
      ) : (
        <Form
          form={form}
          labelCol={{ span: 6 }}
          className="labeAlignLeft profile-input-divs"
          onFieldsChange={handleFieldsChange}
          initialValues={{
            email: "",
            firstName: "",
            lastName: "",
            address: "",
            contactNo: contactFormat(profile?.contactNo) || "",
            socialMediaLink: "",
          }}
        >
          <Form.Item label="Name" style={{ marginBottom: "0px" }}>
            <Row gutter={[6, 6]}>
              <Col className="gutter-row">
                <Form.Item name="firstName" className="forName">
                  <Input disabled={isSuperAdmin} />
                </Form.Item>
              </Col>
              <Col className="gutter-row">
                <Form.Item name="lastName" className="forName">
                  <Input disabled={isSuperAdmin} />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Divider />
          <Form.Item label="Email Address">
            <Row gutter={[6, 6]} className="email-responsive">
              <Col className="gutter-row" lg={8} xs={24}>
                <Form.Item name="email" className="allbox-forEmail">
                  <Input
                    prefix={<MailOutlined className="ant-input-disabled" />}
                    readOnly
                    disabled
                    {...{ ...(isSuperAdmin && { style: { width: "100%" } }) }}
                  />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row responsiveFor-button mt-2 mt-lg-0"
                lg={4}
                xs={24}
              >
                <Button
                  type="primary"
                  onClick={() =>
                    isSuperAdmin
                      ? superAdminModal("changeEmail")
                      : setUpdateEmail(true)
                  }
                  disabled={isSuperAdmin && rejected}
                >
                  Update Email
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Divider />
          <Form.Item label="Phone">
            <Row gutter={16}>
              <Col className="gutter-row" lg={13} xs={24}>
                <Form.Item name="contactNo" className="allbox-forPhone">
                  <Input
                    maxLength={14}
                    prefix={
                      <PhoneOutlined
                        className="text-secondary"
                        style={{ transform: "rotate(90deg)" }}
                      />
                    }
                    onChange={handlePhoneChange}
                    disabled={isSuperAdmin}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Divider />
          <Form.Item label="Address">
            <Row gutter={16}>
              <Col className="gutter-row" lg={13} xs={24}>
                <Form.Item
                  name="address"
                  className="google-address"
                  {...{
                    ...(isSuperAdmin && {
                      style: {
                        pointerEvents: "none",
                        backgroundColor: "#f5f5f5",
                        opacity: 0.6,
                        borderColor: "#d9d9d9",
                        borderRadius: 8,
                      },
                    }),
                  }}
                >
                  {googleKey ? (
                    <GooglePlacesAutocomplete
                      apiKey={googleKey}
                      selectProps={{
                        isClearable: true,
                        defaultInputValue:
                          formData?.address?.label ||
                          formData?.address ||
                          profile?.address,
                        value: formData?.address || profile?.address,
                        onChange: handleAddrChange,
                      }}
                    />
                  ) : (
                    <Select
                      {...{
                        ...(isSuperAdmin && {
                          style: {
                            borderRadius: 8,
                            height: 38,
                          },
                          className: "google-api-address",
                        }),
                      }}
                      disabled
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Divider />
          <Form.Item label="Password">
            <Row gutter={[6, 6]} className="password-responsive">
              <Col className="gutter-row" lg={8} xs={24}>
                <Form.Item name="password" className="allbox-forPassword">
                  <Input
                    type="password"
                    autoComplete="new-password"
                    prefix={
                      <LockOutlined
                        className="ant-input-disabled"
                        style={{ width: 14, height: 14 }}
                      />
                    }
                    disabled
                    {...{ ...(isSuperAdmin && { style: { width: "100%" } }) }}
                  />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row responsiveFor-button mt-2 mt-lg-0"
                lg={4}
                xs={24}
              >
                <Button
                  type="primary"
                  onClick={() =>
                    isSuperAdmin
                      ? superAdminModal("changePassword")
                      : setUpdatePassword(true)
                  }
                  disabled={isSuperAdmin && rejected}
                >
                  Update Password
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Divider />
          <Title level={4} style={{ marginBottom: "0px", color: "#484D59" }}>
            Social Media Links
          </Title>
          <Divider />
          <Form.Item label="Social Media" className="social-content">
            <Row gutter={16}>
              <Col className="gutter-row" lg={14} xs={24}>
                {socialMedia.map(({ icon, name, placeholder }) => (
                  <Row
                    gutter={[20, 20]}
                    style={{ paddingBottom: "30px", alignItems: "center" }}
                  >
                    <Col className="gutter-row" lg={2} xs={4}>
                      {icon}
                    </Col>
                    <Col className="gutter-row" lg={22} xs={20}>
                      <Form.Item className="inner-social-content">
                        <Input
                          placeholder={placeholder}
                          onChange={(e) => handleMediaChange(name, e)}
                          value={getSocialMediaLinks(name)}
                          disabled={isSuperAdmin}
                        />
                        <Text className="text-danger social-error">
                          {error?.[name]}
                        </Text>
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </Form.Item>
        </Form>
      )}
      <ModalDailog
        isModalOpen={updateEmail}
        handleCancel={handleCancel}
        closable={false}
        title="Update Email"
        className="Email-Popup"
      >
        <UpdateEmail
          handleCancel={handleCancel}
          email={form.getFieldValue("email")}
        />
      </ModalDailog>
      <ModalDailog
        isModalOpen={updatePassword}
        handleCancel={handleCancel}
        closable={false}
        title="Update Password"
        className="Email-Popup"
        width={380}
      >
        <UpdatePassword
          handleCancel={handleCancel}
          email={form.getFieldValue("email")}
        />
      </ModalDailog>
    </>
  );
};

export default ProfileInfo;
