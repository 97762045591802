export const InvoiceArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g filter="url(#filter0_d_16800_43278)">
      <rect
        x="2"
        y="20"
        width="20"
        height="20"
        rx="10"
        transform="rotate(-90 2 20)"
        fill="white"
      />
      <path
        d="M14.5 5L9.5 10L14.5 15"
        stroke="#484D59"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_16800_43278"
        x="0"
        y="0"
        width="24"
        height="24"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_16800_43278"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_16800_43278"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const InvoiceRightarrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g filter="url(#filter0_d_16800_43279)">
      <rect
        x="22"
        width="20"
        height="20"
        rx="10"
        transform="rotate(90 22 0)"
        fill="white"
      />
      <path
        d="M9.5 15L14.5 10L9.5 5"
        stroke="#484D59"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_16800_43279"
        x="0"
        y="0"
        width="24"
        height="24"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_16800_43279"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_16800_43279"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const filterInvoice = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
      stroke="currentColor"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const dotedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.99935 10.8333C10.4596 10.8333 10.8327 10.4602 10.8327 9.99992C10.8327 9.53968 10.4596 9.16659 9.99935 9.16659C9.53911 9.16659 9.16602 9.53968 9.16602 9.99992C9.16602 10.4602 9.53911 10.8333 9.99935 10.8333Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99935 4.99992C10.4596 4.99992 10.8327 4.62682 10.8327 4.16659C10.8327 3.70635 10.4596 3.33325 9.99935 3.33325C9.53911 3.33325 9.16602 3.70635 9.16602 4.16659C9.16602 4.62682 9.53911 4.99992 9.99935 4.99992Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99935 16.6666C10.4596 16.6666 10.8327 16.2935 10.8327 15.8333C10.8327 15.373 10.4596 14.9999 9.99935 14.9999C9.53911 14.9999 9.16602 15.373 9.16602 15.8333C9.16602 16.2935 9.53911 16.6666 9.99935 16.6666Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const editRename = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M2.87604 18.1157C2.92198 17.7022 2.94496 17.4955 3.00751 17.3022C3.06301 17.1308 3.14143 16.9676 3.24064 16.8172C3.35246 16.6476 3.49955 16.5005 3.79373 16.2063L17 3.00006C18.1046 1.89549 19.8955 1.89549 21 3.00006C22.1046 4.10463 22.1046 5.89549 21 7.00006L7.79373 20.2063C7.49955 20.5005 7.35245 20.6476 7.18289 20.7594C7.03245 20.8586 6.86929 20.937 6.69785 20.9925C6.5046 21.0551 6.29786 21.0781 5.88437 21.124L2.5 21.5001L2.87604 18.1157Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const invoiceClock = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 11 10"
    fill="none"
  >
    <path
      d="M9.72614 5.44641C9.58723 6.75136 8.84784 7.97422 7.62434 8.68061C5.5916 9.85421 2.99234 9.15774 1.81874 7.125L1.69374 6.90849M1.27259 4.55356C1.4115 3.24862 2.15089 2.02575 3.37438 1.31937C5.40713 0.145764 8.00639 0.842234 9.17999 2.87498L9.30499 3.09148M1.24609 8.03297L1.61212 6.66694L2.97814 7.03297M8.02062 2.96699L9.38665 3.33301L9.75267 1.96699M5.49938 2.74998V4.99998L6.74938 5.74998"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const invoiceNotDueArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
  >
    <path
      d="M10 6H3M3 6L6.5 9.5M3 6L6.5 2.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const paidIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
  >
    <path
      d="M10.5 3L5 8.5L2.5 6"
      stroke="#12B76A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DeleteIconViewNew = (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#FEF3F2"
      strokeWidth="8"
    />
    <path
      d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
      stroke="#D92D20"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const checkNewIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_19015_16867)">
      <path
        d="M18.3327 9.23318V9.99985C18.3317 11.7969 17.7498 13.5454 16.6738 14.9847C15.5978 16.424 14.0854 17.4769 12.3621 17.9864C10.6389 18.4959 8.79707 18.4348 7.11141 17.812C5.42575 17.1892 3.98656 16.0383 3.00848 14.5307C2.0304 13.0232 1.56584 11.2399 1.68408 9.44677C1.80232 7.65365 2.49702 5.94678 3.66458 4.58074C4.83214 3.2147 6.41 2.26266 8.16284 1.86663C9.91568 1.4706 11.7496 1.65179 13.391 2.38318M18.3327 3.33318L9.99935 11.6748L7.49935 9.17485"
        stroke="#039855"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_19015_16867">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const BackBtn = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M2 6.49992H11C12.6569 6.49992 14 7.84306 14 9.49992C14 11.1568 12.6569 12.4999 11 12.4999H8M2 6.49992L4.66667 3.83325M2 6.49992L4.66667 9.16659"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const invoiceUser = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.86835 13.0344C4.61068 12.292 5.6175 11.875 6.66732 11.875H13.334C14.3838 11.875 15.3906 12.292 16.1329 13.0344C16.8753 13.7767 17.2923 14.7835 17.2923 15.8333V17.5C17.2923 17.8452 17.0125 18.125 16.6673 18.125C16.3221 18.125 16.0423 17.8452 16.0423 17.5V15.8333C16.0423 15.115 15.757 14.4262 15.2491 13.9183C14.7412 13.4103 14.0523 13.125 13.334 13.125H6.66732C5.94902 13.125 5.26015 13.4103 4.75224 13.9183C4.24433 14.4262 3.95898 15.115 3.95898 15.8333V17.5C3.95898 17.8452 3.67916 18.125 3.33398 18.125C2.98881 18.125 2.70898 17.8452 2.70898 17.5V15.8333C2.70898 14.7835 3.12602 13.7767 3.86835 13.0344Z"
      fill="#484D59"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99935 3.125C8.50358 3.125 7.29102 4.33756 7.29102 5.83333C7.29102 7.3291 8.50358 8.54167 9.99935 8.54167C11.4951 8.54167 12.7077 7.3291 12.7077 5.83333C12.7077 4.33756 11.4951 3.125 9.99935 3.125ZM6.04102 5.83333C6.04102 3.64721 7.81322 1.875 9.99935 1.875C12.1855 1.875 13.9577 3.64721 13.9577 5.83333C13.9577 8.01946 12.1855 9.79167 9.99935 9.79167C7.81322 9.79167 6.04102 8.01946 6.04102 5.83333Z"
      fill="#484D59"
    />
  </svg>
);

export const LocationIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10 11.25C9.38193 11.25 8.77775 11.0667 8.26384 10.7233C7.74994 10.38 7.3494 9.89191 7.11288 9.32089C6.87635 8.74987 6.81447 8.12154 6.93505 7.51534C7.05563 6.90915 7.35325 6.35233 7.79029 5.91529C8.22733 5.47825 8.78415 5.18063 9.39034 5.06005C9.99654 4.93947 10.6249 5.00135 11.1959 5.23788C11.7669 5.4744 12.255 5.87494 12.5983 6.38884C12.9417 6.90275 13.125 7.50693 13.125 8.125C13.124 8.9535 12.7945 9.74778 12.2086 10.3336C11.6228 10.9195 10.8285 11.249 10 11.25ZM10 6.25C9.62916 6.25 9.26665 6.35997 8.95831 6.566C8.64997 6.77202 8.40964 7.06486 8.26773 7.40747C8.12581 7.75008 8.08868 8.12708 8.16103 8.4908C8.23338 8.85451 8.41195 9.1886 8.67418 9.45083C8.9364 9.71305 9.27049 9.89163 9.63421 9.96397C9.99792 10.0363 10.3749 9.99919 10.7175 9.85728C11.0601 9.71536 11.353 9.47504 11.559 9.1667C11.765 8.85835 11.875 8.49584 11.875 8.125C11.8745 7.62787 11.6768 7.15125 11.3253 6.79972C10.9738 6.4482 10.4971 6.2505 10 6.25Z"
      fill="#484D59"
    />
    <path
      d="M10 18.75L4.72751 12.5319C4.65425 12.4385 4.58174 12.3445 4.51001 12.25C3.60937 11.0636 3.12282 9.61452 3.12501 8.125C3.12501 6.30164 3.84934 4.55295 5.13865 3.26364C6.42796 1.97433 8.17664 1.25 10 1.25C11.8234 1.25 13.5721 1.97433 14.8614 3.26364C16.1507 4.55295 16.875 6.30164 16.875 8.125C16.8772 9.61384 16.3909 11.0623 15.4906 12.2481L15.49 12.25C15.49 12.25 15.3025 12.4963 15.2744 12.5294L10 18.75ZM5.50751 11.4969C5.50876 11.4969 5.65376 11.6894 5.68688 11.7306L10 16.8175L14.3188 11.7237C14.3463 11.6894 14.4925 11.4956 14.4931 11.495C15.2289 10.5257 15.6265 9.34189 15.625 8.125C15.625 6.63316 15.0324 5.20242 13.9775 4.14752C12.9226 3.09263 11.4919 2.5 10 2.5C8.50816 2.5 7.07742 3.09263 6.02253 4.14752C4.96764 5.20242 4.37501 6.63316 4.37501 8.125C4.3737 9.34265 4.77174 10.5271 5.50813 11.4969H5.50751Z"
      fill="#484D59"
    />
  </svg>
);

export const InvoiceSendIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_19104_16481)">
      <path
        d="M6.99903 9.00012L13.999 2.00012M7.08408 9.21883L8.83616 13.7242C8.99051 14.1211 9.06768 14.3195 9.17888 14.3774C9.27528 14.4277 9.39012 14.4277 9.48658 14.3776C9.59785 14.3198 9.67525 14.1215 9.83007 13.7247L14.2236 2.46625C14.3634 2.10813 14.4333 1.92907 14.395 1.81465C14.3618 1.71528 14.2839 1.6373 14.1845 1.60411C14.0701 1.56588 13.891 1.63576 13.5329 1.77552L2.2744 6.16908C1.87768 6.32389 1.67933 6.4013 1.62152 6.51257C1.57141 6.60903 1.57148 6.72386 1.6217 6.82026C1.67964 6.93146 1.87809 7.00864 2.27498 7.16299L6.78032 8.91506C6.86089 8.94639 6.90117 8.96206 6.93509 8.98625C6.96515 9.0077 6.99145 9.03399 7.01289 9.06406C7.03709 9.09798 7.05275 9.13826 7.08408 9.21883Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_19104_16481">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const changeOrderRefresh = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g>
      <path
        d="M8.00065 13.6666C11.1303 13.6666 13.6673 11.1296 13.6673 7.99998C13.6673 6.11629 12.7482 4.44727 11.334 3.41692M8.66732 14.9333L7.33398 13.6L8.66732 12.2666M8.00065 2.33332C4.87104 2.33332 2.33398 4.87037 2.33398 7.99998C2.33398 9.88367 3.25309 11.5527 4.66732 12.5831M7.33398 3.73332L8.66732 2.39998L7.33398 1.06665"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export const arrowDownToggle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15 12.5L10 7.5L5 12.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const SaveInvoiceIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g>
      <path
        d="M10 5.33333H5.73333C5.35997 5.33333 5.17328 5.33333 5.03067 5.26067C4.90523 5.19676 4.80324 5.09477 4.73933 4.96933C4.66667 4.82672 4.66667 4.64004 4.66667 4.26667V2M11.3333 14V9.73333C11.3333 9.35997 11.3333 9.17328 11.2607 9.03067C11.1968 8.90523 11.0948 8.80324 10.9693 8.73933C10.8267 8.66667 10.64 8.66667 10.2667 8.66667H5.73333C5.35997 8.66667 5.17328 8.66667 5.03067 8.73933C4.90523 8.80324 4.80324 8.90523 4.73933 9.03067C4.66667 9.17328 4.66667 9.35997 4.66667 9.73333V14M14 6.21699V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.0799 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H9.78301C10.1091 2 10.2722 2 10.4256 2.03684C10.5617 2.0695 10.6918 2.12337 10.811 2.19648C10.9456 2.27894 11.0609 2.39424 11.2915 2.62484L13.3752 4.7085C13.6058 4.9391 13.7211 5.0544 13.8035 5.18895C13.8766 5.30825 13.9305 5.43831 13.9632 5.57436C14 5.72781 14 5.89087 14 6.21699Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const reversIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M2 3.5H7.5M7.5 3.5L5.5 5.5M7.5 3.5L5.5 1.5M2 8.5H10M10 8.5L8 10.5M10 8.5L8 6.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const notSent = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M7 5.5H4M5 7.5H4M8 3.5H4M10 5.25V3.4C10 2.55992 10 2.13988 9.83651 1.81901C9.6927 1.53677 9.46323 1.3073 9.18099 1.16349C8.86012 1 8.44008 1 7.6 1H4.4C3.55992 1 3.13988 1 2.81901 1.16349C2.53677 1.3073 2.3073 1.53677 2.16349 1.81901C2 2.13988 2 2.55992 2 3.4V8.6C2 9.44008 2 9.86012 2.16349 10.181C2.3073 10.4632 2.53677 10.6927 2.81901 10.8365C3.13988 11 3.55992 11 4.4 11H5.75M11 11L10.25 10.25M10.75 9C10.75 9.9665 9.9665 10.75 9 10.75C8.0335 10.75 7.25 9.9665 7.25 9C7.25 8.0335 8.0335 7.25 9 7.25C9.9665 7.25 10.75 8.0335 10.75 9Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const dateIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M13.3333 1.66675V5.00008M6.66667 1.66675V5.00008M2.5 8.33342H17.5M4.16667 3.33341H15.8333C16.7538 3.33341 17.5 4.07961 17.5 5.00008V16.6667C17.5 17.5872 16.7538 18.3334 15.8333 18.3334H4.16667C3.24619 18.3334 2.5 17.5872 2.5 16.6667V5.00008C2.5 4.07961 3.24619 3.33341 4.16667 3.33341Z"
      stroke="#344054"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const uploadIconI = (
  <svg
    width="47"
    height="46"
    viewBox="0 0 47 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3.5" y="3" width="40" height="40" rx="20" fill="#F2F4F7" />
    <rect
      x="3.5"
      y="3"
      width="40"
      height="40"
      rx="20"
      stroke="#F9FAFB"
      strokeWidth="6"
    />
    <g clipPath="url(#clip0_19569_2929)">
      <path
        d="M26.8326 26.3332L23.4992 22.9999M23.4992 22.9999L20.1659 26.3332M23.4992 22.9999V30.4999M30.4909 28.3249C31.3037 27.8818 31.9458 27.1806 32.3158 26.3321C32.6858 25.4835 32.7627 24.5359 32.5344 23.6388C32.3061 22.7417 31.7855 21.9462 31.0548 21.3778C30.3241 20.8094 29.425 20.5005 28.4992 20.4999H27.4492C27.197 19.5243 26.7269 18.6185 26.0742 17.8507C25.4215 17.0829 24.6033 16.4731 23.681 16.0671C22.7587 15.661 21.7564 15.4694 20.7493 15.5065C19.7423 15.5436 18.7568 15.8085 17.8669 16.2813C16.977 16.7541 16.2058 17.4225 15.6114 18.2362C15.017 19.05 14.6148 19.9879 14.4351 20.9794C14.2553 21.9709 14.3027 22.9903 14.5736 23.961C14.8445 24.9316 15.3319 25.8281 15.9992 26.5832"
        stroke="#475467"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_19569_2929">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(13.5 13)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const sendReIcon = (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#ECFDF3"
      strokeWidth="8"
    />
    <path
      d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
      stroke="#039855"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const invoiceSendIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_16800_55996)">
      <path
        d="M7.00001 9.00012L14 2.00012M7.08506 9.21883L8.83714 13.7242C8.99148 14.1211 9.06866 14.3195 9.17986 14.3774C9.27626 14.4277 9.39109 14.4277 9.48755 14.3776C9.59882 14.3198 9.67623 14.1215 9.83105 13.7247L14.2246 2.46625C14.3644 2.10813 14.4342 1.92907 14.396 1.81465C14.3628 1.71528 14.2848 1.6373 14.1855 1.60411C14.0711 1.56588 13.892 1.63576 13.5339 1.77552L2.27538 6.16908C1.87866 6.32389 1.6803 6.4013 1.6225 6.51257C1.57239 6.60903 1.57245 6.72386 1.62268 6.82026C1.68062 6.93146 1.87906 7.00864 2.27596 7.16299L6.7813 8.91506C6.86186 8.94639 6.90215 8.96206 6.93607 8.98625C6.96613 9.0077 6.99242 9.03399 7.01387 9.06406C7.03806 9.09798 7.05373 9.13826 7.08506 9.21883Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_16800_55996">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const InvoiceEye = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M1.61342 8.47537C1.52262 8.33161 1.47723 8.25973 1.45182 8.14886C1.43273 8.06559 1.43273 7.93425 1.45182 7.85097C1.47723 7.74011 1.52262 7.66823 1.61341 7.52447C2.36369 6.33648 4.59693 3.33325 8.00027 3.33325C11.4036 3.33325 13.6369 6.33648 14.3871 7.52447C14.4779 7.66823 14.5233 7.74011 14.5487 7.85097C14.5678 7.93425 14.5678 8.06559 14.5487 8.14886C14.5233 8.25973 14.4779 8.33161 14.3871 8.47537C13.6369 9.66336 11.4036 12.6666 8.00027 12.6666C4.59693 12.6666 2.36369 9.66336 1.61342 8.47537Z"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00027 9.99992C9.10484 9.99992 10.0003 9.10449 10.0003 7.99992C10.0003 6.89535 9.10484 5.99992 8.00027 5.99992C6.8957 5.99992 6.00027 6.89535 6.00027 7.99992C6.00027 9.10449 6.8957 9.99992 8.00027 9.99992Z"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AddNoteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.3337 6.99992V4.53325C13.3337 3.41315 13.3337 2.85309 13.1157 2.42527C12.9239 2.04895 12.618 1.74299 12.2416 1.55124C11.8138 1.33325 11.2538 1.33325 10.1337 1.33325H5.86699C4.74689 1.33325 4.18683 1.33325 3.75901 1.55124C3.38269 1.74299 3.07673 2.04895 2.88498 2.42527C2.66699 2.85309 2.66699 3.41315 2.66699 4.53325V11.4666C2.66699 12.5867 2.66699 13.1467 2.88498 13.5746C3.07673 13.9509 3.38269 14.2569 3.75901 14.4486C4.18683 14.6666 4.74689 14.6666 5.86699 14.6666H8.00033M9.33366 7.33325H5.33366M6.66699 9.99992H5.33366M10.667 4.66659H5.33366M12.0003 13.9999V9.99992M10.0003 11.9999H14.0003"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const onReceipt = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
  >
    <path
      d="M3.5 4.5L6.5 7.5L9.5 4.5"
      stroke="#D9D9D9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ReceiptCustomerIcon = (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7" />
    <rect
      x="4"
      y="4"
      width="48"
      height="48"
      rx="24"
      stroke="#FFFAEB"
      strokeWidth="8"
    />
    <path
      d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
      stroke="#DC6803"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PhoneInvoice = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M6.98356 7.74376C7.56356 8.95178 8.35422 10.084 9.35553 11.0853C10.3568 12.0866 11.4891 12.8773 12.6971 13.4573C12.801 13.5072 12.8529 13.5321 12.9187 13.5513C13.1523 13.6194 13.4392 13.5704 13.637 13.4288C13.6927 13.3889 13.7403 13.3413 13.8356 13.246C14.1269 12.9547 14.2726 12.809 14.4191 12.7138C14.9715 12.3546 15.6837 12.3546 16.2361 12.7138C16.3825 12.809 16.5282 12.9547 16.8196 13.246L16.9819 13.4084C17.4248 13.8513 17.6462 14.0727 17.7665 14.3105C18.0058 14.7835 18.0058 15.342 17.7665 15.815C17.6462 16.0528 17.4248 16.2743 16.9819 16.7171L16.8506 16.8485C16.4092 17.2898 16.1886 17.5105 15.8885 17.6791C15.5556 17.8661 15.0385 18.0006 14.6567 17.9994C14.3126 17.9984 14.0774 17.9316 13.607 17.7981C11.0792 17.0807 8.69387 15.7269 6.70388 13.7369C4.7139 11.747 3.36017 9.36166 2.6427 6.83383C2.50919 6.36346 2.44244 6.12827 2.44141 5.78415C2.44028 5.4023 2.57475 4.88522 2.76176 4.5523C2.9303 4.25227 3.15098 4.0316 3.59233 3.59024L3.72369 3.45888C4.16656 3.01601 4.388 2.79458 4.62581 2.67429C5.09878 2.43507 5.65734 2.43507 6.1303 2.67429C6.36812 2.79458 6.58955 3.01601 7.03242 3.45888L7.19481 3.62127C7.48615 3.91261 7.63182 4.05828 7.72706 4.20476C8.08622 4.75717 8.08622 5.46932 7.72706 6.02173C7.63182 6.16821 7.48615 6.31388 7.19481 6.60522C7.09955 6.70048 7.05192 6.74811 7.01206 6.80379C6.87038 7.00165 6.82146 7.28853 6.88957 7.52216C6.90873 7.5879 6.93367 7.63985 6.98356 7.74376Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const emailInvoiceIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33268 4.3244C2.76119 4.3244 2.29102 4.79458 2.29102 5.36607V15.3661C2.29102 15.9376 2.76119 16.4077 3.33268 16.4077H16.666C17.2375 16.4077 17.7077 15.9376 17.7077 15.3661V5.36607C17.7077 4.79458 17.2375 4.3244 16.666 4.3244H3.33268ZM1.04102 5.36607C1.04102 4.10422 2.07084 3.0744 3.33268 3.0744H16.666C17.9279 3.0744 18.9577 4.10422 18.9577 5.36607V15.3661C18.9577 16.6279 17.9279 17.6577 16.666 17.6577H3.33268C2.07084 17.6577 1.04102 16.6279 1.04102 15.3661V5.36607Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.15405 5.00767C1.352 4.72489 1.7417 4.65611 2.02448 4.85406L9.9994 10.4365L17.9743 4.85406C18.2571 4.65611 18.6468 4.72489 18.8448 5.00767C19.0427 5.29045 18.9739 5.68015 18.6912 5.8781L10.3578 11.7114C10.1426 11.8621 9.85619 11.8621 9.64099 11.7114L1.30766 5.8781C1.02487 5.68015 0.956103 5.29045 1.15405 5.00767Z"
      fill="currentColor"
    />
  </svg>
);
export const webIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <g clipPath="url(#clip0_18695_22044)">
      <path
        d="M9.99935 2.53278C12.0837 4.81474 13.2683 7.77614 13.3327 10.8661C13.2683 13.9561 12.0837 16.9175 9.99935 19.1994M9.99935 2.53278C7.91495 4.81474 6.73039 7.77614 6.66602 10.8661C6.73039 13.9561 7.91495 16.9175 9.99935 19.1994M9.99935 2.53278C5.39698 2.53278 1.66602 6.26374 1.66602 10.8661C1.66602 15.4685 5.39698 19.1994 9.99935 19.1994M9.99935 2.53278C14.6017 2.53278 18.3327 6.26374 18.3327 10.8661C18.3327 15.4685 14.6017 19.1994 9.99935 19.1994M2.0827 8.36611H17.916M2.08268 13.3661H17.916"
        stroke="#484D59"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_18695_22044">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.866089)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const InstagramIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <rect
      x="1.25"
      y="2.11609"
      width="17.5"
      height="17.5"
      rx="6"
      fill="url(#paint0_radial_18695_22045)"
    />
    <rect
      x="1.25"
      y="2.11609"
      width="17.5"
      height="17.5"
      rx="6"
      fill="url(#paint1_radial_18695_22045)"
    />
    <rect
      x="1.25"
      y="2.11609"
      width="17.5"
      height="17.5"
      rx="6"
      fill="url(#paint2_radial_18695_22045)"
    />
    <path
      d="M14.375 7.42859C14.375 7.94636 13.9553 8.36609 13.4375 8.36609C12.9197 8.36609 12.5 7.94636 12.5 7.42859C12.5 6.91082 12.9197 6.49109 13.4375 6.49109C13.9553 6.49109 14.375 6.91082 14.375 7.42859Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 13.9911C11.7259 13.9911 13.125 12.592 13.125 10.8661C13.125 9.1402 11.7259 7.74109 10 7.74109C8.27411 7.74109 6.875 9.1402 6.875 10.8661C6.875 12.592 8.27411 13.9911 10 13.9911ZM10 12.7411C11.0355 12.7411 11.875 11.9016 11.875 10.8661C11.875 9.83055 11.0355 8.99109 10 8.99109C8.96447 8.99109 8.125 9.83055 8.125 10.8661C8.125 11.9016 8.96447 12.7411 10 12.7411Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 10.6161C3.75 8.51589 3.75 7.46579 4.15873 6.66362C4.51825 5.95802 5.09193 5.38434 5.79754 5.02481C6.5997 4.61609 7.6498 4.61609 9.75 4.61609H10.25C12.3502 4.61609 13.4003 4.61609 14.2025 5.02481C14.9081 5.38434 15.4817 5.95802 15.8413 6.66362C16.25 7.46579 16.25 8.51589 16.25 10.6161V11.1161C16.25 13.2163 16.25 14.2664 15.8413 15.0686C15.4817 15.7742 14.9081 16.3478 14.2025 16.7074C13.4003 17.1161 12.3502 17.1161 10.25 17.1161H9.75C7.6498 17.1161 6.5997 17.1161 5.79754 16.7074C5.09193 16.3478 4.51825 15.7742 4.15873 15.0686C3.75 14.2664 3.75 13.2163 3.75 11.1161V10.6161ZM9.75 5.86609H10.25C11.3207 5.86609 12.0486 5.86706 12.6112 5.91303C13.1592 5.9578 13.4395 6.03896 13.635 6.13857C14.1054 6.37826 14.4878 6.76071 14.7275 7.23111C14.8271 7.42661 14.9083 7.70687 14.9531 8.25489C14.999 8.8175 15 9.54536 15 10.6161V11.1161C15 12.1868 14.999 12.9147 14.9531 13.4773C14.9083 14.0253 14.8271 14.3056 14.7275 14.5011C14.4878 14.9715 14.1054 15.3539 13.635 15.5936C13.4395 15.6932 13.1592 15.7744 12.6112 15.8191C12.0486 15.8651 11.3207 15.8661 10.25 15.8661H9.75C8.67928 15.8661 7.95141 15.8651 7.3888 15.8191C6.84078 15.7744 6.56052 15.6932 6.36502 15.5936C5.89462 15.3539 5.51217 14.9715 5.27248 14.5011C5.17287 14.3056 5.09171 14.0253 5.04694 13.4773C5.00097 12.9147 5 12.1868 5 11.1161V10.6161C5 9.54536 5.00097 8.8175 5.04694 8.25489C5.09171 7.70687 5.17287 7.42661 5.27248 7.23111C5.51217 6.76071 5.89462 6.37826 6.36502 6.13857C6.56052 6.03896 6.84078 5.9578 7.3888 5.91303C7.95141 5.86706 8.67928 5.86609 9.75 5.86609Z"
      fill="white"
    />
    <defs>
      <radialGradient
        id="paint0_radial_18695_22045"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.5 15.2411) rotate(-55.3758) scale(15.9498)"
      >
        <stop stopColor="#B13589" />
        <stop offset="0.79309" stopColor="#C62F94" />
        <stop offset="1" stopColor="#8A3AC8" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_18695_22045"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.875 20.2411) rotate(-65.1363) scale(14.1214)"
      >
        <stop stopColor="#E0E8B7" />
        <stop offset="0.444662" stopColor="#FB8A2E" />
        <stop offset="0.71474" stopColor="#E2425C" />
        <stop offset="1" stopColor="#E2425C" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_18695_22045"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0.312501 2.74109) rotate(-8.1301) scale(24.3068 5.19897)"
      >
        <stop offset="0.156701" stopColor="#406ADC" />
        <stop offset="0.467799" stopColor="#6A45BE" />
        <stop offset="1" stopColor="#6A45BE" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
export const FacebookIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <g clipPath="url(#clip0_18695_22046)">
      <path
        d="M20 10.8661C20 5.34324 15.5229 0.866089 10 0.866089C4.47715 0.866089 0 5.34324 0 10.8661C0 15.8573 3.65684 19.9944 8.4375 20.7446V13.7567H5.89844V10.8661H8.4375V8.66296C8.4375 6.15671 9.93047 4.77234 12.2146 4.77234C13.3084 4.77234 14.4531 4.96765 14.4531 4.96765V7.42859H13.1922C11.95 7.42859 11.5625 8.19949 11.5625 8.99109V10.8661H14.3359L13.8926 13.7567H11.5625V20.7446C16.3432 19.9944 20 15.8573 20 10.8661Z"
        fill="#1877F2"
      />
      <path
        d="M13.8926 13.7567L14.3359 10.8661H11.5625V8.99109C11.5625 8.20027 11.95 7.42859 13.1922 7.42859H14.4531V4.96765C14.4531 4.96765 13.3088 4.77234 12.2146 4.77234C9.93047 4.77234 8.4375 6.15671 8.4375 8.66296V10.8661H5.89844V13.7567H8.4375V20.7446C9.47287 20.9066 10.5271 20.9066 11.5625 20.7446V13.7567H13.8926Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_18695_22046">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.866089)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const youtubeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <g clipPath="url(#clip0_18695_22047)">
      <path
        d="M19.6014 6.02068C19.4883 5.59519 19.2654 5.20686 18.9551 4.89456C18.6448 4.58225 18.2579 4.35693 17.8332 4.24113C16.2695 3.82068 10.0195 3.82068 10.0195 3.82068C10.0195 3.82068 3.76953 3.82068 2.20589 4.24113C1.78114 4.35693 1.39425 4.58225 1.08394 4.89456C0.773628 5.20686 0.550786 5.59519 0.437713 6.02068C0.0195313 7.59113 0.0195312 10.8661 0.0195312 10.8661C0.0195312 10.8661 0.0195313 14.1411 0.437713 15.7116C0.550786 16.1371 0.773628 16.5254 1.08394 16.8377C1.39425 17.15 1.78114 17.3753 2.20589 17.4911C3.76953 17.9116 10.0195 17.9116 10.0195 17.9116C10.0195 17.9116 16.2695 17.9116 17.8332 17.4911C18.2579 17.3753 18.6448 17.15 18.9551 16.8377C19.2654 16.5254 19.4883 16.1371 19.6014 15.7116C20.0195 14.1411 20.0195 10.8661 20.0195 10.8661C20.0195 10.8661 20.0195 7.59113 19.6014 6.02068Z"
        fill="#FF0302"
      />
      <path
        d="M7.97461 13.84V7.89227L13.2019 10.8661L7.97461 13.84Z"
        fill="#FEFEFE"
      />
    </g>
    <defs>
      <clipPath id="clip0_18695_22047">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.866089)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const yelpIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="28"
    viewBox="0 0 22 28"
    fill="none"
  >
    <path
      d="M18.1561 8.30498C19.1476 9.12529 21.8694 11.3754 21.426 12.9549C21.2204 13.6644 20.4062 13.8916 19.8021 14.1132C18.4092 14.6173 16.9809 15.0174 15.5289 15.3103C14.3539 15.5486 11.8433 16.0696 12.3088 13.9912C12.4917 13.1876 13.1401 12.5058 13.6612 11.9017C14.7308 10.6442 15.8227 9.31405 17.114 8.28261C17.1622 8.24509 17.2175 8.21786 17.2767 8.20261C17.3358 8.18737 17.3974 8.18443 17.4577 8.19397C17.5727 8.14447 17.6993 8.12862 17.823 8.14827C17.9466 8.16792 18.0621 8.22226 18.1561 8.30498Z"
      fill="#C1272D"
    />
    <path
      d="M16.6925 24.8878C15.8218 24.1839 15.2071 23.081 14.675 22.1167C14.154 21.1856 13.3338 19.9552 13.1952 18.8745C12.9126 16.652 16.1326 17.9878 17.0582 18.3092C18.2886 18.7415 21.5863 19.2182 21.2205 21.1413C20.9157 22.7097 19.0257 24.6218 17.4463 24.8656C17.4262 24.8683 17.4059 24.8683 17.3858 24.8656L17.3692 24.8821C17.3249 24.9043 17.275 24.9321 17.2251 24.9547C17.1404 25.0005 17.0436 25.0187 16.9481 25.0067C16.8525 24.9947 16.7632 24.9531 16.6925 24.8878Z"
      fill="#C1272D"
    />
    <path
      d="M9.49243 19.1684C11.2438 18.4429 11.4045 20.3987 11.3934 21.5848C11.3768 22.9981 11.3329 24.4169 11.2271 25.8246C11.1883 26.3955 11.2106 27.0822 10.6674 27.443C9.99678 27.8864 8.70549 27.5372 8.01262 27.3211C7.99151 27.3095 7.96921 27.3002 7.94612 27.2934C6.48856 27.038 4.70948 26.3674 4.53717 25.0209C4.53072 24.9483 4.53979 24.8752 4.56377 24.8064C4.58774 24.7376 4.62608 24.6747 4.67623 24.6218C4.71788 24.5416 4.76415 24.4639 4.8148 24.3891C5.03648 24.0963 5.27522 23.8168 5.52979 23.5522C5.95096 22.9259 6.40549 22.3218 6.88759 21.7398C7.5859 20.8975 8.44491 19.6002 9.49243 19.1684Z"
      fill="#C1272D"
    />
    <path
      d="M9.74742 13.0935C7.1924 10.993 4.70389 7.73965 3.41804 4.73058C2.43702 2.44121 4.9311 1.0446 6.85419 0.412796C9.44803 -0.440772 10.4789 0.301913 10.7393 2.89575C10.9555 5.13183 11.0554 7.37762 11.0386 9.62405C11.033 10.5718 11.2991 12.1014 10.7449 12.955C10.8335 13.3042 10.307 13.5536 10.0073 13.3152C9.9629 13.282 9.91864 13.2376 9.8687 13.1988C9.8232 13.17 9.78231 13.1345 9.74742 13.0935Z"
      fill="#C1272D"
    />
    <path
      d="M9.47094 16.3468C9.67651 17.2557 9.0331 17.6215 8.27941 17.9652C6.9304 18.562 5.5392 19.0584 4.11713 19.4505C3.29682 19.6777 2.03878 20.2209 1.24628 19.6168C0.647727 19.1624 0.608912 18.2141 0.553531 17.5273C0.431645 15.9916 0.664414 12.3286 2.79259 12.1735C3.83455 12.1009 5.13696 13.032 5.99053 13.5203C7.12717 14.1743 8.34603 14.923 9.19392 15.9479C9.26616 15.9904 9.32834 16.0481 9.37616 16.117C9.42398 16.1858 9.45632 16.2642 9.47094 16.3468Z"
      fill="#C1272D"
    />
  </svg>
);

export const BoldIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      d="M19.6 15.6562C20.57 14.9862 21.25 13.8862 21.25 12.8662C21.25 10.6062 19.5 8.86621 17.25 8.86621H11V22.8662H18.04C20.13 22.8662 21.75 21.1662 21.75 19.0762C21.75 17.5562 20.89 16.2562 19.6 15.6562ZM14 11.3662H17C17.83 11.3662 18.5 12.0362 18.5 12.8662C18.5 13.6962 17.83 14.3662 17 14.3662H14V11.3662ZM17.5 20.3662H14V17.3662H17.5C18.33 17.3662 19 18.0362 19 18.8662C19 19.6962 18.33 20.3662 17.5 20.3662Z"
      fill="#98A2B3"
    />
  </svg>
);

export const ItalicIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      d="M14 8.86621V11.8662H16.21L12.79 19.8662H10V22.8662H18V19.8662H15.79L19.21 11.8662H22V8.86621H14Z"
      fill="#98A2B3"
    />
  </svg>
);

export const LinkIconInvoice = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      d="M7.9 16.8662C7.9 15.1562 9.29 13.7662 11 13.7662H15V11.8662H11C8.24 11.8662 6 14.1062 6 16.8662C6 19.6262 8.24 21.8662 11 21.8662H15V19.9662H11C9.29 19.9662 7.9 18.5762 7.9 16.8662ZM12 17.8662H20V15.8662H12V17.8662ZM21 11.8662H17V13.7662H21C22.71 13.7662 24.1 15.1562 24.1 16.8662C24.1 18.5762 22.71 19.9662 21 19.9662H17V21.8662H21C23.76 21.8662 26 19.6262 26 16.8662C26 14.1062 23.76 11.8662 21 11.8662Z"
      fill="#98A2B3"
    />
  </svg>
);

export const ImageView = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      d="M25 23.8662V9.86621C25 8.76621 24.1 7.86621 23 7.86621H9C7.9 7.86621 7 8.76621 7 9.86621V23.8662C7 24.9662 7.9 25.8662 9 25.8662H23C24.1 25.8662 25 24.9662 25 23.8662ZM12.5 18.3662L15 21.3762L18.5 16.8662L23 22.8662H9L12.5 18.3662Z"
      fill="#98A2B3"
    />
  </svg>
);

export const ListIconView = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      d="M8.25 15.3662C7.42 15.3662 6.75 16.0362 6.75 16.8662C6.75 17.6962 7.42 18.3662 8.25 18.3662C9.08 18.3662 9.75 17.6962 9.75 16.8662C9.75 16.0362 9.08 15.3662 8.25 15.3662ZM8.25 9.36621C7.42 9.36621 6.75 10.0362 6.75 10.8662C6.75 11.6962 7.42 12.3662 8.25 12.3662C9.08 12.3662 9.75 11.6962 9.75 10.8662C9.75 10.0362 9.08 9.36621 8.25 9.36621ZM8.25 21.3662C7.42 21.3662 6.75 22.0462 6.75 22.8662C6.75 23.6862 7.43 24.3662 8.25 24.3662C9.07 24.3662 9.75 23.6862 9.75 22.8662C9.75 22.0462 9.08 21.3662 8.25 21.3662ZM11.25 23.8662H25.25V21.8662H11.25V23.8662ZM11.25 17.8662H25.25V15.8662H11.25V17.8662ZM11.25 9.86621V11.8662H25.25V9.86621H11.25Z"
      fill="#98A2B3"
    />
  </svg>
);

export const unOrderList = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <path
      d="M7 21.8662H9V22.3662H8V23.3662H9V23.8662H7V24.8662H10V20.8662H7V21.8662ZM8 12.8662H9V8.86621H7V9.86621H8V12.8662ZM7 15.8662H8.8L7 17.9662V18.8662H10V17.8662H8.2L10 15.7662V14.8662H7V15.8662ZM12 9.86621V11.8662H26V9.86621H12ZM12 23.8662H26V21.8662H12V23.8662ZM12 17.8662H26V15.8662H12V17.8662Z"
      fill="#98A2B3"
    />
  </svg>
);

export const sendLink = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M8.47154 12.7427L7.52874 13.6855C6.22699 14.9872 4.11644 14.9872 2.81469 13.6855C1.51294 12.3837 1.51294 10.2732 2.81469 8.97142L3.7575 8.02861M12.2428 8.97142L13.1856 8.02861C14.4873 6.72687 14.4873 4.61632 13.1856 3.31457C11.8838 2.01282 9.77329 2.01282 8.47154 3.31457L7.52874 4.25738M5.66681 10.8333L10.3335 6.16667"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const EmptyBox = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
  >
    <path
      d="M6.75 30H15.2049C16.9179 30 18.4839 30.9678 19.25 32.5C20.0161 34.0322 21.5821 35 23.2951 35H37.7049C39.4179 35 40.9839 34.0322 41.75 32.5C42.5161 30.9678 44.0821 30 45.7951 30H54.25M22.9164 10H38.0836C40.7758 10 42.1219 10 43.3103 10.4099C44.3612 10.7724 45.3184 11.364 46.1126 12.1419C47.0107 13.0215 47.6127 14.2255 48.8167 16.6334L54.2331 27.4663C54.7056 28.4112 54.9418 28.8837 55.1084 29.3789C55.2564 29.8186 55.3632 30.2712 55.4276 30.7307C55.5 31.2481 55.5 31.7763 55.5 32.8328V38C55.5 42.2004 55.5 44.3006 54.6825 45.9049C53.9635 47.3161 52.8161 48.4635 51.4049 49.1825C49.8006 50 47.7004 50 43.5 50H17.5C13.2996 50 11.1994 50 9.59507 49.1825C8.18386 48.4635 7.0365 47.3161 6.31745 45.9049C5.5 44.3006 5.5 42.2004 5.5 38V32.8328C5.5 31.7763 5.5 31.2481 5.57244 30.7307C5.63676 30.2712 5.74359 29.8186 5.89155 29.3789C6.05815 28.8837 6.2944 28.4112 6.76687 27.4663L12.1833 16.6334C13.3873 14.2255 13.9893 13.0215 14.8874 12.1419C15.6816 11.364 16.6388 10.7724 17.6897 10.4099C18.8781 10 20.2242 10 22.9164 10Z"
      stroke="#344054"
      strokeOpacity="0.5"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InvoiceSignedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 10 8"
    fill="none"
  >
    <path
      d="M7 3H0.5M9 1H0.5M9 5H0.5M7 7H0.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InvoiceSentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="12"
    viewBox="0 0 9 10"
    fill="none"
  >
    <path
      d="M0.5 2.5H6M6 2.5L4 4.5M6 2.5L4 0.5M0.5 7.5H8.5M8.5 7.5L6.5 9.5M8.5 7.5L6.5 5.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InvoiceDraftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
  >
    <path
      d="M5.5 5.5H2.5M3.5 7.5H2.5M6.5 3.5H2.5M8.5 5.25V3.4C8.5 2.55992 8.5 2.13988 8.33651 1.81901C8.1927 1.53677 7.96323 1.3073 7.68099 1.16349C7.36012 1 6.94008 1 6.1 1H2.9C2.05992 1 1.63988 1 1.31901 1.16349C1.03677 1.3073 0.8073 1.53677 0.66349 1.81901C0.5 2.13988 0.5 2.55992 0.5 3.4V8.6C0.5 9.44008 0.5 9.86012 0.66349 10.181C0.8073 10.4632 1.03677 10.6927 1.31901 10.8365C1.63988 11 2.05992 11 2.9 11H4.25M9.5 11L8.75 10.25M9.25 9C9.25 9.9665 8.4665 10.75 7.5 10.75C6.5335 10.75 5.75 9.9665 5.75 9C5.75 8.0335 6.5335 7.25 7.5 7.25C8.4665 7.25 9.25 8.0335 9.25 9Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InvoiceDueIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
  >
    <path
      d="M10.35 5.75L9.35027 4.75L8.34998 5.75M9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C6.65096 0.5 8.09426 1.38907 8.87725 2.71455M5 2.5V5L6.5 6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InvoiceNotDueIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <path
      d="M8.5 5H1.5M1.5 5L5 8.5M1.5 5L5 1.5"
      stroke="#3483FA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InvoicePaidIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
  >
    <path
      d="M9 1L3.5 6.5L1 4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const DraftIcon = () => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 5.5H3M4 7.5H3M7 3.5H3M9 5.25V3.4C9 2.55992 9 2.13988 8.83651 1.81901C8.6927 1.53677 8.46323 1.3073 8.18099 1.16349C7.86012 1 7.44008 1 6.6 1H3.4C2.55992 1 2.13988 1 1.81901 1.16349C1.53677 1.3073 1.3073 1.53677 1.16349 1.81901C1 2.13988 1 2.55992 1 3.4V8.6C1 9.44008 1 9.86012 1.16349 10.181C1.3073 10.4632 1.53677 10.6927 1.81901 10.8365C2.13988 11 2.55992 11 3.4 11H4.75M10 11L9.25 10.25M9.75 9C9.75 9.9665 8.9665 10.75 8 10.75C7.0335 10.75 6.25 9.9665 6.25 9C6.25 8.0335 7.0335 7.25 8 7.25C8.9665 7.25 9.75 8.0335 9.75 9Z"
      stroke="#667085"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="5"
    height="16"
    viewBox="0 0 5 16"
    fill="none"
  >
    <path
      d="M2.49935 8.83337C2.95959 8.83337 3.33268 8.46028 3.33268 8.00004C3.33268 7.5398 2.95959 7.16671 2.49935 7.16671C2.03911 7.16671 1.66602 7.5398 1.66602 8.00004C1.66602 8.46028 2.03911 8.83337 2.49935 8.83337Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49935 3.00004C2.95959 3.00004 3.33268 2.62694 3.33268 2.16671C3.33268 1.70647 2.95959 1.33337 2.49935 1.33337C2.03911 1.33337 1.66602 1.70647 1.66602 2.16671C1.66602 2.62694 2.03911 3.00004 2.49935 3.00004Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.49935 14.6667C2.95959 14.6667 3.33268 14.2936 3.33268 13.8334C3.33268 13.3731 2.95959 13 2.49935 13C2.03911 13 1.66602 13.3731 1.66602 13.8334C1.66602 14.2936 2.03911 14.6667 2.49935 14.6667Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InvoiceDateIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
  >
    <path
      d="M13 6.66671H1M9.66667 1.33337V4.00004M4.33333 1.33337V4.00004M4.2 14.6667H9.8C10.9201 14.6667 11.4802 14.6667 11.908 14.4487C12.2843 14.257 12.5903 13.951 12.782 13.5747C13 13.1469 13 12.5868 13 11.4667V5.86671C13 4.7466 13 4.18655 12.782 3.75873C12.5903 3.3824 12.2843 3.07644 11.908 2.88469C11.4802 2.66671 10.9201 2.66671 9.8 2.66671H4.2C3.0799 2.66671 2.51984 2.66671 2.09202 2.88469C1.71569 3.07644 1.40973 3.3824 1.21799 3.75873C1 4.18655 1 4.7466 1 5.86671V11.4667C1 12.5868 1 13.1469 1.21799 13.5747C1.40973 13.951 1.71569 14.257 2.09202 14.4487C2.51984 14.6667 3.0799 14.6667 4.2 14.6667Z"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ViewSendInvoiceIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M6.99952 8.99999L13.9995 1.99999M7.08457 9.2187L8.83665 13.724C8.991 14.1209 9.06817 14.3194 9.17937 14.3773C9.27577 14.4275 9.39061 14.4276 9.48706 14.3775C9.59833 14.3197 9.67574 14.1213 9.83056 13.7246L14.2241 2.46612C14.3639 2.108 14.4337 1.92894 14.3955 1.81453C14.3623 1.71516 14.2844 1.63718 14.185 1.60399C14.0706 1.56576 13.8915 1.63564 13.5334 1.77539L2.27489 6.16895C1.87817 6.32377 1.67981 6.40118 1.62201 6.51245C1.5719 6.60891 1.57196 6.72374 1.62219 6.82014C1.68013 6.93134 1.87858 7.00852 2.27547 7.16286L6.78081 8.91494C6.86137 8.94627 6.90166 8.96194 6.93558 8.98613C6.96564 9.00758 6.99193 9.03387 7.01338 9.06393C7.03758 9.09785 7.05324 9.13814 7.08457 9.2187Z"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DeleteInvoice = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M5 1H9M1 3H13M11.6667 3L11.1991 10.0129C11.129 11.065 11.0939 11.5911 10.8667 11.99C10.6666 12.3412 10.3648 12.6235 10.0011 12.7998C9.58798 13 9.06073 13 8.00623 13H5.99377C4.93927 13 4.41202 13 3.99889 12.7998C3.63517 12.6235 3.33339 12.3412 3.13332 11.99C2.90607 11.5911 2.871 11.065 2.80086 10.0129L2.33333 3M5.66667 6V9.33333M8.33333 6V9.33333"
      stroke="#E42626"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AddInternalNoteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
  >
    <path
      d="M12.3332 7.00004V4.53337C12.3332 3.41327 12.3332 2.85322 12.1152 2.42539C11.9234 2.04907 11.6175 1.74311 11.2412 1.55136C10.8133 1.33337 10.2533 1.33337 9.13317 1.33337H4.8665C3.7464 1.33337 3.18635 1.33337 2.75852 1.55136C2.3822 1.74311 2.07624 2.04907 1.88449 2.42539C1.6665 2.85322 1.6665 3.41327 1.6665 4.53337V11.4667C1.6665 12.5868 1.6665 13.1469 1.88449 13.5747C2.07624 13.951 2.3822 14.257 2.75852 14.4487C3.18635 14.6667 3.7464 14.6667 4.8665 14.6667H6.99984M8.33317 7.33337H4.33317M5.6665 10H4.33317M9.6665 4.66671H4.33317M10.9998 14V10M8.99984 12H12.9998"
      stroke="#667085"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EditAllocationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M4.83301 9.5V2.5C4.83301 1.9564 4.83301 1.6846 4.7442 1.4702C4.62579 1.18434 4.39867 0.957217 4.11281 0.838807C3.89841 0.75 3.62661 0.75 3.08301 0.75C2.53941 0.75 2.26761 0.75 2.05321 0.838807C1.76734 0.957217 1.54022 1.18434 1.42182 1.4702C1.33301 1.6846 1.33301 1.9564 1.33301 2.5V9.5C1.33301 10.0436 1.33301 10.3154 1.42182 10.5298C1.54022 10.8157 1.76734 11.0428 2.05321 11.1612C2.26761 11.25 2.53941 11.25 3.08301 11.25C3.62661 11.25 3.89841 11.25 4.11281 11.1612C4.39867 11.0428 4.62579 10.8157 4.7442 10.5298C4.83301 10.3154 4.83301 10.0436 4.83301 9.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6663 7.16667V2.5C10.6663 1.9564 10.6663 1.6846 10.5775 1.4702C10.4591 1.18434 10.232 0.957217 9.94614 0.838807C9.73174 0.75 9.45994 0.75 8.91634 0.75C8.37274 0.75 8.10094 0.75 7.88654 0.838807C7.60068 0.957217 7.37356 1.18434 7.25515 1.4702C7.16634 1.6846 7.16634 1.9564 7.16634 2.5V7.16667C7.16634 7.71027 7.16634 7.98206 7.25515 8.19646C7.37356 8.48233 7.60068 8.70945 7.88654 8.82786C8.10094 8.91667 8.37274 8.91667 8.91634 8.91667C9.45994 8.91667 9.73174 8.91667 9.94614 8.82786C10.232 8.70945 10.4591 8.48233 10.5775 8.19646C10.6663 7.98206 10.6663 7.71026 10.6663 7.16667Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ACHIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18px"
    height="18px"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3 21.0001H21M4 18.0001H20M6 18.0001V13.0001M10 18.0001V13.0001M14 18.0001V13.0001M18 18.0001V13.0001M12 7.00695L12.0074 7.00022M21 10.0001L14.126 3.88986C13.3737 3.2212 12.9976 2.88688 12.5732 2.75991C12.1992 2.64806 11.8008 2.64806 11.4268 2.75991C11.0024 2.88688 10.6263 3.2212 9.87404 3.88986L3 10.0001H21Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
