import ModalDailog from "../../../../../../common/components/ModalDialogue";
import PastReports from "../../modals/PastReports";
import ReportProblem from "../../modals/ReportProblem";
import ConfirmCard from "../../Model/ConfirmCard";
import PaymentModel from "../../Model/PaymentModel";
import PayoneerModel from "../../Model/PayoneerModel";
import PayPalModel from "../../Model/PayPalModel";
import UploadFormCard from "../../Model/UploadFormCard";
import UploadIdCard from "../../Model/UploadIdCard";
import VenmoModel from "../../Model/VenmoModel";

export const UploadIdCardModal = ({
  formModal,
  setFormModal,
  setFormData,
  formData,
  setConfirmModal,
}) => (
  <ModalDailog
    isModalOpen={formModal?.idCardModal}
    handleCancel={() => {
      console.log("cancelewd.");
      setFormModal((prev) => ({
        ...prev,
        idCardModal: !prev?.idCardModal,
      }));
    }}
    title="Upload ID Card"
    closable={false}
    width={400}
    className="upload-Id"
  >
    <UploadIdCard
      setFormData={setFormData}
      setFormModal={setFormModal}
      formData={formData}
      setConfirmModal={setConfirmModal}
    />
  </ModalDailog>
);

export const ConfirmCardModal = ({
  confirmModal,
  setConfirmModal,
  formData,
  setFormModal,
  setLoader,
}) => (
  <ModalDailog
    bodyStyle={{ padding: "0px" }}
    isModalOpen={confirmModal}
    handleCancel={() => setConfirmModal(false)}
    title="Confirm Your ID Card"
    closable={false}
    width={529}
    className="conform-Id"
  >
    <ConfirmCard
      formData={formData}
      setFormModal={setFormModal}
      setConfirmModal={setConfirmModal}
      setLoader={setLoader}
    />
  </ModalDailog>
);

export const UploadFormCardModal = ({
  formData,
  setFormData,
  setIsFormDeleted,
  formModal,
  setFormModal,
  setLoader,
}) => (
  <ModalDailog
    isModalOpen={formModal?.w9FormModal}
    handleCancel={() =>
      setFormModal((prev) => ({ ...prev, w9FromModal: false }))
    }
    title="Upload Signed W9 Form"
    closable={false}
    width={400}
    className="upload-w9Form"
  >
    <UploadFormCard
      formData={formData}
      setFormData={setFormData}
      setLoader={setLoader}
      setIsFormDeleted={setIsFormDeleted}
      setFormModal={setFormModal}
    />
  </ModalDailog>
);

export const PaymentModalPopup = ({
  paymentModal,
  setPaymentModal,
  toggleModal,
  setToggleModal,
  setLoader,
}) => (
  <ModalDailog
    bodyStyle={{ padding: "0px" }}
    isModalOpen={paymentModal}
    handleCancel={() =>
      setPaymentModal((prev) => ({ ...prev, paymentModal: false }))
    }
    title="Select Payment Method"
    closable
    width={483}
    className="payment-modal"
  >
    <PaymentModel
      setPaymentModal={setPaymentModal}
      toggleModal={toggleModal}
      setToggleModal={setToggleModal}
      setLoader={setLoader}
    />
  </ModalDailog>
);

export const PaypalModalPopup = ({
  paypalToggle,
  setPaymentModal,
  setLoader,
  formData,
  setToggleModal,
  errors,
  setErrors,
}) => (
  <ModalDailog
    bodyStyle={{ padding: "0px" }}
    isModalOpen={paypalToggle}
    handleCancel={() =>
      setToggleModal((prev) => ({
        ...prev,
        paypal: { ...prev?.paypal, toggle: false },
      }))
    }
    title="Setup Payment Method"
    closable
    width={520}
    className="paypal-modal"
    centered={false}
  >
    <PayPalModel
      setToggleModal={setToggleModal}
      setPaymentModal={setPaymentModal}
      setLoader={setLoader}
      formData={formData}
      errors={errors}
      setErrors={setErrors}
    />
  </ModalDailog>
);

export const VenmoModalPopup = ({
  venmoToggle,
  setPaymentModal,
  setToggleModal,
  setLoader,
  formData,
  errors,
  setErrors,
}) => (
  <ModalDailog
    bodyStyle={{ padding: "0px" }}
    isModalOpen={venmoToggle}
    handleCancel={() =>
      setToggleModal((prev) => ({
        ...prev,
        venmo: { ...prev?.venmo, toggle: false },
      }))
    }
    title="Setup Payment Method"
    closable
    width={531}
    className="venmo-modal"
    centered={false}
  >
    <VenmoModel
      setToggleModal={setToggleModal}
      setPaymentModal={setPaymentModal}
      setLoader={setLoader}
      formData={formData}
      errors={errors}
      setErrors={setErrors}
    />
  </ModalDailog>
);

export const PayoneerModelPopup = ({
  payoToggle,
  setToggleModal,
  setPaymentModal,
  setLoader,
  formData,
  errors,
  setErrors,
}) => (
  <ModalDailog
    bodyStyle={{ padding: "0px" }}
    isModalOpen={payoToggle}
    handleCancel={() =>
      setToggleModal((prev) => ({
        ...prev,
        payo: { ...prev?.payo, toggle: false },
      }))
    }
    title="Setup Payment Method"
    closable
    width={531}
    className="payoneer-modal"
    centered={false}
  >
    <PayoneerModel
      setToggleModal={setToggleModal}
      setPaymentModal={setPaymentModal}
      setLoader={setLoader}
      formData={formData}
      errors={errors}
      setErrors={setErrors}
    />
  </ModalDailog>
);
export const ReportProblemModal = ({
  reportToggle,
  handleReportClose,
  form,
  callReportList,
}) => (
  <ModalDailog
    isModalOpen={reportToggle?.toggle}
    handleCancel={handleReportClose}
    className="superAdmin-profile-popup"
  >
    <ReportProblem
      form={form}
      type={reportToggle?.type}
      handleCancel={handleReportClose}
      callReportList={callReportList}
    />
  </ModalDailog>
);

export const PastReportsModal = ({
  pastReports,
  setCurrentPage,
  handlePastReportClose,
  currentPage,
}) => (
  <ModalDailog
    isModalOpen={pastReports?.toggle}
    handleCancel={() => {
      setCurrentPage(1);
      handlePastReportClose();
    }}
    className="superAdmin-profile-popup"
  >
    <PastReports
      type={pastReports?.type}
      handleCancel={(callBack) => {
        handlePastReportClose(callBack);
      }}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  </ModalDailog>
);
