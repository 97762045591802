/* eslint-disable no-unused-vars */
import { Button, Input, Modal } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { AchPay, SquarePaymentsForm } from "react-square-web-payments-sdk";

import { NumberFormat } from "../../../utils/numberFormat";
import { payCash } from "../../../utils/svg.file";
import CustomModal from "../../invoicing/details/CustomModal";
import { ACHIcon } from "../../invoicing/svg.icons";
export const PayDepositePopup = ({
  isModalVisible,
  handleCardPaymentCancel,
  handleACHPaymentCancel,
  setIsModalVisible,
  needCheckOthers = true,
  handleCancel = null,
  setShowPaymentOptions = null,
  paymentIntegration = false,
}) => (
  <CustomModal
    isModalOpen={isModalVisible}
    centered
    width={595}
    closable={false}
    {...{ ...(handleCancel && { handleCancel }) }}
  >
    <div className="d-flex gap-3">
      {payCash}
      <div className="flex-fill">
        <h2 className="pay-title">
          How You Would Like To Pay For This Invoice
        </h2>
        <div
          className="w-100 text-content"
          style={{
            borderRadius: 8,
            maxWidth: 392,
            width: "100%",
            color: "#667085",
          }}
        >
          If you have any questions please reach out to our team and we will be
          glad to assist you!
        </div>
      </div>
    </div>
    <div
      className={classNames(
        "pt-4 d-flex justify-content-end gap-2 flex-sm-row flex-column-reverse",
        {
          "": needCheckOthers,
          "text-end": !needCheckOthers,
        },
      )}
    >
      {paymentIntegration && (
        <>
          <Button
            className={classNames("clear-filter mobile-clear", {
              "": !needCheckOthers,
            })}
            onClick={() =>
              handleCardPaymentCancel({
                toggle: false,
                toggleNext: true,
                prevPopup: "options",
              })
            }
            // style={{ width: 132 }}
          >
            Credit Card
          </Button>
          <Button
            className="clear-filter mobile-clear"
            onClick={() => handleACHPaymentCancel({ prevPopup: "options" })}
            // style={{ width: 182 }}
          >
            ACH Bank Transfer
          </Button>
        </>
      )}
      {needCheckOthers && (
        <Button
          className="apply-btn mobile-clear "
          type="primary"
          onClick={() => {
            if (setShowPaymentOptions) setShowPaymentOptions(false);
            setIsModalVisible(true);
          }}
        >
          Check/ Other
        </Button>
      )}
    </div>
  </CustomModal>
);

export const CreditCardPaymentPopup = ({
  showCardPayment,
  handleCardPaymentCancel,
  setIsModalVisible,
  needCheckOthers = true,
  handleCancel = null,
  setShowCardPayment = null,
}) => (
  <CustomModal
    centered
    width={511}
    closable={false}
    isModalOpen={showCardPayment}
    {...{ ...(handleCancel && { handleCancel }) }}
  >
    <div className="d-flex gap-3">
      {payCash}
      <div className="flex-fill">
        <h2 className="pay-title">
          How You Would Like To Pay For This Invoice
        </h2>
        <div
          className="w-100 text-content"
          style={{
            borderRadius: 8,
            maxWidth: 392,
            width: "100%",
            color: "#667085",
          }}
        >
          If you have any questions please reach out to our team and we will be
          glad to assist you!
        </div>
      </div>
    </div>
    <div className="text-end pt-4 gap-2 d-flex align-items-center justify-content-end flex-column flex-sm-row">
      <Button
        className="clear-filter"
        onClick={() =>
          handleCardPaymentCancel({
            toggle: true,
            toggleNext: true,
            prevPopup: "card",
          })
        }
      >
        Credit Card
      </Button>
      {needCheckOthers && (
        <Button
          className="apply-btn"
          type="primary"
          onClick={() => {
            if (setShowCardPayment) setShowCardPayment(false);
            setIsModalVisible(true);
          }}
          // style={{ width: 141 }}
        >
          Check/ Other
        </Button>
      )}
    </div>
  </CustomModal>
);

export const CardPaymentFeePopup = ({
  showCardPaymentFee,
  cardPercentage,
  paymentAmount,
  handleCardPaymentCancel,
  handleConfirmCardPayment,
  handleBackToCard = null,
  handleCancel = null,
}) => (
  <CustomModal
    isModalOpen={showCardPaymentFee}
    centered
    width={542}
    closable={false}
    {...{ ...(handleCancel && { handleCancel }) }}
  >
    <div className="d-flex gap-3">
      {payCash}
      <div className="flex-fill">
        <h2 className="pay-title">Pay With Credit Card</h2>
        <div
          className="w-100 text-content"
          style={{
            borderRadius: 8,
            maxWidth: 405,
            width: "100%",
          }}
        >
          <p>
            <span style={{ color: "#667085", fontWeight: 600 }}>
              Please Note:
            </span>{" "}
            <span
              style={{
                color: "#667085",
              }}
            >
              <span>If you pay by Credit Card there will be a </span>
              <span
                style={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                }}
              >
                {cardPercentage}%
              </span>
              <span>
                processing fee added to the total amount of the invoice
              </span>
              <span
                style={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                }}
              >
                ({NumberFormat(paymentAmount)}).
              </span>
            </span>
          </p>
          <p style={{ marginTop: 15, marginBottom: 0, color: "#667085" }}>
            ACH Bank Transfer payments do not have a fee.
          </p>
        </div>
      </div>
    </div>
    <div className="text-end pt-4 d-flex align-items-center gap-2 justify-content-end flex-column flex-sm-row">
      <Button
        className="clear-filter mobile-width"
        onClick={() =>
          handleBackToCard
            ? handleBackToCard()
            : handleCardPaymentCancel({ toggle: true, toggleNext: false })
        }
        // style={{ width: 132 }}
      >
        Back
      </Button>
      <Button
        className="apply-btn mobile-width"
        type="primary"
        onClick={() => handleConfirmCardPayment()}
        // style={{ width: 141 }}
      >
        Confirm
      </Button>
    </div>
  </CustomModal>
);

export const ACHPaymentPopup = ({
  showACHPayment,
  handleACHPaymentCancel,
  setIsModalVisible,
  needCheckOthers = true,
  handleCancel = null,
  setShowACHPayment = null,
}) => (
  <CustomModal
    centered
    width={511}
    closable={false}
    isModalOpen={showACHPayment}
    {...{ ...(handleCancel && { handleCancel }) }}
  >
    <div className="d-flex gap-3">
      {payCash}
      <div className="flex-fill">
        <h2 className="pay-title">
          How You Would Like To Pay For This Invoice
        </h2>
        <div
          className="w-100 text-content"
          style={{
            borderRadius: 8,
            maxWidth: 395,
            width: "100%",
            color: "#667085",
          }}
        >
          If you have any questions please reach out to our team and we will be
          glad to assist you!
        </div>
      </div>
    </div>
    <div className="text-end pt-4 d-flex align-items-center justify-content-end gap-2 flex-column flex-sm-row">
      <Button
        className="clear-filter"
        onClick={() => handleACHPaymentCancel()}
        // style={{ width: 189 }}
      >
        ACH Bank Transfer
      </Button>
      {needCheckOthers && (
        <Button
          className="apply-btn"
          type="primary"
          onClick={() => {
            if (setShowACHPayment) setShowACHPayment(false);
            setIsModalVisible(true);
          }}
          // style={{ width: 141 }}
        >
          Check/ Other
        </Button>
      )}
    </div>
  </CustomModal>
);

export const PayWithCheckCash = ({
  isModalVisible,
  handleOk,
  handleCancel,
  depositPopupDetail,
  handleClose,
  openPopups,
}) => (
  <CustomModal
    title={false}
    isModalOpen={isModalVisible}
    onOk={handleOk}
    onCancel={handleCancel}
    footer={false}
    className="custom-modal"
    closable={false}
    width={542}
    centered
  >
    <div className="d-flex gap-3">
      {payCash}
      <div className="flex-fill">
        <h2 className="pay-title">To pay with a Check or Cash</h2>
        <div
          className="w-100 text-content"
          style={{
            borderRadius: 8,
            maxWidth: 600,
            minHeight: 150,
            width: "100%",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: (
                depositPopupDetail?.invoiceSettings?.depositPopupTemplate
                  ?.payWithCheckCashNote ||
                depositPopupDetail?.depositPopupTemplate
                  ?.payWithCheckCashNote ||
                depositPopupDetail?.depositPopupTemplate
              )?.replace(/@/g, ""),
            }}
          />
        </div>
      </div>
    </div>
    <div className="text-end pt-4 d-flex align-items-center gap-2 justify-content-end flex-column flex-sm-row">
      {(depositPopupDetail?.invoiceSettings?.paymentIntegration?.stripe
        ?.accountId ||
        depositPopupDetail?.invoiceSettings?.paymentIntegration?.quickbooks
          ?.accountId) && (
        <Button className="clear-filter" onClick={() => openPopups()}>
          Back
        </Button>
      )}
      <Button
        className="apply-btn  mobile-width"
        type="primary"
        onClick={handleClose}
      >
        Confirm
      </Button>
    </div>
  </CustomModal>
);

export const QBBillingDetail = ({
  isModalOpen,
  handleCancel,
  handleBillingEmailChange,
  billingEmail,
  billingError,
  handleQBPayment,
  type,
  loading,
}) => (
  <Modal
    open={isModalOpen}
    onCancel={handleCancel}
    className="custom-modal"
    title=""
    width={400}
    closeIcon={<></>}
    centered
    footer={false}
  >
    <label htmlFor="billingEmail" className="mb-2">
      Billing Email
    </label>
    <Input
      name="billingEmail"
      id="billingEmail"
      value={billingEmail}
      onChange={(e) => handleBillingEmailChange(e)}
      className={`radius-30 `}
      placeholder="Enter billing email"
    />
    <p className="text-danger">{billingError}</p>
    <Button
      className="apply-btn w-100"
      type="primary"
      onClick={() => handleQBPayment({ type })}
      disabled={!billingEmail || billingError || loading}
      loading={loading}
    >
      Next
    </Button>
  </Modal>
);

export const QBAchPaymentModal = ({ isModalOpen, handleCancel }) => (
  <Modal
    open={isModalOpen}
    onCancel={handleCancel}
    className="custom-modal"
    title={false}
    width={542}
    closeIcon={<></>}
    centered
    footer={false}
  >
    QB Ach
  </Modal>
);

export const SquareAchPaymentModal = ({
  isModalOpen,
  handleCancel,
  locationId,
  handleTokenizeResponse,
  handleBack,
  loading,
  setPaymentLoading,
}) => {
  const [accountHolderName, setAccountHolderName] = useState({
    firstName: "",
    lastName: "",
  });
  const [hasError, setHasError] = useState("");
  const handleNameChange = ({ target: { name, value } }) => {
    setAccountHolderName((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!accountHolderName?.firstName) {
      setHasError("Please Provide Account Holder's First Name");
    }
    if (!accountHolderName?.lastName) {
      setHasError("Please Provide Account Holder's Last Name");
    }
    if (!accountHolderName?.firstName && !accountHolderName?.lastName) {
      setHasError("Please Provide Account Holder's First Name And Last Name");
    }
    if (accountHolderName?.firstName && accountHolderName?.lastName) {
      setHasError("");
    }
  }, [accountHolderName]);

  return (
    <Modal
      open={isModalOpen}
      onCancel={() => {
        setAccountHolderName({
          firstName: "",
          lastName: "",
        });
        handleCancel();
      }}
      className="custom-modal"
      title="Bank Account Holder Details"
      width={350}
      closeIcon={<></>}
      centered
      footer={false}
    >
      <Input
        name="firstName"
        value={accountHolderName?.firstName}
        onChange={(e) => handleNameChange(e)}
        placeholder="First Name"
        className="my-2 radius-30"
      />
      <Input
        name="lastName"
        value={accountHolderName?.lastName}
        onChange={(e) => handleNameChange(e)}
        placeholder="Last Name"
        className="my-2 radius-30"
      />
      {hasError && <p className="text-danger">{hasError}</p>}
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <Button className="w-50 clear-filter" onClick={() => handleBack()}>
          Back
        </Button>
        <SquarePaymentsForm
          applicationId={process.env.REACT_APP_SQUARE_ACH_APP_ID}
          locationId={locationId}
          cardTokenizeResponseReceived={handleTokenizeResponse}
        >
          <AchPay
            accountHolderName={`${accountHolderName?.firstName} ${accountHolderName?.lastName}`}
          >
            <Button
              disabled={hasError || loading}
              className="apply-btn"
              type="primary"
              onClick={() => setPaymentLoading(true)}
              loading={loading}
            >
              Pay With Bank
            </Button>
          </AchPay>
        </SquarePaymentsForm>
      </div>
    </Modal>
  );
};
