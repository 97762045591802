import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";

import { increaseViewCount } from "../../../api/formula";
import { createValidUrl } from "../../../utils/commonFuctions";
import { DeleteIcon, plus } from "../../../utils/svg.file";

function Services({
  service,
  isAdded,
  editService,
  customerAddedServices,
  clearInput,
}) {
  const [validImage, setValidImage] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const handleIncreaseViewCount = async (id) => {
    await increaseViewCount(id);
  };
  const isValidImage = (url) => {
    if (!url) return false;
    const s = document.createElement("IMG");
    s.src = createValidUrl(url);
    s.onerror = () => {
      setValidImage(false);
      return false;
    };
    s.onload = () => {
      setValidImage(true);
      return true;
    };
    return false;
  };
  useEffect(() => {
    isValidImage(service?.photo);
  }, [service?.photo]);
  return (
    <div className="list-service-box">
      <div className="services-box">
        {validImage ? (
          <img src={createValidUrl(service?.photo)} alt="service-img" />
        ) : (
          <img
            src="https://onepercent-pro.s3.amazonaws.com/defaultImage/default_service_image.jpeg"
            alt="service-img"
          />
        )}
        <div className="px-3">
          <Tooltip placement="top" title={service.title || service.displayName}>
            <span>{service.title || service.displayName}</span>
          </Tooltip>
        </div>
      </div>
      {isAdded ? (
        !customerAddedServices ? (
          <CheckCircleOutlined style={{ color: "#02a302" }} />
        ) : (
          <span
            onClick={() => {
              setDeleting(true);
            }}
          >
            {DeleteIcon}
          </span>
        )
      ) : (
        <span
          className="closeicon-panel"
          onClick={() => {
            editService(service);
            clearInput();
            handleIncreaseViewCount(service._id);
          }}
        >
          {plus}
        </span>
      )}
      <Modal
        className="modal-radius warning-modal"
        title="Warning!"
        visible={deleting}
        footer={null}
        closeIcon={<InfoCircleOutlined />}
        width={350}
      >
        <p className="text-center my-3">
          Are you sure, you want to delete service ?
        </p>
        <Row>
          <Col md={12} className="text-center">
            <Button
              type="text"
              onClick={() => {
                setDeleting(false);
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col md={12}>
            <Button
              type="link"
              onClick={() => {
                editService(service);
                setDeleting(false);
                clearInput();
              }}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default Services;
