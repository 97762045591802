import { Button, Col, Form, Input, Row } from "antd";

function AddTab({
  handleCancel,
  handleTabNameChange,
  tabName,
  handleTabNameSubmit,
  tabNameError,
}) {
  return (
    <div className="add-promo-code">
      <h3 className="fliter-sort-title-add-tab">New Tab</h3>
      <Form layout="vertical" onSubmitCapture={handleTabNameSubmit}>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Form.Item>
              <Input
                size="large"
                placeholder="Tab Name"
                name="tabName"
                value={tabName}
                onChange={handleTabNameChange}
                style={{ borderRadius: "8px" }}
              />
              <div role="alert" className="text-danger">
                {tabNameError?.length > 0 && tabNameError}
              </div>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Button
              onClick={handleCancel}
              style={{ width: "100%", borderRadius: "8px" }}
              size="large"
            >
              Cancel
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              style={{ width: "100%", borderRadius: "8px" }}
              size="large"
              htmlType="submit"
              type="submit"
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export default AddTab;
