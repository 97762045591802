import { Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteIdAction } from "../../../../../api/admin";
import api from "../../../../../api/api";
import { useLoader } from "../../../../../common/hooks";
import {
  getDocNPayDataAction,
  getMyReferralsAction,
  getReferralsListAction,
  getReferralsOverviewAction,
  getSummaryDetailsAction,
  profileFormChangeAction,
  saveReferralFilters,
  setFormFieldValues,
  updateProfileData,
} from "../../../../../redux/affiliate/affiliate.action";
import { AFFILIATE_TYPES } from "../../../../../redux/affiliate/affiliate.types";
import { createValidUrl } from "../../../../../utils/commonFuctions";
import { contactFormat } from "../../../../../utils/javascript";

export const profileInfoContainer = (isSuperAdmin) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { profile, formData } = useSelector((state) => state?.affiliate);
  const { loading } = useLoader();
  const [updateEmail, setUpdateEmail] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [googleKey, setGoogleKey] = useState();
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [error, setError] = useState();
  const token = localStorage.getItem("affiliateToken");
  const handleFieldsChange = async (data) => {
    const head = data[0];
    const payload = { ...formData, [head?.name[0]]: head?.value };
    dispatch(setFormFieldValues({ ...payload }));
    dispatch(profileFormChangeAction(true));
  };
  const handleCancel = () => {
    setUpdateEmail(false);
    setUpdatePassword(false);
  };

  const validationRegex = {
    instagram: /^[a-zA-Z0-9._]{1,30}$/,
    facebook: /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9_.-]+\/?$/,
    youtube:
      /^(https?:\/\/)?(www\.)?youtube\.com\/(c\/|channel\/|user\/)?[a-zA-Z0-9_-]+\/?$/,
    tiktok: /^[a-zA-Z0-9_.]{2,24}$/,
    web: /^(https?:\/\/)?([a-zA-Z0-9-]+\.?)+([a-zA-Z]{2,})?(:\d{1,5})?(\/[^\s]*)?$/,
  };

  const errorMessage = {
    instagram: "Instagram username is not valid",
    facebook: "Facebook link is not valid",
    youtube: "Youtube link is not valid",
    tiktok: "Tik tok username is not valid",
    web: "Website url is not valid",
  };

  const socialMediaValidation = (name, value) =>
    validationRegex?.[name]?.test(value);

  const handleMediaChange = (name, { target: { value } }) => {
    console.log("value --> ", socialMediaValidation(name, value));
    const payload = [
      ...(formData?.socialMediaLink?.length
        ? formData?.socialMediaLink
        : socialMediaLinks),
    ];
    const index = payload.findIndex((obj) => obj?.mediaName === name);
    if (~index) {
      payload[index] = {
        ...payload[index],
        link: value,
      };
    } else {
      payload.push({ mediaName: name, link: value });
    }
    setSocialMediaLinks([...payload]);
    setError((prevState) => ({
      ...prevState,
      [name]: value
        ? socialMediaValidation(name, value)
          ? ""
          : errorMessage[name]
        : "",
    }));
  };
  const getGoogleKey = async () => {
    const response = await api.request({
      url: "/api-integration/affiliate-google-client-id",
      method: "GET",
      token,
    });
    if (response.remote === "success") {
      setGoogleKey(response.data.google.clientId);
    }
  };

  const handleAddrChange = (data) => {
    dispatch(setFormFieldValues({ address: data }));
    dispatch(updateProfileData({ address: data }));
    dispatch(profileFormChangeAction(true));
  };

  const handlePhoneChange = ({ target: { value } }) => {
    form.setFieldValue("contactNo", contactFormat(value));
  };

  const getSocialMediaLinks = (name) =>
    formData?.socialMediaLink?.find((obj) => obj.mediaName === name)?.link;

  useEffect(() => {
    if (profile) {
      const fields = Object.keys(form.getFieldsValue());
      const pay = {};
      fields.forEach((key) => {
        pay[key] = isSuperAdmin
          ? key === "contactNo"
            ? contactFormat(profile[key])
            : profile[key]
          : profile[key] || form.getFieldValue(key);
      });
      pay.password = profile?.password || "*************";
      form.setFieldsValue(pay);
    }
  }, [loading, profile]);

  useEffect(() => {
    if (!isSuperAdmin) getGoogleKey();
  }, []);

  useEffect(() => {
    if (socialMediaLinks?.length) {
      dispatch(
        setFormFieldValues({
          ...formData,
          socialMediaLink: [...socialMediaLinks],
        }),
      );
      dispatch(profileFormChangeAction(true));
    }
  }, [socialMediaLinks]);

  return {
    form,
    loading,
    updateEmail,
    updatePassword,
    googleKey,
    profile,
    formData,
    setUpdateEmail,
    setUpdatePassword,
    handleFieldsChange,
    handleCancel,
    handleMediaChange,
    handleAddrChange,
    handlePhoneChange,
    getSocialMediaLinks,
    error,
  };
};

export const docNPayContainer = (isSuperAdmin = false) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formModal, setFormModal] = useState({
    w9FormModal: false,
    idCardModal: false,
    paymentModal: false,
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const [formData, setFormData] = useState({
    idCard: null,
    w9Form: null,
  });
  const [toggleModal, setToggleModal] = useState({
    paypal: {
      toggle: false,
      email: "",
      notes: "",
    },
    venmo: {
      toggle: false,
      userName: "",
      phone: "",
      notes: "",
    },
    payo: {
      toggle: false,
      name: "",
      email: "",
      customerId: "",
      notes: "",
    },
  });
  const [errors, setErrors] = useState({});
  const [isCardDeleted, setIsCardDeleted] = useState(false);
  const [isFormDeleted, setIsFormDeleted] = useState(false);
  const documentData = useSelector((state) => state?.affiliate?.docNPay);
  const affiliateId = useSelector((state) => state?.affiliate?.profile?._id);

  const download = async (fileUrl, fileName) => {
    try {
      const response = await fetch(createValidUrl(fileUrl));

      if (!response.ok) {
        throw new Error("Failed to fetch image");
      }

      const blob = await response.blob();

      const mimeType = response.headers.get("content-type");
      const blobWithMIME = new Blob([blob], { type: mimeType });

      const objectURL = URL.createObjectURL(blobWithMIME);

      const anchor = document.createElement("a");
      anchor.href = objectURL;
      anchor.download = fileName;

      anchor.click();

      URL.revokeObjectURL(objectURL);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const handleDeleteId = async (card) => {
    setLoading(true);
    const payload = { documentName: card };
    const result = await deleteIdAction(payload);
    if (result?.remote === "success") {
      setLoading(false);
      if (card === "idCard") {
        dispatch({
          type: AFFILIATE_TYPES.DOCUMENT_DETAIL,
          payload: { ...documentData, idCardImageName: "" },
        });
      } else {
        dispatch({
          type: AFFILIATE_TYPES.DOCUMENT_DETAIL,
          payload: { ...documentData, w9FormName: "" },
        });
      }
      setFormData((prev) => ({ ...prev, [card]: null }));
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getDocNPayDataAction(setLoading, isSuperAdmin, affiliateId));
  }, []);

  useEffect(() => {
    if (documentData?.idCardImageName?.length === 0 && !isSuperAdmin) {
      setIsCardDeleted(true);
    }
    if (documentData?.w9FormName?.length === 0) {
      setIsFormDeleted(true);
    }
  }, [documentData]);

  return {
    errors,
    loading,
    formData,
    formModal,
    toggleModal,
    documentData,
    confirmModal,
    isCardDeleted,
    isFormDeleted,
    download,
    setErrors,
    setLoading,
    setFormData,
    setFormModal,
    handleDeleteId,
    setToggleModal,
    setConfirmModal,
    setIsCardDeleted,
    setIsFormDeleted,
  };
};

export const myReferralContainer = (isSuperAdmin = false) => {
  const dispatch = useDispatch();
  const referral = useSelector((state) => state.affiliate.referrals);
  const affiliateId = useSelector((state) => state?.affiliate?.profile?._id);
  const savedData = JSON.parse(localStorage.getItem("referral-filter"))?.[
    affiliateId
  ];
  const [isVisibleLinkModal, setVisibleLinkModal] = useState(false);
  const [isVisibleCodeModal, setVisibleCodeModal] = useState(false);

  const handleLinkShareModalVisibility = () =>
    setVisibleLinkModal(!isVisibleLinkModal);

  const handlePromoCodeModalVisibility = () =>
    setVisibleCodeModal(!isVisibleCodeModal);

  useEffect(() => {
    if (savedData) {
      dispatch(saveReferralFilters(savedData));
    }
  }, []);

  useEffect(() => {
    const payload = {
      page: 1,
      perPage: 10,
      ...(isSuperAdmin && { affiliate: affiliateId }),
      ...(savedData && { ...savedData }),
    };
    dispatch(getReferralsListAction(affiliateId, isSuperAdmin));
    dispatch(getSummaryDetailsAction(affiliateId, isSuperAdmin));
    dispatch(getReferralsOverviewAction(affiliateId, isSuperAdmin));
    dispatch(getMyReferralsAction(payload, isSuperAdmin));
  }, []);
  return {
    referral,
    isVisibleLinkModal,
    isVisibleCodeModal,
    handleLinkShareModalVisibility,
    handlePromoCodeModalVisibility,
  };
};
