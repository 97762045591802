import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Button, Card, Form, message } from "antd";
import classNames from "classnames";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch, useSelector } from "react-redux";

import {
  copyTermsAndCondition,
  updateOrganizationTermAndCondition,
} from "../../../api/organization";
import { getOrganization } from "../../../redux/organization/organization.action";
import { USER_ROLE } from "../../../utils/constants/constants";
import { mentions } from "../../../utils/suggestions";
import BreadcrumbBar from "../../breadcrumb/Breadcrumb.pages";
import DisclaimerPopup from "../../disclaimerPopup";

export default function TermsAndConditions({
  needBreadcrumb = true,
  needPreview = true,
  className = "",
  handleClosePopup = null,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { userRole } = user;
  const [previewHtml, setPreviewHtml] = useState();
  const [openDisclaimerPopup, setOpenDisclaimerPopup] = useState(false);
  const mentionsVariables = [
    { key: "companyName", value: user?.companyName?.bold() },
  ];
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(),
  );
  const [loading, setLoading] = useState(false);
  const [termAndConditionCopying, setTermAndConditionCopying] = useState(false);
  const [html, setHtml] = useState("");
  const replaceVariables = (html) => {
    if (html) {
      let newHtml = html;
      mentions.forEach((item) => {
        mentionsVariables?.forEach((data) => {
          if (item.field === data.key) {
            newHtml = newHtml.replaceAll(item.value, data.value);
          }
        });
      });
      setPreviewHtml(newHtml);
    }
  };

  const _copyTermsAndCondition = async () => {
    setTermAndConditionCopying(true);
    const key = "updatable";
    const hide = message.loading({
      content: "Term And Condition Template Copy In Process",
      key,
      duration: 0,
    });
    const response = await copyTermsAndCondition();
    if (response.remote === "success") {
      dispatch({
        type: "user/userLoaded",
        payload: {
          ...user,
          organization: {
            ...user.organization,
            termAndCondition: response.data.termAndCondition,
          },
        },
      });
      setHtml(response.data.termAndCondition);
      hide();
      message.success({
        content: "Copied Successfully",
        key,
        duration: 2,
      });

      setTermAndConditionCopying(false);
    } else {
      hide();
      message.error({
        content: "Something Went Wrong",
        key,
        duration: 2,
      });

      setTermAndConditionCopying(false);
    }
    setOpenDisclaimerPopup(false);
  };
  useEffect(() => {
    replaceVariables(html);
  }, [html]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    if (user && user.organization && html.includes("@{{")) {
      html = html.replace(/<\/?a[^>]*>/g, "");
      html = html.replaceAll("@{{", "{{");
    }
    setHtml(html);
  };

  const updateTermAndCondition = async () => {
    if (user && user.organization) {
      setLoading(true);
      if (html === "") {
        message.error("content cannot be empty");
        setLoading(false);
      }
      const newFData = new FormData();
      newFData.append("termAndCondition", html);
      const response = await updateOrganizationTermAndCondition(newFData);
      if (response.remote === "success") {
        message.success("Updated Successfully", 2);
        dispatch(getOrganization());
        if (handleClosePopup) {
          handleClosePopup();
        }
        setLoading(false);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && user.organization) {
      const contentBlock = htmlToDraft(
        user.organization.termAndCondition.replaceAll("{{", "@{{"),
      );
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      setHtml(user.organization.termAndCondition);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [user]);

  return (
    <>
      {needBreadcrumb && (
        <BreadcrumbBar
          name="Dashboard"
          subname="Terms And Condition"
          breaclass="mb-3"
          link="/"
        />
      )}
      <Card bordered={false} className="termsconditions radius-12">
        <h5>Terms & conditions</h5>
        {userRole?.roleName === USER_ROLE.superAdmin ? (
          <> </>
        ) : (
          <>
            <Form layout="vertical">
              <Form.Item>
                <span
                  onClick={() => setOpenDisclaimerPopup(true)}
                  style={{
                    color: "blue",
                    textDecoration: "underline", // Add underline style
                    margin: 0,
                    cursor: "pointer",
                  }}
                >
                  {termAndConditionCopying ? "Processing..." : "Click "}
                </span>
                here to copy Term And Condition Template
              </Form.Item>
            </Form>
          </>
        )}
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          wrapperClassName="wrapper-class"
          editorClassName={classNames("editor-class", {
            [className]: className,
          })}
          toolbarClassName="toolbar-class"
          mention={{
            trigger: "@",
            separator: " ",
            suggestions: mentions,
          }}
        />
        <Button
          onClick={updateTermAndCondition}
          type="primary"
          size="large"
          className="my-3 radius-4"
          shape="round"
          disabled={loading}
        >
          Update terms and condition
        </Button>
        {needPreview && (
          <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
        )}
        <DisclaimerPopup
          visible={openDisclaimerPopup}
          onCancel={() => setOpenDisclaimerPopup(false)}
          onClick={_copyTermsAndCondition}
          loading={termAndConditionCopying}
          disclaimer="By copying the default Terms and Conditions provided by ZevBit you
            understand that the default Terms and Conditions will need to be
            reviewed and edited by you to be suited to your local laws and
            regulations. ZevBit is not responsible for any errors stemming from
            the misuse of our default Terms and Conditions."
          buttonTitle="Accept & Copy"
        />
      </Card>
    </>
  );
}
