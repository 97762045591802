import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Space } from "antd";
import React, { useEffect, useState } from "react";

import { getCodeUserData } from "../../../../api/admin";
import { downloadcloud } from "../../../../utils/svg.file";

const initialFilter = {
  startDate: "",
  endDate: "",
};

function CodeUsers({ selectedCode }) {
  const [, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [filter] = useState(initialFilter);
  const [, setUserListData] = useState();
  const [currentPage] = useState(1);
  const [dateValue, setDateValue] = useState([]);
  const { RangePicker } = DatePicker;
  const perPageUserData = 8;
  const getCodeUserList = async () => {
    setLoader(true);
    const result = await getCodeUserData(
      selectedCode?._id,
      search,
      filter?.startDate,
      filter?.endDate,
      currentPage,
      perPageUserData,
    );
    if (result?.remote === "success") {
      setUserListData(result?.data?.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    getCodeUserList();
  }, []);

  const renderExtraFooter = () => (
    <Button onClick={() => setDateValue([])}>Clear</Button>
  );

  return (
    <>
      {selectedCode?.couponCode}
      <div>
        {selectedCode?.usedQuantity}/{selectedCode?.quantityOfPromoCode}
      </div>
      <div>{selectedCode?.name}</div>
      <Space size={[16, 16]}>
        <Input
          style={{ width: "100%" }}
          size="large"
          className="btn-filter"
          placeholder="Search"
          prefix={<SearchOutlined style={{ fontSize: "18px" }} />}
          name="searchPromo"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button size="large" className="btn-filter">
          {downloadcloud} <span className="ms-1">Export</span>
        </Button>
        <RangePicker
          format="MM/DD/YYYY"
          onCalendarChange={(val) => setDateValue(val)}
          value={dateValue}
          renderExtraFooter={renderExtraFooter}
        />
      </Space>
      <RangePicker />
    </>
  );
}

export default CodeUsers;
