import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";

import CustomButton from "../../../../globalComponents/button";
import { saveicon } from "../../../../utils/svg.file";
import FormulaV2 from "../../../formula/v2/formula.v2.component";
import AttachmentsFile from "../attachment-file";
import ClientContract from "../client-contract";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EstimateTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", px: 2 }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Tabs
            className="custom-tabs"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Formulas" {...a11yProps(0)} />
          </Tabs>

          <Stack direction="row" spacing={2} className="btn-estimates">
            <CustomButton className="px-3">
              <span className="me-2">{saveicon}</span>
              <span>Update</span>
            </CustomButton>
          </Stack>
        </Stack>
      </Box>
      <div className="estimates-bg-gray">
        <TabPanel value={value} index={0}>
          <FormulaV2 />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ClientContract />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AttachmentsFile />
        </TabPanel>
      </div>
    </Box>
  );
};
export default EstimateTabs;
