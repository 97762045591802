import { EditOutlined } from "@ant-design/icons";
import { Button, message, Select, Switch } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  getEmailTemplatesDetails,
  getInvoicesSettingDetails,
  payment,
  removeStripDetailsAPI,
  stripeUrlDetail,
  updateInvoicesSettingDetails,
} from "../../../api/invoice";
import { DATE_FORMATE } from "../../../utils/constants/constants";
import { NumberFormat } from "../../../utils/numberFormat";
import { timeClockIcon } from "../../../utils/svg.file";
import { renderStatus } from "../helper";

export const DEFAULT_TERM = [
  {
    value: 0,
    label: "Due As Receipt",
  },
  {
    value: 3,
    label: "Net 3",
  },
  {
    value: 7,
    label: "Net 7",
  },
  {
    value: 15,
    label: "Net 15",
  },
  {
    value: 30,
    label: "Net 30",
  },
  {
    value: 90,
    label: "Net 90",
  },
];

export const EmailColumn = [
  {
    title: "Name",
    dataIndex: "invoiceName",
    width: 475,
    render: (invoiceName) => <span>{invoiceName}</span>,
  },
  {
    title: "Invoice #",
    dataIndex: "invoiceNumber",
    className: "text-nowrap",
    render: (invoiceNumber) => <span>{invoiceNumber}</span>,
  },
  {
    title: "Status",
    dataIndex: "invoiceStatus",
    className: "text-nowrap",
    render: (status) => (status ? renderStatus(status) : ""),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    className: "text-nowrap",
    render: (amount) => (amount ? NumberFormat(amount) : ""),
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    className: "text-nowrap",
    render: (date) => (date ? moment(date).format(DATE_FORMATE) : ""),
  },
  {
    title: "Amount Paid",
    dataIndex: "amount_paid",
  },
  {
    title: "Date Paid",
    dataIndex: "date_paid",
    className: "text-nowrap",
  },
  {
    title: "Balance",
    dataIndex: "balance",
    className: "text-nowrap",
  },
];
export const RowData = [
  {
    name: "-",
    invoice: "-",
    status: (
      <Button className="due d-flex align-items-center gap-1">
        {timeClockIcon} <span>Due</span>
      </Button>
    ),
    ammount: "-",
    due_date: "-",
    amount_paid: "-",
    date_paid: "-",
    balance: "-",
  },
];
const handleChange = (value) => {
  console.log(`selected ${value}`);
};
export const EmailTemplateColumnData = [
  {
    title: "Template Name",
    dataIndex: "template_name",
  },
  {
    title: "Category",
    dataIndex: "category",
    render: () => (
      <Select
        defaultValue="Estimate"
        style={{ width: 250, background: "#F2F4F7" }}
        onChange={handleChange}
        options={[
          {
            value: "Estimate",
            label: "Estimate",
          },
          {
            value: "Signed Estimate",
            label: "Signed Estimate",
          },
          {
            value: "Due Invoice",
            label: "Due Invoice",
          },
          {
            value: "Overdue Invoice",
            label: "Overdue Invoice",
          },
          {
            value: "Not Due Invoice",
            label: "Not Due Invoice",
          },
          {
            value: "Recorded Payment Receipt",
            label: "Recorded Payment Receipt",
          },
        ]}
      />
    ),
  },
  {
    title: "Default",
    dataIndex: "default",
    render: () => <Switch defaultChecked />,
  },
  {
    title: "Action",
    dataIndex: "action",
    render: () => (
      <Link to="/edit-email-template">
        <EditOutlined style={{ cursor: "pointer", color: "#667085" }} />
      </Link>
    ),
  },
];

export const EmailTemplateRowData = [
  {
    template_name: "Sending Estimate",
    category: "category",
    default: "default",
    action: "Action",
  },
  {
    template_name: "Signed Estimate Notification",
    category: "category",
    default: "default",
    action: "Action",
  },
  {
    template_name: "Sending Due Invoice",
    category: "category",
    default: "default",
    action: "Action",
  },
  {
    template_name: "Recorded Payment Receipt",
    category: "category",
    default: "default",
    action: "Action",
  },
  {
    template_name: "QB/Stripe Payment Receipt",
    category: "category",
    default: "default",
    action: "Action",
  },
];

export const handleEmailOpen = (setState) => {
  setState((prev) => ({ ...prev, emailOpen: true }));
};
export const handlequickBooks = (setState) => {
  setState((prev) => ({ ...prev, quickBooks: true }));
};
export const handlePaymentIntegration = (setState) => {
  setState((prev) => ({ ...prev, paymentIntegration: true }));
};
export const handleCancel = (setState) => {
  setState((prev) => ({ ...prev, emailOpen: false }));
  setState((prev) => ({ ...prev, quickBooks: false }));
  setState((prev) => ({ ...prev, paymentIntegration: false }));
};

export const getInvoicesSetting = async (setState) => {
  const res = await getInvoicesSettingDetails();
  if (res.remote === "success") {
    setState((prev) => ({ ...prev, invoiceData: res.data?.data }));
  } else {
    toast.error(res.error?.errors?.error);
  }
};
export const paymentHandler = async (payload, setState) => {
  const res = await payment(payload);
  if (res.remote === "success") {
    setState((prev) => ({
      ...prev,
      invoiceData: {
        ...prev.invoiceData,
        paymentIntegration: {
          ...prev.invoiceData.paymentIntegration,
          [payload.integrationType]: {
            isSync: true,
          },
        },
      },
    }));
  } else {
    toast.error(res.error?.errors?.error);
  }
  return res;
};
export const generateStripeUrl = async (setState, query) => {
  const res = await stripeUrlDetail(query);
  if (res.remote === "success") {
    setState(res.data?.data);
  } else {
    toast.error(res.error?.errors?.error);
  }
};
export const removeStripeUrl = async (setState) => {
  const res = await removeStripDetailsAPI();
  if (res.remote === "success") {
    setState();
  } else {
    toast.error(res.error?.errors?.error);
  }
  return res;
};
export const getEmailTemplates = async (setState) => {
  const res = await getEmailTemplatesDetails();
  if (res.remote === "success") {
    setState(res.data?.data);
  } else {
    toast.error(res.error?.errors?.error);
  }
};

export const setInvoicesSetting = async (data) => {
  message.destroy();
  const hideLoadingMessage = message.loading("Processing...", 0);

  try {
    const res = await updateInvoicesSettingDetails(data);
    hideLoadingMessage();
    if (res.remote === "success") {
      message.success("Settings are Updated");
    } else {
      message.error(res.error?.errors?.error || "Something went wrong");
    }
  } catch (error) {
    hideLoadingMessage();
    message.error("Something went wrong");
  }
};

export const handleSavePhoneNumber = (
  phoneNumber,
  setUpdatedData,
  setState,
  setError,
) => {
  if (phoneNumber?.length < 11) {
    setError("Please enter valid phone number");
  } else {
    setError("");
    setUpdatedData((prev) => ({
      ...prev,
      billingInfo: {
        ...prev.billingInfo,
        phoneNumber,
      },
    }));
    setState((prev) => ({
      ...prev,
      invoiceData: {
        ...prev.invoiceData,
        billingInfo: {
          ...prev.invoiceData.billingInfo,
          phoneNumber,
        },
      },
    }));
  }
};

export const handleSaveEmail = (email, setUpdatedData, setState) => {
  setUpdatedData((prev) => ({
    ...prev,
    billingInfo: {
      ...prev.billingInfo,
      email,
    },
  }));
  setState((prev) => ({
    ...prev,
    invoiceData: {
      ...prev.invoiceData,
      billingInfo: {
        ...prev.invoiceData.billingInfo,
        email,
      },
    },
  }));
};

export const handleSavedaysAfterClientSigns = (
  days,
  setUpdatedData,
  setState,
) => {
  setUpdatedData((prev) => ({
    ...prev,
    autoAssignFirstInvoiceDate: {
      ...prev.autoAssignFirstInvoiceDate,
      daysAfterClientSign: days,
    },
  }));
  setState((prev) => ({
    ...prev,
    invoiceData: {
      ...prev.invoiceData,
      autoAssignFirstInvoiceDate: {
        ...prev.invoiceData.autoAssignFirstInvoiceDate,
        daysAfterClientSign: days,
      },
    },
  }));
};

export const handleSaveremindersDays = (days, setUpdatedData, setState) => {
  setUpdatedData((prev) => ({
    ...prev,
    sendOverdueInvoiceReminder: {
      ...prev.sendOverdueInvoiceReminder,
      recurringReminderDays: days,
    },
  }));
  setState((prev) => ({
    ...prev,
    invoiceData: {
      ...prev.invoiceData,
      sendOverdueInvoiceReminder: {
        ...prev.invoiceData?.sendOverdueInvoiceReminder,
        recurringReminderDays: days,
      },
    },
  }));
};
