import { Card, Image, Row } from "antd";
import React from "react";

import logo from "../../images/logo-signup.png";
import ScheduleDemo from "./common/ScheduleDemo";

const Header = ({ promoCode, needButton = true }) => (
  <Card style={{ border: "none" }}>
    <Row
      justify="space-between"
      id="header-section"
      {...{ ...(!needButton && { style: { justifyContent: "center" } }) }}
    >
      <Image
        src={logo}
        preview={false}
        style={{
          width: "225px",
          height: "52.95px",
          marginBottom: "15px",
        }}
      />
      {needButton && <ScheduleDemo type="primary" promoCode={promoCode} />}
    </Row>
  </Card>
);

export default Header;
