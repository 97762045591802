import AttachmentIcon from "@mui/icons-material/Attachment";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ContentCut from "@mui/icons-material/ContentCut";
import ContentPaste from "@mui/icons-material/ContentPaste";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DnsIcon from "@mui/icons-material/Dns";
import EditIcon from "@mui/icons-material/Edit";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";

import Avatar0 from "../../../../../images/attached-avatar.png";
import { edit } from "../../../../../utils/svg.file";

function TextArea(props) {
  return <div contentEditable>{props.placeholder}</div>;
}
function UnitCost() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        variant="contained"
        className="unit-button"
      >
        Hrs
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleClose}>Kilometers</MenuItem>
        <MenuItem onClick={handleClose}>Liters</MenuItem>
        <MenuItem onClick={handleClose}>Centimeters</MenuItem>
        <MenuItem onClick={handleClose}>Metric ton</MenuItem>
        <MenuItem onClick={handleClose}>Liters</MenuItem>
      </Menu>
    </>
  );
}

function ChargeManual() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return <Checkbox {...label} />;
}
function ActionDot() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        id="demo-positioned-button1"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DnsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cut</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <ContentPaste fontSize="small" />
          </ListItemIcon>
          <ListItemText>Paste</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <FolderOpenOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Save as template</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose} sx={{ color: "#D92D20" }}>
          <ListItemIcon sx={{ color: "#D92D20" }}>
            <DeleteForeverOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
export const ESTIMATES_COLUMNS_DATA = [
  {
    id: "1",
    name: "Name material",
    key: "materialname",
    className: "text-nowrap",
  },
  {
    id: "2",
    name: "Enter Quantity",
    key: "enterquantity",
    width: "350px",
  },
  {
    id: "3",
    name: "Unit",
    key: "unit",
  },
  {
    id: "4",
    name: "Cost",
    key: "cost",
  },
  {
    id: "5",
    name: "Charge (Checked for manual )",
    key: "charge",
  },
  {
    id: "6",
    name: "",
    key: "action",
    width: "50px",
  },
];

export const ESTIMATES_COLUMNS_ROW_DATA = [
  {
    materialname: "Labor (Excavation)",
    enterquantity: (
      <TextArea placeholder="{Quantity} * {Catalog: 'Labor Cost'}" />
    ),
    unit: <UnitCost />,
    cost: <TextArea placeholder="{Quantity} * {Catalog: 'Labor Cost'}" />,
    charge: (
      <Stack direction="row" spacing={2} alignItems="center">
        <ChargeManual />
        <TextArea placeholder="{Quantity} * {Catalog: 'Labor Charge per Hours'}     " />
      </Stack>
    ),
    action: <ActionDot />,
  },
  {
    materialname: "Labor (Pool)",
    enterquantity: (
      <TextArea placeholder="{Quantity} * {Catalog: 'Labor Cost'}" />
    ),
    unit: <UnitCost />,
    cost: <TextArea placeholder="{Quantity} * {Catalog: 'Labor Cost'}" />,
    charge: (
      <Stack direction="row" spacing={2} alignItems="center">
        <ChargeManual />
        <TextArea placeholder="{Quantity} * {Catalog: 'Labor Charge per Hours'}     " />
      </Stack>
    ),
    action: <ActionDot />,
  },
  {
    materialname: "Mobilization Hours",
    enterquantity: (
      <TextArea placeholder="{Quantity} * {Catalog: 'Labor Cost'}" />
    ),
    unit: <UnitCost />,
    cost: <TextArea placeholder="{Quantity} * {Catalog: 'Labor Cost'}" />,
    charge: (
      <Stack direction="row" spacing={2} alignItems="center">
        <ChargeManual />
        <TextArea placeholder="{Quantity} * {Catalog: 'Labor Charge per Hours'}     " />
      </Stack>
    ),
    action: <ActionDot />,
  },
];

export const TASK_TEMPLETE_COLUMNS_DATA = [
  {
    id: "1",
    name: "",
    key: "drag",
    width: "40px",
  },
  {
    id: "2",
    name: "Task Name",
    key: "taskname",
  },
  {
    id: "3",
    name: "Description",
    key: "description",
    width: "500px",
  },
  {
    id: "4",
    name: "Source",
    key: "Source",
    width: "80px",
  },
  {
    id: "5",
    name: "Task Type",
    key: "tasktype",
    width: "100px",
  },
  {
    id: "6",
    name: "Est Hrs",
    key: "esthrs",
  },
  {
    id: "7",
    name: "",
    key: "action",
  },
];

// rows data
function SourceView(props) {
  return (
    <Chip
      label={props.name}
      style={{
        background: props.bgcolor,
        color: props.textcolor,
        fontWeight: "500",
      }}
    />
  );
}

function TaskMenu() {
  const [taskdropmenu, setTaskdropmenu] = React.useState(null);

  const handleClickTaskMenu = (event) => {
    setTaskdropmenu(event.currentTarget);
  };

  const handelCloseMenu = () => {
    setTaskdropmenu(null);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <>
      <IconButton onClick={handleClickTaskMenu} sx={{ color: "#98A2B3" }}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={taskdropmenu}
        open={taskdropmenu}
        onClose={handelCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handelCloseMenu}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
          <Typography component="div">
            <Switch {...label} size="small" />
          </Typography>
        </MenuItem>
        <MenuItem onClick={handelCloseMenu}>
          <ListItemIcon>{edit}</ListItemIcon>
          <ListItemText>Task</ListItemText>
          <Typography component="div">
            <Switch {...label} size="small" />
          </Typography>
        </MenuItem>

        <Divider />
        <MenuItem onClick={handelCloseMenu} sx={{ color: "#D92D20" }}>
          <ListItemIcon sx={{ color: "#D92D20" }}>
            <DeleteForeverOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export const TASK_TEMPLETE_COLUMNS_ROW_DATA = [1, 2, 3, 4, 5].map(() => ({
  drag: (
    <IconButton size="small" sx={{ color: "#98A2B3" }}>
      <MenuIcon />
    </IconButton>
  ),
  taskname: "Labor (Excavation)",
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  Source: <SourceView name="Auto" bgcolor="#F2F4F7" textcolor="#344054" />,
  tasktype: <SourceView name="Crew" bgcolor="#EFF8FF" textcolor="#175CD3" />,
  esthrs: "N/A",
  action: <TaskMenu />,
}));

export const ATTACHMENT_COLUMNS_DATA = [
  {
    id: "1",
    name: "Name",
    key: "name",
    width: "900px",
  },
  {
    id: "2",
    name: "Type",
    key: "type",
  },
  {
    id: "3",
    name: "",
    key: "action",
  },
];

function AvatarView() {
  return <Avatar src={Avatar0} alt="" variant="rounded" />;
}

function TypeClip(props) {
  return (
    <Chip
      label={props.label}
      style={{ background: props.bgcolor, color: props.textcolor }}
    />
  );
}

export const ATTACHMENT_ROW_DATA = [
  {
    name: (
      <Stack direction="row" spacing={2} alignItems="center">
        <AvatarView /> <span>Dashboard protoype FINAL.jpeg</span>
      </Stack>
    ),
    type: <TypeClip bgcolor="#EEF4FF" textcolor="#3538CD" label="Attachment" />,
    action: (
      <IconButton size="small">
        <DeleteForeverOutlinedIcon />
      </IconButton>
    ),
  },
  {
    name: (
      <Stack direction="row" spacing={2} alignItems="center">
        <Avatar
          variant="rounded"
          sx={{ background: "#EFF8FF", color: "#1570EF" }}
        >
          <ContentPaste />
        </Avatar>
        <span>Dashboard recording.docx</span>
      </Stack>
    ),
    type: <TypeClip bgcolor="#EEF4FF" textcolor="#3538CD" label="Attachment" />,
    action: (
      <IconButton size="small">
        <DeleteForeverOutlinedIcon />
      </IconButton>
    ),
  },

  {
    name: (
      <Stack direction="row" spacing={2} alignItems="center">
        <Avatar
          variant="rounded"
          sx={{ background: "#F4F3FF", color: "#6938EF" }}
        >
          <AttachmentIcon />
        </Avatar>
        <span>Front render</span>
      </Stack>
    ),
    type: <TypeClip bgcolor="#F4F3FF" textcolor="#5925DC" label="Link" />,
    action: (
      <>
        <IconButton size="small">
          <DeleteForeverOutlinedIcon />
        </IconButton>
        <IconButton size="small">
          <EditIcon />
        </IconButton>
      </>
    ),
  },
];
