/* eslint-disable no-unused-vars */
import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const StartFreeTrial = ({ promoCode, ...props }) => {
  const history = useNavigate();
  const handleFreeTrial = () => {
    history(`/free-signup?planName=estimatingPlan&promoCode=${promoCode}`);
  };
  return (
    <Button onClick={handleFreeTrial} {...props} className={props.className}>
      Start Free Trial
    </Button>
  );
};

export default StartFreeTrial;
