export const SERVER_URL =
  process.env.REACT_APP_SERVER_URL || "http://localhost:5001/api";

export const FILE_URL = SERVER_URL.replace("/api", "");
export const LIMIT_OF_PAGES = 10;
export const LIMIT_OF_PAGES__REDIRECT_MESSAGES = 10;

export const MEDIA_URL = FILE_URL;

export const AGORA_APP_ID = process.env.AGORA_APP_ID || "";
export const CLOUD_BASE_URL =
  process.env.REACT_APP_CLOUDFRONT_BASEURL ||
  "https://d69zsq52030i3.cloudfront.net";
export const TWILIO_APP_ID = process.env.TWILIO_ACCOUNT_SID || "";
export const TWILIO_TOKEN = process.env.TWILIO_ACCOUNT_TOKEN || "";
export const TWILIO_ADDRESS_ID = process.env.TWILIO_ADDRESS_ID || "";
export const DEFAULT_NUMBER =
  process.env.TWILIO_DEFAULT_NUMBER || "+19087389296";
