import { generateRandomId } from "../../utils/formula/formula";

export const transformSuggestionResponse = (response) =>
  response.data.Data.map((item) => ({
    customId: generateRandomId(),
    title: item.title,
    price: item.price,
    unit: item.unit,
    image: item.image,
    _id: item._id,
  }));
