import { Container } from "@material-ui/core";
import { Button, Col, Layout, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { LoaderContext } from "../../../../../common/contexts";
import { useLoader } from "../../../../../common/hooks";
import { ReactComponent as BackIcon } from "../../../../../images/affiliateImages/back-icon.svg";
import logo from "../../../../../images/logo-signup.png";
import {
  getReferralHeaderDetails,
  getReferralHistoryDetails,
} from "../../../../../redux/affiliate/affiliate.action";
import {
  promoCodeStatus,
  promoCodeStatusColor,
  statusBackgroundColor,
} from "../../../../../utils/constants/constants";
import SmallLoader from "../../../../loader/smallLoader";
import TableData from "../../../../setting/UserListV2/Table";
import {
  convertLinksCode,
  convertToFloating,
  referralDetailCols,
} from "../../helper";

const { Content } = Layout;

const getColor = (status) =>
  promoCodeStatusColor[status?.toLowerCase()] || "#FFBC44";

const getBackgroundColor = (status) =>
  statusBackgroundColor[status?.toLowerCase()] || "";

const MyReferralDetails = ({ isSuperAdmin = false }) => {
  const referralState = useSelector((state) => state?.affiliate?.myReferrals);
  const affiliateId = useSelector((state) => state?.affiliate?.profile?._id);
  const headerDetails = useSelector(
    (state) => state?.affiliate?.referralDetail,
  );
  const historyDetails = useSelector(
    (state) => state?.affiliate?.referralHistory,
  );
  const { setLoading } = useContext(LoaderContext);
  const { loading } = useLoader();
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const referralDetail = referralState?.data?.find(
    (obj) =>
      obj?.companyNumber ===
        location.pathname.slice(location.pathname.lastIndexOf("/") + 1) ||
      obj?.organizationId ===
        location.pathname.slice(location.pathname.lastIndexOf("/") + 1),
  );

  const handleGoBack = () => {
    if (!isSuperAdmin) history("/affiliate/profile");
    else
      history("/v3/affiliate-marketing", { state: { backFromDetail: true } });
  };

  useEffect(() => {
    if (referralDetail) {
      dispatch(
        getReferralHeaderDetails(
          setLoading,
          referralDetail?.organizationId,
          isSuperAdmin,
          affiliateId,
        ),
      );
    } else {
      if (!isSuperAdmin) history("/affiliate/profile");
      else history("/v3/affiliate-marketing");
    }
  }, []);

  useEffect(() => {
    if (historyDetails && referralDetail?.organizationId) {
      dispatch(
        getReferralHistoryDetails(
          false,
          referralDetail?.organizationId,
          currentPage,
          10,
          isSuperAdmin,
          affiliateId,
        ),
      );
    }
  }, [currentPage]);

  const onScrollEnd = () => {
    if (historyDetails?.pagination?.[0]?.totalPage > currentPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    const container = document.querySelector(
      "#infinite-scroll-tbl-code .ant-table-body",
    );
    const handleScroll = () => {
      if (
        container?.scrollTop + container?.clientHeight >=
        container?.scrollHeight - 20
      ) {
        onScrollEnd();
      }
    };

    container?.addEventListener("scroll", handleScroll);
    return () => container?.removeEventListener("scroll", handleScroll);
  }, [onScrollEnd, currentPage]);

  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "100vh",
      }}
    >
      <SmallLoader />
    </div>
  );

  return loading || !referralDetail?.organizationId ? (
    <Loader />
  ) : (
    <div>
      <Container>
        <Content style={{ marginTop: "30px" }}>
          <img
            src={logo}
            alt="ZevBit-logo"
            style={{ width: "127.48px", height: "30px" }}
          />
          <Row className="mt-5" style={{ width: 1295 }}>
            <Col xs={24}>
              <Row justify="space-between">
                <Row
                  align="middle"
                  style={{ justifyContent: "space-between", width: "34%" }}
                >
                  <Col>
                    <Button
                      icon={
                        <BackIcon
                          style={{
                            height: "100%",
                            verticalAlign: "middle",
                          }}
                        />
                      }
                      type="text"
                      onClick={handleGoBack}
                    />
                  </Col>
                  <Col>
                    {isSuperAdmin ? (
                      <span style={{ fontSize: 25 }}>
                        {headerDetails?.maName}
                      </span>
                    ) : (
                      <span style={{ fontSize: 25 }}>
                        Account #{headerDetails?.companyNumber}
                      </span>
                    )}
                  </Col>
                  <Col>
                    <span
                      style={{
                        color: getColor(headerDetails?.status),
                        backgroundColor: getBackgroundColor(
                          headerDetails?.status,
                        ),
                        padding: 5,
                        borderRadius: 16,
                        verticalAlign: "middle",
                      }}
                    >
                      {headerDetails?.commission}% Commission
                    </span>
                  </Col>
                  <Col>
                    <span
                      style={{
                        color: getColor(headerDetails?.status),
                        backgroundColor: getBackgroundColor(
                          headerDetails?.status,
                        ),
                        padding: 5,
                        borderRadius: 16,
                        verticalAlign: "middle",
                      }}
                    >
                      {promoCodeStatus[headerDetails?.status] ||
                        headerDetails?.status}
                    </span>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col className="mx-3">
                    <span>
                      Link/ Code Used:{" "}
                      {headerDetails?.linkOrCode
                        ? convertLinksCode(headerDetails?.linkOrCode)
                        : "-"}
                    </span>
                  </Col>
                  <Col>
                    <span>
                      Total Commission: $
                      {convertToFloating(headerDetails?.totalCommission)}
                    </span>
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col xs={24} className="p-0">
              <div className="tablecard mt-5">
                <TableData
                  className="my-referrals-tbl-details"
                  columns={referralDetailCols}
                  dataSource={historyDetails?.data}
                  pagination={false}
                  scroll={{ y: 600 }}
                  id="infinite-scroll-tbl-code"
                  style={{ overflowY: "auto" }}
                />
              </div>
            </Col>
          </Row>
        </Content>
      </Container>
    </div>
  );
};

export default MyReferralDetails;
