import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";

import rootReducer from "./reducer";
// import logger from "redux-logger";

const middleware = [thunkMiddleware];
if (process.env.NODE_ENV === "development") {
  // middleware.push(logger);
}
const composedEnhancer = composeWithDevTools(
  applyMiddleware(...middleware),
  // other store enhancers if any
);

const store = createStore(rootReducer, composedEnhancer);
export default store;
