import api from "./api";

export async function getBillingDetailsAction() {
  const response = await api.request({
    url: `/v3/admin/master/billing`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.data,
    };
  }
  return response;
}

export const updateCardDetailAction = async (data) => {
  const response = await api.request({
    url: "/v3/admin/master/billing",
    method: "PUT",
    data,
  });
  return response;
};

export const addNewCardDetailAction = async (data) => {
  const response = await api.request({
    url: "/v3/admin/master/card",
    method: "POST",
    data,
  });
  return response;
};

export const retryPaymentAction = async () => {
  const response = await api.request({
    url: "/v3/admin/master/retry-payment",
    method: "PUT",
  });
  return response;
};

export const cancelSubscriptionAction = async (data) => {
  const response = await api.request({
    url: "/v3/admin/master",
    method: "DELETE",
    data,
  });
  return response;
};
export const reactivateSubscriptionAction = async (data) => {
  const response = await api.request({
    url: "/v3/admin/master/reactive-subscription",
    method: "POST",
    data,
  });
  return response;
};

export const checkTaxCalculationAction = async (data) => {
  const response = await api.request({
    url: "/v3/admin/master/pre-tax",
    method: "POST",
    data,
  });
  return response;
};

export const applyPromoCodeBilling = async (data) => {
  const response = await api.request({
    url: "/v3/admin/master/promo-code/attach",
    method: "PUT",
    data,
  });
  return response;
};

export const removePromoCodeBilling = async (data) => {
  const response = await api.request({
    url: "/v3/admin/master/promo-code/detach",
    method: "DELETE",
    data,
  });
  return response;
};

export const changeCurrentPlan = async (data) => {
  const response = await api.request({
    url: "/v3/subscription/plan/change",
    method: "POST",
    data,
  });
  return response;
};

export const start30DaysTrialPlan = async (data) => {
  const response = await api.request({
    url: "/v3/subscription/plan/upgraded-plan-trial",
    method: "POST",
    data,
  });
  return response;
};

export const getChangePlan = async (planId) => {
  const response = await api.request({
    url: `/v3/subscription/plan/change?planId=${planId}`,
    method: "GET",
  });

  return response;
};
