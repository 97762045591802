import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  getContractDetailsByTokenAPI,
  viewedEstimation,
} from "../../../api/contract";
import ModalDailog from "../../../common/components/ModalDialogue";
import { getSquareInfoAction } from "../../../redux/invoice/invoice.action";
import { createValidUrl } from "../../../utils/commonFuctions";
import { calculateDiscount } from "../../../utils/formula/estimate";
import SmallLoader from "../../loader/smallLoader";
import Content from "./content";
import { addExtraPercentageAmount } from "./helper";

function SignContractV2() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [expiredToken, setExpiredToken] = useState(false);

  const [projectCharges, setProjectCharges] = useState({});
  const [estimateDetails, setEstimateDetails] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [organizationDetails, setOrganizationDetails] = useState({});
  const token = searchParams.get("token");
  const isEstimationViewed = localStorage.getItem(estimateDetails?._id);
  const [viewed, setViewed] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});
  const [callingView, setCallingView] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const dispatch = useDispatch();
  const [depositPopupDetail, setDepositPopupDetail] = useState({
    invoiceSettings: {
      _id: "",
      depositPopup: false,
      depositPopupTemplate: {
        payWithCheckCash: false,
        payWithCreditCardAch: false,
        popUpTemplate: {
          body: "",
          title: "",
          welcomeNote: "",
        },
      },
    },
  });
  const viewEstimate = async () => {
    setCallingView(true);
    setViewed(false);
    if (estimateDetails?._id && !isEstimationViewed) {
      const response = await viewedEstimation(estimateDetails?._id);
      if (response.remote === "success") {
        localStorage.setItem(estimateDetails?._id, "true");
      }
      setCallingView(false);
    }
  };

  const getContractDetailsByToken = async (token) => {
    setLoading(true);
    const res = await getContractDetailsByTokenAPI({ token });
    if (res.remote === "success") {
      const companyLogo = {
        logo: res.data?.estimateDetails?.organizationDetails?.logo,
        companyName: res.data?.estimateDetails?.organizationDetails?.name,
      };
      if (res.data.estimateDetails.estimateDetails.isDeleted) {
        navigate("/page-not-found", { state: companyLogo });
      }
      setCompanyDetails({ ...companyLogo });
      const estimationDetails = res.data.estimateDetails.estimateDetails;

      if (
        res.data.estimateDetails?.estimateDetails?.signedByClient?.signature
      ) {
        const isDepositPopupViewed =
          res.data?.estimateDetails.estimateDetails.isDepositPopupViewed;
        const depositPopup =
          res.data?.estimateDetails?.organizationDetails?.invoiceSettings
            .depositPopup;
        const shouldShowPopup = !isDepositPopupViewed && depositPopup;
        if (shouldShowPopup) {
          setPaymentAmount(
            addExtraPercentageAmount(
              organizationDetails?.invoiceSettings?.invoice?.amount,
              organizationDetails?.invoiceSettings?.paymentIntegration
                ?.extraChargeForCardPayment?.chargePercentage,
            ),
          );
        }
        setDepositPopupDetail({
          invoiceSettings: {
            ...res.data?.estimateDetails?.organizationDetails?.invoiceSettings,
            openModal: shouldShowPopup,
          },
        });
      }
      setEstimateDetails(estimationDetails || {});
      setCurrentUser(res.data.estimateDetails.preparedBy || {});
      setCustomerDetails(res.data.estimateDetails.customerDetails || {});
      setOrganizationDetails(
        res.data.estimateDetails.organizationDetails || {},
      );
      const formulas = estimationDetails.services;
      const discountPercentage =
        estimationDetails?.estimationSettings?.fluffNumberDiscount || 0;
      const builtInDesignCost =
        estimationDetails?.estimateSettings?.builtInDesignCost?.price || 0;
      let projectCharge = builtInDesignCost;
      formulas.forEach((formula) => {
        const originalActualService = formula.actualServices.find(
          (srv) => srv.type === "original",
        );
        projectCharge += originalActualService?.totalServiceCharge || 0;
      });
      const discount = calculateDiscount(projectCharge, discountPercentage);
      const totalChargeAfterDiscount =
        projectCharge - discount < 0 ? 0 : projectCharge - discount;
      let taxAmount = 0;
      let totalChargeAfterDiscountAndTax = 0;
      if (estimationDetails.tax && estimationDetails.tax.status) {
        taxAmount =
          (totalChargeAfterDiscount *
            Number(estimationDetails.tax.percentage)) /
          100;
      }
      totalChargeAfterDiscountAndTax = totalChargeAfterDiscount + taxAmount;

      setProjectCharges({
        taxAmount: estimationDetails.estimationOverview?.taxAmount || taxAmount,
        totalChargeAfterDiscountAndTax:
          estimationDetails.estimationOverview?.totalCharge ||
          totalChargeAfterDiscountAndTax,
        projectCharge:
          estimationDetails.estimationOverview?.totalChargeWithoutTax ||
          projectCharge,
        discount: discount.toFixed(2),
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (estimateDetails?._id && !viewed && !isEstimationViewed) {
      setViewed(true);
    }
  }, [estimateDetails]);

  useEffect(() => {
    if (token) {
      getContractDetailsByToken(token);
    } else {
      setExpiredToken(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      depositPopupDetail?.invoiceSettings?.invoice?._id &&
      depositPopupDetail?.invoiceSettings?.activePaymentIntegration === "square"
    ) {
      dispatch(
        getSquareInfoAction(
          token,
          depositPopupDetail?.invoiceSettings?.invoice?._id,
        ),
      );
    }
  }, [depositPopupDetail]);

  return (
    <>
      {expiredToken ? (
        <h1>Invalid Link</h1>
      ) : (
        <div
          style={{
            padding: "25px",
            background: "#F7F7F7",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading ? (
            <SmallLoader />
          ) : (
            <Content
              token={searchParams.get("token")}
              estimateDetails={estimateDetails}
              projectCharges={projectCharges}
              currentUser={currentUser}
              customerDetails={customerDetails}
              organizationDetails={organizationDetails}
              needToShowTermsButton={false}
              depositPopupDetail={depositPopupDetail}
              setDepositPopupDetail={setDepositPopupDetail}
              paymentAmount={paymentAmount}
            />
          )}
        </div>
      )}
      <ModalDailog isModalOpen={viewed} closeIcon={<></>}>
        <Row style={{ justifyContent: "center" }}>
          <Col>
            <div>
              {companyDetails?.logo && (
                <img
                  src={createValidUrl(companyDetails?.logo)}
                  alt="Company logo"
                  style={{ width: 200 }}
                />
              )}
            </div>
            <div>
              <p style={{ fontSize: 20, fontWeight: "bold" }}>
                Your Estimate From {companyDetails?.companyName}
              </p>
            </div>
            <div>
              <Button
                type="primary"
                onClick={() => viewEstimate()}
                style={{ margin: "0 auto" }}
                disabled={callingView}
              >
                View Estimation
              </Button>
            </div>
          </Col>
        </Row>
      </ModalDailog>
    </>
  );
}

export default SignContractV2;
