import { USER_ROLE } from "../utils/constants/constants";
import { getRole } from "../utils/role";
import api from "./api";
import { transformLeadStatus } from "./transform/admin";

const APIToken = localStorage.getItem("token");

export async function getEmailSetting(userRole) {
  const url =
    userRole === USER_ROLE.superAdmin
      ? "/admin/get-email-setting"
      : "/v3/settings/smtp-settings";
  const response = await api.request({
    url,
    method: "GET",
  });
  if (response.remote === "success") {
    return userRole === USER_ROLE.superAdmin
      ? {
          remote: response.remote,
          data: response.data.Data[0],
        }
      : {
          remote: response.remote,
          data: response.data.data,
        };
  }
  return response;
}

export async function getGoogleKey() {
  const response = await api.request({
    url: "/admin/get-google-key",
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      googleKey: response.data.googleKey,
    };
  }
  return response;
}

export async function updateEmailSetting(body) {
  const response = await api.request({
    url: "/admin/update-email-setting",
    method: "Post",
    data: body,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.Data,
    };
  }
  return response;
}

export async function updateOrganizationEmailSetting(body) {
  const response = await api.request({
    url: "/v3/settings/smtp-settings",
    method: "Put",
    data: body,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.Data,
    };
  }
  return response;
}

export async function getUserList() {
  const response = await api.request({
    url: "/auth/user-list",
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.Data,
    };
  }
  return response;
}

export async function getUserListByCompanyName(data) {
  const response = await api.request({
    url: "/auth/user-list-by-company-name",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.Data,
    };
  }
  return response;
}

export async function getUserListGroupByCompanyName(
  skip,
  isTrial,
  search = "",
) {
  const response = await api.request({
    url: `/auth/user-list-group-by-company-name?skip=${skip}&isTrial=${isTrial}&search=${search}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.Data,
      totalCount: response.data.totalCount,
    };
  }
  return response;
}

export async function getUserListByOrganization(skip, search = "") {
  const response = await api.request({
    url: `/auth/user-list-by-organization?skip=${skip}&search=${search}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.Data,
      totalCount: response.data.totalCount,
    };
  }
  return response;
}

export async function deleteUserById(data) {
  const response = await api.request({
    url: `/v3/hrms/employee/${data}`,
    method: "DELETE",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getAllQuestionsList(type) {
  const url = `/question?questionType=${type}`;
  const response = await api.request({
    url,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function changeSequence(data, token) {
  const response = await api.request({
    url: "/question/change-sequence",
    method: "put",
    data,
    token,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function addQuestions(data) {
  const response = await api.request({
    url: "/question/add-question",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function deleteQuestions(data) {
  const response = await api.request({
    url: `/question?id=${data}`,
    method: "DELETE",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.msg,
    };
  }
  return response;
}

export async function editQuestions(data) {
  const response = await api.request({
    url: "/question",
    method: "PUT",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data?.msg,
    };
  }
  return response;
}

export async function deleteDefaultQuestions(id, token, payload) {
  const response = await api.request({
    url: `/question?id=${id}`,
    method: "DELETE",
    data: payload,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.msg,
    };
  }
  return response;
}

export async function getServices(type) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin" && !type) {
      return "/v2/formula/get-services";
    }
    return "/v2/formula/organization/get-services";
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.services,
    };
  }
  return response;
}

export async function addLeadApi(data) {
  const response = await api.request({
    url: "/customer/lead/add",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.lead,
      message: response.data.message,
    };
  }
  return response;
}

export async function getAllLead(
  token,
  search,
  filter,
  pageNo = 1,
  limit = 10,
) {
  let response;
  if (filter?.filter) {
    response = await api.requestByToken({
      url: filter.url,
      method: "GET",
    });
  } else if (search) {
    response = await api.request({
      url: `/customer/lead?pageNo=${pageNo}&limit=${limit}&search=${search}`,
      method: "GET",
    });
  } else {
    response = await api.request({
      url: `/customer/lead?pageNo=${pageNo}&limit=${limit}`,
      method: "GET",
    });
  }
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getAllStaff() {
  const response = await api.request({
    url: "/customer/staff-list",
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getLeadStatus({ token, type }) {
  const response = await api.requestByToken({
    url: `/status/lead-status?type=${type}`,
    method: "GET",
    token,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function updateStatus(token, leadId, statusId, contractId) {
  let url = `/customer/lead/update?id=${leadId}&leadStatus=${statusId}`;
  if (contractId) {
    url += `&contractId=${contractId}`;
  }
  const response = await api.request({
    url,
    method: "PUT",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export const updateProjectStatus = async (estimationId, newStatusId) => {
  const response = await api.request({
    url: `v3/invoice/dashboard/project-status`,
    method: "PUT",
    data: {
      estimationId,
      newStatusId,
    },
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
};

export async function getLeadSources() {
  const response = await api.request({
    url: "/lead-source/lead-source-list",
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function updateSource(token, data) {
  const response = await api.requestByToken({
    url: "/lead-source/update",
    method: "PUT",
    token,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getOptions(token, id) {
  const response = await api.requestByToken({
    url: `/question/by-id?id=${id}`,
    method: "GET",
    token,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function deleteLeads(data) {
  const response = await api.request({
    url: `/customer/lead/delete-many?id=${JSON.stringify(data)}`,
    method: "DELETE",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function getLeadById(token, id) {
  const response = await api.request({
    url: `/customer/lead/by-id?id=${id}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function addLeadSource(value) {
  const response = await api.request({
    url: "/lead-source/add-lead-source",
    method: "POST",
    data: value,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function getSourceLead() {
  const response = await api.requestByToken({
    url: "/lead-source/lead-source-list",
    method: "get",
    token: APIToken,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function updateSourceLead(payload) {
  const response = await api.request({
    url: "/lead-source/update",
    method: "PUT",
    data: payload,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function updateLeadStatus(payload) {
  const response = await api.requestByToken({
    url: "/status/lead-status",
    method: "PUT",
    token: APIToken,
    data: payload,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function deleteSourceLead(payload) {
  const response = await api.requestByToken({
    url: `/lead-source/remove-lead-source?id=${payload}`,
    method: "DELETE",
    token: APIToken,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getUserAttachmentAlbumList(id) {
  const response = await api.requestByToken({
    url: `/customer/lead/albums?leadId=${id}`,
    method: "GET",
    token: APIToken,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getUserAttachmentAlbum(id, parentFolderId) {
  const response = await api.requestByToken({
    url: `/customer/lead/folder?leadId=${id}&parentFolderId=${parentFolderId}`,
    method: "GET",
    token: APIToken,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function createNewUserAlbum(value) {
  const response = await api.requestByToken({
    url: "/customer/lead/album",
    method: "POST",
    token: APIToken,
    data: value,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function editLeadApi(data) {
  const response = await api.request({
    url: "/customer/lead/by-id",
    method: "PUT",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function editLeadSoftwareFollowUpStatus(data) {
  const response = await api.request({
    url: "/customer/lead/update-software-follow-up-status",
    method: "PUT",
    data,
  });
  return response;
}

export async function addNewUserAlbumImages(value) {
  const response = await api.requestByToken({
    url: "customer/lead/album",
    method: "PUT",
    token: APIToken,
    data: value,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getTabs(token) {
  const response = await api.requestByToken({
    url: "/tab-filter/crm",
    method: "GET",
    token,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function deleteAlbumImages(value) {
  const response = await api.requestByToken({
    url: "/customer/lead/delete-attachment",
    method: "PUT",
    token: APIToken,
    data: value,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function saveFilter(token, data) {
  const response = await api.requestByToken({
    url: "/tab-filter/crm",
    method: "POST",
    token,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function applyFilter(token, filterUrl) {
  const response = await api.requestByToken({
    url: `${filterUrl}`,
    method: "GET",
    token,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getLeadByStatus() {
  const response = await api.request({
    url: "/customer/lead/pipeline-view",
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getScheduleEstimation(data) {
  const response = await api.requestByToken({
    url: "/estimation-schedule/fetch-sales-person",
    method: "PUT",
    token: APIToken,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

// start activity Log tab API functions

export async function makeCall(data) {
  const response = await api.requestByToken({
    url: "/customer/lead/make-call",
    method: "POST",
    token: APIToken,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function increaseCallAttempts(data) {
  const response = await api.request({
    url: "/customer/lead/increase-call-attempts",
    method: "PUT",
    data,
  });
  return response;
}

export async function sendSMS(data) {
  const response = await api.requestByToken({
    url: "/customer/lead/send-sms",
    method: "POST",
    token: APIToken,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function sendEmail(data) {
  const response = await api.requestByToken({
    url: "/customer/lead/send-email",
    method: "POST",
    token: APIToken,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

// End activity log tab API functions

// Start CRM Lead Status API functions

export async function addLead(data) {
  const response = await api.requestByToken({
    url: "/status/lead-status",
    method: "POST",
    token: APIToken,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function fetchAllLead({ type }) {
  const response = await api.requestByToken({
    url: `/status/lead-status?type=${type}`,
    method: "GET",
    token: APIToken,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: transformLeadStatus(response?.data || []),
    };
  }
  return response;
}

export async function updateLead(data) {
  const response = await api.requestByToken({
    url: "/status/lead-status",
    method: "PUT",
    token: APIToken,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function deleteLead(id) {
  const response = await api.requestByToken({
    url: `/status/lead-status/by-id?id=${id}`,
    method: "DELETE",
    token: APIToken,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
// End CRM Lead Status API functions

export async function addScheduleEstimation(data) {
  if (!data.selfAssignLead) {
    data.selfAssignLead = false;
  }
  const response = await api.requestByToken({
    url: "/estimation-schedule/add",
    method: "POST",
    token: APIToken,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

// for edit estimation schedule time

export async function editScheduleEstimation(data) {
  const response = await api.requestByToken({
    url: "/estimation-schedule/edit",
    method: "PUT",
    token: APIToken,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getActivityLog(id) {
  const response = await api.requestByToken({
    url: `/customer/lead/activity-logs/${id}`,
    method: "GET",
    token: APIToken,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function setPaymentDetailsApi(data) {
  const response = await api.requestByToken({
    url: "/v1/organization/payment-gateway-details ",
    method: "PUT",
    token: APIToken,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function deleteTab(id) {
  const response = await api.request({
    url: `/tab-filter/crm/${id}`,
    method: "DELETE",
    token: APIToken,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function addTab(data, token) {
  const response = await api.request({
    url: "tab-filter/crm",
    method: "POST",
    data,
    token,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function cleanFilter(id) {
  const response = await api.request({
    url: `/tab-filter/crm-clear-filter/${id}`,
    method: "DELETE",
    token: APIToken,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function deleteFolder(data) {
  const response = await api.request({
    url: "/customer/lead/delete-album",
    method: "DELETE",
    token: APIToken,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function generatePaymentLink(data) {
  const response = await api.request({
    url: "/payment/generate-link",
    method: "POST",
    token: APIToken,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function addNoteActivityLog(data) {
  const response = await api.request({
    url: "/customer/lead/add-note",
    method: "POST",
    token: APIToken,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

// company default attachment API start

export async function createNewDefaultFolder(data) {
  const response = await api.request({
    url: "/customer/lead/default/album",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getDefaultAttachment() {
  const response = await api.request({
    url: "/customer/lead/default/album",
    method: "get",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function deleteDefaultAttachment(id) {
  const response = await api.request({
    url: `/customer/lead/default/delete-album/${id}`,
    method: "DELETE",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

// company default attachment API end

// schedule estimate - get email template API
export async function getEmailTemplate() {
  const response = await api.request({
    url: "/email-template",
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
// this function is used in customer side who is not logged in and try to view the contract
export async function getEmailTemplateByOrganizationId(organizationId) {
  const response = await api.request({
    url: `/email-template/template-by-organizationId/${organizationId}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

// API for sending mail to customer after scheduling a estimate.
export async function sendMailToCustomer(data) {
  const response = await api.request({
    url: "/estimation-schedule/customer-email-scheduled-estimate",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

// CRM message center API Start
export async function getMessageCenterMessagesHistory(data) {
  const response = await api.request({
    url: "/chat/conversation-history-client",
    method: "post",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

// API for get data of user list of company when type of question "User list" is selected
export async function getUserListOfCompany({ user }) {
  const response = await api.request({
    url: `/staff/staff-user-for-question?users=${JSON.stringify(user)}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

// Update Lead question answer from CRM lead table
export async function updateLeadAnswer(data) {
  const response = await api.request({
    url: "/customer/lead/question/update",
    method: "put",
    data,
  });
  return response;
}

// Update Lead source status from CRM lead table
export async function updateSourceStatus(leadId, statusId) {
  const url = `/customer/lead/source/update?id=${leadId}&sourceStatus=${statusId}`;
  const response = await api.request({
    url,
    method: "PUT",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getAdminList() {
  const response = await api.request({
    url: "/time-tracking/employee-sheet/list-of-admins",
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.data,
    };
  }
  return response;
}

export async function sendTimecardData(data) {
  const response = await api.request({
    url: "/time-tracking/employee-sheet/send-time-card",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function addPromoCode(data) {
  const response = await api.request({
    url: "/v3/admin/super/promo-code",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function addFreeTrialCode(data) {
  const response = await api.request({
    url: "/v3/admin/super/trial-code",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getPromoCodeListData(
  currentPromoPage,
  perPagePromoData,
  promoSearch,
) {
  const response = await api.request({
    url: `/v3/admin/super/promo-code/list?page=${currentPromoPage}&perPage=${perPagePromoData}&search=${promoSearch}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function getCodeUserData(
  selectedCode,
  search,
  startDate,
  endDate,
  currentPage,
  perPageUserData,
) {
  const response = await api.request({
    url: `/v3/admin/super/promo-code/used?couponId=${selectedCode}&search=${search}&startDate=${startDate}&endDate=${endDate}&page=${currentPage}&perPage=${perPageUserData}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getUserListDataAction(data, setLoader) {
  if (setLoader) setLoader(true);
  const response = await api.request({
    url: "/v3/admin/super/organization-list",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    if (setLoader) setLoader(false);
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  if (setLoader) setLoader(false);
  return response;
}

export async function addNewSourceAction(data) {
  const response = await api.request({
    url: "/v3/admin/super/source",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function addNewAffiliateAction(data) {
  const response = await api.request({
    url: "/v3/admin/super/affiliate",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function changeHrmsUserListAction(data) {
  const response = await api.request({
    url: "/v3/admin/super/information",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function handleRejectStatusAction(id) {
  const response = await api.request({
    url: `/v3/admin/super/suspend/${id}`,
    method: "DELETE",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function handleReactivateStatusAction(data) {
  const response = await api.request({
    url: "/v3/admin/super/suspend/reactive",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function handleFreeStatusAction(data) {
  const response = await api.request({
    url: "/v3/admin/super/free-user",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getHrmsUserListAction() {
  const response = await api.request({
    url: "/v3/admin/super/hrms-user-list",
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getSourceListAction() {
  const response = await api.request({
    url: "/v3/admin/super/source/list",
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getAffiliateListAction() {
  const response = await api.request({
    url: "/v3/admin/super/affiliate/list",
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getUserLastSignInDataAction() {
  const response = await api.request({
    url: "/v3/organization/last-sign-in",
    method: "PUT",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getUsersDataAction(
  userId,
  currentUsersPage,
  perPageCount,
) {
  const response = await api.request({
    url: `/v3/admin/super/organization/user-list?organizationId=${userId}&page=${currentUsersPage}&perPage=${perPageCount}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getBillingDataAction(
  userId,
  currentUsersPage,
  perPage,
  isOrganisationId = true,
) {
  const response = await api.request({
    url: isOrganisationId
      ? `/v3/payment/subscription/invoice-list?organization=${userId}&page=${currentUsersPage}&perPage=${perPage}`
      : `/v3/payment/subscription/invoice-list?page=${currentUsersPage}&perPage=${perPage}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getTrialCodeListData(
  currentPromoPage,
  perPagePromoData,
  promoSearch,
) {
  const response = await api.request({
    url: `/v3/admin/super/trial-code/list?page=${currentPromoPage}&perPage=${perPagePromoData}&search=${promoSearch}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function deletePromoCode(id) {
  const response = await api.request({
    url: `/v3/admin/super/promo-code/${id}`,
    method: "DELETE",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function editPromoCode(id, data) {
  const response = await api.request({
    url: `/v3/admin/super/promo-code/${id}`,
    method: "PUT",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function deleteTrialCode(id) {
  const response = await api.request({
    url: `/v3/admin/super/trial-code/${id}`,
    method: "DELETE",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function editIsActiveStatus(id, data) {
  const response = await api.request({
    url: `/v3/admin/super/promo-code/status/${id}`,
    method: "PUT",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function editIsTrialActiveStatus(id, data) {
  const response = await api.request({
    url: `/v3/admin/super/trial-code/status/${id}`,
    method: "PUT",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function manageAssignee(data) {
  const response = await api.request({
    url: "/customer/create-or-delete-lead-assignee",
    method: "post",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function extendFreeTrial(id, data) {
  const response = await api.request({
    url: `/v3/admin/super/free-trial/${id}`,
    method: "PUT",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function resetPasswordUserList(data) {
  const response = await api.request({
    url: "/v3/hrms/employee/reset-password",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function handleUploadImageAction(data) {
  const response = await api.request({
    url: `/v3/affiliate/profile/documents/id-card`,
    method: "PUT",
    data,
    token: localStorage.getItem("affiliateToken"),
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function updateLeadSequence(data) {
  const response = await api.request({
    url: "status/update-sequence",
    method: "patch",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function handleUploadFormAction(data) {
  const response = await api.request({
    url: `/v3/affiliate/profile/documents/w9-form`,
    method: "PUT",
    data,
    token: localStorage.getItem("affiliateToken"),
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export const getDocNPayDetail = async (setLoading) => {
  const response = await api.request({
    url: `/v3/affiliate/profile/documents`,
    method: "GET",
    token: localStorage.getItem("affiliateToken"),
  });
  if (response.remote === "success") {
    setLoading(false);
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  setLoading(false);
  return response;
};
export const getPromoCodeDataAction = async (
  couponId,
  page = 1,
  perPage = 5,
  searchValue = "",
  sortOrder = "",
  sortField = "",
) => {
  const response = await api.request({
    url: `v3/admin/super/promo-code/used?couponId=${couponId}&page=${page}&perPage=${perPage}&search=${searchValue}${
      sortField && sortOrder ? `&order=${sortOrder}&fieldName=${sortField}` : ""
    }`,
    method: "GET",
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
};
export async function deleteIdAction(data) {
  const response = await api.request({
    url: `/v3/affiliate/profile/documents`,
    method: "DELETE",
    data,
    token: localStorage.getItem("affiliateToken"),
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function setPaymentMethodAction(data, docStatus) {
  console.log("docStatus --> ", docStatus);
  const response = await api.request({
    url: `/v3/affiliate/profile/documents/payment-method`,
    method: docStatus === "Approved" ? "PUT" : "POST",
    data,
    token: localStorage.getItem("affiliateToken"),
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export const deletePromoCodeAction = async (
  organizationId,
  password,
  couponId,
) => {
  const data = {
    promoCodeId: couponId,
    password,
  };
  const response = await api.request({
    url: `v3/admin/super/promo-code/remove/${organizationId}`,
    method: "DELETE",
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors?.errors?.error,
  };
};

export const getPromoCodeMetaAction = async (id, startDate, endDate) => {
  const response = await api.request({
    url: `v3/admin/super/promo-code/metadata?couponId=${id}${
      startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ""
    }`,
    method: "GET",
    token: localStorage.getItem("token"),
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors?.errors?.error,
  };
};

export const loginAccount = async (data) => {
  const response = await api.request({
    url: `v3/admin/super/ma-login`,
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors?.errors?.error,
  };
};
