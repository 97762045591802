import {
  CloudUploadOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  message,
  Row,
  Switch,
  Tooltip,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import { debounce } from "lodash";
import React, { useEffect } from "react";
import {
  Navigate,
  useLocation,
  // useNavigate
} from "react-router-dom";

// import { Upload } from "antd";
// import ImgCrop from "antd-img-crop";
import {
  getCatalogDetailsBySuperId,
  searchCatalogByName,
  searchOldCatalogByName,
} from "../../../api/catalogue";
import {
  addFormulaNewElement,
  getFormulaById,
  publishChanges,
  unpublishChanges,
  updateFormula,
} from "../../../api/formula";
import { GetAllUnits } from "../../../api/unit";
import { createValidUrl } from "../../../utils/commonFuctions";
import { USER_ROLE } from "../../../utils/constants/constants";
import { fileToBase64 } from "../../../utils/fileBase64";
import { validateArthmaticString } from "../../../utils/formula/formula";
import regex from "../../../utils/regex";
import { getRole } from "../../../utils/role";
import { treeIcon, unpublish } from "../../../utils/svg.file";
import BreadcrumbBar from "../../breadcrumb/Breadcrumb.pages";
import SmallLoader from "../../loader/smallLoader";
import Category from "./category/category";
import SubCategory from "./category/subCategory";
import ClientContractV2 from "./clientContractV2";
import ElementCard from "./elementCard/elementCard.compnent";
import { FORMULA_MATERIAL_TYPES } from "./helper";
import HiddenValueCard from "./hiddenValueCard/hiddenValueCard.component";
import MaterialCard from "./materialCard/materialCard.component";

/**
 * @author  Technologies
 * @version 2.0.0
 */

function FormulaV2() {
  // const navigate = useNavigate();
  const [publishing, setPublishing] = React.useState(false);
  const [formulaDetails, setFormulaDetails] = React.useState({});
  const [publishedFormula, setPublishedFormula] = React.useState("");
  const [publishedAt, setPublishedAt] = React.useState("");
  const [displayName, setDisplayName] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [elementList, setElementList] = React.useState([]);
  const [materials, setMaterials] = React.useState([]);
  const [hiddenValues, setHiddenValues] = React.useState([]);

  const [markupId, setMarkupId] = React.useState(null);
  const [clientContract, setClientContract] = React.useState("");
  const [clientContractV2, setClientContractV2] = React.useState([]);
  const [redirect, setRedirect] = React.useState(null);
  const [catalogs, setCatalogs] = React.useState([]);
  const [photo, setPhoto] = React.useState(null);
  const [file, setFile] = React.useState(null);

  const [units, setUnits] = React.useState([]);
  const [customFields, setCustomFields] = React.useState([]);
  const [processedElementList, setProcessedElementList] = React.useState([]);
  const [processedLineItems, setProcessedLineItems] = React.useState([]);
  const [updateProcessedElement, setUpdateProcessedElement] =
    React.useState(""); // this state will store the id of the dropdown element which has changed the dropdown field
  const [isLoadingFirstTime, setIsLoadingFirstTime] = React.useState(true);
  const [isAddingElement, setIsAddingElement] = React.useState(null);
  const [catalogsAndServices, setCatalogsAndServices] = React.useState([]);
  const [subCatalogItems, setSubCatalogItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [isUpdatedElementName, setIsUpdatedElementName] = React.useState({});
  const [isUpdatedLineItemName, setIsUpdatedLineItemName] = React.useState({});
  const [toggleLineItemNameUpdate, setToggleLineItemNameUpdate] =
    React.useState(false); // this statue toggles when line item name update
  const [variations, setVariations] = React.useState({});
  const [isAddingMaterial, setIsAddingMaterial] = React.useState(false);

  const [manualSave, setManualSave] = React.useState(false);

  const current = new Date();
  const date = `${
    current.getMonth() + 1
  }/${current.getDate()}/${current.getFullYear()}`;

  const onChange = async (e) => {
    const base64 = await fileToBase64(e.file);
    setFile(e.file || null);
    setPhoto(base64);
  };

  const params = useLocation();

  React.useEffect(() => {
    getAllCatalogsAndServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const searchQuery = params.search.trim();
    const query = new URLSearchParams(searchQuery);
    const formulaId = query.get("formulaId");
    if (!formulaId) {
      setRedirect("/services");
    } else {
      getFormulaDetails(formulaId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  React.useEffect(() => {
    const newProcessedLineItems = [];
    materials.forEach((material) => {
      const elem = material.name.match(regex.materialInput) || [];
      let { name } = material;
      elem.forEach((nam) => {
        const elemName = nam.split("||")[2];
        name = name.replace(nam, elemName.replace("}}", ""));
      });
      name = name.trim();
      newProcessedLineItems.push({
        display: `${name} - Quantity`,
        id: `${material._id}-quantity`,
      });
      newProcessedLineItems.push({
        display: `${name} - Unit`,
        id: `${material._id}-unit`,
      });
      newProcessedLineItems.push({
        display: `${name} - Unit Cost`,
        id: `${material._id}-unitCost`,
      });
      newProcessedLineItems.push({
        display: `${name} - Cost`,
        id: `${material._id}-cost`,
      });
      newProcessedLineItems.push({
        display: `${name} - Charge`,
        id: `${material._id}-charge`,
      });
      if (material.type === FORMULA_MATERIAL_TYPES.material) {
        (material.customFields || []).forEach((customField) => {
          newProcessedLineItems.push({
            display: `${name}-${customField.fieldName}`,
            id: customField.superId || customField._id,
          });
        });
      }
    });
    setProcessedLineItems(newProcessedLineItems);
  }, [materials]);

  async function getFormulaDetails(formulaId) {
    const formulaDetails = await getFormulaById(formulaId);
    if (formulaDetails.remote === "success") {
      setFormulaDetails(formulaDetails.data.data);
      const publishedFormulaDetails = {
        title: formulaDetails.data.data.title,
        elements: formulaDetails.data.data.elements,
        materials: formulaDetails.data.data.materials,
        hiddenValues: formulaDetails.data.data.hiddenValues,
        publishedAt: formulaDetails.data.data.publishedAt,
        clientContract: formulaDetails.data.data.clientContract,
        photo: file || formulaDetails.data.data.photo,
        catalogs: formulaDetails.data.data.catalogs,
      };
      setPublishedFormula(JSON.stringify(publishedFormulaDetails));
      setTitle(formulaDetails.data.data.title);

      setCategory(formulaDetails.data.data.category || "");
      setSubCategory(formulaDetails.data.data.subCategory || "");

      setPublishedAt(formulaDetails.data.data.publishedAt);
      setDisplayName(formulaDetails.data.data.displayName);
      const catalogs = formulaDetails.data.data?.catalogs || [];
      setCatalogs([...catalogs]);
      setClientContract(formulaDetails.data.data.clientContract);
      setClientContractV2(formulaDetails.data.data.clientContractV2);
      setPhoto(formulaDetails.data.data.photo);
      !file && setFile(formulaDetails.data.data.photo);
      setMarkupId(
        formulaDetails.data.data.elements.find(
          (element) => element.name === "Markup",
        )?._id,
      );
      setIsLoadingFirstTime(false);
    } else {
      setIsLoadingFirstTime(false);
    }
  }

  async function updateFormulaDetails(data) {
    const key = "updatable";
    message.loading({ content: "Saving...", key, duration: 0 });
    setIsAddingElement(true);
    const updatedFormulaDetails = await updateFormula(formulaDetails._id, data);
    if (updatedFormulaDetails.remote === "success") {
      const publishedFormulaDetails = {
        title: updatedFormulaDetails.data.data.title,
        publishedAt: updatedFormulaDetails.data.data.publishedAt,
        elements: updatedFormulaDetails.data.data.elements,
        materials: updatedFormulaDetails.data.data.materials,
        hiddenValues: updatedFormulaDetails.data.data.hiddenValues,
        clientContract: updatedFormulaDetails.data.data.clientContract,
        photo: updatedFormulaDetails.data.data.photo,
        catalogs: updatedFormulaDetails.data.data.catalogs,
      };
      setPublishedFormula(JSON.stringify(publishedFormulaDetails));
      setFormulaDetails(updatedFormulaDetails.data.data);
      message.success({ content: "Saved", key, duration: 1 });
      setTimeout(() => {
        setIsAddingElement(null);
        setIsAddingMaterial(false);
      }, 500);
    } else {
      setIsAddingElement(null);
      setIsAddingMaterial(false);
    }
  }

  async function publish() {
    setPublishing(true);
    const key = "updatable";
    message.loading({ content: "Publishing...", key, duration: 0 });
    const updatedFormulaDetails = await publishChanges(formulaDetails._id);
    if (updatedFormulaDetails.remote === "success") {
      setPublishedAt(date);
      message.success({ content: "Published", key, duration: 1 });
    } else {
      message.error({ content: "Something Went Wrong", key, duration: 1 });
    }
    setPublishing(false);
  }
  async function unpublishAPI() {
    setPublishing(true);
    const key = "updatable";
    message.loading({ content: "Unpublishing...", key, duration: 0 });
    const updatedFormulaDetails = await unpublishChanges(formulaDetails._id);
    if (updatedFormulaDetails.remote === "success") {
      setPublishedAt("");
      message.success({ content: "Unpublished", key, duration: 1 });
    } else {
      message.error({ content: "Something Went Wrong", key, duration: 1 });
    }
    setPublishing(false);
  }

  React.useEffect(() => {
    if (Object.keys(formulaDetails || {}).length) {
      const newElements = (
        formulaDetails.elements.length > elementList.length
          ? formulaDetails.elements
          : elementList
      ).map((element, idx) => {
        const newElement = elementList[idx];
        if (newElement) {
          if (element.customInput) {
            newElement.customInput = element.customInput;
          }
          return { ...element, ...newElement };
        }
        return element;
      });
      const newMaterials = formulaDetails.materials.map((material, idx) => {
        const newMaterial = materials[idx];
        if (newMaterial) {
          return { ...material, ...newMaterial };
        }
        return material;
      });
      const newHiddenValues = formulaDetails.hiddenValues.map(
        (hiddenValue, idx) => {
          const newHiddenValue = hiddenValues[idx];
          if (newHiddenValue) {
            return { ...hiddenValue, ...newHiddenValue };
          }
          return hiddenValue;
        },
      );
      setElementList(newElements);
      setMaterials(newMaterials);
      setHiddenValues(newHiddenValues);
      // setElementList([...formulaDetails.elements]);
      // setMaterials([...formulaDetails.materials]);
      // setHiddenValues([...(formulaDetails.hiddenValues || [])]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulaDetails]);

  const isCustomFieldFetched = (elementId) =>
    customFields.find((item) => item.element._id === elementId);

  const getSubCatalogDetailsAndCustomFields = async (subCatalogArray) => {
    let results = [];
    subCatalogArray.forEach((subCatalog) => {
      results.push(getCatalogDetailsBySuperId(subCatalog.dropdown));
    });
    results = await Promise.all(results);
    const customFieldsFetched = [...customFields];
    results.forEach((result, idx) => {
      if (result.remote === "success") {
        customFieldsFetched.push({
          customFields: result.data.data.customFields,
          element: subCatalogArray[idx],
        });
      }
    });
    setCustomFields(customFieldsFetched);
  };

  const fetchVariation = async (dropdownElements) => {
    const results = [];
    for (let i = 0; i < dropdownElements.length; i++) {
      results.push(getCatalogDetailsBySuperId(dropdownElements[i].dropdown));
    }
    await Promise.all(results);
    // const response = await getVariationBySuperCatalogId(id);
    // if (response.remote === "success") {
    //   setVariations({ ...variations, [id]: response.data.data });
    // }
  };

  React.useEffect(() => {
    let newDropdownElements = [];
    elementList.forEach((elem) => {
      if (elem.dropdown) {
        if (!isCustomFieldFetched(elem._id)) {
          newDropdownElements.push({
            _id: elem._id,
            name: elem.name,
            dropdown: elem.dropdown,
          });
        }
      }
    });
    newDropdownElements = newDropdownElements.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t._id === value._id),
    );
    fetchVariation(newDropdownElements);
    getSubCatalogDetailsAndCustomFields(newDropdownElements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementList]);

  const getElementCustomFields = React.useCallback(
    (elementId) =>
      customFields.find((element) => element.element._id === elementId),
    [customFields],
  );

  const filterUserCatalogItems = (values, catalogId) => {
    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      if (value.includes(catalogId)) {
        return true;
      }
    }
    return false;
  };

  React.useEffect(() => {
    if (isUpdatedElementName.id) {
      const elementsNeedToUpdate = elementList.filter(
        (element) =>
          !["prefilled", "dropdown"].includes(element.type) ||
          element.isConditional,
      );
      // const elementsNeedToUpdate = [...elementList];
      const updatedElement = elementList.find(
        (element) => element._id === isUpdatedElementName.id,
      );
      let customFields;
      const replaceElements = [];
      if (updatedElement.type === "dropdown") {
        customFields = getElementCustomFields(updatedElement._id).customFields;
        const elementVariations = variations[updatedElement._id];
        if (elementVariations) {
          elementVariations.forEach((variation) => {
            const oldElementStyle = `%{{variation||${variation.superId}||(${isUpdatedElementName.prevName}: ${variation.name})}}`;
            const oldElementRegex = new RegExp(
              oldElementStyle.replace(regex.escapeRegex, "\\$&"),
              "gi",
            );
            const newElementRegex = `%{{variation||${variation.superId}||{${updatedElement.name}: ${variation.name}}}}`;
            replaceElements.push({ oldElementRegex, newElementRegex });
          });
        }
      }
      if (customFields) {
        customFields.forEach((field) => {
          const oldElementStyle = `@{{element||${isUpdatedElementName.id}-${
            field.superId || field._id
          }||${isUpdatedElementName.prevName} - ${field.fieldName}}}`;
          const oldElementRegex = new RegExp(
            oldElementStyle.replace(regex.escapeRegex, "\\$&"),
            "gi",
          );
          const newElementRegex = `@{{element||${isUpdatedElementName.id}-${
            field.superId || field._id
          }||${updatedElement.name} - ${field.fieldName}}}`;
          replaceElements.push({
            oldElementRegex,
            newElementRegex,
          });
        });
        const oldElementStyle = `@{{element||${isUpdatedElementName.id}||${isUpdatedElementName.prevName}}}`;
        const oldElementRegex = new RegExp(
          oldElementStyle.replace(regex.escapeRegex, "\\$&"),
          "gi",
        );
        const newElementRegex = `@{{element||${updatedElement._id}||${updatedElement.name}}}`;
        replaceElements.push({ oldElementRegex, newElementRegex });
      } else {
        const oldElementStyle = `@{{element||${isUpdatedElementName.id}||${isUpdatedElementName.prevName}}}`;
        const oldElementRegex = new RegExp(
          oldElementStyle.replace(regex.escapeRegex, "\\$&"),
          "gi",
        );
        const newElementRegex = `@{{element||${updatedElement._id}||${updatedElement.name}}}`;
        replaceElements.push({ oldElementRegex, newElementRegex });
      }
      const newUpdatedElements = [];
      // update elements
      for (let i = 0; i < elementsNeedToUpdate.length; i++) {
        const element = { ...elementsNeedToUpdate[i] };
        for (let k = 0; k < replaceElements.length; k++) {
          const { oldElementRegex, newElementRegex } = replaceElements[k];
          element.value = element.value.replace(
            oldElementRegex,
            newElementRegex,
          );
          if (element.isConditional && element.conditions) {
            element.conditions.expression =
              element.conditions.expression?.replace(
                oldElementRegex,
                newElementRegex,
              );
          }
        }
        newUpdatedElements.push(element);
      }
      const newElementList = elementList.map((element) => {
        const isUpdatedElement = newUpdatedElements.find(
          (elm) => elm._id === element._id,
        );
        if (isUpdatedElement) {
          return isUpdatedElement;
        }
        return element;
      });
      setElementList(newElementList);

      // update materials
      const materialsNeedToUpdate = materials.map((material) => {
        const isElementInQuantity = material.quantity.includes(
          isUpdatedElementName.id,
        );
        const isElementInUnitCost = material.unitCost?.includes(
          isUpdatedElementName.id,
        );
        const isElementInCost = material.cost.includes(isUpdatedElementName.id);
        const isElementInCharge = material.charge.includes(
          isUpdatedElementName.id,
        );
        const isElementInConditionalExpression =
          material.conditions?.expression?.includes(isUpdatedElementName.id);
        for (let k = 0; k < replaceElements.length; k++) {
          const { oldElementRegex, newElementRegex } = replaceElements[k];
          if (isElementInQuantity) {
            material.quantity = material.quantity.replace(
              oldElementRegex,
              newElementRegex,
            );
          }
          if (isElementInCharge) {
            material.charge = material.charge.replace(
              oldElementRegex,
              newElementRegex,
            );
          }
          if (isElementInCost) {
            material.cost = material.cost.replace(
              oldElementRegex,
              newElementRegex,
            );
          }
          if (isElementInUnitCost) {
            material.unitCost = material.unitCost.replace(
              oldElementRegex,
              newElementRegex,
            );
          }
          if (isElementInConditionalExpression) {
            material.conditions.expression =
              material.conditions.expression.replace(
                oldElementRegex,
                newElementRegex,
              );
          }
        }
        return material;
      });

      setMaterials(materialsNeedToUpdate);
      // update Hidden Values
      const updatedHiddenValues = hiddenValues.map((value) => {
        const isElementInExpression = value.expression.condition.includes(
          isUpdatedElementName.id,
        );
        const isElementInValues = value.value.includes(isUpdatedElementName.id);
        for (let k = 0; k < replaceElements.length; k++) {
          const { oldElementRegex, newElementRegex } = replaceElements[k];

          if (isElementInExpression) {
            value.expression.condition = value.expression.condition.replace(
              oldElementRegex,
              newElementRegex,
            );
          }
          if (isElementInValues) {
            value.value = value.value.replace(oldElementRegex, newElementRegex);
          }
        }
        return value;
      });
      setHiddenValues(updatedHiddenValues);

      // update client contract wording
      let newClientContract = clientContract;
      for (let k = 0; k < replaceElements.length; k++) {
        const { oldElementRegex, newElementRegex } = replaceElements[k];
        newClientContract = clientContract.replace(
          oldElementRegex,
          newElementRegex,
        );
      }
      setClientContract(newClientContract);
      // reset state
      setIsUpdatedElementName({});
      debouncedSave({
        newElements: newElementList,
        newMaterials: materialsNeedToUpdate,
        newHiddenValues: updatedHiddenValues,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatedElementName, elementList]);

  React.useEffect(() => {
    (async () => {
      const newProcessedElementList = [];
      if (updateProcessedElement) {
        const element = elementList.find(
          (elem) => elem._id === updateProcessedElement,
        );
        const res = await getCatalogDetailsBySuperId(element.dropdown);
        if (res.remote === "success") {
          const { customFields } = res.data.data;
          setCustomFields((prevState) =>
            (prevState || []).map((custom) => {
              if (custom.element._id === updateProcessedElement) {
                return {
                  customFields,
                  element,
                };
              }
              return custom;
            }),
          );
        }
      }
      elementList.forEach((element) => {
        if (element.type === "dropdown") {
          const elementCustomFields = getElementCustomFields(element._id);
          if (elementCustomFields) {
            const { customFields } = elementCustomFields;
            customFields.forEach((fields) => {
              newProcessedElementList.push({
                display: `${element.name} - ${fields.fieldName}`,
                id: `${element._id}-${fields.superId || fields._id}`,
              });
            });
            if (!customFields.length) {
              newProcessedElementList.push({
                display: element.name,
                id: element._id,
              });
            }
          }
        } else {
          const newElement = {
            display: element.name,
            id: element._id,
          };
          newProcessedElementList.push(newElement);
        }
        if (element.customInput) {
          element.customInput.forEach((customInput) => {
            newProcessedElementList.push({
              display: `${element.name} - ${customInput.name}`,
              id: `${element._id}-${customInput.id}`,
            });
          });
        }
      });
      setProcessedElementList(newProcessedElementList);
      setUpdateProcessedElement("");
    })();
  }, [elementList, getElementCustomFields, updateProcessedElement]);

  async function getUnitList() {
    const response = await GetAllUnits();
    if (response.remote === "success") {
      setUnits(response.data.userData);
    }
  }
  React.useEffect(() => {
    getUnitList();
  }, []);
  const saveFormDataToApi = ({
    newMaterials,
    newElements,
    newHiddenValues,
  }) => {
    const materialsToSave = newMaterials || materials;
    let elementsToSave =
      newElements && newElements.length > 0 ? newElements : elementList;
    const hiddenElementsToSave =
      newHiddenValues > 0 ? newHiddenValues : hiddenValues;
    elementsToSave = elementsToSave.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t._id === value._id),
    );
    // start
    let isValid = true;
    for (let i = 0; i < elementsToSave.length; i++) {
      if (!isValid) {
        break;
      }
      if (!elementList[i].isValid) {
        isValid = false;
        break;
      }
    }
    for (let i = 0; i < materialsToSave.length; i++) {
      if (!isValid) {
        break;
      }
      if (
        !materialsToSave[i].isValidcharge ||
        !materialsToSave[i].isValidcost ||
        !materialsToSave[i].isValidquantity ||
        !materialsToSave[i].isValidUnitCost
      ) {
        isValid = false;
        break;
      }
    }
    const catalogsValue = catalogs.filter(
      (item, pos) => catalogs.indexOf(item) === pos,
    );
    const formData = new FormData();
    elementsToSave.forEach((item) => {
      item.formula =
        item.formula?.filter((element) =>
          filterUserCatalogItems([item.value], element),
        ) || [];
      return item;
    });
    materialsToSave.forEach((item) => {
      item.formula = item.formula.filter((element) =>
        filterUserCatalogItems(
          [item.quantity, item.unitCost, item.cost, item.charge],
          element,
        ),
      );
      return item;
    });
    const allValues = [];
    elementsToSave.forEach((item) => {
      allValues.push(item.value);
    });
    materialsToSave.forEach((item) => {
      allValues.push(item.quantity);
      allValues.push(item.unitCost);
      allValues.push(item.cost);
      allValues.push(item.charge);
    });
    const newCatalogsValueList = catalogsValue.filter((item) =>
      filterUserCatalogItems(allValues, item),
    );
    setElementList(elementsToSave);
    formData.append("elements", JSON.stringify(elementsToSave));
    formData.append("catalogs", JSON.stringify(newCatalogsValueList));
    formData.append("materials", JSON.stringify(materialsToSave));
    formData.append("hiddenValues", JSON.stringify(hiddenElementsToSave));
    formData.append("clientContract", clientContract);
    formData.append("clientContractV2", JSON.stringify(clientContractV2));
    formData.append("title", title);
    formData.append("displayName", displayName);
    formData.append("category", category);
    formData.append("subCategory", subCategory);
    formData.append("publishedAt", publishedAt || null);
    formData.append("isValid", isValid);
    formData.append("photo", file || photo);

    updateFormulaDetails(formData);
    // end
  };
  const debouncedSave = debounce((e) => {
    if (!manualSave) {
      saveFormDataToApi(e);
    }
  }, 1000); // Debounce for 1 second

  const handleChange = (value, name, index, newMaterial, customIndex) => {
    let isElementNameUpdate = false;
    const newElementList = elementList; // if we copy the array, it cause the speed issues
    const prevName = newElementList[index][name];
    newElementList[index][name] = value;
    if (newElementList[index].type !== "dropdown") {
      newElementList[index].dropdown = "";
    }
    switch (name) {
      case "customFieldToShow":
        newElementList[index].customFieldToShow = value;
        break;
      case "value":
        const isValid = validateArthmaticString(value.trim());
        newElementList[index].isValid = isValid.isValid;
        break;
      case "name":
        if (!value) {
          newElementList[index].isValid = false;
        } else {
          newElementList[index].isValid = true;
        }
        const customInput = value.match(regex.customInput);
        if (customInput) {
          newElementList[index].customInput = customInput.map((item) => {
            item = item.replace("!", "");
            const isExists = (newElementList[index].customInput || []).find(
              (cuInput) => cuInput.name === item,
            );
            if (isExists) {
              return isExists;
            }
            return {
              id: Date.now().toString(),
              name: item,
              value: 0,
            };
          });
        } else {
          newElementList[index].customInput = [];
        }
        // update state so that it can update the element name in the other boxes if it is used
        setIsUpdatedElementName({
          id: newElementList[index]._id,
          prevName,
          elementList,
        });
        isElementNameUpdate = true;
        break;
      case "dropdown":
        setUpdateProcessedElement(newElementList[index]._id);
        break;
      default:
        break;
    }
    if (customIndex !== null && customIndex !== undefined) {
      newElementList[index].customInput[customIndex].value = value;
    }
    if (newMaterial) {
      const processed = processMaterial(newMaterial);
      newElementList[index].formula = [
        ...new Set([
          ...(newElementList[index].formula || []),
          ...processed,
          ...(catalogs || []),
        ]),
      ];
      setCatalogs([...(catalogs || []), ...processed]);
    }
    setElementList(newElementList);
    if (!isElementNameUpdate) {
      debouncedSave({});
    }
  };

  const saveNewElement = async (newElement, oldMaterials, at) => {
    const body = {
      element: newElement,
    };
    const response = await addFormulaNewElement(formulaDetails._id, body);
    if (response.remote === "success") {
      const newElementList = [...oldMaterials];
      // newElementList[newElementList.length - 4] = {
      //   ...response.data.data,
      //   disabled: false,
      //   automatic: false,
      // };
      newElementList[at] = {
        ...response.data.data,
        disabled: false,
        automatic: false,
      };
      setElementList([...newElementList]);
      setTimeout(() => {
        setIsAddingElement(null);
      }, 500);
    } else {
      // setIsAddingElement(null);
    }
  };

  const isElementIdExists = () => {
    let isExists = true;
    elementList.forEach((item) => {
      if (!item._id) {
        isExists = false;
      }
    });
    return isExists;
  };

  const handleNewElement = (at) => {
    if (!isLoadingFirstTime && isAddingElement === null && isElementIdExists) {
      // setIsLoading(elementList.length - 3);
      setIsAddingElement(at);
      const newElement = {
        name: "",
        color: "#00AC07",
        type: "prefilled",
        unit: "",
        value: 0,
        view: [
          "client",
          // "internal",
          "full",
        ],
        isValid: false,
      };
      const newElementList = elementList.map((elem) => ({ ...elem }));
      newElementList.splice(at, 0, newElement);
      // setElementList(newElementList);
      saveNewElement(newElement, newElementList, at);
    }
  };

  const processMaterial = (text) => {
    // eslint-disable-next-line no-useless-escape
    const tags = text.match(regex.materialInput) || [];
    const allMaterialsIds = tags.map((myTag) => {
      const tagData = myTag.slice(3, -2);
      const tagDataArray = tagData.split("||");
      if (tagDataArray[0] === "catalog") {
        return tagDataArray[1];
      }
      return null;
    });
    return allMaterialsIds.filter((elem) => elem);
  };

  const handleMaterialChange = (e, index, material) => {
    const newMaterials = [...materials];
    newMaterials[index][e.target.name] = e.target.value;
    if (e.target.name === "type") {
      if (e.target.value === FORMULA_MATERIAL_TYPES.labor) {
        newMaterials[index].numberOfWorkers =
          newMaterials[index].numberOfWorkers === undefined
            ? '{Company Settings: "# Of Workers"}'
            : newMaterials[index].numberOfWorkers;
      }
    }
    if (e.target.name === "name") {
      let name = e.target.value;
      const elem = name.match(regex.materialInput) || [];

      elem.forEach((nam) => {
        const elemName = nam.split("||")[2];
        name = name.replace(nam, elemName.replace("}}", ""));
      });
      name = name.trim();
      setIsUpdatedLineItemName({
        [`${newMaterials[index]._id}-quantity`]: `${name} - Quantity`,
        [`${newMaterials[index]._id}-unit`]: `${name} - Unit`,
        [`${newMaterials[index]._id}-unitCost`]: `${name} - Unit Cost`,
        [`${newMaterials[index]._id}-cost`]: `${name} - Cost`,
        [`${newMaterials[index]._id}-charge`]: `${name} - Charge`,
      });
      setToggleLineItemNameUpdate((prevState) => !prevState);
    }
    if (
      e.target.name === "quantity" ||
      e.target.name === "cost" ||
      e.target.name === "charge" ||
      e.target.name === "unitCost"
    ) {
      const isValid = validateArthmaticString(e.target.value.trim());
      if (e.target.name === "unitCost") {
        newMaterials[index].isValidUnitCost = isValid.isValid;
      } else {
        newMaterials[index][`isValid${e.target.name}`] = isValid.isValid;
      }
    }
    if (!e.target.manual && !newMaterials[index].manual) {
      newMaterials[index].charge = `{Cost} * @{{element||${markupId}||Markup}}`;
    } else {
      newMaterials[index].manual = true;
    }
    if (material) {
      const processed = processMaterial(material);
      newMaterials[index].formula = [
        ...new Set([...(newMaterials[index].formula || []), ...processed]),
      ];
      setCatalogs([...(catalogs || []), ...processed]);
    }
    setMaterials([...newMaterials]);
    // debouncedSave([...newMaterials]);
  };
  const handleHiddenValueChange = (e, index, isConditional, newMaterial) => {
    const newHiddenValues = [...hiddenValues];
    if (newHiddenValues[index]) {
      if (isConditional) {
        newHiddenValues[index].expression[e.target.name] = e.target.value;
      } else {
        newHiddenValues[index][e.target.name] = e.target.value;
      }
    }
    if (newMaterial) {
      const processed = processMaterial(newMaterial);
      newHiddenValues[index].formula = [
        ...new Set([...(newHiddenValues[index].formula || []), ...processed]),
      ];
      setCatalogs([...(catalogs || []), ...processed]);
    }
    setHiddenValues([...newHiddenValues]);
    debouncedSave({});
  };

  const onFocusOut = () => {
    debouncedSave({});
  };

  const handleAddMaterial = (at) => {
    setIsAddingMaterial(true);
    const newMaterial = {
      name: "",
      quantity: "",
      cost: "{Quantity} * ",
      charge: "",
      formula: [],
    };
    const newMaterialsList = materials.map((elem) => ({ ...elem }));
    newMaterialsList.splice(at, 0, newMaterial);
    setMaterials(newMaterialsList);
    debouncedSave({ newMaterials: newMaterialsList });
  };

  const handleAddHiddenValue = () => {
    const newHiddenValue = {
      name: "",
      value: "",
      isConditional: true,
      expression: { condition: "", fullfill: "", fail: "" },
    };
    setHiddenValues([...hiddenValues, newHiddenValue]);
    debouncedSave({ newHiddenValues: [...hiddenValues, newHiddenValue] });
  };

  const handleRemoveElement = (id) => {
    const newElementList = [...elementList].filter((elem) => elem._id !== id);
    setElementList([...newElementList]);
    debouncedSave({ newElements: newElementList });
  };

  const handleRemoveMaterial = (index) => {
    const newMaterialList = [...materials];
    newMaterialList.splice(index, 1);
    setMaterials([...newMaterialList]);
    const data = {
      newMaterials: [...newMaterialList],
    };
    debouncedSave(data);
  };

  useEffect(
    () =>
      // Clean up the debounced function
      () => {
        debouncedSave.cancel();
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const role = React.useMemo(() => getRole(), []);

  const handleDeleteHiddenValue = (id, toggleFun) => {
    const newHiddenValues = [...hiddenValues].filter((item) => item._id !== id);
    setHiddenValues([...newHiddenValues]);
    if (toggleFun) {
      toggleFun(false);
    }
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  const getAllCatalogsAndServices = async () => {
    setLoading(true);
    let result;
    const searchQuery = params.search.trim();
    const query = new URLSearchParams(searchQuery);
    const show = query.get("show");
    if (show === "old") {
      result = await searchOldCatalogByName("", []);
    } else {
      result = await searchCatalogByName("", []);
    }
    if (result.remote === "success") {
      if (result?.data?.data) {
        setCatalogsAndServices(
          result.data.data.map((item) => ({
            display: item.name,
            slug: item.slug,
            id: item.superId,
            name: item.name,
            superId: item.superId,
          })),
        );
        setLoading(false);
      }
    }
    let subCatalog;
    if (show === "old") {
      subCatalog = await searchOldCatalogByName("", [
        "subCatalog",
        "subContractor",
      ]);
    } else {
      subCatalog = await searchCatalogByName("", [
        "subCatalog",
        "subContractor",
      ]);
    }
    if (subCatalog.remote === "success") {
      const subCatalogList = subCatalog.data.data.map((item) => ({
        display: item.name,
        slug: item.slug,
        id: item.superId,
        name: item.name,
        superId: item.superId,
        type: item.type,
        customFields: item.customFields,
      }));
      setSubCatalogItems(subCatalogList);
    }
  };

  return loading ? (
    <SmallLoader />
  ) : (
    <>
      <BreadcrumbBar
        breaclass="mb-3"
        name="Dashboard"
        subname="Create Template"
        link="/"
      />
      <Card
        className="shadow estimate-card mb-4"
        style={{ borderRadius: "10px" }}
      >
        <div className="furmulla-tree d-flex align-items-center justify-content-center flex-column stick-add-formula">
          <div className="furmulla-tree-box d-inline-flex ant-cover-b ant-cover-primary p-4">
            <Row gutter={[24, 0]} className="align-items-center mb-3">
              <Switch
                checked={manualSave}
                onChange={(e) => setManualSave(e)}
                checkedChildren="Manual Save"
                unCheckedChildren="Autosave"
              />
            </Row>
            <Row gutter={[24, 0]} className="align-items-center mb-3">
              <Col span={8} xs={24}>
                <label>Name Service:</label>
              </Col>
              <Col span={16} xs={24}>
                <Input
                  placeholder="Title"
                  className="ant-furmulla-input"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  onBlur={onFocusOut}
                />
              </Col>
            </Row>
            {role === USER_ROLE.superAdmin && (
              <>
                <Row gutter={[24, 0]} className="align-items-center mb-3">
                  <Col span={8} xs={24}>
                    <label>Category:</label>
                  </Col>
                  <Col span={16} xs={24}>
                    <Category setCategory={setCategory} category={category} />
                  </Col>
                </Row>
                <Row gutter={[24, 0]} className="align-items-center mb-3">
                  <Col span={8} xs={24}>
                    <label>Sub Category:</label>
                  </Col>
                  <Col span={16} xs={24}>
                    <SubCategory
                      category={category}
                      subCategory={subCategory}
                      setSubCategory={setSubCategory}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row gutter={[24, 0]} className="align-items-center">
              <Col span={8} xs={24}>
                <label>Display Name:</label>
              </Col>
              <Col span={16} xs={24}>
                <Input
                  placeholder="Title"
                  className="ant-furmulla-input"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  onBlur={onFocusOut}
                />
              </Col>
            </Row>
          </div>
          <span
            className="ant-cricle-add"
            onClick={() => handleNewElement(elementList.length - 3)}
          >
            {treeIcon}
          </span>
        </div>
        <Row gutter={[24, 0]} className="pt-4 tree-line-furmulla">
          {isLoadingFirstTime ? (
            <div className="text-center d-flex align-items-center justify-content-center ht-100">
              <span className="">
                <SmallLoader />
              </span>
            </div>
          ) : (
            elementList.length &&
            elementList.map((element, index) => (
              <React.Fragment key={element._id}>
                <ElementCard
                  element={element}
                  handleChange={handleChange}
                  idx={index}
                  elementList={elementList}
                  onFocusOut={onFocusOut}
                  handleRemoveElement={handleRemoveElement}
                  handleNewElement={handleNewElement}
                  hiddenValueList={hiddenValues}
                  isLoading={isAddingElement}
                  catalogsAndServices={catalogsAndServices}
                  subCatalogItems={subCatalogItems}
                  processedElementList={processedElementList}
                  setVariations={setVariations}
                />
              </React.Fragment>
            ))
          )}
        </Row>

        <div className="mobile-overflow" style={{ overflowX: "auto" }}>
          <table className="table ant-furmulla-table table-hover">
            <thead>
              <tr>
                <th colSpan="9">
                  <span>Line Items Needed</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoadingFirstTime ? (
                <div className="text-center d-flex align-items-center justify-content-center ht-100">
                  <span className="">
                    <SmallLoader />
                  </span>
                </div>
              ) : (
                materials.map((material, index) => (
                  <MaterialCard
                    key={index}
                    isAddingMaterial={isAddingMaterial}
                    material={material}
                    handleChange={handleMaterialChange}
                    index={index}
                    onFocusOut={onFocusOut}
                    handleRemoveMaterial={handleRemoveMaterial}
                    hiddenValueList={hiddenValues}
                    catalogsAndServices={catalogsAndServices}
                    units={units}
                    processedElementList={processedElementList}
                    elementList={elementList}
                    handleAddMaterial={handleAddMaterial}
                    subCatalogItems={subCatalogItems}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>
        <span
          className="ant-add-material mb-3 d-inline-block"
          onClick={() => handleAddMaterial(materials.length)}
        >
          Add New Material: {treeIcon}
        </span>
        <div className="mobile-overflow">
          <table className="table ant-furmulla-table table-hover">
            <thead>
              <tr>
                <th colSpan="6">
                  <span>Hidden Values</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoadingFirstTime ? (
                <div className="text-center d-flex align-items-center justify-content-center ht-100">
                  <span className="">
                    <SmallLoader />
                  </span>
                </div>
              ) : (
                hiddenValues.map((hiddenValue, index) => (
                  <HiddenValueCard
                    hiddenValue={hiddenValue}
                    index={index}
                    handleChange={handleHiddenValueChange}
                    key={hiddenValue._id || index}
                    elementList={elementList}
                    hiddenValueList={hiddenValues.filter(
                      (hidden) => hidden._id !== hiddenValue._id,
                    )}
                    handleDeleteHiddenValue={handleDeleteHiddenValue}
                    catalogsAndServices={catalogsAndServices}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>
        <span
          className="ant-add-material mb-3 d-inline-block"
          onClick={handleAddHiddenValue}
        >
          Add New Hidden Value: {treeIcon}
        </span>
        <div className="">
          <ClientContractV2
            getElementCustomFields={getElementCustomFields}
            processedElementList={processedElementList}
            elementList={elementList}
            onFocusOut={onFocusOut}
            clientContractV2={clientContractV2}
            setClientContractV2={setClientContractV2}
            hiddenValueList={hiddenValues}
            processedLineItems={processedLineItems}
            isUpdatedLineItemName={isUpdatedLineItemName}
            toggleLineItemNameUpdate={toggleLineItemNameUpdate}
            setIsUpdatedLineItemName={setIsUpdatedLineItemName}
          />
        </div>

        <div className="mobile-overflow">
          <table className="table ant-furmulla-table">
            <thead>
              <tr>
                <th>
                  <span>Select Photo - Client Contract</span>
                </th>
              </tr>
            </thead>
            <tr>
              <td className="p-0">
                <div className="border p-3 radius-4 line-height-40 min-height">
                  Choose Photo:{" "}
                  <ImgCrop aspect={150 / 95}>
                    <Upload
                      ghost
                      maxCount={1}
                      showUploadList={false}
                      customRequest={onChange}
                    >
                      <Tooltip title="Photo">
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<UploadOutlined />}
                        />
                      </Tooltip>
                    </Upload>
                  </ImgCrop>
                  <img
                    src={createValidUrl(photo)}
                    style={{ width: "130px", height: "95px" }}
                    alt=""
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div className="ant-floating" style={{ zIndex: "9999" }}>
          <Button type="primary" onClick={() => saveFormDataToApi({})}>
            <SaveOutlined />
          </Button>
          {role === "superAdmin" && (
            <Button
              type="primary"
              loading={publishing}
              onClick={() => publish()}
              disabled={publishedFormula === formulaDetails?.publishedFormula}
              style={{ zIndex: "999" }}
            >
              <CloudUploadOutlined />
            </Button>
          )}
          {role === "superAdmin" && (
            <Button
              type="primary"
              loading={publishing}
              onClick={() => unpublishAPI()}
              disabled={publishedFormula === formulaDetails?.publishedFormula}
              style={{ zIndex: "999" }}
            >
              {unpublish}
            </Button>
          )}
        </div>
      </Card>
    </>
  );
}

export default FormulaV2;
