/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useContext, useState } from "react";

import api from "../../api/api";
import {
  SEND_BULK_LEADS_EMAIL,
  SEND_BULK_LEADS_SMS,
} from "../constants/api-urls";
import { LoaderContext } from "./loader-context";

export const CRMContext = createContext();
export const CRMProvider = ({ children }) => {
  const [isEmailSuccess, setEmailSuccess] = useState(false);
  const { setLoading } = useContext(LoaderContext);
  const value = {
    isEmailSuccess,
    setEmailSuccess,
    onSendBulkEmails: useCallback(
      async (values) => {
        try {
          setLoading(true);
          const result = await api.request({
            url: SEND_BULK_LEADS_EMAIL,
            method: "POST",
            data: values,
          });
          if (result?.remote === "success") {
            setEmailSuccess(true);
            setLoading(false);
          }
        } catch (error) {}
      },
      [setLoading, setEmailSuccess],
    ),
    onSendBulkSMSs: useCallback(async (values) => {
      try {
        await api.request({
          url: SEND_BULK_LEADS_SMS,
          method: "POST",
          data: values,
        });
      } catch (error) {}
    }, []),
  };
  return <CRMContext.Provider value={value}>{children}</CRMContext.Provider>;
};
