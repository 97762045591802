import { setLoading } from "../redux/affiliate/affiliate.action";
import api from "./api";

export const registerAffiliate = async (data) => {
  const response = await api.request({
    url: `/v3/affiliate/auth/sign-up`,
    method: "POST",
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};
export const loginAffiliate = async (data) => {
  const response = await api.request({
    url: `/v3/affiliate/auth/sign-in`,
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const surveyAffiliate = async (data, dispatch) => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  dispatch(setLoading(true));
  const response = await api.request({
    url: `/v3/affiliate/profile/survey`,
    method: "POST",
    data,
    token: affiliateToken,
  });

  if (response.remote === "success") {
    dispatch(setLoading(false));
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  dispatch(setLoading(false));
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const alreadyHaveAccount = async (data) => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  const response = await api.request({
    url: `/v3/affiliate/profile/existing-email`,
    method: "POST",
    data,
    token: affiliateToken,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
    statusCode: response.errors.status,
  };
};

export const skippingExistingAccount = async (data) => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  const response = await api.request({
    url: `/v3/affiliate/profile/has-email`,
    method: "POST",
    data,
    token: affiliateToken,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const sendForgotPasswordLink = async (data, dispatch) => {
  dispatch(setLoading(true));
  const response = await api.request({
    url: `/v3/affiliate/auth/forgot-password-link`,
    method: "POST",
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const updateForgotPassword = async (data, dispatch) => {
  dispatch(setLoading(true));
  const response = await api.request({
    url: `/v3/affiliate/auth/forgot-password`,
    method: "POST",
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const updateProfile = async (data) => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  const response = await api.request({
    url: "/v3/affiliate/profile",
    method: "PUT",
    data,
    token: affiliateToken,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const verifyOTP = async (codeEndpoint, data, dispatch) => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  dispatch(setLoading(true));
  const response = await api.request({
    url: `/v3/affiliate/profile/${codeEndpoint}`,
    method: "PUT",
    data,
    token: affiliateToken,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const resendOTP = async (data) => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  const response = await api.request({
    url: "/v3/affiliate/profile/code/resend",
    method: "POST",
    data,
    token: affiliateToken,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const sendOTP = async (endpoint, data, dispatch) => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  dispatch(setLoading(true));
  const response = await api.request({
    url: `/v3/affiliate/profile/code/${endpoint}`,
    method: "POST",
    data,
    token: affiliateToken,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    errors: response.errors,
  };
};

export const getProfileDetails = async (url = null, token = null) => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  const response = await api.request({
    url: url || "/v3/affiliate/profile",
    method: "GET",
    token: token || affiliateToken,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getReferralsList = async () => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  const response = await api.request({
    url: "/v3/affiliate/referral/list",
    method: "GET",
    token: affiliateToken,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }

  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const setScheduleDemo = async (data) => {
  const userToken = localStorage.getItem("token");
  const response = await api.request({
    url: "v3/affiliate/referral/landing/schedule-demo",
    method: "POST",
    token: userToken,
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getReferralsOverview = async (start, end) => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  const response = await api.request({
    url: `v3/affiliate/referral/overview?startDate=${start || ""}&endDate=${
      end || ""
    }`,
    method: "GET",
    token: affiliateToken,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getMyReferrals = async (data) => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  const response = await api.request({
    url: `v3/affiliate/referral/my-referral`,
    method: "POST",
    token: affiliateToken,
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getSummaryDetails = async () => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  const response = await api.request({
    url: "v3/affiliate/referral/summary",
    method: "GET",
    token: affiliateToken,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const postPaymentRequest = async (data, setLoading) => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  if (setLoading) setLoading(true);
  const response = await api.request({
    url: "v3/affiliate/referral/payout-request",
    method: "POST",
    token: affiliateToken,
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getReferralHeaders = async (orgId) => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  const response = await api.request({
    url: `v3/affiliate/referral/activity/header?organizationId=${orgId}`,
    method: "GET",
    token: affiliateToken,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getReferralHistory = async (orgId, page = 1, perPage = 10) => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  const response = await api.request({
    url: `v3/affiliate/referral/activity?organizationId=${orgId}&page=${page}&perPage=${perPage}`,
    method: "GET",
    token: affiliateToken,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data?.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getAffiliateMarketerDetails = async (payload = null) => {
  const userToken = localStorage.getItem("token");
  const response = await api.request({
    url: `v3/affiliate/super-admin/dash-board/affiliate-list`,
    method: "POST",
    token: userToken,
    ...(Object.keys(payload || {})?.length && { data: payload }),
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response?.data?.data?.[0],
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const updateAffiliateStatus = async (id, data) => {
  const userToken = localStorage.getItem("token");
  const response = await api.request({
    url: `v3/affiliate/super-admin/dash-board/status/${id}`,
    method: "PUT",
    token: userToken,
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response?.data?.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const updateAffiliateUserEmail = async (data) => {
  const userToken = localStorage.getItem("token");
  const response = await api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/update-email`,
    method: "PUT",
    token: userToken,
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response?.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};
export const updateAffiliateUserPassword = async (data) => {
  const userToken = localStorage.getItem("token");
  const response = await api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/update-password`,
    method: "PUT",
    token: userToken,
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response?.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getPayoutRequestListAPI = async (data) => {
  const response = await api.request({
    url: "v3/affiliate/super-admin/dash-board/payout-request",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: "success",
      data: {
        payoutRequests: response.data.data.data[0].data,
        pagination: response.data.data.pagination,
      },
    };
  }
  return response;
};
export const getLinkNCodeData = async (page, perPage) => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  const response = await api.request({
    url: `v3/affiliate/referral/promo-code-link?page=${page}&perPage=${perPage}`,
    method: "GET",
    token: affiliateToken,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response?.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getSurveyDetails = async (id) => {
  const response = await api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/survey?affiliate=${id}`,
    method: "GET",
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response?.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getSuperAdminDoc = async (id, setLoading) => {
  const response = await api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/documents-payment?affiliate=${id}`,
    method: "GET",
    token: localStorage.getItem("token"),
  });

  if (response.remote === "success") {
    setLoading(false);
    return {
      remote: response.remote,
      data: response?.data,
    };
  }
  setLoading(false);
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const reportDocument = async (data, setLoading) => {
  if (setLoading) setLoading(true);
  const response = await api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/report-documents`,
    method: "POST",
    data,
    token: localStorage.getItem("token"),
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response?.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};
export const changeAffiliateCommission = async (affiliateId, data) => {
  const response = await api.request({
    url: `v3/affiliate/super-admin/dash-board/commission/${affiliateId}`,
    method: "PUT",
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data?.data,
      message: response?.data?.message,
    };
  }
  return {
    remote: response.remote,
    error: response.errors?.errors,
  };
};

export const getReportedList = async (affiliateId, type) => {
  const response = await api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/report-list?affiliate=${affiliateId}&documentType=${type}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data?.data,
      error: response.errors?.errors,
    };
  }
};
export const getReferralsListSuperAdmin = async (affiliateId) => {
  const response = await api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/referral/list?affiliate=${affiliateId}`,
    method: "GET",
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }

  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getSummaryDetailSuperAdmin = async (affiliateId) => {
  const response = await api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/referral/summary?affiliate=${affiliateId}`,
    method: "GET",
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors?.errors,
  };
};
export const removeProofOfPaymentAPI = async ({ payoutRequestId }) => {
  const res = api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/payment-payout/remove-proof/${payoutRequestId}`,
    method: "PUT",
  });
  return res;
};
export const rejectPayoutRequestAPI = async ({ payoutRequestId, data }) => {
  const res = api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/payment-payout/reject/${payoutRequestId}`,
    method: "PUT",
    data,
  });
  return res;
};
export const uploadProofOfPaymentAPI = async ({ data }) => {
  const res = api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/payment-payout/pay`,
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};
export const getLinkNCodeSuperAdminData = async (
  affiliateId,
  page,
  perPage,
) => {
  const response = await api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/promo-code?affiliate=${affiliateId}&page=${page}&perPage=${perPage}`,
    method: "GET",
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response?.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getReferralOverviewSuperAdmin = async (
  affiliateId,
  startDate,
  endDate,
) => {
  const response = await api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/referral/overview?affiliate=${affiliateId}&startDate=${
      startDate || ""
    }&endDate=${endDate || ""}`,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getPaymentSuperAdminData = async (affiliateId) => {
  const response = await api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/payment-payout?affiliate=${affiliateId}`,
    method: "GET",
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response?.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};
export const getReferralSuperAdminAction = async (payload) => {
  const response = await api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/referral/my-referral`,
    method: "POST",
    data: payload,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data?.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getPaymentData = async () => {
  const affiliateToken = localStorage.getItem("affiliateToken");
  const response = await api.request({
    url: `v3/affiliate/referral/payment-payout`,
    method: "GET",
    token: affiliateToken,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response?.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};
export const getReferralSuperAdminHeader = async (affiliateId, orgId) => {
  const response = await api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/activity/header?affiliate=${affiliateId}&organizationId=${orgId}`,
    method: "GET",
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};

export const getReferralSuperAdminActivity = async (affiliateId, orgId) => {
  const response = await api.request({
    url: `v3/affiliate/super-admin/affiliate-dashboard/activity?affiliate=${affiliateId}&organizationId=${orgId}`,
    method: "GET",
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data?.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors,
  };
};
