import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import api from "../../api/api";
import ModalDailog from "../../common/components/ModalDialogue";
import defaultCoverImg from "../../images/defaultCoverPhoto.jpg";
import { setReceiptData } from "../../redux/invoice/invoice.action";
import { createValidUrl } from "../../utils/commonFuctions";
import { DATE_FORMATE } from "../../utils/constants/constants";
import { formatString, toCapitalize } from "../../utils/javascript";
import { NumberFormat } from "../../utils/numberFormat";
import { leftarrow, rightarrow } from "../../utils/svg.file";
import {
  downloadBase64File,
  getBase64OfHTMLDivComponentAsPDF,
} from "../contractpreview/v3/helper";
import SmallLoader from "../loader/smallLoader";
import {
  invoicePaymentData,
  receiptColumn,
  renderStatus,
  renderTableAndFooter,
} from "./helper";
import ViewReceipt from "./ViewReceipt";

const ViewPaymentRecept = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get("token");
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const sendReceiptDetails = useSelector(
    (state) => state?.invoice?.sendReceipt,
  );
  const [viewed, setViewed] = useState(false);
  const [callingView, setCallingView] = useState(false);
  const isEstimationViewed = localStorage.getItem(
    sendReceiptDetails?.receiptData?.[0]?._id,
  );
  const currentIndex = useSelector((state) => state?.invoice?.currentIndex);
  const getReceiptData = async (token) => {
    setLoading(true);
    const response = await api.request({
      url: `v3/invoice/customer/payment-receipt?token=${token}`,
      method: "GET",
    });
    if (response.remote === "success") {
      dispatch(setReceiptData(response.data.data));
    } else {
      navigate("/receipt-not-found", {
        state: {
          data: response.errors.errors.data,
        },
      });
    }
    setLoading(false);
  };

  const paymentDataRow = [
    {
      date_paid: sendReceiptDetails?.receiptData?.[current]?.paymentDate
        ? moment(
            sendReceiptDetails?.receiptData?.[current]?.paymentDate,
          ).format(DATE_FORMATE)
        : "-",
      amount: NumberFormat(
        sendReceiptDetails?.receiptData?.[current]?.amount,
        true,
      ),
      payment_method: sendReceiptDetails?.receiptData?.[current]?.paymentMethod
        ? toCapitalize(
            formatString(
              sendReceiptDetails?.receiptData?.[current]?.paymentMethod,
            ),
          )
        : "-",
    },
  ];
  const receiptDataRow = sendReceiptDetails?.receiptData?.[
    current
  ]?.invoices?.map((obj) => ({
    description: obj?.invoiceName,
    invoice: obj?.invoiceNumber,
    status: renderStatus(obj?.invoiceStatus),
    amount: NumberFormat(obj?.invoiceAmount, true),
    due_date: moment(obj?.dueDate).format(DATE_FORMATE),
    amount_paid: NumberFormat(obj?.paidAmount, true),
    date_paid: sendReceiptDetails?.receiptData?.[current]?.paymentDate
      ? moment(sendReceiptDetails?.receiptData?.[current]?.paymentDate).format(
          DATE_FORMATE,
        )
      : "-",
    balance: obj?.balance
      ? NumberFormat(obj?.balance)
      : NumberFormat(+obj?.amount - +obj?.amountPaid, true),
  }));

  const tableData = {
    paid: {
      column: { invoice: receiptColumn, payment: invoicePaymentData },
      data: { invoice: receiptDataRow, payment: paymentDataRow },
    },
    payment: { column: invoicePaymentData, data: paymentDataRow },
  };
  const handlePrev = () => {
    setCurrent((prev) => prev - 1);
  };
  const handleNext = () => {
    setCurrent((prev) => prev + 1);
  };
  const handleDownloadPdf = async () => {
    setGeneratingPdf(true);
    setTimeout(async () => {
      const base64 = await getBase64OfHTMLDivComponentAsPDF(
        "divToPrint",
        "certificateOfCompletion",
      );
      downloadBase64File(base64, `Zevbit.pdf`);
      setGeneratingPdf(false);
    }, 1000);
  };
  useEffect(() => {
    if (token) {
      getReceiptData(token);
    }
  }, [token]);
  useEffect(() => {
    if (
      sendReceiptDetails?.receiptData?.[currentIndex]?._id &&
      !viewed &&
      !isEstimationViewed
    ) {
      setViewed(true);
    }
  }, [sendReceiptDetails]);
  return (
    <div>
      {" "}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minHeight: "100vh",
          }}
        >
          <SmallLoader />
        </div>
      ) : (
        <div className="px-2 px-md-0">
          <Row justify="center" className="mt-3 mx-973">
            <div id="divToPrint">
              {sendReceiptDetails?.receiptData?.length > 1 && (
                <Col style={{ textAlign: "center" }}>
                  <Button
                    type="ghost"
                    className="border-0"
                    disabled={current === 0}
                    onClick={() => handlePrev()}
                    style={{ verticalAlign: "middle" }}
                  >
                    {rightarrow}
                  </Button>
                </Col>
              )}
              <Col
                xs={24}
                lg={sendReceiptDetails?.receiptData?.length > 1 ? 22 : 24}
              >
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    type="primary"
                    onClick={handleDownloadPdf}
                    disabled={generatingPdf}
                    className="my-2 download-pdf-btn"
                    icon={
                      <>
                        {generatingPdf && <SmallLoader />}
                        <DownloadOutlined />
                      </>
                    }
                  >
                    Download
                  </Button>
                </div>
                <Card bodyStyle={{ padding: "10px" }} className="mt-3">
                  {sendReceiptDetails?.receiptData?.[current]
                    ?.organizationCoverPhoto ? (
                    <img
                      alt=""
                      src={
                        sendReceiptDetails?.receiptData?.[current]
                          ?.organizationCoverPhoto
                          ? createValidUrl(
                              sendReceiptDetails?.receiptData?.[current]
                                ?.organizationCoverPhoto,
                            )
                          : defaultCoverImg
                      }
                      className="rounded-3 w-100"
                    />
                  ) : (
                    ""
                  )}

                  {sendReceiptDetails?.receiptData?.[current]
                    ?.organizationLogo && (
                    <div className="px-4 pt-4">
                      <img
                        alt={`organization-logo -${current}`}
                        src={createValidUrl(
                          sendReceiptDetails?.receiptData?.[current]
                            ?.organizationLogo,
                        )}
                        style={{ width: "155px", height: "74px" }}
                      />
                    </div>
                  )}
                  <div className="mt-3 p-4 d-flex align-items-start justify-content-between">
                    <ul className="list-style">
                      <li>
                        <span className="font-16">
                          {
                            sendReceiptDetails?.receiptData?.[current]
                              ?.organizationName
                          }
                        </span>
                      </li>
                      <li>
                        {`${formatPhoneNumber(
                          `+${
                            sendReceiptDetails?.receiptData?.[current]
                              ?.organizationPhoneNumber || ""
                          }`,
                          "NATIONAL",
                        )}`}
                      </li>
                      <li>
                        {
                          sendReceiptDetails?.receiptData?.[current]
                            ?.organizationEmail
                        }
                      </li>
                      <li>
                        {
                          sendReceiptDetails?.receiptData?.[current]
                            ?.organizationAddress
                        }
                      </li>
                    </ul>
                    <ul className="list-style text-right">
                      <li>
                        Invoice Number <br />{" "}
                        {
                          sendReceiptDetails?.receiptData?.[current]
                            ?.invoiceNumber
                        }
                      </li>

                      <li className="mt-2">
                        Invoice Date <br />{" "}
                        {moment(
                          sendReceiptDetails?.receiptData?.[current]
                            ?.invoiceDate,
                        ).format(DATE_FORMATE)}
                      </li>

                      <li className="mt-2">
                        Due Date <br />
                        {moment(
                          sendReceiptDetails?.receiptData?.[current]?.dueDate,
                        ).format(DATE_FORMATE)}
                      </li>
                    </ul>
                  </div>
                  <div className="p-4 d-flex align-items-start justify-content-between pt-0">
                    <ul className="list-style">
                      <li>
                        <span className="font-16">Billed To:</span>
                      </li>
                      <li>
                        {
                          sendReceiptDetails?.receiptData?.[current]
                            ?.billedToPhoneNumber
                        }
                      </li>
                      <li>
                        {
                          sendReceiptDetails?.receiptData?.[current]
                            ?.billedToName
                        }{" "}
                        <br />
                        {
                          sendReceiptDetails?.receiptData?.[current]
                            ?.billedToAddress
                        }
                      </li>
                    </ul>
                    <ul className="list-style text-right">
                      <li>
                        Total Project <br />{" "}
                        {NumberFormat(
                          sendReceiptDetails?.receiptData?.[current]
                            ?.totalProjectAmount,
                        )}
                      </li>

                      <li className="mt-2">
                        Project Balance <br />
                        {NumberFormat(
                          sendReceiptDetails?.receiptData?.[current]
                            ?.totalProjectAmount -
                            (sendReceiptDetails?.receiptData?.[current]
                              ?.totalPaidAmount ||
                              sendReceiptDetails?.receiptData?.[current]
                                ?.totalPaid ||
                              0),
                          true,
                        )}
                      </li>

                      <li className="mt-2">
                        Total Paid <br />
                        {NumberFormat(
                          sendReceiptDetails?.receiptData?.[current]
                            ?.totalPaid ||
                            sendReceiptDetails?.receiptData?.[current]
                              ?.totalPaidAmount,
                          true,
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="px-2 invoice-title mt-3">
                    {renderTableAndFooter({
                      redirectFrom: "paid",
                      data: sendReceiptDetails?.receiptData?.[current],
                      tableData: tableData.paid,
                    })}
                  </div>
                </Card>
              </Col>
              {sendReceiptDetails?.receiptData?.length > 1 && (
                <Col style={{ textAlign: "center" }}>
                  <Button
                    type="ghost"
                    className="border-0"
                    disabled={
                      current === sendReceiptDetails?.receiptData?.length - 1
                    }
                    onClick={() => handleNext()}
                  >
                    {leftarrow}
                  </Button>
                </Col>
              )}
            </div>
          </Row>
        </div>
      )}
      <ModalDailog isModalOpen={viewed} closeIcon={<></>}>
        <ViewReceipt
          receiptType="receipt"
          callingView={callingView}
          setViewed={setViewed}
          setCallingView={setCallingView}
        />
      </ModalDailog>
    </div>
  );
};

export default ViewPaymentRecept;
