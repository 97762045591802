import "./docNpay.css";

import { Button, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ModalDailog from "../../../../common/components/ModalDialogue";
import { ReactComponent as EyeIcon } from "../../../../images/affiliateImages/eye-icon.svg";
import { ReactComponent as PaymentProofIcon } from "../../../../images/affiliateImages/payment-proof.svg";
import { ReactComponent as QuestionMarkIcon } from "../../../../images/affiliateImages/question-mark.svg";
import { getAffiliatePaymentDataAction } from "../../../../redux/affiliate/affiliate.action";
import { createValidUrl } from "../../../../utils/commonFuctions";
import { convertToUpperCase } from "../../../../utils/javascript";
import TableData from "../../../setting/UserListV2/Table";
import {
  convertToFloating,
  paymentStatus,
  paymentStatusColor,
} from "../helper";
import Preview from "./modals/Preview";
import PayoneerModel from "./Model/PayoneerModel";
import PayPalModel from "./Model/PayPalModel";
import VenmoModel from "./Model/VenmoModel";

const convertStatus = (status) =>
  status
    ? { status: paymentStatus[status], color: paymentStatusColor[status] }
    : "-";

const Payments = ({ isSuperAdmin = false }) => {
  const dispatch = useDispatch();
  const [toggleModal, setToggleModal] = useState({});
  const [formData, setFormData] = useState({});
  const [proofOfPaymentToggle, setProofOfPaymentToggle] = useState(false);
  const [rowData, setRowData] = useState();
  const [file, setFile] = useState("");
  const [extension, setExtension] = useState("");
  const paymentColumns = [
    {
      title: "Payment Number",
      dataIndex: "paymentNumber",
      render: (value) => value || "-",
    },
    {
      title: "Date Requested",
      dataIndex: "dateOfRequested",
      className: "text-center",
      render: (value) => (value ? moment(value).format("MM/DD/YYYY") : "-"),
    },
    {
      title: "Date Of Payment",
      dataIndex: "paymentDate",
      className: "text-center",
      render: (value) => (value ? moment(value).format("MM/DD/YYYY") : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "text-center",
      render: (value, row) => {
        const statusVal = convertStatus(value);
        return (
          <>
            <span style={{ marginRight: 5, color: statusVal?.color }}>
              {statusVal?.status || statusVal}
            </span>
            {value === "error" && (
              <Tooltip
                title={row?.errorDescription}
                overlayClassName="affiliate-payment-tooltip"
                showArrow={false}
              >
                <QuestionMarkIcon
                  style={{ color: "#98A2B3" }}
                  className="cursor-pointer"
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: "Amount Requested",
      dataIndex: "amount",
      className: "text-center",
      render: (value) => (value ? `$${convertToFloating(value)}` : "-"),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      className: "text-center",
      render: (value, row) =>
        value ? (
          <>
            <span style={{ marginRight: 10 }}>{convertToUpperCase(value)}</span>
            {isSuperAdmin && (
              <EyeIcon
                style={{ color: "#101828", cursor: "pointer" }}
                onClick={() => {
                  setToggleModal({ [value]: !toggleModal?.[value] });
                  setFormData({ [value]: { ...row?.[value] } });
                }}
              />
            )}
          </>
        ) : (
          "-"
        ),
    },
    {
      title: "Proof Of Payment",
      dataIndex: "proofOfPayments",
      className: "text-center",
      render: (value, row) =>
        value && (
          <Button
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              const arr = row?.proofOfPayments?.split(".");
              const ext = arr[arr?.length - 1];
              setFile(createValidUrl(row?.proofOfPayments));
              setProofOfPaymentToggle(true);
              setExtension(ext);
              setRowData(row);
            }}
            icon={<PaymentProofIcon style={{ color: "#101828" }} />}
          />
        ),
    },
  ];
  const affiliateId = useSelector((state) => state?.affiliate?.profile?._id);
  const paymentData = useSelector((state) => state?.affiliate?.paymentDetails);

  const handleProofOfPaymentToggle = () => {
    setProofOfPaymentToggle((prevState) => !prevState);
  };

  const downloadFile = async (fileUrl, fileName) => {
    const url = createValidUrl(fileUrl);

    try {
      let extension;
      if (url.startsWith("http")) {
        const arr = url.split("?")[0].split(".");
        extension = arr[arr.length - 1];
      } else if (url.startsWith("data")) {
        // for base64
        const arr = url.split("/")[1].split(";");
        [extension] = arr;
      }
      const response = await fetch(url);
      const blob = await response.blob();

      const fileUrl = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement("a");
      a.href = fileUrl;
      a.download = `${fileName}.${extension}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleCancel = (name) => {
    const toggle = {
      ...toggleModal,
      [name]: !toggleModal?.[name],
    };

    if (!toggle?.[name]) {
      setFormData((prevState) => ({ ...prevState, [name]: {} }));
    }

    setToggleModal(toggle);
  };

  useEffect(() => {
    dispatch(getAffiliatePaymentDataAction(isSuperAdmin, affiliateId));
  }, []);

  return (
    <>
      <div
        className="tablecard"
        style={{ ...(!isSuperAdmin && { maxHeight: 500 }), overflow: "auto" }}
      >
        <TableData
          className="my-referrals-tbl"
          columns={paymentColumns}
          dataSource={paymentData?.data}
          pagination={false}
          {...{ ...(!isSuperAdmin && { scroll: { x: 500 } }) }}
        />
      </div>
      <ModalDailog
        isModalOpen={toggleModal?.paypal}
        className="paypal-modal"
        handleCancel={() => handleCancel("paypal")}
      >
        <PayPalModel
          isSuperAdmin={isSuperAdmin}
          setIsPaypalModel={setToggleModal}
          formData={formData?.paypal}
          handleCancel={handleCancel}
        />
      </ModalDailog>
      <ModalDailog
        isModalOpen={toggleModal?.venmo}
        className="venmo-modal"
        handleCancel={() => handleCancel("venmo")}
      >
        <VenmoModel
          isSuperAdmin={isSuperAdmin}
          setIsVenmoModel={setToggleModal}
          formData={formData?.venmo}
          handleCancel={handleCancel}
        />
      </ModalDailog>
      <ModalDailog
        isModalOpen={toggleModal?.payoneer}
        className="payoneer-modal"
        handleCancel={() => handleCancel("payoneer")}
      >
        <PayoneerModel
          isSuperAdmin={isSuperAdmin}
          setIsPayoModel={setToggleModal}
          formData={formData?.payoneer}
          handleCancel={handleCancel}
        />
      </ModalDailog>
      <ModalDailog
        isModalOpen={proofOfPaymentToggle}
        handleCancel={handleProofOfPaymentToggle}
        closable={false}
        className="paypal-modal"
        title="Proof Of Payment"
      >
        <Preview
          rowData={rowData}
          file={file}
          extension={extension}
          handleProofOfPaymentToggle={handleProofOfPaymentToggle}
          handleDownloadProof={downloadFile}
        />
      </ModalDailog>
    </>
  );
};

export default Payments;
