import isEmpty from "is-empty";
import moment from "moment";
import Validator from "validator";

import { validatePassword } from "../../utils/constants/constants";

/**
 * @description Validates the user signup data step 1 and return the errors
 */
export const signUpStepOneSchema = (data) => {
  const errors = {};
  if (Validator.isEmpty(data.email)) {
    errors.email = "Email is required";
  } else if (!Validator.isEmail(data.email)) {
    errors.email = "Invalid Email";
  }
  if (Validator.isEmpty(data.name)) {
    errors.name = "Full Name is required";
  }
  if (Validator.isEmpty(data.companyName)) {
    errors.companyName = "Company Name is required";
  }
  if (Validator.isEmpty(data.contactNo)) {
    errors.contactNo = "Contact No is required";
  }
  if (
    data.contactNo &&
    (data.contactNo?.length > 11 || data.contactNo?.length < 11)
  ) {
    errors.contactNo = "Contact number should be 10 digits";
  }
  if (!data.termsCondition) {
    errors.termsCondition =
      "Please accept ZevBit's Terms & Conditions and Privacy Policy";
  }
  if (!data.receiveEmail) {
    errors.receiveEmail = "Please agree to receive Email updates from ZevBit";
  }
  if (!validatePassword(data.password)) {
    errors.password = ` Password must have: <br />
    1. Minimum 8 characters  <br />
    2. Atleast one upper case and smaller case required
    3. Atleast one special character required
    4. Atleast one number required
    5. No spaces accepted`;
  }
  if (Validator.isEmpty(data.confirmPassword)) {
    errors.confirmPassword = "Confirm Password is required";
  }
  if (data.confirmPassword !== data.password) {
    errors.confirmPassword = "Password doesn't match";
  }

  return {
    isValid: isEmpty(errors),
    errors,
  };
};

export const createPasswordSchema = (data) => {
  const errors = {};
  if (Validator.isEmpty(data.password)) {
    errors.password = "Password is required";
  } else if (!validatePassword(data.password)) {
    errors.password = ` Password must have: <br />
    1. Minimum 8 characters  <br />
    2. Atleast one upper case and smaller case required
    3. Atleast one special character required
    4. Atleast one number required
    5. No spaces accepted`;
  }
  if (Validator.isEmpty(data.confirmPassword)) {
    errors.confirmPassword = "Confirm Password is required";
  } else if (data.confirmPassword !== data.password) {
    errors.confirmPassword = "Password doesn't match";
  }

  return {
    isValid: isEmpty(errors),
    errors,
  };
};

export const paymentInfoSchema = (data) => {
  const errors = {};
  if (Validator.isEmpty(data.name)) {
    errors.name = "Name is required";
  }
  if (Validator.isEmpty(data.address)) {
    errors.address = "Billing address is required";
  }
  if (Validator.isEmpty(data.city)) {
    errors.city = "City is required";
  }
  if (Validator.isEmpty(data.state)) {
    errors.state = "State is required";
  }
  if (Validator.isEmpty(data.zipCode)) {
    errors.zipCode = "Zip Code is required";
  }
  if (Validator.isEmpty(data.country)) {
    errors.country = "Country is required";
  }

  return {
    isValid: isEmpty(errors),
    errors,
  };
};

export const promoCodeSchema = (data) => {
  const errors = {};
  if (Validator.isEmpty(data.codeName)) {
    errors.codeName = "Promo code name is required";
  } else if (data.codeName?.length > 30) {
    errors.codeName = "Promo code name should not be more than 30 characters";
  }
  if (Validator.isEmpty(data.promoCode)) {
    errors.promoCode = "Promo code is required";
  }
  if (
    data.promoCode?.length > 0 &&
    !/^[a-zA-Z0-9_-]{4,10}$/.test(data?.promoCode)
  ) {
    errors.promoCode =
      "Promo code must be between 4 and 10 characters long and can only contain alphanumeric characters, underscores, and hyphens.";
  }
  if (data.plans?.length === 0) {
    errors.plans = "Please select plan";
  }
  if (data.discountType?.length === 0) {
    errors.discountType = "Please select discount type";
  }
  if (data.description?.length === 0) {
    errors.description = "Please enter description";
  }
  if (
    data?.discountType === "percentage" &&
    Validator.isEmpty(data.numberOfTime)
  ) {
    errors.numberOfTime = "This field is required";
  }
  if (
    data?.discountType !== "freeTrial" &&
    Validator.isEmpty(data.discount.toString())
  ) {
    errors.discount = "Discount is required";
  }
  if (data?.discountType === "percentage" && data?.discount > 100) {
    errors.discount = "Percentage Discount should be less then 100";
  }
  if (
    Validator.isEmpty(data.quantity.toString()) &&
    data.isUnlimitedQuantity === false
  ) {
    errors.quantity = "Quantity is required";
  }
  if (Validator.isEmpty(data.startDate)) {
    errors.startDate = "Start Date is required";
  } else if (moment(data.startDate, "MM/DD/YYYY").isBefore(moment(), "day")) {
    errors.startDate = "Start Date cannot be earlier than today";
  }
  if (
    Validator.isEmpty(data.expirationDate) &&
    data.noExpirationDate === false
  ) {
    errors.expirationDate = "Expiry Date is required";
  } else if (
    moment(data.expirationDate, "MM/DD/YYYY").isBefore(moment(), "day")
  ) {
    errors.expirationDate = "Expiry Date cannot be earlier than today";
  } else if (
    moment(data?.expirationDate, "MM/DD/YYYY").isBefore(
      moment(data?.startDate, "MM/DD/YYYY"),
      "day" && data?.noExpirationDate === false,
    )
  ) {
    errors.expirationDate = "Expiry Date cannot be earlier than Start Date";
  }
  /* if (
    Validator.isEmpty(data?.howManyMonths) &&
    data.numberOfTime === "repeating"
  ) {
    errors.howManyMonths = "This field is required";
  } */
  return {
    isValid: isEmpty(errors),
    errors,
  };
};

export const FreeTrialCodeSchema = (data) => {
  const errors = {};
  if (Validator.isEmpty(data.codeName)) {
    errors.codeName = "Trial code name is required";
  }
  if (Validator.isEmpty(data.promoCode)) {
    errors.promoCode = "Trial code is required";
  }
  if (
    data.promoCode?.length > 0 &&
    !/^[a-zA-Z0-9_-]{5,}$/.test(data?.promoCode)
  ) {
    errors.promoCode =
      "Trial code must be at least 5 characters long and can only contain alphanumeric characters, underscores, and hyphens.";
  }
  if (data.plans?.length === 0) {
    errors.plans = "Please select plan";
  }
  /*  if (data.discountType?.length === 0) {
    errors.discountType = "Please select discount type";
  } */
  /*  if (data.description?.length === 0) {
    errors.description = "Please enter description";
  } */
  if (Validator.isEmpty(data.numberOfTime)) {
    errors.numberOfTime = "This field is required";
  }
  /*  if (Validator.isEmpty(data.discount)) {
    errors.discount = "Discount is required";
  } */
  /*  if (data.discount > 100) {
    errors.discount = "Percentage Discount should be less then 100";
  } */
  if (Validator.isEmpty(data.quantity) && data.isUnlimitedQuantity === false) {
    errors.quantity = "Quantity is required";
  }
  if (Validator.isEmpty(data.startDate)) {
    errors.startDate = "Start Date is required";
  }
  if (
    Validator.isEmpty(data.expirationDate) &&
    data.noExpirationDate === false
  ) {
    errors.expirationDate = "Expiry Date is required";
  }
  return {
    isValid: isEmpty(errors),
    errors,
  };
};

export const addSourceSchema = (data) => {
  const errors = {};
  if (Validator.isEmpty(data.name)) {
    errors.name = "Source name is required";
  }
  if (Validator.isEmpty(data.type)) {
    errors.type = "Source type is required";
  }
  return {
    isValid: isEmpty(errors),
    errors,
  };
};

export const addAffiliateSchema = (data) => {
  const errors = {};
  if (Validator.isEmpty(data.name)) {
    errors.name = "Affiliate name is required";
  }
  return {
    isValid: isEmpty(errors),
    errors,
  };
};

export const payPalFormSchema = (data) => {
  const errors = {};
  if (Validator.isEmpty(data.email)) {
    errors.email = "Email is required";
  } else if (!Validator.isEmail(data.email)) {
    errors.email = "Invalid Email";
  }
  return {
    isValid: isEmpty(errors),
    errors,
  };
};

export const venmoFormSchema = (data) => {
  const errors = {};
  if (Validator.isEmpty(data.userName)) {
    errors.userName = "UserName is required";
  }
  if (Validator.isEmpty(data.phone)) {
    errors.phone = "This field is required";
  } else if (data.phone?.length !== 4) {
    errors.phone = "Please enter last 4 digits of linked phone number";
  }
  return {
    isValid: isEmpty(errors),
    errors,
  };
};

export const payoneerFormSchema = (data) => {
  const errors = {};
  if (Validator.isEmpty(data.name)) {
    errors.name = "Name is required";
  }
  if (Validator.isEmpty(data.email)) {
    errors.email = "Email is required";
  } else if (!Validator.isEmail(data.email)) {
    errors.email = "Invalid Email";
  }
  if (Validator.isEmpty(data.customerId)) {
    errors.customerId = "Customer Id is required";
  }
  return {
    isValid: isEmpty(errors),
    errors,
  };
};
