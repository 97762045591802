import { Button, Card, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getBillingDetailsAction } from "../../../../api/billing";
import { getCurrentUser } from "../../../../api/user";
import ModalDailog from "../../../../common/components/ModalDialogue";
import { layer } from "../../../../utils/svg.file";
import SmallLoader from "../../../loader/smallLoader";
import PaymentGateway from "./PaymentGateway";
import ReactiveSubscription from "./ReactiveSubscription";

function WelcomeBack() {
  const [loader, setLoader] = useState(false);
  const [billingDetail, setBillingDetail] = useState();
  const [showReactiveSub, setShowReactiveSub] = useState(false);
  const [showPaymentGate, setShowPaymentGate] = useState(false);

  const getBillingDetails = async () => {
    setLoader(true);
    const result = await getBillingDetailsAction();
    if (result?.remote === "success") {
      setBillingDetail(result?.data);
      localStorage.setItem("planName", result?.data?.subscriptionPlanName);
      localStorage.setItem("companyName", result?.data?.organizationId?.name);
      localStorage.setItem(
        "companyNumber",
        result?.data?.organizationId?.companyNumber,
      );
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  const dispatch = useDispatch();
  const getUserDetails = async () => {
    const response = await getCurrentUser();
    if (response?.data?.id) {
      const userId = response?.data?.id;
      localStorage.setItem("userId", userId);
    }
    if (response?.remote === "success") {
      dispatch({ type: "user/userLoaded", payload: response.data });
      if (!localStorage.getItem("userRole")) {
        localStorage.setItem("userRole", response.data.userRole);
      }
    }
  };

  useEffect(() => {
    getUserDetails();
    getBillingDetails();
  }, []);
  return (
    <>
      {loader && (
        <div className="center-loader">
          <SmallLoader />
        </div>
      )}
      <Card>
        <h4>Welcome Back!</h4>
        <p>
          We value your satisfaction as a customer and apologize for any
          inconvenience caused. Please contact us to discuss any further issues.
          We are here to assist you and look forward to restoring your
          subscription so you can fully enjoy our software. If you wish to
          reactivate your subscription, you can do so below!
        </p>
        <h5>Your Previous Plan</h5>
        <p>
          Previously you were subscribed to the{" "}
          {billingDetail?.subscriptionPlanName?.charAt(0)?.toUpperCase() +
            billingDetail?.subscriptionPlanName?.slice(1)}{" "}
          plan.
        </p>
        <Card
          title={[
            <Space size={[8, 0]} className="plan-title">
              {layer}
              <span>
                {billingDetail?.subscriptionPlanName?.charAt(0)?.toUpperCase() +
                  billingDetail?.subscriptionPlanName?.slice(1)}{" "}
                {billingDetail?.billingMethod} Plan
              </span>
            </Space>,
          ]}
          bodyStyle={{ padding: "16px" }}
          className="pyment-card mt-20"
        >
          <div className="plancard">
            <h3>
              ${billingDetail?.amount}{" "}
              <sub>
                /{" "}
                {billingDetail?.billingMethod === "Monthly" ? "Month" : "Year"}{" "}
                + Tax
              </sub>
            </h3>
            <p>
              {billingDetail?.subscriptionPlanName === "estimating"
                ? "CRM, Instant Estimating, Materials Catalog, Production Rates"
                : ""}
            </p>

            <Space size={[8, 0]} className="mt-4">
              <Button
                size="large"
                className="radius-8"
                onClick={() => setShowReactiveSub(true)}
                type="primary"
              >
                Reactivate Subscription
              </Button>
            </Space>
          </div>
        </Card>
      </Card>
      <ModalDailog
        bodyStyle={{ padding: "0px" }}
        isModalOpen={showReactiveSub}
        handleOk={() => setShowReactiveSub(false)}
        handleCancel={() => setShowReactiveSub(false)}
        title={false}
        closable={false}
        width={1240}
      >
        <ReactiveSubscription
          handleCancel={() => setShowReactiveSub(false)}
          handleChoosePlan={() => setShowPaymentGate(true)}
          prevPlan={
            billingDetail?.isSubscriptionEnd &&
            billingDetail?.subscriptionPlanName
          }
        />
      </ModalDailog>
      <ModalDailog
        bodyStyle={{ padding: "0px" }}
        isModalOpen={showPaymentGate}
        handleOk={() => setShowPaymentGate(false)}
        handleCancel={() => setShowPaymentGate(false)}
        title={false}
        closable={false}
        width="100%"
        wrapClassName="reactivate"
      >
        <PaymentGateway
          setShowReactiveSub={setShowReactiveSub}
          setShowPaymentGate={setShowPaymentGate}
        />
      </ModalDailog>
    </>
  );
}

export default WelcomeBack;
