import React, { createContext, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../api/api";
import { GET_ALL_SMS_TEMPLATES } from "../constants/api-urls";
import { LoaderContext } from ".";

export const SMSTemplateContext = createContext({});

export const SMSTemplateProvider = ({ children }) => {
  const [smsTemplates, setSMSTemplates] = useState([]);
  const [smsTemplateDetails, setSMSTemplateDetails] = useState(null);
  const { setLoading } = useContext(LoaderContext);
  const navigate = useNavigate();

  const onCreateSMSTemplate = async (values) => {
    try {
      setLoading(true);
      if (values.isAutomation === undefined) {
        values.isAutomation = false;
      }
      values.activeStatus = true;
      const result = await api.request({
        url: GET_ALL_SMS_TEMPLATES,
        method: "POST",
        data: values,
      });

      if (result.remote === "success") {
        navigate("/notification-templates");
      }
      setLoading(false);
    } catch (error) {}
  };
  const getAllSMSTemplates = async () => {
    try {
      setLoading(true);
      const result = await api.request({
        url: `${GET_ALL_SMS_TEMPLATES}/list`,
        METHOD: "GET",
      });
      if (result.remote === "success") {
        setSMSTemplates(result.data.data);
        setSMSTemplateDetails(null);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const getSMSTemplateDetails = async (id) => {
    try {
      setLoading(true);
      const result = await api.request({
        url: `${GET_ALL_SMS_TEMPLATES}/?id=${id}`,
        method: "GET",
      });
      if (result.remote === "success") {
        setSMSTemplateDetails(result.data.data);
      }
      setLoading(false);
    } catch (error) {}
  };

  const onUpdateSMSTemplate = async (id, values) => {
    try {
      if (values.isAutomation === undefined) {
        values.isAutomation = false;
      }
      setLoading(true);
      values.id = id;
      const result = await api.request({
        url: `${GET_ALL_SMS_TEMPLATES}`,
        method: "PUT",
        data: values,
      });

      if (result.remote === "success") {
        navigate("/notification-templates?type=SMSTemplate");
      }
      setLoading(false);
    } catch (error) {}
  };

  const onDeleteSMSTemplate = async (id) => {
    try {
      setLoading(true);
      const result = await api.request({
        url: `${GET_ALL_SMS_TEMPLATES}/?id=${id}`,
        method: "DELETE",
      });
      if (result.remote === "success") {
        await getAllSMSTemplates();
      }
      setLoading(false);
    } catch (error) {}
  };

  const value = {
    smsTemplates,
    smsTemplateDetails,
    onCreateSMSTemplate: useCallback((values) => {
      onCreateSMSTemplate(values);
    }, []),
    getAllSMSTemplates: useCallback((params) => {
      getAllSMSTemplates(params);
    }, []),
    getSMSTemplateDetails: useCallback((id) => {
      getSMSTemplateDetails(id);
    }, []),
    onUpdateSMSTemplate: useCallback((id, values) => {
      onUpdateSMSTemplate(id, values);
    }, []),
    onDeleteSMSTemplate: useCallback((id) => {
      onDeleteSMSTemplate(id);
    }, []),
  };
  return (
    <SMSTemplateContext.Provider value={value}>
      {children}
    </SMSTemplateContext.Provider>
  );
};
