import React from "react";

import { FormInput } from "../../common/components/form-input";
import { ReactComponent as Copy } from "../../images/affiliateImages/copy.svg";
import { copyToClipboard } from "../../utils/javascript";

const AffiliateFormInput = ({ value, className, label, disabled }) => (
  <FormInput
    suffix={
      <Copy
        {...{ ...(!disabled && { onClick: () => copyToClipboard(value) }) }}
        style={{ width: 20, height: 20 }}
      />
    }
    style={{ borderRadius: 8 }}
    value={value}
    className={className}
    label={label}
    disabled={disabled}
    readOnly
  />
);

export default AffiliateFormInput;
