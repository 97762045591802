import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { sendOTP } from "../../../../../api/affiliate";
import ModalDailog from "../../../../../common/components/ModalDialogue";
import { setLoading } from "../../../../../redux/affiliate/affiliate.action";
import ResetPasswordModal from "../../../ResetPasswordModal";
import { passwordValidator } from "../../helper";
import ConfirmOTP from "./ConfirmOTP";

const UpdatePassword = ({ email, handleCancel }) => {
  const [form] = Form.useForm();
  const [openOTP, setOpenOTP] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [openForgetPass, setOpenForgetPass] = useState(false);
  const dispatch = useDispatch();
  const handleOpenOTP = () => {
    setOpenOTP(true);
    handleCancel();
  };
  const handleOpenForgetPass = () => {
    setOpenForgetPass(!openForgetPass);
  };
  const handleCloseOTP = () => {
    setOpenOTP(false);
  };
  const handleSubmit = async (data) => {
    // NOTE: API integration for sending the email details to get the OTP
    if (data) {
      delete data.confPassword;
      const { remote, errors } = await sendOTP(
        "update-password",
        data,
        dispatch,
      );
      if (remote === "success") {
        setNewPass(data?.newPassword);
        dispatch(setLoading(false));
        handleOpenOTP();
      } else if (errors) {
        form.setFields([
          { name: ["password"], errors: [errors?.errors?.error] },
        ]);
        dispatch(setLoading(false));
      }
    }
  };
  return (
    <>
      <div style={{ marginBottom: 15 }}>
        To update your password you must enter your new password and confirm the
        password password, then you must verify the link that we will send to
        your email address.
      </div>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="password"
          label="Current Password"
          style={{ marginBottom: "0px!important" }}
          className="password-RemoveMargin"
          rules={[
            () => ({
              validator: (_, value) => passwordValidator(value),
            }),
          ]}
        >
          <Input.Password autoComplete="new-password" name="password" />
        </Form.Item>
        <span>Forgot Password?</span>
        <Button
          type="link"
          className="mx-10 p-0"
          style={{ marginBottom: "10px" }}
          onClick={() => handleOpenForgetPass()}
        >
          Click Here
        </Button>
        <Form.Item
          name="newPassword"
          label="New Password"
          style={{ marginBottom: 15 }}
          rules={[
            () => ({
              validator: (_, value) => passwordValidator(value),
            }),
          ]}
        >
          <Input.Password autoComplete="new-password" name="newPassword" />
        </Form.Item>
        <Form.Item
          name="confPassword"
          label="Confirm Password"
          dependencies={["newPassword"]}
          style={{ marginBottom: 15 }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!"),
                );
              },
            }),
          ]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Form.Item className="forFlex-buttons">
          <Button className="w-50" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button
            className="w-50"
            type="primary"
            onClick={() => handleSubmit()}
            htmlType="submit"
          >
            Update Password
          </Button>
        </Form.Item>
      </Form>
      <ModalDailog
        isModalOpen={openForgetPass}
        handleCancel={handleOpenForgetPass}
        title="Forgot Password"
        centered={false}
      >
        <ResetPasswordModal handleCancel={handleOpenForgetPass} />
      </ModalDailog>
      <ModalDailog
        isModalOpen={openOTP}
        handleCancel={handleCloseOTP}
        closable={false}
        title="Update Password"
        className="Email-Popup Email-OTP"
      >
        <ConfirmOTP
          email={email}
          handleCloseOTP={handleCloseOTP}
          btnTitle="Update Password"
          updateField="password"
          codeEndpoint="update-password"
          newPassword={newPass}
        />
      </ModalDailog>
    </>
  );
};

export default UpdatePassword;
