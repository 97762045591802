import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React from "react";
import { Container } from "react-bootstrap";

import logo from "../../images/logo-signup.png";
import tablet from "../../images/tablat.png";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //  eslint-disable-next-line no-console
    console.log("Errors: ", error, errorInfo);
  }

  home = () => {
    window.location.replace("/");
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="py-5">
          <Container>
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div className="contentboxnew">
              <Row gutter={[24, 24]}>
                <Col lg={12} xs={12}>
                  <div className="h-100view">
                    <div className="centerbox">
                      <h1>Please Refresh Page</h1>
                      <p>
                        Due to a connection error, ZevBit needs you to refresh
                        the page or press the button below to return to the
                        previous page.
                      </p>
                      <Button
                        onClick={this.home}
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          borderRadius: "3px",
                        }}
                      >
                        <ArrowLeftOutlined /> <span>Go back</span>
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col lg={12} xs={12}>
                  <img src={tablet} alt="" />
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
