import { Button, Checkbox, Col, Form, Row } from "antd";

const columnList = [
  {
    label: "Name",
    value: "",
  },
  {
    label: "Issue Date",
    value: "",
  },
  {
    label: "Email",
    value: "",
  },
  {
    label: "Canceled Notes",
    value: "",
  },
  {
    label: "Signed Up",
    value: "",
  },
  {
    label: "Plan",
    value: "",
  },
  {
    label: "Assigned To",
    value: "",
  },
  {
    label: "Storage Used",
    value: "",
  },
  {
    label: "Trial Days",
    value: "",
  },
  {
    label: "Source",
    value: "",
  },
  {
    label: "Trial End Date",
    value: "",
  },
  {
    label: "Promo Code",
    value: "",
  },
  {
    label: "# of Employees",
    value: "",
  },
  {
    label: "Promo Code Name",
    value: "",
  },
  {
    label: "Last Sign In",
    value: "",
  },
  {
    label: "Last Payment Date",
    value: "",
  },
  {
    label: "Leads Added",
    value: "",
  },
  {
    label: "Discount",
    value: "",
  },
  {
    label: "Leads Converted",
    value: "",
  },
  {
    label: "Amount Of Last Payment",
    value: "",
  },
  {
    label: "Status",
    value: "",
  },
  {
    label: "Billing",
    value: "",
  },
];

function ColumnSetting() {
  return (
    <div className="add-promo-code">
      <h3 className="fliter-sort-title">Column Settings</h3>
      Click on the checkbox below to select which columns you want to displayed.
      <Form layout="vertical">
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Checkbox.Group
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <Row gutter={[8, 8]}>
                {columnList?.map((v) => (
                  <Col xs={12}>
                    <Checkbox value={v?.value} className="checkedbox">
                      {v?.label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Col>
          <Col xs={12}>
            <Button style={{ width: "100%", borderRadius: "8px" }} size="large">
              Display All
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              style={{ width: "100%", borderRadius: "8px" }}
              size="large"
              htmlType="submit"
              type="submit"
            >
              Apply
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export default ColumnSetting;
