import USERS_GROUP_LIST from "./usersListActionConstFile";

const initialState = {
  usersGroupList: [],
};

export const reducerUsersGroupList = (state = initialState, action) => {
  switch (action.type) {
    case USERS_GROUP_LIST:
      return {
        ...state,
        usersGroupList: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
