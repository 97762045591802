import React from "react";
import { Route, Routes } from "react-router-dom";

import Error from "../Error";
import routes from "./affiliateRoutes";

const AffiliateHome = () => (
  <Routes>
    {routes.map((route, i) => (
      <Route path={route.path} element={route.component} key={i} />
    ))}

    <Route path="*" element={<Error />} />
  </Routes>
);

export default AffiliateHome;
