export const GET_ALL_EMAIL_TEMPLATES = "v3/settings/email-templates/list";
export const GET_ORGANIZATION_DETAILS =
  "/v1/organization/get-organization-details";
export const GET_ALL_SMS_TEMPLATES = "/sms-template-setting";
export const SEND_BULK_LEADS_EMAIL = "/customer/lead/send-bulk-email";
export const SEND_BULK_LEADS_SMS = "/customer/lead/send-bulk-sms";
export const GET_ALL_CALL_TEMPLATES = "/call-templates";
export const UPDATE_EMAIL_TEMPLATE = "v3/settings/email-templates";
export const UPDATE_DEFAULT_TEMPLATE =
  "v3/settings/email-templates/set-default";
export const GET_EMAIL_TEMPLATES = "v3/settings/email-templates?templateId";
export const ADD_EMAIL_TEMPLATES = "v3/settings/email-templates";
export const OLD_EMAIL_TEMPLATE_ROUTE = "/email-template";
