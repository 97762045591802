import { Alert } from "antd";
import React from "react";

const AlertBanner = ({
  message,
  icon,
  onClose,
  type,
  style,
  closable = false,
  showIcon = true,
  className,
}) => (
  <Alert
    {...{
      message,
      type,
      closable,
      showIcon,
      icon,
      style,
      className,
      ...(closable && { onClose }),
    }}
  />
);

export default AlertBanner;
