/* eslint-disable no-unused-vars */
import { Button, Col, Divider, Form, Input, message, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setPaymentMethodAction } from "../../../../../api/admin";
import payonerLogo from "../../../../../images/payonerLogo.png";
import { AFFILIATE_TYPES } from "../../../../../redux/affiliate/affiliate.types";
import { payoneerFormSchema } from "../../../../../validators/auth/auth.validator";

const PayoneerModel = ({
  setToggleModal,
  setPaymentModal,
  setLoader,
  formData,
  errors,
  setErrors,
  isSuperAdmin = false,
  handleCancel,
}) => {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const affiliate = useSelector((state) => state?.affiliate);

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setToggleModal((prev) => ({
      ...prev,
      payo: { ...prev?.payo, [name]: value },
    }));
  };
  const handleSubmit = async (e) => {
    e && e?.preventDefault();
    setErrors({});
    const { isValid, errors: formError } = payoneerFormSchema(formData);
    if (!isValid) {
      setErrors(formError);
    } else {
      setLoader(true);
      const payload = {
        paymentMethodType: "payoneer",
        payoneer: {
          name: formData?.name,
          email: formData?.email,
          payoneerCustomerId: formData?.customerId,
          ...(formData?.notes && { note: formData?.notes }),
        },
      };
      const result = await setPaymentMethodAction(
        payload,
        affiliate?.docNPay?.status,
      );
      if (result?.errors) {
        setLoader(false);
        message.error(
          result?.errors?.errors?.error || "Something went wrong",
          10,
        );
      } else if (result?.remote === "success") {
        setLoader(false);
        message.success(result?.data?.message || "", 10);
        setToggleModal((prev) => ({
          ...prev,
          payo: {
            toggle: false,
            name: "",
            email: "",
            customerId: "",
            notes: "",
          },
        }));
        dispatch({
          type: AFFILIATE_TYPES.DOCUMENT_DETAIL,
          payload: {
            ...result?.data?.data,
          },
        });
      }
    }
  };

  return (
    <>
      {" "}
      <div className="add-promo-code cencelnotes mt-4">
        <Row gutter={[0, 24]}>
          {isSuperAdmin && (
            <Col xs={24}>
              <img src={payonerLogo} alt="Payoneer" />
            </Col>
          )}
          {!isSuperAdmin && (
            <>
              <Col xs={24}>
                <img src={payonerLogo} alt="Payoneer" />
                <div className="Payment-inner-modal-text mt-3">
                  Withdraw funds to your Payoneer account. 2% Fee
                </div>
              </Col>{" "}
              <Col xs={24} className="payoneer-modal-instruction">
                <h4>Instructions</h4>
                <div className="remove-padding mt-3">
                  1. Enter the Payoneer Information that will receive the funds
                </div>
                <div className="remove-padding-again pb-0">
                  New to Payoneer? Register{" "}
                  <a href="https://www.payoneer.com/">here</a>
                </div>
              </Col>
              <Divider style={{ margin: "0px" }} />
            </>
          )}
          <Form layout="vertical" onSubmitCapture={handleSubmit}>
            <div className="payoneer-payment-info mb-4">
              <h3>Payment Info</h3>
            </div>
            <Row gutter={[24, 24]} className="payoneer-two-inputs-fields">
              <Col lg={8} xs={24} className="payoneer-payment-input-name">
                NAME
              </Col>
              <Col lg={16} xs={24}>
                <Input
                  type="text"
                  name="name"
                  value={formData?.name}
                  onChange={handleFormChange}
                  disabled={isSuperAdmin}
                  className="w-100"
                />
                <div role="alert" className="text-danger">
                  {errors?.name}
                </div>
              </Col>
              <Col lg={8} xs={24} className="payoneer-payment-input-name">
                EMAIL
              </Col>
              <Col lg={16} xs={24}>
                <Input
                  type="text"
                  name="email"
                  value={formData?.email}
                  onChange={handleFormChange}
                  disabled={isSuperAdmin}
                  className="w-100"
                />
                <div role="alert" className="text-danger">
                  {errors?.email}
                </div>
              </Col>
              <Col lg={8} xs={24} className="payoneer-payment-input-name">
                PAYONEER CUSTOMER ID
              </Col>
              <Col lg={16} xs={24}>
                <Input
                  type="text"
                  name="customerId"
                  value={formData?.customerId || formData?.payoneerCustomerId}
                  onChange={handleFormChange}
                  disabled={isSuperAdmin}
                  className="w-100"
                />
                <div role="alert" className="text-danger">
                  {errors?.customerId}
                </div>
              </Col>
              <Col lg={8} xs={24} className="payoneer-payment-input-name">
                OTHER NOTES
              </Col>
              <Col lg={16} xs={24}>
                <TextArea
                  rows={4}
                  name="notes"
                  value={formData?.notes || formData?.note}
                  onChange={handleFormChange}
                  disabled={isSuperAdmin}
                  className="w-100"
                  style={{ resize: "none" }}
                />
                <div role="alert" className="text-danger">
                  {errors?.notes}
                </div>
              </Col>
            </Row>
            <Row className="payoneer-two-buttons">
              <Row className="payoneer-inner-two-buttons" gutter={[20, 20]}>
                <Col lg={6} xs={12}>
                  <Button
                    style={{ width: "100%", borderRadius: "8px" }}
                    size="large"
                    onClick={() => {
                      if (isSuperAdmin) {
                        handleCancel("payoneer");
                      } else {
                        setPaymentModal((prev) => ({
                          ...prev,
                          paymentModal: true,
                        }));
                        setErrors();
                        setToggleModal((prev) => ({
                          ...prev,
                          payo: {
                            ...prev?.payo,
                            toggle: false,
                            email: "",
                            notes: "",
                          },
                        }));
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                {!isSuperAdmin && (
                  <Col lg={8} xs={12}>
                    <Button
                      style={{ width: "100%", borderRadius: "8px" }}
                      size="large"
                      htmlType="submit"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                )}
              </Row>
            </Row>
          </Form>
        </Row>
      </div>
    </>
  );
};

export default PayoneerModel;
