import { Button, Col, Form, Image, Input, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import {
  alreadyHaveAccount,
  skippingExistingAccount,
} from "../../api/affiliate";
import affiliateRegisterImage from "../../images/affiliate_login_rightImg.png";
import logo from "../../images/logo-signup.png";

const { Content } = Layout;

const AlreadyExists = () => {
  const location = useLocation();
  const { state } = location;

  const [form] = Form.useForm();
  const history = useNavigate();
  const [isExist, setIsExist] = useState(false);

  const apiCall = async (data) => {
    const response = await skippingExistingAccount(data);
    return response;
  };

  const handleCancelClick = async () => {
    const response = await apiCall({ hasEmail: false });
    if (response.remote === "success") {
      history("/affiliate/survey");
    } else {
      toast.error(response.error?.errors?.error);
    }
  };
  const handleClick = async () => {
    const response = await apiCall({ hasEmail: true });
    if (response.remote === "success") {
      setIsExist(true);
    } else {
      toast.error(response.error?.errors?.error);
    }
  };

  const handleSubmit = async (data) => {
    const response = await alreadyHaveAccount(data);
    if (response.remote === "success") {
      history("/affiliate/survey");
    } else if (response.statusCode === 500) {
      toast.error(response.error?.errors?.error);
    } else {
      form.setFields([
        {
          name: ["email"],
          errors: [response.error?.errors?.error],
        },
      ]);
    }
  };

  useEffect(() => {
    if (state?.isExist) {
      setIsExist(true);
    }
  }, []);

  return (
    <>
      <Row>
        <Col lg={12} xs={24} className="d-none d-sm-block">
          <Content className="h-full">
            <Image
              src={affiliateRegisterImage}
              preview={false}
              className="h-full"
            />
          </Content>
        </Col>
        <Col lg={12} xs={24}>
          <div className="main-layout">
            <Content className="h-full afflite-market">
              <div className="w-100" style={{ textAlign: "center" }}>
                <Image src={logo} preview={false} width={200} height={47} />
              </div>
              {!isExist ? (
                <>
                  <div>
                    <h4
                      style={{
                        fontSize: "20px",
                        paddingTop: "35px",
                        fontWeight: "600",
                      }}
                    >
                      Do You Have An Existing ZevBit Software Account?
                    </h4>
                    <p
                      style={{
                        color: "#344054",
                        paddingTop: "20px",
                        fontWeight: "500",
                      }}
                    >
                      Select “Yes” if you have an existing ZevBit account for
                      your own company or the company you work for.
                    </p>
                    <p style={{ color: "#344054", fontWeight: "500" }}>
                      Select “No” if you are registering as an affiliate and
                      dont already have a ZevBit account.
                    </p>
                  </div>
                  <div
                    style={{ textAlign: "center" }}
                    className="already-exists-button d-flex gap-3"
                  >
                    <Button type="secondary" onClick={handleCancelClick}>
                      No
                    </Button>
                    <Button type="primary" onClick={handleClick}>
                      Yes
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Form
                    layout="vertical"
                    autoComplete="off"
                    onFinish={handleSubmit}
                    form={form}
                  >
                    <label htmlFor="email" className="Email-Page-Only-Email">
                      Enter the email that you use to login to your ZevBit
                      account
                    </label>
                    <Form.Item
                      label=""
                      id="email"
                      name="email"
                      className="alreadyExists-emailInput"
                      rules={[
                        { required: true, message: "Please enter your email!" },
                        { type: "email", message: "Please enter valid email" },
                      ]}
                      requiredMark="optional"
                    >
                      <Input placeholder="Email Address" />
                    </Form.Item>
                    <div className="alreadyExist-twoButtons d-flex align-items-lg-end align-items-center justify-content-lg-end justify-content-center ">
                      <Button
                        size="large"
                        type="secondary"
                        onClick={handleCancelClick}
                      >
                        Skip
                      </Button>
                      <Button type="primary" htmlType="submit" size="large">
                        Fill Out Survey
                      </Button>
                    </div>
                  </Form>
                </>
              )}
              <div className="company-design d-table w-100 text-lg-end text-center d-none d-sm-block">
                © ZevBit 2023
              </div>
            </Content>
          </div>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
};

export default AlreadyExists;
