import { capitalizeString } from "../../utils/constants/constants";

export const transformLeadStatus = (data) => {
  let leadStatus = data.listStatus;
  leadStatus = leadStatus.map((item) => ({
    ...item,
    name: item?.name ? capitalizeString(item.name) : "-",
  }));
  return { ...data, listStatus: leadStatus };
};
