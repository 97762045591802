import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

import howItWorkPreview from "../../images/affiliateLandingImages/how-it-work-preview.png";
import { howItWorkDescription } from "./utils";

const { Title } = Typography;

const HowItWorkSection = ({ promoCode }) => {
  const history = useNavigate();
  const handleBookDemo = () => {
    history(`/schedule-demo?promoCode=${promoCode}`);
  };
  return (
    <Row className="mt-5" id="how-it-work" gutter={[24, 24]} align="middle">
      <Col xs={24} lg={12}>
        {howItWorkDescription?.map(({ title, subContent }) => (
          <>
            <Title style={{ color: "#3483FA" }} level={4}>
              {title}
            </Title>
            {subContent?.map(({ srNo, content }) => (
              <p className="d-flex">
                <Title
                  style={{
                    marginRight: 10,
                    color: "#3483FA",
                    width: "fit-content",
                  }}
                  level={5}
                >
                  {srNo}
                </Title>
                <span>{content}</span>
              </p>
            ))}
          </>
        ))}
        <Button
          type="text"
          style={{ color: "#3483FA" }}
          onClick={handleBookDemo}
        >
          <span>Book Now</span>
          <span style={{ marginLeft: 5 }}>
            <ArrowRightOutlined />
          </span>
        </Button>
      </Col>
      <Col xs={24} lg={12}>
        <img src={howItWorkPreview} alt="how-it-work-preview" />
      </Col>
    </Row>
  );
};

export default HowItWorkSection;
