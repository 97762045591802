import { PROMO_CODE_DETAILS } from "./landing.types";

const initialState = {
  _id: "",
  textOfLandingPage: "",
  promoCode: "",
};

function LandingReducer(state = initialState, action) {
  switch (action.type) {
    case PROMO_CODE_DETAILS.DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case PROMO_CODE_DETAILS.REMOVE_CODE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default LandingReducer;
