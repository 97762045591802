import { Form, Input } from "antd";
import React from "react";

export default function InputField({
  label,
  onKeyPress,
  onChange,
  placeholder,
  icon,
  type,
  name,
  dclass,
  showIcon,
  value,
}) {
  return (
    <>
      <Form.Item className={dclass}>
        <label className="ant-label-login">{label}</label>
        <Input
          id={label}
          defaultValue={value || ""}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          prefix={icon}
          size="large"
          className="ant-login-input"
          name={name}
          suffix={showIcon}
          onKeyPress={onKeyPress}
        />
      </Form.Item>
    </>
  );
}
