import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { Form, Input, message } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { approveEmployee } from "../../api/user";
import lock from "../../images/lock.png";
import { createValidUrl } from "../../utils/commonFuctions";
import { createPasswordSchema } from "../../validators/auth/auth.validator";
import SmallLoader from "../loader/smallLoader";

const initialData = {
  password: "",
  confirmPassword: "",
};

const CreatePassword = () => {
  const navigate = useNavigate();
  // Commented by Ralph as it never used
  // const url = useLocation();
  const { id } = useParams();
  const [formData, setFormData] = useState(initialData);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { isValid, errors: formError } = createPasswordSchema(formData);
    if (!isValid) {
      setErrors(formError);
    } else {
      setLoader(true);
      const approveData = {
        password: formData?.password,
        repeatPassword: formData?.confirmPassword,
        token: id,
      };
      const response = await approveEmployee(approveData);
      if (response.remote === "success") {
        setLoader(false);
        message.success("Password created successfully", 3);
        navigate("/auth");
      } else {
        setLoader(false);
        message.error(
          response?.errors?.errors?.Error || "Sorry, your account is cancelled",
          3,
        );
      }
    }
  };
  return (
    <Box
      component={Paper}
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {loader && (
        <div className="center-loader">
          <SmallLoader />
        </div>
      )}
      <div className="logo text-center mb-4">
        <img
          width="3468"
          height="831"
          alt="ZevBit Logo"
          src={createValidUrl("superadmin/logo/zavbit-logo.png")}
          data-orig-src={createValidUrl("superadmin/logo/zavbit-logo.png")}
          className="img-responsive wp-image-1509 lazyautosizes lazyloaded"
          srcSet={`"${createValidUrl(
            "superadmin/logo/zavbit-logo-200x48.png",
          )} 200w, ${createValidUrl(
            "superadmin/logo/zavbit-logo-400x96.png",
          )} 400w, ${createValidUrl(
            "superadmin/logo/zavbit-logo-600x144.png",
          )} 600w, ${createValidUrl(
            "superadmin/logo/zavbit-logo-800x192.png",
          )} 800w, ${createValidUrl(
            "superadmin/logo/zavbit-logo-1200x288.png",
          )} 1200w, ${createValidUrl("superadmin/logo/zavbit-logo.png")} 3468w`}
          data-srcset={`${createValidUrl(
            "superadmin/logo/zavbit-logo-200x48.png",
          )} 200w, ${createValidUrl(
            "superadmin/logo/zavbit-logo-400x96.png",
          )} 400w, ${createValidUrl(
            "superadmin/logo/zavbit-logo-600x144.png",
          )} 600w, ${createValidUrl(
            "superadmin/logo/zavbit-logo-800x192.png",
          )} 800w, ${createValidUrl(
            "superadmin/logo/zavbit-logo-1200x288.png",
          )} 1200w, ${createValidUrl("superadmin/logo/zavbit-logo.png")} 3468w`}
          data-sizes="auto"
          data-orig-sizes="(max-width: 640px) 100vw, 400px"
          sizes="280px"
        ></img>
      </div>
      <Typography component="h1" variant="h5">
        Create a password to access your zevbit account
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Form.Item className="mb-0">
          <label className="ant-label-login">Password</label>
          <Input.Password
            size="large"
            className="ant-login-input"
            prefix={<img src={lock} alt="" />}
            placeholder="Password"
            name="password"
            label="Your password"
            fullWidth
            onChange={handleChange}
            value={formData?.password}
          />
          <div role="alert" className="text-danger">
            {errors.password && (
              <>
                Password must have: <br />
                1. Minimum 8 characters <br />
                2. Atleast one upper case and smaller case required <br />
                3. Atleast one special character required <br />
                4. Atleast one number required <br /> 5. No spaces accepted
              </>
            )}
          </div>
        </Form.Item>
        <Form.Item className="mb-2">
          <label className="ant-label-login">Confirm Password</label>
          <Input.Password
            size="large"
            className="ant-login-input"
            prefix={<img src={lock} alt="" />}
            placeholder="Confirm Password"
            name="confirmPassword"
            onChange={handleChange}
            value={formData?.confirmPassword}
          />
          <div role="alert" className="text-danger">
            {errors.confirmPassword}
          </div>
        </Form.Item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} md={2}>
            <Button
              type="button"
              onClick={() => navigate("/auth")}
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} md={5}>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, pr: 3 }}
            >
              Create password
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreatePassword;
