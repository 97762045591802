import { Col, Input, Row } from "antd";
import React from "react";

function Prefilled({ handleChange, idx, element, onFocusOut }) {
  return (
    <>
      <Row gutter={[24, 0]} className="mb-3">
        <Col md={8} className="mb-3">
          <label>Enter Prefilled Amount</label>
        </Col>
        <Col md={16}>
          <Input
            placeholder="0"
            className="ant-furmulla-input"
            onChange={(e) => handleChange(e.target.value, e.target.name, idx)}
            defaultValue={element.value}
            name="value"
            onBlur={onFocusOut}
            type="number"
            min={0}
          />
        </Col>
      </Row>
    </>
  );
}

export default Prefilled;
