import { Card, Col, Row, Typography } from "antd";
import React from "react";

import { estimateList } from "./utils";

const { Title } = Typography;

const WayToEstimate = () => (
  <div id="way-to-estimate" className="my-5">
    <div className="text-center">
      <Title level={1} className="font-28">
        ZevBit: A New Way To Estimate, A New Way To Win.
      </Title>
      <p style={{ fontSize: 18 }}>
        Start estimating your projects with confidence! Get in touch with the
        ZevBit Team Today!
      </p>
    </div>
    <Row className="justify-content-center" gutter={[24, 24]}>
      {estimateList.map(({ title, list, style }) => (
        <Col>
          <Card style={{ borderRadius: 16, color: "#fff", ...style }}>
            <Title level={4} style={{ color: "#fff" }}>
              {title}
            </Title>
            <ul>
              {list.map((content) => (
                <li>{content}</li>
              ))}
            </ul>
          </Card>
        </Col>
      ))}
    </Row>
  </div>
);

export default WayToEstimate;
