// import { client } from '../../api/client'
import { getCurrentUser } from "../api/user";

const initialState = {
  ip: "",
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    // case 'todos/todoAdded': {
    //   return [...state, action.payload]
    // }
    // case 'todos/todoToggled': {
    //   return state.map((todo) => {
    //     if (todo.id !== action.payload) {
    //       return todo
    //     }

    //     return {
    //       ...todo,
    //       completed: !todo.completed,
    //     }
    //   })
    // }
    // case 'todos/colorSelected': {
    //   const { color, todoId } = action.payload
    //   return state.map((todo) => {
    //     if (todo.id !== todoId) {
    //       return todo
    //     }

    //     return {
    //       ...todo,
    //       color,
    //     }
    //   })
    // }
    // case 'todos/todoDeleted': {
    //   return state.filter((todo) => todo.id !== action.payload)
    // }
    // case 'todos/allCompleted': {
    //   return state.map((todo) => {
    //     return { ...todo, completed: true }
    //   })
    // }
    // case 'todos/completedCleared': {
    //   return state.filter((todo) => !todo.completed)
    // }
    case "user/userLoaded": {
      return action.payload;
    }
    case "user/userFetch": {
      return state;
    }
    case "user/userProfileImg": {
      return { ...state, profileImage: action.payload.profileImage };
    }
    case "user/userDetails": {
      return {
        ...state,
        name: action.payload.name,
        companyName: action.payload.companyName,
        contactNo: action.payload.contactNo,
      };
    }
    case "user/userLogo": {
      return { ...state, organization: { logo: action.payload.logo } };
    }
    case "user/organization": {
      return { ...state, organization: action.payload };
    }
    case "user/updateUserIp": {
      return { ...state, ip: action.payload };
    }
    case "user/update-username": {
      return { ...state, name: action?.payload };
    }
    default:
      return state;
  }
}

// Thunk function
export async function fetchUser(dispatch) {
  const response = await getCurrentUser();
  dispatch({ type: "user/userLoaded", payload: response?.data });
}

// export function saveNewTodo(text) {
//   return async function saveNewTodoThunk(dispatch, getState) {
//     const initialTodo = { text }
//     const response = await client.post('/fakeApi/todos', { todo: initialTodo })
//     dispatch({ type: 'todos/todoAdded', payload: response.todo })
//   }
// }
