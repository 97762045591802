import { Button } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { getUsersDataAction } from "../../../../api/admin";
import ModalDailog from "../../../../common/components/ModalDialogue";
import { ReactComponent as LockOutlined } from "../../../../images/lock.svg";
import { DATE_FORMATE } from "../../../../utils/constants/constants";
import { convertJSONToCSV } from "../../../../utils/javascript";
import { downloadcloud } from "../../../../utils/svg.file";
import SmallLoader from "../../../loader/smallLoader";
import { formatDate } from "..";
import SetNewPassword from "../modal/SetNewPassword";
import TableData from "../Table";

function Users({ selectedUser }) {
  const [usersData, setUsersData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentUsersPage, setCurrentUsersPage] = useState(1);
  const [showPasswordModel, setShowPasswordModel] = useState(false);
  const [password, setPassword] = useState("");
  const [resetPasswordUser, setResetPasswordUser] = useState();
  const [showError, setShowError] = useState(false);
  const getUsersData = async () => {
    setLoader(true);

    const result = await getUsersDataAction(
      selectedUser?._id,
      currentUsersPage,
      8,
    );
    if (result?.remote === "success") {
      setUsersData(result?.data?.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const exportUserRecords = async () => {
    const result = await getUsersDataAction(
      selectedUser?._id,
      currentUsersPage,
      usersData[0]?.pagination[0]?.totalCount || 8,
    );
    if (result?.remote === "success") {
      setExportData(result?.data?.data?.[0]?.data);
    }
  };

  const handleCancel = () => {
    setResetPasswordUser("");
    setShowPasswordModel(false);
  };

  useEffect(() => {
    getUsersData();
    setExportData([]);
  }, [selectedUser?._id, currentUsersPage]);

  useEffect(() => {
    if (usersData?.length && !exportData?.length) {
      exportUserRecords();
    }
  }, [usersData]);

  const usersColumns = [
    {
      title: "User Name",
      dataIndex: "name",
    },
    {
      title: "Role",
      dataIndex: "userRole",
      render: (v) => v?.roleName,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phoneNo",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (v) => <>{formatDate(v)}</>,
    },
    {
      title: "Last Sign In",
      dataIndex: "lastSignIn",
      render: (v) => <>{formatDate(v)}</>,
    },
    {
      title: "Reset Password",
      dataIndex: "action",
      render: (action, record) => (
        <div className="text-center">
          <LockOutlined
            onClick={() => {
              setResetPasswordUser(record?._id);
              setShowPasswordModel(true);
              setShowError(false);
              setPassword("");
            }}
          />
        </div>
      ),
    },
  ];

  const generateExcelData = (item) => {
    const excelData = {
      "User Name": item?.name || "",
      Role: item?.userRole?.roleName || "",
      Email: item?.email || "",
      Phone: item?.phoneNo || "",
      Created: item?.createdAt
        ? moment(new Date(item?.createdAt)).format(DATE_FORMATE)
        : "-",
      "Last Sign In": item?.lastSignIn
        ? moment(new Date(item?.lastSignIn)).format(DATE_FORMATE)
        : "-",
    };
    return excelData;
  };

  const handleDownload = (compName) => {
    const csvDataList = [];
    exportData?.forEach((item) => {
      csvDataList.push(generateExcelData(item));
    });
    const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
      convertJSONToCSV(csvDataList),
    )}`;
    const link = document.createElement("a");
    link.href = csvContent;
    link.download = `${compName}_User_List`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {loader && (
        <div className="center-loader">
          <SmallLoader />
        </div>
      )}
      <div className="export-btnd">
        <Button
          size="large"
          className="btn-filter"
          onClick={() => handleDownload(selectedUser?.companyName)}
        >
          {downloadcloud} <span className="ms-1">Export</span>
        </Button>
      </div>

      {/* <div className="userlist-details"> */}
      <TableData
        scroll={{
          x: 500,
        }}
        columns={usersColumns}
        dataSource={usersData[0]?.data}
        // onChange={onChange}
        currentPage={currentUsersPage}
        setCurrentPage={setCurrentUsersPage}
        perPage={usersData[0]?.pagination[0]?.perPageCount}
        paginationData={usersData[0]?.pagination[0]}
      />
      <ModalDailog
        bodyStyle={{ padding: 24 }}
        isModalOpen={showPasswordModel}
        // handleOk={handleOk}
        handleCancel={() => handleCancel()}
        title={false}
        destroyOnClose
      >
        <SetNewPassword
          setShowPasswordModel={setShowPasswordModel}
          setPassword={setPassword}
          password={password}
          selectedUser={resetPasswordUser}
          showError={showError}
          setShowError={setShowError}
        />
      </ModalDailog>
      {/* </div> */}
    </>
  );
}

export default Users;
