import { Card, Col, Row, Typography } from "antd";
import React from "react";

import { earningList } from "./utils";

const { Title } = Typography;
const InstantEarningSection = () => (
  <div id="instant-earning" className="mt-5">
    <Title level={1}>Instant Estimating</Title>
    <p>
      It used to take a few hours to estimate a project, but now with
      ZevBit&lsquo;s Instant Estimating you can write estimates in minutes using
      your Company&lsquo;s production rates and material prices.
    </p>
    <p>
      ZevBit doesn&lsquo;t just auto populate professional, easy to read,
      electronically signable quotes for you, it also builds out an accurate
      materials list based on how your company performs certain scopes and
      services.
    </p>
    <Row className="justify-content-center" gutter={[24, 24]}>
      {earningList.map(({ title, list, style }) => (
        <Col xs={24} lg={6}>
          <Card style={{ borderRadius: 8, color: "#fff", ...style }}>
            <Title level={4} style={{ color: "#fff" }}>
              {title}
            </Title>
            <ul>
              {list.map((content) => (
                <li>{content}</li>
              ))}
            </ul>
          </Card>
        </Col>
      ))}
    </Row>
  </div>
);

export default InstantEarningSection;
