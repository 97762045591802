import { Table } from "antd";
import SpinFC from "antd/lib/spin";
import classNames from "classnames";

const TableData = ({
  columns,
  dataSource,
  rowSelection,
  onChange,
  scroll,
  currentPage,
  setCurrentPage,
  perPage,
  paginationData,
  showSizeChanger = false,
  onRow = null,
  pagination = true,
  className,
  id = "",
  loading = false,
  Loader = null,
  summary = null,
  style = null,
  ...rest
}) => (
  <>
    <Table
      className={classNames({
        [className]: className,
      })}
      showSorterTooltip={false}
      columns={columns}
      dataSource={dataSource}
      rowSelection={rowSelection}
      onChange={onChange}
      scroll={scroll}
      id={id}
      {...{
        ...(pagination
          ? {
              pagination: {
                onChange: (page) => setCurrentPage(page),
                current: currentPage,
                pageSize: perPage, // Number of items to display per page
                total: paginationData?.totalCount || 1, // Total number of items
                showSizeChanger,
              },
            }
          : { pagination }),
      }}
      {...{ ...(onRow && { onRow }) }}
      {...{
        ...(loading && {
          loading: {
            indicator: Loader ? <Loader /> : <SpinFC />,
            spinning: loading,
          },
        }),
      }}
      {...{ ...(summary && { summary }) }}
      {...{ ...(style && { style }) }}
      {...rest}
    />
  </>
);
export default TableData;
