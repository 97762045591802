import { StarFilled } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import React from "react";

import { ratings } from "./utils";

const RatingNReviewSection = () => (
  <Row
    gutter={[24, 24]}
    style={{ justifyContent: "space-between" }}
    id="ratings-review"
    className="mt-5"
  >
    {ratings?.map((obj) => (
      <Col lg={8} xs={24}>
        <Card
          style={{
            borderRadius: 16,
            background: "#F9FAFB",
            border: "none",
            height: "100%",
          }}
          bodyStyle={{ height: "100%" }}
        >
          <Row style={{ flexDirection: "column", height: "100%" }}>
            <Col>
              <span>
                {Array(5)
                  .fill("")
                  .map((_, ind) => (
                    <StarFilled
                      style={{
                        color: ind + 1 <= obj?.ratingStared ? "#FEC84B" : "",
                      }}
                    />
                  ))}
              </span>
              <p
                className="ratting-review"
                style={{ fontSize: 30, fontWeight: 600 }}
              >
                {obj?.title}
              </p>
              <p style={{ fontSize: 24 }} className="ratting-review-content">
                {obj?.content}
              </p>
            </Col>
            <Col style={{ marginTop: "auto" }}>
              <p style={{ fontSize: 18, marginBottom: 0 }}>— {obj?.author}</p>
              <p style={{ fontSize: 16, color: "#667085" }}>{obj?.role}</p>
            </Col>
          </Row>
        </Card>
      </Col>
    ))}
  </Row>
);

export default RatingNReviewSection;
