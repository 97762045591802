import { Button, /* message, */ Modal, Table } from "antd";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useSearchParams } from "react-router-dom";

// import api from "../../../api/api";
import { markVisibleEmailDetails } from "../../../api/invoice";
// import { markVisibleEmailDetails } from "../../../api/invoice";
import { createValidUrl } from "../../../utils/commonFuctions";
import {
  projectSummaryMentions,
  replacePatterns,
} from "../../../utils/suggestions";
import { payCash } from "../../../utils/svg.file";
import { EmailColumn } from "./helper";
const toolbarOptions = {
  options: [], // Hides all options
};
export default function EmailModal({
  title,
  welcomeNote,
  body,
  // payWithCheckCash,
  // payWithCreditCardAch,
  handleSave,
  action,
  invoice,
  payWithCheckCashNote,
  handleClose,
  openPopups,
  organizationLogo,
}) {
  const [searchParams] = useSearchParams();
  const [editorStateTitle, setEditorStateTitle] = useState("");
  const [editorStateWelcomeNote, setEditorStateWelcomeNote] = useState(
    EditorState.createEmpty(),
  );
  const [editorStateBody, setEditorStateBody] = useState(
    EditorState.createEmpty(),
  );
  // const [initialPayWithCheckCash, setInitialPayWithCheckCash] =
  //   useState(payWithCheckCash);
  // const [initialPayWithCreditCardAch, setInitialPayWithCreditCardAch] =
  //   useState(payWithCreditCardAch);
  // const [paymentLoading, setPaymentLoading] = useState(false);
  const [isPayCheckCash, setIsPayCheckCash] = useState(false);
  const [payWithCheckCashNoteContent, setPayWithCheckCashNoteContent] =
    useState(EditorState.createEmpty());

  useEffect(() => {
    const contentBlockBody = htmlToDraft(payWithCheckCashNote || "");
    if (contentBlockBody) {
      const contentStateBody = ContentState.createFromBlockArray(
        contentBlockBody.contentBlocks,
      );
      const newEditorStateBody =
        EditorState.createWithContent(contentStateBody);
      setPayWithCheckCashNoteContent(newEditorStateBody);
    }
  }, [payWithCheckCashNote]);

  const saveAndClose = () => {
    // Convert editor content to HTML
    // const titleContent = draftToHtml(
    //   convertToRaw(editorStateTitle.getCurrentContent()),
    // );
    const welcomeNoteContent = draftToHtml(
      convertToRaw(editorStateWelcomeNote.getCurrentContent()),
    );
    const bodyContent = draftToHtml(
      convertToRaw(editorStateBody.getCurrentContent()),
    );
    const payWithCheckCashTemplate = draftToHtml(
      convertToRaw(payWithCheckCashNoteContent.getCurrentContent()),
    );
    setIsPayCheckCash(false);
    // Call handleSave function to save and close the modal
    handleSave({
      title: editorStateTitle,
      welcomeNote: welcomeNoteContent,
      body: replacePatterns(bodyContent),
      payWithCheckCash: true,
      // payWithCreditCardAch: initialPayWithCreditCardAch,
      // payWithCheckCashNote: draftToHtml(
      //   convertToRaw(payWithCheckCashNoteContent.getCurrentContent()),
      // ),
      payWithCheckCashNote: replacePatterns(payWithCheckCashTemplate),
    });
  };
  // const togglePaymentOption = (option) => {
  //   if (option === "checkCash") {
  //     if (initialPayWithCheckCash && !initialPayWithCreditCardAch) {
  //       message.warning("At least one payment option must be selected.");
  //       return;
  //     }
  //     setInitialPayWithCheckCash(!initialPayWithCheckCash);
  //   } else if (option === "creditCardAch") {
  //     if (initialPayWithCreditCardAch && !initialPayWithCheckCash) {
  //       message.warning("At least one payment option must be selected.");
  //       return;
  //     }
  //     setInitialPayWithCreditCardAch(!initialPayWithCreditCardAch);
  //   }
  // };

  const onEditorStateChangeTitle = (id) => {
    setEditorStateTitle(document.getElementById(id).innerHTML);
  };

  const onEditorStateChangeWelcomeNote = (editorStateWelcomeNote) => {
    setEditorStateWelcomeNote(editorStateWelcomeNote);
  };

  const onEditorStateChangeBody = (editorStateBody) => {
    setEditorStateBody(editorStateBody);
  };
  const showModal = () => {
    openPopups();
    handleClose();
  };
  const showCashModal = () => {
    setIsPayCheckCash(true);
  };

  const handleCashCancel = () => {
    setIsPayCheckCash(false);
    // handleClose();
  };
  // const handleStripePayment = async () => {
  //   setPaymentLoading(true);
  //   const response = await api.request({
  //     url: `v3/invoice/customer/payment-link?token=${searchParams.get(
  //       "token",
  //     )}&invoiceId=${invoice._id}`,
  //     method: "GET",
  //   });
  //   if (response.remote === "success") {
  //     window.location.href = response.data.data.paymentLink;
  //   } else {
  //     message.error("Something went wrong");
  //   }
  //   setPaymentLoading(false);
  // };
  useEffect(() => {
    if (title) {
      document.getElementById("editable-content").innerHTML = title;
      setEditorStateTitle(title);
    }

    const contentBlockWelcomeNote = htmlToDraft(welcomeNote || "");
    if (contentBlockWelcomeNote) {
      const contentStateWelcomeNote = ContentState.createFromBlockArray(
        contentBlockWelcomeNote.contentBlocks,
      );
      const newEditorStateWelcomeNote = EditorState.createWithContent(
        contentStateWelcomeNote,
      );
      setEditorStateWelcomeNote(newEditorStateWelcomeNote);
    }

    const contentBlockBody = htmlToDraft(body || "");
    if (contentBlockBody) {
      const contentStateBody = ContentState.createFromBlockArray(
        contentBlockBody.contentBlocks,
      );
      const newEditorStateBody =
        EditorState.createWithContent(contentStateBody);
      setEditorStateBody(newEditorStateBody);
    }
  }, [title, welcomeNote, body]);
  useEffect(() => {
    if (action) {
      markVisibleEmailDetails(searchParams.get("token"));
    }
  }, [action]);

  const handleContentInput = (payWithCheckCashNoteEditorState) => {
    setPayWithCheckCashNoteContent(payWithCheckCashNoteEditorState);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-end mb-2">
        {!action && (
          <Button type="primary" onClick={() => showCashModal(true)}>
            Next
          </Button>
        )}
      </div>
      <div className="d-flex gap-4 flex-column">
        <div className="d-flex gap-5 align-items-center flex-wrap">
          {organizationLogo && (
            <img
              alt="organization-logo"
              src={createValidUrl(organizationLogo)}
              width={203}
              height={75}
              style={{ objectFit: "contain" }}
            />
          )}
          <div className="flex-fill">
            <div className={`font-s ${action ? "" : ""}`}>
              <div className="editor-wrapper contentscroll">
                <div
                  id="editable-content"
                  style={{
                    fontSize: "25px",
                    fontWeight: "500",
                    color: "#667085",
                    maxWidth: "510px",
                    outline: "none",
                  }}
                  contentEditable={!action}
                  onChange={onEditorStateChangeTitle}
                  onChangeCapture={(e) => console.log("e", e)}
                  onKeyUp={() => onEditorStateChangeTitle("editable-content")}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {/* invoice-setting-card */}
        <div className="">
          <div className="editor-wrapper content-descption-scroll">
            <Editor
              editorState={editorStateWelcomeNote}
              onEditorStateChange={onEditorStateChangeWelcomeNote}
              toolbar={toolbarOptions}
              toolbarHidden
              readOnly={!!action}
            />
          </div>
        </div>
        <div className="table-setting-box">
          <Table
            // style={{ whiteSpace: "pre" }}
            columns={EmailColumn.filter(
              (col) =>
                !["amount_paid", "date_paid", "balance"].includes(
                  col.dataIndex,
                ),
            )}
            dataSource={[invoice] || []}
            // scroll={{ x: 1300 }}
            tableLayout="auto"
            className="userTable estimation-change-order-table"
            pagination={false}
          />
        </div>
        <div className="invoice-setting-card">
          <div className="editor-wrapper content-descption-scroll">
            <Editor
              readOnly={!!action}
              editorState={editorStateBody}
              onEditorStateChange={onEditorStateChangeBody}
              toolbarHidden
              mention={{
                trigger: "@",
                separator: " ",
                suggestions: projectSummaryMentions,
              }}
            />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end gap-3 flex-wrap">
          <>
            {action && (
              <>
                <Button className="clear-filter" onClick={handleClose}>
                  Pay Later
                </Button>
                <Button
                  className="apply-btn mobile-width"
                  type="primary"
                  onClick={showModal}
                >
                  Pay Deposit Now
                </Button>
                {/* initialPayWithCreditCardAch && (
                  <>
                    <Button
                      type="primary"
                      loading={paymentLoading}
                      disabled={paymentLoading}
                      onClick={handleStripePayment}
                    >
                      Pay With Credit Card/ACH
                    </Button>
                  </>
                )} */}
              </>
            )}
          </>
        </div>
      </div>
      <Modal
        title={false}
        open={isPayCheckCash}
        onOk={saveAndClose}
        onCancel={handleCashCancel}
        footer={false}
        closable={false}
        className="custom-modal"
      >
        <div className="d-flex gap-3">
          {payCash}
          <div className="flex-fill">
            <h2 className="pay-title">To pay with a Check or Cash</h2>
            <div
              className="w-100 border p-3 text-content"
              style={{
                borderRadius: 8,
                maxWidth: 600,
                minHeight: 150,
                width: "100%",
              }}
            >
              <Editor
                editorState={payWithCheckCashNoteContent}
                onEditorStateChange={handleContentInput}
                toolbar={toolbarOptions}
                toolbarHidden
                mention={{
                  trigger: "@",
                  separator: " ",
                  suggestions: projectSummaryMentions,
                }}
                editorStyle={{ minHeight: "150px" }}
              />
            </div>
            <div className="text-end pt-4">
              <Button key="confirm" type="primary" onClick={saveAndClose}>
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
