import { Button, Col, Form, Image, Input, Layout, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { surveyAffiliate } from "../../../api/affiliate";
import affiliateRegisterImage from "../../../images/affiliate_login_rightImg.png";
import logo from "../../../images/logo-signup.png";
import { getSurveyDetailAction } from "../../../redux/affiliate/affiliate.action";
import SmallLoader from "../../loader/smallLoader";

const { Content } = Layout;

const AffiliateSurvey = ({ isSuperAdmin = false }) => {
  const history = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const affiliateId = useSelector((state) => state?.affiliate?.rowDetails?.row);
  const affiliateDetails = useSelector(
    (state) => state?.affiliate?.surveyDetails,
  );
  const { loading: formLoading } = useSelector((state) => state?.affiliate);
  const affiliateToken = localStorage.getItem("affiliateToken");
  const configs = {
    title: "",
    className: "modal-pop-up",
    content: (
      <div>
        <h5>Registration Complete!</h5>
        <span>
          The next step is to fill out the necessary documentation to approve
          your affiliate account. Please click the button below to continue.{" "}
        </span>
      </div>
    ),
    okText: "Go To My Account",
    onOk() {
      history("/affiliate/profile", { state: { to: "docNPay" } });
    },
  };
  const handleSubmit = async (data) => {
    const response = await surveyAffiliate(data, dispatch);
    if (response.remote === "success") {
      setLoading(false);
      Modal.info(configs);
    } else {
      setLoading(false);
      console.log("response", {
        remote: response.remote,
        error: response.error,
      });
    }
  };
  useEffect(() => {
    if (!affiliateToken && !isSuperAdmin) {
      history("/affiliate");
    }
  }, []);

  useEffect(() => {
    if (affiliateId?._id && isSuperAdmin) {
      dispatch(getSurveyDetailAction(setLoading, affiliateId?._id));
    }
  }, []);

  useEffect(() => {
    if (affiliateDetails && isSuperAdmin) {
      form.setFieldsValue({ ...affiliateDetails });
    }
  }, [affiliateDetails]);

  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: 540,
        alignItems: "center",
      }}
    >
      <SmallLoader />
    </div>
  );

  return isSuperAdmin && loading ? (
    <Loader />
  ) : (
    (affiliateToken || isSuperAdmin) && (
      <>
        <Row>
          {!isSuperAdmin && (
            <Col lg={12} xs={24} className="d-none d-sm-block">
              <img
                src={affiliateRegisterImage}
                alt="affiliate-banner"
                style={{ width: "100%", objectFit: "cover", height: "100%" }}
              />
            </Col>
          )}
          <Col lg={isSuperAdmin ? 24 : 12} xs={24}>
            <div
              className="main-layout"
              {...{
                ...(isSuperAdmin && {
                  style: { margin: 0 },
                }),
              }}
            >
              <Content
                className={`h-full ${!isSuperAdmin && "afflite-market"}`}
                // {...{
                //   ...(!isSuperAdmin && {
                //     style: { padding: "60px 3rem 1.5rem" },
                //   }),
                // }}
              >
                {!isSuperAdmin && (
                  <div className="w-100" style={{ textAlign: "center" }}>
                    <Image
                      src={logo}
                      preview={false}
                      style={{
                        width: "200px",
                        height: "47.07px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
                <h2
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    padding: "27px 0 7px 0",
                  }}
                >
                  Fill out the survey to get started
                </h2>
                <Form
                  layout="vertical"
                  autoComplete="off"
                  onFinish={handleSubmit}
                  form={form}
                  className="affiliate-survay-form"
                  disabled={isSuperAdmin}
                >
                  <Form.Item
                    label={
                      <>
                        <span>
                          How many people do you think you can refer to ZevBit
                          Software?
                        </span>
                        <span style={{ color: "red", marginLeft: 5 }}>*</span>
                      </>
                    }
                    name="peopleYouReferToZevBit"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please fill how many people you want to refer!",
                      },
                    ]}
                    requiredMark="optional"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        <span>
                          What target audience/niche market do you focus on?
                        </span>
                        <span style={{ color: "red", marginLeft: 5 }}>*</span>
                      </>
                    }
                    name="targetedAudience"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please fill target audience/niche you want to focus on!",
                      },
                    ]}
                    requiredMark="optional"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        <span>
                          How much time per week can you dedicate to promoting
                          ZevBit Software and maintaining an active affiliate
                          network?
                        </span>
                        <span style={{ color: "red", marginLeft: 5 }}>*</span>
                      </>
                    }
                    name="perWeekTimeForPromoteZevBit"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please fill per week time for promote ZevBit!",
                      },
                    ]}
                    requiredMark="optional"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        <span>
                          What are the platforms you will be using to promote
                          ZevBit Software?
                        </span>
                        <span style={{ color: "red", marginLeft: 5 }}>*</span>
                      </>
                    }
                    name="usePlateFormForPromoteZevBit"
                    rules={[
                      {
                        required: true,
                        message: "Please fill platforms you want to promote!",
                      },
                    ]}
                    requiredMark="optional"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        <span>
                          How many followers do you have on your social media
                          accounts?
                        </span>
                        <span style={{ color: "red", marginLeft: 5 }}>*</span>
                      </>
                    }
                    name="socialMediaFollowers"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please fill how many followers you have on your social media account!",
                      },
                    ]}
                    requiredMark="optional"
                  >
                    <Input />
                  </Form.Item>
                  {!isSuperAdmin && (
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ float: "right", color: "#fff" }}
                        className="affiliate-survay-button"
                        disabled={formLoading}
                      >
                        Submit Survey
                      </Button>
                    </Form.Item>
                  )}
                </Form>
                {!isSuperAdmin && (
                  <div className="company-design d-none d-sm-block">
                    © ZevBit 2023
                  </div>
                )}
              </Content>
            </div>
          </Col>
        </Row>
      </>
    )
  );
};

export default AffiliateSurvey;
