import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";

import Success from "../../../../../images/affiliateImages/success.svg";

const fontSize18 = {
  fontSize: 18,
};

const fontSize20 = {
  fontSize: 20,
};

const commonStyle = {
  position: "absolute",
  width: "64.7%",
  borderRadius: 5,
  top: 0,
};

const commonAlertProps = {
  style: commonStyle,
  type: "success",
  showIcon: true,
};

const commonBannerProps = {
  showIcon: true,
  style: { borderRadius: 8 },
};
const Content = ({ children }) => <div className="mx-1">{children}</div>;

const P = ({ children, color = "#027A48", style }) => (
  <p className="m-0" style={{ fontSize: 14, color, ...style }}>
    {children}
  </p>
);

export const selectAlertMessage = {
  profile: {
    message: (
      <Content>
        <P>Profile Saved Successfully</P>
      </Content>
    ),
    icon: <CheckCircleOutlined style={{ ...fontSize18 }} />,
    ...commonAlertProps,
  },
  updateEmail: {
    message: (
      <Content>
        <P>Your email was updated successfully</P>
        <P>Please use this email to login in the future.</P>
      </Content>
    ),
    icon: <CheckCircleOutlined style={{ ...fontSize20 }} />,
    ...commonAlertProps,
  },
  updatePassword: {
    message: (
      <Content>
        <P>Your password was updated successfully</P>
      </Content>
    ),
    icon: <CheckCircleOutlined style={{ ...fontSize18 }} />,
    ...commonAlertProps,
  },
};

export const selectDocumentationMessage = {
  approved: {
    message: (
      <Content>
        <P>Your Documentation Is Approved!</P>
        <P color="#039855">
          Start sharing your unique link & promo code to as many landscapers as
          possible! The more you share, the more you earn.
        </P>
      </Content>
    ),
    type: "success",
    icon: <img src={Success} alt="success-icon" />,
    ...commonBannerProps,
  },
  requested: {
    message: (
      <Content>
        <P color="#B54708">Your Documentation Was Successfully Submitted</P>
        <P color="#DC6803">
          Approval process takes 1-2 business days. Once you are approved you
          will receive an email with your unique affiliate link & promo code!
        </P>
      </Content>
    ),
    type: "warning",
    icon: <WarningOutlined style={{ ...fontSize20, color: "#DC6803" }} />,
    ...commonBannerProps,
  },
  registered: {
    message: (
      <Content>
        <P color="#B54708">Your Documentation Was Successfully Submitted</P>
        <P color="#DC6803">
          Approval process takes 1-2 business days. Once you are approved you
          will receive an email with your unique affiliate link & promo code!
        </P>
      </Content>
    ),
    type: "info",
    icon: <WarningOutlined style={{ ...fontSize20, color: "#DC6803" }} />,
    ...commonBannerProps,
  },
  rejected: {
    message: (
      <Content>
        <P color="#B54708">Your account has been rejected.</P>
      </Content>
    ),
    type: "warning",
    icon: <WarningOutlined style={{ ...fontSize20, color: "#DC6803" }} />,
    ...commonBannerProps,
  },
};
