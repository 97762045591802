import { Button } from "antd";
import React from "react";

const InvoicingDemo = ({ title, handleCancel = null }) => {
  const videoLink = process.env.REACT_APP_INVOICE_DEMO_LINK;
  const handleContinue = () => {
    const tabPanel = new URLSearchParams(location.search).get("panel");
    if (
      location.pathname === "/invoice-dashboard" ||
      tabPanel === "invoicing"
    ) {
      window.location.reload();
    } else {
      location.replace("/invoice-dashboard");
    }
    handleCancel();
  };
  return (
    <>
      <h2 className="change-title mb-3">{title}</h2>
      <div className="video">
        <iframe
          className="d-block"
          width="100%"
          height="315"
          src={videoLink}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <div className="text-center mt-3">
        <Button
          type="primary"
          className="w-275"
          onClick={() => handleContinue()}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default InvoicingDemo;
