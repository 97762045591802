import { Button, Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";

import { viewedReceipt } from "../../api/invoice";
import { createValidUrl } from "../../utils/commonFuctions";
import { capitalizeString } from "../../utils/constants/constants";

const getKey = {
  invoice: "invoiceList",
  receipt: "receiptData",
};

const ViewReceipt = ({
  receiptType,
  callingView,
  setViewed,
  setCallingView,
}) => {
  const currentIndex = useSelector((state) => state?.invoice?.currentIndex);
  const invoiceList = useSelector((state) => state?.invoice?.sendReceipt)?.[
    getKey[receiptType]
  ]?.[currentIndex];
  const token = new URLSearchParams(location?.search).get("token");
  const isEstimationViewed = localStorage.getItem(invoiceList?._id);
  const viewEstimate = async () => {
    setCallingView(true);
    setViewed(false);
    if (token && !isEstimationViewed) {
      const response = await viewedReceipt(token);
      if (response.remote === "success") {
        localStorage.setItem(invoiceList?._id, "true");
      }
      setCallingView(false);
    }
  };
  return (
    <Row style={{ justifyContent: "center" }}>
      <Col>
        {invoiceList?.organizationLogo && (
          <div>
            <img
              src={createValidUrl(invoiceList?.organizationLogo)}
              alt="Company logo"
              style={{ width: 200 }}
            />
          </div>
        )}
        <div>
          <p style={{ fontSize: 20, fontWeight: "bold" }}>
            Your {receiptType && capitalizeString(receiptType)} From{" "}
            {invoiceList?.organizationName}
          </p>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => viewEstimate()}
            style={{ margin: "0 auto" }}
            disabled={callingView}
          >
            View {receiptType && capitalizeString(receiptType)}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default ViewReceipt;
