import { getDistance } from "../../api/customer";
import { getUserEstimationList } from "../../api/v2/userEstimation";
import distanceStringToMinutes from "../../utils/distance/distanceInMinutes";
import leadTypes from "./lead.types";

export const setCustomerLeadInfo = (leadInfo) => async (dispatch, getState) => {
  if (!leadInfo) {
    return;
  }
  dispatch({
    type: leadTypes.LEAD_LOADING,
  });
  const state = getState();
  const { organization } = state;
  if (!leadInfo.distance) {
    const distanceBody = {
      origins: organization.address,
      destinations: leadInfo.address,
    };
    const farness = await getDistance(distanceBody);
    if (farness.remote === "success") {
      const { totalMinutes } = distanceStringToMinutes(farness.data.Address);
      leadInfo.distance = totalMinutes || 0;
    }
  }
  dispatch({
    type: leadTypes.LEAD_INFO,
    leadInfo,
  });
};

export const setUserEstimationList = (data) => async (dispatch) => {
  if (!data) {
    return;
  }
  dispatch({
    type: leadTypes.LEAD_LOADING,
  });
  const id = data;
  const userEstimateListInfo = await getUserEstimationList(id);
  dispatch({
    type: leadTypes.USER_ESTIMATION,
    userEstimateListInfo,
  });
};
