import { message } from "antd";

import { getDocNPayDetail } from "../../api/admin";
import {
  changeAffiliateCommission,
  getAffiliateMarketerDetails,
  getLinkNCodeData,
  getLinkNCodeSuperAdminData,
  getMyReferrals,
  getPaymentData,
  getPaymentSuperAdminData,
  getProfileDetails,
  getReferralHeaders,
  getReferralHistory,
  getReferralOverviewSuperAdmin,
  getReferralsList,
  getReferralsListSuperAdmin,
  getReferralsOverview,
  getReferralSuperAdminAction,
  getReferralSuperAdminActivity,
  getReferralSuperAdminHeader,
  getReportedList,
  getSummaryDetails,
  getSummaryDetailSuperAdmin,
  getSuperAdminDoc,
  getSurveyDetails,
  postPaymentRequest,
  updateAffiliateStatus,
  updateAffiliateUserEmail,
  updateAffiliateUserPassword,
} from "../../api/affiliate";
import api from "../../api/api";
import { modifyAffiliateStatus } from "../../components/affiliates/v3/helper";
import { affiliateOverviewData } from "../../components/setting/UserListV2/details/helper";
import { AFFILIATE_TYPES } from "./affiliate.types";
export const authAction = (url, data) => async (dispatch) => {
  dispatch(setLoading(true));
  const response = await api.request({
    url,
    method: "POST",
    data,
  });

  if (response.remote === "success") {
    if (response.data.data.token) {
      localStorage.setItem("affiliateToken", response.data.data.token);
    }
    dispatch({
      type: AFFILIATE_TYPES.AUTH,
      payload: response?.data?.data,
    });
    dispatch({
      type: AFFILIATE_TYPES.AUTH_ERROR,
      payload: "",
    });
    dispatch(setLoading(false));
  } else {
    dispatch({
      type: AFFILIATE_TYPES.AUTH_ERROR,
      payload: response?.errors?.errors,
    });
    dispatch(setLoading(false));
  }
};

export const getProfileAction = (setLoading) => async (dispatch) => {
  setLoading(true);
  const response = await getProfileDetails();

  if (response.remote === "success") {
    dispatch({
      type: AFFILIATE_TYPES.PROFILE,
      payload: response?.data?.data,
    });
  }
  setLoading(false);
};

export const profileFormChangeAction = (isChanged) => async (dispatch) => {
  dispatch({
    type: AFFILIATE_TYPES.FORM_CHANGE,
    payload: isChanged,
  });
};

export const setFormFieldValues = (data) => async (dispatch) => {
  dispatch({
    type: AFFILIATE_TYPES.FORM_FIELDS_CHANGE,
    payload: data,
  });
};

export const setLoading = (isLoading) => async (dispatch) => {
  dispatch({
    type: AFFILIATE_TYPES.LOADING,
    payload: isLoading,
  });
};

export const updateProfileData = (data) => async (dispatch) => {
  dispatch({
    type: AFFILIATE_TYPES.PROFILE,
    payload: data,
  });
};

export const alertMessageType = (type) => async (dispatch) => {
  dispatch({
    type: AFFILIATE_TYPES.BANNER_TYPE,
    payload: type,
  });
};

export const showBannerAction = (show) => async (dispatch) => {
  dispatch({
    type: AFFILIATE_TYPES.SHOW_BANNER,
    payload: show,
  });
};

export const setInputErrors = (error) => async (dispatch) => {
  dispatch({
    type: AFFILIATE_TYPES.FORM_ERRORS,
    payload: error,
  });
};

export const resetInputErrors = (fieldName) => async (dispatch) => {
  dispatch({
    type: AFFILIATE_TYPES.RESET_FORM_ERROR,
    payload: fieldName,
  });
};

export const getReferralsListAction =
  (affiliateId, isSuperAdmin = false) =>
  async (dispatch) => {
    const response = isSuperAdmin
      ? await getReferralsListSuperAdmin(affiliateId)
      : await getReferralsList();

    if (response.remote === "success") {
      dispatch({
        type: AFFILIATE_TYPES.REFERRAL_LIST,
        payload: response.data.data,
      });
    } else {
      message.error(response.error?.errors?.error);
    }
  };
export const getReferralsOverviewAction =
  (affiliateId, isSuperAdmin = false, start = "", end = "") =>
  async (dispatch) => {
    const clonedOverview = [...affiliateOverviewData];
    const response = isSuperAdmin
      ? await getReferralOverviewSuperAdmin(affiliateId, start, end)
      : await getReferralsOverview(start, end);

    if (response.remote === "success") {
      let data = { ...response?.data?.data };
      data?.affiliateOverview?.forEach((obj) => {
        const index = clonedOverview?.findIndex(
          (ob) => ob.action === obj?.action,
        );
        if (~index) {
          clonedOverview[index] = { ...obj };
        }
      });
      data = { ...data, affiliateOverview: clonedOverview };
      dispatch({
        type: AFFILIATE_TYPES.OVERVIEW,
        payload: data,
      });
    } else {
      message.error(response.error?.errors?.error);
    }
  };

export const postPaymentRequestAction = (data) => async (dispatch) => {
  const response = await postPaymentRequest(data);

  if (response.remote === "success") {
    dispatch({
      type: AFFILIATE_TYPES.REQUEST_PAYOUT,
      payload: response.data.data,
    });
  } else {
    message.error(response.error?.errors?.error);
  }
};

export const getMyReferralsAction =
  (data, isSuperAdmin = false) =>
  async (dispatch) => {
    const response = isSuperAdmin
      ? await getReferralSuperAdminAction(data)
      : await getMyReferrals(data);

    if (response.remote === "success") {
      dispatch({
        type: AFFILIATE_TYPES.MY_REFERRALS,
        payload: isSuperAdmin ? response.data : response.data?.data,
      });
    } else {
      message.error(response.error?.errors);
    }
  };

export const getSummaryDetailsAction =
  (affiliateId, isSuperAdmin = false) =>
  async (dispatch) => {
    const response = isSuperAdmin
      ? await getSummaryDetailSuperAdmin(affiliateId)
      : await getSummaryDetails();

    if (response.remote === "success") {
      dispatch({
        type: AFFILIATE_TYPES.SUMMARY,
        payload: response.data.data,
      });
    } else {
      message.error(response.error?.errors?.error);
    }
  };

export const saveReferralFilters = (data) => async (dispatch) => {
  dispatch({
    type: AFFILIATE_TYPES.MY_REFERRALS_FILTER,
    payload: data,
  });
};

export const getReferralHeaderDetails =
  (setLoading, orgId, isSuperAdmin = false, affiliateId = null) =>
  async (dispatch) => {
    if (setLoading) setLoading(true);
    const response = isSuperAdmin
      ? await getReferralSuperAdminHeader(affiliateId, orgId)
      : await getReferralHeaders(orgId);

    if (response.remote === "success") {
      dispatch({
        type: AFFILIATE_TYPES.REFERRAL_DETAILS,
        payload: response?.data?.data,
      });
      if (setLoading) setLoading(false);
    } else {
      dispatch({
        type: AFFILIATE_TYPES.REFERRAL_DETAILS,
        payload: {},
      });
      if (setLoading) setLoading(false);
    }
  };

export const getReferralHistoryDetails =
  (
    setLoading,
    orgId,
    page = 1,
    perPage = 10,
    isSuperAdmin = false,
    affiliateId = null,
  ) =>
  async (dispatch) => {
    if (setLoading) setLoading(true);
    const response = isSuperAdmin
      ? await getReferralSuperAdminActivity(affiliateId, orgId)
      : await getReferralHistory(orgId, page, perPage);

    if (response.remote === "success") {
      const data = {
        data: response?.data?.[0]?.data,
        pagination: response?.data?.[0]?.pagination,
      };
      dispatch({
        type: AFFILIATE_TYPES.REFERRAL_HISTORY,
        payload: data,
      });
      if (setLoading) setLoading(false);
    } else {
      dispatch({
        type: AFFILIATE_TYPES.REFERRAL_HISTORY,
        payload: {},
      });
      if (setLoading) setLoading(false);
    }
  };

export const affiliateMarketerAction =
  (setLoading = false, payload = null) =>
  async (dispatch) => {
    if (setLoading) setLoading(true);
    const response = await getAffiliateMarketerDetails(payload);
    if (response.remote === "success") {
      dispatch({
        type: AFFILIATE_TYPES.AFFILIATE_MARKETER,
        payload: response?.data,
      });
      if (setLoading) setLoading(false);
    } else {
      dispatch({
        type: AFFILIATE_TYPES.AFFILIATE_MARKETER,
        payload: {},
      });
    }
  };

export const updateAffiliateStatusAction =
  (id, payload) => async (dispatch, getState) => {
    let data = { ...getState()?.affiliate?.affiliateMarketer };
    const clonedData = [...data?.data];
    const response = await updateAffiliateStatus(id, payload);
    if (response.remote === "success") {
      const recordInd = clonedData?.findIndex((obj) => obj?._id === id);
      clonedData[recordInd] = {
        ...clonedData[recordInd],
        status: modifyAffiliateStatus[payload?.status] || payload?.status,
      };
      data = { ...data, data: [...clonedData] };
      dispatch({
        type: AFFILIATE_TYPES.AFFILIATE_MARKETER,
        payload: { ...data },
      });
    }
  };
export const setRowDetails = (data) => async (dispatch) => {
  dispatch({
    type: AFFILIATE_TYPES.SET_AFFILIATE_ROW_DETAILS,
    payload: data,
  });
};

export const superAdminProfileAction = (id, token) => async (dispatch) => {
  const response = await getProfileDetails(
    `v3/affiliate/super-admin/affiliate-dashboard/profile-details?affiliate=${id}`,
    token,
  );

  if (response.remote === "success") {
    dispatch({
      type: AFFILIATE_TYPES.PROFILE,
      payload: response?.data?.data,
    });
  }
};

export const superAdminModalAction = (data) => async (dispatch) => {
  dispatch({
    type: AFFILIATE_TYPES.SUPER_ADMIN_MODALS,
    payload: data,
  });
};

export const updateUserEmail = (data, toggleModal) => async (dispatch) => {
  const response = await updateAffiliateUserEmail(data);
  if (response.remote === "success") {
    dispatch(showBannerAction(true));
    dispatch(alertMessageType("profile"));
    toggleModal("changeEmail");
    dispatch({
      type: AFFILIATE_TYPES.PROFILE,
      payload: { email: data?.newEmail },
    });
  } else {
    message.error(response?.error?.errors?.error);
  }
};
export const updateUserPass = (data, toggleModal) => async (dispatch) => {
  const response = await updateAffiliateUserPassword(data);
  if (response.remote === "success") {
    dispatch(showBannerAction(true));
    dispatch(alertMessageType("profile"));
    toggleModal("changePassword");
  } else {
    message.error(response?.error?.errors?.error);
  }
};

export const getLinksNCodeAction =
  (page, perPage, setLoading, affiliateId = null, isSuperAdmin = false) =>
  async (dispatch) => {
    if (setLoading) setLoading(true);
    const response = isSuperAdmin
      ? await getLinkNCodeSuperAdminData(affiliateId, page, perPage)
      : await getLinkNCodeData(page, perPage);
    if (response.remote === "success") {
      dispatch({
        type: AFFILIATE_TYPES.LINK_CODE,
        payload: response.data?.data,
      });
    } else {
      message.error(response?.error?.errors?.error);
    }
    if (setLoading) setLoading(false);
  };

export const getSurveyDetailAction = (setLoading, id) => async (dispatch) => {
  if (setLoading) setLoading(true);
  const response = await getSurveyDetails(id);
  if (response.remote === "success") {
    dispatch({
      type: AFFILIATE_TYPES.SURVEY_DETAILS,
      payload: response?.data?.data,
    });
  }
  if (setLoading) setLoading(false);
};
export const changeAffiliateCommissionAction =
  (affiliateId, data, toggleModal, form, setLoading = null) =>
  async (dispatch, getState) => {
    const affiliateData = getState()?.affiliate?.affiliateMarketer;
    if (setLoading) setLoading(true);
    const response = await changeAffiliateCommission(affiliateId, data);
    if (response.remote === "success") {
      const index = affiliateData?.data?.findIndex(
        (obj) => obj?._id === affiliateId,
      );
      if (~index) {
        affiliateData.data[index] = {
          ...affiliateData.data[index],
          demo: data?.demoCommission,
          directSignUp: data?.directSignUpCommission,
        };
      }
      dispatch({
        type: AFFILIATE_TYPES.AFFILIATE_MARKETER,
        payload: { ...affiliateData },
      });
      message.success(response?.message);
      if (toggleModal) toggleModal();
      if (form) form?.resetFields();
      if (setLoading) setLoading(false);
    } else {
      form?.setFields([
        { name: ["password"], errors: [response?.error?.error] },
      ]);
      if (setLoading) setLoading(false);
    }
  };

export const getSuperAdminDocInfoAction = (id) => async (dispatch) => {
  const response = await getSuperAdminDoc(id);
  if (response.remote === "success") {
    dispatch({
      type: AFFILIATE_TYPES.DOCUMENT_DETAIL,
      payload: response?.data,
    });
  }
};

export const getReportedListAction = (id, type) => async (dispatch) => {
  const response = await getReportedList(id, type);
  if (response.remote === "success") {
    dispatch({
      type: AFFILIATE_TYPES.REPORTED_LIST,
      payload: { type, data: response?.data },
    });
  } else {
    message.error(response?.error);
  }
};
export const getAffiliatePaymentDataAction =
  (isSuperAdmin = false, affiliateId = null) =>
  async (dispatch) => {
    const response = isSuperAdmin
      ? await getPaymentSuperAdminData(affiliateId)
      : await getPaymentData();

    if (response.remote === "success") {
      console.log(isSuperAdmin);
      dispatch({
        type: AFFILIATE_TYPES.AFFILIATE_PAYMENT_DATA,
        payload: response?.data,
      });
    }
  };

export const getDocNPayDataAction =
  (setLoading, isSuperAdmin = false, affiliateId = "") =>
  async (dispatch) => {
    if (setLoading) setLoading(true);
    const response = !isSuperAdmin
      ? await getDocNPayDetail(setLoading)
      : await getSuperAdminDoc(affiliateId, setLoading);

    if (response.remote === "success") {
      dispatch({
        type: AFFILIATE_TYPES.DOCUMENT_DETAIL,
        payload: response.data?.data,
      });
    }
  };
