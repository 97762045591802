import api from "./api";

export const getPlanValue = async (data) => {
  const response = await api.request({
    url: `/payment/plans?planIds[]=${data.join()}`,
    method: "get",
  });
  return response;
};

export const paymentSuccessAPICall = async (data) => {
  const response = await api.request({
    url: "/payment/complete-payment",
    method: "POST",
    data,
  });
  return response;
};

export const paymentSubscriptionAPICall = async (data) => {
  const response = await api.request({
    url: "/v3/payment/subscription",
    method: "POST",
    data,
  });
  return response;
};
export const applyPromoCodeAPICall = async (data) => {
  const response = await api.request({
    url: "/v3/payment/subscription/apply-coupon",
    method: "POST",
    data,
  });
  return response;
}; // by Natasha - V3

export const trialPaymentSuccessAPICall = async (data) => {
  const response = await api.request({
    url: "/payment/free-trial-subscription",
    method: "POST",
    data,
  });
  return response;
};

export const cancelTrial = async (data) => {
  const response = await api.request({
    url: "/payment/cancel-free-trial",
    method: "put",
    data,
  });
  return response;
};
