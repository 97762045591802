import { Container } from "@mui/system";
import { Col, Row } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import logo from "../../images/logo-signup.png";
import whiteLogo from "../../images/white-logo.png";
import { createValidUrl } from "../../utils/commonFuctions";

const PageNotFound = () => {
  const location = useLocation();
  const [receivedData] = useState(location.state);
  return (
    <>
      <div className="destop-3">
        <Container maxWidth="md">
          <div className="text-center py-5">
            <img src={logo} alt="" style={{ width: "150px" }} />
          </div>
          <div className="dashtop-content">
            <Row gutter={[24, 24]} align="center">
              <Col xs={12} lg={12}>
                <span>sorry!</span>
                <h3>Page not found</h3>
                <p>
                  it seems that what you were looking for is no longer
                  available, please contact{" "}
                  {receivedData?.companyName || "Zevbit"} to solve this problem
                </p>
              </Col>
              <Col xs={12} lg={12}>
                <img
                  src={
                    receivedData.logo ? createValidUrl(receivedData.logo) : logo
                  }
                  alt=""
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className="destop-3-footer">
        <Container maxWidth="md">
          <Row gutter={[24, 24]} align="center">
            <Col xs={6} lg={6}>
              <img src={whiteLogo} alt="" style={{ width: "150px" }} />
            </Col>
            <Col xs={18} lg={18}>
              <p>© Copyright 2023 | ZevBit | All Rights Reserved</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default PageNotFound;
