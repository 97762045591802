import { Avatar, Button, Col, Input, message, Row } from "antd";
import { useState } from "react";

import { editPromoCode } from "../../../../api/admin";
import userProfile from "../../../../images/blank-profile.png";
import WriteIcon from "../../../../images/editIcon.png";
import { createValidUrl } from "../../../../utils/commonFuctions";
import { modifyData } from "../helper";

const InternalNotes = ({
  record,
  handleCancel,
  addedNote,
  notesBy,
  getPromoCodeList,
}) => {
  const { TextArea } = Input;
  const [isEdit, setIsEdit] = useState(false);
  const [note, setNote] = useState(addedNote);
  const handleEdit = async () => {
    const modRec = { ...record, notes: note };
    const payload = modifyData(modRec);
    const result = await editPromoCode(record?._id, payload);
    if (result.remote === "success") {
      message.success("Internal Note edited successfully", 10);
      setIsEdit(false);
      handleCancel();
      getPromoCodeList();
    }
  };
  return (
    <>
      <div className="add-promo-code">
        <div className="warning-heading">
          <h3 className="fliter-sort-title-add-tab">Internal Notes</h3>
          <img
            src={WriteIcon}
            alt="Write Icon"
            onClick={() => {
              setIsEdit(true);
            }}
            className="editNote"
          />
        </div>
        <Row gutter={[24, 0]}>
          {isEdit ? (
            <TextArea
              size="large"
              placeholder="Edit Note (Only for internal use)"
              name="notes"
              type="textarea"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              style={{ borderRadius: "8px", height: "123px" }}
              rows={4}
              className="mb-1"
            />
          ) : (
            <Col xs={24} className="mt-4 mb-3 viewNote">
              {addedNote}
            </Col>
          )}
          <Col className="promocode-internal-logoImage-col">
            <Col className="promocode-internal-logoImage">
              <Avatar
                src={createValidUrl(notesBy?.profileImage) || userProfile}
                alt="Avatar Image"
                className="me-2"
              />
              {notesBy?.name}
            </Col>
          </Col>

          <Col xs={24}>
            {isEdit ? (
              <Row gutter={[12, 12]}>
                <Col xs={12}>
                  <Button
                    onClick={() => {
                      setIsEdit(false);
                      handleCancel();
                    }}
                    style={{ width: "100%", borderRadius: "8px" }}
                    size="large"
                    className="mt-4"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs={12}>
                  <Button
                    style={{ width: "100%", borderRadius: "8px" }}
                    size="large"
                    className="mt-4 promocode-submit-button"
                    onClick={() => handleEdit()}
                    type="primary"
                  >
                    Edit
                  </Button>
                </Col>
              </Row>
            ) : (
              <Button
                onClick={() => {
                  setIsEdit(false);
                  handleCancel();
                }}
                style={{ width: "100%", borderRadius: "8px" }}
                size="large"
                type="primary"
              >
                Close
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
export default InternalNotes;
