import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Form } from "antd";
import * as React from "react";

import CustomButton from "../../../../../globalComponents/button";
import { uploadFile } from "../../../../../utils/svg.file";
import UploadFileView from "./UploadFile.view";

const UploadFileModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Dialog
        open={props.uploadopen}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
        className="modal-mui-radius"
      >
        <DialogTitle className="title-editlink">
          <span className="d-inline-block me-2">{uploadFile} </span>{" "}
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Form layout="vertical">
              <UploadFileView />

              <Stack direction="row" spacing={2} className="btn-estimates mt-3">
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={props.handleClose}
                >
                  Cancel
                </Button>
                <CustomButton fullWidth onClick={props.handleClose}>
                  Save
                </CustomButton>
              </Stack>
            </Form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UploadFileModal;
