import { Col, Row, Typography } from "antd";
import React from "react";

import sitePreview from "../../images/affiliateLandingImages/site-preview.png";
import ScheduleDemo from "./common/ScheduleDemo";
import StartFreeTrial from "./common/StartFreeTrial";

const { Title } = Typography;

const TopHeader = ({ promoCode }) => (
  <Row
    id="top-header-section"
    gutter={[24, 24]}
    className="mt-5 affalitte-marketing-mobile"
  >
    <Col xs={24} lg={11}>
      <Title
        style={{
          fontWeight: 600,
          fontSize: 60,
          display: "flex",
          flexDirection: "column",
        }}
      >
        Write Estimates On-Site In Minutes
      </Title>
      <p style={{ fontSize: 20, maxWidth: "70%" }}>
        On Average Landscape Contractors{" "}
        <span style={{ color: "#3483FA", fontWeight: 600 }}>
          Save AT LEAST 36 Hours
        </span>{" "}
        A Month With ZevBit&lsquo;s Instant Estimating!
      </p>
      <div className="mt-5 d-flex">
        <ScheduleDemo promoCode={promoCode} />
        <StartFreeTrial type="primary" className="mx-3" promoCode={promoCode} />
      </div>
    </Col>
    <Col xs={24} lg={13}>
      <img src={sitePreview} alt="site-preview" />
    </Col>
  </Row>
);

export default TopHeader;
