import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getQueryParam } from "../../utils/constants/constants";

export default function BreadcrumbBar(props) {
  const { CustomBackBtn = null, ...rest } = props;
  const navigator = useNavigate();
  const location = useLocation();
  const redirectTo =
    location?.state?.redirectTo ||
    getQueryParam({ name: "redirectTo", location });
  return (
    <div className="breadcrumb-box-main">
      {!rest.hideArrow && !CustomBackBtn ? (
        <div className="float-none text-end">
          <span
            className="btn breadcrumb-btn text-white d-inline-flex align-items-center mt-1"
            onClick={() => {
              if (redirectTo) {
                navigator(redirectTo);
              } else {
                navigator(-1);
              }
            }}
          >
            <ArrowLeftOutlined style={{ fontSize: "20px" }} />
          </span>
        </div>
      ) : (
        <CustomBackBtn
          onClick={() => {
            if (redirectTo) {
              navigator(redirectTo);
            } else {
              navigator(-1);
            }
          }}
        />
      )}
    </div>
  );
}
