/* eslint-disable react-hooks/exhaustive-deps */
import { message } from "antd";
import moment from "moment";
import React, { createContext, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getAllOccupiedCrewForCalender,
  getAllProjectId,
  getCalculatedEndDate,
  getDifferenceBetweenDatesAPI,
  getProjectByIdInJobModule,
  postProjectSchedule,
  scheduleProject,
  setProjectInQue,
} from "../../api/jobStages";
import { LoaderContext } from ".";
export const JobOrganizationContext = createContext();
export const JobOrganizationProvider = ({ children }) => {
  const [distance, setDistance] = useState("");
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [selectServices, setSelectServices] = useState(null);
  const [projectDetailsForcrewTask, setProjectDetailsForcrewTask] =
    useState(null);
  const [callAllFunction, setCallAllFunction] = useState(false);
  const [occupiedCrew, setOccupiedCrew] = useState([]);
  const { setLoading } = useContext(LoaderContext);
  const [queeueProjectResponse, setQueueProjectResponse] = useState({});
  const [projectId, setProjectId] = useState("");
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [selectCrewDetailsForCrewTask, setSelectCrewDetailsForCrewTask] =
    useState("");
  const [customerData, setCustomerData] = useState("");
  const handleDistance = async (value) => {
    setDistance(value);
  };
  const handleSetCustomerData = (value) => {
    setCustomerData(value);
  };
  const projectDetailforAddTaskCrew = async (value) => {
    setProjectDetailsForcrewTask(value);
  };

  const callAllFunctionAfterTaskAdd = async (value) => {
    setCallAllFunction(value);
  };

  const onGetSelectedTaskId = async (id) => {
    if (id === null) {
      setSelectedTaskId(null);
    }
    setSelectedTaskId(id);
  };

  const getselectedService = (value) => {
    setSelectServices(value);
  };

  const onGetProjectId = async (id) => {
    const data = await getAllProjectId(id);
    if (data.remote === "success") {
      setProjectId(data);
    }
  };

  const onGetProjectDetails = async (id) => {
    if (id) {
      try {
        setLoading(true);
        const response = await getProjectByIdInJobModule(id);
        if (response.remote === "success") {
          setProject(response.data.data[0]);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(error.message);
      }
    }
  };

  const getSelectedCrewDetails = async (value) => {
    setSelectCrewDetailsForCrewTask(value);
  };
  const onConstructionScheduleProject = async (values) => {
    try {
      setLoading(true);
      const response = await scheduleProject(values);
      if (response.remote === "success") {
        message.success("Project scheduled successfully");
        navigate("/job-organization-scheduling");
      } else {
        message.error(response.errors.errors.error || "Something went wrong");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const onConstrationQueueProject = async (values) => {
    try {
      setLoading(true);
      const response = await setProjectInQue(values);
      if (response.remote === "success") {
        if (response.data.message) {
          message.success(response.data.message);
          setQueueProjectResponse(response);
        }
      } else {
        message.error(response.errors.errors);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const getOccupiedCrewData = async (values) => {
    try {
      const response = await getAllOccupiedCrewForCalender(values);
      if (response.remote === "success") {
        setOccupiedCrew(response.data.data);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const onCalculateEndDate = async (values) => {
    try {
      if (values && values.startDate === null) {
        values.startDate = moment().format();
      }
      const response = await getCalculatedEndDate(values);

      if (response.remote === "success") {
        return response.data.data;
      }
    } catch (error) {
      message.error(error.message);
    }
    return null;
  };

  const getDifferenceBetweenDates = async (values) => {
    try {
      const response = await getDifferenceBetweenDatesAPI(values);
      if (response.remote === "success") {
        return response.data.data;
      }
    } catch (error) {
      message.error(error.message);
    }
    return null;
  };

  const onProjectSchedule = async (values) => {
    try {
      const response = await postProjectSchedule(values);
      if (response.remote === "success") {
        return response.data;
      }
    } catch (error) {
      message.error(error.message);
    }
    return null;
  };

  const value = {
    selectCrewDetailsForCrewTask,
    callAllFunction,
    selectedTaskId,
    selectServices,
    projectDetailsForcrewTask,
    projectId,
    project,
    setProject,
    occupiedCrew,
    queeueProjectResponse,
    setQueueProjectResponse,
    customerData,
    distance,
    handleSetCustomerData: useCallback((value) => {
      handleSetCustomerData(value);
    }, []),
    onGetProjectDetails: useCallback((id) => {
      onGetProjectDetails(id);
    }, []),
    onGetProjectId: useCallback((id) => {
      onGetProjectId(id);
    }, []),
    getSelectedCrewDetails: useCallback(async (values) => {
      const response = await getSelectedCrewDetails(values);
      return response;
    }, []),
    getselectedService: useCallback((values) => {
      getselectedService(values);
    }, []),
    onConstructionScheduleProject: useCallback((values) => {
      onConstructionScheduleProject(values);
    }, []),
    getOccupiedCrewData: useCallback((values) => {
      getOccupiedCrewData(values);
    }, []),
    onCalculateEndDate: useCallback(async (values) => {
      const response = await onCalculateEndDate(values);
      return response;
    }, []),
    onConstrationQueueProject: useCallback((values) => {
      onConstrationQueueProject(values);
    }, []),
    getDifferenceBetweenDates: useCallback(async (values) => {
      const response = await getDifferenceBetweenDates(values);
      return response;
    }, []),
    handleDistance: useCallback(async (value) => {
      const response = await handleDistance(value);
      return response;
    }, []),
    onProjectSchedule: useCallback(async (values) => {
      const response = await onProjectSchedule(values);
      return response;
    }, []),
    projectDetailforAddTaskCrew: useCallback(async (values) => {
      const response = await projectDetailforAddTaskCrew(values);
      return response;
    }, []),
    onGetSelectedTaskId: useCallback(async (values) => {
      const response = await onGetSelectedTaskId(values);
      return response;
    }, []),
    callAllFunctionAfterTaskAdd: useCallback(async (values) => {
      const response = await callAllFunctionAfterTaskAdd(values);
      return response;
    }, []),
  };
  return (
    <JobOrganizationContext.Provider value={value}>
      {children}
    </JobOrganizationContext.Provider>
  );
};
