import "./affiliateLanding.css";

import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  getLandingPromoCodeDetails,
  visitLink,
} from "../../api/affiliateLanding";
import { getIpAddress } from "../../common/hooks/get-ip-address";
import { getPromoCodeDetailAction } from "../../redux/affiliateLanding/landing.action";
import BannerSection from "./BannerSection";
import CRMSection from "./CRMSection";
import FooterContent from "./FooterContent";
// import FreeTrialSection from "./FreeTrialSection";
import Header from "./Header";
import HowItWorkSection from "./HowItWorkSection";
import InstantEarningSection from "./InstantEarningSection";
import RatingNReviewSection from "./RatingNReviewSection";
import SupportSection from "./SupportSection";
import TopHeader from "./TopHeader";
import TrialBanner from "./TrialBanner";
import WayToEstimate from "./WayToEstimate";

const { Content } = Layout;

const AffiliateLanding = () => {
  const location = useLocation();
  const [promoCode, setPromoCode] = useState();
  const [apiCalled, setApiCalled] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const urlPath = location.pathname;
    setPromoCode(urlPath?.replace(/^\/|\/$/g, ""));
  }, []);

  useEffect(async () => {
    const urlPath = location.pathname;
    const ip = await getIpAddress();
    if (ip?.ip) {
      const payload = {
        ipAddress: ip?.ip,
        promoCode: urlPath?.replace(/^\/|\/$/g, ""),
        userAgent: navigator?.userAgent,
      };
      await visitLink(payload);
    }
  }, []);

  useEffect(async () => {
    if (promoCode && !apiCalled) {
      const result = await getLandingPromoCodeDetails(promoCode);
      if (result.remote === "success") {
        dispatch(
          getPromoCodeDetailAction({
            ...result?.data?.data,
            promoCode,
          }),
        );
      }
      setApiCalled(true);
    }
  }, [promoCode]);

  return (
    <>
      <div className="container landingButtons">
        <Content style={{ marginTop: 30 }}>
          <Header promoCode={promoCode} />
          <TopHeader promoCode={promoCode} />
          <BannerSection promoCode={promoCode} />
          <iframe
            title="landing-video"
            width="100%"
            height={595}
            src="https://www.youtube.com/embed/vu7HTl8t6Z8?autoplay=1&mute=1&enablejsapi=1&wmode=opaque"
            id="landing-video-iframe"
            style={{ display: "block", borderRadius: 8 }}
            className="mt-5 mobile-iframe"
          />
          <InstantEarningSection />
          <HowItWorkSection promoCode={promoCode} />
          <CRMSection />
          <TrialBanner promoCode={promoCode} />
          <SupportSection />
          <RatingNReviewSection />
          <WayToEstimate />
        </Content>
      </div>
      <FooterContent promoCode={promoCode} />
    </>
  );
};

export default AffiliateLanding;
