/* eslint-disable no-unused-vars */
import "react-phone-input-2/lib/style.css";

import {
  MailOutlined,
  PhoneOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";

import { getLandingPromoCodeDetails } from "../../api/affiliateLanding";
import { getCdnToken, signup, updatedSignup } from "../../api/auth";
import { paymentSuccessAPICall } from "../../api/planPricing";
import arrow from "../../images/arrow.png";
import company from "../../images/company.png";
import lock from "../../images/lock.png";
import logo from "../../images/logo.svg";
import RightImage from "../../images/Rectangle_1.png";
import user from "../../images/user.png";
import { getPromoCodeDetailAction } from "../../redux/affiliateLanding/landing.action";
import { createValidUrl } from "../../utils/commonFuctions";
import { signUpStepOneSchema } from "../../validators/auth/auth.validator";
import InputField from "../inputField/inputField.component";
import SmallLoader from "../loader/smallLoader";

const selectedPlanDetail = {
  value: 30000,
  planId: process.env.REACT_APP_ESTIMATING_PRICE_ID,
  planName: "Estimating",
};

const customStyle = {
  WebkitAppearance: "none", // Disable default arrow button appearance for webkit browsers
  MozAppearance: "none", // Disable default arrow button appearance for Mozilla Firefox
  appearance: "none", // Disable default arrow button appearance for other browsers
  paddingRight: "4px", // Adjust padding to align the input text
  color: "green",
};

function FreeTrialSignUp() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    contactNo: "",
    name: "",
    termsCondition: false,
    receiveEmail: false,
  });
  const [state, setState] = useState({
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [accountErr, setAccountErr] = useState(false);
  const [loader, setLoader] = useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };
  const urlParams = new URLSearchParams(window.location.search);
  const planName = urlParams.get("planName") || "estimatingPlan";
  const [logoUrl, setLogoUrl] = useState();
  const promoCode =
    useSelector((state) => state?.landingDetails?.promoCode) ||
    urlParams.get("promoCode");
  const dispatch = useDispatch();

  const fetchCdnToken = async () => {
    try {
      const reponse = await getCdnToken();
      const newCdnToken = reponse.data.token;
      localStorage.setItem("cdnToken", newCdnToken);
      setLogoUrl(createValidUrl("superadmin/logo/zavbit-logo.png"));
    } catch (error) {}
  };

  useEffect(() => {
    fetchCdnToken();
  }, []);
  useEffect(() => {
    if (planName && planName?.length > 0) {
      localStorage.setItem("planName", planName);
    }
  }, [planName]);

  const onToken = async (token, value, planPriceId) => {
    setLoader(true);
    const payload = {
      amount: value,
      planId: planPriceId,
      token: token?.id,
      email: token?.email,
      contactNumber: form?.contactNo,
      companyName: form?.companyName,
      customerName: form?.name,
    };
    const response = await paymentSuccessAPICall(payload);
    response && setLoader(false);
    if (response?.remote === "failure") {
      message.error(response?.errors?.errors || "Something went to wrong", 10);
    } else {
      message.success(
        "Payment completed successfully; please check your mail" ||
          response?.data?.message ||
          "Payment completed successfully",
        10,
      );
      navigate("/auth");
    }
  };

  const handleAllChange = (e) => {
    if (
      e.target.name === "contactNo" &&
      (e.target.value?.length > 11 || e.target.value?.length < 11)
    ) {
      setErrors({
        ...errors,
        contactNo: "Contact number should be minimum 10 digits",
      });
      setForm({ ...form, [e.target.name]: e.target.value });
    } else if (
      e.target.name === "termsCondition" ||
      e.target.name === "receiveEmail"
    ) {
      setForm({ ...form, [e.target.name]: e.target.checked });
    } else {
      setErrors({ ...errors, contactNo: "" });
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e && e?.preventDefault();
    setErrors({});
    const { isValid, errors: formError } = signUpStepOneSchema(form);
    if (!isValid) {
      if (
        form.contactNo &&
        (e.target.value?.length > 11 || e.target.value?.length < 11)
      ) {
        const formErrorClone = formError;
        formErrorClone.contactNo = "Contact number should be minimum 10 digits";
        setErrors(formErrorClone);
      } else {
        setErrors(formError);
      }
      setState({
        message: "",
      });
    } else if (
      form.contactNo &&
      (e.target.value?.length > 11 || e.target.value?.length < 11)
    ) {
      const errorsClone = { ...errors };
      errorsClone.name = "";
      errorsClone.email = "";
      errorsClone.companyName = "";
      errorsClone.password = "";
      errorsClone.confirmPassword = "";
      errorsClone.contactNo = "Contact number should be minimum 10 digits";
      setErrors(errorsClone);
      setState({
        message: "",
      });
    } else {
      setLoader(true);
      const payload = {
        email: form?.email,
        userName: form?.name,
        password: form?.password,
        companyName: form?.companyName,
        contactNumber: form?.contactNo,
        isAgreeTermsAndConditions: form?.termsCondition,
        isSendEmailNotification: form?.receiveEmail,
        planType: localStorage.getItem("planName"),
        isFreeTrial: true,
        ...(promoCode && { linkPromoCode: promoCode }),
      };
      const result = await updatedSignup(payload);
      if (result?.errors) {
        setLoader(false);
        setErrors(
          result.errors.errors?.email ||
            result.errors.errors?.company ||
            "Something went wrong!",
        );
        if (result?.errors?.status === 400) {
          const messageV3 = "Account Already Exists. Login.";
          setAccountErr(true);
          message.error(messageV3, 10);
        } else if (result.errors.errors?.paymentDone) {
          message.error(
            result.errors.errors?.paymentStatusMessage ||
              result.errors.errors?.email ||
              result.errors.errors?.company ||
              "Something went wrong!",
            10,
          );
          setErrors(result.errors.errors || "Something went wrong!");
        } else {
          message.error(
            result.errors.errors?.paymentStatusMessage ||
              result.errors.errors?.email ||
              result.errors.errors?.company ||
              "Something went wrong!",
            10,
          );
          setErrors(result.errors.errors || "Something went wrong!");
        }
      } else {
        setLoader(false);
        localStorage.setItem("companyName", result?.data?.user?.companyName);
        localStorage.setItem("planName", result?.data?.user?.selectedPlan);
        localStorage.setItem(
          "isFreeTrial",
          result?.data?.user?.withTrialPeriod,
        );
        localStorage.setItem(
          "companyNumber",
          result?.data?.user?.companyNumber,
        );
        localStorage.setItem("userId", result?.data?.user?.id);
        localStorage.setItem("isCardDeclined", false);
        localStorage.setItem("isSubscriptionCanceled", false);
        message.success("Signed up successfully", 10);
        if (!result?.data?.user?.isPaymentCompleted) {
          navigate("/payment-information");
        }
      }
    }
  };

  const tooltipContent = (
    <div>
      <p>Your password needs to:</p>
      <ul>
        <li>Include both lower and upper case characters</li>
        <li>Be at least 8 characters long</li>
        <li>Includes at least one special character</li>
        <li>Includes at least one number</li>
        <li>No spaces accepted</li>
      </ul>
    </div>
  );

  useEffect(async () => {
    if (promoCode) {
      const result = await getLandingPromoCodeDetails(promoCode);
      if (result.remote === "success") {
        dispatch(
          getPromoCodeDetailAction({
            ...result?.data?.data,
            promoCode,
          }),
        );
      }
    }
  }, []);

  return (
    <div className="d-flex align-items-center">
      <Row gutter={[0, 0]}>
        {loader && (
          <div className="center-loader">
            <SmallLoader />
          </div>
        )}
        <Col lg={12} className="d-none d-lg-block">
          <img src={RightImage} alt="NormalImage" className="h-100" />
        </Col>
        <Col lg={12} className="space-100" xs={24}>
          <Card className="radius-40 create-singup-page" bordered={false}>
            <div className="login-heading mb-0">
              <div className="mb-0 text-center position-relative">
                <div className="logo text-center mb-0">
                  <Link to="/">
                    <img
                      width="3300"
                      height="831"
                      alt="ZevBit Logo"
                      style={{ display: "inline-block" }}
                      src={logoUrl}
                      data-orig-src={createValidUrl(
                        "superadmin/logo/zavbit-logo.png",
                      )}
                      className="img-responsive wp-image-1509 lazyautosizes lazyloaded"
                      srcSet={`"${createValidUrl(
                        "superadmin/logo/zavbit-logo-200x48.png",
                      )} 200w, ${createValidUrl(
                        "superadmin/logo/zavbit-logo-400x96.png",
                      )} 400w, ${createValidUrl(
                        "superadmin/logo/zavbit-logo-600x144.png",
                      )} 600w, ${createValidUrl(
                        "superadmin/logo/zavbit-logo-800x192.png",
                      )} 800w, ${createValidUrl(
                        "superadmin/logo/zavbit-logo-1200x288.png",
                      )} 1200w, ${createValidUrl(
                        "superadmin/logo/zavbit-logo.png",
                      )} 3468w`}
                      data-srcset={`${createValidUrl(
                        "superadmin/logo/zavbit-logo-200x48.png",
                      )} 200w, ${createValidUrl(
                        "superadmin/logo/zavbit-logo-400x96.png",
                      )} 400w, ${createValidUrl(
                        "superadmin/logo/zavbit-logo-600x144.png",
                      )} 600w, ${createValidUrl(
                        "superadmin/logo/zavbit-logo-800x192.png",
                      )} 800w, ${createValidUrl(
                        "superadmin/logo/zavbit-logo-1200x288.png",
                      )} 1200w, ${createValidUrl(
                        "superadmin/logo/zavbit-logo.png",
                      )} 3468w`}
                      data-sizes="auto"
                      data-orig-sizes="(max-width: 640px) 100vw, 400px"
                      sizes="280px"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <h2 className="heaading02">Create an account</h2>
            <h6 className="heading06">Start your 14-day free trial.</h6>
            <Form onSubmitCapture={handleSubmit}>
              <InputField
                value=""
                label="Full Name*"
                type="text"
                name="name"
                dclass="ant-icon-space mb-0"
                onChange={handleAllChange}
              />
              <div role="alert" className="text-danger">
                {errors.name}
              </div>
              <InputField
                variant="light"
                label="Email*"
                type="text"
                name="email"
                dclass="ant-icon-space mb-0"
                onChange={handleAllChange}
              />
              <div role="alert" className="text-danger">
                {errors.email}
              </div>
              {accountErr && (
                <div role="alert" className="text-danger">
                  Account Already Exists. <Link to="/login">Log in</Link>
                </div>
              )}
              <Form.Item className="mb-0 phoneInput">
                <label className="ant-label-login">Phone Number*</label>
                <PhoneInput
                  inputStyle={customStyle}
                  className="PHONEiNPUT"
                  name="contactNo"
                  value={form?.contactNo}
                  onChange={(e) =>
                    handleAllChange({
                      target: { value: e, name: "contactNo" },
                    })
                  }
                  countryCodeEditable={false}
                  onlyCountries={["us"]}
                  disableCountryGuess
                  country="us"
                  isValid
                />
                <div role="alert" className="text-danger">
                  {errors.contactNo}
                </div>{" "}
              </Form.Item>
              <InputField
                value=""
                label="Company Name*"
                type="text"
                name="companyName"
                dclass="ant-icon-space mb-0"
                onChange={handleAllChange}
              />
              <div role="alert" className="text-danger">
                {errors.companyName}
              </div>
              <Form.Item className="mb-0">
                <label className="ant-label-login">Password*</label>
                <Input
                  size="large"
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  onChange={handleAllChange}
                  suffix={
                    <Tooltip title={tooltipContent}>
                      <QuestionCircleOutlined className="question-mark" />
                    </Tooltip>
                  }
                />
                <Checkbox
                  className="show-password-checkbox"
                  onChange={togglePasswordVisibility}
                  checked={passwordVisible}
                >
                  Show Password
                </Checkbox>

                <div role="alert" className="text-danger">
                  <div role="alert" className="text-danger">
                    {errors.password && (
                      <>
                        Your password needs to:
                        <br />
                        1. Be at least 8 characters long <br />
                        2. Include both lower and upper case characters
                        <br />
                        3. Includes at least one special character <br />
                        4. Includes at least one number <br />
                        5. No spaces accepted
                      </>
                    )}
                  </div>
                </div>
              </Form.Item>
              <Form.Item className="mb-2">
                <label className="ant-label-login">Confirm Password*</label>
                <Input
                  size="large"
                  className="ant-login-input"
                  type={passwordVisible ? "text" : "password"}
                  name="confirmPassword"
                  onChange={handleAllChange}
                  suffix={
                    <Tooltip title={tooltipContent}>
                      <QuestionCircleOutlined className="question-mark" />
                    </Tooltip>
                  }
                />
                <div role="alert" className="text-danger">
                  {errors.confirmPassword}
                </div>
              </Form.Item>
              <Form.Item className="mb-2">
                <Checkbox
                  name="termsCondition"
                  checked={form?.termsCondition}
                  onChange={handleAllChange}
                >
                  I agree to ZevBit&lsquo;s{" "}
                  <a
                    href="https://zevbit.com/terms-of-service/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms & Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://zevbit.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </Checkbox>
                <div role="alert" className="text-danger">
                  {errors?.termsCondition}
                </div>
              </Form.Item>
              <Form.Item className="mb-2">
                <Checkbox
                  name="receiveEmail"
                  checked={form?.receiveEmail}
                  onChange={handleAllChange}
                >
                  I agree to receive Email updates from ZevBit.
                </Checkbox>
                <div role="alert" className="text-danger">
                  {errors?.receiveEmail}
                </div>
              </Form.Item>
              <Form.Item className="mb-0">
                <Button
                  id="ad_free_trial"
                  htmlType="submit"
                  type="submit"
                  className="ant-btn-save"
                  loading={loader || false}
                >
                  Start Free Trial
                </Button>
              </Form.Item>
              <Form.Item className="forLogin">
                Already have an account? <Link to="/auth">Log in</Link>
              </Form.Item>
            </Form>
          </Card>
        </Col>

        <StripeCheckout
          label="Ok" // Component button text
          name="ZevBit" // Modal Header
          description={`Payment For ${selectedPlanDetail?.planName}  Plan`}
          amount={selectedPlanDetail?.value}
          token={(token) =>
            onToken(
              token,
              selectedPlanDetail?.value,
              selectedPlanDetail?.planId,
            )
          }
          className="click-stripe-button ant-btn-save"
          stripeKey={process.env.REACT_APP_STRIPE_PRIVATE_KEY}
          image="https://dev-backend-bucket.s3.amazonaws.com/superadmin/favicon/favicon-270x270.png" // Pop-in header image
          billingAddress={false}
        />
      </Row>
    </div>
  );
}

export default FreeTrialSignUp;
