import "./userlist.css";

import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Stack } from "@mui/material";
import { Avatar, Button, Card, DatePicker, Input, message, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import {
  changeHrmsUserListAction,
  getAffiliateListAction,
  getHrmsUserListAction,
  getSourceListAction,
  getUserListDataAction,
  handleFreeStatusAction,
  handleReactivateStatusAction,
  handleRejectStatusAction,
} from "../../../api/admin";
import ModalDailog from "../../../common/components/ModalDialogue";
import { createValidUrl } from "../../../utils/commonFuctions";
import { capitalizeString } from "../../../utils/constants/constants";
import { convertJSONToCSV, modifyPhoneNumber } from "../../../utils/javascript";
import { SelectArrowNew } from "../../../utils/svg.file";
import SmallLoader from "../../loader/smallLoader";
import Details from "./details";
import UserListFilter from "./fillter/UserListFilter";
import AddAffiliate from "./modal/AddAffiliate";
import AddSource from "./modal/AddSource";
import AddTab from "./modal/AddTab";
import CancelNotes from "./modal/CancelNotes";
import ColumnSetting from "./modal/ColumnSetting";
import FilterContentList from "./modal/UserFilterContent";
import StatusDropdown from "./StatusDropdown";
import TableData from "./Table";

export const formatDate = (date) =>
  date ? moment.utc(date).local()?.format("MM/DD/YYYY") : "-";

const initialFormData = {
  codeName: "",
  promoCode: "",
  notes: "",
  plans: [],
  subscriptionType: "",
  discountType: "",
  numberOfTime: "",
  discount: "",
  quantity: "",
  isUnlimitedQuantity: false,
  startDate: "",
  expirationDate: "",
  noExpirationDate: false,
  isActive: true,
  description: "",
  promoCodeId: "",
  trialDays: "",
  trialMonths: "",
  customTrialDays: null,
};

const initialFilter = {
  status: [],
  numberOfUsers: [],
  trialDays: "",
  trialStartDate: "",
  trialEndDate: "",
  signUpStartDate: "",
  signUpEndDate: "",
  promoCodeId: [],
  leadAdded: [],
  leadConverted: [],
  plan: [],
  billingMethod: [],
  issueDateStart: "",
  issueDateEnd: "",
  lastPaymentDateStart: "",
  lastPaymentDateEnd: "",
};

const sourceInitialValue = {
  name: "",
  type: "",
};

const UserListV2 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [AdvanceFilter, setAdvanceFilter] = useState(false);
  const [, setTableColumnSetting] = useState(false);
  const columnSetting = () => {
    setTableColumnSetting(true);
  };
  const [form, setForm] = useState(sourceInitialValue);
  const [sourceError, setSourceError] = useState({});
  const [, setFormData] = useState(initialFormData);
  const [search, setSearch] = useState("");
  const [, setErrors] = useState({});
  const [userListData, setUserListData] = useState([]);
  const [sortFields, setSortFields] = useState("");
  const [sortOrder, setSortOrder] = useState();
  const [totalCount, setTotalCount] = useState();
  const [onlyUserData, setOnlyUserData] = useState([]);
  const [hrmsUserList, setHrmsUserList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [affiliateList, setAffiliateList] = useState([]);
  const [currentUserListPage, setCurrentUserListPage] = useState(1);
  const [cancelNotesModel, setCancelNotesModel] = useState(false);
  const [addSourceModel, setAddSourceModel] = useState(false);
  const [addAffiliateModel, setAddAffiliateModel] = useState(false);
  const [affiliateSearch, setAffiliateSearch] = useState([]);
  const [cancelNotesObj, setCancelNotesObj] = useState({});
  const [selectedFilter, setSelectedFilter] = useState(initialFilter);
  const [loader, setLoader] = useState(false);
  const [tabName, setTabName] = useState("");
  const [tabNameError, setTabNameError] = useState("");
  const [isInputMode, setIsInputMode] = useState(false);
  const [passDate, setPassDate] = useState(false);
  const [dateValue, setDateValue] = useState("");
  const [openSelect, setOpenSelect] = useState(false);
  const [orgLoader, setOrgLoader] = useState(false);
  const [userListTabsData, setUserListTabsData] = useState([
    {
      id: 1,
      label: "All Users",
      count: "724",
    },
  ]);
  const [showColumnModel, setShowColumnModel] = useState(false);
  const [showUserModel, setShowUserModel] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [detailActiveTab, setDetailActiveTab] = useState(1);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [outside, setOutside] = useState(false);
  const [statusVal, setStatusVal] = useState("");
  const { Option } = Select;
  const AdvanceFilterShow = () => {
    setAdvanceFilter(!AdvanceFilter);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);

    setTableColumnSetting(false);
  };
  const handleCancel = () => {
    setFormData(initialFormData);
    setErrors({});
    setIsModalOpen(false);
    setTableColumnSetting(false);
  };
  const handleTabNameCancel = () => {
    setIsInputMode(false);
  };
  const handleSelectChange = (value) => {
    setDateValue(value);
  };
  const handleApplyClick = async ({
    keyValue,
    noneKeyValue,
    value,
    id,
    ind,
    noneValue,
  }) => {
    const payload = {
      [keyValue]: noneValue ? (keyValue === "affiliate" ? "" : []) : value,
      organizationId: id,
      ...(noneKeyValue && { [noneKeyValue]: noneValue }),
      performedDemoDate: moment(dateValue).format("YYYY-MM-DD"),
    };
    const response = await changeHrmsUserListAction(payload);
    if (response.remote === "success") {
      setPassDate({ [ind]: false });
      setOpenSelect({ [ind]: true });
    }
  };

  const handleHrmsUserChanges = async (
    keyValue,
    noneKeyValue,
    value,
    id,
    ind,
    noneValue,
  ) => {
    let needDate = false;
    const updatedArray = onlyUserData.map((item, i) => {
      if (i === ind) {
        if (
          keyValue === "whoDidDemo" &&
          (!item[keyValue] || !item[keyValue]?.length)
        ) {
          needDate = true;
          setOpenSelect({ [ind]: false });
        } else {
          needDate = false;
        }
        return {
          ...item,
          [keyValue]: value,
          ...(noneKeyValue && { [noneKeyValue]: noneValue }),
        };
      }
      return item;
    });
    setUserListData((prevListData) => [
      {
        ...prevListData[0],
        data: updatedArray,
      },
    ]);

    setOnlyUserData(updatedArray);
    const payload = {
      [keyValue]: noneValue ? (keyValue === "affiliate" ? "" : []) : value,
      organizationId: id,
      ...(noneKeyValue && { [noneKeyValue]: noneValue }),
    };
    if (!needDate) await changeHrmsUserListAction(payload);
    if (keyValue === "source") {
      setLoader(true);
      getUserListData();
    }
    if (needDate) setPassDate({ [ind]: needDate });
  };

  const changeAffiliateSearch = (e) => {
    const filteredOptions = affiliateList.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    setAffiliateSearch(filteredOptions);
  };

  const getColorForTrialDays = (trialDays) => {
    if (trialDays > 5) {
      return "#101828";
    }
    if (trialDays >= 3 && trialDays <= 5) {
      return "#BC8C2F";
    }
    if (trialDays >= 1 && trialDays <= 2) {
      return "#BF1717";
    }
    if (trialDays < 1) {
      return "#101828";
    }
    return "#101828";
  };

  const ModalFooter = ({
    keyValue,
    noneKeyValue,
    value,
    id,
    ind,
    noneValue,
  }) => (
    <div style={{ display: "flex", padding: 10, justifyContent: "center" }}>
      <Button
        onClick={() => {
          setValue(null);
          setOpen(false);
        }}
        style={{
          marginRight: 10,
          borderRadius: "8px",
          padding: "8px 16px",
          height: "auto",
        }}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        style={{ borderRadius: "8px", padding: "8px 16px", height: "auto" }}
        onClick={() =>
          handleApplyClick({
            keyValue,
            noneKeyValue,
            value,
            id,
            ind,
            noneValue,
          })
        }
      >
        Apply
      </Button>
    </div>
  );

  const getUserListData = async (setLoader = null) => {
    if (setLoader) setLoader(true);
    const payload = {
      page: currentUserListPage,
      perPage: 10,
      search,
      ...(sortFields && { fieldName: sortFields }),
      order: sortOrder,
    };
    const result = await getUserListDataAction(payload, setLoader);
    if (result?.remote === "success") {
      setUserListData(result?.data?.data);
      setOnlyUserData(result?.data?.data[0]?.data);
      setTotalCount(result?.data?.data[0]?.pagination[0]?.totalCount);
      if (setLoader) setLoader(false);
    } else {
      if (setLoader) setLoader(false);
    }
  };

  const handleStatusChange = async (value, v, record, ind) => {
    setLoader(true);

    if (value === "Suspend") {
      setLoader(true);
      const result = await handleRejectStatusAction(record?._id);
      if (result?.remote === "success") {
        setLoader(true);
        getUserListData();

        setLoader(false);
        setStatusVal({ [ind]: value });
      } else {
        setLoader(false);
        message.error(result?.errors?.errors?.error, 3);
      }
    }
    if (v === "Suspend" && value === "Approved") {
      setLoader(true);
      const payload = { id: record?._id };

      const result = await handleReactivateStatusAction(payload);
      if (result?.remote === "success") {
        setLoader(true);
        getUserListData();

        setLoader(false);
        setStatusVal({ [ind]: value });
      } else {
        setLoader(false);
        message.error(result?.errors?.errors?.error, 3);
      }
    }
    if (value === "FreeUser") {
      setLoader(true);
      const payload = { organizationId: record?._id, status: "FreeUser" };

      const result = await handleFreeStatusAction(payload);
      if (result?.remote === "success") {
        setLoader(true);
        getUserListData();

        setLoader(false);
        setStatusVal({ [ind]: value });
      } else {
        setLoader(false);
        message.error(result?.errors?.errors?.error, 3);
      }
    }
    if (v === "FreeUser" && value === "Approved") {
      setLoader(true);
      const payload = { organizationId: record?._id, status: "Approved" };
      const result = await handleFreeStatusAction(payload);
      if (result?.remote === "success") {
        setLoader(true);
        getUserListData();
        setLoader(false);
        setStatusVal({ [ind]: value });
      } else {
        setLoader(false);
        message.error(result?.errors?.errors?.error, 3);
      }
    }
    setLoader(false);
  };

  const userListColumns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      render: (text, record) =>
        text?.length > 0 ? (
          <div
            onClick={() => {
              setShowUserModel(true);
              setSelectedUser(record);
            }}
            className="user-list-name"
          >
            {text}
          </div>
        ) : (
          <>-</>
        ),
      sorter: (a, b) =>
        a.companyName.toString().localeCompare(b.companyName.toString()),
      width: 300,
      fixed: "left",
    },
    {
      title: "Account #",
      dataIndex: "accountNumber" || "-",
      sorter: (a, b) => a.accountNumber - b.accountNumber,
      width: 150,
      className: "text-center",
    },
    {
      title: "MA Name",
      dataIndex: "maName" || "-",
      sorter: (a, b) => a.maName.toString().localeCompare(b.maName.toString()),
      width: 200,
      className: "text-center",
    },
    {
      title: "MA Email",
      dataIndex: "maEmail" || "-",
      sorter: (a, b) =>
        a.maEmail.toString().localeCompare(b.maEmail.toString()),
      width: 300,
      className: "text-center",
    },
    {
      title: "MA Number",
      dataIndex: "maNumber",
      sorter: (a, b) =>
        a.maNumber.toString().localeCompare(b.maNumber.toString()),
      width: 200,
      className: "text-center",
      render: (v) => modifyPhoneNumber(v),
    },
    {
      title: "Signed Up",
      dataIndex: "signUpDate",
      render: (v) => <>{formatDate(v)}</>,
      sorter: (a, b) => new Date(a.signUpDate) - new Date(b.signUpDate),
      width: 150,
    },
    {
      title: "Who Scheduled",
      dataIndex: "whoScheduled",
      className: "text-center",
      render: (v, record, ind) => (
        <Select
          mode="multiple"
          className="select-phase-2"
          onChange={(value) => {
            handleHrmsUserChanges(
              "whoScheduled",
              "isNoneWhoScheduled",
              value,
              record?._id,
              ind,
              !!value?.includes("none"),
            );
          }}
          size="large"
          placeholder="Assign User"
          style={{ width: "100%" }}
          value={
            record?.isNoneWhoScheduled
              ? "none"
              : v?.map((val) => (val?._id?.length > 0 ? val?._id : val))
          }
          maxTagCount={2}
          onClick={(e) => e.stopPropagation()}
        >
          <Option value="none">None</Option>
          {hrmsUserList?.map((v, i) => (
            <Option
              value={v?._id}
              key={i}
              disabled={
                onlyUserData[ind]?.whoScheduled?.length > 0 &&
                onlyUserData[ind]?.whoScheduled[0]?._id?.length > 0
                  ? onlyUserData[ind]?.whoScheduled?.length === 4 &&
                    !onlyUserData[ind]?.whoScheduled?.some(
                      (val) => val?._id === v?._id,
                    )
                  : onlyUserData[ind]?.whoScheduled?.length === 4 &&
                    !onlyUserData[ind]?.whoScheduled?.includes(v?._id)
              }
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar src={createValidUrl(v?.profileImage)} size="small" />
                <span>{v?.name}</span>
              </Stack>
            </Option>
          ))}
        </Select>
      ),
      sorter: (a, b) => {
        const aName = (
          a?.isNoneWhoScheduled
            ? "none"
            : a?.whoScheduled?.length > 0
            ? a?.whoScheduled[0]?.name
            : ""
        )?.toLowerCase();
        const bName = (
          b?.isNoneWhoScheduled
            ? "none"
            : b?.whoScheduled?.length > 0
            ? b?.whoScheduled[0]?.name
            : ""
        )?.toLowerCase();
        return aName.localeCompare(bName);
      },
      width: 350,
    },
    {
      title: "Who Did Demo",
      className: "text-center",
      dataIndex: "whoDidDemo",
      render: (v, record, ind) => (
        <>
          <Select
            className="select-phase-2"
            mode="multiple"
            open={openSelect[ind]}
            onFocus={() => setOpenSelect({ [ind]: true })}
            onDropdownVisibleChange={(open) => setOpenSelect(open)}
            onChange={(value) => {
              handleHrmsUserChanges(
                "whoDidDemo",
                "isNoneWhoDidDemo",
                value,
                record?._id,
                ind,
                !!value?.includes("none"),
              );
            }}
            size="large"
            placeholder="Assign User"
            style={{ width: "100%" }}
            value={
              record?.isNoneWhoDidDemo
                ? "none"
                : v?.map((val) => (val?._id?.length > 0 ? val?._id : val))
            }
            maxTagCount={2}
            onClick={(e) => e.stopPropagation()}
          >
            <Option value="none">None</Option>
            {hrmsUserList?.map((v, i) => (
              <Option
                value={v?._id}
                key={i}
                disabled={
                  onlyUserData[ind]?.whoDidDemo?.length > 0 &&
                  onlyUserData[ind]?.whoDidDemo[0]?._id?.length > 0
                    ? onlyUserData[ind]?.whoDidDemo?.length === 4 &&
                      !onlyUserData[ind]?.whoDidDemo?.some(
                        (val) => val?._id === v?._id,
                      )
                    : onlyUserData[ind]?.whoDidDemo?.length === 4 &&
                      !onlyUserData[ind]?.whoDidDemo?.includes(v?._id)
                }
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Avatar src={createValidUrl(v?.profileImage)} size="small" />
                  <span>{v?.name}</span>
                </Stack>
              </Option>
            ))}
          </Select>
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ position: "absolute" }}
          >
            <DatePicker
              open={passDate[ind]}
              value={dateValue}
              disabledDate={(current) =>
                current && current.isAfter(moment().endOf("day"))
              }
              onSelect={(value) => handleSelectChange(value)}
              style={{
                visibility: "hidden",
                width: "100%",
                top: -35,
              }}
              placement="bottomLeft"
              popupClassName="some-class"
              dateRender={(current) => (
                <div
                  className={`ant-picker-cell-inner ${
                    moment(current).format("DD/MM/YYYY") ===
                    moment().format("DD/MM/YYYY")
                      ? "dotsclass"
                      : ""
                  }`}
                >
                  {current.date()}
                </div>
              )}
              renderExtraFooter={() => (
                <>
                  <ModalFooter
                    keyValue="whoDidDemo"
                    noneKeyValue="isNoneWhoDidDemo"
                    value={v}
                    id={record?._id}
                    ind={ind}
                    noneValue={!!value?.includes("none")}
                  />
                </>
              )}
              showToday={false}
            />
          </div>
        </>
      ),
      sorter: (a, b) => {
        const aName = (
          a?.isNoneWhoDidDemo
            ? "none"
            : a?.whoDidDemo?.length > 0
            ? a?.whoDidDemo[0]?.name
            : ""
        )?.toLowerCase();
        const bName = (
          b?.isNoneWhoDidDemo
            ? "none"
            : b?.whoDidDemo?.length > 0
            ? b?.whoDidDemo[0]?.name
            : ""
        )?.toLowerCase();
        return aName.localeCompare(bName);
      },
      width: 350,
    },
    {
      title: "Who Closed",
      dataIndex: "whoClosed",
      className: "text-center",
      render: (v, record, ind) => (
        <Select
          className="select-phase-2"
          mode="multiple"
          onChange={(value) => {
            handleHrmsUserChanges(
              "whoClosed",
              "isNoneWhoClosed",
              value,
              record?._id,
              ind,
              !!value?.includes("none"),
            );
          }}
          size="large"
          placeholder="Assign User"
          style={{ width: "100%" }}
          value={
            record?.isNoneWhoClosed
              ? "none"
              : v?.map((val) => (val?._id?.length > 0 ? val?._id : val))
          }
          maxTagCount={2}
          onClick={(e) => e.stopPropagation()}
        >
          <Option value="none">None</Option>
          {hrmsUserList?.map((v, i) => (
            <Option
              value={v?._id}
              key={i}
              disabled={
                onlyUserData[ind]?.whoClosed?.length > 0 &&
                onlyUserData[ind]?.whoClosed[0]?._id?.length > 0
                  ? onlyUserData[ind]?.whoClosed?.length === 4 &&
                    !onlyUserData[ind]?.whoClosed?.some(
                      (val) => val?._id === v?._id,
                    )
                  : onlyUserData[ind]?.whoClosed?.length === 4 &&
                    !onlyUserData[ind]?.whoClosed?.includes(v?._id)
              }
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar src={createValidUrl(v?.profileImage)} size="small" />
                <span>{v?.name}</span>
              </Stack>
            </Option>
          ))}
        </Select>
      ),
      sorter: (a, b) => {
        const aName = (
          a?.isNoneWhoClosed
            ? "none"
            : a?.whoClosed?.length > 0
            ? a?.whoClosed[0]?.name
            : ""
        )?.toLowerCase();
        const bName = (
          b?.isNoneWhoClosed
            ? "none"
            : b?.whoClosed?.length > 0
            ? b?.whoClosed[0]?.name
            : ""
        )?.toLowerCase();
        return aName.localeCompare(bName);
      },
      width: 350,
    },
    {
      title: "Who's Maintaining",
      dataIndex: "whoMaintaining",
      className: "text-center",
      render: (v, record, ind) => (
        <Select
          mode="multiple"
          className="select-phase-2"
          onChange={(value) => {
            handleHrmsUserChanges(
              "whoMaintaining",
              "isNoneWhoMaintaining",
              value,
              record?._id,
              ind,
              !!value?.includes("none"),
            );
          }}
          size="large"
          placeholder="Assign User"
          style={{ width: "100%" }}
          value={
            record?.isNoneWhoMaintaining
              ? "none"
              : v?.map((val) => (val?._id?.length > 0 ? val?._id : val))
          }
          maxTagCount={2}
          onClick={(e) => e.stopPropagation()}
        >
          <Option value="none">None</Option>
          {hrmsUserList?.map((v, i) => (
            <Option
              value={v?._id}
              key={i}
              disabled={
                onlyUserData[ind]?.whoMaintaining?.length > 0 &&
                onlyUserData[ind]?.whoMaintaining[0]?._id?.length > 0
                  ? onlyUserData[ind]?.whoMaintaining?.length === 4 &&
                    !onlyUserData[ind]?.whoMaintaining?.some(
                      (val) => val?._id === v?._id,
                    )
                  : onlyUserData[ind]?.whoMaintaining?.length === 4 &&
                    !onlyUserData[ind]?.whoMaintaining?.includes(v?._id)
              }
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar src={createValidUrl(v?.profileImage)} size="small" />
                <span>{v?.name}</span>
              </Stack>
            </Option>
          ))}
        </Select>
      ),
      sorter: (a, b) => {
        const aName = (
          a?.isNoneWhoMaintaining
            ? "none"
            : a?.whoMaintaining?.length > 0
            ? a?.whoMaintaining[0]?.name
            : ""
        )?.toLowerCase();
        const bName = (
          b?.isNoneWhoMaintaining
            ? "none"
            : b?.whoMaintaining?.length > 0
            ? b?.whoMaintaining[0]?.name
            : ""
        )?.toLowerCase();
        return aName.localeCompare(bName);
      },
      width: 350,
    },
    {
      title: "Trial Days",
      dataIndex: "trialDays",
      sorter: (a, b) => a?.trialStatsDays - b?.trialStatsDays,
      render: (trialDays, record) => (
        <span style={{ color: getColorForTrialDays(record?.trialStatsDays) }}>
          {trialDays || "-"}
        </span>
      ),
      width: 300,
    },
    {
      title: "Trial End Date",
      dataIndex: "trialEndDate",
      render: (v) => <>{v ? formatDate(v) : "-"}</>,
      sorter: (a, b) => new Date(a?.trialEndDate) - new Date(b?.trialEndDate),
      width: 300,
    },
    {
      title: "# Of Employees",
      className: "text-center",
      dataIndex: "userCount",
      sorter: (a, b) => a.userCount - b.userCount,
      width: 150,
    },
    {
      title: "Last Sign In",
      dataIndex: "lastSignIn",
      render: (v) => <>{formatDate(v)}</>,
      sorter: (a, b) => new Date(a.lastSignIn) - new Date(b.lastSignIn),
      width: 150,
    },
    {
      title: "Leads Added",
      dataIndex: "leadAdded",
      sorter: (a, b) => a?.leadAdded - b?.leadAdded,
      width: 150,
      className: "text-center",
    },
    {
      title: "Leads Converted",
      className: "text-center",
      dataIndex: "leadConverted",
      sorter: (a, b) => a?.leadConverted - b?.leadConverted,
      width: 150,
    },
    {
      title: "Status",
      className: "text-center",
      dataIndex: "status",
      render: (v, record, ind) => (
        <StatusDropdown
          val={statusVal[ind] || v}
          record={record}
          onChange={handleStatusChange}
          getUserListData={getUserListData}
          index={ind}
        />
      ),
      sorter: (a, b) =>
        (a.status === "Approved" && a?.isSubscriptionEnd
          ? "Canceled"
          : a?.status
        )
          ?.toString()
          ?.localeCompare(
            (a.status === "Approved" && b?.isSubscriptionEnd
              ? "Canceled"
              : b?.status
            )?.toString(),
          ),
      width: 250,
    },
    {
      title: "Issue Date",
      className: "text-center",
      dataIndex: "issueDate",
      render: (v) => <>{formatDate(v) || "-"}</>,
      sorter: (a, b) => new Date(a.issueDate) - new Date(b.issueDate),
      width: 150,
    },
    {
      title: "Canceled Notes",
      className: "text-center",
      dataIndex: "cancelNotes",
      render: (cancelNotes) =>
        cancelNotes ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
              setCancelNotesObj(cancelNotes);
              setCancelNotesModel(true);
            }}
          >
            {cancelNotes?.otherNotes?.length > 0
              ? cancelNotes?.otherNotes
              : cancelNotes?.cancelingReason}{" "}
            <InfoCircleOutlined
              onClick={(e) => {
                e.stopPropagation();
                setCancelNotesObj(cancelNotes);
                setCancelNotesModel(true);
              }}
            />
          </div>
        ) : (
          "-"
        ),
      sorter: (a, b) =>
        a?.cancelNotes?.toString()?.localeCompare(b?.cancelNotes?.toString()),
      width: 300,
    },
    {
      title: "Plan",
      className: "text-center",
      dataIndex: "subscriptionPlanName",
      render: (subscriptionPlanName, record) => {
        const x = subscriptionPlanName
          ? capitalizeString(subscriptionPlanName)?.split(/(?=[A-Z])/)?.[0]
          : "-";
        return subscriptionPlanName ? (
          <>
            {x} ({record?.billingMethod?.charAt(0)?.toUpperCase()})
          </>
        ) : (
          <>-</>
        );
      },
      sorter: (a, b) =>
        a?.subscriptionPlanName
          ?.toString()
          ?.localeCompare(b?.subscriptionPlanName?.toString()),
      width: 150,
    },
    {
      title: "Storage Used",
      className: "text-center",
      dataIndex: "storageUsed",
      render: (v) => <>{v || "-"}</>,
      sorter: (a, b) => {
        const convertToMB = (value) => {
          if (value.includes("GB")) {
            return parseFloat(value) * 1024 * 1024; // Convert GB to MB
          }
          if (value.includes("MB")) {
            return parseFloat(value) * 1024; // Convert MB to KB
          }
          if (value.includes("KB")) {
            return parseFloat(value); // Already in KB
          }
          return 0; // Default to 0 if format is unexpected
        };
        const aStorage = convertToMB(a.storageUsed);
        const bStorage = convertToMB(b.storageUsed);
        return aStorage - bStorage;
      },
      width: 150,
    },
    {
      title: "Source",
      dataIndex: "source",
      className: "text-center",
      render: (v, record, ind) => (
        <Select
          suffixIcon={<>{SelectArrowNew}</>}
          size="large"
          className="select-phase-2 rightborder"
          style={{ width: "100%" }}
          value={v}
          placeholder="Assign Source"
          onChange={(value) => {
            handleHrmsUserChanges(
              "source",
              null,
              value,
              record?._id,
              ind,
              false,
            );
          }}
          dropdownRender={(menu) =>
            !addSourceModel && (
              <>
                {menu}

                <div className="addbtn-source">
                  <Button
                    type="link "
                    onClick={() => {
                      setAddSourceModel(true);
                      setSourceError({});
                      setForm(sourceInitialValue);
                    }}
                  >
                    Add Source
                  </Button>
                </div>
              </>
            )
          }
          options={
            !addSourceModel &&
            sourceList.map((item) => ({
              label: item?.name,
              value: item?._id,
            }))
          }
          onClick={(e) => e.stopPropagation()}
        />
      ),
      sorter: (a, b) => (a?.source || "")?.localeCompare(b?.source || ""),
      width: 200,
    },
    {
      title: "Hot/Cold",
      className: "text-center",
      dataIndex: "sourceType",
      render: (v) => (
        <span
          style={{
            color: v === "Hot" ? "#E42626" : v === "Cold" ? "#51C1C2" : "black",
          }}
        >
          {v || "-"}
        </span>
      ),
      sorter: (a, b) =>
        a?.sourceType?.toString()?.localeCompare(b?.sourceType?.toString()),
      width: 150,
    },
    {
      title: "Affiliate",
      className: "text-center",
      dataIndex: "affiliate",
      render: (v, record, ind) => (
        <Select
          suffixIcon={<>{SelectArrowNew}</>}
          size="large"
          className="select-phase-2 rightborder"
          style={{ width: "100%" }}
          value={record?.isNoneAffiliate ? "none" : v}
          onBlur={() => setAffiliateSearch(affiliateList)}
          placeholder="Select Affiliate"
          onChange={(value) => {
            handleHrmsUserChanges(
              "affiliate",
              "isNoneAffiliate",
              value,
              record?._id,
              ind,
              value === "none",
            );
          }}
          {...{
            ...(v && {
              dropdownStyle: {
                display: "none",
              },
            }),
          }}
          dropdownRender={(menu) =>
            !addAffiliateModel && (
              <>
                <div style={{ padding: "8px" }}>
                  <Input
                    size="large"
                    type="text"
                    placeholder="Search"
                    style={{
                      width: "100%",
                      marginBottom: "8px",
                      borderColor: "#D0D5DD",
                      borderRadius: "8px",
                    }}
                    onChange={changeAffiliateSearch}
                    prefix={<SearchOutlined />}
                  />
                </div>
                {menu}
              </>
            )
          }
          options={[
            ...affiliateSearch?.map((item) => ({
              label: item?.name,
              value: item?._id,
            })),
          ]}
          onClick={(e) => e.stopPropagation()}
        />
      ),
      sorter: (a, b) =>
        (a?.isNoneAffiliate
          ? "none"
          : a?.affiliate
          ? a?.affiliate
          : ""
        )?.localeCompare(
          b?.isNoneAffiliate ? "none" : b?.affiliate ? b?.affiliate : "",
        ),
      width: 300,
    },
    {
      title: "Promo Code",
      dataIndex: "promoCode",
      render: (v) => <>{v || "-"}</>,
      sorter: (a, b) => a.promoCode.localeCompare(b.promoCode),
      width: 150,
      className: "text-center",
    },
    {
      title: "Promo Code Name",
      dataIndex: "promoCodeName",
      render: (v) => <>{v || "-"}</>,
      sorter: (a, b) => a.promoCodeName.localeCompare(b.promoCodeName),
      width: 300,
      className: "text-center",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      render: (v) => <>{v ? `-$${v}` : "-"}</>,
      sorter: (a, b) => a.discount.localeCompare(b.discount),
      width: 150,
      className: "text-center",
    },
    {
      title: "Last Payment Date",
      className: "text-center",
      dataIndex: "lastPaymentDate",
      render: (v) => <>{formatDate(v) || "-"}</>,
      sorter: (a, b) =>
        new Date(a.lastPaymentDate) - new Date(b.lastPaymentDate),
      width: 200,
    },
    {
      title: "Last Payment",
      className: "text-center",
      dataIndex: "amount",
      render: (v) => (v ? <>${v}</> : "-"),
      sorter: (a, b) => a.amount - b.amount,
      width: 150,
    },
    {
      title: "LTV",
      className: "text-center",
      dataIndex: "LTV",
      render: (v) => (v ? <>${v?.toFixed(2)}</> : "$0.00"),
      sorter: (a, b) => a.LTV - b.LTV,
      width: 150,
    },
    {
      title: "Billing",
      className: "text-center",
      dataIndex: "billing",
      render: (v, record) => (
        <Button
          size="large"
          type="primary"
          style={{ width: "100%", borderRadius: "8px" }}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedUser(record);
            setDetailActiveTab(2);
            setShowUserModel(true);
          }}
          disabled={v === 0}
        >
          {v || 0} invoices
        </Button>
      ),
      sorter: (a, b) => a?.billing - b?.billing,
      width: 150,
    },
  ];

  useEffect(() => {
    getUserListData(setOrgLoader);
  }, [currentUserListPage, search, sortOrder, sortFields]);

  const getHrmsUserList = async () => {
    setLoader(true);

    const result = await getHrmsUserListAction();
    if (result?.remote === "success") {
      setHrmsUserList(result?.data?.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const getSourceList = async () => {
    setLoader(true);

    const result = await getSourceListAction();
    if (result?.remote === "success") {
      setSourceList(result?.data?.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  const getAffiliateList = async () => {
    setLoader(true);

    const result = await getAffiliateListAction();
    if (result?.remote === "success") {
      setAffiliateList(result?.data?.data);
      setAffiliateSearch(result?.data?.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    getHrmsUserList();
    getSourceList();
    getAffiliateList();
  }, []);

  const handleTabNameChange = (e) => {
    setTabName(e.target.value);
    if (e.target.value?.length > 0) {
      setTabNameError("");
    }
  };

  const handleTabNameSubmit = () => {
    if (tabName?.length > 0) {
      setUserListTabsData([...userListTabsData, { label: tabName }]);
      setTabName("");
      setIsInputMode(false);
    } else {
      setTabNameError("Tab Name required");
    }
  };

  const handleRowClick = (row) => ({
    onClick: (e) => {
      e.stopPropagation();
      setShowUserModel(true);
      setSelectedUser(row);
    },
  });

  const generateCsvData = (item) => {
    const csvData = {
      "Company Name": item?.companyName || "",
      "Account #": item?.accountNumber || "",
      "MA Name": item?.maName || "",
      "MA Email": item?.maEmail || "",
      "MA Number": modifyPhoneNumber(item?.maNumber) || "",
      "Signed Up": formatDate(item?.signUpDate) || "",
      "Who Scheduled": `"${
        item?.whoScheduled?.map((v) => v?.name).join(", ") || ""
      }"`,
      "Who Did Demo": `"${
        item?.whoDidDemo?.map((v) => v?.name).join(", ") || ""
      }"`,
      "Who Closed": `"${
        item?.whoClosed?.map((v) => v?.name).join(", ") || ""
      }"`,
      "Who's Maintaining": `"${
        item?.whoMaintaining?.map((v) => v?.name).join(", ") || ""
      }"`,
      "# Of Employees": item?.userCount || "",
      "Last Sign In": formatDate(item?.lastSignIn) || "",
      "Leads Added": item?.leadAdded || "",
      "Leads Converted": item?.leadConverted || "",
      Status: item?.status || "",
      "Issue Date": formatDate(item?.issueDate) || "",
      "Canceled Notes":
        item?.cancelNotes?.otherNotes?.length > 0
          ? item?.cancelNotes?.otherNotes
          : item?.cancelNotes?.cancelingReason || "",
      Plan: item?.subscriptionPlanName
        ? `${
            item?.subscriptionPlanName?.charAt(0)?.toUpperCase() +
            item?.subscriptionPlanName?.slice(1)
          } (${item?.billingMethod?.charAt(0)?.toUpperCase()})`
        : "",
      "Storage Used": item?.storageUsed || "",
      Source: item?.source || "",
      "Hot/Cold": item?.sourceType || "",
      Affiliate: item?.affiliate || "",
      "Last Payment Date": formatDate(item?.lastPaymentDate) || "",
      "Last Payment": item?.amount ? `$${item?.amount}` : "",
      LTV: `$${item?.LTV?.toFixed(2)}` || "",
      Billing: item?.billing || "",
    };
    return csvData;
  };

  const handleExport = async () => {
    setLoader(true);
    const payload = {
      page: 1,
      perPage: totalCount,
    };
    const result = await getUserListDataAction(payload);
    if (result?.remote === "success") {
      const csvDataList = [];
      result?.data?.data[0]?.data?.forEach((item) => {
        csvDataList.push(generateCsvData(item));
      });
      const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
        convertJSONToCSV(csvDataList),
      )}`;
      const link = document.createElement("a");
      link.href = csvContent;
      link.download = "User_List";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handleDivClick = () => {
    if (value && outside) {
      setValue(null);
      setOpen(false);
    } else if (!value && outside) {
      setOpen(false);
    }
  };
  const handleTableChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    setSortFields(field);
    setSortOrder(order);
  };

  const Loader = () => (
    <div className="center-loader">
      <SmallLoader />
    </div>
  );

  return (
    <div className="mt-3" onClick={handleDivClick}>
      {loader && <Loader />}
      <Card bordered={false} className="userCard">
        <div className="fillter-section mt-20 site-space-compact-wrapper">
          <UserListFilter
            showModal={showModal}
            columnSetting={columnSetting}
            setShowColumnModel={setShowColumnModel}
            search={search}
            setSearch={setSearch}
            handleExport={handleExport}
          />
        </div>
      </Card>
      <Card
        bordered={false}
        className="mt-20 userCard"
        style={{
          boxShadow:
            "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 2px 3px 0px rgba(16, 24, 40, 0.10)",
        }}
        bodyStyle={{ padding: "0px" }}
      >
        <div className="tablecard">
          <TableData
            scroll={{
              x: 1500,
              y: "calc(100vh - 410px)",
            }}
            columns={userListColumns}
            dataSource={userListData[0]?.data}
            currentPage={currentUserListPage}
            setCurrentPage={setCurrentUserListPage}
            perPage={10}
            paginationData={userListData[0]?.pagination[0]}
            onRow={handleRowClick}
            onChange={handleTableChange}
            loading={orgLoader && !loader}
            Loader={Loader}
          />
        </div>
      </Card>
      <ModalDailog
        width={AdvanceFilter ? "70%" : "520px"}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={false}
      >
        <FilterContentList
          AdvanceFilterShow={AdvanceFilterShow}
          AdvanceFilter={AdvanceFilter}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      </ModalDailog>

      <ModalDailog
        bodyStyle={{ padding: "0px" }}
        isModalOpen={isInputMode}
        handleCancel={handleTabNameCancel}
        title={false}
      >
        <AddTab
          handleCancel={handleTabNameCancel}
          handleTabNameChange={handleTabNameChange}
          tabName={tabName}
          handleTabNameSubmit={handleTabNameSubmit}
          tabNameError={tabNameError}
        />
      </ModalDailog>
      <ModalDailog
        bodyStyle={{ padding: "0px" }}
        isModalOpen={showColumnModel}
        handleCancel={() => setShowColumnModel(false)}
        title={false}
      >
        <ColumnSetting />
      </ModalDailog>
      <ModalDailog
        isModalOpen={showUserModel}
        className="userlist-modal"
        handleCancel={() => {
          setOpen(false);
          // TODO: Don't remove this time-out as its closing the calender and pop-up
          setTimeout(() => {
            setShowUserModel(false);
            setDetailActiveTab(1);
          }, 100);
        }}
        width={1300}
        title={false}
      >
        <Details
          selectedUser={selectedUser}
          isActiveTab={detailActiveTab}
          setIsActiveTab={setDetailActiveTab}
          setOpen={setOpen}
          open={open}
          setOutside={setOutside}
          setValue={setValue}
          value={value}
        />
      </ModalDailog>
      <ModalDailog
        bodyStyle={{ padding: "0px" }}
        isModalOpen={cancelNotesModel}
        handleCancel={() => setCancelNotesModel(false)}
        title={false}
        closable={false}
        width={399}
      >
        <CancelNotes
          cancelNotesObj={cancelNotesObj}
          handleCancel={() => setCancelNotesModel(false)}
        />
      </ModalDailog>
      <ModalDailog
        bodyStyle={{ padding: "0px" }}
        isModalOpen={addSourceModel}
        handleCancel={() => setAddSourceModel(false)}
        title={false}
        closable={false}
        width={320}
      >
        <AddSource
          cancelNotesObj={cancelNotesObj}
          handleCancel={() => {
            setAddSourceModel(false);
            setForm(sourceInitialValue);
            setSourceError({});
          }}
          form={form}
          setForm={setForm}
          sourceError={sourceError}
          setSourceError={setSourceError}
          setAddSourceModel={setAddSourceModel}
          getSourceList={getSourceList}
        />
      </ModalDailog>
      <ModalDailog
        bodyStyle={{ padding: "0px" }}
        isModalOpen={addAffiliateModel}
        handleCancel={() => setAddAffiliateModel(false)}
        title={false}
        closable={false}
        width={320}
      >
        <AddAffiliate
          cancelNotesObj={cancelNotesObj}
          handleCancel={() => {
            setAddAffiliateModel(false);
            setForm(sourceInitialValue);
            setSourceError({});
          }}
          form={form}
          setForm={setForm}
          sourceError={sourceError}
          setSourceError={setSourceError}
          setAddAffiliateModel={setAddAffiliateModel}
          getAffiliateList={getAffiliateList}
        />
      </ModalDailog>
    </div>
  );
};
export default UserListV2;
