import { formatPhoneNumber } from "react-phone-number-input";

export const mentions = [
  { text: "Customer", value: "{{CUSTOMER_NAME}}", field: "customerName" },
  { text: "Company", value: "{{COMPANY_NAME}}", field: "companyName" },
  { text: "Address", value: "{{ADDRESS}}", field: "address" },
  {
    text: "UserNamePhoneNumber",
    value: "{{USER_NAME_PHONE_NUMBER}}",
    field: "phoneNumber",
  },
  {
    text: "CompanyPhoneNumber",
    value: "{{COMPANY_PHONE_NUMBER}}",
    field: "CompanyPhoneNumber",
  },
  {
    text: "Customer Address",
    value: "{{CUSTOMER_ADDRESS}}",
    field: "customerAddress",
  },
  { text: "UserName", value: "{{USER_NAME}}", field: "currentUserName" },
  {
    text: "ClickHereToSeeTheFullProjectSummary",
    value: "{{ClickHereToSeeTheFullProjectSummary}}",
    field: "ClickHereToSeeTheFullProjectSummary",
  },
];

export const templateMentions = [
  { text: "Customer Name", value: "{{CUSTOMER_NAME}}", field: "customerName" },
  { text: "Company Name", value: "{{COMPANY_NAME}}", field: "companyName" },
];
export const projectSummaryMentions = [
  { text: "Customer Name", value: "{{CUSTOMER_NAME}}", field: "customerName" },
  { text: "Customer Name", value: "{{CUSTOMERNAME}}", field: "customerName" },
  { text: "Customer Address", value: "{{ADDRESS}}", field: "customerAddress" },
  { text: "Customer Address", value: "{{PHONENO}}", field: "customerAddress" },
  {
    text: "Customer Number",
    value: "{{USER_NAME_PHONE_NUMBER}}",
    field: "customerNumber",
  },
  {
    text: "Customer Address",
    value: "{{CUSTOMER_ADDRESS}}",
    field: "customerAddress",
  },
  { text: "User Name", value: "{{USER_NAME}}", field: "userName" },
  {
    text: "Organization Name",
    value: "{{COMPANY_NAME}}",
    field: "organizationName",
  },
  {
    text: "Organization Number",
    value: "{{COMPANY_PHONE_NUMBER}}",
    field: "organizationNumber",
  },
  {
    text: "Organization Address",
    value: "{{COMPANY_ADDRESS}}",
    field: "organizationAddress",
  },
  { text: "Billing Email", value: "{{BILLING_EMAIL}}", field: "billingEmail" },
  {
    text: "Billing Phone Number",
    value: "{{BILLING_PHONE}}",
    field: "billingPhoneNumber",
  },
];

export const SMSTemapateMention = [
  { text: "Customer Email", value: "$customerEmail", field: "customerEmail" },
  { text: "Customer Name", value: "$customerName", field: "customerName" },
  {
    text: "Customer Address",
    value: "$customerAddress",
    field: "customerAddress",
  },
  { text: "day", value: "$day", field: "day" },
];

export function replacePatterns(input, customerDetails, organizationDetails) {
  return input
    .replace(/<\/?a[^>]*>/g, "")
    .replaceAll("@{{", "{{")
    .replace(
      /{{USER_NAME_PHONE_NUMBER}}/g,
      formatPhoneNumber(
        organizationDetails?.phoneNumber
          ? `+${organizationDetails?.phoneNumber}`
          : "",
      ),
    )
    .replace(
      /{{COMPANY_PHONE_NUMBER}}/g,
      formatPhoneNumber(
        customerDetails?.contactNo ? `+${customerDetails?.contactNo}` : "",
      ),
    );
}
