import React, { createContext, useCallback, useContext, useState } from "react";

import api from "../../api/api";
import { GET_ORGANIZATION_DETAILS } from "../constants/api-urls";
import { LoaderContext } from ".";

export const OrganizationContext = createContext({});

export const OrganizationProvider = ({ children }) => {
  const [organization, setOrgaization] = useState(null);
  const { setLoading } = useContext(LoaderContext);

  const getOrganizationDetails = async () => {
    try {
      setLoading(true);
      const result = await api.request({
        url: GET_ORGANIZATION_DETAILS,
        METHOD: "GET",
      });
      if (result?.remote === "success") {
        setOrgaization(result.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const value = {
    organization,
    getOrganizationDetails: useCallback(() => {
      getOrganizationDetails();
    }, []),
  };

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  );
};
