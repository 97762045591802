import "../docNpay.css";

import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getReportedListAction } from "../../../../../redux/affiliate/affiliate.action";
import SmallLoader from "../../../../loader/smallLoader";
import { docNPayContainer } from "../container";
import AfterUpload from "./AfterUpload";
import InitialUpload from "./InitialUpload";
import {
  ConfirmCardModal,
  PastReportsModal,
  PaymentModalPopup,
  PayoneerModelPopup,
  PaypalModalPopup,
  ReportProblemModal,
  UploadFormCardModal,
  UploadIdCardModal,
  VenmoModalPopup,
} from "./modals";

const DocNPay = ({ isSuperAdmin = false }) => {
  const {
    errors,
    loading,
    formData,
    formModal,
    toggleModal,
    documentData,
    confirmModal,
    download,
    setErrors,
    setLoading,
    setFormData,
    setFormModal,
    handleDeleteId,
    setToggleModal,
    setConfirmModal,
    setIsCardDeleted,
    setIsFormDeleted,
  } = docNPayContainer(isSuperAdmin);
  const rejected = documentData?.status === "Rejected";
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [reportToggle, setReportToggle] = useState();

  const [pastReports, setPastReports] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const affiliateId = useSelector((state) => state?.affiliate?.profile?._id);
  const reportList = useSelector(
    (state) => state?.affiliate?.docNPay?.reported,
  );

  const handleReportClose = () => {
    setReportToggle({ toggle: !reportToggle?.toggle, type: null });
    form.resetFields();
  };

  const handlePastReportClose = () => {
    setPastReports({ toggle: !pastReports?.toggle, type: null });
  };

  const callReportList = (type = "") => {
    if (isSuperAdmin && affiliateId && !type) {
      ["idCard", "w9Form"].forEach((type) => {
        dispatch(getReportedListAction(affiliateId, type));
      });
    }
    if (type) dispatch(getReportedListAction(affiliateId, type));
  };

  useEffect(() => {
    callReportList();
  }, []);
  return (
    <>
      {loading ? (
        <div className="center-loader">
          <SmallLoader />
        </div>
      ) : (
        <>
          {!Object.keys(documentData)?.length ||
          (documentData?.status === "Registered" && !isSuperAdmin) ? (
            <InitialUpload
              documentData={documentData}
              handleDeleteId={handleDeleteId}
              download={download}
              setFormModal={setFormModal}
            />
          ) : (
            <AfterUpload
              documentData={documentData}
              isSuperAdmin={isSuperAdmin}
              reportList={reportList}
              download={download}
              setFormModal={setFormModal}
              handleDeleteId={handleDeleteId}
              setReportToggle={setReportToggle}
              setPastReports={setPastReports}
              rejected={rejected}
            />
          )}
          <UploadIdCardModal
            setFormModal={setFormModal}
            formModal={formModal}
            formData={formData}
            setFormData={setFormData}
            setConfirmModal={setConfirmModal}
          />
          <ConfirmCardModal
            confirmModal={confirmModal}
            setConfirmModal={setConfirmModal}
            formData={formData}
            setFormModal={setFormModal}
            setLoader={setLoading}
            setIsCardDeleted={setIsCardDeleted}
          />
          <UploadFormCardModal
            formData={formData}
            setFormData={setFormData}
            formModal={formModal}
            setFormModal={setFormModal}
            setLoader={setLoading}
            setIsFormDeleted={setIsFormDeleted}
          />
          <PaymentModalPopup
            paymentModal={formModal?.paymentModal}
            setPaymentModal={setFormModal}
            toggleModal={toggleModal}
            setToggleModal={setToggleModal}
            setLoader={setLoading}
          />
          <PaypalModalPopup
            paypalToggle={toggleModal?.paypal?.toggle}
            setPaymentModal={setFormModal}
            setLoader={setLoading}
            formData={toggleModal?.paypal}
            setToggleModal={setToggleModal}
            errors={errors}
            setErrors={setErrors}
          />
          <VenmoModalPopup
            venmoToggle={toggleModal?.venmo?.toggle}
            setPaymentModal={setFormModal}
            setToggleModal={setToggleModal}
            setLoader={setLoading}
            errors={errors}
            setErrors={setErrors}
            formData={toggleModal?.venmo}
          />
          <PayoneerModelPopup
            payoToggle={toggleModal?.payo?.toggle}
            setToggleModal={setToggleModal}
            setPaymentModal={setFormModal}
            setLoader={setLoading}
            formData={toggleModal?.payo}
            setPayoData={setToggleModal}
            errors={errors}
            setErrors={setErrors}
          />
          <ReportProblemModal
            form={form}
            reportToggle={reportToggle}
            handleReportClose={handleReportClose}
            callReportList={callReportList}
            rejected={rejected}
          />
          <PastReportsModal
            pastReports={pastReports}
            setCurrentPage={setCurrentPage}
            handlePastReportClose={handlePastReportClose}
            currentPage={currentPage}
            rejected={rejected}
          />
        </>
      )}
    </>
  );
};

export default DocNPay;
