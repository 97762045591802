import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";

import ReactMentionInput from "../../../../../utils/mentionInput/mentionInput";

function CustomInput({ customIndex, handleChange, custom, onFocusOut, idx }) {
  const [value, setValue] = useState(0);
  useEffect(() => {
    setValue(custom?.value || 0);
  }, []);
  const onBlur = () => {
    handleChange(value, "", idx, null, customIndex);
    onFocusOut();
  };
  return (
    <Row gutter={[8, 0]} className="align-items-center mb-3 " key={customIndex}>
      <Col md={8}>
        <label>{custom.name}</label>
      </Col>
      <Col md={16}>
        <ReactMentionInput
          className="ant-furmulla-input"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          isCustomNameInput
          value={value}
          onBlur={onBlur}
        />
      </Col>
    </Row>
  );
}

export default CustomInput;
