import { Button } from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";
const MyButton = styled(Button)({
  background: "#3483FA !important",
  border: 0,
  borderRadius: "8px !important",
  boxShadow: "none",
  color: "#fff !important",
  height: "39px ! important",

  textTransform: "capitalize !important",
  "&:hover": {
    background: "#3483FA !important",
  },
});
const CustomButton = (props) => (
  <>
    <MyButton {...props}>{props.children}</MyButton>
  </>
);
export default CustomButton;
