import { Select } from "antd";
import React from "react";

const { Option } = Select;

const StatusDropdown = ({ val, record, onChange, index }) => {
  const statusOption = [
    { label: "Pending", value: "Pending", color: "#FFB800" },
    {
      label: "Active",
      value: "Approved",
      color: "#14A88D",
      dependentValue: ["Suspend", "FreeUser"],
    },
    {
      label: "Rejected",
      value: "Suspend",
      color: "#E42626",
      dependentValue: ["Approved"],
    },
    {
      label: "Card Declined",
      value: "CardDeclined",
      color: "#EF5DA8",
    },
    {
      label: "Active Trial User",
      value: "ActiveTrial",
      color: "#FF9045",
    },
    {
      label: "Canceled on Trial",
      value: "CancelOnTrial",
      color: "#E42626",
    },
    { label: "Canceled", value: "Canceled", color: "#D34141" },
    {
      label: "Free User",
      value: "FreeUser",
      color: "#9C26E4",
      dependentValue: ["Approved"],
    },
  ];
  const filteredData = statusOption?.filter((obj) =>
    obj?.dependentValue?.includes(val),
  );

  const handleStatusChange = (value, v, record) => {
    onChange(value, v, record, index);
  };
  return (
    <>
      <Select
        className="select-phase-2"
        size="large"
        maxTagCount={2}
        onClick={(e) => e.stopPropagation()}
        onChange={(value) => handleStatusChange(value, val, record)}
        value={val}
        style={{
          width: 200,
          color: statusOption?.find((obj) => obj?.value === val)?.color,
        }}
        {...{
          ...(!filteredData?.length && {
            dropdownStyle: {
              display: "none",
            },
          }),
        }}
      >
        {statusOption.map((obj, ind) => (
          <Option
            value={obj?.value}
            style={{
              color: obj?.color,
              ...((!obj?.dependentValue?.length || obj?.value === val) && {
                display: "none",
              }),
            }}
            key={`status-opt-${ind}`}
          >
            {obj?.label}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default StatusDropdown;
