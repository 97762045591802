import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export const withRouter = (Component) => {
  function Wrapper(props) {
    const userGroupListFromStore = useSelector(
      (state) => state?.usersList?.usersGroupList,
    );

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {}, [
      userGroupListFromStore?.internal?.count,
      userGroupListFromStore?.office?.count,
      userGroupListFromStore?.project?.count,
      userGroupListFromStore?.follow_up?.count,
      dispatch,
      userGroupListFromStore,
    ]);

    return <Component location={location} {...props} />;
  }

  return Wrapper;
};
