import { UploadOutlined } from "@ant-design/icons";
import { Container } from "@material-ui/core";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Layout,
  message,
  Modal,
  Row,
} from "antd";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { postPaymentRequest, updateProfile } from "../../../api/affiliate";
import { LoaderContext } from "../../../common/contexts";
import { useLoader } from "../../../common/hooks";
import logo from "../../../images/logo-signup.png";
import {
  alertMessageType,
  getAffiliatePaymentDataAction,
  getProfileAction,
  getSummaryDetailsAction,
  profileFormChangeAction,
  resetInputErrors,
  setInputErrors,
  showBannerAction,
} from "../../../redux/affiliate/affiliate.action";
import { AFFILIATE_TYPES } from "../../../redux/affiliate/affiliate.types";
import { removeFormat } from "../../../utils/javascript";
import {
  documentIcon,
  linkCodesIcon,
  logoutIconA,
  MenuIcon,
  myPaymentIcon,
  paymentIcon,
  ProfileIcon,
  referralIcon,
  rulesIcon,
} from "../../../utils/svg.file";
import SmallLoader from "../../loader/smallLoader";
import AlertBanner from "../common/AlertBanner";
import DocNPay from "./profileTabs/docNPay/index";
import LinkNCode from "./profileTabs/LinkNCode";
import { PayoutModal } from "./profileTabs/modals/ReferralModals";
import { selectAlertMessage } from "./profileTabs/modals/utils";
import Payments from "./profileTabs/Payments";
import ProfileInfo from "./profileTabs/ProfileInfo";
import Referrals from "./profileTabs/referrals/index";
import Rules from "./profileTabs/Rules";

const { Content } = Layout;

const items = [
  {
    key: "profile",
    label: "My Profile",
    children: <ProfileInfo />,
    checkCondition: false,
    icon: ProfileIcon,
  },
  {
    key: "docNPay",
    label: "Documentation & Payment",
    children: <DocNPay />,
    checkCondition: false,
    icon: documentIcon,
  },
  {
    key: "referrals",
    label: "My Referrals",
    children: <Referrals />,
    icon: referralIcon,
  },
  {
    key: "payments",
    label: "My Payments",
    children: <Payments />,
    icon: paymentIcon,
  },
  {
    key: "linkNCode",
    label: "Links/ Codes",
    children: <LinkNCode />,
    icon: linkCodesIcon,
  },
  {
    key: "rules",
    label: "Rules",
    children: <Rules />,
    icon: rulesIcon,
  },
];
const Profile = () => {
  const [isActiveTab, setIsActiveTab] = useState();
  const [activeTabName, setActiveTabName] = useState();
  const [openPayout, setOpenPayout] = useState(false);
  const [value, setValue] = useState();
  const [form] = Form.useForm();
  const { setLoading } = useContext(LoaderContext);
  const { loading } = useLoader();
  const { isChanged, formData, profile, bannerType, showBanner } = useSelector(
    (state) => state?.affiliate,
  );
  const [open, setOpen] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 992px)").matches,
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 992px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  // const redirectTo = new URL(window.location.href);
  const summaryData = useSelector((state) => state?.affiliate?.summary);
  const formErrors = useSelector((state) => state.affiliate.error?.formErrors);
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const configs = {
    title: "",
    className: "modal-pop-up payment-request-modal-popup",
    centered: true,
    content: (
      <div>
        <h5 style={{ fontSize: "18px" }}>Payment Request Successful</h5>
        <span>
          <p style={{ textAlign: "justify" }}>
            Your request has been processed. This process can take 24 to 72
            hours to be reflected in the selected payment method. You can view
            the status of your request in the &ldquo;My Payments&rdquo; tab.
          </p>
        </span>
      </div>
    ),
    okText: "Confirm",
    onOk() {
      dispatch(getSummaryDetailsAction());
      handlePayoutModalVisibility();
    },
  };
  const handleSubmit = async () => {
    const data = {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      contactNumber: removeFormat(
        formData?.contactNumber || formData?.contactNo,
      ),
      address: formData?.address?.label || formData?.address,
      socialMediaLink: formData?.socialMediaLink,
    };
    const response = await updateProfile(data);
    if (response.remote === "success") {
      dispatch(showBannerAction(true));
      dispatch(alertMessageType("profile"));
    } else {
      dispatch(showBannerAction(false));
    }
    dispatch(profileFormChangeAction(false));
  };
  const handleLogOutClick = () => {
    localStorage.removeItem("affiliateUserId");
    localStorage.removeItem("affiliateToken");
    dispatch({
      type: AFFILIATE_TYPES.AUTH_LOGOUT,
    });
    history("/affiliate");
  };

  const handlePayoutModalVisibility = () => {
    setOpenPayout(!openPayout);
    dispatch(resetInputErrors("payout-request"));
    form.resetFields();
  };
  const handleChange = (e) => {
    const modifiedValue = e.target.value.toString().replace(/\B\D/g, "");
    setValue(`$${modifiedValue}`);
    if (
      +modifiedValue >=
      summaryData?.myBalance - summaryData?.totalRequestedAmount
    ) {
      dispatch(
        setInputErrors({
          [e.target.name]: "You don't have enough balance to withdraw",
        }),
      );
    } else {
      dispatch(resetInputErrors("payout-request"));
    }
  };
  const handleConfirmPayout = async () => {
    if (!Object.keys(formErrors || {})?.length) {
      const response = await postPaymentRequest({
        amount: form?.getFieldValue("payout-request"),
      });
      if (response.remote === "success") {
        handlePayoutModalVisibility();
        setValue("");
        dispatch(resetInputErrors("payout-request"));
        Modal.info(configs);
        form.resetFields();
      } else {
        console.log("response", response);
        dispatch(
          setInputErrors({
            "payout-request": response?.error?.errors?.error,
          }),
        );
      }
    }
  };
  useEffect(() => {
    const initial = items[0];
    setIsActiveTab(initial.key);
    setActiveTabName(initial.label);
  }, []);

  useEffect(() => {
    dispatch(getProfileAction(setLoading));
  }, []);

  useEffect(() => {
    if (showBanner) {
      setTimeout(() => {
        dispatch(showBannerAction(false));
      }, 2000);
    }
  }, [showBanner]);

  useEffect(() => {
    if (profile?.status && profile?.status === "Approved") {
      setIsActiveTab("referrals");
      setActiveTabName(items[2]?.label);
    } else {
      const label = items.find((obj) => obj.key === location.state?.to)?.label;
      setIsActiveTab(location.state?.to || "profile");
      setActiveTabName(label || items[0]?.label);
    }

    if (profile?.status && profile?.status === "Rejected") {
      handleLogOutClick();
      message.error("Your account has been rejected");
    }
  }, [profile]);

  useEffect(() => {
    if (isActiveTab === "payments") {
      dispatch(getAffiliatePaymentDataAction(false, profile?._id));
    }
  }, [openPayout]);

  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "100vh",
      }}
    >
      <SmallLoader />
    </div>
  );

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Container>
        {showBanner && (
          <AlertBanner
            {...selectAlertMessage[bannerType || "profile"]}
            closable
            onClose={() => dispatch(showBannerAction(false))}
          />
        )}
        <Content>
          <img
            src={logo}
            alt="ZevBit-logo"
            style={{ width: "127.48px", height: "30px", marginTop: "30px" }}
          />
          <Row className="rowFirst mt-5">
            <Col span={16}>
              <h5 className="d-flex align-items-center gap-3">
                <span onClick={showDrawer} className="d-inline-block d-lg-none">
                  {MenuIcon}
                </span>
                <span className="flex">{activeTabName}</span>
              </h5>
            </Col>
            <Col span={8} className="text-right">
              {isActiveTab === "profile" && (
                <Button
                  type="primary"
                  onClick={() => handleSubmit()}
                  disabled={!isChanged}
                  style={{
                    backgroundImage: "none",
                    borderRadius: "8px",
                    color: "White",
                  }}
                >
                  Save Profile
                </Button>
              )}
              {isActiveTab === "payments" && (
                <Button
                  type="primary"
                  className="affiliate-button req-icon"
                  onClick={() => handlePayoutModalVisibility()}
                >
                  <span className="d-inline-block d-lg-none">
                    {myPaymentIcon}
                  </span>
                  <span className="d-none d-lg-block">Request Payout</span>
                </Button>
              )}
            </Col>
          </Row>
          <Row className="rowSecond d-none d-lg-flex">
            <Col xs={24} lg={20}>
              <div className="tabs my-3 profile-tabs">
                <ul>
                  {items.map(
                    ({ label, key, checkCondition = true, ...rest }) => (
                      <React.Fragment key={key}>
                        {(checkCondition
                          ? profile?.status === "Approved"
                          : true) && (
                          <li
                            key={key}
                            className={classNames({
                              active: isActiveTab === key,
                            })}
                            onClick={() => {
                              setIsActiveTab(key);
                              setActiveTabName(rest.additionalLabel || label);
                            }}
                          >
                            {label}
                          </li>
                        )}
                      </React.Fragment>
                    ),
                  )}
                </ul>
              </div>
            </Col>
            <Col xs={24} lg={4} className="text-right">
              <p
                className="text-danger"
                style={{ cursor: "pointer", alignItems: "center" }}
                onClick={handleLogOutClick}
              >
                <span>Log Out</span>
                <UploadOutlined
                  style={{
                    transform: "rotate(90deg)",
                    verticalAlign: "middle",
                    marginLeft: 5,
                    fontSize: 16,
                  }}
                />
              </p>
            </Col>
          </Row>
          <Divider style={{ marginTop: "0px" }} />
          {items.map(({ key, children }) => (
            <React.Fragment key={key}>
              {isActiveTab === key && children}
            </React.Fragment>
          ))}
        </Content>
      </Container>
      <PayoutModal
        isModalOpen={openPayout}
        handleCancel={handlePayoutModalVisibility}
        handleChange={handleChange}
        value={value}
        form={form}
        handleConfirmPayout={handleConfirmPayout}
        summaryData={summaryData}
      >
        <Row gutter={[24, 24]}>
          <Col span={12} style={{ height: 44 }}>
            <Button
              className="w-100 h-100"
              style={{ borderRadius: 8 }}
              onClick={() => handlePayoutModalVisibility()}
            >
              Cancel
            </Button>
          </Col>
          <Col span={12} style={{ height: 44 }}>
            <Button
              className="w-100 h-100 affiliate-button"
              type="primary"
              onClick={() => handleConfirmPayout()}
              htmlType="submit"
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </PayoutModal>
      {matches && (
        <Drawer
          placement="left"
          closable={false}
          onClose={onClose}
          visible={open}
          width={285}
          bodyStyle={{ padding: "15px" }}
          footer={[
            <>
              <p
                className="text-danger m-0"
                style={{ cursor: "pointer", alignItems: "center" }}
                onClick={handleLogOutClick}
              >
                {logoutIconA} <span className="ms-2">Log Out</span>
              </p>
            </>,
          ]}
        >
          <span onClick={onClose}>{MenuIcon}</span>
          <ul className="drawerMenu mt-3">
            {items.map(
              ({ icon, label, key, checkCondition = true, ...rest }) => (
                <React.Fragment key={key}>
                  {(checkCondition ? profile?.status === "Approved" : true) && (
                    <li
                      key={key}
                      className={classNames({
                        active: isActiveTab === key,
                      })}
                      onClick={() => {
                        setIsActiveTab(key);
                        setActiveTabName(rest.additionalLabel || label);
                        onClose();
                      }}
                    >
                      <span>{icon}</span> <span>{label}</span>
                    </li>
                  )}
                </React.Fragment>
              ),
            )}
          </ul>
        </Drawer>
      )}
    </div>
  );
};

export default Profile;
