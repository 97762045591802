import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Switch } from "antd";
import { useState } from "react";

import { plancheck } from "../../../../utils/svg.file";

function ReactiveSubscription({ handleCancel, handleChoosePlan, prevPlan }) {
  const [planType, setPlanType] = useState("monthly");
  const plandata = [
    {
      subtitle: "Estimating",
      prizeTitle: "$97",
      sub: ".99",
      text: "per month",
      content: "Advanced features and reporting.",
      btnText: "Start Now",
      subItems: "FEATURES",
      describe: "Everything in our Estimating plan....",
      children: [
        "CRM",
        "Instant Estimating",
        "Material Catalog",
        "Production Rates",
        "Labor Change Calculator",
        "Overhead Recovery System",
        "Market Catalog",
        "E-Sign Contracts",
        "24/7 US Based Support",
        "150+ Estimating Templates",
      ],
      isPrevPlan: "Reactivate Subscription",
      planName: "estimating",
      planType: "monthly",
    },
    {
      subtitle: "Essential",
      prizeTitle: "$197",
      sub: ".99",
      content: "Invoicing, Change Orders, Quickbooks Sync & MORE...",
      btnText: "Start Now",
      subItems: "FEATURES",
      describe: "Everything in Estimating plan plus....",
      children: [
        "All Invoicing Features",
        "All Change Order Features",
        "Auto Generate Invoices",
        "Send & Receive Credit Card/ACH Payments",
        "Quickbooks Sync & Payment Integration",
        "View & Send Project Summary’s",
        "Automatic Reminder Emails",
        "24/7 US Based Support",
      ],
      isPrevPlan: "Reactivate Subscription",
      planName: "essential",
      planType: "monthly",
    },
    {
      subtitle: "Estimating Yearly",
      prizeTitle: "$77",
      sub: ".99",
      text: "per month (Billed Annually)",
      content: "Advanced features and reporting.",
      btnText: "Start Now",
      subItems: "FEATURES",
      describe: "Everything in our Estimating plan....",
      children: [
        "CRM",
        "Instant Estimating",
        "Material Catalog",
        "Production Rates",
        "Labor Change Calculator",
        "Overhead Recovery System",
        "Market Catalog",
        "E-Sign Contracts",
        "24/7 US Based Support",
        "150+ Estimating Templates",
      ],
      planType: "annual",
      planName: "estimatingYearly",
      isPrevPlan: "Reactivate Subscription",
    },
    {
      subtitle: "Essential Yearly",
      prizeTitle: "$157",
      sub: ".99",
      text: "per month (Billed Annually)",
      content: "Invoicing, Change Orders, Quickbooks Sync & MORE...",
      btnText: "Start Now",
      subItems: "FEATURES",
      describe: "Everything in Estimating plan plus....",
      children: [
        "All Invoicing Features",
        "All Change Order Features",
        "Auto Generate Invoices",
        "Send & Receive Credit Card/ACH Payments",
        "Quickbooks Sync & Payment Integration",
        "View & Send Project Summary’s",
        "Automatic Reminder Emails",
        "24/7 US Based Support",
      ],
      planType: "annual",
      planName: "essentialYearly",
      isPrevPlan: "Reactivate Subscription",
    },
  ];

  return (
    <div className="userlist-details">
      <h3 className="fliter-sort-title d-flex align-items-center">
        <ArrowLeftOutlined onClick={handleCancel} />
        <span className="ms-2"> Reactivate Subscription</span>
      </h3>
      <div className="tab-content">
        <div className="text-center">
          <h5>Select A Plan That Fits Your Company</h5>
          <p> Simple, transparent pricing that grows with you.</p>
        </div>
        <div className="text-center mt-3 plan-title-content mb-5">
          <span style={{ marginRight: 15 }}>Monthly</span>
          <Switch
            onChange={(checked) => {
              setPlanType(checked ? "annual" : "monthly");
            }}
          />
          <span style={{ fontWeight: "bold", marginLeft: 15 }}>Annual - </span>
          <span style={{ fontWeight: "bold", color: "#3483FA" }}>
            You Save 20%
          </span>
        </div>
        <div className="tablecard">
          <Row gutter={[24, 24]} justify="center">
            {plandata
              ?.filter((obj) => obj?.planType === planType)
              .map((item, index) => (
                <Col xs={24} lg={9} md={9} key={index}>
                  <Card
                    style={{ borderRadius: "8px", height: "100%" }}
                    title={[
                      <div className="plan-title">
                        {item.subtitle}

                        <div className="title-prize">
                          <sup>{item.prizeTitle}</sup>
                          <sub>
                            {item.sub} <small>{item.text}</small>
                          </sub>
                        </div>
                        <p>{item.content}</p>
                        <Button
                          disabled={item.btndisabled}
                          onClick={() => {
                            handleChoosePlan(item?.planName);
                          }}
                          style={{ width: "100%", borderRadius: "8px" }}
                          size="large"
                          type="primary"
                          className={item.className}
                        >
                          {item?.planName === prevPlan
                            ? item.isPrevPlan
                            : item.btnText}
                        </Button>
                      </div>,
                    ]}
                  >
                    <div className="content-plan">
                      <h6>{item.subItems}</h6>
                      <p>{item.describe}</p>
                      <ul>
                        {item.children.map((subitems) => (
                          <li key={subitems}>
                            {plancheck}
                            <span className="ms-2">{subitems}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Card>
                </Col>
              ))}
          </Row>
        </div>
      </div>
    </div>
  );
}
export default ReactiveSubscription;
