import { Button, Col, Row } from "antd";
import classNames from "classnames";
import React from "react";

import pdfIcon from "../../../../../images/affiliateImages/PDF_file_icon 1.png";

const Preview = ({
  rowData,
  handleProofOfPaymentToggle,
  handleDownloadProof,
  file,
  extension,
}) => (
  <div className="add-promo-code cencelnotes">
    {console.log("file -->", extension)}
    <Row gutter={[24, 0]}>
      <Col
        xs={24}
        className={classNames(`conform-Id-image p-0`, {
          "d-flex": extension === "pdf",
          "justify-content-center": extension === "pdf",
        })}
      >
        <img
          id="imagePreview"
          src={extension === "pdf" ? pdfIcon : file}
          alt="Preview"
          {...{
            ...(extension !== "pdf" && {
              style: { boxShadow: "0 0 12px #f3f3f3" },
            }),
          }}
        />
      </Col>
      <Row className="upload-id-two-buttons w-100" justify="space-between">
        <Col className="mt-4" span={1} xs={11}>
          <Button
            className="affiliate-default-button"
            onClick={handleProofOfPaymentToggle}
            style={{ width: "100%", borderRadius: "8px" }}
            size="large"
          >
            Cancel
          </Button>
        </Col>
        <Col className="mt-4" span={1} xs={11}>
          <Button
            className="affiliate-button"
            onClick={() =>
              handleDownloadProof(
                rowData?.proofOfPayments,
                `${
                  rowData?.paymentMethod
                }-payment-proof${rowData?.proofOfPayments?.slice(
                  rowData?.proofOfPayments?.lastIndexOf("."),
                )}`,
              )
            }
            style={{ width: "100%", borderRadius: "8px" }}
            size="large"
            type="primary"
          >
            Download
          </Button>
        </Col>
      </Row>
    </Row>
  </div>
);

export default Preview;
