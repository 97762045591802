import { getGoogleClientId } from "../api/api-integration";

const googleClientId = async () => {
  if (localStorage.getItem("isCardDeclined") === "false") {
    const response = await getGoogleClientId();
    if (response.remote === "success") {
      return response.data.google.clientId;
    }
    return "";
  }
  return "";
};

export default googleClientId;
