import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../PromoCodePage.css";

import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
} from "antd";
import { EditorState } from "draft-js";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";

import { addPromoCode, getAffiliateListAction } from "../../../../api/admin";
import { plansForPromoCode } from "../../../../utils/constants/constants";
import { promoCodeSchema } from "../../../../validators/auth/auth.validator";
import SmallLoader from "../../../loader/smallLoader";

const promoCodeDuration = [
  {
    value: "1",
    label: "1 Month",
  },
  {
    value: "2",
    label: "2 Month",
  },
  {
    value: "3",
    label: "3 Month",
  },
  {
    value: "6",
    label: "6 Month",
  },
  {
    value: "12",
    label: "1 Year",
  },
  {
    value: "entireSubscription",
    label: "Entire Subscription",
  },
];

const trialDaysOption = [
  {
    value: "1",
    label: "1 Month",
  },
  {
    value: "2",
    label: "2 Months",
  },
  {
    value: "3",
    label: "3 Months",
  },
];

const mentions = [
  {
    text: "Discounted Price With Tax",
    value: "{DISCOUNT_PRICE_WITH_TAX}",
    field: "Discounted Price With Tax",
  },
  {
    text: "Discounted Price",
    value: "{DISCOUNT_PRICE}",
    field: "Discounted Price",
  },
  {
    text: "Plan Price",
    value: "{PLAN_PRICE}",
    field: "Plan Price",
  },
];

const PromoCode = ({
  handleCancel,
  setFormData,
  formData,
  errors,
  setErrors,
  getPromoCodeList,
}) => {
  const { TextArea } = Input;
  const { Option } = Select;
  const [loader, setLoader] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [affiliateList, setAffiliateList] = useState([]);
  const [affiliateSearch, setAffiliateSearch] = useState([]);
  const inputRef = useRef(null);
  const getAffiliateList = async () => {
    setLoader(true);
    const result = await getAffiliateListAction();
    if (result?.remote === "success") {
      setAffiliateList(result?.data?.data);
      setAffiliateSearch(result?.data?.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const changeAffiliateSearch = (e) => {
    const filteredOptions = affiliateList.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    setAffiliateSearch(filteredOptions);
  };

  useEffect(() => {
    getAffiliateList();
  }, []);

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const plainTextContent = newEditorState.getCurrentContent().getPlainText();
    setFormData({ ...formData, description: plainTextContent });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "discount") {
      if (!/^\d*(\.\d{1,2})?$/.test(value)) {
        e.preventDefault();
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e && e?.preventDefault();
    setErrors({});
    const { isValid, errors: formError } = promoCodeSchema(formData);
    console.log("formData?.startDate -> ", formData?.startDate);
    if (!isValid) {
      setErrors(formError);
    } else {
      setLoader(true);
      const payload = {
        name: formData?.codeName,
        id: formData?.promoCode,
        ...(formData?.notes !== "" && { notes: formData?.notes }),
        ...(formData?.textOfLandingPage !== "" && {
          textOfLandingPage: formData?.textOfLandingPage,
        }),
        ...(formData?.affiliate !== "" && { affiliate: formData?.affiliate }),
        ...(formData?.subscriptionType !== "" && {
          subscriptionTypes: formData?.subscriptionType,
        }),
        discountTypes: formData?.discountType,
        appliedPlans: formData?.plans,
        isForever: formData?.numberOfTime === "entireSubscription",
        ...(formData?.numberOfTime !== "entireSubscription" && {
          numbersOfTime:
            formData?.numberOfTime !== "entireSubscription" &&
            formData?.discountType === "percentage" &&
            formData?.numberOfTime
              ? formData?.numberOfTime
              : 1,
        }),
        discount: Number(formData?.discount),
        isUnlimitedQuantity: formData?.isUnlimitedQuantity,
        quantity: Number(formData?.quantity),
        startDate: moment(formData?.startDate).utc().toISOString(),
        isActive: true,
        ...(formData?.noExpirationDate === false && {
          expireDate: moment(formData?.expirationDate).utc().toISOString(),
        }),
        description: formData?.description,
        ...((formData?.trialDays === "1" ||
          formData?.trialDays === "2" ||
          formData?.trialDays === "3" ||
          formData?.customTrialDays?.length > 0) && {
          trialMonths:
            formData?.customTrialDays?.length > 0
              ? Number(formData?.customTrialDays)
              : Number(formData?.trialDays),
        }),
      };
      const result = await addPromoCode(payload);
      if (result?.errors) {
        setLoader(false);
        message.error(
          result?.errors?.errors?.error || "Something wend wrong",
          10,
        );
        setEditorState(EditorState.createEmpty());
      } else if (result?.remote === "success") {
        setLoader(false);
        message.success("Promo code added successfully", 10);
        getPromoCodeList();
        handleCancel();
        setEditorState(EditorState.createEmpty());
      }
    }
  };

  return (
    <>
      {loader && (
        <div className="center-loader">
          <SmallLoader />
        </div>
      )}
      <div className="add-promo-code">
        {/* <h3 className="fliter-sort-title">Add a Promo Code</h3> */}
        <Form layout="vertical" onSubmitCapture={handleSubmit}>
          <Row gutter={[24, 24]} className="promocode-main-row">
            <Col xs={12} lg={14} className="promocode-first-col">
              <Row gutter={[12, 12]}>
                <Col xs={12}>
                  <Form.Item
                    label={
                      <span>
                        Promo Code Name <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                  >
                    <Input
                      size="large"
                      placeholder="Promo Code Name"
                      name="codeName"
                      value={formData?.codeName}
                      onChange={handleFormChange}
                      style={{ borderRadius: "8px" }}
                    />
                    <div role="alert" className="text-danger">
                      {errors.codeName}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label={
                      <span>
                        Promo Code <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                  >
                    <Input
                      size="large"
                      placeholder="E.g. ZBS10"
                      name="promoCode"
                      value={formData?.promoCode}
                      onChange={handleFormChange}
                      style={{ borderRadius: "8px" }}
                    />
                    <div role="alert" className="text-danger">
                      {errors.promoCode}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Affiliate" className="select-promo-code">
                    {formData?.affiliate?.length > 0 ? (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            affiliate: value,
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select Affiliate"
                        value={formData?.affiliate}
                        dropdownRender={(menu) => (
                          <>
                            <Input
                              size="large"
                              type="text"
                              placeholder="Search"
                              style={{
                                width: "100%",
                                marginBottom: "8px",
                                borderColor: "#D0D5DD",
                                borderRadius: "8px",
                              }}
                              onChange={changeAffiliateSearch}
                              prefix={<SearchOutlined />}
                            />
                            {menu}
                          </>
                        )}
                        options={affiliateSearch?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                        }))}
                      />
                    ) : (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            affiliate: value,
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select Affiliate"
                        dropdownRender={(menu) => (
                          <>
                            <Input
                              size="large"
                              type="text"
                              placeholder="Search"
                              style={{
                                width: "100%",
                                marginBottom: "8px",
                                borderColor: "#D0D5DD",
                                borderRadius: "8px",
                              }}
                              onChange={changeAffiliateSearch}
                              prefix={<SearchOutlined />}
                            />
                            {menu}
                          </>
                        )}
                        options={affiliateSearch?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                        }))}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label={
                      <span>
                        Plans Promo Code Applies To{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    className="select-promo-code promocode-plans-applies"
                  >
                    <Select
                      mode="multiple"
                      onChange={(value) => {
                        setFormData({
                          ...formData,
                          plans: value,
                        });
                      }}
                      className="select-promo-code"
                      size="large"
                      placeholder="Select plans"
                      value={formData?.plans}
                    >
                      <Option disabled>Select plans</Option>
                      {plansForPromoCode?.map((v, i) => (
                        <Option value={v?.value} key={i}>
                          {v?.label}
                        </Option>
                      ))}
                    </Select>
                    <div role="alert" className="text-danger">
                      {errors.plans}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label={
                      <span>
                        Discount Type <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    className="select-promo-code"
                  >
                    {formData?.discountType?.length > 0 ? (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            discountType: value,
                            trialDays: value !== "freeTrial" && "",
                            numberOfTime:
                              value === "amount"
                                ? null
                                : formData?.numberOfTime,
                            subscriptionType:
                              value === "freeTrial"
                                ? formData?.subscriptionType?.filter(
                                    (v) => v !== "Existing subscription",
                                  )
                                : formData?.subscriptionType,
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        value={formData?.discountType}
                      >
                        <Option disabled>Select option</Option>
                        <Option value="percentage">Percentage</Option>
                        <Option value="amount">Fixed amount</Option>
                        <Option value="freeTrial">Free Trial</Option>
                      </Select>
                    ) : (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            trialDays: value !== "freeTrial" && "",
                            discountType: value,
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        // defaultValue="Select option"
                        // value={formData?.subscriptionType}
                      >
                        <Option disabled>Select option</Option>
                        <Option value="percentage">Percentage</Option>
                        <Option value="amount">Fixed amount</Option>
                        <Option value="freeTrial">Free Trial</Option>
                      </Select>
                    )}

                    <div role="alert" className="text-danger">
                      {errors.discountType}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Subscription Type"
                    className="select-promo-code"
                  >
                    {formData?.subscriptionType?.length > 0 ? (
                      <Select
                        mode="multiple"
                        maxTagCount={2}
                        onChange={(value) => {
                          setFormData({
                            ...formData,
                            subscriptionType: value,
                          });
                        }}
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        value={formData?.subscriptionType}
                      >
                        <Option disabled>Select option</Option>
                        <Option value="New subscription">
                          New Subscriptions
                        </Option>
                        <Option
                          value="Existing subscription"
                          disabled={formData?.discountType === "freeTrial"}
                        >
                          Existing Subscriptions
                        </Option>
                        <Option value="Reactivation">Reactivation</Option>
                      </Select>
                    ) : (
                      <Select
                        mode="multiple"
                        maxTagCount={2}
                        onChange={(value) => {
                          setFormData({
                            ...formData,
                            subscriptionType: value,
                          });
                        }}
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        // defaultValue="Select option"
                        // value={formData?.subscriptionType}
                      >
                        <Option disabled>Select option</Option>
                        <Option value="New subscription">
                          New Subscriptions
                        </Option>
                        <Option
                          value="Existing subscription"
                          disabled={formData?.discountType === "freeTrial"}
                        >
                          Existing Subscriptions
                        </Option>
                        <Option value="Reactivation">Reactivation</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label={
                      <span>
                        Amount / Percentage{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                  >
                    <Input
                      size="large"
                      placeholder="Please enter"
                      name="discount"
                      value={formData?.discount}
                      onChange={handleFormChange}
                      type="number"
                      style={{ borderRadius: "8px" }}
                      disabled={formData?.discountType === "freeTrial"}
                      prefix={formData?.discountType === "amount" && "$"}
                      suffix={formData?.discountType === "percentage" && "%"}
                    />
                    <div role="alert" className="text-danger">
                      {errors.discount}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="How many billing cycles?"
                    className="select-promo-code"
                  >
                    {formData?.numberOfTime?.length > 0 ? (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            numberOfTime: value,
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        value={formData?.numberOfTime}
                        disabled={
                          formData?.discountType === 0 ||
                          formData?.discountType !== "percentage"
                        }
                      >
                        <Option disabled>Select option</Option>
                        {promoCodeDuration?.map((v, i) => (
                          <Option value={v?.value} key={i}>
                            {v?.label}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            numberOfTime: value,
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        // defaultValue="Select option"
                        // value={formData?.numberOfTime}
                        disabled={
                          formData?.discountType === 0 ||
                          formData?.discountType !== "percentage"
                        }
                      >
                        {" "}
                        <Option disabled>Select option</Option>
                        {promoCodeDuration?.map((v, i) => (
                          <Option value={v?.value} key={i}>
                            {v?.label}
                          </Option>
                        ))}
                      </Select>
                    )}

                    <div role="alert" className="text-danger">
                      {errors.numberOfTime}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Trial Days" className="select-promo-code">
                    {formData?.trialDays?.length > 0 ||
                    formData?.customTrialDays?.length > 0 ? (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            trialDays: value,
                            customTrialDays: "",
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        value={
                          formData?.trialDays ||
                          `${formData?.customTrialDays} Months`
                        }
                        disabled={
                          formData?.discountType?.length === 0 ||
                          formData?.discountType !== "freeTrial"
                        }
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Space style={{ padding: "0 8px 4px" }}>
                              Custom :
                              <Input
                                ref={inputRef}
                                value={formData?.customTrialDays}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    customTrialDays: e.target.value,
                                    trialDays: "",
                                  });
                                }}
                                type="Number"
                                style={{ width: 50 }}
                              />
                              Months
                            </Space>
                          </>
                        )}
                        options={[
                          { label: "Select option", value: "", disabled: true }, // This is the new disabled option
                          ...trialDaysOption.map((item) => ({
                            label: item?.label,
                            value: item?.value,
                          })),
                        ]}
                      />
                    ) : (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            trialDays: value,
                            customTrialDays: "",
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        // value={formData?.trialDays}
                        disabled={
                          formData?.discountType?.length === 0 ||
                          formData?.discountType !== "freeTrial"
                        }
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Space style={{ padding: "0 8px 4px" }}>
                              Custom :
                              <Input
                                ref={inputRef}
                                value={formData?.customTrialDays}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    customTrialDays: e.target.value,
                                    trialDays: "",
                                  });
                                }}
                                type="Number"
                                style={{ width: 50 }}
                              />
                              Months
                            </Space>
                          </>
                        )}
                        options={[
                          { label: "Select option", value: "", disabled: true }, // This is the new disabled option
                          ...trialDaysOption.map((item) => ({
                            label: item?.label,
                            value: item?.value,
                          })),
                        ]}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <label className="mb-1">Start Date</label>
                  <DatePicker
                    placeholder="MM/DD/YYYY"
                    size="large"
                    style={{ borderRadius: "8px", width: "100%" }}
                    format="MM/DD/YYYY"
                    onChange={(date) => {
                      setFormData({
                        ...formData,
                        startDate: moment(date).format("MM/DD/YYYY HH:mm"),
                      });
                    }}
                    value={
                      formData.startDate ? moment(formData.startDate) : null
                    }
                  />
                  <div role="alert" className="text-danger">
                    {errors.startDate}
                  </div>
                </Col>
                <Col xs={12}>
                  <label className="mb-1">
                    Expiration Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <DatePicker
                    placeholder="MM/DD/YYYY"
                    size="large"
                    format="MM/DD/YYYY"
                    style={{ borderRadius: "8px", width: "100%" }}
                    disabled={formData?.noExpirationDate}
                    onChange={(date) =>
                      setFormData({
                        ...formData,
                        expirationDate: moment(date).format("MM/DD/YYYY HH:mm"),
                      })
                    }
                    value={
                      formData.expirationDate
                        ? moment(formData.expirationDate)
                        : null
                    }
                  />
                  <div role="alert" className="text-danger">
                    {errors.expirationDate}
                  </div>
                  <Form.Item valuePropName="checked" className="mb-0">
                    <Checkbox
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          noExpirationDate: !formData?.noExpirationDate,
                          expirationDate:
                            e.target.checked === true
                              ? ""
                              : formData?.expirationDate,
                        });
                      }}
                      checked={formData?.noExpirationDate}
                    >
                      No Expiration Date
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label={
                      <span>
                        How many companies can use?{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    className="promocode-last-input-field"
                  >
                    <Input
                      size="large"
                      placeholder={
                        formData?.isUnlimitedQuantity ? "Unlimited" : "E.g. 200"
                      }
                      name="quantity"
                      value={formData?.quantity}
                      onChange={handleFormChange}
                      type="number"
                      style={{ borderRadius: "8px" }}
                      disabled={formData?.isUnlimitedQuantity}
                      className="mb-2"
                    />
                    <div role="alert" className="text-danger">
                      {errors.quantity}
                    </div>
                  </Form.Item>

                  <Form.Item
                    valuePropName="checked"
                    style={{ marginTop: "-13px" }}
                  >
                    <Checkbox
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          isUnlimitedQuantity: !formData?.isUnlimitedQuantity,
                          quantity:
                            e.target.checked === true ? "" : formData?.quantity,
                        })
                      }
                      checked={formData?.isUnlimitedQuantity}
                    >
                      Unlimited
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={12} lg={10} className="promocode-second-col">
              <Row gutter={[12, 12]}>
                <Col xs={24}>
                  <Form.Item
                    label={
                      <span>
                        Description Shown On Payment Page:{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                  >
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                      toolbarHidden
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                      mention={{
                        trigger: "@",
                        separator: " ",
                        suggestions: mentions,
                      }}
                      placeholder="Promo Code activated! You will only be charged @{Discounted Price With Tax} for the first month with the discount applied. From the second month on, your monthly subscription charge will be @{Plan Price}/Month + Tax. If you have any questions please feel free to contact ZevBit Support!"
                      style={{ width: "100%", borderRadius: "4px" }}
                    />
                    <div role="alert" className="text-danger">
                      {errors.description}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item label="Promo Code Notes (Internal)">
                    <TextArea
                      size="large"
                      placeholder="Add Note (Only for internal use)"
                      name="notes"
                      type="textarea"
                      value={formData?.notes}
                      onChange={handleFormChange}
                      style={{ borderRadius: "8px", height: "123px" }}
                      rows={4}
                      className="mb-1"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item label="Text Of Offer To Show On Landing Page">
                    <Input
                      size="large"
                      placeholder="Get 10% OFF Your First Month!"
                      name="textOfLandingPage"
                      value={formData?.textOfLandingPage}
                      onChange={handleFormChange}
                      style={{ borderRadius: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Button
                    onClick={handleCancel}
                    style={{ width: "100%", borderRadius: "8px" }}
                    size="large"
                    className="mt-4"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs={12}>
                  <Button
                    style={{ width: "100%", borderRadius: "8px" }}
                    size="large"
                    htmlType="submit"
                    type="submit"
                    className="mt-4 promocode-submit-button"
                  >
                    Add Promo Code
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
export default PromoCode;
