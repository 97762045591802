import { Button, Col, DatePicker, message, Row, Space } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  extendFreeTrial,
  getUsersDataAction,
  loginAccount,
} from "../../../../api/admin";
import {
  modifiedStatus,
  statusColors,
} from "../../../../utils/constants/constants";
import { trial } from "../../../../utils/svg.file";
import SmallLoader from "../../../loader/smallLoader";
import Billing from "./Billing";
import { showLoginButton } from "./helper";
import Users from "./Users";
const items = [
  {
    id: 1,
    label: "Users",
  },
  {
    id: 2,
    label: "Billing",
  },
];

const Details = ({
  selectedUser,
  isActiveTab,
  setIsActiveTab,
  setOpen,
  open,
  setOutside,
  setValue,
  value,
}) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [currentUsersPage, setCurrentUsersPage] = useState(1);

  const getTrialDays = (status, trialDays) => {
    if (status === "ActiveTrial") {
      return trialDays;
    }
    return modifiedStatus[status?.toLowerCase()] || status;
  };
  const getBackgroundColor = (status, trialDays, remainingDays) =>
    status === "ActiveTrial"
      ? remainingDays > 7
        ? "#719D40"
        : remainingDays > 3 && remainingDays < 8
        ? "#FFBC44"
        : remainingDays < 4
        ? "#AD3838"
        : ""
      : statusColors[status?.toLowerCase()] || "#FFBC44";
  const handleExtendTrialPeriod = (e) => {
    e.stopPropagation();
    setOpen((val) => !val);
  };

  const handleSelectChange = (value) => {
    setValue(value);
  };
  const handleApplyClick = async ({ selectedUserData: data }) => {
    const valueDate = moment(value);
    const payload = {
      date: valueDate.format("YYYY-MM-DD"),
    };
    const result = await extendFreeTrial(data?._id, payload);
    if (result?.remote !== "success") {
      message.error(result?.errors?.errors?.error);
    } else {
      message.success(result?.data?.message);
      setValue(valueDate);
      setOpen(false);
    }
  };
  const ModalFooter = (selectedUserData) => (
    <div style={{ display: "flex", padding: 10, justifyContent: "center" }}>
      <Button
        onClick={() => {
          setValue(null);
          setOpen(false);
        }}
        style={{
          marginRight: 10,
          borderRadius: "8px",
          padding: "8px 16px",
          height: "auto",
        }}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        style={{ borderRadius: "8px", padding: "8px 16px", height: "auto" }}
        onClick={() => handleApplyClick(selectedUserData)}
      >
        Apply
      </Button>
    </div>
  );

  const getStatus = (status, isSubscriptionEnd) =>
    status === "Approved" && isSubscriptionEnd ? "canceled" : status;

  const getUsersData = async () => {
    setLoader(true);

    const result = await getUsersDataAction(
      selectedUser?._id,
      currentUsersPage,
      8,
    );
    if (result?.remote === "success") {
      setUsersData(result?.data?.data?.[0]);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handleLoginAccount = async () => {
    const response = await loginAccount({ organizationId: selectedUser?._id });
    if (response.remote) {
      const superAdminToken = localStorage.getItem("token");
      localStorage.setItem("fromSuperAdmin", true);
      localStorage.setItem("superAdminToken", superAdminToken);
      localStorage.setItem("token", response.data?.data?.token);
      localStorage.setItem("userRole", "masterAdmin");
      window.location.replace("/v2/CRM?view=default");
    }
  };

  useEffect(() => {
    getUsersData();
  }, []);

  return (
    <>
      {loader && (
        <div className="center-loader">
          <SmallLoader />
        </div>
      )}
      <div className="userlist-details">
        <Space
          size={[16, 16]}
          align="start"
          wrap
          style={{ justifyContent: "space-between" }}
          className="w-100"
        >
          <Row gutter={24}>
            <Col>
              <h3 className="fliter-sort-title mb-0">
                {selectedUser?.companyName}
              </h3>
              <span>
                {selectedUser?.maName} - {selectedUser?.maEmail}
              </span>
              <div>{selectedUser?.companyAddress}</div>
              <div>#{selectedUser?.accountNumber}</div>
            </Col>
            <Col>
              <Row gutter={24}>
                <Col>
                  <span
                    className="trail-days"
                    style={{
                      background: getBackgroundColor(
                        getStatus(
                          selectedUser?.status,
                          selectedUser?.isSubscriptionEnd,
                        ),
                        selectedUser?.trialDays,
                        selectedUser?.trialStatsDays,
                      ),
                    }}
                  >
                    {getTrialDays(
                      getStatus(
                        selectedUser?.status,
                        selectedUser?.isSubscriptionEnd,
                      ),
                      selectedUser?.trialDays,
                    )}
                  </span>
                </Col>
                {selectedUser?.status === "ActiveTrial" && (
                  <Col
                    style={{ cursor: "pointer" }}
                    onMouseEnter={() => {
                      setOutside(false);
                    }}
                    onMouseLeave={() => {
                      setOutside(true);
                    }}
                  >
                    <span
                      className="period"
                      aria-hidden
                      onClick={handleExtendTrialPeriod}
                    >
                      Extend Trial Period{" "}
                      <small className="ms-3">{trial}</small>
                    </span>

                    <DatePicker
                      open={open}
                      value={value}
                      onSelect={(value) => handleSelectChange(value)}
                      style={{ visibility: "hidden", width: 0, padding: 0 }}
                      placement="bottomRight"
                      popupClassName="some-class"
                      popupStyle={{ paddingTop: "15px" }}
                      dateRender={(current) => (
                        <div
                          className={`ant-picker-cell-inner ${
                            moment(current).format("DD/MM/YYYY") ===
                            moment().format("DD/MM/YYYY")
                              ? "dotsclass"
                              : ""
                          }`}
                        >
                          {current.date()}
                        </div>
                      )}
                      disabledDate={(current) =>
                        moment(current).endOf("day") < moment().startOf("day")
                      }
                      renderExtraFooter={() => (
                        <>
                          <ModalFooter selectedUserData={selectedUser} />
                        </>
                      )}
                      showToday={false}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {showLoginButton.includes(selectedUser?.sortStatus) && (
            <Button
              type="primary"
              style={{
                width: "100%",
                borderRadius: "8px",
              }}
              size="large"
              className="promocode-submit-button"
              onClick={() => handleLoginAccount()}
            >
              Login to this account
            </Button>
          )}
        </Space>
        <span className="close-icon-list" onClick={() => navigate(-1)}></span>
        <div className="tabs my-3">
          <ul>
            {items.map((item) => (
              <li
                onClick={() => setIsActiveTab(item.id)}
                className={isActiveTab === item.id ? "active" : null}
                key={item.id}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
        <div className="tab-content">
          {isActiveTab === 1 && (
            <Row>
              <Col xs={24}>
                <div className="tablecard">
                  <Users
                    selectedUser={selectedUser}
                    usersData={usersData}
                    currentUsersPage={currentUsersPage}
                    setCurrentUsersPage={setCurrentUsersPage}
                  />
                </div>
              </Col>
            </Row>
          )}
          {isActiveTab === 2 && (
            <Row>
              <Col xs={24}>
                <div className="tablecard">
                  <Billing selectedUser={selectedUser} />
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  );
};
export default Details;
