import { combineReducers } from "redux";

import userReducer from "./features/user";
import affiliateReducer from "./redux/affiliate/affiliate.reducer";
import LandingReducer from "./redux/affiliateLanding/landing.reducer";
import APIReducer from "./redux/api/api.reducer";
import { invoiceReducer } from "./redux/invoice/invoice.reducer";
import { leadReducer } from "./redux/lead/leadreducer";
import LeadStatusReducer from "./redux/leadStatus/leadStatus.reducer";
import OrganizationReducer from "./redux/organization/organization.reducer";
import { reducerUsersGroupList } from "./redux/usersList/usersListReducer";

const rootReducer = combineReducers({
  user: userReducer,
  organization: OrganizationReducer,
  lead: leadReducer,
  usersList: reducerUsersGroupList,
  api: APIReducer,
  leadStatus: LeadStatusReducer,
  landingDetails: LandingReducer,
  affiliate: affiliateReducer,
  invoice: invoiceReducer,
});

export default rootReducer;
