import moment from "moment";

const constants = {
  averageGuysPerCrews: "On Average, how many guys work on each of your crews? ",
  averageHoursPerGuy: "How many hours do your guys work per day on average? ",
};
export const FIELD_TYPE = {
  string: "string",
  numeric: "numeric",
};
export const howToCalculateOverheadRate =
  "https://docs.google.com/spreadsheets/d/10c4sXjfSOIjYn7iYlx85zhVRNLJtanxlnVCk5M9tOp8/edit?usp=sharing";
export const DATE_TIME_FORMATE = "MM/DD/YYYY HH:mm";
export const DATE_FORMATE = "MM/DD/YYYY";
export const DATE_FORMATE_FOR_FREE = "M/D/YY";
export const CHART_DATE_FORMATE = "DD-MM-YYYY";
export const API_DATE_FORMATE_FOR_GRAPH = "YYYY-MM-DD";
export const HOURS_FORMAT = "HH:mm";
export const DATE_FORMAT_ACTIVITY_LOG = "dddd, MM/DD/YYYY";
export const MONTH_FORMAT = "MM";
export const DATE_FORMAT = "DD";
export const YEAR_FORMAT = "YYYY";
export const TIME_DATE_FORMATE = "hh:mm a";
export const TIME_DATE_FORMATE_NOT_WORKING_DAY = "ddd DD, MMM YYYY";
export const TIME_FORMAT_NOT_WORKING_DAY = "HH:mm A";
export const TIME_FORMAT_NOT_WORKING_DAY_IN_24 = HOURS_FORMAT;
export const TIME_FORMATE_24 = HOURS_FORMAT;
export const FULL_MONTH_YEAR_DATE = "MMMM Do, YYYY";
export const decimalValueRegex = /^\d*\.?\d*$/;
export const DATE_FORMATE_VALUE = "MM-DD-YYYY";
export const API_CALL_DATE_FORMAT = API_DATE_FORMATE_FOR_GRAPH;
export const API_CALL_TIME_FORMAT = "HH:mm:ss";
export const DATE_DAY_NAME = "dddd";

export const LEAD_TYPES = {
  invoice: "INVOICE",
  crm: "CRM",
  estimate: "ESTIMATE",
  project: "PROJECT",
};

export const USER_ROLE = Object.freeze({
  masterAdmin: "masterAdmin",
  superAdmin: "superAdmin",
  admin: "admin",
  salesPerson: "salesPerson",
});
export const PAYMENT_STATUS = Object.freeze({
  PENDING: "Pending",
  FAILED: "Failed",
  PAID: "Paid",
  SUCCESS: "success",
});
export const PRODUCTION_RATE_LINK = Object.freeze({
  production_rate_calculator:
    "https://docs.google.com/spreadsheets/d/1rIXVpYZrW8liJjPIXhM78yEYH-LN7UA5OYqPnRbqdh4/edit?usp=sharing",
});

export const STATUS_TYPE = Object.freeze({
  CRM: "CRM",
});

export const customerDetailsRoute = (
  { customerLeadId, isScheduledCustomer, projectId, tab, query },
  locState,
) => {
  let baseRoute = `/v2/customer-details?customerId=${customerLeadId}`;
  if (isScheduledCustomer) {
    baseRoute += `&scheduledCustomer=${isScheduledCustomer}`;
  }
  if (projectId) {
    baseRoute += `&projectId=${projectId}`;
  }
  if (tab) {
    baseRoute += `&tab=${tab}`;
  }
  if (query) {
    baseRoute += query;
  }
  if (!query?.includes("panel")) {
    baseRoute += `&panel=lead_info`;
  }
  if (locState) {
    baseRoute += `&redirectTo=${locState?.redirectToCRM}`;
  }
  return baseRoute;
};

export const getQueryParam = ({ name, location }) => {
  if (!location || !name || !location.search) {
    return null;
  }
  return new URLSearchParams(location.search).get(name);
};

// function to get difference between two dates in days
/**
 *
 * @param {DATE} date1 Start date
 * @param {DATE} date2 End date
 * @returns number of days between two dates
 */
export const getDifferenceBetweenDates = (date1, date2) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

// function to capitalize first word of string
export const capitalizeString = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

// all the userEstimationStatus that are possible by backend
export const userEstimationStatus = {
  pending: "Pending",
  sentToCustomer: "Sent to Customer",
  signed: "Signed",
  internallyConfirmed: "Internally Confirmed",
  removed: "Removed",
};

// add alpha to the colors
export const addAlpha = (color, opacity) => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const getMinsBetweenTwoDates = (startTime, endTime) =>
  Math.round((endTime.getTime() - startTime.getTime()) / 1000 / 60);

export const getPercentage = (total, amount) => (amount / total) * 100;

export function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export const MISCELLANEOUS_AND_OTHERS = "miscellaneous and others";
export const plans = {
  essential: {
    planId: process.env.REACT_APP_ESSENTIAL_PLAN_ID,
    planName: "Essential Package",
    planPrice: "197.99",
  },
  essentialPlan: {
    planId: process.env.REACT_APP_ESSENTIAL_PLAN_ID,
    planName: "Essential Package",
    planPrice: "197.99",
  },
  estimating: {
    planId: process.env.REACT_APP_ESTIMATING_PLAN_ID,
    planName: "Estimating Package",
    planPrice: "97.99",
  },
  estimatingPlan: {
    planId: process.env.REACT_APP_ESTIMATING_PLAN_ID,
    planName: "Estimating Package",
    planPrice: "97.99",
  },
  estimatingYearly: {
    planId: process.env.REACT_APP_ESTIMATING_YEAR_PLAN_ID,
    planName: "Estimating Annually Package",
    planPrice: "77.99",
  },
  essentialYearly: {
    planId: process.env.REACT_APP_ESSENTIAL_YEAR_PLAN_ID,
    planName: "Essential Annually Package",
    planPrice: "157.99",
  },
  estimatingYearlyPlan: {
    planId: process.env.REACT_APP_ESTIMATING_YEAR_PLAN_ID,
    planName: "Estimating Annually Package",
    planPrice: "77.99",
  },
  essentialYearlyPlan: {
    planId: process.env.REACT_APP_ESSENTIAL_YEAR_PLAN_ID,
    planName: "Essential Annually Package",
    planPrice: "157.99",
  },
};

export const plansForPromoCode = [
  { label: "Estimating (M)", value: "estimatingPlan" },
  { label: "Essentials (M)", value: "essentialPlan" },
];

export function validatePassword(password) {
  // Check if password length is between 12 and 18 characters
  if (password.length < 8) {
    return false;
  }

  // Check if password contains at least one uppercase and one lowercase letter
  if (!/[A-Z]/.test(password) || !/[a-z]/.test(password)) {
    return false;
  }

  // Check if password contains at least one special character
  if (!/[\W_]/.test(password)) {
    return false;
  }

  // Check if password contains at least one number
  if (!/\d/.test(password)) {
    return false;
  }

  // Check if password contains any spaces
  if (/\s/.test(password)) {
    return false;
  }

  // All conditions passed, so password is valid
  return true;
}

export function manageUserRole(role) {
  return (
    role === USER_ROLE.masterAdmin ||
    role === USER_ROLE.superAdmin ||
    role === USER_ROLE.admin
  );
}

export default constants;

export const statusColors = {
  pending: "#3483FA",
  approved: "#3483FA",
  suspend: "#000",
  carddeclined: "#EF5DA8",
  "card declined": "#EF5DA8",
  canceled: "#E42626",
  cancelled: "#E42626",
  cancelontrial: "#D34141",
  freeuser: "#A800E3",
  ongrace: "#3483FA",
};

export const modifiedStatus = {
  pending: "Pending",
  approved: "Active",
  suspend: "Rejected",
  carddeclined: "Card Declined",
  "card declined": "Card Declined",
  cancelontrial: "Cancel On Trial",
  canceled: "Canceled",
  cancelled: "Canceled",
  freeuser: "Free User",
  ongrace: "Active",
};

export const ESTIMATE_TEMPLATE_DISCLAIMER = `By clicking "Agree," you acknowledge that it is your responsibility to review all the estimate templates you are updating to ensure they are accurate for you and your company. ZevBit is not liable for any misuse of templates or underestimating. Please refer to ZevBit's <a style={{ color: "blue" }}>help center</a> to learn how to customize estimate templates.`;
export const ESTIMATE_LEAD_DELETE_DISCLAIMER =
  "By deleting this customer from ZevBit they will no longer be able to sign their estimate (If you sent them one) and all of the internal calculations for materials, costs etc will be lost. This action is not reversible. ";

export const FALSY_CONSTANTS = [
  "undefined",
  undefined,
  false,
  "false",
  "null",
  null,
];

export const countryList = [
  {
    label: "United States",
    value: "US",
  },
  {
    label: "Canada",
    value: "CA",
  },
];

export const discountStatusColor = {
  "Discount Remaining": "#14A88D",
  "Discount Used": "#E42626",
  Canceled: "#B72B2B",
  "Card Declined": "#E42626",
  Removed: "#E42626",
};

export const promoCodeStatus = {
  pending: "Pending",
  active: "Active",
  activetrial: "Active Trial User",
  rejected: "Rejected",
  carddeclined: "Card Declined",
  canceled: "Canceled",
  freeuser: "Free User",
  notSignedUp: "Not Signed Up",
  activeOnTrial: "Active Trial User",
  canceledOnTrial: "Canceled On Trial",
  onHold: "On Hold",
};
export const promoCodeStatusColor = {
  pending: "#FFB800",
  active: "#14A88D",
  activetrial: "#FF9045",
  carddeclined: "#EF5DA8",
  canceled: "#D34141",
  rejected: "#E42626",
  freeuser: "#9C26E4",
  notsignedup: "#AE9023",
  activeontrial: "#FF9045",
  canceledontrial: "#E42626",
  onhold: "#E42626",
};

export const statusBackgroundColor = {
  pending: "#FFB800",
  active: "#ECFDF3",
  activetrial: "#FF9045",
  carddeclined: "#EF5DA8",
  canceled: "#FDECEC",
  rejected: "#FDECEC",
  freeuser: "#9C26E4",
  notsignedup: "#FBFDEC",
  activeontrial: "#FDF6EC",
  canceledontrial: "#FDECEC",
  onhold: "#FDECEC",
};

export const deActiveNote =
  "Are you sure you want to deactivate this Promo Code? Remember that people who have already used this code will continue to receive any remaining discounts (If applicable). New accounts trying to use this code will see an error message.";

export const removeCodeNote =
  "Are you sure you want to remove this promo code for this company? By doing so, the company will lose the privileges of the promo code.";

export const initialActions = {
  filledLeadForm: "Lead Form",
  directSignUp: "Direct Sign Up",
  scheduledDemo: "Schedule Demo",
  reactive: "Reactivation",
  freeTrial: "Free Trial",
};

export const presets = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, "day"), moment().subtract(1, "day")],
  "This Week": [
    moment().startOf("week"),
    moment().endOf("week").valueOf() > moment().valueOf()
      ? moment()
      : moment().endOf("week"),
  ],
  "Last Week": [
    moment().subtract(1, "weeks").startOf("week"),
    moment().subtract(1, "weeks").endOf("week"),
  ],
  "This Month": [
    moment().startOf("month"),
    moment().endOf("month").valueOf() > moment().valueOf()
      ? moment()
      : moment().endOf("month"),
  ],
  "Last Month": [
    moment().subtract(1, "months").startOf("month"),
    moment().subtract(1, "months").endOf("month"),
  ],
  "This Year": [
    moment().startOf("year"),
    moment().endOf("year").valueOf() > moment().valueOf()
      ? moment()
      : moment().endOf("year"),
  ],
  "Last Year": [
    moment().subtract(1, "years").startOf("years"),
    moment().subtract(1, "years").endOf("years"),
  ],
  "All Time": [],
};
export const REMOVE_LAYOUTS = ["/v2/affiliate-registration"];

export const imageFileExtensions = ["image/png", "image/jgp", "image/jpeg"];

export const docFileExtension = ["application/pdf", "pdf"];

export const allImageFilesExtension = [
  "image/svg+xml",
  "image/gif",
  ...imageFileExtensions,
];

export const fileExtension = [...imageFileExtensions, ...docFileExtension];
export const emailTemplateCatagories = Object.freeze({
  ESTIMATE: "Estimate",
  SIGNED_ESTIMATE: "Signed Estimate",
  DUE_INVOICE: "Due Invoice",
  OVERDUE_INVOICE: "Overdue Invoice",
  NOT_DUE_INVOICE: "Not Due Invoice",
  RECORDED_PAYMENT_RECEIPT: "Recorded Payment Receipt",
  PAYMENT_RECEIPT: "QB/Stripe Payment Receipt",
  CHANGE_ORDER: "Change Order",
  CREDIT: "Credit",
  PROJECT_SUMMARY: "Project Summary",
  DUE_DATE_REMINDERS: "Due Date Reminders",
  OVERDUE_REMINDERS: "Overdue Reminders",
});
export const paymentOption = Object.freeze({
  quickBook: "quickbooks",
  stripe: "stripe",
  square: "square",
});
export const getSectionFromUrl = () => {
  const url = new URL(window.location.href);
  // Assuming the section is a query parameter named 'section'
  return url.searchParams.get("section");
};

export const modifiedTagsValue = (backendTagsValuesArray, frontendTagArray) => {
  const realValues = backendTagsValuesArray.filter((item1) =>
    frontendTagArray.some(
      (item2) =>
        item2?.text?.replace(/\s/g, "").toLowerCase() ===
        item1?.name?.replace(/\s/g, "").toLowerCase(),
    ),
  );
  const newTags = realValues.map((item) => ({
    text:
      item.name.charAt(0).toUpperCase() + item.name.slice(1).replace(/_/g, " "),
    value: `${item.replacement}`,
    field: item?.replacement?.replace(/_/g, ""),
  }));
  return newTags;
};

export const getQueryFromUrl = (query) => {
  const url = new URL(window.location.href);
  // Assuming the section is a query parameter named 'section'
  return url.searchParams.get(query);
};
export const PROJECT_STATUS = {
  onHold: "On Hold",
  Delayed: "Delayed",
  notStarted: "Not Started",
  Cancelled: "Cancelled",
  inProgress: "In Progress",
  Completed: "Completed",
};

export const hasBannerPermission = [USER_ROLE.masterAdmin, USER_ROLE.admin];

export const essentialPlanFeatures = [
  "Complete Invoicing Platform",
  "Auto Generate Invoices",
  "Quickbooks Sync & Payments",
  "Automatic Reminder Emails",
  "Create & Send Change Orders",
  "Credit Card/ACH Payments",
  "View & Send Project Summary’s",
  "24/7 US Based Support",
];

export const hideBannerRoutes = ["/welcome-back", "/change-plan"];

export const REDIRECTION_TO = {
  STRIPE: "stripe",
  QB_SYNC: "qb-sync",
  QB_PAYMENT: "qb-payment",
  SQUARE_SYNC: "square-sync",
  SQUARE_PAYMENT: "square-payment",
};

export const PAYMENT_INTEGRATION_TYPE = {
  quickbooks: "Linked With Quickbooks",
  stripe: "Linked With Stripe",
  square: "Linked With Square",
};

export const VALIDATION_MESSAGE = {
  email: "Enter a valid email address",
  number: "Invalid card",
  expiryDate: "Expiration date must be e.g.: 01/24",
  cvc: "CVC number must be e.g.: 123",
  name: "Enter a card holder detail",
  country: "Please select the country",
  postalCode: "Invalid Zip code",
};

export const MAX_ATTACHMENT_SIZE = 25 * 1024;

export const ATTACHMENT_TYPES = ["image", "pdf", "text"];
