export const howItWorkDescription = [
  {
    title: "How Does Instant Estimating Work?",
    subContent: [
      {
        srNo: "1",
        content:
          "Use ZevBit’s user-friendly onboarding process to customize ZevBit by inputting your Company’s specific material prices, production rates, and markups for each service you offer.",
      },
      {
        srNo: "2",
        content:
          "Use ZevBit to calculate your overhead rates and have your overhead burden factored into every quote.",
      },
    ],
  },
  {
    title: "Write Quotes In Minutes!",
    subContent: [
      {
        srNo: "3",
        content:
          "Enter dimensions and simple details about a project into ZevBit's Instant Estimating Templates and ZevBit will calculate extremely accurate pricing, materials costs, labor hours, and profit margins for you.",
      },
      {
        srNo: "4",
        content:
          "ZevBit saves its users a ton of keyboard time by Auto-Populating quotes so you can email your customers an easy to read, professional, and electronically signable quote with the click of a button.",
      },
    ],
  },
];

export const crmContent = [
  {
    title: "CRM",
    subContent: [
      {
        content:
          "Never lose track of a lead again! Organize your leads and current customers with ZevBit’s powerful CRM. Keep a detailed record of each client with contact information, project details, pending/signed quotes, and more!",
        needBullet: false,
      },
      {
        content:
          "Create Customer profiles in ZevBit’s CRM to neatly store each and every detail regarding a lead or a current customer.",
      },
      {
        content:
          "Keep track of your follow-up attempts before and after an estimate has been sent!",
      },
      {
        content:
          "Use ZevBit’s Pipeline View to easily see where each lead is within your sales process.",
      },
      {
        content:
          "ZevBit’s CRM Reporting features allow you to see your conversion rate on leads and each lead source’s performance to analyze where your advertising budget is best spent.",
      },
    ],
  },
];

export const listContent = [
  "Available when you need us most, around the clock support to continue pushing your business forward.",
  "Send a text into your personal ZevBit Support group chat to receive a lightning fast response.",
];

export const ratings = [
  {
    ratingStared: 5,
    title: "“Truly Instant Estimating”",
    content:
      "This Software is perfect for my landscaping company! ZevBit allows my new salesmen to easily learn how to estimate accurately and quickly! This allowed my company to scale faster than I could have imagined. I have watched ZevBit from the beginning and I know this is going to be the best business software out there!",
    author: "Metikolo Fukofuka",
    role: "Hardscape Contractor",
  },
  {
    ratingStared: 5,
    title: "“Don’t use your brain, use theirs!”",
    content:
      "ZevBit allows me to write insanely accurate quotes in just a fraction of the time. ZevBit’s Instant Estimating is much more accurate than my old quoting process, it uses my crew’s production rates and material prices in their formulas. Getting every customer a quote the same day as the project walk is game changing. Do not sleep on ZevBit!",
    author: "Ben Jennings",
    role: "Landscape Contractor",
  },
  {
    ratingStared: 5,
    title: "“It’s what the landscaping industry needed…”",
    content:
      "ZevBit’s Estimating has completely changed the way I write quotes and close projects. It used to take me days to get a quote back to a customer after the job walk. Now my salesman and I write the quotes during the job walk and present an E-Signable quote instantly, giving me the opportunity to close the deal before I hop back in my truck. Looking forward to using the rest of the features!",
    author: "Jonathan Dellalo",
    role: "Landscape Contractor",
  },
];

export const earningList = [
  {
    title: "ZevBit Quotes Are...",
    list: [
      "Accurate",
      "Professional",
      "Easy To Read",
      "Electronically Signable",
    ],
    style: {
      backgroundImage: "linear-gradient(134deg,#2375f6,#71f69d)",
    },
  },
  {
    title: "Based on...",
    list: [
      "Your Material Prices",
      "Your Production Rates",
      "Your Overhead",
      "Your Company's Methods",
    ],
    style: {
      backgroundImage: "linear-gradient(134deg,#71f69d,#2375f6)",
    },
  },
];

export const estimateList = [
  {
    title: "Your Old Processes...",
    list: [
      "Hand Calculating Your Estimates",
      "SqFt Pricing",
      "Hand Delivering Estimates",
      "Typing Estimates",
    ],
    style: {
      background: "#FF5151",
    },
  },
  {
    title: "Using Zevbit!",
    list: [
      "Estimating Instantly",
      "Accurate Estimating Based On Your Production Rates, Material Pricing, & Overhead",
      "Sending & Signing Estimates Electronically",
      "Auto Populate Professional, Easy To Read Estimates",
    ],
    style: {
      backgroundImage: "linear-gradient(134deg,#71f69d,#2375f6)",
    },
  },
];

export function extractErrorMessage(errorString) {
  // Use a regular expression to match the error message
  const errorMessageMatch = errorString.match(
    /Error validating request body\. "([^"]+)" is required\./,
  );

  // If a match is found, return the captured group (the error message) without quotes
  if (errorMessageMatch && errorMessageMatch.length > 1) {
    return `${errorMessageMatch[1]} is required.`;
  }

  // If no match is found, return a default message or null
  return null;
}
