import { Button, Form, Input, Modal } from "antd";
import moment from "moment";
import React, { useState } from "react";

import userProfile from "../../images/blank-profile.png";
import { createValidUrl } from "../../utils/commonFuctions";

const { TextArea } = Input;

export default function NotificationModal(props) {
  const [, setHistoryModal] = useState(false);
  const { currentData } = props;
  const showHistory = () => {
    setHistoryModal(true);
  };
  return (
    <Modal
      title={false}
      visible={props.isNotification}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      footer={false}
      className="modal-radius"
    >
      <div className="notification-list border-0 mb-3">
        <div className="notification-list_img">
          <div
            className="notification-dot"
            style={{ background: currentData?.activeStatus ? "#1A73E8" : "" }}
          />
          <img
            src={
              createValidUrl(currentData?.sender?.profileImage) || userProfile
            }
            alt="user"
          />
        </div>
        <div className="notification-list_detail">
          <h6>{currentData?.sender?.name}</h6>
          <span>Sent you a Time Card</span>
        </div>
        <div className="notification-list-ago">
          <span>{moment(currentData?.createdAt).fromNow()}</span>
        </div>
      </div>
      <Form layout="vertical" className="mb-5">
        <Form.Item label="Comment">
          <TextArea
            className="xd-textarea"
            rows={4}
            placeholder="Hey Olivia;This is the Time Card We were talking about, check it out?"
            value={currentData?.comment}
          />
        </Form.Item>
      </Form>

      <div className="d-flex aligin-items-center justify-content-between">
        <Button
          onClick={props.handleCancel}
          shape="round"
          size="large"
          className="xd-btn-close"
        >
          Close
        </Button>
        <Button type="primary" shape="round" size="large" onClick={showHistory}>
          Open Card
        </Button>
      </div>
    </Modal>
  );
}
