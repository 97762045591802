import { Col, Row, Select } from "antd";
import React from "react";

const { Option } = Select;

function Dropdown({
  setSearchValue,
  idx,
  handleChange,
  tempName,
  element,
  onFocusOut,
  suggestedCatalogs,
  variation,
}) {
  const [customFields, setCustomFields] = React.useState([]);
  const [isCustomFieldAvailable, setIsCustomFieldAvailable] =
    React.useState(false);
  const getSelectedVariation = React.useCallback(() => {
    const variations = [...(variation || [])];
    const selectedVariation = variations.find(
      (v) => v.superId === element.value,
    );
    setCustomFields(selectedVariation?.customFields || []);
  }, [element.value, variation]);

  React.useEffect(() => {
    getSelectedVariation();
  }, [getSelectedVariation]);

  React.useEffect(() => {
    const catalogItem = suggestedCatalogs.find(
      (item) => item.superId === element.dropdown,
    );
    if (catalogItem?.type === "subContractor") {
      setIsCustomFieldAvailable(false);
    } else {
      setIsCustomFieldAvailable(true);
    }
  }, [element.dropdown, suggestedCatalogs]);

  return (
    <>
      <Row gutter={[24, 0]} className="mb-3">
        <Col md={8} className="mb-3">
          <label>Choose Dropdown Items:</label>
        </Col>
        <Col md={16}>
          <Select
            showSearch
            style={{ width: "100%" }}
            onSearch={setSearchValue}
            onChange={(value) => {
              handleChange(value, "dropdown", idx);
            }}
            filterOption={(input, option) =>
              option.name.toLowerCase().includes(input.toLowerCase())
            }
            defaultValue={tempName || element.dropdown}
            onBlur={onFocusOut}
          >
            {suggestedCatalogs.map((catalog) => (
              <Option
                key={catalog._id}
                value={catalog.superId}
                name={catalog.name}
              >
                {catalog.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={[24, 0]} className="mb-3">
        <Col md={8} className="mb-3">
          <label>Choose Variation:</label>
        </Col>
        <Col md={16}>
          <Select
            showSearch
            style={{ width: "100%" }}
            onSearch={setSearchValue}
            onChange={(value) => {
              handleChange(value, "value", idx);
            }}
            filterOption={(input, option) =>
              option.name.toLowerCase().includes(input.toLowerCase())
            }
            defaultValue={element.value}
            onBlur={onFocusOut}
            placeholder="Select Default Variation"
          >
            {variation.map((catalog) => (
              <Option
                key={catalog._id}
                value={catalog.superId}
                name={catalog.name}
              >
                {catalog.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      {isCustomFieldAvailable && (
        <Row gutter={[24, 0]} className="mb-3">
          <Col md={8} className="mb-3">
            <label>Field To Show:</label>
          </Col>
          <Col md={16}>
            <Select
              showSearch
              style={{ width: "100%" }}
              onSearch={setSearchValue}
              onChange={(value) => {
                handleChange(value, "customFieldToShow", idx);
              }}
              filterOption={(input, option) =>
                option.name.toLowerCase().includes(input.toLowerCase())
              }
              defaultValue={element.customFieldToShow || ""}
              onBlur={onFocusOut}
              placeholder="Select Default Field to Show"
            >
              {customFields.map((catalog) => (
                <Option
                  key={catalog._id}
                  value={catalog._id}
                  name={catalog.fieldName}
                >
                  {catalog.fieldName}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Dropdown;
