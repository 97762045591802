import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";

const TableView = ({ rows, columns }) => (
  <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
        <TableHead>
          <TableRow className="table-head-bg">
            {(columns || []).map((data, index) => (
              <TableCell sx={{ width: data.width }} key={index}>
                {data?.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className="tablebody-first">
          {rows && rows?.length > 0
            ? (rows || []).map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {(columns || []).map((data, id) => (
                    <TableCell
                      component="th"
                      scope="row"
                      key={id}
                      className={data.className}
                    >
                      {row[data.key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            : ""}
        </TableBody>
      </Table>
    </TableContainer>
  </>
);
export default TableView;
