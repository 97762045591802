export const AFFILIATE_TYPES = {
  AUTH: "affiliate/auth",
  AUTH_ERROR: "affiliate/auth/error",
  PROFILE: "affiliate/profile",
  FORM_CHANGE: "affiliate/form-changed",
  FORM_FIELDS_CHANGE: "affiliate/form-data",
  LOADING: "affiliate/loading-data",
  BANNER_TYPE: "affiliate/banner-type",
  SHOW_BANNER: "affiliate/show-banner",
  FORM_ERRORS: "affiliate/form-input-error",
  RESET_FORM_ERROR: "affiliate/reset-form-input",
  REFERRAL_LIST: "affiliate/referral-list",
  SUMMARY: "affiliate/summary",
  OVERVIEW: "affiliate/overview",
  MY_REFERRALS: "affiliate/my-referrals",
  MY_REFERRALS_FILTER: "affiliate/my-referral/filters",
  REFERRAL_DETAILS: "affiliate/referrals-details",
  REFERRAL_HISTORY: "affiliate/referral-history",
  AFFILIATE_MARKETER: "affiliate/affiliate-marketer",
  SET_AFFILIATE_ROW_DETAILS: "affiliate/affiliate-row-details",
  SUPER_ADMIN_MODALS: "affiliate/toggle-modal",
  LINK_CODE: "affiliate/link-code",
  SURVEY_DETAILS: "affiliate/super-admin/survey",
  DOCUMENT_DETAIL: "affiliate/document-payment",
  REPORTED_LIST: "affiliate/document-report",
  AFFILIATE_PAYMENT_DATA: "affiliate/payment-details",
  REQUEST_PAYOUT: "affiliate/requested-payout",
  AUTH_LOGOUT: "affiliate/auth/logout",
};
