import { Col, Row, Typography } from "antd";
import React from "react";

import supportBanner from "../../images/affiliateLandingImages/support-banner.png";
import { listContent } from "./utils";

const { Title } = Typography;

const SupportSection = () => (
  <Row
    className="mt-5"
    id="support-section"
    style={{ fontSize: 18 }}
    gutter={[24, 24]}
    align="middle"
  >
    <Col lg={12} xs={24}>
      <Title level={2}>US Based Support</Title>
      <p>
        We know what it is like to be a contractor because we have been there.
        When you need help we’re just a phone call away allowing you to get your
        problems fixed asap!
      </p>
      {listContent?.map((content) => (
        <p style={{ position: "relative" }}>
          <span
            style={{
              position: "absolute",
              top: 8,
              left: 0,
              height: 5,
              width: 5,
              borderRadius: "100%",
              background: "#3483FA",
            }}
          />
          <span className="mx-3">{content}</span>
        </p>
      ))}
    </Col>
    <Col lg={12} xs={24}>
      <img src={supportBanner} alt="how-it-work-preview" />
    </Col>
  </Row>
);

export default SupportSection;
