import { Button, Col, Row } from "antd";

function CancelNotes({ cancelNotesObj, handleCancel }) {
  return (
    <div className="add-promo-code cencelnotes">
      <h3 className="fliter-sort-title-add-tab" style={{ color: "#484D59" }}>
        Cancelled Notes
      </h3>
      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <h6>
            Please give us a brief explanation on why you are canceling: *
          </h6>
          <p>{cancelNotesObj?.explainCancelingReason}</p>
        </Col>
        <Col xs={24}>
          <h6>
            What features would ZevBit need to have for you to not cancel? *
          </h6>
          <p>{cancelNotesObj?.lakeFeaturesNotes}</p>
        </Col>
        <Col xs={24}>
          <Button
            className="mt-5"
            onClick={handleCancel}
            style={{ width: "100%", borderRadius: "8px" }}
            size="large"
            type="primary"
          >
            Done
          </Button>
        </Col>
      </Row>
    </div>
  );
}
export default CancelNotes;
