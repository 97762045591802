import Loader from "../../components/loader/smallLoader";

export const SmallLoader = () => (
  <>
    <div className="text-center d-flex align-items-center justify-content-center ht-100 w-100">
      <span className="">
        <Loader />
        <p className="mt-2">Loading Please Wait....</p>
      </span>
    </div>
  </>
);
