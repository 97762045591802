import CloseIcon from "@mui/icons-material/Close";
import { FormControl, FormGroup, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { message } from "antd";
import * as React from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} className="border-bottom">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const EditModal = (props) => {
  const { formulaDetails, handleUpdateTitleAndDisplayName } = props;
  const [titles, setTitles] = React.useState({ title: "", displayName: "" });

  React.useEffect(() => {
    if (formulaDetails && Object.keys(formulaDetails).length && props?.open) {
      setTitles({
        title: formulaDetails?.title,
        displayName: formulaDetails?.displayName,
      });
    }
  }, [formulaDetails, props.open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTitles({
      ...titles,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (!titles?.title) {
      message.error("Service Name Is Required.");
      return;
    }
    if (!titles?.displayName) {
      message.error("Display Name Is Required.");
      return;
    }
    handleUpdateTitleAndDisplayName(titles);
  };

  return (
    <>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="edit-modal-radius"
        fullWidth={400}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
        >
          Edit tamplate’s names
        </BootstrapDialogTitle>
        <DialogContent className="pt-3">
          <FormGroup className="input-edit-field">
            <FormControl className="mb-3">
              <label className="form-label">Service name</label>
              <input
                type="text"
                name="title"
                placeholder="Service name"
                value={titles?.title || ""}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className="mb-3">
              <label className="form-label">Display name</label>
              <input
                type="text"
                name="displayName"
                placeholder="Display name"
                value={titles?.displayName || ""}
                onChange={handleChange}
              />
            </FormControl>
          </FormGroup>
        </DialogContent>
        <DialogActions className="px-3">
          <Stack direction="row" spacing={2} className="btn-estimates">
            <Button variant="outlined" onClick={props.handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default EditModal;
