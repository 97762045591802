import { SearchOutlined } from "@ant-design/icons";
import { Avatar, Space, Tag } from "antd";
import moment from "moment";

export const items = [
  {
    id: 1,
    label: "User List",
  },
  {
    id: 2,
    label: "Demo Requests ",
  },
  {
    id: 3,
    label: "Promo Code",
  },
  {
    id: 4,
    label: "Affiliate Marketer",
  },
];

export const promoCodeItems = [
  {
    id: 1,
    label: "Promo Code",
  },
  {
    id: 2,
    label: "Free Trial Code",
  },
];

export const userlistTabsData = [
  {
    id: 1,
    label: "All Users",
    count: "724",
  },
];

export const PromoRowData = [
  {
    key: "1",
    code: "AVZ10",
    discount: "10%",
    plan: "Essential. +2",
    usequantity: "20/200",
    paymentmethod: "Annually",
    startdate: "06/20/2023",
    expirationdate: "No Expiration",
  },
  {
    key: "2",
    code: "ZBS20",
    discount: "10%",
    plan: "Essential",
    usequantity: "20/Unlimited",
    paymentmethod: "Monthly",
    startdate: "06/20/2023",
    expirationdate: "08/20/2023",
  },
];

export const DemoRequestColums = [
  {
    title: "Customer Name",
    dataIndex: "customername",
  },
  {
    title: "Company Name",
    dataIndex: "companyname",
  },
  {
    title: "Phone",
    dataIndex: "phone",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Assigned",
    dataIndex: "assigned",
    render: () => (
      <div className="assigned">
        <Space size={[0, 8]} className="search-assigned">
          <SearchOutlined
            style={{
              fontSize: "18px",
              marginRight: "10px",
              color: "#667085",
            }}
          />

          <Tag
            closable
            icon={
              <Avatar
                size={16}
                src="https://imgv3.fotor.com/images/gallery/Realistic-Male-Profile-Picture.jpg"
              />
            }
          >
            Heidi
          </Tag>
          <Tag
            closable
            icon={
              <Avatar
                size={16}
                src="https://imgv3.fotor.com/images/gallery/AI-3D-Female-Profile-Picture.jpg"
              />
            }
          >
            Marlene
          </Tag>
        </Space>
      </div>
    ),
  },
  {
    title: "Date/Time/Time Zone",
    dataIndex: "timezone",
  },
];

export const demoRequestRowData = [
  {
    key: "1",
    customername: "Charlotte Waters",
    companyname: "Langworth PLC",
    phone: "248-7145-0697",
    email: "zion.funk@sawayn",
    timezone: "06/20/2023 20:00 PST",
  },
  {
    key: "2",
    customername: "Craig G. Orta",
    companyname: "Home Quarters",
    phone: "248-7145-0697",
    email: "zion.funk@sawayn",
    timezone: "06/20/2023 20:00 PST",
  },
  {
    key: "3",
    customername: "Raymond S. Quintero",
    companyname: "Auto Stores",
    phone: "248-7145-0697",
    email: "zion.funk@sawayn",
    timezone: "06/20/2023 20:00 PST",
  },
  {
    key: "4",
    customername: "Donald S. Parker",
    companyname: "Silverwoods",
    phone: "248-7145-0697",
    email: "zion.funk@sawayn",
    timezone: "06/20/2023 20:00 PST",
  },
  {
    key: "5",
    customername: "Randy E. Rodriguez",
    companyname: "Architectural Design",
    phone: "248-7145-0697",
    email: "zion.funk@sawayn",
    timezone: "06/20/2023 20:00 PST",
  },
];

export const AffiliateMarketerDataColumns = [
  {
    title: "Affiliate Name",
    dataIndex: "affiliatename",
  },
  {
    title: "Company Name",
    dataIndex: "companyname",
  },
  {
    title: "Total Referrals",
    dataIndex: "totalreferrals",
  },
  {
    title: "Active Referrals",
    dataIndex: "activereferrals",
  },
  {
    title: "Total Commission",
    dataIndex: "totalcommission",
  },
  {
    title: "Commission Last 30 Days",
    dataIndex: "commissionlast",
    width: 250,
  },
  {
    title: "Last Withdraw",
    dataIndex: "lastwithdraw",
  },
  {
    title: "Wallet Balance",
    dataIndex: "walletbalance",
  },
];

export const AffiliateMarketerDataRow = [1, 2, 3, 4, 5].map(() => ({
  affiliatename: "Martin J. Stover",
  companyname: "Megatronic",
  totalreferrals: "9,999",
  activereferrals: "23",
  totalcommission: "$0.00",
  commissionlast: "$0.00",
  lastwithdraw: "$0.00",
  walletbalance: "01/01/2023",
}));

export const modifyData = (data) => {
  console.log("data ->", data);
  return {
    name: data?.codeName || data?.name,
    id: data?.promoCode || data?.couponCode,
    ...(data?.notes !== "" && { notes: data?.notes }),
    ...(data?.textOfLandingPage !== "" && {
      textOfLandingPage: data?.textOfLandingPage,
    }),
    ...(data?.affiliate &&
      data?.affiliate !== "" && {
        affiliate: data?.affiliate?._id
          ? data?.affiliate?._id
          : data?.affiliate,
      }),
    ...((data?.subscriptionType !== "" ||
      data?.validForSubscriptionInterval !== "") && {
      subscriptionTypes:
        data?.subscriptionType || data?.validForSubscriptionInterval,
    }),
    discountTypes: data?.discountType || data?.discountTypes,
    appliedPlans: data?.plans || data?.validForPlan,
    isForever: data?.numberOfTime === "entireSubscription",
    ...(data?.numberOfTime !== "entireSubscription" && {
      numbersOfTime:
        data?.numberOfTime !== "entireSubscription" &&
        data?.discountType === "percentage" &&
        data?.numberOfTime
          ? data?.numberOfTime
          : 1,
    }),
    discount: Number(data?.discount),
    isUnlimitedQuantity:
      data?.isUnlimitedQuantity || data?.quantityOfPromoCode === "Unlimited",
    ...(!data?.isUnlimitedQuantity &&
      data?.quantity && {
        quantity: Number(data?.quantity),
      }),
    startDate: moment(data?.startDate).utc().toISOString(),
    isActive: true,
    ...(data?.noExpirationDate === false && {
      expireDate: moment(data?.expirationDate).utc().toISOString(),
    }),
    description: data?.description,
    ...((data?.trialDays === "1" ||
      data?.trialDays === "2" ||
      data?.trialDays === "3" ||
      data?.customTrialDays?.length > 0) && {
      trialMonths:
        data?.customTrialDays?.length > 0
          ? Number(data?.customTrialDays)
          : Number(data?.trialDays),
    }),
  };
};
