/* eslint-disable no-unused-vars */
import "../button.css";
import "../invoice-receipt.css";
import "./invoice-detail.css";

import { DownOutlined } from "@ant-design/icons";
import { Button, Card, Col, Dropdown, message, Row } from "antd";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { formatPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../../../api/api";
import defaultCoverImg from "../../../images/defaultCoverPhoto.jpg";
import {
  getEmailTemplatesAction,
  getProjectSummaryDetails,
  getTagsDetails,
  resetSendReceiptAction,
} from "../../../redux/invoice/invoice.action";
import { INVOICE_TYPE } from "../../../redux/invoice/invoice.types";
import { createValidUrl } from "../../../utils/commonFuctions";
import { modifiedTagsValue } from "../../../utils/constants/constants";
import { NumberFormat } from "../../../utils/numberFormat";
import {
  projectSummaryMentions,
  replacePatterns,
} from "../../../utils/suggestions";
import SmallLoader from "../../loader/smallLoader";
import { invoiceEmailCategory, renderTableAndFooter } from "../helper";
import { BackBtn, InvoiceEye, invoiceSendIcon, sendLink } from "../svg.icons";
import CustomModal from "./CustomModal";
import SendCustomerModal from "./SendCustomerModal";
const toolbarOptions = {
  options: [], // Hides all options
};
const SendProjectSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEditNote, setIsEditNote] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const estimationId = new URLSearchParams(location.search).get("estimationId");
  const { projectDetails } = useSelector((state) => state?.invoice);
  const { tags } = useSelector((state) => state?.invoice);
  const [editerStateContent, setEditerStateContent] = useState(
    EditorState.createEmpty(),
  );
  const [files, setFiles] = useState(null);
  const fileInputRef = useRef(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [validFileError, setValidFileError] = useState(false);
  const items = [
    {
      label: "Send Via Email",
      key: "1",
      icon: invoiceSendIcon,
      onClick: () => setCustomerModal(true),
    },
    {
      label: "Copy Shareable Link",
      key: "2",
      icon: sendLink,
      onClick: () => {
        navigator.clipboard.writeText(
          projectDetails.baseDetails?.shareableLink,
        );
        message.success("Shareable Link copied to clipboard");
      },
    },
    {
      label: "Send Link To My Email",
      key: "3",
      icon: (
        <span>
          <InvoiceEye />
        </span>
      ),
      onClick: () => sendSharableLink(),
    },
  ];
  const sendSharableLink = async () => {
    if (!projectDetails.baseDetails?.shareableLink) {
      return toast.error("Link not available");
    }
    const hideLoadingMessage = message.loading("Processing...", 0);
    const response = await api.request({
      url: `v3/settings/email-templates/send-sharable-link`,
      method: "POST",
      data: {
        link: projectDetails.baseDetails?.shareableLink,
        customerName: projectDetails?.baseDetails?.billedToName,
      },
    });
    hideLoadingMessage();
    if (response.remote === "success") {
      message.success("Invoice link sent successfully");
    } else {
      message.error(response.errors?.error || "Something went wrong");
    }
  };
  const handleEditNote = () => {
    setIsEditNote(true);
  };
  // Function to handle saving edited note
  const handleSaveNote = async () => {
    const hideLoadingMessage = message.loading("Processing...", 0);
    // Update the summaryNote in the state
    const payload = {
      ...projectDetails,
      baseDetails: {
        ...projectDetails.baseDetails,
        summaryNote: draftToHtml(
          convertToRaw(editerStateContent.getCurrentContent()),
        )?.replace(/(^|\s|>)(@)/g, ""),
      },
    };
    const response = await api.request({
      url: `v3/invoice/project-summary-note?estimationId=${estimationId}`,
      method: "PUT",
      data: {
        invoiceSummaryNote: replacePatterns(
          draftToHtml(
            convertToRaw(editerStateContent.getCurrentContent()),
          )?.replace(/(^|\s|>)(@)/g, ""),
        ),
      },
    });
    hideLoadingMessage();
    if (response.remote === "success") {
      message.success("Notes updated successfully");
      setIsEditNote(false);
      dispatch({
        type: INVOICE_TYPE.PROJECT_DETAILS,
        payload,
      });
    } else {
      message.error(response.errors.errors.error || "Something went wrong");
    }
  };

  const handleCancel = () => {
    console.log("first...");
    if (fileInputRef?.current) {
      fileInputRef.current = null;
    }
    setFiles(null);
    setCustomerModal(false);
    setFileSizeError(false);
    setValidFileError(false);
  };
  const handleBack = () => {
    dispatch(resetSendReceiptAction());
    navigate(-1);
  };
  const getEmailTemplates = async () => {
    await dispatch(
      getEmailTemplatesAction(invoiceEmailCategory.projectSummary),
    );
    setLoading(false);
  };
  const onEditorStateChange = (editorStateTitle) => {
    setEditerStateContent(editorStateTitle);
  };
  useEffect(() => {
    if (estimationId) {
      dispatch(getProjectSummaryDetails(estimationId));
      getEmailTemplates();
    }
  }, []);
  useEffect(() => {
    if (projectDetails.baseDetails?.summaryNote) {
      const contentBlock = htmlToDraft(
        projectDetails.baseDetails.summaryNote || "",
      );
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const content = EditorState.createWithContent(contentState);
        setEditerStateContent(content);
      }
    }
  }, [projectDetails.baseDetails?.summaryNote]);
  useEffect(() => {
    if (estimationId) {
      dispatch(getTagsDetails(estimationId));
    }
  }, [estimationId]);
  useEffect(() => {
    console.log({
      suggestions: modifiedTagsValue(tags, projectSummaryMentions),
    });
  }, []);
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minHeight: "100vh",
          }}
        >
          <SmallLoader />
        </div>
      ) : (
        <div className="px-2 px-md-0">
          {" "}
          <Row justify="center" className="mt-3 mx-973">
            <Col xs={24} lg={24}>
              <Card bordered={false} className="shadow-card-md">
                <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
                  <div className="d-flex align-items-center">
                    <Button className="clear-filter" onClick={handleBack}>
                      {BackBtn}
                      <span className="ms-2">Back</span>
                    </Button>
                  </div>
                  <Dropdown.Button
                    icon={<DownOutlined style={{ fontSize: "18px" }} />}
                    type="primary"
                    size="large"
                    className="apply-btn send-customer-btn"
                    overlayStyle={{ zIndex: 9999 }}
                    menu={{
                      items,
                    }}
                    overlayClassName="send-list-view"
                    trigger={["click"]}
                    // onClick={handleSendReceiptCustomer}
                    placement="bottomRight"
                  >
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        setCustomerModal(true);
                      }}
                      style={{ fontSize: "14px" }}
                    >
                      {invoiceSendIcon}
                      <span className="ms-2">Send to Customer</span>
                    </span>
                  </Dropdown.Button>
                </div>
              </Card>
              <Card bodyStyle={{ padding: "24px" }} className="mt-3">
                {projectDetails.baseDetails?.estimationCoverPhoto ? (
                  <img
                    alt=""
                    src={
                      projectDetails.baseDetails?.estimationCoverPhoto
                        ? createValidUrl(
                            projectDetails.baseDetails?.estimationCoverPhoto,
                          )
                        : defaultCoverImg
                    }
                    className="rounded-3 w-100"
                  />
                ) : (
                  ""
                )}
                <div className="px-4 pt-4">
                  <img
                    alt="organization-logo"
                    src={
                      projectDetails.baseDetails?.logo
                        ? createValidUrl(projectDetails.baseDetails?.logo)
                        : defaultCoverImg
                    }
                    style={{ width: "155px", height: "74px" }}
                  />
                </div>
                <div className="mt-3 p-4 d-flex align-items-start justify-content-between">
                  <ul className="list-style list-style-m">
                    <li>
                      <span className="font-16">
                        {projectDetails.baseDetails?.name || ""}
                      </span>
                    </li>
                    {projectDetails.baseDetails?.phoneNumber && (
                      <li>
                        {`${formatPhoneNumber(
                          `+${projectDetails.baseDetails?.phoneNumber || ""}`,
                          "NATIONAL",
                        )}`}
                      </li>
                    )}
                    <li>{projectDetails.baseDetails?.email || ""}</li>
                    <li>{projectDetails.baseDetails?.address || ""}</li>
                  </ul>
                </div>
                <div className="p-4 d-flex align-items-start justify-content-between pt-0">
                  <ul className="list-style">
                    <li>
                      <span className="font-16">Billed To:</span>
                    </li>
                    {projectDetails.baseDetails?.billedToPhoneNumber && (
                      <li>
                        {`${formatPhoneNumber(
                          `+${
                            projectDetails.baseDetails?.billedToPhoneNumber ||
                            ""
                          }`,
                          "NATIONAL",
                        )}`}
                      </li>
                    )}
                    <li>
                      {projectDetails.baseDetails?.billedToName || ""} <br />
                      {projectDetails.baseDetails?.billedToAddress || ""}
                    </li>
                  </ul>
                  <ul className="list-style text-right">
                    <li>
                      Total Project <br />
                      {NumberFormat(
                        projectDetails.baseDetails?.totalProjectAmount,
                      )}
                    </li>
                    <li className="mt-2">
                      Project Balance <br />
                      {NumberFormat(
                        projectDetails.baseDetails?.totalProjectAmount -
                          projectDetails.baseDetails?.totalPaidProjectAmount,
                      )}
                    </li>
                    <li className="mt-2">
                      Total Paid <br />
                      {NumberFormat(
                        projectDetails.baseDetails?.totalPaidProjectAmount,
                      )}
                    </li>
                  </ul>
                </div>
                <div className="px-2 invoice-title mt-3">
                  {projectDetails.projectSummaryTable &&
                    renderTableAndFooter({
                      tableData: projectDetails,
                      redirectFrom: invoiceEmailCategory.projectSummary,
                    })}
                </div>
                <div className="px-2 invoice-title mt-3">
                  {projectDetails.remainingPayments &&
                    renderTableAndFooter({
                      tableData: projectDetails,
                      redirectFrom: "remainingPayments",
                    })}
                </div>
                <div
                  className="d-flex flex-wrap gap-2 flex-column-reverse flex-lg-row"
                  style={{ justifyContent: "space-between" }}
                >
                  {projectDetails?.baseDetails?.summaryNote && (
                    <div className="mt-3 w-50 mobile-summary">
                      <p
                        style={{
                          color: "#646973",
                          margin: 0,
                          fontSize: 12,
                          marginBottom: "5px",
                        }}
                      >
                        Project Summary
                      </p>
                      <div
                        className="w-100 border p-3 text-content"
                        style={{
                          borderRadius: 8,
                          maxWidth: 600,
                          minHeight: 150,
                          cursor: "pointer",
                          width: "100%",
                        }}
                      >
                        {!isEditNote ? (
                          <>
                            <div
                              className=""
                              style={{
                                minHeight: "150px",
                                fontSize: "initial",
                                wordBreak: "break-word",
                              }}
                              onClick={() => handleEditNote()}
                              dangerouslySetInnerHTML={{
                                __html: projectDetails.baseDetails.summaryNote,
                              }}
                            />
                          </>
                        ) : (
                          <div>
                            {tags.length && (
                              <Editor
                                editorState={editerStateContent}
                                onEditorStateChange={onEditorStateChange}
                                onBlur={() => handleSaveNote()}
                                toolbar={toolbarOptions}
                                editorStyle={{
                                  minHeight: 150,
                                  cursor: "pointer",
                                }}
                                toolbarHidden
                                mention={{
                                  trigger: "@",
                                  separator: " ",
                                  suggestions: modifiedTagsValue(
                                    tags,
                                    projectSummaryMentions,
                                  ),
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className="summary my-5 mobile-30"
                    style={{ width: "30%" }}
                  >
                    {projectDetails?.baseDetails?.signedEstimate !==
                      undefined && (
                      <div className="summary-item">
                        <span>Signed Estimate</span>
                        <span>
                          {NumberFormat(
                            projectDetails.baseDetails.signedEstimate,
                          )}
                        </span>
                      </div>
                    )}
                    {projectDetails?.baseDetails?.changeOrders !==
                      undefined && (
                      <div className="summary-item">
                        <span>Change Orders</span>
                        <span>
                          {NumberFormat(
                            projectDetails.baseDetails.changeOrders,
                          )}
                        </span>
                      </div>
                    )}
                    {projectDetails?.baseDetails?.credits !== undefined && (
                      <div className="summary-item">
                        <span>Credits</span>
                        <span>
                          {NumberFormat(projectDetails.baseDetails.credits)}
                        </span>
                      </div>
                    )}
                    <hr />
                    {projectDetails?.baseDetails?.total !== undefined && (
                      <div className="summary-item">
                        <span>Total</span>
                        <span>
                          {NumberFormat(projectDetails.baseDetails.total)}
                        </span>
                      </div>
                    )}
                    {projectDetails?.baseDetails?.paymentsReceived !==
                      undefined && (
                      <div className="summary-item">
                        <span>Payments Received</span>
                        <span>
                          {NumberFormat(
                            projectDetails.baseDetails.paymentsReceived,
                          )}
                        </span>
                      </div>
                    )}
                    <hr />
                    {projectDetails?.baseDetails?.remainingBalance !==
                      undefined && (
                      <div className="summary-item">
                        <span style={{ color: "#3483FA" }}>
                          Remaining Balance
                        </span>
                        <span>
                          {NumberFormat(
                            projectDetails.baseDetails?.remainingBalance,
                          )}
                        </span>
                      </div>
                    )}
                    {projectDetails?.baseDetails?.amountDue !== undefined && (
                      <div className="summary-item">
                        <span style={{ color: "#E42626" }}>Amount Due</span>
                        <span>
                          {NumberFormat(projectDetails.baseDetails.amountDue)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </Col>

            {customerModal && (
              <CustomModal
                isModalOpen={customerModal}
                handleCancel={handleCancel}
                closable={false}
                centered
                width={800}
              >
                <SendCustomerModal
                  handleCancel={handleCancel}
                  handleBack={handleBack}
                  action={invoiceEmailCategory.projectSummary}
                  defaultEmail={projectDetails.baseDetails?.email}
                  receiptType="summarySent"
                  files={files}
                  setFiles={setFiles}
                  fileInputRef={fileInputRef}
                  fileSizeError={fileSizeError}
                  setFileSizeError={setFileSizeError}
                  validFileError={validFileError}
                  setValidFileError={setValidFileError}
                />
              </CustomModal>
            )}
          </Row>
        </div>
      )}
    </>
  );
};

export default SendProjectSummary;
