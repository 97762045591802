import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Input, Row, Typography } from "antd";
import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useRef } from "react";

import { presets } from "../../../../../utils/constants/constants";

const { Text } = Typography;

const { RangePicker } = DatePicker;

const RangePickerFilter = ({
  value,
  openRangeSelector,
  handleApplyClick,
  handleRangeToggle,
  handleRangeChange,
  changePos = false,
  className = "",
  setOpenRangeSelector,
}) => {
  const dateRangeRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target)
      ) {
        setOpenRangeSelector(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dateRangeRef}>
      <RangePicker
        ranges={presets}
        open={openRangeSelector}
        value={value}
        className={classNames("promo-code-filter", { [className]: className })}
        popupClassName={classNames("promo-code-range-filter", {
          "promo-code-range-filter-pos": changePos,
        })}
        disabledDate={(current) =>
          current && current.isAfter(moment().endOf("day"))
        }
        suffixIcon={openRangeSelector ? <UpOutlined /> : <DownOutlined />}
        renderExtraFooter={() => (
          <Row
            className="py-3 promocode-internal-modal"
            justify="space-between"
          >
            <Col
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Col
                style={{
                  width: "45%",
                }}
              >
                <Input
                  style={{ borderRadius: 8 }}
                  value={
                    value?.[0]
                      ? moment(value?.[0]).format("ddd, MMM DD, YYYY")
                      : ""
                  }
                />
              </Col>
              <Col xs={2} className="text-center">
                <Text>-</Text>
              </Col>
              <Col style={{ width: "45%" }}>
                <Input
                  style={{ borderRadius: 8 }}
                  value={
                    value?.[1]
                      ? moment(value?.[1]).format("ddd, MMM DD, YYYY")
                      : ""
                  }
                />
              </Col>
            </Col>
            <Col
              style={{
                width: "35%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Col style={{ width: "45%" }}>
                <Button
                  className="my-auto w-100"
                  onClick={() => handleRangeToggle(false)}
                  type="default"
                >
                  Cancel
                </Button>
              </Col>
              <Col style={{ width: "45%" }}>
                <Button
                  className="my-auto w-100 filter-apply-button p-0"
                  type="primary"
                  onClick={handleApplyClick}
                >
                  Apply
                </Button>
              </Col>
            </Col>
          </Row>
        )}
        onClick={() => handleRangeToggle(!openRangeSelector)}
        onCalendarChange={handleRangeChange}
        onOk={handleRangeChange}
      />
    </div>
  );
};

export default RangePickerFilter;
