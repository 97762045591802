import { Select } from "antd";
import React from "react";

import { countryList } from "../../utils/constants/constants";

const CountryDropdown = ({
  value,
  handleCountryChange,
  extraClassName,
  label = "",
}) => (
  <>
    <label htmlFor="countrySelect" className="ant-label-login">
      {label || "Country"}
    </label>
    <Select
      className={`w-100 countryDropdown ${extraClassName}`}
      value={value || ""}
      onChange={handleCountryChange}
      id="countrySelect"
      name="country"
    >
      <Select.Option value="" disabled>
        Select Country
      </Select.Option>
      {countryList.map((obj) => (
        <Select.Option value={obj?.value}>{obj?.label}</Select.Option>
      ))}
    </Select>
  </>
);

export default CountryDropdown;
