import { DeleteOutlined } from "@ant-design/icons";
import React from "react";

import ReactMentionInput from "../../../../utils/mentionInput/mentionInput";
import ConditionalInput from "../elementCard/conditionInput";
function ClientContractV2({
  clientContractV2,
  setClientContractV2,
  elementList,
  hiddenValueList,
  getElementCustomFields,
  processedLineItems,
  isUpdatedLineItemName,
  setIsUpdatedLineItemName,
  toggleLineItemNameUpdate,
}) {
  const inputRefs = React.useRef([]);
  const [remountCondition, setRemountCondition] = React.useState(false);
  const [contract, setContract] = React.useState([]);

  const [updatedProcessedElementList, setUpdateProcessedElementList] =
    React.useState([]);

  const handleInputRef = (element, index) => {
    inputRefs.current[index] = element;
  };

  const handleBlur = () => {
    const newContract = contract.map((contract) => {
      delete contract.tempId;
      return contract;
    });
    setClientContractV2(newContract);
  };

  React.useEffect(() => {
    if (clientContractV2 && clientContractV2.length) {
      setContract(
        clientContractV2.map((contract, idx) => {
          if (!contract._id) {
            contract.tempId = idx;
          }
          return contract;
        }),
      );
    }
  }, [clientContractV2]);

  React.useEffect(() => {
    if (elementList && getElementCustomFields) {
      const newProcessedElementList = [];
      elementList.forEach((element) => {
        if (element.type === "dropdown") {
          const elementCustomFields = getElementCustomFields(element._id);
          if (elementCustomFields) {
            const { customFields } = elementCustomFields;
            customFields.forEach((fields) => {
              newProcessedElementList.push({
                display: `${element.name} - ${fields.fieldName}`,
                id: `${element._id}-${fields.superId || fields._id}`,
              });
            });
            newProcessedElementList.push({
              display: element.name,
              id: element._id,
            });
          }
        } else {
          const newElement = {
            display: element.name,
            id: element._id,
          };
          newProcessedElementList.push(newElement);
        }
        if (element.customInput) {
          element.customInput.forEach((customInput) => {
            newProcessedElementList.push({
              display: `${element.name} - ${customInput.name}`,
              id: `${element._id}-${customInput.id}`,
            });
          });
        }
      });
      setUpdateProcessedElementList(newProcessedElementList);
    }
  }, [elementList, getElementCustomFields]);

  function replaceLineItems(clientContract) {
    for (let i = 0; i < clientContract.length; i++) {
      const { content } = clientContract[i];

      // Use a single regex to match all occurrences and perform the replacement
      clientContract[i].content = content.replace(
        /\^{{lineItems\|\|([^|]*)\|\|([^}]*)}}/g,
        (match, id) => {
          const replacement = isUpdatedLineItemName[id];
          return replacement ? `^{{lineItems||${id}||${replacement}}}` : match;
        },
      );
    }

    return clientContract;
  }
  React.useEffect(() => {
    if (Object.keys(isUpdatedLineItemName).length) {
      // update line items name in client contracts
      const newClientContract = replaceLineItems(
        [...clientContractV2],
        processedLineItems,
      );
      setIsUpdatedLineItemName({});
      setClientContractV2(newClientContract);
    }
  }, [toggleLineItemNameUpdate]);

  return (
    <table className="table ant-furmulla-table w-100">
      <thead>
        <tr>
          <th colSpan="9">
            <span>Enter Wording - Client Contract V2</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {contract?.map((item, index) => (
          <tr key={index}>
            <td className="py-1" style={{ width: "33%" }}>
              {!remountCondition ? (
                <ConditionalInput
                  hiddenValueList={hiddenValueList}
                  elementList={elementList}
                  element={item}
                  handleChange={(value) => {
                    // this function is only running when input is blurred
                    const newContractLine = { ...item };
                    newContractLine.conditions = value;
                    contract[index] = newContractLine;
                    setContract([...contract]);
                    handleBlur();
                  }}
                  placeholder="Provide a Condition or Leave Blank for No Condition"
                />
              ) : (
                ""
              )}
            </td>
            <td className="py-1">
              <ReactMentionInput
                inputRef={(element) => handleInputRef(element, index)}
                className="ant-furmulla-input px-2 outline w-200"
                elementList={updatedProcessedElementList}
                lineItemsList={processedLineItems}
                hiddenInputList={hiddenValueList.map((hiddenValue) => ({
                  display: hiddenValue.name,
                  id: hiddenValue._id,
                }))}
                onChange={(e) => {
                  const newContractLine = { ...item };
                  newContractLine.content = e.target.value;
                  contract[index] = newContractLine;
                  setContract([...contract]);
                }}
                placeholder="Enter Client Contract"
                value={item.content}
                onBlur={handleBlur}
                onKeyDown={(e) => {
                  const keyCode = e.which ? e.which : e.keyCode;
                  if (keyCode === 13) {
                    e.preventDefault();
                    setRemountCondition(true);
                    // for `Enter` key press
                    const newContractList = [...contract];
                    newContractList.splice(index + 1, 0, {
                      tempId: Date.now(),
                      isConditional: false,
                      content: "",
                      conditions: {
                        expression: "",
                        element: "",
                      },
                    });
                    setContract(newContractList);
                    setTimeout(() => {
                      if (inputRefs.current[index + 1]) {
                        inputRefs.current[index + 1].focus();
                      }
                    });
                    setTimeout(() => {
                      setRemountCondition(false);
                    }, 500);
                  }
                }}
              />
            </td>
            {contract.length !== 1 ? (
              <td className="py-1" style={{ width: "1%" }}>
                <span
                  className="delect"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setRemountCondition(true);
                    setContract((prevState) => {
                      const newState = prevState.filter(
                        (contract) =>
                          contract._id !== item._id ||
                          contract.tempId !== item.tempId,
                      );
                      return newState;
                    });
                    if (setClientContractV2) {
                      setClientContractV2((prevState) => {
                        const newState = prevState.filter(
                          (contract) =>
                            contract._id !== item._id ||
                            contract.tempId !== item.tempId,
                        );
                        return newState;
                      });
                    }
                    setTimeout(() => {
                      setRemountCondition(false);
                    }, 500);
                  }}
                >
                  <DeleteOutlined className="text-danger" />
                </span>
              </td>
            ) : (
              ""
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ClientContractV2;
