import "./overview.css";
import "bootstrap/dist/css/bootstrap.css";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Input, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as MyReferralsFilterIcon } from "../../../../../images/affiliateImages/my-referral-filter.svg";
import { ReactComponent as MyReferralsIcon } from "../../../../../images/affiliateImages/my-referrals.svg";
import {
  getMyReferralsAction,
  // saveReferralFilters,
} from "../../../../../redux/affiliate/affiliate.action";
import {
  initialActions,
  promoCodeStatus,
  promoCodeStatusColor,
} from "../../../../../utils/constants/constants";
import { formatDate } from "../../../../setting/UserListV2";
import TableData from "../../../../setting/UserListV2/Table";
import {
  convertLinksCode,
  convertToFloating,
  subscriptionPlanName,
} from "../../helper";
import AffiliateFilter from "./AffiliateFilter";

const { Title } = Typography;

const MyReferrals = ({ isSuperAdmin = false }) => {
  const [sortOrder, setSortOrder] = useState();
  const [sortFields, setSortFields] = useState();
  const [toggleModal, setToggleModal] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 400px)").matches,
  );
  useEffect(() => {
    window
      .matchMedia("(max-width:400px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const history = useNavigate();
  const [openRangeSelector, setOpenRangeSelector] = useState(false);
  const myReferralsTblData = useSelector(
    (state) => state?.affiliate?.myReferrals,
  );
  const [currentReferralPage, setCurrentReferralPage] = useState(1);
  const dispatch = useDispatch();

  const getBackgroundColor = (status) =>
    promoCodeStatusColor[status?.toLowerCase()] || "#FFBC44";

  const affiliateId = useSelector((state) => state?.affiliate?.profile?._id);

  const filterDetails = useSelector(
    (state) => state?.affiliate?.["myReferral-filters"],
  );

  const promoCodeDetailColumns = [
    {
      ...(isSuperAdmin
        ? {
            title: "MA Name",
            dataIndex: "maName",
            sorter: (a, b) => a?.maName - b?.maName,
          }
        : {
            title: "Account #",
            dataIndex: "companyNumber",
            sorter: (a, b) => a?.companyNumber - b?.companyNumber,
          }),
    },
    {
      title: "Link/ Code",
      dataIndex: "linkOrCode",
      render: (v) => (
        <a
          {...{
            ...((v?.includes("https://") || v?.includes("http://")) && {
              href: v,
            }),
          }}
        >
          {convertLinksCode(v) || "-"}
        </a>
      ),
      sorter: (a, b) => a?.linkOrCode?.localeCompare(b?.linkOrCode),
      className: "text-center",
    },
    {
      title: "Initial Action",
      dataIndex: "initialAction",
      render: (v) => initialActions?.[v] || v,
      sorter: (a, b) => a?.initialAction?.localeCompare(b?.initialAction),
      className: "text-center",
    },
    {
      title: "Date Of Action",
      dataIndex: "dateOfAction",
      render: (v) => `${formatDate(v)}`,
      sorter: (a, b) => new Date(a?.dateOfAction) - new Date(b?.dateOfAction),
      className: "text-center",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (v) => (
        <span style={{ color: getBackgroundColor(v) }}>
          {promoCodeStatus[v] || v}
        </span>
      ),
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      className: "text-center",
    },
    {
      title: "Active Mths",
      dataIndex: "activeMonth",
      sorter: (a, b) => a?.activeMonth || 0 - b?.activeMonth || 0,
      className: "text-center",
      render: (v) => v || "-",
    },
    {
      title: "Current Plan",
      dataIndex: "currentPlan",
      sorter: (a, b) => a?.currentPlan?.localeCompare(b?.currentPlan),
      render: (v) => subscriptionPlanName?.[v] || "-",
      className: "text-center",
    },
    {
      title: "Comm'n %",
      dataIndex: "commission",
      sorter: (a, b) => a?.commission - b?.commission,
      className: "text-center",
      render: (v) => (v ? `${v}%` : "-"),
    },
    {
      title: "Mthly Comm'n",
      dataIndex: "monthlyCommission",
      sorter: (a, b) => a?.monthlyCommission - b?.monthlyCommission,
      className: "text-center",
      render: (v) => `$${convertToFloating(v)}`,
    },
    {
      title: "Total Comm'n",
      dataIndex: "totalCommission",
      sorter: (a, b) => a?.totalCommission - b?.totalCommission,
      className: "text-center",
      render: (v) => `$${convertToFloating(v)}`,
    },
  ];

  const [searchValue, setSearchValue] = useState();

  const handleSearch = ({ target: { value } }) => {
    let payload = {
      page: 1,
      perPage: 10,
      ...(isSuperAdmin && { affiliate: affiliateId }),
      ...(filterDetails && { ...filterDetails }),
    };
    if (value) {
      payload = {
        ...payload,
        search: value,
      };
      setSearchValue(value);
    } else {
      setSearchValue("");
    }
    dispatch(getMyReferralsAction(payload, isSuperAdmin));
  };

  const handleTableChange = (_, __, sorter) => {
    const { field, order } = sorter;
    setSortFields(field);
    setSortOrder(order);
  };

  const handleRowClick = (row) => ({
    onClick: (e) => {
      e.stopPropagation();
      history(
        isSuperAdmin
          ? `/v3/affiliate-marketing/my-referrals/profile/${row?.organizationId}`
          : `/affiliate/profile/${row?.companyNumber}`,
      );
    },
  });

  const toggleFilterModal = () => {
    if (toggleModal && openRangeSelector) setOpenRangeSelector(false);
    setToggleModal((prevState) => !prevState);
  };

  const callReferralAPI = (filters, isSuperAdmin) => {
    const payload = {
      page: currentReferralPage,
      perPage: myReferralsTblData?.pagination?.[0]?.perPageCount || 10,
      ...(sortFields && { fieldName: sortFields }),
      ...(isSuperAdmin && { affiliate: affiliateId }),
      order: sortOrder,
      ...(filters && { ...filters }),
    };
    dispatch(getMyReferralsAction(payload, isSuperAdmin));
  };

  useEffect(() => {
    if (myReferralsTblData?.data?.length) {
      callReferralAPI(filterDetails?.[affiliateId], isSuperAdmin);
    }
  }, [currentReferralPage, sortFields, sortOrder]);

  const filterData = JSON.parse(localStorage.getItem("referral-filter"))?.[
    affiliateId
  ];

  return (
    <>
      <Row gutter={[24, 16]} className="mt-5">
        <Col xs={24}>
          <div className="d-flex align-items-center justify-content-between justify-content-between flex-wrap gap-3">
            <Title
              level={4}
              style={{ fontWeight: "normal", margin: "auto 0px" }}
            >
              <MyReferralsIcon style={{ marginRight: 5, color: "#3483FA" }} />
              <span>My Referrals</span>
            </Title>
            <div className="d-flex flex-wrap gap-3 flex-lg-nowrap">
              <Button
                className="full-width-s"
                size="large"
                style={{ borderRadius: "8px", marginRight: 10 }}
                onClick={toggleFilterModal}
              >
                <MyReferralsFilterIcon
                  style={{ stroke: "#667085", marginRight: 10 }}
                />
                Filters{" "}
                {!!Object.keys(filterData || {})?.length && (
                  <span className="dot" />
                )}
              </Button>
              <Input
                size="large"
                className="btn-filter"
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "18px" }} />}
                name="searchPromo"
                id="searchCode"
                autoComplete="new-password"
                onChange={handleSearch}
                value={searchValue}
              />
            </div>
          </div>
        </Col>
        <Col xs={24}>
          <div className="tablecard">
            <TableData
              className="my-referrals-tbl"
              columns={promoCodeDetailColumns}
              dataSource={myReferralsTblData?.data}
              currentPage={currentReferralPage}
              setCurrentPage={setCurrentReferralPage}
              perPage={myReferralsTblData?.pagination?.[0]?.perPageCount}
              paginationData={myReferralsTblData?.pagination?.[0]}
              onRow={handleRowClick}
              scroll={{ x: 500 }}
              onChange={handleTableChange}
            />
          </div>
        </Col>
      </Row>
      <Drawer
        open={toggleModal}
        onClose={toggleFilterModal}
        placement="left"
        closable={false}
        destroyOnClose
        afterOpenChange={() => setOpenRangeSelector(false)}
        width={matches && 290}
      >
        <AffiliateFilter
          openRangeSelector={openRangeSelector}
          setOpenRangeSelector={setOpenRangeSelector}
          toggleFilterModal={toggleFilterModal}
          page={currentReferralPage}
          perPage={myReferralsTblData?.pagination?.[0]?.perPageCount || 10}
          sortFields={sortFields}
          sortOrder={sortOrder}
          isSuperAdmin={isSuperAdmin}
          search={searchValue}
        />
      </Drawer>
    </>
  );
};

export default MyReferrals;
