import api from "./api";

export const visitLink = async (payload) => {
  const response = await api.request({
    url: "/v3/affiliate/referral/landing/click",
    method: "POST",
    data: payload,
    token: localStorage.getItem("token"),
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
};

export const getLandingPromoCodeDetails = async (promoCode) => {
  const response = await api.request({
    url: `/v3/affiliate/referral/landing/note?promoCode=${promoCode}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
};

export const submitLeadForm = async (data) => {
  const response = await api.request({
    url: `v3/affiliate/referral/landing/lead-form`,
    method: "POST",
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return {
    remote: response.remote,
    error: response.errors?.errors?.error,
  };
};
