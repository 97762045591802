import { USER_ROLE } from "../../../../utils/constants/constants";
import {
  crmNew,
  dashboard,
  dot,
  estimating,
  hrms,
  invoicingIcon,
  job,
  promocode,
  setting,
  template,
  userListIcon,
} from "../../../../utils/svg.file";

export const NAVBAR_MENU = ({
  userRole,
  isAdmin,
  superId,
  subscriptionDetails,
}) => [
  {
    key: "dashboard",
    title: "Dashboard",
    url: "/Maindashboard",
    type: "item",
    icon: dashboard,
    disabled: true,
  },
  {
    key: "userList",
    title: "User List",
    url: "/v2/user-list",
    type: "item",
    icon: userListIcon,
    hide:
      userRole === USER_ROLE.superAdmin
        ? false
        : userRole === USER_ROLE.salesPerson && !superId
        ? false
        : !(userRole === USER_ROLE.admin && !superId),
  },
  {
    key: "promoCode",
    title: "Promo Code",
    url: "/v2/promo-code",
    type: "item",
    icon: promocode,
    hide:
      userRole === USER_ROLE.superAdmin
        ? false
        : !(userRole === USER_ROLE.admin && !superId),
  },
  {
    key: "affiliateMarketing",
    title: "Affiliate Marketing",
    url: "/v3/affiliate-marketing",
    type: "item",
    icon: promocode,
    hide: userRole !== USER_ROLE.superAdmin,
  },
  {
    key: "crm",
    title: "CRM",
    url: "/v2/crm",
    type: "item",
    icon: crmNew,
    disabled: false,
    hide: userRole === USER_ROLE.salesPerson && !superId,
  },
  {
    key: "estimationDashboard",
    title: "Estimate Dashboard",
    url: "/v2/estimating",
    type: "item",
    icon: estimating,
    disabled: false,
    hide: userRole === USER_ROLE.salesPerson && !superId,
  },
  {
    key: "invoiceDashboard",
    title: "Invoice Dashboard",
    url: "/invoice-dashboard",
    type: "item",
    icon: (
      <div>
        {invoicingIcon}{" "}
        <div
          style={{
            background: "linear-gradient(to right, #3483fa 0%, #71f69d 100%)",
            color: "#fff",
            borderRadius: 5,
            display: "inline-block",
            position: "absolute",
            left: "27px",
            top: "-11px",
            lineHeight: "12px",
            padding: "5px",
          }}
        >
          New
        </div>
      </div>
    ),
    checkPermission: "INVOICE",
    hide: userRole === USER_ROLE.salesPerson,
  },
  {
    key: "jobCosting",
    title: "Job Costing",
    url: "/job-costing",
    type: "item",
    icon: job,
    disabled: true,
  },

  {
    key: "settings",
    title: "Settings",
    type: "subItem",
    icon: setting,
    className: "ps-0",
    hide: !isAdmin,
    child: [
      {
        key: "emailSettings",
        title: "Email Settings",
        url: "/email-setting",
        type: "item",
        icon: dot,
        hide: ![USER_ROLE.superAdmin, USER_ROLE.masterAdmin].includes(userRole),
      },
      {
        key: "catalog",
        title: "Catalog",
        url: "/catalog?type=materials",
        className: "me-2 dot-align",
        type: "item",
        icon: dot,
      },
      {
        key: "estimateTemplates",
        title: "Estimate Templates",
        url: "/estimate-templates",
        type: "item",
        icon: dot,
        className: "me-2 dot-align",
      },
      {
        key: "userList",
        title: "User List",
        url: "/userlist",
        type: "item",
        className: "me-2 dot-align",
        icon: dot,
        hide: userRole !== USER_ROLE.superAdmin, // show the organization list for super admin
      },
      {
        key: "companySettings",
        title: "Company Settings",
        url: "/company-settings",
        type: "item",
        icon: dot,
        className: "me-2 dot-align",
      },
      {
        key: "paymentTerms",
        title: "Payment Terms",
        url: "/payment-terms",
        type: "item",
        icon: dot,
        className: "me-2 dot-align",
      },
      {
        key: "termAndCondition",
        title: "Term And Condition",
        url: "/term-and-conditions",
        type: "item",
        icon: dot,
        className: "me-2 dot-align",
        disabled: false,
      },
      {
        key: "invoicesetting",
        title: "Invoice Settings",
        url: "/invoice-setting",
        type: "item",
        icon: dot,
        className: "me-2 dot-align",
        checkPermission: "SETTING_INVOICE",
        needToCheckPermissions: true,
        hide: !(
          subscriptionDetails?.permissions?.SETTING_INVOICE?.read ||
          subscriptionDetails?.permissions?.SETTING_INVOICE?.write
        ),
      },
      {
        key: "coverNotes",
        title: "Cover Notes",
        url: "/cover-notes",
        type: "item",
        icon: dot,
        className: "me-2 dot-align",
      },
      {
        key: "setUpQuestions",
        title: "New Lead Questions",
        url: "v2/setup-question",
        type: "item",
        icon: dot,
        className: "me-2 dot-align",
      },
      {
        key: "generalSettings",
        title: "Status Settings",
        url: "/general-settings",
        type: "item",
        icon: dot,
        className: "me-2 dot-align",
      },
      {
        key: "notificationTemplates",
        title: "Notification Templates",
        url: "/notification-templates",
        type: "item",
        icon: dot,
        className: "me-2 dot-align",
      },
    ],
  },
  {
    key: "template",
    icon: template,
    title: "Template",
    url: "/template",
    disabled: true,
    type: "item",
  },
  {
    key: "hrms",
    icon: hrms,
    title: "HRMS",
    url: "/hrms",
    type: "item",
    disabled: userRole === USER_ROLE.salesPerson,
  },
];
