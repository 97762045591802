import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { handleUploadImageAction } from "../../../../../api/admin";
import { AFFILIATE_TYPES } from "../../../../../redux/affiliate/affiliate.types";

const ConfirmCard = ({
  formData,
  setFormModal,
  setConfirmModal,
  setLoader,
  setIsCardDeleted,
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  const dispatch = useDispatch();
  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const imageDataURL = e.target.result;
      setImageUrl(imageDataURL);
      const imagePreview = document.getElementById("imagePreview");
      imagePreview.src = imageDataURL;
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {
    handleFileUpload(formData?.idCard);
  }, [formData?.idCard]);

  const handleUploadImage = async () => {
    setLoader(true);
    const data = new FormData();
    data.append("idCard", formData?.idCard);
    const payload = data;
    const result = await handleUploadImageAction(payload);
    if (result?.remote === "success") {
      setLoader(false);
      dispatch({
        type: AFFILIATE_TYPES.DOCUMENT_DETAIL,
        payload: {
          ...result?.data?.data,
        },
      });
      setIsCardDeleted(false);
    } else {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="add-promo-code cencelnotes">
        <Row gutter={[0, 20]}>
          <Col xs={24}>
            <div className="conform-Id-para pb-0">
              Are you sure you want to use this photo? Make sure the photo is
              not blurry.
            </div>
          </Col>
          <Col xs={24} className="conform-Id-image">
            <div>
              <img id="imagePreview" src={imageUrl} alt="Preview" />
            </div>
          </Col>
          <Row className="upload-id-two-buttons">
            <Col xs={10}>
              <Button
                onClick={() => {
                  setConfirmModal(false);
                  setFormModal((prev) => ({ ...prev, idCardModal: true }));
                }}
                style={{ width: "100%", borderRadius: "8px" }}
                size="large"
                type="primary"
              >
                Go Back
              </Button>
            </Col>
            <Col xs={10}>
              <Button
                onClick={() => {
                  setConfirmModal(false);
                  handleUploadImage();
                }}
                style={{ width: "100%", borderRadius: "8px" }}
                size="large"
                type="primary"
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
};

export default ConfirmCard;
