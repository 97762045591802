import { Button, Col, Form, Row } from "antd";

function DeletePromoCode({ handleCancel, handleConfirmDelete }) {
  return (
    <div className="add-promo-code">
      <h3 className="fliter-sort-title">Warning!</h3>
      Warning! Are you sure you want to delete this Promo Code? Remember that
      people who obtained this promo code will not be able to use it in the
      future.
      <Form layout="vertical">
        <Row gutter={[24, 0]}>
          <Col xs={12}>
            <Button
              onClick={handleCancel}
              style={{ width: "100%", borderRadius: "8px" }}
              size="large"
            >
              Cancel
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              style={{ width: "100%", borderRadius: "8px" }}
              size="large"
              onClick={handleConfirmDelete}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export default DeletePromoCode;
