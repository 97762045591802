import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { sendOTP } from "../../../../../api/affiliate";
import ModalDailog from "../../../../../common/components/ModalDialogue";
import { setLoading } from "../../../../../redux/affiliate/affiliate.action";
import { passwordValidator } from "../../helper";
import ConfirmOTP from "./ConfirmOTP";

const UpdateEmail = ({ email, handleCancel }) => {
  const [form] = Form.useForm();
  const [openOTP, setOpenOTP] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const dispatch = useDispatch();
  const handleOpenOTP = () => {
    setOpenOTP(true);
    handleCancel();
  };
  const handleCloseOTP = () => {
    setOpenOTP(false);
  };
  const handleSubmit = async (data) => {
    if (data) {
      const response = await sendOTP("update-email", data, dispatch);
      if (response.remote === "success") {
        setNewEmail(data?.newEmail);
        dispatch(setLoading(false));
        handleOpenOTP();
      } else {
        dispatch(setLoading(false));
        form.setFields([
          { name: ["password"], errors: [response?.errors?.errors?.error] },
        ]);
      }
    }
  };

  return (
    <div>
      <span>
        To update your email address you must enter your new email address and
        password, then you must verify the link that we will send to your
        current email address.
      </span>
      <Form
        form={form}
        layout="vertical"
        onFinish={(data) => handleSubmit(data)}
        autoComplete="off"
      >
        <Form.Item
          label="New Email"
          rules={[{ type: "email", message: "Please enter valid email" }]}
          name="newEmail"
          style={{ fontWeight: 500 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Current Password"
          name="password"
          style={{ fontWeight: 500 }}
          rules={[
            () => ({
              validator: (_, value) => passwordValidator(value),
            }),
          ]}
        >
          <Input.Password name="password" autoComplete="new-password" />
        </Form.Item>
        <Form.Item className="forFlex-buttons">
          <Button className="w-50" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button
            className="w-50"
            type="primary"
            onClick={() => handleSubmit()}
            htmlType="submit"
          >
            Update Email
          </Button>
        </Form.Item>
      </Form>
      <ModalDailog
        isModalOpen={openOTP}
        handleCancel={handleCloseOTP}
        closable={false}
        title="Update Email"
        className="Email-Popup Email-OTP"
      >
        <ConfirmOTP
          email={email}
          handleCloseOTP={handleCloseOTP}
          codeEndpoint="update-email"
          newEmail={newEmail}
        />
      </ModalDailog>
    </div>
  );
};

export default UpdateEmail;
