import { FORMULA_MATERIAL_TYPES } from "../../components/formula/v2/helper";
import { userEstimationStatus } from "../constants/constants";
import { NumberFormat } from "../numberFormat";
import regex from "../regex";
import { calculateProfitPercentage } from "./estimate";

function escapeRegExp(string) {
  return (string || "").replace(regex.escapeRegex, "\\$&"); // $& means the whole matched string
}

export const generateRandomId = () =>
  Date.now() + Math.floor(Math.random() * 100000);

/**
 * @description this function will evaluate the hidden values of the formula
 * @param {object} expression - the expression object = {
 *          name: string,
 *          value: string?,
 *          isConditional: boolean,
 *          expression: {
 *            condition: string,
 *            fullfill: string,
 *            fail: string
 *         }
 *       }
 */
export const processConditionalExpression = (expression) => {
  const expressionTest = regex.conditionalExpression.test(expression);
  if (expressionTest) {
    const condition = expression;
    const splited = condition.split(regex.operationRegex);
    let operation = condition.match(regex.operationRegex);
    operation = operation ? operation[0].trim() : null;
    let num1 = 0;
    let num2 = 0;
    try {
      num1 = Number(eval(splited[0]));
    } catch (error) {}
    try {
      num2 = Number(eval(splited[1]));
    } catch (error) {}
    const result = evaluateCondition(num1, num2, operation);
    return result;
  }
  return null;
};
export const processNonConditionalHiddenValues = (
  hiddenValue,
  hiddenValueList,
) => {
  const usedHiddenValueList = hiddenValueList.map((item) => ({
    title: `@{{hidden||${item._id}||${item.name}}}`,
    value: item.calculatedValue,
  }));
  let valueToUpdate = hiddenValue.value;
  usedHiddenValueList.forEach((item) => {
    const newReg = new RegExp(escapeRegExp(item.title), "i");
    valueToUpdate = (valueToUpdate || "").replace(newReg, item.value);
  });
  hiddenValue.calculatedValue = valueToUpdate;
  return hiddenValue;
};
/**
 *  @description this function will evaluate the expression
 * @param {number} num1
 * @param {number} num2
 * @param {string} operation
 * @returns boolean
 */
function evaluateCondition(num1, num2, operation) {
  let res = false;
  switch (operation) {
    case "<":
      res = num1 < num2;
      break;
    case ">":
      res = num1 > num2;
      break;
    case ">=":
      res = num1 >= num2;
      break;
    case "<=":
      res = num1 <= num2;
      break;
    case "=":
      res = num1 === num2;
      break;
    default:
      res = false;
      break;
  }
  return res;
}
const sign = ["+", "-", "*", "/"];
export const validateArthmaticString = (str) => {
  if (!str) {
    return { isValid: false, idx: 0 };
  }
  const validBracket = validateBrackets(str);
  if (!validBracket.isValid) {
    return {
      isValid: false,
      message: "invalid breacket",
      idx: validBracket.idx,
    };
  }
  const isValid = validateSign(str);
  return isValid;
};

const validateBrackets = (str) => {
  const bracketStack = [];
  for (let i = 0; i < str.length; i += 1) {
    const val = str[i];
    if (val === "(") {
      bracketStack.push({ val, idx: i });
    } else if (val === ")") {
      if (bracketStack.length) {
        bracketStack.pop();
        continue;
      } else {
        return { isValid: false, idx: i };
      }
    }
  }
  if (bracketStack.length) {
    return { isValid: false, idx: bracketStack[bracketStack.length - 1].idx };
  }
  return { isValid: true };
};

const validateSign = (str) => {
  if (sign.includes(str[0]) || sign.includes(str[str.length - 1])) {
    return { isValid: false };
  }
  let prev = "";
  for (let i = 0; i < str.length; i += 1) {
    if (
      (sign.includes(prev) && sign.includes(str[i])) ||
      (sign.includes(prev) && str[i] === ")")
    ) {
      return { isValid: false };
    }
    if (str[i].trim() !== "") {
      prev = str[i];
    }
  }
  return { isValid: true };
};
const getElementFinalValue = (element, value = 0) => {
  if (!element) return 0;
  if (element.shouldHide) {
    return 0;
  }
  return element.finalCalculatedValue !== undefined
    ? (
        element.finalCalculatedValue *
        (element.multiplicationFactor === undefined ||
        element.multiplicationFactor === null
          ? 1
          : Number(element.multiplicationFactor))
      ).toString()
    : `${value || 0} * ${
        element.multiplicationFactor === undefined ||
        element.multiplicationFactor === null
          ? 1
          : element.multiplicationFactor
      }`;
};
export const processElements = (formula, elements, totalServiceCharge) => {
  const usedElements = [];
  elements.forEach((element) => {
    const selectedVariationCustomFields = element.selected?.customFields;
    if (element.type === "dropdown" && selectedVariationCustomFields) {
      selectedVariationCustomFields.forEach((variationCustomField) => {
        const customFieldWithSuperId = {
          title: `@{{element||${element._id}-${
            variationCustomField?.superId || variationCustomField?._id
          }||${element.name} - ${variationCustomField?.fieldName}}}`,
          price: getElementFinalValue(element, variationCustomField.fieldValue),
          usedMaterials: element.formula,
          customInput: [
            ...(element.customInput?.map((customInput) => ({
              title: `@{{custom||${customInput._id}||${customInput.name}}}`,
              value: customInput.value,
            })) || []),
            ...(element.customInput?.map((customInput) => ({
              title: `@{{custom||${customInput.id}||${customInput.name}}}`,
              value: customInput.value,
            })) || []),
          ],
        };
        const withOutSuperId = {
          ...customFieldWithSuperId,
          title: `@{{element||${element._id}-${variationCustomField?._id}||${element.name} - ${variationCustomField?.fieldName}}}`,
        };
        usedElements.push(customFieldWithSuperId);
        usedElements.push(withOutSuperId);
      });
      usedElements.push({
        title: `@{{element||${element._id}||${element.name}}}`,
        price: getElementFinalValue(element, element.value),
        usedMaterials: element.formula,
        customInput: [
          ...(element.customInput?.map((customInput) => ({
            title: `@{{custom||${customInput._id}||${customInput.name}}}`,
            value: customInput.value,
          })) || []),
          ...(element.customInput?.map((customInput) => ({
            title: `@{{custom||${customInput.id}||${customInput.name}}}`,
            value: customInput.value,
          })) || []),
        ],
      });
    } else {
      usedElements.push({
        title: `@{{element||${element._id}||${element.name}}}`,
        price: getElementFinalValue(element, element.value),
        usedMaterials: element.formula,
        customInput: [
          ...(element.customInput?.map((customInput) => ({
            title: `@{{custom||${customInput._id}||${customInput.name}}}`,
            value: customInput.value,
          })) || []),
          ...(element.customInput?.map((customInput) => ({
            title: `@{{custom||${customInput.id}||${customInput.name}}}`,
            value: customInput.value,
          })) || []),
        ],
      });
    }
    if (element.customInput) {
      element.customInput.forEach((cuInput) => {
        usedElements.push({
          title: `@{{element||${element._id}-${cuInput.id}||${element.name} - ${cuInput.name}}}`,
          price: cuInput.value,
          usedMaterials: element.formula,
        });
      });
    }
  });
  const totalServiceChargeRegex = new RegExp(
    escapeRegExp("{Total Charge}"),
    "g",
  );
  formula = formula?.replace(totalServiceChargeRegex, totalServiceCharge) || "";
  usedElements.forEach((element) => {
    const regex = new RegExp(escapeRegExp(element.title), "g");
    try {
      formula = formula.replace(regex, element.price);
      if (element.customInput) {
        element.customInput.forEach((customInput) => {
          const regex = new RegExp(escapeRegExp(customInput.title), "g");
          formula = formula.replace(regex, `(${customInput.value})`);
        });
      }
    } catch (error) {}
  });

  return formula;
};

// process element's condition i.e. should we show them on UI or not
// For this we are going to add `shouldHide` if this true we are going to hide from UI
// else we show that on UI default value is true
export const processElementsCondition = (elements, expression) => {
  // create regex for all elements
  const regexElements = [];
  for (let i = 0; i < elements.length; i += 1) {
    const elem = elements[i];
    const regexElem = {
      regex: `@{{element||${elem._id}||${elem.name}}}`,
      type: elem.type,
      value:
        elem.type === "prefilled" || elem.type === "dropdown"
          ? elem.value
          : elem.finalCalculatedValue,
      dropdown: elem.dropdown,
      selected: elem.selected,
      shouldHide: elem.hasOwnProperty("shouldHide") ? elem.shouldHide : false,
    };
    if (!elem.disabled) {
      regexElements.push(regexElem);
    }
  }
  const splitted = expression
    .split(regex.operationRegex)
    .map((exp) => exp.trim());
  let conditionSymbol = expression.match(regex.operationRegex);
  conditionSymbol = conditionSymbol ? conditionSymbol[0] : "";

  const val1 = splitted[0];
  const val2 = splitted[1];
  const usedElements = [];
  let result = false;

  if (val1 !== undefined && val2 !== undefined) {
    let exp1 = val1;
    let exp2 = val2;
    for (let j = 0; j < regexElements.length; j += 1) {
      const regexAt = regexElements[j].regex;
      const generatedRegex = new RegExp(escapeRegExp(regexAt), "g");
      const value =
        regexElements[j].type === "dropdown"
          ? regexElements[j].selected?.superId || regexElements[j].selected?._id
          : regexElements[j].value;
      if (exp1 && exp1.includes(regexAt)) {
        exp1 = exp1.replace(generatedRegex, value);
        usedElements.push(regexElements[j]);
      }
      if (exp2 && exp2.includes(regexAt)) {
        exp2 = exp2.replace(generatedRegex, value);
        usedElements.push(regexElements[j]);
      }
      if (isNaN(exp2)) {
        const temp = /\|\|([a-fA-F0-9]+)\|\|/g;
        const objId = exp2.match(temp);
        if (objId && !exp2.startsWith("[")) {
          exp2 = objId[0]?.split("||")[1];
        }
      }
    }
    let isVisibleUsedElements = true;
    for (let i = 0; i < usedElements.length; i += 1) {
      if (usedElements[i].shouldHide) {
        isVisibleUsedElements = false;
        break;
      }
    }
    if (!isVisibleUsedElements) {
      result = false;
    } else {
      switch (conditionSymbol) {
        case "=":
          if (exp2.startsWith("[")) {
            const range = exp2.split(",").map((elem) => elem.trim());
            range[0] = range[0].slice(1).trim();
            range[range.length - 1] = range[range.length - 1]
              .slice(0, -1)
              .trim();
            if (!isNaN(range[0])) {
              result =
                Number(exp1) >= Number(range[0]) &&
                Number(exp1) <= Number(range[1]);
            } else if (range.join(", ").includes(exp1)) {
              result = true;
              break;
            }
          } else {
            result = exp1 === exp2;
          }
          break;
        case "<":
          result = Number(exp1) < Number(exp2);
          break;
        case ">":
          result = Number(exp1) > Number(exp2);
          break;
        case "<=":
          result = Number(exp1) <= Number(exp2);
          break;
        case ">=":
          result = Number(exp1) >= Number(exp2);
          break;
        case "!=":
          if (exp2.startsWith("[")) {
            const range = exp2.split(",").map((elem) => elem.trim());
            range[0] = range[0].slice(1).trim();
            range[range.length - 1] = range[range.length - 1]
              .slice(0, -1)
              .trim();
            if (!isNaN(range[0])) {
              result =
                Number(exp1) <= Number(range[0]) &&
                Number(exp1) >= Number(range[1]);
            } else if (!range.join(", ").includes(exp1)) {
              result = true;
              break;
            }
          } else {
            result = exp1 !== exp2;
          }
          break;
        default:
          break;
      }
    }
  }
  return { result, val1, val2 };
};

export const processFormulaMaterials = (formula, materials) => {
  const usedMaterials = [];
  materials.forEach((item) => {
    usedMaterials.push({
      id: item._id,
      superId: item.superId,
      title: `@{{catalog||${item.superId}||${item.slug}}}`,
      price: item.price,
    });
    usedMaterials.push({
      id: item._id,
      superId: item.superId,
      title: `@{{catalog||${item.superId}||${item.name}}}`,
      price: item.price,
    });
    usedMaterials.push({
      id: item._id,
      superId: item.superId,
      title: `@{{catalog||${item.superId}||undefined}}`,
      price: item.price,
    });
  });
  usedMaterials.forEach((material) => {
    const regex = new RegExp(escapeRegExp(material.title), "g");
    formula = (formula || "").replace(regex, `(${material.price})`);
    if (formula.includes(material.id) || formula.includes(material.superId)) {
      const regexId = new RegExp(
        // eslint-disable-next-line prefer-template
        escapeRegExp(`@{{catalog||${material.id}||`) +
          "[^{}]+" +
          escapeRegExp("}}"),
        "g",
      );
      const regexSuperId = new RegExp(
        // eslint-disable-next-line prefer-template
        escapeRegExp(`@{{catalog||${material.superId}||`) +
          "[^{}]+" +
          escapeRegExp("}}"),
        "g",
      );
      formula = (formula || "").replace(regexId, `(${material.price})`);
      formula = (formula || "").replace(regexSuperId, `(${material.price})`);
    }
  });
  return formula;
};

export const processHiddenValuesWithMaterials = (formula, hiddenValues) => {
  const usedHiddenValues = hiddenValues.map((item) => ({
    title: `@{{hidden||${item._id}||${item.name}}}`,
    price: item.calculatedValue,
  }));
  usedHiddenValues.forEach((hiddenValue) => {
    const regex = new RegExp(escapeRegExp(hiddenValue.title), "g");
    formula = typeof formula === "number" ? formula.toString() : formula;
    formula = (formula || "").replace(regex, `(${hiddenValue.price})`);
  });

  return formula;
};

export function processFormula({
  formula,
  materials,
  elements,
  hiddenValues,
  multiplicationFactor,
  currentElement,
  totalServiceCharge,
  lineItem,
}) {
  if (lineItem?.type === FORMULA_MATERIAL_TYPES.material) {
    const lineItemRegex = (
      lineItem.materialTypeVariation?.customFields || []
    ).map((customField) => ({
      regex: new RegExp(
        escapeRegExp(
          `^{{lineItems||${customField.superId || customField._id}||${
            lineItem.name
          }-${customField.fieldName}}}`,
        ),
        "g",
      ),
      price: customField.fieldValue,
    }));
    lineItemRegex.forEach((item) => {
      formula = formula.replace(item.regex, item.price);
    });
  }
  if (hiddenValues) {
    let processedHiddenValues = hiddenValues.map((hiddenValue) => {
      if (hiddenValue.isConditional) {
        const tempValue = processElements(
          hiddenValue.expression.condition,
          elements,
          totalServiceCharge || 0,
        );
        const result = processConditionalExpression(tempValue);
        if (result) {
          hiddenValue.value = hiddenValue.expression.fullfill;
        } else {
          hiddenValue.value = hiddenValue.expression.fail;
        }
      }
      return hiddenValue;
    });
    processedHiddenValues = processedHiddenValues.map((hiddenValue) =>
      processNonConditionalHiddenValues(hiddenValue, processedHiddenValues),
    );
    if (formula.includes("hidden")) {
      formula = processHiddenValuesWithMaterials(
        formula,
        processedHiddenValues,
      );
    }
  }
  if (materials) {
    formula = processFormulaMaterials(formula, materials);
  }
  if (elements) {
    formula = processElements(formula, elements, totalServiceCharge || 0);
  }
  try {
    multiplicationFactor =
      multiplicationFactor === undefined || multiplicationFactor === null
        ? 1
        : multiplicationFactor;
    let result =
      Number(eval(formula).toFixed(2)) * Number(multiplicationFactor);

    if (typeof currentElement === "object") {
      currentElement.calculatedValueBeforeRoundingOff = result;
      if (currentElement.roundOff) {
        result = Math.ceil(result);
      }
      if (!currentElement.isEditingCustomResultValue) {
        currentElement.finalCalculatedValue = result;
      }
    }
    if (result === Infinity || result === -Infinity || isNaN(result)) {
      result = 0;
    }
    return result;
  } catch (error) {
    return 0;
  }
}

const processClientContract = (formula, materials, elements, lineItems) => {
  let newContract = "";

  const clientContractV2 = formula.clientContractV2 || [];
  clientContractV2.forEach((contract) => {
    if (contract.conditions.expression) {
      const processedCondition = processElementsCondition(
        elements,
        contract.conditions.expression,
      );
      if (processedCondition.result) {
        newContract += `${contract.content}\n<br>`;
      }
    } else {
      newContract += `${contract.content}\n<br>`;
    }
  });
  if (materials) {
    const usedMaterials = materials.map((item) => ({
      title: `@{{catalog||${item.superId}||${item.name}}}`,
      price: item.price,
    }));
    usedMaterials.forEach((material) => {
      const regex = new RegExp(escapeRegExp(material.title), "g");
      newContract = newContract.replace(regex, material.price);
    });
  }

  if (elements) {
    const usedElements = [];
    elements.forEach((element) => {
      let price = "";
      const selectedVariationCustomFields = element.selected?.customFields;
      if (element.type === "dropdown" && selectedVariationCustomFields) {
        usedElements.push({
          title: `@{{element||${element._id}||${element.name}}}`,
          price: element.selected?.name || "",
        });
        selectedVariationCustomFields.forEach((variationCustomField) => {
          usedElements.push({
            title: `@{{element||${element._id}-${
              variationCustomField?.superId || variationCustomField._id
            }||${element.name} - ${variationCustomField?.fieldName}}}`,
            price: eval(
              getElementFinalValue(element, variationCustomField.fieldValue),
            ),
          });
        });
      } else {
        price =
          element.finalCalculatedValue !== undefined
            ? (
                element.finalCalculatedValue *
                (element.multiplicationFactor === undefined ||
                element.multiplicationFactor === null
                  ? 1
                  : Number(element.multiplicationFactor))
              ).toString()
            : (
                Number(element.value) *
                (element.multiplicationFactor === undefined ||
                element.multiplicationFactor === null
                  ? 1
                  : Number(element.multiplicationFactor))
              ).toString();
        usedElements.push({
          title: `@{{element||${element._id}||${element.name}}}`,
          price,
        });
      }
      if (element.customInput) {
        element.customInput.forEach((cuInput) => {
          usedElements.push({
            title: `@{{element||${element._id}-${cuInput.id}||${element.name} - ${cuInput.name}}}`,
            price: cuInput.value,
            usedMaterials: element.formula,
          });
        });
      }
    });

    usedElements.forEach((element) => {
      const regex = new RegExp(escapeRegExp(element.title), "g");
      try {
        newContract = newContract.replace(
          regex,
          `<strong>${element.price}</strong>`,
        );
      } catch (error) {}
    });
  }
  if (lineItems) {
    const usedLineItems = [];
    lineItems.forEach((item) => {
      const name =
        "[a-zA-Z0-9'\\(\\)\\#\\$\\&\\[\\]\\{\\}\\%\\!\\?\\=\\@\\* ]+";
      usedLineItems.push({
        title: `\\^\\{\\{lineItems\\|\\|${item._id}-quantity\\|\\|${name}- Quantity\\}\\}`,
        // title: `^{{lineItems||${item._id}-quantity||${item.name} - Quantity}}`,
        price: item.quantityValue,
        currencyFormat: false,
      });
      usedLineItems.push({
        title: `\\^\\{\\{lineItems\\|\\|${item._id}-unit\\|\\|${name}- Unit\\}\\}`,
        // title: `^{{lineItems||${item._id}-unit||${item.name} - Unit}}`,
        price: item.unitToShow,
        currencyFormat: false,
      });
      usedLineItems.push({
        title: `\\^\\{\\{lineItems\\|\\|${item._id}-unitCost\\|\\|${name}- Unit Cost\\}\\}`,
        // title: `^{{lineItems||${item._id}-unitCost||${item.name} - Unit Cost}}`,
        price: item.unitCostValue,
        currencyFormat: true,
      });
      usedLineItems.push({
        title: `\\^\\{\\{lineItems\\|\\|${item._id}-cost\\|\\|${name}- Cost\\}\\}`,
        // title: `^{{lineItems||${item._id}-cost||${item.name} - Cost}}`,
        price: item.costValue,
        currencyFormat: true,
      });
      usedLineItems.push({
        title: `\\^\\{\\{lineItems\\|\\|${item._id}-charge\\|\\|${name}- Charge\\}\\}`,
        // title: `^{{lineItems||${item._id}-charge||${item.name} - Charge}}`,
        price: item.chargeValue,
        currencyFormat: true,
      });

      if (item.type === FORMULA_MATERIAL_TYPES.material) {
        (item.materialTypeVariation?.customFields || []).forEach(
          (customField) => {
            usedLineItems.push({
              title: `^{{lineItems||${
                customField.superId || customField._id
              }||${item.name}-${customField.fieldName}}}`,
              price: customField.fieldValue,
              unit: customField.fieldUnit,
            });
          },
        );
      }
    });
    usedLineItems.forEach((lineItem) => {
      // const regex = new RegExp(escapeRegExp(lineItem.title), "g");
      const regex = new RegExp(lineItem.title, "g");
      newContract = newContract.replace(
        regex,
        lineItem.currencyFormat
          ? `<strong>${NumberFormat(lineItem.price)}</strong>`
          : `<strong>${lineItem.price} ${
              lineItem.unit ? lineItem.unit : ""
            }</strong>`,
      );
    });
  }
  newContract = newContract.replace(
    /<a.*?><strong>(.*?)<\/strong><\/a>/g,
    "<strong>$1</strong>",
  );
  formula.processedClientContract = newContract;
  return newContract;
};

export function processMaterials(formula, changeOrderId) {
  const orderId = changeOrderId || 0;
  let materials = [...formula.actualServices[orderId].materials];

  const elements = [...formula.actualServices[orderId].elements].map(
    (element) => {
      const newElement = { ...element };
      return newElement;
    },
  );

  materials = materials.filter((material) => {
    if (material.isConditional) {
      const cond = processElementsCondition(
        elements,
        material.conditions.expression || "",
      );
      return cond.result;
    }
    return true;
  });

  let totalServiceCost = 0;
  let totalServiceCharge = 0;
  let hours = 0;
  let laborCost = 0;
  let serviceMaterialCost = 0;
  let subcontractorCost = 0;
  let equipmentCost = 0;
  let laborCharge = 0;
  let serviceMaterialCharge = 0;
  let subcontractorCharge = 0;
  let equipmentCharge = 0;
  materials = materials.map((material) => {
    material.processedName = material.name;
    elements.forEach((elem) => {
      if (elem.type === "dropdown") {
        const reg = new RegExp(
          escapeRegExp(`@{{element||${elem._id}||${elem.name}}}`),
        );
        material.processedName = material.processedName.replace(
          reg,
          elem.selected?.name,
        );
      }
    });
    let quantity;
    const calculatedQuantity = processFormula({
      formula: material.quantity || "",
      materials: [
        ...(formula.catalog || []),
        ...material.formula,
        ...(formula.catalogs || []),
      ],
      elements,
      hiddenValues: formula.actualServices[orderId].hiddenValues || [],
      lineItem: material,
    });
    material.calculatedQuantity = calculatedQuantity;
    if (!material.isQuantityChanged) {
      quantity = calculatedQuantity;
    } else {
      quantity = material.quantityValue;
    }
    if (material.type === "labor") {
      hours += quantity;
    }

    const unitCostFormula = material.unitCost?.replace(
      /\{Quantity\}/g,
      quantity,
    );
    let unitCost;
    const calculatedUnitCost = processFormula({
      formula: unitCostFormula || "",
      materials: [
        ...(formula.catalog || []),
        ...material.formula,
        ...(formula.catalogs || []),
      ],
      elements,
      hiddenValues: formula.actualServices[orderId].hiddenValues || [],
      lineItem: material,
    });
    material.calculatedUnitCost = calculatedUnitCost;
    if (!material.isUnitCostChanged) {
      unitCost = calculatedUnitCost;
    } else {
      unitCost = material.unitCostValue;
    }
    let materialCost = material.cost?.replace(/\{Quantity\}/g, quantity);
    materialCost = materialCost?.replace(/\{Unit Cost\}/g, unitCost);
    let cost;
    const calculatedCost = processFormula({
      formula: materialCost || "",
      materials: [
        ...(formula.catalog || []),
        ...material.formula,
        ...(formula.catalogs || []),
      ],
      elements,
      hiddenValues: formula.actualServices[orderId].hiddenValues || [],
      lineItem: material,
    });
    material.calculatedCost = calculatedCost;
    if (!material.isCostChanged) {
      cost = calculatedCost;
    } else {
      cost = material.costValue;
    }
    let materialCharge = material.charge?.replace(/\{Quantity\}/g, quantity);
    materialCharge = materialCharge?.replace(/\{Cost\}/, cost);
    let charge;
    const calculatedCharge = processFormula({
      formula: materialCharge,
      materials: [
        ...(formula.catalog || []),
        ...material.formula,
        ...(formula.catalogs || []),
      ],
      elements,
      hiddenValues: formula.actualServices[orderId].hiddenValues || [],
      lineItem: material,
    });
    material.calculatedCharge = calculatedCharge;
    if (!material.isChargeChanged) {
      charge = calculatedCharge;
    } else {
      charge = material.chargeValue;
    }
    material.quantityValue = quantity;
    material.unitCostValue = unitCost;
    material.costValue = cost;
    material.chargeValue = charge;
    material.action = formula.draggableId;

    material.unitToShow = material.unit?.name;
    switch (material.type) {
      case "labor":
        laborCost += material.costValue;
        laborCharge += material.chargeValue;
        break;
      case "material":
        serviceMaterialCost += material.costValue;
        serviceMaterialCharge += material.chargeValue;
        break;
      case "subcontractor":
        subcontractorCost += material.costValue;
        subcontractorCharge += material.chargeValue;
        break;
      case "equipment":
        equipmentCost += material.costValue;
        equipmentCharge += material.chargeValue;
        break;
      default:
        break;
    }
    if (
      (formula.notOriginal && !formula.isEditClientContract) ||
      (!formula.isEditClientContract &&
        formula.actualServices[orderId].type === "original")
    ) {
      processClientContract(
        formula,
        [...(formula.catalog || []), ...material.formula],
        elements,
        materials,
      );
    }
    totalServiceCost += material.costValue;
    totalServiceCharge += material.chargeValue;
    unitCost = NumberFormat(material.unitCostValue);
    cost = NumberFormat(material.costValue);
    charge = NumberFormat(material.chargeValue);
    return {
      ...material,
      costToShow: cost,
      chargeToShow: charge,
      quantityToShow: material.quantityValue,
      unitCostToShow: unitCost,
    };
  });
  // !TODO remove estimatedHours because we are now storing this value in manHrs
  formula.estimatedHours = hours;

  // formula.manHrs = hours;
  formula.actualServices[orderId].manHrs = hours;

  // formula.overheadCharges = calculateOverheadCharges({
  //   manHrs: formula.manHrs,
  //   overheadRate: formula.overheadRate,
  //   subcontractorCost,
  //   subcontractorRecoveryPercentage: formula.subcontractorRecoveryPercentage,
  // });
  formula.actualServices[orderId].overheadCharges = calculateOverheadCharges({
    manHrs: formula.actualServices[orderId].manHrs,
    overheadRate: formula.actualServices[orderId].overheadRate,
    subcontractorCost,
    subcontractorRecoveryPercentage:
      formula.actualServices[orderId].subcontractorRecoveryPercentage,
  });

  // formula.totalServiceCost = Number(totalServiceCost.toFixed(2));
  formula.actualServices[orderId].totalServiceCost = Number(
    totalServiceCost.toFixed(2),
  );

  // formula.totalServiceCharge = Number(totalServiceCharge.toFixed(2));
  formula.actualServices[orderId].totalServiceCharge = Number(
    totalServiceCharge.toFixed(2),
  );

  let profitPercent = Number(
    ((1 - Number(totalServiceCost / totalServiceCharge)) * 100).toFixed(2),
  );
  profitPercent = isNaN(profitPercent) ? 0 : profitPercent;
  // formula.grossProfit = {
  //   profit: Number((totalServiceCharge - totalServiceCost).toFixed(2)),
  //   profitPercentage: profitPercent,
  // };
  formula.actualServices[orderId].grossProfit = {
    profit: Number((totalServiceCharge - totalServiceCost).toFixed(2)),
    profitPercentage: profitPercent,
  };

  // formula.netProfit = {
  //   profitPercentage: totalServiceCharge
  //     ? Number(
  //         (
  //           ((formula.grossProfit.profit - formula.overheadCharges) /
  //             totalServiceCharge) *
  //           100
  //         ).toFixed(2),
  //       )
  //     : 0,
  //   profit: Number(
  //     (formula.grossProfit.profit - formula.overheadCharges).toFixed(2),
  //   ),
  // };
  formula.actualServices[orderId].netProfit = {
    profitPercentage: totalServiceCharge
      ? Number(
          (
            ((formula.actualServices[orderId].grossProfit.profit -
              formula.actualServices[orderId].overheadCharges) /
              totalServiceCharge) *
            100
          ).toFixed(2),
        )
      : 0,
    profit: Number(
      (
        formula.actualServices[orderId].grossProfit.profit -
        formula.actualServices[orderId].overheadCharges
      ).toFixed(2),
    ),
  };

  // formula.laborCost = Number(laborCost.toFixed(2));
  formula.actualServices[orderId].laborCost = Number(laborCost.toFixed(2));

  // formula.laborCharge = Number(laborCharge.toFixed(2));
  formula.actualServices[orderId].laborCharge = Number(laborCharge.toFixed(2));

  // formula.materialCost = Number(serviceMaterialCost.toFixed(2));
  formula.actualServices[orderId].materialCost = Number(
    serviceMaterialCost.toFixed(2),
  );

  // formula.materialCharge = Number(serviceMaterialCharge.toFixed(2));
  formula.actualServices[orderId].materialCharge = Number(
    serviceMaterialCharge.toFixed(2),
  );

  // formula.subcontractorCost = Number(subcontractorCost.toFixed(2));
  formula.actualServices[orderId].subcontractorCost = Number(
    subcontractorCost.toFixed(2),
  );

  // formula.subcontractorCharge = Number(subcontractorCharge.toFixed(2));
  formula.actualServices[orderId].subcontractorCharge = Number(
    subcontractorCharge.toFixed(2),
  );

  // formula.equipmentCost = Number(equipmentCost.toFixed(2));
  formula.actualServices[orderId].equipmentCost = Number(
    equipmentCost.toFixed(2),
  );

  // formula.equipmentCharge = Number(equipmentCharge.toFixed(2));
  formula.actualServices[orderId].equipmentCharge = Number(
    equipmentCharge.toFixed(2),
  );
  return materials;
}

export const processCustomInput = (expression, totalNumberOfWorkers) => {
  const numberOfGuys = totalNumberOfWorkers || 5;
  const regex = new RegExp(
    escapeRegExp('{Company Settings: "# Of Workers"}'),
    "g",
  );
  const newExpression = expression.replace(regex, numberOfGuys);
  return newExpression;
};

export const calculateOverheadCharges = ({
  manHrs,
  overheadRate,
  subcontractorCost,
  subcontractorRecoveryPercentage,
}) =>
  Number(
    (
      manHrs * overheadRate +
      subcontractorCost * (subcontractorRecoveryPercentage / 100)
    ).toFixed(2),
  );

export const calculateEstimationOverview = ({
  services,
  tax,
  taxStatus,
  onlySigned,
}) => {
  let totalLaborCost = 0;
  let totalMaterialsCost = 0;
  let totalSubContractorCost = 0;
  let totalEquipmentCost = 0;

  let totalLaborCharge = 0;
  let totalMaterialsCharge = 0;
  let totalSubContractorCharge = 0;
  let totalEquipmentCharge = 0;

  let totalOverheadCharges = 0;

  let totalManHrs = 0;

  let projectCharge = 0;
  let projectCost = 0;

  const signedStatus = [
    userEstimationStatus.signed,
    userEstimationStatus.internallyConfirmed,
  ];
  services.forEach((formula) => {
    let changeOrderIndex = 0;
    let removingService = false;
    if (onlySigned) {
      changeOrderIndex = signedStatus.includes(formula.actualServices[0].status)
        ? 0
        : signedStatus.includes(formula.actualServices[1]?.status)
        ? 1
        : -1;
      if (
        signedStatus.includes(formula.actualServices[0].status) &&
        formula.isRemoved
      ) {
        removingService = true;
      }
    }

    if (
      (!onlySigned && formula.isRemoved && changeOrderIndex !== -1) ||
      removingService
    ) {
      return;
    }
    if (changeOrderIndex !== -1) {
      projectCharge += isNaN(
        formula.actualServices[changeOrderIndex].totalServiceCharge,
      )
        ? 0
        : formula.actualServices[changeOrderIndex].totalServiceCharge;
      projectCost += isNaN(
        formula.actualServices[changeOrderIndex].totalServiceCost,
      )
        ? 0
        : formula.actualServices[changeOrderIndex].totalServiceCost;

      totalLaborCost += formula.actualServices[changeOrderIndex].laborCost;
      totalLaborCharge += formula.actualServices[changeOrderIndex].laborCharge;

      totalMaterialsCost +=
        formula.actualServices[changeOrderIndex].materialCost;
      totalMaterialsCharge +=
        formula.actualServices[changeOrderIndex].materialCharge;

      totalSubContractorCost +=
        formula.actualServices[changeOrderIndex].subcontractorCost;
      totalSubContractorCharge +=
        formula.actualServices[changeOrderIndex].subcontractorCharge;

      totalEquipmentCost +=
        formula.actualServices[changeOrderIndex].equipmentCost;
      totalEquipmentCharge +=
        formula.actualServices[changeOrderIndex].equipmentCharge;

      totalOverheadCharges +=
        formula.actualServices[changeOrderIndex].overheadCharges;

      totalManHrs += formula.actualServices[changeOrderIndex].manHrs;
    }
  });
  projectCharge = Number(projectCharge.toFixed(2));
  let taxAmount = 0;
  if (tax && taxStatus) {
    if (tax > 0) {
      taxAmount = Number(((projectCharge * Number(tax)) / 100).toFixed(2));
      projectCharge += taxAmount;
    }
  }
  projectCost = Number(projectCost.toFixed(2));
  const totalChargeWithoutTax = taxStatus
    ? projectCharge - taxAmount
    : projectCharge;

  const grossProfit = totalChargeWithoutTax - projectCost;
  const grossProfitPercentage = calculateProfitPercentage(
    grossProfit,
    totalChargeWithoutTax,
  );
  const netProfit = grossProfit - totalOverheadCharges;

  let netProfitPercentage = calculateProfitPercentage(
    netProfit,
    totalChargeWithoutTax,
  );
  netProfitPercentage = isNaN(netProfitPercentage)
    ? 0
    : Number(netProfitPercentage);
  netProfitPercentage = netProfitPercentage < 0 ? 0 : netProfitPercentage;

  return {
    totalCharge: Number(projectCharge.toFixed(2)),
    totalCost: projectCost,
    taxAmount,
    tax,
    totalChargeWithoutTax,

    grossProfit: {
      profitPercentage: Number(grossProfitPercentage),
      profit: Number(grossProfit.toFixed(2)),
    },
    netProfit: {
      profitPercentage: Number(netProfitPercentage),
      profit: Number(netProfit.toFixed(2)),
    },

    totalLaborCost: Number(totalLaborCost.toFixed(2)),
    totalMaterialsCost: Number(totalMaterialsCost.toFixed(2)),
    totalSubContractorCost: Number(totalSubContractorCost.toFixed(2)),
    totalEquipmentCost: Number(totalEquipmentCost.toFixed(2)),

    totalLaborCharge: Number(totalLaborCharge.toFixed(2)),
    totalMaterialsCharge: Number(totalMaterialsCharge.toFixed(2)),
    totalSubContractorCharge: Number(totalSubContractorCharge.toFixed(2)),
    totalEquipmentCharge: Number(totalEquipmentCharge.toFixed(2)),

    totalOverheadCharges: Number(totalOverheadCharges.toFixed(2)),
    totalManHrs: Number(totalManHrs.toFixed(2)),
  };
};

export const generateChangeOrderNumber = (
  estimationNumber,
  lastChangeOrderNumber,
) => {
  if (!lastChangeOrderNumber) {
    return `CO-${estimationNumber}-1`;
  }
  return `CO-${estimationNumber}-${Number(lastChangeOrderNumber) + 1}`;
};
