/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { getVariationBySuperCatalogId } from "../../../../../api/catalogue";
import ReactMentionInput from "../../../../../utils/mentionInput/mentionInput";

function ConditionalInput({
  hiddenValueList,
  elementList,
  element,
  handleChange,
  disabled,
  placeholder,
}) {
  const [value, setValue] = useState("");
  const [variations, setVariations] = useState([]);
  const [processedVariations, setProcessedVariations] = useState([]);
  const [selectedElement, setSelectedElement] = useState({});
  const [processedElementList, setProcessedElementList] = useState([]);
  const [isChanging, setIsChanging] = useState(false);
  const fetchVariation = async (id) => {
    const response = await getVariationBySuperCatalogId(id);
    if (response.remote === "success") {
      setVariations(response.data.data);
      setProcessedVariations(
        response.data.data.map((variation) => ({
          id: variation.superId,
          display: `(${selectedElement.name}: ${variation.name})`,
          slug: variation.slug || variation.name,
        })),
      );
    }
  };

  const getSelectedVariation = (id) => {
    const variation = variations.find((variation) => variation.superId === id);
    if (variation) {
      return `(${selectedElement.name}: ${variation.name})`;
    }
  };

  useEffect(() => {
    setProcessedElementList(
      elementList.filter((elem) => elem._id !== element._id),
    );
  }, [element._id, elementList]);
  useEffect(() => {
    const selectedElement = elementList.find(
      (elem) => element.conditions?.element === elem._id,
    );
    if (selectedElement) {
      setSelectedElement(selectedElement);
    }
  }, [element, elementList]);
  useEffect(() => {
    if (!isChanging) {
      setValue(element.conditions?.expression || "");
    }
  }, [element.conditions?.expression]);
  useEffect(() => {
    if (selectedElement.dropdown) {
      fetchVariation(selectedElement.dropdown);
    }
  }, [selectedElement.dropdown]);
  return (
    <div>
      <ReactMentionInput
        className="ant-furmulla-input"
        elementList={processedElementList.map((elem) => ({
          id: elem._id,
          display: elem.name,
        }))}
        elementProps={{
          onAdd: (id) => {
            setSelectedElement(elementList.find((elem) => elem._id === id));
          },
        }}
        variationsList={processedVariations}
        getSelectedVariation={getSelectedVariation}
        hiddenInputList={hiddenValueList.map((hiddenValue) => ({
          display: hiddenValue.name,
          id: hiddenValue._id,
        }))}
        placeholder={placeholder || ""}
        value={value}
        onChange={(e) => {
          if (!isChanging) {
            setIsChanging(true);
          }
          if (e.target.value) {
            processedVariations.forEach((variation) => {
              e.target.value.replace(variation.display, variation.slug);
            });
          }
          setValue(e.target.value);
        }}
        disabled={disabled}
        onBlur={() => {
          if (isChanging) {
            setIsChanging(false);
            handleChange({
              expression: value,
              element: selectedElement._id,
            });
          }
        }}
        noMaterial
        isTotalCharge
      />
    </div>
  );
}

export default ConditionalInput;
