import "../userlist.css";
import "../PromoCodePage.css";
import "react-toastify/dist/ReactToastify.css";

import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  deletePromoCodeAction,
  getPromoCodeDataAction,
  getPromoCodeMetaAction,
} from "../../../../api/admin";
import ModalDailog from "../../../../common/components/ModalDialogue";
import SuccessIcon from "../../../../images/success-outlined.svg";
import {
  DATE_FORMATE,
  discountStatusColor,
  promoCodeStatus,
  promoCodeStatusColor,
  removeCodeNote,
} from "../../../../utils/constants/constants";
import { convertJSONToCSV } from "../../../../utils/javascript";
import { downloadcloud } from "../../../../utils/svg.file";
import RangePickerFilter from "../../../affiliates/v3/profileTabs/referrals/RangePickerFilter";
import SmallLoader from "../../../loader/smallLoader";
import { formatDate } from "..";
import WarningModal from "../modal/WarningModal";
import TableData from "../Table";
import { avoidColumns, getActionName, promoCodeMetaData } from "./helper";

const { Text } = Typography;

const formattedValueType = (value) => {
  const modValue = parseInt(+value?.toFixed(2)) === 0 ? Math.abs(value) : value;
  return {
    freeTrial: `${modValue} ${modValue > 1 ? "Days" : "Day"}`,
    amount: `$${modValue?.toFixed(2)}`,
    percentage: `$${modValue?.toFixed(2)}`,
  };
};

const PromoCodeModal = ({
  selectedPromoCode,
  openRangeSelector,
  handleRangeToggle,
  setOpenRangeSelector,
}) => {
  const [loader, setLoader] = useState(false);
  const [promoCodeData, setPromoCodeData] = useState([]);
  const [openModal, setOpenModal] = useState({
    isRemoveCode: false,
    isConfirmDelete: false,
  });
  const [deleteCodeId, setDeleteCodeId] = useState();
  const [password, setPassword] = useState("");
  const [currentPromoPage, setCurrentPromoPage] = useState(1);
  const [exportData, setExportData] = useState([]);
  const [isExportData, setIsExportData] = useState(false);
  const getBackgroundColor = (status) =>
    promoCodeStatusColor[status?.toLowerCase()] || "#FFBC44";
  const [sortOrder, setSortOrder] = useState();
  const [sortFields, setSortFields] = useState();
  const [searchValue, setSearchValue] = useState();
  const [promoCodeMeta, setPromoCodeMeta] = useState({});
  const [value, setValue] = useState(null);
  // const [openRangeSelector, setOpenRangeSelector] = useState(false);
  const promoCodeDetailColumns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      sorter: (a, b) => a?.companyName?.localeCompare(b?.companyName),
    },
    {
      title: "Account #",
      dataIndex: "companyNumber",
      sorter: (a, b) => a?.companyNumber - b?.companyNumber,
    },
    {
      title: "MA Name",
      dataIndex: "maName",
      sorter: (a, b) => a?.maName?.localeCompare(b?.maName),
    },
    {
      title: "Link or Code?",
      dataIndex: "promoCodeUsedThrough",
      sorter: (a, b) =>
        a?.promoCodeUsedThrough?.localeCompare(b?.promoCodeUsedThrough),
    },
    {
      title: "Type Of Account",
      dataIndex: "accountStatus",
      sorter: (a, b) => a?.accountStatus?.localeCompare(b?.accountStatus),
    },
    {
      title: "Discount Status",
      dataIndex: "discountStatus",
      render: (v) => <span style={{ color: discountStatusColor[v] }}>{v}</span>,
      sorter: (a, b) => a?.discountStatus?.localeCompare(b?.discountStatus),
    },
    {
      title: "Date Discount Applied",
      dataIndex: "discountAppliedDate",
      render: (v) => `${formatDate(v)}`,
      sorter: (a, b) =>
        new Date(a?.discountAppliedDate) - new Date(b?.discountAppliedDate),
    },
    {
      title: "Total Discount",
      dataIndex: "totalDiscount",
      render: (v) => formattedValueType(v)[selectedPromoCode?.discountTypes],
      sorter: (a, b) => a?.totalDiscount - b?.totalDiscount,
    },
    {
      title: "Amount Used",
      dataIndex: "usedDiscount",
      render: (v) => formattedValueType(v)[selectedPromoCode?.discountTypes],
      sorter: (a, b) => a?.usedDiscount - b?.usedDiscount,
    },
    {
      title: "% Used",
      dataIndex: "usedDiscountPercentage",
      render: (v) => `${Math.round(v)}%`,
      sorter: (a, b) => a?.usedDiscountPercentage - b?.usedDiscountPercentage,
    },
    {
      title: "Remaining Discount",
      dataIndex: "remainingDiscount",
      render: (v) => formattedValueType(v)[selectedPromoCode?.discountTypes],
      sorter: (a, b) => a?.remainingDiscount - b?.remainingDiscount,
    },
    {
      title: "Expected End Date",
      dataIndex: "expectedEndDate",
      render: (v) => `${formatDate(v)}`,
      sorter: (a, b) =>
        new Date(a?.expectedEndDate) - new Date(b?.expectedEndDate),
    },
    {
      title: "Current Status",
      dataIndex: "currentStatus",
      render: (v) => (
        <span style={{ color: getBackgroundColor(v) }}>
          {promoCodeStatus[v]}
        </span>
      ),
      sorter: (a, b) => a?.currentStatus?.localeCompare(b?.currentStatus),
    },
    {
      title: "Active Months",
      dataIndex: "activeMonthCount",
      sorter: (a, b) => a?.activeMonthCount - b?.activeMonthCount,
    },
    {
      title: "Remove",
      dataIndex: "",
      render: (_, record) =>
        record?.discountStatus === "Discount Remaining" ? (
          <Space size={[2, 2]}>
            <Button
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                setPassword("");
                setOpenModal((prevState) => ({
                  ...prevState,
                  isRemoveCode: true,
                }));
                setDeleteCodeId(record?._id);
              }}
            >
              <CloseOutlined style={{ color: "#E42626" }} />
            </Button>
          </Space>
        ) : (
          <></>
        ),
    },
  ];

  const promoCodeOverviewColumns = [
    {
      title: "Initial Action",
      dataIndex: "action",
      render: (v) => getActionName[v],
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      render: () => "-",
    },
    {
      title: "Filled Out/Requested",
      dataIndex: "filledOutRequested",
      render: (v, _, index) =>
        index ===
        promoCodeMetaData?.findIndex((obj) => obj?.action === "signUpCode")
          ? v || "-"
          : v,
    },
    {
      title: "Signed Up",
      dataIndex: "signedUp",
      render: (v) => v,
    },
    {
      title: "Canceled on Trial",
      dataIndex: "canceledOnTrial",
      render: (v) => v,
    },
    {
      title: "Purchased After Trial",
      dataIndex: "purchasedOnTrial",
      render: (v) => v,
    },
    {
      title: "Canceled",
      dataIndex: "canceled",
      render: (v) => v,
    },
    {
      title: "Card Declined",
      dataIndex: "onHold",
      render: (v) => v,
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (v) => v,
    },
  ];

  const configs = {
    title: "",
    className: "modal-pop-up",
    centered: true,
    content: (
      <div>
        <h5>Exporting Data</h5>
        <span>
          Your file will export with the same layout/filters that are applied.
          This file will be exported in .csv format.{" "}
        </span>
      </div>
    ),
    okText: "Confirm",
    onOk() {
      handleDownload(exportData, selectedPromoCode?.couponCode);
      handleCancel();
    },
    onCancel() {
      handleCancel();
    },
  };

  const messageConfig = {
    content: (
      <>
        <p
          className="m-0"
          style={{ fontSize: 14, color: "#027A48", fontWeight: 500 }}
        >
          Data Export Successfully
        </p>
        <p className="m-0" style={{ fontSize: 14, color: "#027A48" }}>
          All your data was downloaded successfully, you can view the file in
          the download section of your computer.
        </p>
      </>
    ),
    duration: 5,
    icon: <img src={SuccessIcon} alt="success-msg-icon" />,
    className: "export-success-msg",
  };

  const getPromoCodeDetails = async () => {
    setLoader(true);
    const result = await getPromoCodeDataAction(
      selectedPromoCode?._id,
      currentPromoPage,
      10,
      searchValue,
      sortOrder,
      sortFields,
    );

    if (result?.remote === "success") {
      setPromoCodeData(result?.data?.data?.[0]);
    }
    setLoader(false);
  };

  const getPromoCodeMeta = async (start, end) => {
    setLoader(true);
    const clonedMeta = [...promoCodeMetaData];
    const result = await getPromoCodeMetaAction(
      selectedPromoCode?._id,
      start,
      end,
    );
    if (result.remote === "success") {
      let data = { ...result?.data?.data };
      data?.promoCodeMetadata?.forEach((obj) => {
        const index = clonedMeta?.findIndex((ob) => ob.action === obj?.action);
        if (~index) {
          clonedMeta[index] = { ...obj };
        }
      });
      data = { ...data, promoCodeMetadata: clonedMeta };
      setPromoCodeMeta(data);
      setLoader(false);
    } else {
      setPromoCodeMeta(() => ({
        totalClicks: 0,
        promoCodeMetadata: promoCodeMetaData,
      }));
      setLoader(false);
    }
  };

  useEffect(() => {
    getPromoCodeDetails();
    getPromoCodeMeta();
  }, [
    selectedPromoCode?._id,
    currentPromoPage,
    searchValue,
    sortFields,
    sortOrder,
  ]);

  const handleRemoveCode = () => {
    setPassword("");
    setOpenModal((prevState) => ({
      ...prevState,
      isConfirmDelete: !prevState.isConfirmDelete,
    }));
  };

  const handleCancel = (modalName) => {
    if (modalName && openModal?.[modalName]) {
      setOpenModal((prevState) => ({
        ...prevState,
        [modalName]: !prevState?.[modalName],
      }));
      setPassword("");
    } else {
      Object.keys(openModal).forEach((modalName) => {
        if (openModal?.[modalName])
          setOpenModal((prevState) => ({
            ...prevState,
            [modalName]: !prevState?.[modalName],
          }));
      });
    }
  };

  const handleDelete = async () => {
    const result = await deletePromoCodeAction(
      deleteCodeId,
      password,
      selectedPromoCode?._id,
    );
    if (result.remote === "success") {
      message.success("Promo code successfully removed", 10);
      handleCancel();
      getPromoCodeDetails(selectedPromoCode?._id);
    } else {
      toast.error(result?.error);
    }
  };

  const handleSearch = ({ target: { value } }) => {
    if (value) {
      setSearchValue(value);
    } else {
      setSearchValue("");
    }
  };

  const generateExcelData = (item) => {
    const excelData = {
      "Company Name": item?.companyName,
      "Account #": item?.companyNumber,
      "MA Name": item?.maName,
      "Link or Code?": item?.promoCodeUsedThrough,
      "Type Of Account": item?.accountStatus,
      "Discount Status": item?.discountStatus,
      "Date Discount Applied": item?.discountAppliedDate
        ? moment(new Date(item?.discountAppliedDate)).format(DATE_FORMATE)
        : "-",
      "Total Discount": formattedValueType(item?.totalDiscount)[
        selectedPromoCode?.discountTypes
      ],
      "Amount Used": formattedValueType(item?.usedDiscount)[
        selectedPromoCode?.discountTypes
      ],
      "% Used": `${Math.round(item?.usedDiscountPercentage)}%`,
      "Remaining Discount": formattedValueType(item?.remainingDiscount)[
        selectedPromoCode?.discountTypes
      ],
      "Expected End Date": item?.expectedEndDate
        ? moment(new Date(item?.expectedEndDate)).format(DATE_FORMATE)
        : "-",
      "Current Status": promoCodeStatus[item?.currentStatus],
      "Active Months": item?.activeMonthCount,
    };
    return excelData;
  };

  const handleDownload = (exportData, promoCodeName) => {
    const csvDataList = [];
    exportData?.forEach((item) => {
      csvDataList.push(generateExcelData(item));
    });
    const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
      convertJSONToCSV(csvDataList),
    )}`;
    const link = document.createElement("a");
    link.href = csvContent;
    link.download = `${promoCodeName} Details`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    message.success(messageConfig);
  };

  const handleExportClick = async () => {
    const result = await getPromoCodeDataAction(
      selectedPromoCode?._id,
      promoCodeData?.pagination?.[0]?.currentPage,
      promoCodeData?.pagination?.[0]?.totalCount,
    );
    if (result?.remote === "success") {
      setExportData(result?.data?.data?.[0]?.data);
      setIsExportData(true);
    }
  };

  useEffect(() => {
    if (isExportData) {
      Modal.confirm(configs);
      setIsExportData(false);
    }
  }, [isExportData]);

  const handleTableChange = (_, __, sorter) => {
    const { field, order } = sorter;
    setSortFields(field);
    setSortOrder(order);
  };

  const handleRangeChange = (date) => {
    setValue(date);
    handleRangeToggle(true);
  };

  const handleApplyClick = async () => {
    /**
     * Call API on apply and close the modal
     */
    if (value?.length) {
      handleRangeToggle(false);
      await getPromoCodeMeta(
        moment(value?.[0])?.format("YYYY-MM-DD"),
        moment(value?.[1])?.format("YYYY-MM-DD"),
      );
    } else {
      handleRangeToggle(false);
      await getPromoCodeMeta();
    }
  };

  useEffect(() => {
    setPromoCodeMeta(() => ({
      totalClicks: 0,
      promoCodeMetadata: [...promoCodeMetaData],
    }));
    return () => {
      handleRangeToggle(false);
    };
  }, []);

  return (
    <div>
      {loader && (
        <div className="center-loader">
          <SmallLoader />
        </div>
      )}
      <div className="userlist-details">
        <Space size={[16, 16]} align="start" wrap>
          <div>
            <h3 className="fliter-sort-title mb-0">
              {selectedPromoCode?.couponCode}
            </h3>
            <span className="d-block mt-1">{selectedPromoCode?.name}</span>
          </div>
        </Space>
        <span
          className="trail-days ms-lg-3 d-inline-block"
          style={{
            background: "#3483fa",
          }}
        >
          {selectedPromoCode?.usedQuantity}/{" "}
          {selectedPromoCode?.quantityOfPromoCode}
        </span>
        <Row gutter={[24, 16]} className="mt-5">
          <Col xs={24}>
            <Space style={{ width: "100%" }} className="promo-code-popup">
              <RangePickerFilter
                value={value}
                open={openRangeSelector}
                handleApplyClick={handleApplyClick}
                handleRangeToggle={handleRangeToggle}
                handleRangeChange={handleRangeChange}
                setOpenRangeSelector={setOpenRangeSelector}
                changePos
              />
            </Space>
          </Col>
          <Col xs={24}>
            <div className="tablecard">
              <TableData
                scroll={{
                  x: 500,
                }}
                columns={promoCodeOverviewColumns}
                dataSource={
                  promoCodeMeta?.promoCodeMetadata || promoCodeMetaData
                }
                summary={(pageData) => {
                  const total = {};
                  pageData.forEach((obj) => {
                    Object.keys(obj).forEach((key) => {
                      if (
                        obj?.[key] !== "action" &&
                        typeof obj[key] !== "string"
                      ) {
                        if (total?.[key]) total[key] += obj[key];
                        else total[key] = obj[key];
                      }
                    });
                  });
                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell>
                        <Text style={{ color: "#3483FA", fontWeight: 600 }}>
                          Total
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text style={{ color: "#3483FA", fontWeight: 600 }}>
                          {promoCodeMeta?.totalClicks || 0}
                        </Text>
                      </Table.Summary.Cell>
                      {promoCodeOverviewColumns.map(
                        ({ dataIndex }) =>
                          !avoidColumns.includes(dataIndex) && (
                            <Table.Summary.Cell>
                              <Text
                                style={{ color: "#3483FA", fontWeight: 600 }}
                              >
                                {total[dataIndex]}
                              </Text>
                            </Table.Summary.Cell>
                          ),
                      )}
                    </Table.Summary.Row>
                  );
                }}
                pagination={false}
                onChange={handleTableChange}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 16]} className="mt-5">
          <Col xs={24}>
            <Space style={{ width: "100%" }} className="promo-code-popup">
              <Input
                size="large"
                className="btn-filter"
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "18px" }} />}
                name="searchPromo"
                id="searchCode"
                autoComplete="new-password"
                onChange={handleSearch}
              />
              <Button
                size="large"
                style={{ borderRadius: "8px" }}
                onClick={handleExportClick}
              >
                <span style={{ marginRight: 10 }}>{downloadcloud}</span>
                Export
              </Button>
            </Space>
          </Col>
          <Col xs={24}>
            <div className="tablecard">
              <TableData
                scroll={{
                  x: 500,
                }}
                columns={promoCodeDetailColumns}
                dataSource={promoCodeData?.data}
                paginationData={promoCodeData?.pagination?.[0]}
                perPage={10}
                currentPage={currentPromoPage}
                setCurrentPage={setCurrentPromoPage}
                onChange={handleTableChange}
                pagination
              />
            </div>
          </Col>
        </Row>
      </div>
      <ModalDailog
        isModalOpen={openModal.isRemoveCode}
        handleCancel={() => handleCancel("isRemoveCode")}
        className="warningModal"
        width={400}
      >
        <WarningModal
          handleCancel={() => handleCancel("isRemoveCode")}
          handleDeactivate={handleRemoveCode}
          description={removeCodeNote}
          okBtnTitle="Delete"
          btnProps={{ danger: true, type: "primary" }}
        />
      </ModalDailog>
      <ModalDailog
        isModalOpen={openModal.isConfirmDelete}
        handleCancel={() => handleCancel("isConfirmDelete")}
        className="warningModal"
        width={400}
      >
        <WarningModal
          handleCancel={() => handleCancel("isConfirmDelete")}
          handleDeactivate={handleDelete}
          description="Please enter the master password to remove this company's promo code."
          okBtnTitle="Delete"
          btnProps={{ danger: true, type: "primary" }}
        >
          <Input.Password
            placeholder="Password"
            name="code-password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            className="warning-input"
            autoComplete="new-password"
            id="code-password"
          />
        </WarningModal>
      </ModalDailog>
      <Popconfirm />
    </div>
  );
};

export default PromoCodeModal;
