/* eslint-disable no-unused-vars */
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getLinksNCodeAction } from "../../../../redux/affiliate/affiliate.action";
import { plansForPromoCode } from "../../../../utils/constants/constants";
import SmallLoader from "../../../loader/smallLoader";
import TableData from "../../../setting/UserListV2/Table";
import {
  convertLinksCode,
  discountTypes,
  formatSubscriptionTypes,
} from "../helper";

const linkNCodeColumns = (isSuperAdmin) =>
  [
    {
      title: "Name",
      dataIndex: "name",
      className: "promocode-table-name-col",
      // sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: "Code",
      dataIndex: "couponCode",
      className: "text-center",
      // sorter: (a, b) => a.couponCode.localeCompare(b.couponCode),
    },
    {
      title: "Link",
      dataIndex: "link",
      render: (v) => (
        <a href={v} onClick={(e) => e.stopPropagation()} target="_blank">
          {convertLinksCode(v)}
        </a>
      ),
    },
    {
      title: "Discount Type",
      className: "text-center",
      dataIndex: "discountTypes",
      // sorter: (a, b) => a.discountTypes.localeCompare(b.discountTypes),
      render: (v) => discountTypes[v],
    },
    {
      title: "Subscription Type",
      className: "text-center",
      dataIndex: "validForSubscriptionInterval",
      render: (v) => formatSubscriptionTypes(v),
      // sorter: (a, b) =>
      //   a.validForSubscriptionInterval[0].localeCompare(
      //     b.validForSubscriptionInterval[0],
      //   ),
    },
    {
      title: "Discount",
      className: "text-center",
      dataIndex: "discount",
      render: (discount, record) =>
        record?.discountTypes === "percentage"
          ? `${discount}%`
          : record?.discountTypes === "amount"
          ? `$${discount.toFixed(2)}`
          : "-",
      // sorter: (a, b) => a.discount - b.discount,
    },
    {
      title: "Billing Cycles",
      className: "text-center",
      dataIndex: "duration_in_months",
      render: (duration_in_months, record) =>
        `${
          record?.duration === "forever"
            ? "Entire Subscription"
            : Number(duration_in_months) === 12
            ? 1
            : Number(duration_in_months) === 0
            ? "-"
            : duration_in_months
        } ${
          Number(duration_in_months) === 12
            ? "Year"
            : Number(duration_in_months) === 0
            ? ""
            : Number(duration_in_months) > 1 &&
              Number(duration_in_months) !== 12
            ? "Months"
            : "Month"
        }`,
      // sorter: (a, b) => a.duration_in_months - b.duration_in_months,
    },
    {
      title: "Trial Days",
      className: "text-center",
      dataIndex: "trialDays",
      render: (trialDays, record) =>
        `${
          trialDays > 0
            ? trialDays
            : record?.trialMonths > 0
            ? record?.trialMonths
            : "-"
        } ${trialDays > 0 ? "Days" : record?.trialMonths > 0 ? "Months" : ""}`,
      // sorter: (a, b) =>
      //   (a?.trialMonths ? a?.trialMonths : 0) -
      //   (b?.trialMonths ? b?.trialMonths : 0),
    },
    {
      title: "Total Allowance",
      className: "text-center",
      dataIndex: "quantityOfPromoCode",
      render: (quantityOfPromoCode, record) => (
        <>
          {quantityOfPromoCode === "Unlimited" ? (
            <>Unlimited</>
          ) : (
            <div
              style={{
                color:
                  Number(quantityOfPromoCode) === record?.usedQuantity
                    ? "#E42626"
                    : "#101828",
              }}
            >
              {Number(quantityOfPromoCode)}
            </div>
          )}
        </>
      ),
      // sorter: (a, b) =>
      //   a.quantityOfPromoCode.toString().localeCompare(b.quantityOfPromoCode),
    },
    {
      ...(isSuperAdmin && {
        title: "Plan",
        className: "text-center promocode-table-plan-col",
        dataIndex: "validForPlan",
        render: (validForPlan) => (
          <>
            {plansForPromoCode[0]?.value === validForPlan[0] &&
              plansForPromoCode[0]?.label}
            {validForPlan?.length > 1 ? `, +${validForPlan?.length - 1}` : ""}
          </>
        ),
        sorter: (a, b) => a.validForPlan[0].localeCompare(b.validForPlan[0]),
      }),
    },
    {
      title: "Start Date",
      className: "text-center",
      dataIndex: "startDate",
      render: (startDate) => moment(startDate).format("MM/DD/YYYY"),
      // sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
    },
    {
      title: "Expiration Date",
      className: "text-center",
      dataIndex: "expireDate",
      render: (expireDate) =>
        expireDate ? moment(expireDate).format("MM/DD/YYYY") : "No Expiration",
      // sorter: (a, b) => new Date(a?.expireDate) - new Date(b?.expireDate),
    },
    {
      ...(isSuperAdmin && {
        title: "Last Edited",
        className: "text-center",
        dataIndex: "updatedAt",
        render: (updatedAt) =>
          updatedAt ? moment(updatedAt).format("MM/DD/YYYY") : "-",
        sorter: (a, b) => new Date(a?.updatedAt) - new Date(b?.updatedAt),
      }),
    },
    /* {
      ...(isSuperAdmin && {
        title: "Edit",
        dataIndex: "action",
        render: (action, record) => (
          <Button
            type="text"
            onClick={(e) => {
              console.log("action, record", action, record);
              e.stopPropagation();
              // setShowEditPromo(true);
              // setDeletePromoId(record._id);
            }}
            icon={<EditOutlined />}
          />
        ),
      }),
    }, */
  ].filter((obj) => Object.keys(obj).length);

const perPage = 10;

const LinkNCode = ({ isSuperAdmin = false }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const linkNCodeData = useSelector((state) => state?.affiliate?.linkNCode);
  const affiliateId = useSelector((state) => state?.affiliate?.profile?._id);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getLinksNCodeAction(
        currentPage,
        perPage,
        setLoading,
        affiliateId,
        isSuperAdmin,
      ),
    );
  }, [currentPage]);

  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "100vh",
      }}
    >
      <SmallLoader />
    </div>
  );

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="tablecard">
        <TableData
          className={classNames("my-referrals-tbl", {
            "super-admin-table": isSuperAdmin,
          })}
          columns={linkNCodeColumns(isSuperAdmin)}
          dataSource={linkNCodeData?.promoCodeList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={linkNCodeData?.pagination?.perPageCount}
          paginationData={linkNCodeData?.pagination}
          scroll={{ x: 500 }}
        />
      </div>
    </>
  );
};

export default LinkNCode;
