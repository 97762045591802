import React from "react";

import {
  CRMProvider,
  EmailTemplateProvider,
  JobOrganizationProvider,
  LoaderProvider,
  OrganizationProvider,
  SMSTemplateProvider,
} from "../common/contexts";
export const AppWrapper = ({ children }) => (
  <>
    <LoaderProvider>
      <JobOrganizationProvider>
        <CRMProvider>
          <OrganizationProvider>
            <SMSTemplateProvider>
              <EmailTemplateProvider>{children}</EmailTemplateProvider>
            </SMSTemplateProvider>
          </OrganizationProvider>
        </CRMProvider>
      </JobOrganizationProvider>
    </LoaderProvider>
  </>
);
