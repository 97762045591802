import { Card, Typography } from "antd";
import React from "react";

import ScheduleDemo from "./common/ScheduleDemo";
import StartFreeTrial from "./common/StartFreeTrial";

const { Title } = Typography;

const TrialBanner = ({ promoCode }) => (
  <Card
    id="trial-banner"
    className="mt-5"
    style={{ borderRadius: 16, textAlign: "center", background: "#1849A9" }}
  >
    <Title style={{ fontSize: 36, fontWeight: 600, color: "#fff" }}>
      Start Your 14 Days Free Trial
    </Title>
    <p style={{ fontSize: 20, color: "#B2DDFF" }}>
      Get in touch with us today to find out how ZevBit can transform the way
      you estimate.
    </p>
    <div className="mt-5 d-flex gap-3 flex-wrap justify-content-center">
      <ScheduleDemo className="m-w-100" promoCode={promoCode} />
      <StartFreeTrial className="m-w-100" type="primary" promoCode={promoCode}>
        Start Free Trial
      </StartFreeTrial>
    </div>
  </Card>
);

export default TrialBanner;
