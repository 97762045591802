import { Button, Col, Form, Input, Row, Select } from "antd";
import { useState } from "react";

import { addNewSourceAction } from "../../../../api/admin";
import { addSourceSchema } from "../../../../validators/auth/auth.validator";
import SmallLoader from "../../../loader/smallLoader";

const options = ["Hot", "Cold"];

function AddSource({
  handleCancel,
  form,
  setForm,
  sourceError,
  setSourceError,
  setAddSourceModel,
  getSourceList,
}) {
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const [loader, setLoader] = useState(false);
  const handleSubmit = async (e) => {
    e && e?.preventDefault();
    setSourceError({});
    const { isValid, errors: formError } = addSourceSchema(form);
    if (!isValid) {
      setSourceError(formError);
    } else {
      setLoader(true);
      const payload = {
        name: form?.name,
        type: form?.type,
      };
      const result = await addNewSourceAction(payload);
      if (result?.remote === "success") {
        getSourceList();
        setLoader(false);
        setAddSourceModel(false);
      } else {
        setLoader(false);
      }
    }
  };

  return (
    <>
      {loader && (
        <div className="center-loader">
          <SmallLoader />
        </div>
      )}
      <div className="add-promo-code">
        <h3 className="fliter-sort-title-add-tab text-center mb-4">
          Add Source
        </h3>
        <Form layout="vertical" onSubmitCapture={handleSubmit}>
          <Row gutter={[24, 0]}>
            <Col xs={24}>
              <Form.Item>
                <Input
                  size="large"
                  placeholder="Source Name"
                  name="name"
                  value={form?.name}
                  onChange={handleChange}
                  style={{ borderRadius: "8px" }}
                />
                <div role="alert" className="text-danger">
                  {sourceError?.name}
                </div>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item>
                {form?.type?.length > 0 ? (
                  <Select
                    className="select-phase-2"
                    size="large"
                    name="type"
                    style={{ width: "100%" }}
                    placeholder="Hot/Cold"
                    options={options.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    value={form?.type}
                    onChange={(value) => setForm({ ...form, type: value })}
                  />
                ) : (
                  <Select
                    className="select-phase-2"
                    size="large"
                    name="type"
                    style={{ width: "100%" }}
                    placeholder="Hot/Cold"
                    options={options.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(value) => setForm({ ...form, type: value })}
                  />
                )}
                <div role="alert" className="text-danger">
                  {sourceError?.type}
                </div>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Button
                onClick={handleCancel}
                style={{ width: "100%", borderRadius: "8px" }}
                size="large"
              >
                Cancel
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                type="primary"
                style={{ width: "100%", borderRadius: "8px" }}
                size="large"
                htmlType="submit"
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
export default AddSource;
