/**
 *
 * @param {number} price - The price of the item
 * @param {number} discount - The discount percentage
 * @returns The discounted price
 */
export function calculateDiscount(price, discount) {
  return price * (discount / 100) || 0;
}

/**
 *
 * @param {number} currentPrice - The current price of the item
 * @param {number} originalPrice - The original price of the item
 * @returns the profite percnetage
 */
export function calculateProfitPercentage(currentPrice, originalPrice) {
  return ((currentPrice / originalPrice) * 100 || 0).toFixed(2);
}

export function calculateDiscountPrice(price, discount) {
  const disc = price * (discount / 100) || 0;
  return (price - disc).toFixed(2);
}
