import moment from "moment";

import { API_DATE_FORMATE_FOR_GRAPH } from "../utils/constants/constants";
import api from "./api";

export async function getJobStages() {
  const response = await api.request({
    method: "get",
    url: "/job-organization/v2/main-board/get-project-stages",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getProjectById(id) {
  const response = await api.request({
    method: "GET",
    url: `/time-tracking/project/${id}`,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getProjectByIdInJobModule(id) {
  const response = await api.request({
    method: "GET",
    url: `/job-organization/v2/job-info-tasks/project/${id}`,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function projectServiceBYId(id) {
  const response = await api.request({
    method: "GET",
    url: `/time-tracking/services/by-project/${id}`,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function projectTaskBYServiceId(id) {
  const response = await api.request({
    method: "GET",
    url: `/time-tracking/task/all_tasks/${id}`,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getAllCrewForJobStage() {
  const response = await api.request({
    method: "GET",
    url: "/crew-route/get/all_crew",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function addGeoup(data) {
  const response = await api.request({
    method: "POST",
    url: "/time-tracking/group/add-group",
    data,
  });
  return response;
}

export async function addTask(data) {
  const response = await api.request({
    method: "POST",
    url: "/job-organization/v2/schedule-task/create-task",
    data,
  });
  return response;
}

export async function getProjectTaskbyProjectId(id) {
  const response = await api.request({
    method: "GET",
    url: `/time-tracking/task/task_by_project/${id}`,
  });
  return response;
}

export async function getGroupByProjectid(id) {
  const response = await api.request({
    method: "GET",
    url: `/time-tracking/group/get-group-by-project/${id}`,
  });
  return response;
}

export async function getExcavationByTaskId(id) {
  const response = await api.request({
    method: "GET",
    url: `/time-tracking/task/taskExclavations/${id}`,
  });
  return response;
}

export async function addSubtask(data) {
  const response = await api.request({
    method: "POST",
    url: "/time-tracking/subtask",
    data,
  });
  return response;
}

export async function uploadTaskImages(file) {
  const formData = new FormData();

  for (let i = 0; i < file.length; i += 1) {
    formData.append("images", file[i]);
  }

  const response = await api.request({
    method: "post",
    url: "/time-tracking/task/uploadtaskimages",
    data: formData,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.data,
    };
  }
  return null;
}

export async function getAvailableCrew(data) {
  const response = await api.request({
    method: "POST",
    url: "/job-organization/available-crew",
    data,
  });
  return response;
}

export async function getAllCrewForCalender(data) {
  let url = `/job-organization/get-available-hours?startDate=${moment(
    data.startDate,
  ).format(API_DATE_FORMATE_FOR_GRAPH)}&endDate=${moment(data.endDate).format(
    API_DATE_FORMATE_FOR_GRAPH,
  )}`;
  if (data?.projectId) {
    url = `/job-organization/get-available-hours?startDate=${moment(
      data.startDate,
    ).format(API_DATE_FORMATE_FOR_GRAPH)}&endDate=${moment(data.endDate).format(
      API_DATE_FORMATE_FOR_GRAPH,
    )}&projectId=${data?.projectId}`;
  }
  const response = await api.request({
    method: "GET",
    url,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getAllOccupiedCrewForCalender(data) {
  const response = await api.request({
    method: "POST",
    url: "/job-organization/occupied-crew-details",
    data,
  });

  return response;
}

export async function getJobInfoData(id) {
  const response = await api.request({
    method: "GET",
    url: `/job-organization/v2/job-info-tasks?projectId=${id}`,
  });
  return response;
}

export async function getActualHoursOfJob(id) {
  const response = await api.request({
    method: "GET",
    url: `/job-organization/actual-hours?projectId=${id}`,
  });
  return response;
}

export const scheduleProject = async (values) => {
  const response = await api.request({
    method: "POST",
    url: "/time-tracking/project/assign-crew-member",
    data: values,
  });
  return response;
};

export const setProjectInQue = async (data) => {
  const response = await api.request({
    method: "POST",
    url: "/job-organization/queue-project",
    data,
  });
  return response;
};

export const setProjectNewSchedule = async (data) => {
  const response = await api.request({
    method: "POST",
    url: "/job-organization/project-schedule/schedule",
    data,
  });
  return response;
};

export const getQueueAll = async () => {
  const response = await api.request({
    method: "GET",
    url: "/job-organization/queue-project",
  });
  return response;
};

export const getQueueAllV2 = async () => {
  const response = await api.request({
    method: "GET",
    url: "/job-organization/v2/main-board/queue-project",
  });
  return response;
};

export const getCalculatedEndDate = async (values) => {
  const response = await api.request({
    method: "POST",
    url: "job-organization/schedule-project-end-date",
    data: values,
  });
  return response;
};

export const getPushBackSchedule = async ({ startDate, endDate, crewId }) => {
  const response = await api.request({
    method: "GET",
    url: `job-organization/push-back-schedule-project?startDate=${startDate}&endDate=${endDate}&crewId=${crewId}`,
  });
  return response;
};

export const getPushBackSchedulePut = async (values) => {
  const response = await api.request({
    method: "PUT",
    url: "job-organization/push-back-schedule-project",
    data: values,
  });
  return response;
};

export const getDifferenceBetweenDatesAPI = async (values) => {
  const response = await api.request({
    method: "POST",
    url: "job-organization/scheduled-duration",
    data: values,
  });
  return response;
};

export const postProjectSchedule = async (values) => {
  const response = await api.request({
    method: "POST",
    url: "job-organization/assign-crew-member",
    data: values,
  });
  return response;
};

export const getDataForProject = async (id) => {
  const response = await api.request({
    method: "GET",
    url: `job-organization/scheduled-project?projectId=${id}`,
  });
  return response;
};

export const getSubContractorByOrganization = async () => {
  const response = await api.request({
    method: "GET",
    url: "job-organization/sub-contractor-task",
  });
  return response;
};

export const getSubContractorByProject = async (projectId) => {
  const response = await api.request({
    method: "GET",
    url: `job-organization/sub-contractor-task?projectId=${projectId}`,
  });
  return response;
};

export const getCrewMembersDetail = async (queryString) => {
  const response = await api.request({
    method: "GET",
    url: `job-organization/crew-members-details?${queryString}`,
  });
  return response;
};

export const handleProjectScheduleEndDates = async (data) => {
  const response = await api.request({
    method: "post",
    url: "job-organization/schedule-project-end-date",
    data,
  });
  return response;
};

export const handleGetNonWorkingDay = async (data) => {
  const response = await api.request({
    method: "get",
    url: `job-organization/non-working-day?crewId=${data?.crewId}&projectId=${data?.projectId}&startDate=${data?.startDate}&endDate=${data?.endDate}`,
  });
  return response;
};

export const deleteTaskOfservice = async (id, data) => {
  const response = await api.request({
    url: `/job-organization/v2/end-job-process/${id}`,
    method: "DELETE",
    data,
  });

  return response;
};

export const getendOfProgressDetail = async (projectId) => {
  const response = await api.request({
    method: "GET",
    url: `job-organization/v2/end-job-process?projectId=${projectId}`,
  });
  return response;
};

export const updateProjectServiceTaskStatus = async (paylaod) => {
  const response = await api.request({
    method: "PUT",
    url: "job-organization/v2/end-job-process/update-task-status",
    data: paylaod,
  });
  return response;
};

export const updateProjectCompleteStatus = async (projectId) => {
  const response = await api.request({
    method: "PUT",
    url: `job-organization/v2/end-job-process/update-project-status/${projectId}`,
  });
  return response;
};

export const getJobMaterial = async (projectId) => {
  const response = await api.request({
    method: "GET",
    url: `job-organization/v2/material?projectId=${projectId}`,
  });
  return response;
};

export const getAllProjectId = async (estimateNumber) => {
  const response = await api.request({
    method: "GET",
    url: `/job-organization/v2/main-board/get-project-by-estimate-number?estimateNumber=${estimateNumber}`,
  });
  return response;
};

export const getPhotosAndNotes = async (projectId) => {
  const response = await api.request({
    method: "GET",
    url: `job-organization/v2/attachments?projectId=${projectId}`,
  });
  return response;
};

export const getServiceProgressValueForChart = async (serviceId) => {
  const response = await api.request({
    method: "GET",
    url: `job-organization/v2/end-job-process/project-service-progress?id=${serviceId}`,
  });
  return response;
};

export const getProjectProgressValueForChart = async (projectId) => {
  const response = await api.request({
    method: "GET",
    url: `job-organization/v2/end-job-process/project-progress?projectId=${projectId}`,
  });
  return response;
};

export const getProjectReciptLists = async (projectId) => {
  const response = await api.request({
    method: "GET",
    url: `job-organization/v2/material/receipts?projectId=${projectId}`,
  });
  return response;
};

export const deleteProjectServiceMaterial = async (materialId) => {
  const response = await api.request({
    method: "delete",
    url: `material-tracking/material/${materialId}`,
  });
  return response;
};

export const addProjectServiceMaterialReceipt = async (payload) => {
  const response = await api.request({
    method: "post",
    url: "material-tracking/receipt",
    data: payload,
  });
  return response;
};

export const updateProjectServiceMaterialValue = async (payload) => {
  const response = await api.request({
    method: "put",
    url: "material-tracking/material/update-material",
    data: payload,
  });
  return response;
};

export const AddProjectServiceNewMaterial = async (payload) => {
  const response = await api.request({
    method: "post",
    url: "job-organization/v2/material",
    data: payload,
  });
  return response;
};

export const AddProjectMiscellaneousMaterial = async (payload) => {
  const response = await api.request({
    method: "post",
    url: "material-tracking/material/add-miscellaneous-material",
    data: payload,
  });
  return response;
};

export const getInvoiceListOfUser = async (id) => {
  const response = await api.request({
    method: "get",
    url: `job-organization/v2/end-job-process/project-invoice?projectId=${id}`,
  });
  return response;
};

export const addProjectMiscellaneousAdminTask = async (payload) => {
  const response = await api.request({
    method: "post",
    url: "job-organization/v2/admin-task",
    data: payload,
  });
  return response;
};

export const getAssigneeList = async () => {
  const response = await api.request({
    method: "get",
    url: "job-organization/v2/admin-task/assignee-list",
  });
  return response;
};

export const deleteAdminTaskOfservice = async (id) => {
  const response = await api.request({
    url: `job-organization/v2/admin-task/${id}`,
    method: "DELETE",
  });

  return response;
};

export const deleteSubContractorTaskOfservice = async (id) => {
  const response = await api.request({
    url: `job-organization/v2/sub-contractor/${id}`,
    method: "DELETE",
  });

  return response;
};

export const addProjectMiscellaneousSubContractorTask = async (payload) => {
  const response = await api.request({
    method: "post",
    url: "job-organization/v2/sub-contractor",
    data: payload,
  });
  return response;
};

export const updateSubcontracterTasks = async (payload) => {
  const response = await api.request({
    method: "PUT",
    url: `job-organization/v2/sub-contractor/${payload.taskId}`,
    data: payload,
  });
  return response;
};

export const updateAdminTasks = async (payload) => {
  const response = await api.request({
    method: "PUT",
    url: `job-organization/v2/admin-task/${payload.taskId}`,
    data: payload,
  });
  return response;
};

export const getSubContractorList = async () => {
  const response = await api.request({
    method: "GET",
    url: "job-organization/v2/sub-contractor/list",
  });
  return response;
};

export const scheduledProject = async (payload) => {
  const response = await api.request({
    method: "POST",
    url: "job-organization/v2/schedule-project",
    data: payload,
  });
  return response;
};

export const scheduleProjectTaskV2 = async (payload) => {
  const response = await api.request({
    method: "post",
    url: "job-organization/v2/schedule-task",
    data: payload,
  });
  return response;
};

export const pushUpProjects = async (payload) => {
  const response = await api.request({
    method: "post",
    url: "/job-organization/v2/main-board/pushback-project",
    data: payload,
  });
  return response;
};
export const deleteCrewTaskByTaskId = async (id) => {
  const response = await api.request({
    method: "DELETE",
    url: `/job-organization/v2/schedule-task/delete-task/${id}`,
  });
  return response;
};

export const editCrewTask = async (payload) => {
  const response = await api.request({
    url: `job-organization/v2/schedule-task/update-task/${payload?.selectedTaskId}`,
    method: "PUT",
    data: payload,
  });
  return response;
};

export const deleteCrewTaskOfservice = async (id) => {
  const response = await api.request({
    url: `job-organization/v2/schedule-project/${id}`,
    method: "DELETE",
  });
  return response;
};

export const updateMiscellaneousAndOtherServiceAndTaskStatus = async (
  payload,
) => {
  const response = await api.request({
    url: "job-organization/v2/end-job-process/update-task-status",
    method: "PUT",
    data: payload,
  });
  return response;
};
export const getCrewTaskAllCrewMember = async () => {
  const response = await api.request({
    url: "job-organization/v2/crew/crew-list",
    method: "get",
  });
  return response;
};
export const getAllScheduleAlert = async () => {
  const response = await api.request({
    url: "job-organization/v2/main-board/scheduled-alert",
    method: "GET",
  });
  return response;
};

export const getScheduleProject = async (id) => {
  const response = await api.request({
    method: "GET",
    url: `job-organization/v2/schedule-project?projectId=${id}`,
  });
  return response;
};
export const getAllScheduleAlertList = async (
  projectId,
  countReviewProject,
) => {
  const response = await api.request({
    url: `job-organization/v2/main-board/project-service-alert?projectId=${projectId}&countReviewProject=${countReviewProject}`,
    method: "GET",
  });
  return response;
};

export const getCrewWorkingHours = async (startDate, endDate, crewId) => {
  const response = await api.request({
    method: "GET",
    url: `job-organization/v2/crew/crew-working-hours?startDate=${startDate}&endDate=${endDate}&crewId=${crewId}`,
  });
  return response;
};
export const UpdateAdminScheduleAlertStatus = async (projectId, payload) => {
  const response = await api.request({
    url: `job-organization/v2/admin-task/conflict-status/${projectId}`,
    method: "PUT",
    data: payload,
  });
  return response;
};

export const UpdateSubContractorScheduleAlertStatus = async (
  projectId,
  payload,
) => {
  const response = await api.request({
    url: `/job-organization/v2/sub-contractor/conflict-status/${projectId}`,
    method: "PUT",
    data: payload,
  });
  return response;
};

export const UpdateExternalInternalScheduleAlertIgnoreStatus = async (
  payload,
) => {
  const response = await api.request({
    url: "/job-organization/v2/crew/ignore-alert",
    method: "post",
    data: payload,
  });
  return response;
};
export const UpdateExternalInternalScheduleAlertActiveStatus = async (
  payload,
) => {
  const response = await api.request({
    url: "/job-organization/v2/crew/active-alert",
    method: "post",
    data: payload,
  });
  return response;
};
export const getProjectListForFilterModal = async () => {
  const response = await api.request({
    url: "/job-organization/v2/main-board/project-list",
    method: "get",
  });
  return response;
};

export const getCrewAvailaibility = async ({
  projectId,
  startDate,
  endDate,
}) => {
  const response = await api.request({
    url: `/job-organization/v2/crew/crew-availability?startDate=${startDate}&endDate=${endDate}&projectId=${[
      projectId,
    ]}&projectStartDate=${startDate}`,
    method: "get",
  });
  return response;
};
export const getProjectServiceListForFilterModal = async () => {
  const response = await api.request({
    url: "/job-organization/v2/main-board/project-service-list",
    method: "get",
  });
  return response;
};

export const getScheduleDataForWorkDay = async (crewId, date) => {
  const response = await api.request({
    url: `/job-organization/v2/crew/get-non-working-day?crewId=${crewId}&date=${date}`,
    method: "get",
  });
  return response;
};

export const getTimeFrameData = async (crewId, date) => {
  const response = await api.request({
    url: `/job-organization/v2/crew/get-timeframe?crewId=${crewId}&date=${date}`,
    method: "get",
  });
  return response;
};

export const getOverTimeData = async (crewId, date) => {
  const response = await api.request({
    url: `/job-organization/v2/crew/assigne-overtime-crew-member?crewId=${crewId}&date=${date}`,
    method: "get",
  });
  return response;
};

export const getWorkForceData = async (crewId, date) => {
  const response = await api.request({
    url: `/job-organization/v2/crew/get-workforce?crewId=${crewId}&date=${date}`,
    method: "get",
  });
  return response;
};

export const updateNonWorkingDay = async (data) => {
  const response = await api.request({
    url: "/job-organization/v2/crew/non-working-day",
    method: "post",
    data,
  });
  return response;
};

export const ignoreAlertsAPI = async (data) => {
  const response = await api.request({
    url: "/job-organization/v2/crew/ignore-alert",
    method: "post",
    data,
  });
  return response;
};

export const activeAlertsAPI = async (data) => {
  const response = await api.request({
    url: "/job-organization/v2/crew/active-alert",
    method: "post",
    data,
  });
  return response;
};

export const deleteNonWorkingDay = async (data) => {
  const response = await api.request({
    url: "/job-organization/v2/crew/delete-non-working-day",
    method: "delete",
    data,
  });
  return response;
};

export const assignOvertimeCrewMember = async (data) => {
  const response = await api.request({
    url: "/job-organization/v2/crew/assigne-overtime-crew-member",
    method: "post",
    data,
  });
  return response;
};

export const deleteOvertime = async (data) => {
  const response = await api.request({
    url: "/job-organization/v2/crew/delete-overtime",
    method: "delete",
    data,
  });
  return response;
};

export const deleteTimeFrame = async (data) => {
  const response = await api.request({
    url: "/job-organization/v2/crew/delete-timeframe",
    method: "delete",
    data,
  });
  return response;
};
export const ScheduleProjectService = async (payload) => {
  const response = await api.request({
    url: "/job-organization/v2/schedule-project-service",
    method: "post",
    data: payload,
  });
  return response;
};

export const crewTaskSequence = async (payload) => {
  const response = await api.request({
    url: "/job-organization/v2/schedule-task/crew-task-sequence",
    method: "POST",
    data: payload,
  });
  return response;
};

export const getCrewUserList = async (crewId, date) => {
  const response = await api.request({
    url: `/job-organization/v2/crew/get-users?crewId=${crewId}&date=${date}`,
    method: "get",
  });
  return response;
};

export const addTemporaryCrewMember = async (payload) => {
  const response = await api.request({
    url: "/job-organization/v2/crew/add-temporary-member",
    method: "post",
    data: payload,
  });
  return response;
};
export const deleteTemporaryCrewMember = async (payload) => {
  const response = await api.request({
    url: "job-organization/v2/crew/delete-temporary-member",
    method: "delete",
    data: payload,
  });
  return response;
};

export const addUpdateCrewMember = async (payload) => {
  const response = await api.request({
    url: "job-organization/v2/crew/create-timeframe",
    method: "post",
    data: payload,
  });
  return response;
};

export const deleteCrewService = async (payload) => {
  const response = await api.request({
    url: "job-organization/v2/schedule-project-service/delete-crew-service",
    method: "delete",
    data: payload,
  });
  return response;
};

export const getCalendarMainView = async () => {
  const response = await api.request({
    url: "job-organization/v2/main-board/calendar-view",
    method: "get",
  });
  return response;
};

export const scheduleProjectStatus = async (payload) => {
  const response = await api.request({
    method: "PUT",
    url: `/job-organization/v2/schedule-project/status/${payload?._id}`,
    data: payload,
  });
  return response;
};

export const addProjectToQueue = async (payload) => {
  const response = await api.request({
    method: "POST",
    url: "/job-organization/v2/main-board/add-project-queue",
    data: payload,
  });
  return response;
};
export const getTemplateServices = async (id) => {
  const response = await api.request({
    url: `job-organization/v2/schedule-project-service/get-service-templates?serviceId=${id}`,
    method: "get",
  });
  return response;
};

export const addTemplateServicesIntoCrewTask = async (payload) => {
  const response = await api.request({
    url: "job-organization/v2/schedule-task/create-template-task",
    method: "post",
    data: payload,
  });
  return response;
};

export const getUserCrewTaskList = async (data) => {
  const response = await api.request({
    method: "GET",
    url: `job-organization/v2/schedule-task/user-crew-task-list?startDate=${moment(
      data.startDate,
    ).format(API_DATE_FORMATE_FOR_GRAPH)}&endDate=${moment(data.endDate).format(
      API_DATE_FORMATE_FOR_GRAPH,
    )}`,
  });
  return response;
};

export const getUserSubContractorTaskList = async (data) => {
  const response = await api.request({
    method: "GET",
    url: `job-organization/v2/sub-contractor/all-task-list?startDate=${moment(
      data.startDate,
    ).format(API_DATE_FORMATE_FOR_GRAPH)}&endDate=${moment(data.endDate).format(
      API_DATE_FORMATE_FOR_GRAPH,
    )}`,
  });
  return response;
};
