import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";

import { Button, Col, Input, Modal, Row, Space } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";

const RangeDatePiker = () => {
  const [datePikerModal, setSetDatePikerModal] = useState(false);
  const showDatePikerModal = () => {
    setSetDatePikerModal(true);
  };
  const handleOk = () => {
    setSetDatePikerModal(false);
  };

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelect = (ranges) => {
    setSelectedDateRange(ranges.selection);
  };

  const handleCancel = () => {
    setSetDatePikerModal(false);
  };
  return (
    <>
      <Input
        onClick={showDatePikerModal}
        size="large"
        style={{ borderRadius: "8px" }}
        value={`${dayjs(selectedDateRange.startDate).format(
          "MMM DD YYYY",
        )} - ${dayjs(selectedDateRange.endDate).format("MMM DD YYYY")}`}
      />
      <Modal
        centered
        title={false}
        visible={datePikerModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={890}
        bodyStyle={{ padding: "0px" }}
        closable={false}
      >
        <div className="range-date-piker">
          <DateRangePicker
            onChange={handleSelect}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[selectedDateRange]}
            direction="horizontal"
          />
          <div className="p-4">
            <Row gutter={[24, 24]}>
              <Col xs={10} offset={6}>
                <Space size={[8, 16]}>
                  <Input
                    size="large"
                    style={{ borderRadius: "8px" }}
                    value={`${dayjs(selectedDateRange.startDate).format(
                      "MMM DD YYYY",
                    )}`}
                  />
                  <span>-</span>
                  <Input
                    size="large"
                    style={{ borderRadius: "8px" }}
                    value={`${dayjs(selectedDateRange.endDate).format(
                      "MMM DD YYYY",
                    )}`}
                  />
                </Space>
              </Col>
              <Col xs={8} className="text-right">
                <Space size={[8, 16]}>
                  <Button
                    onClick={handleCancel}
                    size="large"
                    style={{ borderRadius: "8px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    style={{ borderRadius: "8px" }}
                  >
                    Apply
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default RangeDatePiker;
