import { Button, Col, Form, Input, message, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";

import { submitLeadForm } from "../../api/affiliateLanding";

const LeadForm = () => {
  const [form] = Form.useForm();
  const promoCode = useSelector((state) => state?.landingDetails?.promoCode);
  const handleFormSubmit = async (formData) => {
    const result = await submitLeadForm({ promoCode, ...formData });
    if (result.remote === "success") {
      form.resetFields();
      message.success(result?.data?.message);
    } else {
      message.error(result?.error?.message || result?.error);
    }
  };
  return (
    <Form layout="horizontal" onFinish={handleFormSubmit} form={form}>
      <Row gutter={[14, 14]}>
        <Col xs={12} lg={9}>
          <Form.Item name="name">
            <Input placeholder="Name" required />
          </Form.Item>
        </Col>
        <Col xs={12} lg={9}>
          <Form.Item
            name="contactNumber"
            rules={[
              () => ({
                validator(_, value) {
                  if (/^\d+$/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Invalid phone number"));
                },
              }),
            ]}
          >
            <Input placeholder="Phone" type="tel" required maxLength={10} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[14, 14]}>
        <Col xs={12} lg={9}>
          <Form.Item name="email">
            <Input placeholder="Email" type="email" required />
          </Form.Item>
        </Col>
        <Col xs={12} lg={9}>
          <Form.Item name="companyName">
            <Input placeholder="Company" required />
          </Form.Item>
        </Col>
        <Col xs={12} lg={6}>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ height: 32 }}>
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default LeadForm;
