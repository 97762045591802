export const transformSignUpResponse = (response) => ({
  token: response?.data?.token,
  message: response.data.message,
  user: transformUserResponse(response?.data?.data?.user),
});

export const transformUserResponse = (response) => ({
  id: response?._id,
  name: response?.name,
  email: response?.email,
  profileImage: response?.profileImage,
  userRole: response?.userRole,
  createdAt: response?.createdAt,
  updatedAt: response?.updatedAt,
  companyName: response?.companyName,
  companyImage: response?.companyImage,
  currency: response?.currency,
  timeZone: response?.timeZone,
  activeStatus: response?.activeStatus,
  selectedPlan: response?.selectedPlan,
  withTrialPeriod: response?.withTrialPeriod,
  companyNumber: response?.companyNumber,
});
