import { Button, Card } from "antd";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useLocation, useNavigate } from "react-router-dom";

import {
  projectSummaryMentions,
  replacePatterns,
} from "../../../utils/suggestions";
import SmallLoader from "../../loader/smallLoader";
import { BackBtn } from "../svg.icons";
import { getInvoicesSetting, setInvoicesSetting } from "./helper";
export default function InvoiceNote() {
  const navigate = useNavigate();
  const location = useLocation();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [section, setSection] = useState("");
  const [loading, setIsLoading] = useState(true);
  const [state, setState] = useState({
    invoiceData: {
      defaultInvoiceNote: "",
      defaultSummaryNote: "",
    },
  });

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };
  const handleGetInvoiceData = async () => {
    await getInvoicesSetting(setState);
    setIsLoading(false);
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    const updatedObject = {
      [section]: replacePatterns(
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      ),
    };
    await setInvoicesSetting(updatedObject);
    navigate(-1); // navigate to invoice settings
    setIsLoading(false);
  };

  useEffect(() => {
    if (loading) {
      handleGetInvoiceData();
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // Retrieve the value of the "section" query parameter
    setSection(queryParams.get("section"));
  }, []);
  useEffect(() => {
    if (state.invoiceData[section]) {
      const contentBlock = htmlToDraft(state.invoiceData[section]);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [state.invoiceData]);

  return (
    <>
      {loading && (
        <div className="center-loader">
          <SmallLoader />
        </div>
      )}
      <Card
        bordered={false}
        className="invoice-header-card mb-3"
        bodyStyle={{ padding: "24px" }}
      >
        <div className="d-flex align-items-center gap-3 mb-2">
          <h2 className="invoice-title m-0">Invoice Settings</h2>
          <Button
            type="primary"
            className="ms-auto"
            disabled={loading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
        <div
          className="back d-inline-flex align-items-center gap-2 pointer-cursor mb-3"
          onClick={() => navigate("/invoice-setting")}
        >
          {BackBtn}{" "}
          <span>
            {section === "defaultSummaryNote"
              ? "Default Summary Note"
              : "Default Invoice Note"}
          </span>
        </div>
        <div className="border radius-8 p-3 h-150">
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class editor-class-body"
            toolbarClassName="toolbar-class"
            mention={{
              trigger: "@",
              separator: " ",
              suggestions: projectSummaryMentions,
            }}
          />
        </div>
      </Card>
    </>
  );
}
