import { Avatar, Button, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ReactComponent as ArrowLeft } from "../../../../../images/affiliateImages/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../../../images/affiliateImages/arrow-right.svg";
import { ReactComponent as InfoIcon } from "../../../../../images/warning-icon.svg";
import { createValidUrl } from "../../../../../utils/commonFuctions";

const { Title } = Typography;
const limit = 1;

const PastReports = ({ type, handleCancel, currentPage, setCurrentPage }) => {
  const reportList = useSelector(
    (state) => state?.affiliate?.docNPay?.reported,
  );
  const [totalPage, setTotalPage] = useState(1);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(1);

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage((current) => current - 1);
  };

  const handleNext = () => {
    if (currentPage < reportList?.[type]?.length)
      setCurrentPage((current) => current + 1);
  };

  const resetState = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    if (reportList?.[type]?.length) {
      setStart((currentPage - 1) * limit);
      setEnd((currentPage - 1) * limit + 1);
      setTotalPage(reportList?.[type]?.length / limit);
    }
  }, [reportList, currentPage]);

  return (
    <>
      <div className="text-center mb-3 warning-shadow mx-auto">
        <InfoIcon width={20} height={20} color="#ff0000" />
      </div>
      <Title level={4} className="text-center" style={{ fontWeight: "normal" }}>
        Past Reports
      </Title>
      {reportList?.[type]?.slice(start, end)?.map((obj) => (
        <>
          <p style={{ color: "#667085", maxHeight: "150px", overflow: "auto" }}>
            {obj?.note}
          </p>
          <div className="d-flex">
            <div
              style={{
                borderRadius: "50%",
                height: 24,
                width: 24,
                marginRight: 8,
              }}
            >
              <Avatar
                src={createValidUrl(obj?.user?.profileImage)}
                style={{
                  height: 24,
                  width: 24,
                }}
              />
            </div>
            <span>{obj?.user?.name}</span>
          </div>
        </>
      ))}
      {reportList?.[type]?.length > 1 && (
        <Row className="mt-3" justify="space-between" align="middle">
          <Col>
            <Button
              style={{ borderRadius: 8 }}
              icon={<ArrowLeft style={{ color: "#344054" }} />}
              onClick={handlePrev}
            />
          </Col>
          <Col>
            Page {currentPage} of {totalPage}
          </Col>
          <Col>
            <Button
              style={{ borderRadius: 8 }}
              icon={<ArrowRight style={{ color: "#344054" }} />}
              onClick={handleNext}
            />
          </Col>
        </Row>
      )}
      <Button
        className="w-100"
        onClick={() => {
          resetState();
          handleCancel();
        }}
        style={{ height: 44, borderRadius: 8, marginBottom: 24, marginTop: 32 }}
      >
        Close
      </Button>
    </>
  );
};

export default PastReports;
