import { Button } from "antd";
import React from "react";

import CrossIcon from "../../../../../images/CrossIcon.png";
import RightIcon from "../../../../../images/RightIcon.png";

const InitialUpload = ({
  documentData,
  handleDeleteId,
  download,
  setFormModal,
}) => (
  <div className="documentAndPayment">
    <div className="topHeading_dAP">
      <h4>Documentation Required</h4>
      <p>
        The following documentation is required to be submitted and reviewed by
        our team. Once the documentation is reviewed we will approve your
        account. When your account is approved you will receive an email with
        your unique affiliate link and promo code!
      </p>
    </div>
    <div className="top_documentAndPayment_heading">
      <h4>Step 1 - ID</h4>
      <p>
        Upload a clear image of your identification document. This could be your
        driver&lsquo;s license, passport, or national identity card.
      </p>
      <div className="forFlex">
        <Button
          type="primary"
          size="large"
          onClick={() =>
            setFormModal((prev) => ({
              ...prev,
              idCardModal: !prev?.idCardModal,
            }))
          }
        >
          Upload ID
        </Button>
        {documentData?.idCardImageName && (
          <>
            <div className="forUpload">
              <img src={RightIcon} alt="" />
              <p className="upload_dAP">Uploaded</p>
            </div>
            <div className="forFileName">
              <p className="filename_dAP">
                <span
                  onClick={() =>
                    download(
                      documentData?.idCardImageUrl,
                      documentData?.idCardImageName,
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  {documentData?.idCardImageName}
                </span>
              </p>
              <span onClick={() => handleDeleteId("idCard")}>
                <img src={CrossIcon} alt="" />
              </span>
            </div>
          </>
        )}
      </div>
    </div>
    <div className="center_documentAndPayment_heading">
      <h4>Step 2 - W9</h4>
      <p>
        The W-9 form (FW9) is required by the Internal Revenue Service and
        allows us to comply with tax regulations. The W-9 form collects your tax
        information to properly report any relevant transactions.
      </p>
      <ol>
        <li>
          {" "}
          Fill out a W9 form by clicking{" "}
          <a
            href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{" "}
        </li>
        <li>
          Sign the W9 Form (You can sign via an E-Signature software (Adobe,
          Docusign etc) or Print out the W9 form and manually sign it.
        </li>
        <li> Upload the W9 form below</li>
      </ol>

      <div className="forFlex">
        <Button
          type="primary"
          size="large"
          onClick={() =>
            setFormModal((prev) => ({
              ...prev,
              w9FormModal: !prev?.w9FormModal,
            }))
          }
          disabled={!documentData?.idCardImageName}
        >
          Upload W9 Form
        </Button>
        {documentData?.w9FormName && (
          <>
            <div className="forUpload">
              <img src={RightIcon} alt="" />
              <p className="upload_dAP">Uploaded</p>
            </div>
            <div className="forFileName">
              <p className="filename_dAP">
                <span
                  onClick={() =>
                    download(documentData?.w9FormUrl, documentData?.w9FormName)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {documentData?.w9FormName}
                </span>
              </p>
              <span onClick={() => handleDeleteId("w9Form")}>
                <img src={CrossIcon} alt="" />
              </span>
            </div>
          </>
        )}
      </div>
    </div>
    <div className="bottom_documentAndPayment_heading">
      <h4>Step 3 - Payment Info</h4>
      <p>
        You&lsquo;re almost done! The last step is to provide us with your
        default payment method. This is how ZevBit will pay you the commissions
        you earn as an affiliate! Click below to submit your payment info.
      </p>
      <div className="bottom_dAP_button forFlex">
        <Button
          type="primary"
          size="large"
          onClick={() =>
            setFormModal((prev) => ({
              ...prev,
              paymentModal: !prev?.paymentModal,
            }))
          }
          disabled={!documentData?.idCardImageName || !documentData?.w9FormName}
        >
          Select Payment Method
        </Button>
      </div>
    </div>
  </div>
);

export default InitialUpload;
