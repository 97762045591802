import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React from "react";

const CreatePassword = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget)
  };

  return (
    <Box
      component={Paper}
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5">
        Create a password to access your zevbit account
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          name="password"
          id="password"
          fullWidth
          label="Your password"
          type="password"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          name="repeatPassword"
          id="repeatPassword"
          fullWidth
          label="Repeat your password"
          type="password"
          autoFocus
        />
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} md={2}>
            <Button type="button" variant="outlined" sx={{ mt: 3, mb: 2 }}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} md={5}>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, pr: 3 }}
            >
              Create password
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreatePassword;
