import { Button, Col, Form, Input, message, Row } from "antd";

import { resetPasswordUserList } from "../../../../api/admin";
import { validatePassword } from "../../../../utils/constants/constants";

function SetNewPassword({
  setShowPasswordModel,
  password,
  setPassword,
  selectedUser,
  showError,
  setShowError,
}) {
  const handleSubmit = async () => {
    if (!validatePassword(password) || password?.length === 0) {
      setShowError(true);
    } else {
      const payload = {
        userId: selectedUser,
        newPassword: password,
      };
      const response = await resetPasswordUserList(payload);
      if (response.remote === "success") {
        setShowPasswordModel(false);
        setPassword("");
        message.success(response?.data?.message);
      }
    }
  };
  return (
    <div className="add-promo-code">
      <h3 className="fliter-sort-title-add-tab">Set New Password</h3>
      <Form layout="vertical" onSubmitCapture={handleSubmit}>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Form.Item>
              <label className="ant-label-login">Password</label>
              <Input.Password
                style={{ borderRadius: "8px", height: "45px" }}
                size="large"
                className="ant-login-input"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div role="alert" className="text-danger">
                {showError && (
                  <>
                    Password must have: <br />
                    1. Minimum 8 characters <br />
                    2. Atleast one upper case and smaller case required <br />
                    3. Atleast one special character required <br />
                    4. Atleast one number required <br /> 5. No spaces accepted
                  </>
                )}
              </div>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Button
              className="setbtn"
              onClick={() => setShowPasswordModel(false)}
              style={{ width: "100%", borderRadius: "8px" }}
              size="large"
            >
              Cancel
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              className="setbtn"
              style={{ width: "100%", borderRadius: "8px" }}
              size="large"
              htmlType="submit"
              type="primary"
            >
              Save Password
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export default SetNewPassword;
