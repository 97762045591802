import "bootstrap/dist/css/bootstrap.css";
import "./overview.css";

import { Col, Row, Table, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as OverviewIcon } from "../../../../../images/affiliateImages/overview.svg";
import { getReferralsOverviewAction } from "../../../../../redux/affiliate/affiliate.action";
import {
  affiliateOverviewData,
  avoidColumns,
  overviewAction,
} from "../../../../setting/UserListV2/details/helper";
import TableData from "../../../../setting/UserListV2/Table";
import RangePickerFilter from "./RangePickerFilter";

const { Title, Text } = Typography;

const Overview = ({ isSuperAdmin = false }) => {
  const [openRangeSelector, setOpenRangeSelector] = useState(false);
  const [value, setValue] = useState(null);
  const overviewTblData = useSelector((state) => state?.affiliate?.overview);
  const affiliateId = useSelector((state) => state?.affiliate?.profile?._id);
  const dispatch = useDispatch();
  const promoCodeOverviewColumns = [
    {
      title: "Initial Action",
      dataIndex: "action",
      render: (v) => overviewAction[v],
      width: 216,
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      render: () => "-",
    },
    {
      title: "Filled Out/Requested",
      dataIndex: "filledOutRequested",
      render: (v, row) => <>{row?.action === "directSignUp" ? "-" : v}</>,
    },
    {
      title: "Signed Up",
      dataIndex: "signedUp",
      render: (v) => v,
    },
    {
      title: "Canceled on Trial",
      dataIndex: "canceledOnTrial",
      render: (v) => v,
    },
    {
      title: "Purchased After Trial",
      dataIndex: "purchasedOnTrial",
      render: (v) => v,
    },
    {
      title: "Canceled",
      dataIndex: "canceled",
      render: (v) => v,
    },
    {
      title: "Card Declined",
      dataIndex: "onHold",
      render: (v) => v,
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (v) => v,
    },
  ];

  const handleRangeToggle = (toggle) => {
    setOpenRangeSelector(toggle);
  };

  const handleApplyClick = async () => {
    if (value?.every((val) => val)) {
      const start = moment(value[0]).format("YYYY-MM-DD");
      const end = moment(value[1]).format("YYYY-MM-DD");
      handleRangeToggle(false);
      dispatch(
        getReferralsOverviewAction(affiliateId, isSuperAdmin, start, end),
      );
    } else {
      handleRangeToggle(false);
      dispatch(getReferralsOverviewAction());
    }
  };

  const handleRangeChange = (date) => {
    setValue(date);
    handleRangeToggle(true);
  };

  return (
    <>
      <Row gutter={[24, 16]} className="mt-5">
        <Col xs={24}>
          <div className="affiliate-overview d-flex align-items-center justify-content-between justify-content-between flex-wrap gap-3">
            <Title level={4} style={{ fontWeight: "normal", marginBottom: 20 }}>
              <OverviewIcon style={{ marginRight: 5, color: "#3483FA" }} />
              <span>Overview</span>
            </Title>
            <RangePickerFilter
              value={value}
              openRangeSelector={openRangeSelector}
              setOpenRangeSelector={setOpenRangeSelector}
              handleApplyClick={handleApplyClick}
              handleRangeToggle={handleRangeToggle}
              handleRangeChange={handleRangeChange}
            />
          </div>
        </Col>
        <Col xs={24} className="p-0">
          <div className="overview-table">
            <TableData
              scroll={{
                x: 500,
              }}
              columns={promoCodeOverviewColumns}
              dataSource={
                overviewTblData?.affiliateOverview?.length
                  ? overviewTblData?.affiliateOverview
                  : affiliateOverviewData
              }
              summary={(pageData) => {
                const total = {};
                pageData.forEach((obj) => {
                  Object.keys(obj).forEach((key) => {
                    if (key !== "action") {
                      if (obj?.action !== "directSignUp") {
                        if (total?.[key]) total[key] += obj[key];
                        else total[key] = obj[key];
                      } else {
                        if (total?.[key] && key !== "filledOutRequested")
                          total[key] += obj[key];
                        else if (key !== "filledOutRequested")
                          total[key] = obj[key];
                      }
                    }
                  });
                });
                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell>
                      <Text style={{ color: "#3483FA", fontWeight: 600 }}>
                        Total
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text style={{ color: "#3483FA", fontWeight: 600 }}>
                        {overviewTblData?.totalClick || 0}
                      </Text>
                    </Table.Summary.Cell>
                    {promoCodeOverviewColumns.map(
                      ({ dataIndex }) =>
                        !avoidColumns.includes(dataIndex) && (
                          <Table.Summary.Cell key={`cell-${dataIndex}`}>
                            <Text style={{ color: "#3483FA", fontWeight: 600 }}>
                              {total[dataIndex]}
                            </Text>
                          </Table.Summary.Cell>
                        ),
                    )}
                  </Table.Summary.Row>
                );
              }}
              pagination={false}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Overview;
