import { message } from "antd";

export const displayLoadingMessage = (content, duration, style) => {
  const key = "updatable";
  return message.loading({
    content,
    key,
    duration,
    style,
  });
};

export const displaySuccessMessage = (content, duration, style) => {
  const key = "updatable";
  return message.success({
    content,
    key,
    duration,
    style,
  });
};

export const displayErrorMessage = (content, duration, style) => {
  const key = "updatable";
  return message.error({
    content,
    key,
    duration,
    style,
  });
};
