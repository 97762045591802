import { message } from "antd";

import SmallLoader from "../components/loader/smallLoader";
import { CLOUD_BASE_URL } from "../config";
import store from "../store";

export const handleFormatData = (value) => {
  let date;
  if (value) {
    let day = "";
    let month = "";
    let year = "";
    day = value.getDate();
    if (day.toString().length === 1) {
      day = `0${day}`;
    }
    month = value.getMonth();
    month += 1;
    if (month.toString().length === 1) {
      month = `0${month}`;
    }
    year = value.getFullYear();
    date = `${day}/${month}/${year}`;
    return date;
  }
  return null;
};

export const isValidImageUrl = (url) => {
  const s = document.createElement("IMG");
  s.src = url;
  s.onerror = () => false;
  s.onload = () => true;
};

export const replaceImageURL = (url) => {
  let URLPath = null;
  if (url) {
    URLPath = url?.replace(".us-east-2", "");
    return URLPath;
  }
  return URLPath;
};

export const getUrl = (
  selectedLeadStatus,
  selectedLeadSource,
  selectedSalesPerson,
  dates,
  order,
  search,
  page,
  limit,
) => {
  let urlString = `/customer/lead?pageNo=${page || 1}&limit=${limit || 10}`;
  if (selectedLeadStatus?.length) {
    urlString = urlString.concat(
      `&leadStatus=${JSON.stringify(selectedLeadStatus)}`,
    );
  }
  if (selectedLeadSource?.length) {
    urlString = urlString.concat(
      `&leadSource=${JSON.stringify(selectedLeadSource)}`,
    );
  }
  if (dates?.startDate && dates?.endDate) {
    urlString = urlString.concat(
      `&startDate=${dates?.startDate}&endDate=${dates?.endDate}`,
    );
  }
  if (selectedSalesPerson) {
    urlString = urlString.concat(`&salesPerson=${selectedSalesPerson}`);
  }
  if (order) {
    urlString = urlString.concat(`&sort=${order}`);
  }
  if (search) {
    urlString = urlString.concat(`&search=${search}`);
  }
  return urlString;
};

export const createFilterQueryStringUrl = (
  selectedLeadStatus,
  selectedLeadSource,
  selectedSalesPerson,
  dates,
  order,
  search,
  page,
  limit,
  api,
  selectedLeadStatusIds,
) => {
  let urlString = `${api}?pageNo=${page || 1}&limit=${limit || 10}`;
  if (selectedLeadStatus?.length) {
    urlString = urlString.concat(`&status=${selectedLeadStatusIds}`);
  }
  if (selectedLeadSource?.length) {
    urlString = urlString.concat(`&source=${selectedLeadSource.toString()}`);
  }
  if (dates?.startDate && dates?.endDate) {
    urlString = urlString.concat(
      `&startDate=${dates?.startDate}&endDate=${dates?.endDate}`,
    );
  }
  if (selectedSalesPerson) {
    urlString = urlString.concat(`&salesPerson=${selectedSalesPerson}`);
  }
  if (order) {
    urlString = urlString.concat(`&sort=${order}`);
  }
  if (search) {
    urlString = urlString.concat(`&search=${search}`);
  }
  return urlString;
};

export const subtractQuedate = (queueDate) => {
  const todyaDate = new Date();
  const dyncamicDate = new Date(queueDate);

  // To calculate the time difference of two dates
  const differenceInTime = todyaDate.getTime() - dyncamicDate.getTime();

  // To calculate the no. of days between two dates
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  const removeAfterDecimalValue = Math.trunc(differenceInDays);
  return removeAfterDecimalValue;
};

export const decimalValue = (value) => {
  if (value) {
    const outputValue = Number(value)?.toFixed(2);
    if (outputValue) {
      return outputValue;
    }
    return 0;
  }
  return 0;
};

export const handleErrorMessage = (res) => {
  message.error(
    res?.data?.message ||
      res?.errors?.errors?.message ||
      res?.errors?.errors?.error ||
      res?.errors?.errors?.msg ||
      res?.errors?.msg ||
      res?.errors?.errors ||
      "Something went wrong",
  );
};

export const arraylengthCount = (value) => {
  if (value && value?.length) {
    return value?.length;
  }
  return 0;
};

export const getFirstLetterFromEachWord = (value) => {
  if (value && value !== "--") {
    const matches = value.match(/\b(\w)/g);
    const acronym = matches.join("");
    return acronym || "";
  }
  return "--";
};

export const getFirstTwoCharacterfromString = (value) => {
  if (value) {
    const text = getFirstLetterFromEachWord(value);
    return text?.toUpperCase()?.substring(0, 2);
  }
  return "--";
};

export const oridinalNumberForMonth = (value, dayName) => {
  if (+value) {
    const first = [1, 21, 31];
    const second = [2, 22];
    const third = [3, 23];
    const fouth = [
      4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 24, 25, 26,
      27, 28, 29, 30,
    ];
    if (first.includes(+value)) {
      return `${dayName} ${value}st`;
    }
    if (second.includes(+value)) {
      return `${dayName} ${value}nd`;
    }
    if (third.includes(+value)) {
      return `${dayName} ${value}rd`;
    }
    if (fouth.includes(+value)) {
      return `${dayName} ${value}th`;
    }
  } else {
    return "N/A";
  }
  return "";
};

export const createValidUrl = (path) => {
  const cdnToken =
    store.getState().api.cdnToken || localStorage.getItem("cdnToken");
  const newPath = path
    ? path?.includes("https://") ||
      path?.includes("http://") ||
      path?.includes("data:image")
      ? path
      : `${CLOUD_BASE_URL}/${path}?${cdnToken}`
    : "";
  return newPath;
};
export const handleAPILoader = (value) => (
  <div
    className={`text-center d-flex align-items-center justify-content-center ht-250 custom-loader ${
      value || ""
    }`}
  >
    <span className="">
      <SmallLoader />
    </span>
  </div>
);

export const handleToFixValue = (value) => {
  if (value) {
    return value?.toString()?.includes(".") ? +value?.toFixed(2) : value || 0;
  }
  return 0;
};

export const validFileTypeCheck = (file) => {
  const isValidFile =
    file.type === "image/jpg" ||
    file.type === "image/jpeg" ||
    file.type === "image/png";
  if (!isValidFile) {
    message.error("You can only upload JPG or PNG file!");
    return false;
  }
  return true;
};
