import { Col, Row, Select } from "antd";
import React from "react";

const { Option } = Select;
function BooleanComponent({
  element,
  handleChange,
  idx,
  onFocusOut,
  elementList,
}) {
  const [value, setValue] = React.useState("");
  React.useEffect(() => {
    setValue(element.value);
  }, [element.value]);

  const handleValueChange = (value) => {
    setValue(value);
  };

  const handleValueSave = () => {
    handleChange(value, "value", idx);
    onFocusOut();
  };

  return (
    <>
      <Row gutter={[24, 0]} className="mb-3">
        <Col md={8}>
          <label>Element</label>
        </Col>
        <Col md={16}>
          <Select
            style={{ width: "100%" }}
            value={value}
            onChange={handleValueChange}
            onBlur={handleValueSave}
          >
            {elementList.map((element) => (
              <Option key={element._id} value={element._id}>
                {element.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </>
  );
}

export default BooleanComponent;
