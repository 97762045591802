import {
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";

import { getLeadSources } from "../../../api/admin";
import {
  getTopNMostUsedFormula,
  searchFormulaByNameV3,
} from "../../../api/formula";
import CustomButton from "../../../globalComponents/button";
import { setOrganizationLeadSources } from "../../../redux/organization/organization.action";
import { edit, time } from "../../../utils/svg.file";
import GoogleSearchInput from "../../googleSearchInput";
import { POSSIBLE_ANSWER_TYPE } from "../../setting/SetupQuestion/v2/utils/helper";
import Services from "./services.component";

const { Panel } = Collapse;
const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

function CustomerInformation({
  customerDetails,
  handlePersonalInformation,
  handleSpouseInformation,
  handleQuestionChange,
  addService,
  removeService,
  errors,
  handleSubmit,
  isNewLead,
  isUpdatingLead,
  handleUserSelect,
  selectedAssignedUser,
  handleUserDeselect,
  allOrganizationUserList,
}) {
  const isMounted = useRef(false); // using to prevent memory leak
  const [defaultDropDownValue, setDefaultDropDownValue] = useState("");

  const dispatch = useDispatch();
  const [searchError, setSearchError] = useState("");
  const allLeadSources = useSelector((state) => state.organization.leadSources);
  const [searchedService, setSearchedService] = useState([]);
  const [mostSearchedService, setMostSearchedService] = useState([]);
  const [searchService, setSearchService] = useState("");
  const [editAddedServices, setEditAddedServices] = useState(false);
  const [questionsAnswers, setQuestionsAnswers] = useState([]);
  const transformQuestionValue = (questionValue) => {
    let last = 0;
    const res = {};
    const objLength = Object.keys(questionValue);
    const keys = 100 / (objLength.length - 1);
    for (let i = 0; i < objLength.length; i += 1) {
      res[Math.round(last)] = questionValue[objLength[i]];
      last += keys;
    }
    return res;
  };
  useEffect(() => {
    const newQuestions =
      customerDetails?.questionsAnswers?.map((question) => {
        const { questionInputType, questionValue } = question.question || {};
        if (questionInputType === POSSIBLE_ANSWER_TYPE.scale) {
          if (questionValue?.length) {
            const transformedQuestionValue = transformQuestionValue(
              questionValue.length && questionValue[0],
            );
            question.question.questionValue = [transformedQuestionValue];
          }
        }
        return question;
      }) || [];
    if (!questionsAnswers.length && newQuestions) {
      setQuestionsAnswers(newQuestions);
    }
  }, [customerDetails?.questionsAnswers, questionsAnswers.length]);
  const handleManageAddedServices = (id) => {
    if (customerDetails?.services && customerDetails?.services.length) {
      return customerDetails?.services.some(({ _id }) => _id === id) || false;
    }
    return false;
  };

  const getSearchFeildLength = (e) => {
    if (e.target.value === "") {
      setSearchError("");
      setSearchedService("");
      MostUsedFormulas();
    }
  };
  const fetchService = useCallback(async () => {
    const response = await searchFormulaByNameV3(searchService);
    if (response.remote === "success") {
      const fetchedServices = response.data.data;
      if (fetchedServices.length === 0) {
        setSearchError("Data Not Found");
      }

      fetchedServices.forEach((service) => {
        const isAlreadyAdded = customerDetails?.services?.find(
          (item) => item._id === service._id,
        );

        service.alreadyAdded = !!isAlreadyAdded;
      });
      if (isMounted.current) {
        setSearchedService(fetchedServices);
      }
    }
  }, [customerDetails?.services, searchService]);

  const MostUsedFormulas = useCallback(async () => {
    const limit = 10;
    const response = await getTopNMostUsedFormula(limit);
    if (response.remote === "success") {
      const fetchedServices = response.data.data;
      fetchedServices.forEach((service) => {
        const isAlreadyAdded = customerDetails?.services?.find(
          (item) => item._id === service._id,
        );
        service.alreadyAdded = !!isAlreadyAdded;
      });
      if (isMounted.current) {
        setMostSearchedService(fetchedServices);
      }
    }
  }, [customerDetails?.services]);

  const fetchAllLeadSources = useCallback(async () => {
    const response = await getLeadSources();
    if (response.remote === "success") {
      dispatch(setOrganizationLeadSources(response.data.userData));
    }
  }, [dispatch]);
  useEffect(() => {
    isMounted.current = true;
    if (searchService) {
      fetchService();
    }
    MostUsedFormulas();
    if (!allLeadSources.length) {
      fetchAllLeadSources();
    }
    return () => {
      isMounted.current = false;
    };
  }, [searchService]);

  useEffect(() => {
    if (customerDetails && !editAddedServices) {
      setEditAddedServices(false);
    }
  }, [customerDetails, editAddedServices]);

  useEffect(() => {
    setDefaultDropDownValue(allLeadSources[0]);
  }, [defaultDropDownValue]);

  const renderQuestions = (question) => {
    const { questionInputType, questionValue } = question.question || {};
    const { answer } = question;
    const handleChange = handleQuestionChange(question._id, questionInputType);
    switch (questionInputType) {
      case POSSIBLE_ANSWER_TYPE.dropdown:
        let dropdownSelect;
        if (answer && Array.isArray(answer) && answer.length)
          [dropdownSelect = question.question.defaultValue] = Array.isArray(
            answer,
          )
            ? answer
            : [answer];
        else if (typeof answer !== "object") dropdownSelect = answer;
        else dropdownSelect = question.question.defaultValue;
        const query = {};
        if (dropdownSelect) {
          query.value = dropdownSelect;
        }

        return (
          <Select
            {...query}
            onChange={(e) => handleChange(e)}
            style={{ width: "100%" }}
            placeholder="Choose Option"
            size="large"
          >
            {questionValue.map((val, i) => (
              <Option value={val} key={i}>
                {val}
              </Option>
            ))}
          </Select>
        );
      case POSSIBLE_ANSWER_TYPE.textArea:
        return (
          <TextArea
            className="radius-30 p-3"
            name={question?._id}
            autoSize={{ minRows: 4, maxRows: 6 }}
            defaultValue={
              answer.length ? answer : question.question.defaultValue
            }
            onChange={(e) => handleChange(e.target.value)}
          />
        );
      case POSSIBLE_ANSWER_TYPE.textBox:
        return (
          <Input
            defaultValue={
              answer.length ? answer : question.question.defaultValue
            }
            className="radius-30 p-3"
            name={question?._id}
            onChange={(e) => handleChange(e.target.value)}
          />
        );
      default:
        return null;
    }
  };

  const handleClearInput = () => {
    setSearchService("");
  };

  const filterOption = (inputValue, option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase());

  return (
    <Form className="mt-4" layout="vertical">
      <Row gutter={[24, 0]}>
        <Col lg={6} xs={24}>
          <Form.Item
            label={[
              <React.Fragment key={0}>
                <span className="text-danger me-1">*</span> Full Name
              </React.Fragment>,
            ]}
          >
            <Input
              className="radius-30"
              size="large"
              placeholder="Full Name"
              name="fullName"
              value={customerDetails?.fullName}
              onChange={(e) =>
                handlePersonalInformation(e.target.name, e.target.value)
              }
            />
            <span className="required disp-block px-3">{errors.fullName}</span>
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item
            label={[<React.Fragment key={0}>Address</React.Fragment>]}
            className="googleapi"
          >
            <GoogleSearchInput
              address={customerDetails.address || ""}
              handleChange={(value) =>
                handlePersonalInformation("address", value)
              }
              onOptionMessage="Type the location like 'Miami' "
              placeholder="Address"
            />
            <span className="required disp-block px-3">{errors.address}</span>
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item label={[<React.Fragment key={0}>Email</React.Fragment>]}>
            <Input
              className="radius-30"
              size="large"
              name="email"
              placeholder="Email"
              value={customerDetails.email}
              onChange={(e) =>
                handlePersonalInformation(e.target.name, e.target.value)
              }
            />
            <span className="required disp-block px-3">{errors.email}</span>
          </Form.Item>
        </Col>
        <Col lg={6} xs={24}>
          <Form.Item
            className="phone-styles"
            label={[<React.Fragment key={0}>Phone</React.Fragment>]}
          >
            <PhoneInput
              value={customerDetails.contactNo?.toString() || ""}
              countryCodeEditable={false}
              onlyCountries={["us"]}
              disableCountryGuess
              country="us"
              placeholder="+1(253) 555-2555"
              onChange={(val) => handlePersonalInformation("contactNo", val)}
            />
            <Text italic className="font-12 text-muted mt-1">
              (Enter Number WithOut Country Code)
            </Text>
            <span className="required disp-block px-3">{errors.contactNo}</span>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col md={24} xs={24}>
          <Collapse className="ant-lead-card border-0" defaultActiveKey={["1"]}>
            <Panel
              header="Add Secondary Contact"
              key="2"
              style={{ border: "0px" }}
              className="panel-space-0"
            >
              <Form className="mt-5" layout="vertical">
                <Row gutter={[24, 0]}>
                  <Col lg={12} xs={24}>
                    <Form.Item label="Full Name">
                      <Input
                        className="radius-30"
                        size="large"
                        name="spouseName"
                        placeholder="Full Name"
                        value={customerDetails.spouseInformation?.spouseName}
                        onChange={(e) =>
                          handleSpouseInformation(e.target.name, e.target.value)
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Email">
                      <Input
                        className="radius-30"
                        size="large"
                        name="spouseEmail"
                        placeholder="Email"
                        value={customerDetails.spouseInformation?.spouseEmail}
                        onChange={(e) =>
                          handleSpouseInformation(e.target.name, e.target.value)
                        }
                      />
                    </Form.Item>{" "}
                    <Form.Item label="Phone" className="phone-styles">
                      <PhoneInput
                        value={
                          customerDetails.spouseInformation?.spousePhone?.toString() ||
                          ""
                        }
                        countryCodeEditable={false}
                        onlyCountries={["us"]}
                        disableCountryGuess
                        country="us"
                        placeholder="+1(253) 555-2555"
                        onChange={(val) =>
                          handleSpouseInformation("spousePhone", val)
                        }
                      />
                    </Form.Item>
                  </Col>{" "}
                  <Col lg={12} span={24}>
                    <Form.Item label="Other Information">
                      <TextArea
                        className="radius-30"
                        size="large"
                        placeholder="Other Information"
                        name="spouseOtherInfo"
                        rows={4}
                        value={
                          customerDetails.spouseInformation?.spouseOtherInfo
                        }
                        onChange={(e) =>
                          handleSpouseInformation(e.target.name, e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <hr className="add-spouse-wrap"></hr>
              </Form>
            </Panel>
          </Collapse>
        </Col>

        <Col md={24} xs={24}>
          <div className="add-service-scroll pt-3 pe-3"></div>
        </Col>
      </Row>
      {(!customerDetails._id || editAddedServices) && (
        <>
          <Row>
            <Col md={6} xs={24}>
              <Input
                className="radius-30"
                size="large"
                name="search"
                placeholder="Search Services"
                value={searchService}
                onChange={(e) => {
                  setSearchService(e.target.value);
                  getSearchFeildLength(e);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4 pt-3">
            <Col md={24} xs={24}>
              <h6 className="mb-3 ps-2">Add Services</h6>
              <div>
                <span className="required disp-block px-3">
                  {errors.services}
                </span>
              </div>
              <ul className="services-list">
                {searchedService.length ? (
                  searchedService.map((service) => (
                    <li key={service._id}>
                      <div className="add-service-scroll pt-3 pe-3">
                        <Services
                          service={service}
                          isAdded={handleManageAddedServices(service._id)}
                          editService={addService}
                          clearInput={handleClearInput}
                        />
                      </div>
                    </li>
                  ))
                ) : (
                  <>
                    {searchError.length ? (
                      <>
                        <h6 style={{ position: "relative", left: "50vh" }}>
                          {searchError}
                        </h6>{" "}
                      </>
                    ) : (
                      <>
                        {mostSearchedService.length ? (
                          mostSearchedService.map((service) => (
                            <li key={service._id}>
                              <Services
                                service={service}
                                isAdded={handleManageAddedServices(service._id)}
                                editService={addService}
                                clearInput={handleClearInput}
                              />
                            </li>
                          ))
                        ) : (
                          <div className="pt-2 mb-2 ms-3">
                            <p>There no services available</p>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </ul>
            </Col>
          </Row>
        </>
      )}
      <Row className="mt-4 pt-3">
        <Col md={24} xs={24}>
          <h6 className="mb-3 ps-2">
            Added Services
            {customerDetails._id && (
              <span
                className="text-primary edit-icon"
                onClick={() => setEditAddedServices(!editAddedServices)}
              >
                {edit}
              </span>
            )}
          </h6>
          <Row>
            <Col md={20} xs={24}>
              <ul className="services-list">
                {customerDetails?.services?.length ? (
                  customerDetails.services.map((service) => (
                    <li key={service._id}>
                      <Services
                        service={service}
                        isAdded
                        editService={removeService}
                        customerAddedServices
                        clearInput={handleClearInput}
                      />
                    </li>
                  ))
                ) : (
                  <div className="pt-2 mb-2 ms-3">
                    <p>There is no added services</p>
                  </div>
                )}
              </ul>
            </Col>
          </Row>
          <Row gutter={30} className="border-top pt-5 pb-4">
            <Col md={14} xs={24}>
              <div className="pe-lg-5">
                <Row gutter={30} className="align-items-center mb-5">
                  <Col md={12} xs={24} className="mb-3 mb-lg-0">
                    <h6 className="m-0">
                      Where Did You Here About us?
                      <span style={{ color: "red" }}>*</span>
                    </h6>
                    <span className="required disp-block pt-2">
                      {errors.leadSource}
                    </span>
                  </Col>
                  <Col md={12} xs={24}>
                    {allLeadSources.length ? (
                      <>
                        <Select
                          onChange={(e) =>
                            handlePersonalInformation("leadSource", e)
                          }
                          value={
                            customerDetails.leadSource ||
                            customerDetails?.leadSource?.name ||
                            "Choose option"
                          }
                          style={{ width: "100%" }}
                          size="large"
                        >
                          {allLeadSources.map((value) => (
                            <Option value={value._id} key={value._id}>
                              {value.name}
                            </Option>
                          ))}
                        </Select>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Row gutter={30} className="align-items-center mb-5">
                  <Col md={12} xs={24} className="mb-3 mb-lg-0">
                    <h6 className="m-0">Assigned To?</h6>
                  </Col>
                  <Col md={12} xs={24}>
                    {allOrganizationUserList?.length ? (
                      <>
                        <Select
                          style={{ width: "100%" }}
                          mode="multiple"
                          placeholder="select User"
                          onChange={handleUserSelect}
                          value={selectedAssignedUser}
                          onDeselect={handleUserDeselect}
                          filterOption={filterOption}
                          optionLabelProp="label"
                          size="large"
                        >
                          {allOrganizationUserList.map((value) => (
                            <Option
                              value={value._id}
                              label={value.name}
                              key={value._id}
                              className="assignUser"
                            >
                              <Space>{value.name}</Space>
                            </Option>
                          ))}
                        </Select>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                {questionsAnswers.length
                  ? questionsAnswers
                      .filter((question) =>
                        question.question ? !question.question.isHide : true,
                      )
                      .map((question, index) => {
                        if (!question?.question) {
                          return "";
                        }
                        const { questionLabel, isMandatory } =
                          question.question;
                        return (
                          <Row
                            gutter={30}
                            className="align-items-center mb-5"
                            key={question._id}
                          >
                            <Col md={12} xs={24} className="mb-3 mb-lg-0">
                              <h6 className="m-0">
                                {questionLabel}
                                <span style={{ color: "red" }}>
                                  {isMandatory ? "*" : ""}
                                </span>
                              </h6>
                            </Col>
                            <Col md={12} xs={24}>
                              {renderQuestions(question, index)}
                            </Col>
                            <span className="required disp-block px-3">
                              {errors[question?._id]}
                            </span>
                          </Row>
                        );
                      })
                  : ""}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <div className="text-right">
                <CustomButton
                  type="primary"
                  className="radius-30 add-lead-btn px-4 h-auto"
                  onClick={handleSubmit}
                  disabled={isUpdatingLead}
                >
                  {time}
                  {!isNewLead
                    ? isUpdatingLead
                      ? "Updating..."
                      : "Update Lead"
                    : isUpdatingLead
                    ? "Adding..."
                    : "Add Lead"}
                </CustomButton>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

export default CustomerInformation;
