import api from "./api";

export const editApiIntegration = async (data) => {
  const response = await api.request({
    url: "/api-integration/edit-api-key",
    method: "POST",
    data,
  });
  return response;
};

export const getGoogleClientId = async () => {
  const response = await api.request({
    url: "/api-integration/get-google-client-id",
    method: "GET",
  });
  return response;
};
