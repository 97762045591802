import AlreadyExists from "./AlreadyExists";
import AffiliateProfile from "./v3/Profile";
import MyReferralDetails from "./v3/profileTabs/referrals/MyReferralDetails";
import AffiliateSurvey from "./v3/Survey";

const routes = [
  {
    path: "/already-exists",
    exact: true,
    name: "accountExists",
    component: <AlreadyExists />,
  },
  {
    path: "/survey",
    exact: true,
    name: "affiliateSurvey",
    component: <AffiliateSurvey />,
  },
  {
    path: "/profile",
    exact: true,
    name: "affiliateSurvey",
    component: <AffiliateProfile />,
  },
  {
    path: "/profile/:id",
    exact: true,
    name: "affiliateDetails",
    component: <MyReferralDetails />,
  },
];

export default routes;
