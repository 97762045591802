/* eslint-disable no-unused-vars */
import { MenuOutlined } from "@ant-design/icons";
import { Button, Card, Drawer, Layout } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";

import { getSubscriptionDetails } from "../api/user";
import ModalDailog from "../common/components/ModalDialogue";
import Error from "../components/Error";
import ConvertToInvoice from "../components/hrms/components/billing/ConvertToInvoice";
import InvoicingDemo from "../components/hrms/components/billing/InvoicingDemo";
import SmallLoader from "../components/loader/smallLoader";
import Menubar from "../components/navbar/menubar";
import Navbar from "../components/navbar/v2";
import { fetchUser } from "../features/user";
import logo from "../images/logo-signup.png";
import { setPermissions } from "../redux/api/api.action";
import store from "../store";
// import VideoCalling from "../components/ChatModule/VideoCalling/VideoCalling";
import { createValidUrl } from "../utils/commonFuctions";
import {
  hasBannerPermission,
  hideBannerRoutes,
} from "../utils/constants/constants";
import { getData } from "../utils/fetchApi";
import routes from "../utils/routes";
import InvoicingIntro from "./InvoicingIntro";
import TryFreeTrialModal from "./TryFreeTrialModal";

const { Header, Sider, Content } = Layout;

const withRouter = (WrappedComponent) => {
  const RouterWrapper = (props) => {
    const location = useLocation();
    return <WrappedComponent location={location} {...props} />;
  };
  return RouterWrapper;
};

class Estimates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      currentUser: {},
      loading: false,
      openHowToUse: false,
      openFreeTrial: false,
      showIntro: false,
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  getSubscriptionDetails = async () => {
    if (
      localStorage?.getItem("token") &&
      localStorage?.getItem("isCardDeclined") === "false" &&
      localStorage.getItem("isSubscriptionCanceled") === "false"
    ) {
      const response = await getSubscriptionDetails();
      store.dispatch(setPermissions(response.data.data?.[0]));
    }
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    this.getSubscriptionDetails();
    if (localStorage.getItem("isCardDeclined") === "false") {
      store.dispatch(fetchUser);
      const leadTypes = await getData(`status/list`);
      localStorage.setItem("leadTypes", JSON.stringify(leadTypes));
      const currentUser = await store.getState().user;
      if (currentUser.remote === "success") {
        this.setState({
          currentUser,
        });
      }
    }
  };

  componentDidUpdate = async () => {
    if (store.getState().user !== this.state.currentUser) {
      this.setState({ currentUser: store.getState().user, loading: false });
    }
  };

  render() {
    const { visible } = this.state;
    const btnClass = this.state.isHovered ? "animated" : "pulse";

    return this.state.loading ? (
      <SmallLoader />
    ) : (
      <>
        <Layout
          className="h-full"
          style={{ background: "#F7F7F7", minHeight: "100vh" }}
        >
          <div
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
              background: "#fff",
              zIndex: "999",
              transition: "all 0.3s ease",
            }}
            className={`slide-shadow destop-menu d-none d-lg-block pulse animated ${btnClass}`}
          >
            <div className="logo-side">
              <img
                src={
                  this.state.currentUser.organization &&
                  this.state.currentUser.organization.logo
                    ? createValidUrl(this.state.currentUser.organization.logo)
                    : ""
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "";
                }}
                alt=""
              />
            </div>
            <Navbar />
          </div>

          <Layout className="site-layout main-layout">
            <Header
              className="site-layout-background header-shadow d-flex align-items-center"
              style={{
                position: "fixed",
                zIndex: 99,
                width: "95%",
                padding: "5px 15px",
              }}
            >
              <span
                onClick={this.showDrawer}
                className="text-primary fs-1 me-3 d-lg-none"
              >
                <MenuOutlined />
              </span>
              <Menubar currentUser={this.state.currentUser} />
            </Header>
            <Content className="pt-64 px-lg-4 pb-4 h-full raymind">
              {!hideBannerRoutes.some((route) =>
                location.pathname.includes(route),
              ) &&
                hasBannerPermission.includes(
                  store.getState()?.user?.userRole?.roleName,
                ) &&
                store.getState().api?.subscriptionDetails?.bannerVisible && (
                  <Card
                    className="mb-4 radius-12"
                    bodyStyle={{ padding: "16px" }}
                  >
                    <div className="d-flex align-items-center gap-3 justify-content-between">
                      <img
                        src={logo}
                        alt="ZevBit-logo"
                        style={{
                          width: "127.48px",
                          height: "30px",
                        }}
                      />
                      <div className="trail-demo-title d-flex aligin-items-center gap-2">
                        <span>Invoicing & Change Order</span>
                        Features JUST RELEASED!
                      </div>
                      <div className="d-flex -align-items-center gap-3">
                        <Button
                          className="clear-filter"
                          onClick={() => this.setState({ showIntro: true })}
                        >
                          Watch Demo
                        </Button>
                        <Button
                          className="apply-btn"
                          type="primary"
                          onClick={() => this.setState({ openFreeTrial: true })}
                        >
                          TRY FREE FOR 30 DAYS
                        </Button>
                      </div>
                    </div>
                  </Card>
                )}
              <Routes>
                {routes.map((route, i) => (
                  <Route path={route.path} element={route.component} key={i} />
                ))}

                <Route path="*" element={<Error />} />
              </Routes>
            </Content>
          </Layout>
        </Layout>

        <Drawer
          placement="left"
          closable={false}
          onClose={this.onClose}
          visible={visible}
          bodyStyle={{ padding: "0px" }}
          width={300}
        >
          <Sider
            trigger={null}
            width={300}
            className="slide-shadow"
            style={{ background: "#fff" }}
          >
            <div className="logo-side">
              <img
                src={
                  this.state.currentUser.organization &&
                  this.state.currentUser.organization.logo
                    ? createValidUrl(this.state.currentUser.organization.logo)
                    : ""
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "";
                }}
                alt=""
              />
            </div>

            <Navbar onClose={this.onClose} />
          </Sider>
        </Drawer>
        <ModalDailog
          width={1024}
          isModalOpen={this.state.showIntro}
          handleCancel={() => this.setState({ showIntro: false })}
        >
          <InvoicingIntro
            title="Get 30 Days Free Of Our Newest Features!"
            handleCancel={() => this.setState({ showIntro: false })}
            handleOpenPlan={() => {
              this.setState({ openFreeTrial: true });
              this.setState({ showIntro: false });
            }}
          />
        </ModalDailog>
        <ModalDailog
          isModalOpen={this.state.openFreeTrial}
          handleCancel={() => this.setState({ openFreeTrial: false })}
          width={645}
          className="trial-free-modal"
          zIndex={99999}
        >
          <TryFreeTrialModal
            handleCancel={() => this.setState({ openFreeTrial: false })}
            handleOpenDemo={() => {
              this.setState({ openFreeTrial: false });
              this.setState({ showIntro: true });
            }}
            // Enable convert estimation to invoice once we ready with it: Ralph
            handleConvertToInvoice={() => {
              this.setState({ convertToInvoice: true });
              this.setState({ openFreeTrial: false });
            }}
          />
        </ModalDailog>
        <ModalDailog
          isModalOpen={this.state.convertToInvoice}
          handleCancel={() => this.setState({ convertToInvoice: false })}
        >
          <ConvertToInvoice
            handleCancel={() => this.setState({ convertToInvoice: false })}
            setOpenHowToUse={() => this.setState({ openHowToUse: true })}
          />
        </ModalDailog>
        <ModalDailog
          width={1024}
          height={621}
          isModalOpen={this.state.openHowToUse}
          handleCancel={() => this.setState({ openHowToUse: false })}
        >
          <InvoicingDemo
            title="Check Out This Video How To Use The New Features!"
            handleCancel={() => this.setState({ openHowToUse: false })}
          />
        </ModalDailog>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default withRouter(connect(mapStateToProps)(Estimates));
