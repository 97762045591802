import { Button, Checkbox, Col, Row, Select, Slider, Space } from "antd";
import React from "react";

import RangeDatePiker from "../RangeDatePiker";

const handleChange = () => {};
const FilterContentList = ({
  AdvanceFilterShow,
  AdvanceFilter,
  selectedFilter,
  setSelectedFilter,
}) => {
  React.useEffect(() => {}, [selectedFilter]);
  const numberofuser = [
    {
      value: "jack",
      label: "Jack",
    },
    {
      value: "lucy",
      label: "Lucy",
    },

    {
      value: "Yiminghe",
      label: "yiminghe",
    },
  ];
  const trailDays = [
    {
      value: "1",
      label: "1 Days",
    },
    {
      value: "2",
      label: "2 days",
    },

    {
      value: "3",
      label: "3 days",
    },
  ];

  const handleCheckboxChange = (value) => {
    setSelectedFilter((prevFilter) => ({
      ...prevFilter,
      status: prevFilter.status.includes(value)
        ? prevFilter.status.filter((item) => item !== value)
        : [...prevFilter.status, value],
    }));
  };
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={AdvanceFilter ? 10 : 24}>
          <h3 className="fliter-sort-title">Filters and Sort</h3>
          <label className="lead-check">Lead Status</label>
          <Checkbox.Group
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <Row gutter={[8, 8]}>
              <Col xs={12}>
                <Checkbox
                  value="Pending"
                  className="checkedbox"
                  onChange={() => handleCheckboxChange("Pending")}
                >
                  Pending
                </Checkbox>
              </Col>
              <Col xs={12}>
                <Checkbox
                  value="Cancelled"
                  onChange={() => handleCheckboxChange("Cancelled")}
                >
                  Cancelled
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  value="Incomplete"
                  onChange={() => handleCheckboxChange("Incomplete")}
                >
                  Incomplete
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  value="Cancel on Trial"
                  onChange={() => handleCheckboxChange("Cancel on Trial")}
                >
                  Cancel on Trial
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  value="Approved"
                  onChange={() => handleCheckboxChange("Approved")}
                >
                  Approved
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  value="Affiliate Marketer"
                  onChange={() => handleCheckboxChange("Affiliate Marketer")}
                >
                  Affiliate Marketer
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  value="Card Declined"
                  onChange={() => handleCheckboxChange("Card Declined")}
                >
                  Card Declined
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  value="Internal Team"
                  onChange={() => handleCheckboxChange("Internal Team")}
                >
                  Internal Team
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
          <Space size={[16, 16]} direction="vertical" style={{ width: "100%" }}>
            <div className="label-status">
              <label>Number of Users</label>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select Option"
                onChange={handleChange}
                size="large"
                className="seletect-option"
                options={numberofuser}
              />
            </div>
            <div className="label-status">
              <label>Trial Days </label>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Select Option"
                onChange={handleChange}
                size="large"
                className="seletect-option"
                options={trailDays}
              />
            </div>
            <div className="label-status">
              <label>Trial End Date</label>
              <RangeDatePiker />
            </div>
            <Row gutter={[16, 16]}>
              <Col xs={9}>
                {" "}
                <Button style={{ width: "100%" }} className="btn-filter">
                  Clear Filters
                </Button>
              </Col>
              <Col xs={9}>
                <Button
                  style={{ width: "100%" }}
                  className="btn-filter"
                  onClick={AdvanceFilterShow}
                >
                  {AdvanceFilter ? "Simple Filters" : "Advanced Filters"}
                </Button>
              </Col>

              <Col xs={6}>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  className="btn-done"
                >
                  Done
                </Button>
              </Col>
            </Row>
          </Space>
        </Col>
        {AdvanceFilter ? (
          <Col xs={14}>
            <h3 className="fliter-sort-title">Filters and Sort</h3>
            <Row gutter={[16, 16]}>
              <Col xs={12}>
                <div className="label-status">
                  <label>Last Sign In</label>
                  <RangeDatePiker />
                </div>
              </Col>
              <Col xs={12}>
                <div className="label-status">
                  <label>Plan</label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select Plan"
                    onChange={handleChange}
                    size="large"
                    className="seletect-option"
                    options={[
                      {
                        value: "1",
                        label: "Plan 1",
                      },
                      {
                        value: "2",
                        label: "Plan 2",
                      },
                    ]}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="label-status">
                  <label>Promo Code</label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select promo code"
                    onChange={handleChange}
                    size="large"
                    className="seletect-option"
                    options={[
                      {
                        value: "1",
                        label: "Promo Code1",
                      },
                      {
                        value: "2",
                        label: "Promo Code2",
                      },
                    ]}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="label-status">
                  <label>Source</label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select Source"
                    onChange={handleChange}
                    size="large"
                    className="seletect-option"
                    options={[
                      {
                        value: "1",
                        label: "Source 1",
                      },
                      {
                        value: "2",
                        label: "Source 2",
                      },
                    ]}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="label-status">
                  <label>Leads Added/Converted</label>
                  <RangeDatePiker />
                </div>
              </Col>
              <Col xs={12}>
                <div className="label-status">
                  <label>Billing Method</label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select Billing Method"
                    onChange={handleChange}
                    size="large"
                    className="seletect-option"
                    options={[
                      {
                        value: "1",
                        label: "PayPal Method",
                      },
                      {
                        value: "2",
                        label: "Stripe Method ",
                      },
                    ]}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="label-status">
                  <label>Leads Added</label>
                  <Slider
                    open
                    range={{ draggableTrack: true }}
                    defaultValue={[20, 50]}
                    tooltipPlacement="bottom"
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="label-status">
                  <label>Issue Date</label>
                  <RangeDatePiker />
                </div>
              </Col>
              <Col xs={12}>
                <div className="label-status">
                  <label>Leads Converted</label>
                  <Slider
                    open
                    range={{ draggableTrack: true }}
                    defaultValue={[20, 50]}
                    tooltipPlacement="bottom"
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div className="label-status">
                  <label>Last Payment Date</label>
                  <RangeDatePiker />
                </div>
              </Col>
            </Row>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </>
  );
};
export default FilterContentList;
