import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";

const Filter = ({
  title,
  icon,
  space,
  promoSearch,
  handlePromoSearchChange,
  handleFreeCode,
  handlePromoCode,
  codeActiveTab,
  trialSearch,
  handleTrialSearchChange,
  ...rest
}) => (
  <>
    <Space size={[16, 16]}>
      <Button size="large" style={{ borderRadius: "8px" }} {...rest}>
        <span className={space}> {icon}</span>
        {title}
      </Button>
      <Input
        style={{ width: "100%" }}
        size="large"
        className="btn-filter"
        placeholder="Search Code"
        prefix={<SearchOutlined style={{ fontSize: "18px" }} />}
        name="searchPromo"
        value={promoSearch}
        onChange={handlePromoSearchChange}
        id="searchCode"
        autoComplete="new-password"
      />
    </Space>
  </>
);
export default Filter;
