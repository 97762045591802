import { getSectionFromUrl } from "../../utils/constants/constants";
import { INVOICE_TYPE } from "./invoice.types";

const invoiceDetailInitialState = {
  invoiceList: [],
  showBanner: {},
  sendReceipt: {},
  showWarningBanner: false,
  totalUnpaidSummary: {},
  summaryDetails: {},
  estimationChangeOrder: null,
  paymentList: [],
  clonedAllocation: [],
};

const initialState = {
  ...invoiceDetailInitialState,
  loading: false,
  emailCategory: [],
  receiptDetails: {},
  modalToggle: {},
  projectDetails: {},
  tags: [],
  rowId: "",
  addInvoiceRow: false,
  editInvoiceRow: false,
  recordPayment: [],
  disableRecPagination: false,
  currentIndex: 0,
  isEditRecord: false,
  internalNote: "",
  deleteInvoiceRes: "",
  projectStatuses: [], // Array to store project status objects with label and value properties
  loadingProjectStatuses: false, // Boolean to store the loading state for project statuses
  selectedFilters: {
    [getSectionFromUrl()]: {
      projectStatuses: [],
      invoiceStatuses: [],
      searchValue: "",
      selectedTab: "",
      modifiedTableInvoices: [],
      tableLoading: false,
      selectedPage: 1,
    },
  },
  tabs: [],
  unModifiedInvoices: {
    data: [],
    pagination: {
      totalCount: 0,
      currentPage: 0,
      perPageCount: 0,
      totalPage: 0,
    },
  },
  selectedPaymentId: null,
  allocations: [],
  editRecordPayment: [],
  editAllocation: [],
  isEditPayment: false,
  isEditAllocation: false,
  editInvoiceId: "",
  deletedReason: "",
  clonedAllocation: [],
  squarePaymentInfo: {},
  squareChargeDetails: {},
};

export const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVOICE_TYPE.ESTIMATION_CHANGE_ORDER:
      return {
        ...state,
        estimationChangeOrder: {
          ...state.estimationChangeOrder,
          ...action.payload,
        },
      };
    case INVOICE_TYPE.ESTIMATION_CHANGE_ORDER_PENDING:
      return {
        ...state,
        estimationChangeOrder: {
          ...state.estimationChangeOrder,
          pendingServices: action.payload,
        },
      };
    case INVOICE_TYPE.PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: action.payload,
      };
    case INVOICE_TYPE.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case INVOICE_TYPE.INVOICE_LIST:
      return {
        ...state,
        invoiceList: action.payload,
      };
    case INVOICE_TYPE.SEND_RECEIPT:
      return {
        ...state,
        sendReceipt: { ...action.payload },
      };
    case INVOICE_TYPE.SHOW_BANNER:
      return {
        ...state,
        showBanner: { ...action.payload },
      };
    case INVOICE_TYPE.SHOW_WARNING_BANNER:
      return {
        ...state,
        showWarningBanner: action.payload,
      };
    case INVOICE_TYPE.INVOICE_EMAIL_CATEGORY:
      return {
        ...state,
        emailCategory: action.payload,
      };
    case INVOICE_TYPE.RECEIPT_DETAILS:
      return {
        ...state,
        receiptDetails: action.payload,
      };
    case INVOICE_TYPE.SUMMARY_DETAILS:
      return {
        ...state,
        summaryDetails: action.payload,
      };
    case INVOICE_TYPE.MODAL_TOGGLE:
      return {
        ...state,
        modalToggle: action.payload,
      };
    case INVOICE_TYPE.TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case INVOICE_TYPE.SET_EDIT_PAYMENT_ID:
      return {
        ...state,
        rowId: action.payload,
      };
    case INVOICE_TYPE.ADD_INVOICE_ROW:
      return {
        ...state,
        addInvoiceRow: action.payload,
      };
    case INVOICE_TYPE.EDIT_INVOICE_ROW:
      return {
        ...state,
        editInvoiceRow: action.payload,
      };
    case INVOICE_TYPE.SET_UNPAID_SUMMARY:
      return {
        ...state,
        totalUnpaidSummary: { ...state?.totalUnpaidSummary, ...action.payload },
      };
    case INVOICE_TYPE.SET_RECORD_PAYMENT:
      return {
        ...state,
        recordPayment: action.payload,
      };
    case INVOICE_TYPE.CLEAR_RECORD_PAYMENT:
      return {
        ...state,
        recordPayment: [],
      };
    case INVOICE_TYPE.DISABLED_PAGINATION:
      return {
        ...state,
        disableRecPagination: action.payload,
      };
    case INVOICE_TYPE.CURRENT_INDEX:
      return {
        ...state,
        currentIndex: action.payload,
      };
    case INVOICE_TYPE.IS_EDIT_RECORD_PAYMENT:
      return {
        ...state,
        isEditRecord: action.payload,
      };
    case INVOICE_TYPE.INTERNAL_NOTE:
      return {
        ...state,
        internalNote: action.payload,
      };
    case INVOICE_TYPE.DELETE_INVOICE_REASON:
      return {
        ...state,
        deleteInvoiceRes: action.payload,
      };
    case INVOICE_TYPE.PAYMENT_LIST:
      return {
        ...state,
        paymentList: [...action.payload],
      };
    case INVOICE_TYPE.PROJECT_STATUSES:
      return {
        ...state,
        projectStatuses: action.payload,
      };
    case INVOICE_TYPE.SET_LOADING_PROJECT_STATUSES:
      return {
        ...state,
        loadingProjectStatuses: action.payload, // Update loading state for project statuses
      };
    case INVOICE_TYPE.SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.section]: action.payload.filters,
        },
      };
    case INVOICE_TYPE.SET_TABS_DATA:
      return {
        ...state,
        tabs: action.payload,
      };
    case INVOICE_TYPE.SET_TABLE_INVOICES:
      return {
        ...state,
        unModifiedInvoices: action.payload,
      };
    case INVOICE_TYPE.SET_LOADING:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.section]: {
            ...state.selectedFilters[action.payload.section],
            tableLoading: action.payload.tableLoading,
          },
        },
      };
    case INVOICE_TYPE.ADD_TAB_SUCCESS:
      return {
        ...state,
        tabs: [...state.tabs, action.payload],
      };
    case INVOICE_TYPE.DELETE_TAB_SUCCESS:
      return {
        ...state,
        tabs: state.tabs.filter((tab) => tab._id !== action.payload),
      };
    case INVOICE_TYPE.CREATE_TAB_FILTER_SUCCESS:
      return {
        ...state,
        tabs: state.tabs.map((tab) =>
          tab._id === action.payload._id ? action.payload : tab,
        ),
      };
    case INVOICE_TYPE.RESET_INVOICE_REDUCER:
      return {
        ...initialState,
      };
    case INVOICE_TYPE.SELECTED_PAYMENT_ID:
      return {
        ...state,
        selectedPaymentId: action.payload,
      };
    case INVOICE_TYPE.EDIT_RECORD_PAYMENT:
      return {
        ...state,
        editRecordPayment: action.payload,
      };
    case INVOICE_TYPE.EDIT_ALLOCATIONS:
      return {
        ...state,
        editAllocation: action.payload,
      };
    case INVOICE_TYPE.IS_EDIT_PAYMENT:
      return {
        ...state,
        isEditPayment: action.payload,
      };
    case INVOICE_TYPE.IS_EDIT_ALLOCATION:
      return {
        ...state,
        isEditAllocation: action.payload,
      };
    case INVOICE_TYPE.EDIT_INVOICE_ID:
      return {
        ...state,
        editInvoiceId: action.payload,
      };
    case INVOICE_TYPE.DELETE_PAYMENT_REASON:
      return {
        ...state,
        deletedReason: action.payload,
      };
    case INVOICE_TYPE.RESET_INVOICE_DETAILS:
      return {
        ...initialState,
      };
    case INVOICE_TYPE.SET_ALLOCATION_DATA:
      return {
        ...state,
        clonedAllocation: action.payload,
      };
    case INVOICE_TYPE.SQUARE_PAYMENT_INFO:
      return {
        ...state,
        squarePaymentInfo: action.payload,
      };
    case INVOICE_TYPE.SQUARE_CHARGE_DETAILS:
      return {
        ...state,
        squareChargeDetails: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
