import { Button, Col, Row, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getMyReferralsAction,
  saveReferralFilters,
} from "../../../../../redux/affiliate/affiliate.action";
import { initialActions } from "../../../../../utils/constants/constants";
import {
  convertLinksCode,
  convertObjArray,
  currentComm,
  currentPlan,
  filterStatus,
  isEmpty,
} from "../../helper";
import { RangeFilter, SelectFilter } from "./common/filterComponents";
import RangePickerFilter from "./RangePickerFilter";

const AffiliateFilter = ({
  openRangeSelector,
  setOpenRangeSelector,
  toggleFilterModal,
  page,
  perPage,
  sortField,
  sortOrder,
  isSuperAdmin = false,
  search,
}) => {
  const dispatch = useDispatch();
  const [linkOrCodeList, setLinkOrCodeList] = useState([]);
  const affiliateId = useSelector((state) => state?.affiliate?.profile?._id);
  const linkOrCodes = useSelector(
    (state) => state?.affiliate?.referrals?.promoCodes,
  );
  const savedFilter = useSelector(
    (state) => state?.affiliate?.["myReferral-filters"],
  );
  const handleApplyClick = () => {
    handleRangeToggle(false);
  };
  const handleRangeChange = (date) => {
    if (date?.length) {
      dispatch(
        saveReferralFilters({
          ...savedFilter,
          dateOfAction: {
            start: moment(date[0]).format("YYYY-MM-DD"),
            end: moment(date[1]).format("YYYY-MM-DD"),
          },
        }),
      );
    } else {
      dispatch(
        saveReferralFilters({
          ...savedFilter,
          dateOfAction: {
            start: "",
            end: "",
          },
        }),
      );
    }
    handleRangeToggle(true);
  };
  const handleRangeToggle = (toggle) => {
    setOpenRangeSelector(toggle);
  };
  const handleClearFilter = () => {
    dispatch(saveReferralFilters());
    callReferralAPI();
    const savedFilterData = JSON.parse(localStorage.getItem("referral-filter"));
    if (!isEmpty(savedFilterData)) {
      delete savedFilterData?.[affiliateId];
      localStorage.setItem("referral-filter", JSON.stringify(savedFilterData));
    } else {
      localStorage.removeItem("referral-filter");
    }
    toggleFilterModal();
  };

  const handleFilterChange = (value, name) => {
    dispatch(
      saveReferralFilters({
        ...savedFilter,
        [name]: value,
      }),
    );
  };

  const callReferralAPI = (filters) => {
    const payload = {
      page,
      perPage,
      ...(sortField && { fieldName: sortField }),
      ...(isSuperAdmin && { affiliate: affiliateId }),
      sortOrder,
      ...filters,
      ...(search && { search }),
    };
    dispatch(getMyReferralsAction(payload, isSuperAdmin));
  };

  const handleSaveFilter = () => {
    callReferralAPI(savedFilter);
    dispatch(saveReferralFilters(savedFilter));
    if (!isEmpty(savedFilter)) {
      Object.entries(savedFilter)?.forEach(([k, v]) => {
        if (isEmpty(v)) {
          delete savedFilter?.[k];
        }
      });
      localStorage.setItem(
        "referral-filter",
        JSON.stringify({
          [affiliateId]: savedFilter,
        }),
      );
    } else {
      localStorage.removeItem("referral-filter");
    }
    toggleFilterModal();
  };

  useEffect(() => {
    let clonedList = [];
    if (linkOrCodes?.length) {
      linkOrCodes?.forEach((obj) => {
        clonedList = [
          ...clonedList,
          { value: obj?.couponId, label: obj?.couponId },
          { value: obj?.link, label: convertLinksCode(obj?.link) },
        ];
      });
    }
    setLinkOrCodeList(clonedList);
  }, []);

  return (
    <>
      <div style={{ lineHeight: "26px" }}>
        <Typography>Filters</Typography>
        <SelectFilter
          filterTitle="Initial Action"
          mode="multiple"
          value={savedFilter?.initialAction}
          onChange={handleFilterChange}
          options={convertObjArray(initialActions)}
          name="initialAction"
        />
        <div className="mt-3">
          <Typography>Date Of Initial Action</Typography>
          <RangePickerFilter
            value={Object.values(savedFilter?.dateOfAction || {}).map((val) =>
              val ? moment(val) : "",
            )}
            handleApplyClick={handleApplyClick}
            handleRangeChange={handleRangeChange}
            handleRangeToggle={handleRangeToggle}
            openRangeSelector={openRangeSelector}
            setOpenRangeSelector={setOpenRangeSelector}
            changePos
          />
        </div>
        <SelectFilter
          filterTitle="Status"
          mode="multiple"
          value={savedFilter?.status}
          onChange={handleFilterChange}
          options={convertObjArray(filterStatus)}
          name="status"
        />
        <SelectFilter
          filterTitle="Link/ Code"
          mode="multiple"
          value={savedFilter?.linkOrCode}
          onChange={handleFilterChange}
          options={linkOrCodeList}
          name="linkOrCode"
        />
        <RangeFilter
          filterTitle="Active Months"
          value={savedFilter?.activeMonthRange}
          onChange={handleFilterChange}
          name="activeMonthRange"
        />
        <SelectFilter
          filterTitle="Current Plan"
          mode="multiple"
          value={savedFilter?.currentPlan}
          onChange={handleFilterChange}
          options={currentPlan}
          name="currentPlan"
        />
        <SelectFilter
          filterTitle="Commission %"
          value={savedFilter?.commission}
          onChange={handleFilterChange}
          options={currentComm}
          name="commission"
        />
        <RangeFilter
          filterTitle="Total Commission"
          needFloat
          prefix="$"
          value={savedFilter?.totalCommissionRange}
          onChange={handleFilterChange}
          name="totalCommissionRange"
        />
        <RangeFilter
          filterTitle="Monthly Commission"
          needFloat
          prefix="$"
          value={savedFilter?.monthlyCommissionRange}
          onChange={handleFilterChange}
          name="monthlyCommissionRange"
        />
      </div>
      <div style={{ marginTop: "4rem" }}>
        <Row justify="end" className="mt-3">
          <Col>
            <Button
              className="mr-2"
              style={{ borderRadius: 8 }}
              onClick={toggleFilterModal}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              className="mx-2"
              style={{ borderRadius: 8 }}
              onClick={handleClearFilter}
            >
              Clear
            </Button>
          </Col>
          <Col>
            <Button
              className="ml-2 affiliate-button"
              type="primary"
              onClick={handleSaveFilter}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AffiliateFilter;
