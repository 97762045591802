import leadStatusTypes from "./leadStatus.types";

const INITIAL_STATE = {
  leadStatus: [],
  loadingLeadStatus: false,
  leadStatusType: "",
};
const LeadStatusReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case leadStatusTypes.LOADING_LEAD_STATUS:
      return {
        ...state,
        loadingLeadStatus: true,
      };
    case leadStatusTypes.SET_LEAD_STATUS:
      return {
        ...state,
        leadStatus: action.payload,
        loadingLeadStatus: false,
        leadStatusType: action.leadType,
      };
    default:
      return state;
  }
};

export default LeadStatusReducer;
