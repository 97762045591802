/* eslint-disable react-hooks/exhaustive-deps */
import { message } from "antd";
import React, { createContext, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../api/api";
import {
  ADD_EMAIL_TEMPLATES,
  GET_ALL_CALL_TEMPLATES,
  GET_ALL_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATES,
  OLD_EMAIL_TEMPLATE_ROUTE,
  UPDATE_DEFAULT_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
} from "../constants/api-urls";
import { LoaderContext } from ".";

export const EmailTemplateContext = createContext({});

export const EmailTemplateProvider = ({ children }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [emailTemplateDetails, setEmailTemplateDetails] = useState(null);
  const [callTemplates, setCallTemplates] = useState([]);
  const [callTemplateDetails, setCallTemplateDetails] = useState(null);
  const [oldEmailTemplate, setOldEmailTemplate] = useState([]);
  const { setLoading } = useContext(LoaderContext);
  const navigate = useNavigate();

  const onCreateEmailTemplate = async (values) => {
    setBtnLoading(true);
    const result = await api.request({
      url: ADD_EMAIL_TEMPLATES,
      method: "POST",
      data: values,
    });
    if (result.remote === "success") {
      message.success("Email Template created successfully.");
      navigate("/notification-templates");
    } else {
      message.error(result?.errors?.errors?.error || "Something went wrong");
    }
    setBtnLoading(false);
  };

  const getAllEmailTemplates = async (search, useLoader) => {
    const url = search
      ? `${GET_ALL_EMAIL_TEMPLATES}?search=${search}`
      : GET_ALL_EMAIL_TEMPLATES;
    try {
      if (useLoader) {
        setLoading(true);
      }
      const result = await api.request({
        url,
        METHOD: "GET",
      });

      if (result.remote === "success") {
        setEmailTemplates(result.data.data);
        setEmailTemplateDetails(null);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getEmailTemplateDetails = async (id) => {
    try {
      setLoading(true);
      const result = await api.request({
        url: `${GET_EMAIL_TEMPLATES}=${id}`,
        method: "GET",
      });
      if (result.remote === "success") {
        setEmailTemplateDetails(result.data.data);
        setLoading(false);
      } else {
        message.error(result.errors.errors.error || "Something went wrong");
      }
    } catch (error) {}
  };

  const onUpdateEmailTemplate = async (id, values) => {
    setBtnLoading(true);
    const hideLoadingMessage = message.loading("Processing...", 0);
    if (values.isAutomation === undefined) {
      values.isAutomation = false;
    }
    const result = await api.request({
      url: `${UPDATE_EMAIL_TEMPLATE}/${id}`,
      method: "PUT",
      data: values,
    });
    hideLoadingMessage();
    if (result.remote === "success") {
      if (values.category) {
        setEmailTemplates((prevEmailTemplates) => {
          const updatedTemplates = prevEmailTemplates.map((item) => {
            if (item._id === id) {
              return { ...item, category: values.category };
            }
            return item;
          });
          return updatedTemplates;
        });
      }
      message.success("Email Template updated successfully.");
    } else {
      message.error(result.errors.errors.error || "Something went wrong");
    }
    setBtnLoading(false);
  };

  const onUpdateDefaultEmailTemplate = async (id) => {
    const hideLoadingMessage = message.loading("Processing...", 0);

    const result = await api.request({
      url: `${UPDATE_DEFAULT_TEMPLATE}/${id}`,
      method: "PUT",
    });
    hideLoadingMessage();
    if (result.remote === "success") {
      await getAllEmailTemplates("", false);
      message.success("Email Template updated successfully.");
    } else {
      message.error(result.errors.errors.error || "Something went wrong");
    }
  };

  const onDeleteEmailTemplate = async (id) => {
    const hideLoadingMessage = message.loading("Processing...", 0);
    const result = await api.request({
      url: `${UPDATE_EMAIL_TEMPLATE}/${id}`,
      method: "DELETE",
    });
    hideLoadingMessage();
    if (result.remote === "success") {
      setEmailTemplates((pre) => pre.filter((item) => item._id !== id));
      message.success("Email Template Deleted successfully.");
    } else {
      message.error(result.errors.errors.error || "Something went wrong");
    }
  };

  const onCreateCallTemplate = async (values) => {
    try {
      const result = await api.request({
        url: GET_ALL_CALL_TEMPLATES,
        method: "POST",
        data: values,
      });

      if (result.remote === "success") {
        navigate("/notification-templates?type=CallTemplate");
        message.success("Email Template created successfully.");
      }
    } catch (error) {}
  };

  const getAllCallTemplates = async () => {
    try {
      setLoading(true);
      const result = await api.request({
        url: GET_ALL_CALL_TEMPLATES,
        METHOD: "GET",
      });

      if (result.remote === "success") {
        setCallTemplates(result.data.data);
        setCallTemplateDetails(null);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getCallTemplateDetails = async (id) => {
    try {
      setLoading(true);
      const result = await api.request({
        url: `${GET_ALL_CALL_TEMPLATES}/${id}`,
        method: "GET",
      });
      if (result.remote === "success") {
        setCallTemplateDetails(result.data.data);
        setLoading(false);
      }
    } catch (error) {}
  };

  const onUpdateCallTemplate = async (id, values) => {
    try {
      setLoading(true);
      const result = await api.request({
        url: `${GET_ALL_CALL_TEMPLATES}/${id}`,
        method: "PUT",
        data: values,
      });

      if (result.remote === "success") {
        navigate("/notification-templates?type=CallTemplate");
        message.success("Email Template updated successfully.");
      }
      setLoading(false);
    } catch (error) {}
  };

  const onDeleteCallTemplate = async (id) => {
    try {
      setLoading(true);
      const result = await api.request({
        url: `${GET_ALL_CALL_TEMPLATES}/${id}`,
        method: "DELETE",
      });
      if (result.remote === "success") {
        await getAllCallTemplates();
      }
      setLoading(false);
    } catch (error) {}
  };
  const getOldEmailTemplate = async () => {
    const result = await api.request({
      url: `${OLD_EMAIL_TEMPLATE_ROUTE}`,
      method: "GET",
    });
    if (result.remote === "success") {
      setOldEmailTemplate(result.data.data);
    }
  };
  const value = {
    oldEmailTemplate,
    emailTemplates,
    emailTemplateDetails,
    callTemplates,
    callTemplateDetails,
    btnLoading,
    getOldEmailTemplate: useCallback((values) => {
      getOldEmailTemplate(values);
    }, []),
    onCreateEmailTemplate: useCallback((values) => {
      onCreateEmailTemplate(values);
    }, []),
    getAllEmailTemplates: useCallback((data, loader) => {
      getAllEmailTemplates(data, loader);
    }, []),
    getEmailTemplateDetails: useCallback((id) => {
      getEmailTemplateDetails(id);
    }, []),
    onUpdateEmailTemplate: useCallback((id, values) => {
      onUpdateEmailTemplate(id, values);
    }, []),
    onDeleteEmailTemplate: useCallback((id) => {
      onDeleteEmailTemplate(id);
    }, []),
    onCreateCallTemplate: useCallback((values) => {
      onCreateCallTemplate(values);
    }, []),
    getAllCallTemplates: useCallback((params) => {
      getAllCallTemplates(params);
    }, []),
    getCallTemplateDetails: useCallback((id) => {
      getCallTemplateDetails(id);
    }, []),
    onUpdateCallTemplate: useCallback((id, values) => {
      onUpdateCallTemplate(id, values);
    }, []),
    onDeleteCallTemplate: useCallback((id) => {
      onDeleteCallTemplate(id);
    }, []),
    onUpdateDefaultEmailTemplate: useCallback((id) => {
      onUpdateDefaultEmailTemplate(id);
    }, []),
  };
  return (
    <EmailTemplateContext.Provider value={value}>
      {children}
    </EmailTemplateContext.Provider>
  );
};
