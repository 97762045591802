const organizationTypes = {
  GET_ORGANIZATION: "GET_ORGANIZATION",
  UPDATING_MARKUP: "UPDATING_MARKUP",
  SUCCESS_MARKUP: "SUCCESS_MARKUP",
  ERROR_MARKUP: "ERROR_MARKUP",
  SCHEDULE_ESTIMATE: "SCHEDULE_ESTIMATE",
  SET_ORGANIZATION_QUESTIONS: "SET_ORGANIZATION_QUESTIONS",
  UPDATE_ORGANIZATION_QUESTIONS: "UPDATE_ORGANIZATION_QUESTIONS",
  ADD_ORGANIZATION_QUESTION: "ADD_ORGANIZATION_QUESTION",
  SET_ORGANIZATION_DEFAULT_QUESTIONS: "SET_ORGANIZATION_DEFAULT_QUESTIONS",
  UPDATE_ORGANIZATION_DEFAULT_QUESTIONS:
    "UPDATE_ORGANIZATION_DEFAULT_QUESTIONS",
  ADD_ORGANIZATION_DEFAULT_QUESTION: "ADD_ORGANIZATION_DEFAULT_QUESTION",
  SET_LEAD_SOURCES: "SET_LEAD_SOURCES",
};

export default organizationTypes;
