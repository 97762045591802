const distanceStringToMinutes = (distance) => {
  if (!distance.includes("days")) {
    distance = distance.replace("day", "days");
  }
  if (!distance.includes("hours")) {
    distance = distance.replace("hour", "hours");
  }
  if (!distance.includes("mins")) {
    distance = distance.replace("min", "mins");
  }
  let hours = 0;
  let minutes = 0;
  try {
    if (distance.includes("days") && distance.includes("hours")) {
      hours = distance.split("days")[0].trim() * 24;
      hours += distance.split("days")[1].split("hours")[0].trim();
    } else if (distance.includes("hours") && distance.includes("mins")) {
      hours = distance.split("hours")[0].trim();
      minutes = distance.split("hours")[1].split("mins")[0].trim();
    } else if (distance.includes("days")) {
      hours = distance.split("days")[0].trim();
    } else if (distance.includes("hours")) {
      hours = distance.split("hours")[0].trim();
    } else if (distance.includes("mins")) {
      minutes = distance.split("mins")[0].trim();
    }
    hours = Number(hours) || 0;
    minutes = Number(minutes) || 0;
  } catch (error) {
    hours = 0;
    minutes = 0;
  }
  const totalMinutes = hours * 60 + minutes;
  const minutesInDecimal = totalMinutes / 60;
  return {
    totalMinutes,
    minutesInDecimal,
  };
};
export default distanceStringToMinutes;
