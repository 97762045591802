import { Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { formatPhoneNumber } from "react-phone-number-input";

import styles from "./contractPreview.module.css";

function CertificateOfCompletion({ estimationDetails }) {
  return (
    <div className={styles.certificate} id="certificateOfCompletion">
      <h2>Certificate Of Completion</h2>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={12} lg={12} xl={10}>
          <ul>
            <li>
              <b>Contractor Info</b>
            </li>
            <li>
              <b>Phone Number: </b>
              {estimationDetails.signedByOrganization
                ?.masterAdminPhoneNumber ? (
                <>
                  &nbsp; 1 &nbsp;
                  {formatPhoneNumber(
                    estimationDetails.signedByOrganization
                      ?.masterAdminPhoneNumber
                      ? `+${estimationDetails.signedByOrganization?.masterAdminPhoneNumber}`
                      : "",
                  )}
                </>
              ) : (
                <>N/A</>
              )}
            </li>
            <li>
              <b>Email:</b>{" "}
              {estimationDetails.signedByOrganization?.masterAdminEmail}
            </li>
            <li>
              <b>Company Billing Address:</b>{" "}
              {estimationDetails.signedByOrganization?.companyBillingAddress}
            </li>
            <li>
              <b>Envelope ID:</b> {estimationDetails._id}
            </li>
          </ul>
        </Col>
        <Col xs={24} lg={12} md={12} xl={10}>
          <ul>
            <li>
              <b>Homeowner Info</b>
            </li>
            <li>
              <b>First/Last Name:</b>{" "}
              {estimationDetails.signedByClient?.customerName}
            </li>
            <li>
              <b>Phone Number:</b>{" "}
              {estimationDetails.signedByClient?.customerContactNumber ? (
                <>
                  &nbsp; 1 &nbsp;
                  {formatPhoneNumber(
                    estimationDetails.signedByClient?.customerContactNumber
                      ? `+${estimationDetails.signedByClient?.customerContactNumber}`
                      : "",
                  )}
                </>
              ) : (
                <>N/A</>
              )}
            </li>
            <li>
              <b>Email:</b>{" "}
              {estimationDetails.signedByClient?.customerEmail || "N/A"}
            </li>
            <li>
              <b>Project Address:</b>{" "}
              {estimationDetails.signedByClient?.projectAddress || "N/A"}
            </li>
          </ul>
        </Col>
      </Row>
      <h2>Signer Event</h2>
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={12} xl={10} md={12}>
          <ul>
            <li>
              <b>Contract Signed By Contractor</b>
            </li>
            <li>
              <b>Signer name:</b> {estimationDetails.signedByOrganization?.name}
            </li>
            <li>
              <b> Signed:</b>{" "}
              {moment
                .utc(estimationDetails.signedByOrganization?.date)
                .local()
                .format("MM-DD-YYYY hh:mm A")}{" "}
              {Intl.DateTimeFormat().resolvedOptions().timeZone}{" "}
            </li>
            <li>
              <b>IP Address:</b> {estimationDetails.signedByOrganization?.ip}
            </li>
            <li>
              <b>Security Level:</b> ZevBit, Account Authentication
            </li>
            <li>
              <b>Signature Adoption:</b>{" "}
              {estimationDetails.signedByOrganization?.signatureStyle}
            </li>
          </ul>
        </Col>
        <Col xs={24} lg={12} xl={10} md={12}>
          <ul>
            <li>
              <b>Contract Signed By Customer:</b>{" "}
            </li>
            <li>
              <b>Signer name:</b> {estimationDetails.signedByClient?.name}
            </li>
            <li>
              <b>Sent:</b>
              {estimationDetails.sentToCustomerDate ? (
                <>
                  {moment
                    .utc(estimationDetails.sentToCustomerDate)
                    .local()
                    .format("MM-DD-YYYY hh:mm A")}{" "}
                  {Intl.DateTimeFormat().resolvedOptions().timeZone}
                </>
              ) : (
                " N/A"
              )}
            </li>
            <li>
              <b>Signed:</b>{" "}
              {moment
                .utc(estimationDetails.signedByClient?.date)
                .local()
                .format("MM-DD-YYYY hh:mm A")}{" "}
              {Intl.DateTimeFormat().resolvedOptions().timeZone}
            </li>
            <li>
              <b>IP Address:</b> {estimationDetails.signedByClient?.ip}
            </li>
            <li>
              <b>Security Level:</b>
              {estimationDetails.signedByClient?.signedByEmployeeId
                ? "ZevBit, Account Authentication"
                : "Email, Account Authentication"}
            </li>
            <li>
              <b> Signature Adoption:</b>{" "}
              {estimationDetails.signedByClient?.signatureStyle}
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

export default CertificateOfCompletion;
