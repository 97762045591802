import { Button, Card, Col, Row } from "antd";
import classNames from "classnames";
import React from "react";

import AffiliateFormInput from "../../../AffiliateFormInput";

const ReferralHeader = ({
  handleLinkShareModalVisibility,
  handlePromoCodeModalVisibility,
  referral,
}) => {
  const referralHead = referral?.promoCodes?.[0];
  const commission = referral?.affiliateCommission;
  return (
    <>
      <Card
        style={{ borderRadius: 8 }}
        className="my-referal-banner p-lg-4"
        bodyStyle={{ padding: "0px" }}
      >
        <Row gutter={[16, 16]}>
          <Col
            lg={12}
            xs={24}
            style={{ alignItems: "center" }}
            className="d-none d-lg-block"
          >
            <div style={{ margin: "45px 0px", paddingLeft: 40 }}>
              <p style={{ fontSize: 30, margin: 0, color: "white" }}>
                Refer Friends.
              </p>
              <p style={{ fontSize: 30, margin: 0, color: "white" }}>
                Get{" "}
                {`${commission?.throughOther}-${commission?.throughDirectSignUp}%`}{" "}
                Monthly Commission.
              </p>
            </div>
          </Col>
          <Col lg={7} xs={24} className="my-referal-two-col">
            <Card style={{ borderRadius: 8 }}>
              <div>Default Referral Link</div>
              <div>
                <AffiliateFormInput
                  value={referralHead?.link || "-"}
                  className={classNames("mt-3", {
                    "affiliate-disable-input": !referralHead,
                  })}
                  disabled={!referralHead}
                />
              </div>
              <Button
                className={classNames("w-100", {
                  "affiliate-button": !!referralHead,
                  "affiliate-disable-button": !referralHead,
                })}
                type="primary"
                onClick={() => handleLinkShareModalVisibility()}
                disabled={!referral?.promoCodes?.length}
              >
                Share Link
              </Button>
            </Card>
          </Col>
          <Col lg={5} xs={24} className="my-referal-second-col">
            <Card style={{ borderRadius: 8 }}>
              <div>Default Promo Code</div>
              <div>
                <AffiliateFormInput
                  value={referralHead?.couponId || "-"}
                  className={classNames("mt-3 change-input-color-red", {
                    "affiliate-disable-input": !referralHead,
                  })}
                  disabled={!referralHead}
                />
              </div>
              <Button
                className={classNames("w-100", {
                  "affiliate-button": !!referralHead,
                  "affiliate-disable-button": !referralHead,
                })}
                type="primary"
                onClick={() => handlePromoCodeModalVisibility()}
                disabled={!referral?.promoCodes?.length}
              >
                Share Promo Code
              </Button>
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ReferralHeader;
