import "./change-plan.css";

import { Button, Card, message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { start30DaysTrialPlan } from "../api/billing";
import { getSubscriptionDetails } from "../api/user";
import { setPermissions } from "../redux/api/api.action";
import { essentialPlanFeatures } from "../utils/constants/constants";
import {
  blueCheckIcon,
  capIcon,
  checkIcon,
  featuredIcon,
} from "../utils/svg.file";

const TryFreeTrialModal = ({
  handleCancel,
  handleOpenDemo,
  handleConvertToInvoice,
  needCancel = true,
}) => {
  const dispatch = useDispatch();
  const [startingTrial, setStartingTrial] = useState(false);
  const hasVideoLink = process.env.REACT_APP_INVOICE_INTRO_LINK;
  const handleStartTrial = async () => {
    setStartingTrial(true);
    const payload = {
      planId: process.env.REACT_APP_ESSENTIAL_PLAN_ID,
    };
    const response = await start30DaysTrialPlan(payload);
    if (response.remote === "success") {
      handleConvertToInvoice();
      handleCancel();
      message.success("Successful upgrade the plan");
      const permissions = await getSubscriptionDetails();
      dispatch(setPermissions(permissions.data.data?.[0]));
      setStartingTrial(true);
    } else {
      message.error(response?.error?.errors || "Something went wrong!");
      setStartingTrial(true);
    }
  };
  return (
    <>
      <div className="mb-3">{featuredIcon}</div>
      <div className="change-title">
        Get 30 Days Free Of Our Newest Features!
      </div>
      {hasVideoLink && (
        <span className="estomation-plan-text mb-3 d-block">
          To watch a demo video of the new features,
          <Button
            type="link"
            onClick={() => handleOpenDemo()}
            className="click-btn"
          >
            Click Here
          </Button>
        </span>
      )}

      <div>
        <Card bodyStyle={{ padding: "15px" }} className="mb-3">
          <div className="d-flex align-items-center gap-3 month-text">
            {capIcon}{" "}
            <div className="flex-fill">
              <span>Estimating Monthly Plan: $97.99/Month</span>
              <div className="d-block">Current Plan</div>
            </div>
          </div>
        </Card>
        <Card
          style={{ background: "#EFF8FF", color: "#3483FA" }}
          className="mb-3"
          bodyStyle={{ padding: "15px" }}
        >
          <div className="iconselect">{checkIcon}</div>
          <div className="d-flex align-items-center gap-3 month-text-active">
            {capIcon}{" "}
            <div className="flex-fill">
              <span>Essentials Monthly Plan: $197.99/Month</span>
              <div className="d-block">Access To All New Features</div>
            </div>
          </div>
        </Card>
      </div>

      <span className="estomation-plan-text mb-3 d-block">
        Unlock access to our new premium features that just released!
      </span>

      <div className="features-content">
        <ul
          style={{
            columns: 2,
            columnGap: 20,
          }}
        >
          {essentialPlanFeatures?.map((feature, ind) => (
            <li key={`new-feature-${ind}`}>
              {blueCheckIcon} {feature}
            </li>
          ))}
        </ul>
      </div>

      <div style={{ color: "#667085" }}>
        <span style={{ fontWeight: 600 }}>Please Note:</span> You will be billed
        automatically after 30 Days unless you Change Your Plan from the Billing
        Section.
      </div>
      <div className="d-flex mt-3 gap-3">
        {needCancel && (
          <Button
            block
            className="clear-filter w-50"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
        )}
        <Button
          block
          className={`apply-btn ${needCancel ? "w-50" : "w-100"} `}
          type="primary"
          onClick={() => handleStartTrial()}
          disabled={startingTrial}
        >
          {startingTrial ? (
            <span>Starting Free Trial...</span>
          ) : (
            <span>Start 30 Days Free</span>
          )}
        </Button>
      </div>
    </>
  );
};

export default TryFreeTrialModal;
