import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Space } from "antd";

import { DeleteIcon, lock01 } from "../../../../utils/svg.file";

export const menu = (
  <Menu>
    <Menu.Item key="0">610</Menu.Item>

    <Menu.Divider />
    <Menu.Item key="3">714</Menu.Item>
  </Menu>
);

export const informationDATA = [
  {
    id: 1,
    title: "Address",
    description: "3275 NW 24th Street Rd, Miami, FL",
  },
  {
    id: 2,
    title: "Master Admin Email",
    description: "samuel@petterson.com",
  },
  {
    id: 3,
    title: "Phone Number",
    description: "323 232 42 34",
  },
  {
    id: 4,
    title: "Promo Code Used",
    description: "AV20OFF",
  },
  {
    id: 5,
    title: "Source",
    description: "ZevBit Website",
  },
  {
    id: 6,
    title: "Trial Days",
    description: "0",
  },
  {
    id: 7,
    title: "Trial End",
    description: "01/01/2023",
  },
  {
    id: 8,
    title: "Signed Up",
    description: "01/01/2023",
  },
  {
    id: 9,
    title: (
      <Dropdown overlay={menu} trigger={["click"]}>
        <Space onClick={(e) => e.preventDefault()} align="start">
          Leads Added All Time
          <DownOutlined />
        </Space>
      </Dropdown>
    ),
    description: "610",
  },
  {
    id: 10,
    title: (
      <Dropdown overlay={menu} trigger={["click"]}>
        <Space onClick={(e) => e.preventDefault()} align="start">
          <>
            Leads Converted All Time
            <DownOutlined />
          </>
        </Space>
      </Dropdown>
    ),
    description: "714",
  },
  {
    id: 11,
    title: "Plan",
    description: "Essential",
  },
  {
    id: 12,
    title: "Billing Method",
    description: "Monthly",
  },
];

export const INFORMATION_COLUMN_DATA = [
  {
    title: "User Name",
    dataIndex: "name",
  },
  {
    title: "Role",
    dataIndex: "role",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
  },
  {
    title: "Created",
    dataIndex: "created",
  },
  {
    title: "Last Sign In",
    dataIndex: "last_sign_in",
  },
  {
    title: "Action",
    dataIndex: "action",
    render: () => (
      <Space size={[0, 8]}>
        <Button type="text" shape="circle">
          {lock01}
        </Button>
        <Button type="text" shape="circle" icon={DeleteIcon} />
      </Space>
    ),
  },
];

export const INFORMATION_ROW_DATA = [1, 2, 3, 4, 5].map(() => ({
  name: "Kimberly Reeves",
  role: "Master Admin",
  email: "jamesp@example.com",
  phone: "352-259-2388",
  created: "06/20/2023",
  last_sign_in: "06/20/2023",
}));

export const BILLING_COLUMN_DATA = [
  {
    key: "id",
    title: "#",
    dataIndex: "id",
  },
  {
    title: "date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Invoice Number",
    dataIndex: "invoice",
    key: "invoice",
  },
  {
    title: "Card Used",
    dataIndex: "carduser",
    key: "carduser",
  },
  {
    title: "Payment Status",
    dataIndex: "paymentstatus",
    key: "paymentstatus",
    render: (_, values) => (
      <span style={{ color: values.cardStatus ? "#719D40" : "#E42626" }}>
        {values.cardStatus ? "Approved" : "Card Declined"}
      </span>
    ),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Plan",
    dataIndex: "plan",
    key: "plan",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: () => (
      <Button type="text" shape="circle">
        <DownloadOutlined style={{ fontSize: "18px", color: "#484D59" }} />
      </Button>
    ),
  },
];

export const BILLING_ROW_DATA = [1, 2, 3, 4, 5, 6, 7, 8].map((id) => ({
  id,
  date: "6/12/2023",
  invoice: "321456987012",
  carduser: "****1865",
  amount: "$97.99",
  plan: "Essential",
  cardStatus: id % 2 !== 0,
}));

export const getLabels = {
  scheduledDemo: "Schedule Demo",
};

export const promoCodeMetaData = [
  {
    action: "filledLeadForm",
    filledOutRequested: 0,
    signedUp: 0,
    canceledOnTrial: 0,
    purchasedOnTrial: 0,
    canceled: 0,
    onHold: 0,
    active: 0,
  },
  {
    action: "scheduledDemo",
    filledOutRequested: 0,
    signedUp: 0,
    canceledOnTrial: 0,
    purchasedOnTrial: 0,
    canceled: 0,
    onHold: 0,
    active: 0,
  },
  {
    action: "signUpLink",
    filledOutRequested: 0,
    signedUp: 0,
    canceledOnTrial: 0,
    purchasedOnTrial: 0,
    canceled: 0,
    onHold: 0,
    active: 0,
  },
  {
    action: "signUpCode",
    filledOutRequested: 0,
    signedUp: 0,
    canceledOnTrial: 0,
    purchasedOnTrial: 0,
    canceled: 0,
    onHold: 0,
    active: 0,
  },
];

export const affiliateOverviewData = [
  {
    action: "filledLeadForm",
    filledOutRequested: 0,
    signedUp: 0,
    canceledOnTrial: 0,
    purchasedOnTrial: 0,
    canceled: 0,
    onHold: 0,
    active: 0,
  },
  {
    action: "scheduledDemo",
    filledOutRequested: 0,
    signedUp: 0,
    canceledOnTrial: 0,
    purchasedOnTrial: 0,
    canceled: 0,
    onHold: 0,
    active: 0,
  },
  {
    action: "directSignUp",
    filledOutRequested: 0,
    signedUp: 0,
    canceledOnTrial: 0,
    purchasedOnTrial: 0,
    canceled: 0,
    onHold: 0,
    active: 0,
  },
];

export const getActionName = {
  filledLeadForm: "Lead Forms",
  scheduledDemo: "Demo Requests",
  signUpLink: "Free Trials (From Link)",
  signUpCode: "Used Promo Code",
};
export const overviewAction = {
  filledLeadForm: "Lead Forms",
  scheduledDemo: "Demo Requests",
  directSignUp: "Direct Sign Ups (Free Trial)",
};

export const avoidColumns = ["action", "clicks"];
export const showLoginButton = ["activetrial", "active"];
