import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Space, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { getBillingDataAction } from "../../../../api/admin";
import ModalDailog from "../../../../common/components/ModalDialogue";
import {
  capitalizeString,
  DATE_FORMATE,
  modifiedStatus,
  statusColors,
} from "../../../../utils/constants/constants";
import {
  checkUndefinedNull,
  convertJSONToCSV,
} from "../../../../utils/javascript";
import {
  downloadcloud,
  fi_download as fiDownload,
} from "../../../../utils/svg.file";
import SmallLoader from "../../../loader/smallLoader";
import { formatDate } from "..";
import SetNewPassword from "../modal/SetNewPassword";
import TableData from "../Table";

function Billing({ selectedUser }) {
  const [usersData, setUsersData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentUsersPage, setCurrentUsersPage] = useState(1);
  const [showPasswordModel, setShowPasswordModel] = useState(false);
  const [password, setPassword] = useState("");
  const getBillingsData = async () => {
    setLoader(true);

    const result = await getBillingDataAction(
      selectedUser?._id,
      currentUsersPage,
      8,
    );
    if (result?.remote === "success") {
      setUsersData(result?.data?.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const exportBillingData = async () => {
    const result = await getBillingDataAction(
      selectedUser?._id,
      currentUsersPage,
      usersData[0]?.pagination[0]?.totalCount,
    );
    if (result?.remote === "success") {
      setExportData(result?.data?.data?.[0]?.data);
    }
  };

  useEffect(() => {
    getBillingsData();
  }, [selectedUser, currentUsersPage]);

  useEffect(() => {
    if (usersData?.length && !exportData?.length) {
      exportBillingData();
    }
  }, [usersData]);

  const usersColumns = [
    {
      title: "#",
      dataIndex: "name",
      render: (v, r, i) =>
        usersData?.[0]?.pagination?.[0]?.totalCount -
        (usersData?.[0]?.pagination?.[0]?.perPageCount *
          (usersData?.[0]?.pagination?.[0]?.currentPage - 1) +
          i),
      width: 50,
    },
    {
      title: "Date",
      dataIndex: "paymentDate",
      render: (v) => <>{formatDate(v)}</>,
      width: 130,
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      width: 200,
      render: (v) => <span className="nowarp">{v || "-"}</span>,
    },
    {
      title: "Card Used",
      dataIndex: "cardDetails",
      render: (v) => `****${v?.last4}` || "-",
    },
    {
      title: "Payment Status",
      dataIndex: "status",
      width: 200,
      render: (v) => (
        <span
          className="nowarp"
          style={{ color: statusColors[v?.toLowerCase()] }}
        >
          {modifiedStatus[v?.toLowerCase()] || v || "-"}
        </span>
      ),
    },
    {
      title: "Promo code",
      dataIndex: "coupon",
      width: 150,
      render: (text, record) =>
        text ? (
          <>
            <span>{text}</span>
            <Tooltip
              title={`Promo Code Applied: ${record?.coupon} (${
                record?.couponName
              }) on ${formatDate(record?.couponAppliedDate)}`}
              placement="top"
              zIndex={9999}
            >
              <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
          </>
        ) : (
          "-"
        ),
    },
    {
      title: "Discount",
      dataIndex: "couponDiscount",
      render: (v) => (v ? `-$${v}` : "-"),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (v) => `$${v}` || "-",
    },
    {
      title: "# of Employees",
      dataIndex: "userCount",
      render: (v) => (checkUndefinedNull(v) ? "-" : v),
      // className: "w-100",
    },
    {
      title: "Storage",
      dataIndex: "storageSize",
      render: (v) => v || "-",
    },
    {
      title: "Plan",
      dataIndex: "subscriptionPlanName",
      render: (v, record) =>
        v ? (
          <span className="nowarp">
            {capitalizeString(v)?.split(/(?=[A-Z])/)?.[0]} (
            {record?.billingMethod?.charAt(0)?.toUpperCase()})
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: "Download",
      className: "text-center",
      dataIndex: "pdf",
      render: (v, record) => (
        <Space size={[2, 2]}>
          {record?.status === "Card declined" ? (
            fiDownload
          ) : (
            <a href={v} style={{ color: "red" }}>
              {fiDownload}
            </a>
          )}
        </Space>
      ),
    },
  ];

  const generateExcelData = (item, index) => {
    const excelData = {
      "#":
        usersData?.[0]?.pagination?.[0]?.totalCount -
        (usersData?.[0]?.pagination?.[0]?.perPageCount *
          (usersData?.[0]?.pagination?.[0]?.currentPage - 1) +
          index),
      Date: item?.paymentDate
        ? moment(new Date(item?.paymentDate)).format(DATE_FORMATE)
        : "-",
      "Invoice Number": item?.invoiceNumber || "",
      "Card Used": item?.cardDetails?.last4
        ? `****${item?.cardDetails?.last4}`
        : "",
      "Payment Status": item?.status || "",
      Amount: item?.amount || "",
      "# of Employees": checkUndefinedNull(item?.userCount)
        ? ""
        : item?.userCount,
      Storage: item?.storageSize || "",
      Plan: item?.billingMethod || "",
    };
    return excelData;
  };

  const handleDownload = (compName) => {
    const csvDataList = [];
    exportData?.forEach((item, ind) => {
      csvDataList.push(generateExcelData(item, ind));
    });
    const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
      convertJSONToCSV(csvDataList),
    )}`;
    const link = document.createElement("a");
    link.href = csvContent;
    link.download = `${compName}_Billing_List`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {loader && (
        <div className="center-loader">
          <SmallLoader />
        </div>
      )}
      <div className="export-btnd">
        <Button
          size="large"
          className="btn-filter"
          onClick={() => handleDownload(selectedUser?.companyName)}
        >
          {downloadcloud} <span className="ms-1">Export</span>
        </Button>
      </div>
      <TableData
        scroll={{
          x: 1700,
          y: 300,
        }}
        columns={usersColumns}
        dataSource={usersData[0]?.data}
        currentPage={currentUsersPage}
        setCurrentPage={setCurrentUsersPage}
        perPage={usersData[0]?.pagination[0]?.perPageCount}
        paginationData={usersData[0]?.pagination[0]}
      />
      <ModalDailog
        bodyStyle={{ padding: "0px" }}
        isModalOpen={showPasswordModel}
        handleCancel={() => setShowPasswordModel(false)}
        title={false}
      >
        <SetNewPassword
          setShowPasswordModel={setShowPasswordModel}
          setPassword={setPassword}
          password={password}
        />
      </ModalDailog>
    </>
  );
}

export default Billing;
