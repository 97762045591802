import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../PromoCodePage.css";

import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
} from "antd";
import { convertFromRaw, EditorState } from "draft-js";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";

import { editPromoCode, getAffiliateListAction } from "../../../../api/admin";
import { plansForPromoCode } from "../../../../utils/constants/constants";
import { promoCodeSchema } from "../../../../validators/auth/auth.validator";
import SmallLoader from "../../../loader/smallLoader";
import { modifyData } from "../helper";

const promoCodeDuration = [
  {
    value: "1",
    label: "1 Month",
  },
  {
    value: "2",
    label: "2 Month",
  },
  {
    value: "3",
    label: "3 Month",
  },
  {
    value: "6",
    label: "6 Month",
  },
  {
    value: "entireSubscription",
    label: "Entire Subscription",
  },
];

const trialDaysOption = [
  {
    value: "1",
    label: "1 Month",
  },
  {
    value: "2",
    label: "2 Months",
  },
  {
    value: "3",
    label: "3 Months",
  },
];

const mentions = [
  {
    text: "Discounted Price With Tax",
    value: "{DISCOUNT_PRICE_WITH_TAX}",
    field: "Discounted Price With Tax",
  },
  {
    text: "Discounted Price",
    value: "{DISCOUNT_PRICE}",
    field: "Discounted Price",
  },
  {
    text: "Plan Price",
    value: "{PLAN_PRICE}",
    field: "Plan Price",
  },
];

const EditPromoCode = ({
  handleCancel,
  setFormData,
  formData,
  errors,
  setErrors,
  getPromoCodeList,
  hasInitialized,
  setHasInitialized,
}) => {
  const { Option } = Select;
  const [loader, setLoader] = useState(false);
  const inputRef = useRef(null);
  const [affiliateSearch, setAffiliateSearch] = useState([]);
  const [affiliateList, setAffiliateList] = useState([]);
  const [isAffiliateRemoved, setIsAffiliateRemoved] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // const [hasInitialized, setHasInitialized] = useState(false);
  useEffect(() => {
    if (formData?.description?.length > 0 && !hasInitialized) {
      const initialContent = {
        entityMap: {},
        blocks: [
          {
            key: "abcd",
            text: formData?.description,
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
      };
      const initialContentState = convertFromRaw(initialContent);
      setEditorState(EditorState.createWithContent(initialContentState));
      setHasInitialized(true);
    }
  }, [formData, hasInitialized]);

  const getAffiliateList = async () => {
    setLoader(true);
    const result = await getAffiliateListAction();
    if (result?.remote === "success") {
      setAffiliateList(result?.data?.data);
      setAffiliateSearch(result?.data?.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  useEffect(() => {
    getAffiliateList();
  }, []);

  useEffect(() => {
    if (formData?.affiliate?._id) {
      setIsAffiliateRemoved(true);
    }
  }, [formData]);

  const changeAffiliateSearch = (e) => {
    const filteredOptions = affiliateList.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    setAffiliateSearch(filteredOptions);
  };

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const plainTextContent = newEditorState.getCurrentContent().getPlainText();
    setFormData({ ...formData, description: plainTextContent });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "discount") {
      if (!/^\d*(\.\d{1,2})?$/.test(value)) {
        e.preventDefault();
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e && e?.preventDefault();
    setErrors({});
    const { isValid, errors: formError } = promoCodeSchema(formData);
    if (!isValid) {
      setErrors(formError);
    } else {
      setLoader(true);
      const payload = modifyData(formData);
      const result = await editPromoCode(formData?.promoCodeId, payload);
      if (result?.errors) {
        setLoader(false);

        message.error(
          result?.errors?.errors[0]?.message || "Something went wrong",
          10,
        );
      } else if (result?.remote === "success") {
        setLoader(false);
        message.success("Promo code edited successfully", 10);
        getPromoCodeList();
        setHasInitialized(false);
        handleCancel();
      }
    }
    setIsAffiliateRemoved(false);
  };

  return (
    <>
      {loader && (
        <div className="center-loader">
          <SmallLoader />
        </div>
      )}
      <div className="add-promo-code">
        <h3 className="fliter-sort-title">Edit a Promo Code</h3>
        <Form layout="vertical" onSubmitCapture={handleSubmit}>
          <Row gutter={[24, 24]} className="promocode-main-row">
            <Col xs={12} lg={14} className="promocode-first-col">
              <Row gutter={[12, 12]}>
                <Col xs={12}>
                  <Form.Item label="Promo Code Name">
                    <Input
                      size="large"
                      placeholder="Promo Code Name"
                      name="codeName"
                      value={formData?.codeName}
                      onChange={handleFormChange}
                      style={{ borderRadius: "8px" }}
                    />
                    <div role="alert" className="text-danger">
                      {errors.codeName}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Promo Code">
                    <Input
                      size="large"
                      placeholder="E.g. ZBS10"
                      name="promoCode"
                      value={formData?.promoCode}
                      onChange={handleFormChange}
                      style={{ borderRadius: "8px" }}
                      disabled
                    />
                    <div role="alert" className="text-danger">
                      {errors.promoCode}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Affiliate" className="select-promo-code">
                    {formData?.affiliate?._id ? (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            affiliate: value,
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select Affiliate"
                        value={formData?.affiliate?._id}
                        allowClear
                        onClear={() => {
                          setIsAffiliateRemoved(true);
                          setFormData({
                            ...formData,
                            affiliate: null,
                          });
                        }}
                        dropdownRender={(menu) => (
                          <>
                            <Input
                              size="large"
                              type="text"
                              placeholder="Search"
                              style={{
                                width: "100%",
                                marginBottom: "8px",
                                borderColor: "#D0D5DD",
                                borderRadius: "8px",
                              }}
                              onChange={changeAffiliateSearch}
                              prefix={<SearchOutlined />}
                            />
                            {menu}
                          </>
                        )}
                        options={affiliateSearch?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                          disabled: isAffiliateRemoved,
                        }))}
                      />
                    ) : (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            affiliate: value,
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select Affiliate"
                        dropdownRender={(menu) => (
                          <>
                            <Input
                              size="large"
                              type="text"
                              placeholder="Search"
                              style={{
                                width: "100%",
                                marginBottom: "8px",
                                borderColor: "#D0D5DD",
                                borderRadius: "8px",
                              }}
                              onChange={changeAffiliateSearch}
                              prefix={<SearchOutlined />}
                            />
                            {menu}
                          </>
                        )}
                        options={affiliateSearch?.map((item) => ({
                          label: item?.name,
                          value: item?._id,
                          disabled: isAffiliateRemoved,
                        }))}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Plans Promo Code Applies To"
                    className="select-promo-code"
                  >
                    <Select
                      mode="multiple"
                      onChange={(value) => {
                        setFormData({
                          ...formData,
                          plans: value,
                        });
                      }}
                      className="select-promo-code"
                      size="large"
                      placeholder="Select plans"
                      value={formData?.plans}
                    >
                      <Option disabled>Select plans</Option>
                      {plansForPromoCode?.map((v, i) => (
                        <Option value={v?.value} key={i}>
                          {v?.label}
                        </Option>
                      ))}
                    </Select>
                    <div role="alert" className="text-danger">
                      {errors.plans}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Discount Type"
                    className="select-promo-code"
                  >
                    {formData?.discountType?.length > 0 ? (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            discountType: value,
                            trialDays: value !== "freeTrial" && "",
                            numberOfTime:
                              value === "amount"
                                ? null
                                : formData?.numberOfTime,
                            subscriptionType:
                              value === "freeTrial"
                                ? formData?.subscriptionType?.filter(
                                    (v) => v !== "Existing subscription",
                                  )
                                : formData?.subscriptionType,
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        value={formData?.discountType}
                        disabled
                      >
                        <Option disabled>Select option</Option>
                        <Option value="percentage">Percentage</Option>
                        <Option value="amount">Fixed amount</Option>
                        <Option value="freeTrial">Free Trial</Option>
                      </Select>
                    ) : (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            trialDays: value !== "freeTrial" && "",
                            discountType: value,
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        disabled
                        // defaultValue="Select option"
                        // value={formData?.subscriptionType}
                      >
                        <Option disabled>Select option</Option>
                        <Option value="percentage">Percentage</Option>
                        <Option value="amount">Fixed amount</Option>
                        <Option value="freeTrial">Free Trial</Option>
                      </Select>
                    )}

                    <div role="alert" className="text-danger">
                      {errors.discountType}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Subscription Type"
                    className="select-promo-code"
                  >
                    {formData?.subscriptionType?.length > 0 ? (
                      <Select
                        mode="multiple"
                        maxTagCount={2}
                        onChange={(value) => {
                          setFormData({
                            ...formData,
                            subscriptionType: value,
                          });
                        }}
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        value={formData?.subscriptionType}
                      >
                        <Option disabled>Select option</Option>
                        <Option value="New subscription">
                          New Subscriptions
                        </Option>
                        <Option
                          value="Existing subscription"
                          disabled={formData?.discountType === "freeTrial"}
                        >
                          Existing Subscriptions
                        </Option>
                        <Option value="Reactivation">Reactivation</Option>
                      </Select>
                    ) : (
                      <Select
                        mode="multiple"
                        maxTagCount={2}
                        onChange={(value) => {
                          setFormData({
                            ...formData,
                            subscriptionType: value,
                          });
                        }}
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        // defaultValue="Select option"
                        // value={formData?.subscriptionType}
                      >
                        <Option disabled>Select option</Option>
                        <Option value="New subscription">
                          New Subscriptions
                        </Option>
                        <Option
                          value="Existing subscription"
                          disabled={formData?.discountType === "freeTrial"}
                        >
                          Existing Subscriptions
                        </Option>
                        <Option value="Reactivation">Reactivation</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Amount / Percentage">
                    <Input
                      size="large"
                      placeholder="Please enter"
                      name="discount"
                      value={formData?.discount}
                      onChange={handleFormChange}
                      type="number"
                      style={{ borderRadius: "8px" }}
                      disabled
                      prefix={formData?.discountType === "amount" && "$"}
                      suffix={formData?.discountType === "percentage" && "%"}
                    />
                    <div role="alert" className="text-danger">
                      {errors.discount}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="How many billing cycles?"
                    className="select-promo-code"
                  >
                    {formData?.numberOfTime?.length > 0 ? (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            numberOfTime: value,
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        value={formData?.numberOfTime}
                        disabled
                      >
                        <Option disabled>Select option</Option>
                        {promoCodeDuration?.map((v, i) => (
                          <Option value={v?.value} key={i}>
                            {v?.label}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            numberOfTime: value,
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        // defaultValue="Select option"
                        // value={formData?.numberOfTime}
                        disabled
                      >
                        {" "}
                        <Option disabled>Select option</Option>
                        {promoCodeDuration?.map((v, i) => (
                          <Option value={v?.value} key={i}>
                            {v?.label}
                          </Option>
                        ))}
                      </Select>
                    )}

                    <div role="alert" className="text-danger">
                      {errors.numberOfTime}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Trial Days" className="select-promo-code">
                    {formData?.trialDays?.length > 0 ||
                    formData?.customTrialDays?.length > 0 ? (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            trialDays: value,
                            customTrialDays: "",
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        value={
                          formData?.customTrialDays
                            ? `${formData?.customTrialDays} Months`
                            : formData?.trialDays
                        }
                        disabled
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Space style={{ padding: "0 8px 4px" }}>
                              Custom :
                              <Input
                                ref={inputRef}
                                value={formData?.customTrialDays}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    customTrialDays: e.target.value,
                                    trialDays: "",
                                  });
                                }}
                                type="Number"
                                style={{ width: 50 }}
                              />
                              Days
                            </Space>
                          </>
                        )}
                        options={trialDaysOption.map((item) => ({
                          label: item?.label,
                          value: item?.value,
                        }))}
                      />
                    ) : (
                      <Select
                        onSelect={(value) =>
                          setFormData({
                            ...formData,
                            trialDays: value,
                            customTrialDays: "",
                          })
                        }
                        className="select-promo-code"
                        size="large"
                        placeholder="Select option"
                        // value={formData?.trialDays}
                        disabled
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Space style={{ padding: "0 8px 4px" }}>
                              Custom :
                              <Input
                                ref={inputRef}
                                value={formData?.customTrialDays}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    customTrialDays: e.target.value,
                                    trialDays: "",
                                  });
                                }}
                                type="Number"
                                style={{ width: 50 }}
                              />
                              Days
                            </Space>
                          </>
                        )}
                        options={trialDaysOption.map((item) => ({
                          label: item?.label,
                          value: item?.value,
                        }))}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <label className="mb-1">Start Date</label>
                  <DatePicker
                    placeholder="MM/DD/YYYY"
                    size="large"
                    style={{ borderRadius: "8px", width: "100%" }}
                    format="MM/DD/YYYY"
                    onChange={(date) =>
                      setFormData({
                        ...formData,
                        startDate: moment(date).format("MM/DD/YYYY HH:mm"),
                      })
                    }
                    value={
                      formData.startDate ? moment(formData.startDate) : null
                    }
                  />
                  <div role="alert" className="text-danger">
                    {errors.startDate}
                  </div>
                </Col>
                <Col xs={12}>
                  <label className="mb-1"> Expiration Date</label>
                  <DatePicker
                    placeholder="MM/DD/YYYY"
                    size="large"
                    format="MM/DD/YYYY"
                    style={{ borderRadius: "8px", width: "100%" }}
                    disabled={formData?.noExpirationDate}
                    onChange={(date) =>
                      setFormData({
                        ...formData,
                        expirationDate: moment(date).format("MM/DD/YYYY HH:mm"),
                      })
                    }
                    value={
                      formData.expirationDate
                        ? moment(formData.expirationDate)
                        : ""
                    }
                  />
                  <div role="alert" className="text-danger">
                    {errors.expirationDate}
                  </div>
                  <Form.Item valuePropName="checked" className="mb-0">
                    <Checkbox
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          noExpirationDate: !formData?.noExpirationDate,
                          expirationDate:
                            e.target.checked === true
                              ? ""
                              : formData?.expirationDate || "",
                        });
                      }}
                      checked={formData?.noExpirationDate}
                    >
                      No Expiration Date
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="How many companies can use?"
                    className="promocode-last-input-field"
                  >
                    <Input
                      size="large"
                      placeholder={
                        formData?.isUnlimitedQuantity ? "Unlimited" : "E.g. 200"
                      }
                      name="quantity"
                      value={formData?.quantity}
                      onChange={handleFormChange}
                      type="number"
                      style={{ borderRadius: "8px" }}
                      disabled={formData?.isUnlimitedQuantity}
                      className="mb-2"
                    />
                    <div role="alert" className="text-danger">
                      {errors.quantity}
                    </div>
                  </Form.Item>

                  <Form.Item
                    valuePropName="checked"
                    style={{ marginTop: "-13px" }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          isUnlimitedQuantity: !formData?.isUnlimitedQuantity,
                          quantity:
                            e.target.checked === true ? "" : formData?.quantity,
                        });
                      }}
                      checked={formData?.isUnlimitedQuantity}
                    >
                      Unlimited
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={12} lg={10} className="promocode-second-col">
              <Row gutter={[12, 12]}>
                <Col xs={24}>
                  <Card bordered={false} className="termsconditions">
                    <Form.Item label="Description Shown On Payment Page:">
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                        toolbarHidden
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        mention={{
                          trigger: "@",
                          separator: " ",
                          suggestions: mentions,
                        }}
                        placeholder="Promo Code activated! You will only be charged ${Discounted Price} for the first month with the discount applied. From the second month on, your monthly subscription charge will be ${Subscription Price}/Month + Tax. If you have any questions please feel free to contact ZevBit Support!"
                        style={{ width: "100%", borderRadius: "4px" }}
                      />
                      <div role="alert" className="text-danger">
                        {errors.description}
                      </div>
                    </Form.Item>
                  </Card>
                </Col>
                <Col xs={24}>
                  <Form.Item label="Promo Code Notes (Internal)">
                    <Input
                      size="large"
                      placeholder="Add Note (Only for internal use)"
                      name="notes"
                      value={formData?.notes}
                      onChange={handleFormChange}
                      style={{ borderRadius: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item label="Text Of Offer To Show On Landing Page">
                    <Input
                      size="large"
                      placeholder="Get 10% OFF Your First Month!"
                      name="textOfLandingPage"
                      value={formData?.textOfLandingPage}
                      onChange={handleFormChange}
                      style={{ borderRadius: "8px" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Button
                    onClick={() => {
                      setHasInitialized(false);
                      handleCancel();
                    }}
                    style={{ width: "100%", borderRadius: "8px" }}
                    size="large"
                    className="mt-4"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs={12}>
                  <Button
                    style={{ width: "100%", borderRadius: "8px" }}
                    size="large"
                    htmlType="submit"
                    type="submit"
                    className="mt-4 promocode-submit-button"
                  >
                    Edit Promo Code
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
export default EditPromoCode;
