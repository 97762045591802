import "./affiliate.css";
import "react-toastify/dist/ReactToastify.css";

import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

// import affiliateRegisterImage from "../../../images/affiliateImages/affiliate-register-image.png";
import affiliateRegisterImage from "../../../images/affiliate_login_rightImg.png";
import logo from "../../../images/logo-signup.png";
import { authAction } from "../../../redux/affiliate/affiliate.action";
import { getRedirectionURL } from "../util";
import { passwordValidator } from "./helper";

const { Content } = Layout;

const AffiliateRegistration = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { user, error, loading } = useSelector((state) => state?.affiliate);
  const token = localStorage.getItem("affiliateToken");
  const [showPass, setShowPass] = useState({
    showPassword: false,
    agreement1: false,
    agreement2: false,
  });
  const handleCheckChange = (e) => {
    const { name, checked } = e.target;
    setShowPass((prevState) => ({ ...prevState, [name]: checked }));
  };
  const tooltipTitle = (
    <>
      <p>Your password needs to:</p>
      <ul>
        <li>Include both lower and upper case characters.</li>
        <li>Include at least one number or symbol.</li>
        <li>Be at least 8 characters long.</li>
      </ul>
    </>
  );
  const handleSubmit = async (data) => {
    delete data?.confPassword;
    dispatch(authAction(`/v3/affiliate/auth/sign-up`, data));
  };

  useEffect(() => {
    if (token && user) {
      const existing = user?.existingEmail;
      const hasExisting = user?.isFilledExistingEmail;
      const hadSurvey = user?.isFilledSurvey;
      const { redirectUrl, state } = getRedirectionURL({
        existing,
        hasExisting,
        hadSurvey,
      });
      history(redirectUrl, { state });
    }
    if (error?.error || Array.isArray(error)) {
      if (Array.isArray(error)) {
        error?.forEach((obj) => {
          form.setFields([
            { name: obj?.path, errors: ["Please enter valid info"] },
          ]);
        });
      } else if (error?.error) {
        form.setFields([
          {
            name: ["email"],
            errors: [error?.error],
          },
        ]);
      } else {
        toast.error("Something went wrong!");
      }
    }
  }, [token, user, error]);
  return (
    <>
      <Row>
        <Col xs={24} lg={12} className="d-none d-sm-block">
          <img
            src={affiliateRegisterImage}
            alt="affiliate-banner"
            style={{ width: "100%", objectFit: "cover", height: "100%" }}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Content className="h-full afflite-market">
            <div className="w-100" style={{ textAlign: "center" }}>
              <Image
                src={logo}
                preview={false}
                style={{
                  width: "200px",
                  height: "47.07px",
                  objectFit: "cover",
                }}
              />
            </div>
            <h1>Register To Become An Affiliate</h1>
            <Form
              layout="vertical"
              autoComplete="off"
              onFinish={handleSubmit}
              form={form}
              className="affiliate-register-page"
            >
              <div className="px-12">
                <Row gutter={[24, 24]}>
                  <Col span={12} style={{ paddingLeft: 0 }}>
                    <Form.Item
                      label={
                        <>
                          <span>First Name</span>
                          <span style={{ color: "red", marginLeft: 5 }}>*</span>
                        </>
                      }
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your first name!",
                        },
                      ]}
                      requiredMark="optional"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ paddingRight: 0 }}>
                    <Form.Item
                      label={
                        <>
                          <span>Last Name</span>
                          <span style={{ color: "red", marginLeft: 5 }}>*</span>
                        </>
                      }
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your last name!",
                        },
                      ]}
                      requiredMark="optional"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Form.Item
                label={
                  <>
                    <span>Email</span>
                    <span style={{ color: "red", marginLeft: 5 }}>*</span>
                  </>
                }
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                  { type: "email", message: "Please enter valid email" },
                ]}
                requiredMark="optional"
              >
                <Input
                  suffix={
                    <Tooltip title="You can use the same email as your ZevBit account">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  }
                />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <span>Phone number</span>
                    <span style={{ color: "red", marginLeft: 5 }}>*</span>
                  </>
                }
                name="contactNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number!",
                  },
                ]}
                required
                requiredMark="optional"
              >
                <PhoneInput
                  name="phoneNumber"
                  onlyCountries={["us"]}
                  countryCodeEditable={false}
                  country="us"
                  disableCountryGuess
                  className="affiliate-register-phone-input"
                />
              </Form.Item>
              {/* </Form.Item> */}
              <Form.Item
                name="address"
                label={
                  <>
                    <span>Address</span>
                    <span style={{ color: "red", marginLeft: 5 }}>*</span>
                  </>
                }
                rules={[
                  { required: true, message: "Please enter your address" },
                ]}
                requiredMark="optional"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label={
                  <>
                    <span>Password</span>
                    <span style={{ color: "red", marginLeft: 5 }}>*</span>
                  </>
                }
                // style={{ marginBottom: 10 }}
                rules={[
                  () => ({
                    validator(_, value) {
                      return passwordValidator(value);
                    },
                  }),
                ]}
              >
                <Input
                  suffix={
                    <Tooltip title={tooltipTitle}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  }
                  type={showPass.showPassword ? "text" : "password"}
                  autoComplete="new-password"
                />
              </Form.Item>
              <Checkbox
                name="showPassword"
                onChange={handleCheckChange}
                style={{ marginBottom: 14 }}
              >
                Show password
              </Checkbox>
              <Form.Item
                name="confPassword"
                label={
                  <>
                    <span>Confirm Password</span>
                    <span style={{ color: "red", marginLeft: 5 }}>*</span>
                  </>
                }
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!",
                        ),
                      );
                    },
                  }),
                ]}
                requiredMark="optional"
              >
                <Input
                  suffix={
                    <Tooltip title={tooltipTitle}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  }
                  type={showPass.showPassword ? "text" : "password"}
                />
              </Form.Item>
              <Form.Item
                name="isAgreeTermsAndConditions"
                valuePropName="checked"
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Please accept terms and conditions",
                  },
                ]}
              >
                <Checkbox
                  name="agreement1"
                  style={{ fontSize: "14px" }}
                  checked={showPass.agreement1}
                  onChange={handleCheckChange}
                >
                  I agree to ZevBit&lsquo;s Affiliate
                  <a href="#">Terms & Conditions</a> and
                  <a href="#"> Privacy Policy.</a>
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="isSendEmailNotification"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: "Please accept to receive the mail update",
                  },
                ]}
              >
                <Checkbox
                  name="agreement2"
                  style={{ lineHeight: "32px", marginLeft: 0 }}
                  checked={showPass.agreement2}
                  onChange={handleCheckChange}
                >
                  I agree to receive email updates from ZevBit.
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-100 affiliate-button"
                  disabled={loading}
                >
                  Register
                </Button>
              </Form.Item>
            </Form>
            <div style={{ textAlign: "center", marginBottom: "30px" }}>
              <span>
                Already have an account? <a href="/affiliate/login">Login</a>
              </span>
            </div>
            <div className="company-design">© ZevBit 2023</div>
          </Content>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
};

export default AffiliateRegistration;
