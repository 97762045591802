import { Button, Modal } from "antd";
import React from "react";

import { warning } from "../../utils/svg.file";

function DisclaimerPopup({
  visible,
  onCancel,
  onClick,
  loading,
  disclaimer,
  buttonTitle,
  closable,
}) {
  return (
    <div>
      <Modal
        visible={visible}
        footer={null}
        icon={null}
        closable={!closable}
        onCancel={() => (onCancel && !loading ? onCancel() : "")}
      >
        <div style={{ textAlign: "center" }}>
          <div>{warning}</div>
          <h6>Disclaimer</h6>
          <div dangerouslySetInnerHTML={{ __html: disclaimer || "" }} />
          <Button type="primary" disabled={loading} onClick={onClick}>
            {buttonTitle}
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default DisclaimerPopup;
