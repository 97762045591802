import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Select, Switch } from "antd";
import React, { useRef } from "react";
import { TwitterPicker } from "react-color";

import { getVariationBySuperCatalogId } from "../../../../api/catalogue";
import { treeIcon } from "../../../../utils/svg.file";
import SmallLoader from "../../../loader/smallLoader";
import ConditionalInput from "./conditionInput";
import CustomInput from "./customInput/customInput.element";
import elementData from "./elements/elementsData";
const typeOfOptions = [
  { type: "prefilled", title: "Enter Prefilled Amount" },
  { type: "dropdown", title: "Dropdown" },
  { type: "result_editable", title: "Formula" },
];

function ElementCard({
  element,
  handleChange,
  idx,
  elementList,
  onFocusOut,
  handleRemoveElement,
  handleNewElement,
  hiddenValueList,
  isLoading,
  catalogsAndServices,
  subCatalogItems,
  processedElementList,
  setVariations,
}) {
  const { Option } = Select;
  const [view, setView] = React.useState([]);
  const [typeOfElement, setTypeOfElement] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [variation, setVariation] = React.useState([]);
  const [isColorPicker, setIsColorPicker] = React.useState(false);
  const [selectedColor, setSelectedColor] = React.useState(element.color);
  const [selectedCatalog, setSelectedCatalog] = React.useState(null);
  const [elementName, setElementName] = React.useState({
    value: "",
    name: "name",
    idx: null,
  });
  const [elementView, setElementView] = React.useState([]);

  const colorRef = useRef(null);

  React.useEffect(() => {
    setElementView([...(element.view || [])]);
  }, [element.view]);

  const handleChangeElementView = (e) => {
    setElementView(e);
    handleChange(e, "view", idx);
  };

  const handleSaveElementView = () => {
    handleChange(elementView, "view", idx);
    onFocusOut();
  };

  const handleChangeElementName = () => {
    if (elementName.idx !== null) {
      handleChange(elementName.value, elementName.name, elementName.idx);
    }
  };

  React.useEffect(() => {
    setElementName({ ...elementName, value: element.name });
  }, [element._id]);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (colorRef.current && !colorRef.current.contains(event.target)) {
        setIsColorPicker(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [colorRef]);

  React.useEffect(() => {
    onFocusOut();
  }, [element.color]);

  React.useEffect(() => {
    setTypeOfElement(element.type || "prefilled");
    setView([
      { type: "client", title: "Client view" },
      { type: "full", title: "Admin View" },
    ]);
  }, [element]);

  function handleColorPicker() {
    setIsColorPicker(!isColorPicker);
  }

  React.useEffect(() => {
    async function fetchVariation() {
      const response = await getVariationBySuperCatalogId(element.dropdown);
      if (response.remote === "success") {
        setVariation(response.data.data);
        if (setVariations) {
          setVariations((prevState) => ({
            ...prevState,
            [element._id]: response.data.data,
          }));
        }
      }
    }
    if (element.dropdown) {
      fetchVariation();
    }
  }, [element.dropdown]);

  React.useEffect(() => {}, [searchValue]);

  const getSelectedCatalog = (id) => {
    const catalog = catalogsAndServices.find((item) => item.id === id);
    if (catalog) {
      setSelectedCatalog(catalog);
      return catalog.display;
    }
  };
  const renderSection = () => {
    if (typeOfElement) {
      const Element = elementData[typeOfElement];
      return (
        <Element
          elementList={elementList}
          handleChange={handleChange}
          idx={idx}
          hiddenValueList={hiddenValueList}
          element={element}
          onFocusOut={onFocusOut}
          setSearchValue={setSearchValue}
          variation={variation}
          suggestedCatalogs={subCatalogItems}
          selectedCatalog={selectedCatalog}
          getSelectedCatalog={getSelectedCatalog}
          processedElementList={processedElementList}
        />
      );
    }
  };

  return (
    <>
      <Col xs={24} md={6} className="mb-4 position-relative">
        <div
          className={`furmulla-tree-box  ant-cover-b ${
            !element.automatic ? "ant-cover-success" : "ant-cover-gray"
          } px-2 py-4`}
          style={{
            borderColor: !element.isValid ? "red" : "",
            borderWidth: !element.isValid ? "medium" : "",
          }}
        >
          {isLoading === idx ? (
            <SmallLoader />
          ) : (
            <>
              <div
                className="color-change"
                style={{ backgroundColor: element.color }}
                onClick={handleColorPicker}
              ></div>
              <span className="ant-edit-furmulla" ref={colorRef}>
                {isColorPicker && (
                  <>
                    <TwitterPicker
                      styles={{
                        default: {
                          input: {
                            display: "none",
                            width: 0,
                            backgroundColor: "blue",
                          },
                          hash: {
                            display: "none",
                            width: 0,
                            backgroundColor: "red",
                          },
                        },
                      }}
                      width={184}
                      colors={["#529800", "#D0021B", "#F8E71C", "#9B9B9B"]}
                      onChange={(e) => setSelectedColor(e.hex)}
                      color={selectedColor}
                      onChangeComplete={(e) =>
                        handleChange(e.hex, "color", idx)
                      }
                    />
                  </>
                )}
              </span>
              {!element.disabled && (
                <span className="delect">
                  <DeleteOutlined
                    className="text-danger"
                    onClick={() => setIsDeleting(true)}
                  />
                </span>
              )}
              <div className="ant-automic">{element.auto}</div>

              <Row gutter={[8, 0]} className="align-items-center mb-3 ">
                <Col md={8}>
                  <label>Name Element:</label>
                </Col>
                <Col md={16}>
                  <Input
                    placeholder="Name of Element"
                    className="ant-furmulla-input"
                    onChange={(e) => {
                      setElementName({
                        value: e.target.value,
                        name: e.target.name,
                        idx,
                      });
                    }}
                    value={
                      elementName.value === "Markup"
                        ? "Markup (Materials & Subs)"
                        : elementName.value
                    }
                    name="name"
                    onBlur={handleChangeElementName}
                    disabled={element.disabled}
                  />
                </Col>
              </Row>
              {element.customInput &&
                element.customInput.map((custom, customIndex) => (
                  <CustomInput
                    customIndex={customIndex}
                    handleChange={handleChange}
                    custom={custom}
                    onFocusOut={onFocusOut}
                    idx={idx}
                  />
                ))}
              <Row gutter={[8, 0]} className="align-items-center mb-3">
                <Col md={8}>
                  <label>Type Of Element:</label>
                </Col>
                <Col md={16}>
                  <Select
                    className="select-w"
                    size="large"
                    value={
                      element.type === "result_locked"
                        ? "result_editable"
                        : element.type
                    }
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      handleChange(value, "type", idx);
                      handleChange("", "value", idx);
                      setTypeOfElement(value);
                    }}
                    disabled={element.disabled}
                    onBlur={onFocusOut}
                  >
                    {typeOfOptions.map((item, idx) => (
                      <Option value={item.type} key={idx}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>

              {!element.automatic || element.name === "Markup"
                ? renderSection()
                : ""}
              {element.type === "result_editable" ? (
                <Row gutter={[8, 0]} className="align-items-center  mb-3">
                  <Col md={8}>
                    <label>RoundOff</label>
                  </Col>
                  <Col md={16}>
                    <Switch
                      checked={element.roundOff}
                      onChange={(e) => handleChange(e, "roundOff", idx)}
                    />
                  </Col>
                </Row>
              ) : (
                ""
              )}
              <Row gutter={[8, 0]} className="align-items-center  mb-3">
                <Col md={8}>
                  <label>View</label>
                </Col>
                <Col md={16}>
                  <Select
                    mode="multiple"
                    size="large"
                    allowClear
                    value={elementView}
                    onChange={handleChangeElementView}
                    className="select-w"
                    style={{ width: "100%" }}
                    onBlur={handleSaveElementView}
                  >
                    {view.map((item, index) => (
                      <Option value={item.type} key={index}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
              {!element.disabled ? (
                <Row gutter={[8, 0]} className="align-items-center mb-3">
                  <Col md={8}>
                    <label>Conditional</label>
                  </Col>
                  <Col md={16}>
                    <Select
                      style={{ width: "100%" }}
                      onChange={(e) => handleChange(e, "isConditional", idx)}
                      value={!!element.isConditional}
                    >
                      <Option value>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {!element.isConditional ? (
                ""
              ) : (
                <Row gutter={[8, 0]} className="align-items-center mb-3">
                  <Col md={10}>
                    <label>Show Element If?</label>
                  </Col>
                  <Col md={14}>
                    <ConditionalInput
                      hiddenValueList={hiddenValueList}
                      elementList={elementList}
                      element={element}
                      handleChange={(value) =>
                        handleChange(value, "conditions", idx)
                      }
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
        {idx + 1 < elementList?.length - 2 ? (
          <span
            className="add-element"
            onClick={() => {
              if (handleNewElement) {
                handleNewElement(idx + 1);
              }
            }}
          >
            {treeIcon}
          </span>
        ) : (
          ""
        )}
      </Col>

      <Modal
        className="modal-radius warning-modal"
        title="Warning!"
        visible={isDeleting}
        footer={null}
        closeIcon={<InfoCircleOutlined />}
      >
        <p>Are you sure you want to delete item ?</p>
        <Row>
          <Col md={12} className="text-right">
            <Button type="text" onClick={() => setIsDeleting(false)}>
              Cancel
            </Button>
          </Col>
          <Col md={12}>
            <Button
              type="link"
              onClick={() => {
                handleRemoveElement(element._id);
                setIsDeleting(false);
              }}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default ElementCard;
