import { Button, Form, Tooltip } from "antd";
import { InputOTP } from "antd-input-otp";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { resendOTP, verifyOTP } from "../../../../../api/affiliate";
import {
  alertMessageType,
  setFormFieldValues,
  setLoading,
  showBannerAction,
  updateProfileData,
} from "../../../../../redux/affiliate/affiliate.action";

const ConfirmOTP = ({
  email,
  handleCloseOTP,
  btnTitle = "",
  updateField = "",
  codeEndpoint = "",
  newEmail = "",
  newPassword = "",
}) => {
  const [form] = Form.useForm();
  const [modifiedEmail, setModifiedEmail] = useState("");
  const [timer, setTimer] = useState(5 * 1000);
  const [disableBtn, setDisableBtn] = useState(true);
  const [resent, setResent] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = async (values) => {
    const { otp } = values;
    const modOTP = otp?.join(",")?.replace(/,/g, "");
    if (!otp || otp.includes(undefined) || otp.includes("")) {
      return form.setFields([
        {
          name: "otp",
          errors: ["OTP is invalid."],
        },
      ]);
    }

    // NOTE: API integration for verifying OTP
    const response = await verifyOTP(codeEndpoint, { code: modOTP }, dispatch);

    if (response.remote === "success") {
      if (newEmail || newPassword) {
        dispatch(
          setFormFieldValues({
            [updateField || "email"]: newEmail || newPassword,
          }),
        );
        dispatch(
          updateProfileData({
            [updateField || "email"]: newEmail || newPassword,
          }),
        );
        dispatch(
          alertMessageType(updateField ? "updatePassword" : "updateEmail"),
        );
        dispatch(showBannerAction(true));
      }
      dispatch(setLoading(false));
      handleCloseOTP();
      form.resetFields();
    } else {
      dispatch(setLoading(false));
      form.setFields([
        { name: ["otp"], errors: [response?.error?.errors?.message] },
      ]);
    }
  };

  const handleResend = async () => {
    const response = await resendOTP({ isEmailCode: !updateField });

    if (response.remote === "success") {
      dispatch(setLoading(false));
      setResent(true);
    }
  };

  useEffect(() => {
    if (email) {
      const atIndex = email.indexOf("@");
      if (atIndex !== -1) {
        const modEmail = `${email.slice(0, 2)}****@**${email.substring(
          email.lastIndexOf(".") - 1,
        )}`;
        setModifiedEmail(modEmail);
      }
    }
    if (form) {
      form.resetFields();
    }
  }, []);

  let timerInterval = "";

  useEffect(() => {
    if (timer > 0) {
      timerInterval = setInterval(() => {
        setTimer(timer - 1000);
      }, 1000);
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
    return () => clearInterval(timerInterval);
  }, [timer]);

  const SupportTeam = () => (
    <Tooltip
      title="Please call our support team at (888) 703-4070"
      trigger="click"
      zIndex={1511}
      className="support-team-popup"
    >
      <span className="tooltipText">Support Team</span>
    </Tooltip>
  );

  return (
    <div>
      We {resent ? "resent" : "have sent"} a 6 digit code to {modifiedEmail},
      please enter the code to successfully update your {updateField || "email"}
      .
      <Form
        form={form}
        onFinish={(data) => handleSubmit(data)}
        className="Email-OTP"
      >
        <Form.Item
          name="otp"
          className="center-error-message"
          rules={[{ validator: async () => Promise.resolve() }]}
          label="Enter Code"
          style={{ fontWeight: 500 }}
        >
          <InputOTP
            autoFocus
            inputType="numeric"
            length={6}
            placeholder="000000"
          />
        </Form.Item>
        {!resent ? (
          <>
            <div>
              <span>Did not receive the code? </span>
              <Button
                type="link"
                className="p-0"
                style={{ fontWeight: 600 }}
                disabled={disableBtn}
                onClick={handleResend}
              >
                Resend it!
              </Button>

              <span className="mx-1 ant-btn-link" style={{ fontWeight: 600 }}>
                {moment
                  .utc(moment.duration(timer).asMilliseconds())
                  .format("mm:ss")}
              </span>
            </div>
            <div className="Email-OTP-ContactUs">
              Having trouble? Contact our <SupportTeam />
            </div>
          </>
        ) : (
          <>
            <span>
              We have resent the code. Are you still having problems? Contact
              our <SupportTeam />
            </span>
          </>
        )}
        <Form.Item style={{ marginTop: "20px" }} className="forFlex-buttons">
          <Button className="w-50" onClick={handleCloseOTP}>
            Cancel
          </Button>
          <Button className="w-50" htmlType="submit" type="primary">
            {btnTitle || "Update Email"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ConfirmOTP;
