import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";

import { downloadcloud } from "../../../../utils/svg.file";

const UserListFilter = ({ search, setSearch, handleExport }) => (
  <>
    <Space size={[16, 16]}>
      <Button size="large" className="btn-filter" onClick={handleExport}>
        {downloadcloud} <span className="ms-1">Export</span>
      </Button>
      <Input
        style={{ width: "100%" }}
        size="large"
        className="btn-filter"
        placeholder="Search"
        prefix={<SearchOutlined style={{ fontSize: "18px" }} />}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </Space>
  </>
);
export default UserListFilter;
