import BooleanComponent from "./boolean.element";
import Dropdown from "./dropdown.element";
import Prefilled from "./prefilled.element";
import ResultEditable from "./resultEditable.element";
import ResultLocked from "./resultLocked.element";

const elementData = {
  dropdown: Dropdown,
  boolean: BooleanComponent,
  prefilled: Prefilled,
  result_editable: ResultEditable,
  result_locked: ResultLocked,
};
export default elementData;
