import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";

import api from "../../api/api";
import { INVOICE_TYPE } from "../../redux/invoice/invoice.types";
import { createValidUrl } from "../../utils/commonFuctions";
import { NumberFormat } from "../../utils/numberFormat";
import {
  downloadBase64File,
  getBase64OfHTMLDivComponentAsPDF,
} from "../contractpreview/v3/helper";
import SmallLoader from "../loader/smallLoader";
import { invoiceEmailCategory, renderTableAndFooter } from "./helper";

const ViewProjectSummary = () => {
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const token = new URLSearchParams(location.search).get("token");
  const { projectDetails } = useSelector((state) => state?.invoice);
  const handleGet = async () => {
    setLoading(true);
    const response = await api.request({
      url: `/v3/invoice/customer/view-summary?token=${token}`,
      method: "get",
    });
    if (response.remote === "success") {
      dispatch({
        type: INVOICE_TYPE.PROJECT_DETAILS,
        payload: response.data.data,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (token) {
      handleGet(token);
    }
  }, [token]);
  const handleDownloadPdf = async () => {
    setGeneratingPdf(true);
    setTimeout(async () => {
      const base64 = await getBase64OfHTMLDivComponentAsPDF(
        "divToPrint",
        "certificateOfCompletion",
      );
      downloadBase64File(base64, `Zevbit.pdf`);
      setGeneratingPdf(false);
    }, 1000);
  };
  return (
    <>
      <>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              minHeight: "100vh",
            }}
          >
            <SmallLoader />
          </div>
        ) : (
          <div className="px-2 px-md-0">
            {" "}
            <Row justify="center" className="mt-3 mx-973">
              <Col xs={24} lg={24}>
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    type="primary"
                    onClick={handleDownloadPdf}
                    disabled={generatingPdf}
                    className="my-2 download-pdf-btn"
                    icon={
                      <>
                        {generatingPdf && <SmallLoader />}
                        <DownloadOutlined />
                      </>
                    }
                  >
                    Download
                  </Button>
                </div>

                <div id="divToPrint">
                  <Card bodyStyle={{ padding: "10px" }} className="mt-3 mb-4">
                    {projectDetails.baseDetails?.estimationCoverPhoto && (
                      <img
                        alt=""
                        src={createValidUrl(
                          projectDetails.baseDetails?.estimationCoverPhoto,
                        )}
                        className="rounded-3 w-100"
                      />
                    )}

                    <div className="px-4 pt-4">
                      {projectDetails.baseDetails?.logo && (
                        <img
                          alt="organization-logo"
                          src={createValidUrl(projectDetails.baseDetails?.logo)}
                          style={{ width: "155px", height: "74px" }}
                        />
                      )}
                    </div>
                    <div className="mt-3 p-4 d-flex align-items-start justify-content-between">
                      <ul className="list-style">
                        <li>
                          <span className="font-16">
                            {projectDetails.baseDetails?.name || ""}
                          </span>
                        </li>
                        {projectDetails.baseDetails?.phoneNumber && (
                          <li>
                            {`${formatPhoneNumber(
                              `+${
                                projectDetails.baseDetails?.phoneNumber || ""
                              }`,
                              "NATIONAL",
                            )}`}
                          </li>
                        )}
                        <li>{projectDetails.baseDetails?.email || ""}</li>
                        <li>{projectDetails.baseDetails?.address || ""}</li>
                      </ul>
                    </div>
                    <div className="p-4 d-flex align-items-start justify-content-between pt-0">
                      <ul className="list-style">
                        <li>
                          <span className="font-16">Billed To:</span>
                        </li>
                        {projectDetails.baseDetails?.billedToPhoneNumber && (
                          <li>
                            {`${formatPhoneNumber(
                              `+${
                                projectDetails.baseDetails
                                  ?.billedToPhoneNumber || ""
                              }`,
                              "NATIONAL",
                            )}`}
                          </li>
                        )}
                        <li>
                          {projectDetails.baseDetails?.billedToName || ""}{" "}
                          <br />
                          {projectDetails.baseDetails?.billedToAddress || ""}
                        </li>
                      </ul>
                      <ul className="list-style text-right">
                        <li>
                          Total Project <br />
                          {NumberFormat(
                            projectDetails.baseDetails?.totalProjectAmount,
                          )}
                        </li>
                        <li className="mt-2">
                          Project Balance <br />
                          {NumberFormat(
                            projectDetails.baseDetails?.totalProjectAmount -
                              projectDetails.baseDetails
                                ?.totalPaidProjectAmount,
                          )}
                        </li>
                        <li className="mt-2">
                          Total Paid <br />
                          {NumberFormat(
                            projectDetails.baseDetails?.totalPaidProjectAmount,
                          )}
                        </li>
                      </ul>
                    </div>
                    <div className="px-2 invoice-title mt-3">
                      {projectDetails.projectSummaryTable &&
                        renderTableAndFooter({
                          tableData: projectDetails,
                          redirectFrom: invoiceEmailCategory.projectSummary,
                        })}
                    </div>
                    <div className="px-2 invoice-title mt-3">
                      {projectDetails.remainingPayments &&
                        renderTableAndFooter({
                          tableData: projectDetails,
                          redirectFrom: "remainingPayments",
                        })}
                    </div>
                    <div className="row justify-content-between mt-5">
                      <div className="col-md-6">
                        {projectDetails?.baseDetails?.summaryNote && (
                          <div className="mt-3 text-content">
                            <p
                              style={{
                                color: "#646973",
                                margin: 0,
                                fontSize: 12,
                                marginBottom: "5px",
                              }}
                            >
                              Project Summary
                            </p>

                            <div
                              className="border p-3 mobile-min-w mb-2 mb-md-0"
                              style={{
                                borderRadius: 8,
                                minWidth: 500,
                                minHeight: 150,
                                cursor: "pointer",
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `<p>${projectDetails.baseDetails?.summaryNote}</p>`,
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="summary col-md-3 ">
                        <div className="summary-item">
                          <span>Signed Estimate</span>
                          <span>
                            {NumberFormat(
                              projectDetails.baseDetails.signedEstimate,
                            )}
                          </span>
                        </div>
                        <div className="summary-item">
                          <span>Change Orders</span>
                          <span>
                            {NumberFormat(
                              projectDetails.baseDetails.changeOrders,
                            )}
                          </span>
                        </div>
                        <div className="summary-item">
                          <span>Credits</span>
                          <span className="ml-1">
                            {NumberFormat(projectDetails.baseDetails.credits)}
                          </span>
                        </div>
                        <hr />
                        <div className="summary-item">
                          <span>Total</span>
                          <span>
                            {NumberFormat(projectDetails.baseDetails.total)}
                          </span>
                        </div>

                        <div className="summary-item">
                          <span>Payments Received</span>
                          <span>
                            {NumberFormat(
                              projectDetails.baseDetails.paymentsReceived,
                            )}
                          </span>
                        </div>
                        <hr />
                        <div className="summary-item">
                          <span style={{ color: "#3483FA" }}>
                            Remaining Balance
                          </span>
                          <span>
                            {NumberFormat(
                              projectDetails.baseDetails.remainingBalance,
                            )}
                          </span>
                        </div>
                        <div className="summary-item">
                          <span style={{ color: "#E42626" }}>
                            {projectDetails.baseDetails?.overDue > 0
                              ? "Amount Overdue"
                              : projectDetails.baseDetails?.amountDue > 0
                              ? `Amount Due`
                              : ""}
                          </span>
                          <span>
                            {projectDetails.baseDetails?.overDue > 0
                              ? NumberFormat(
                                  projectDetails.baseDetails?.overDue,
                                )
                              : projectDetails.baseDetails?.amountDue > 0
                              ? NumberFormat(
                                  projectDetails.baseDetails?.amountDue,
                                )
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </>
    </>
  );
};

export default ViewProjectSummary;
