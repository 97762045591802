import { Col, Row } from "antd";
import React from "react";

import ReactMentionInput from "../../../../../utils/mentionInput/mentionInput";

function ResultEditable({
  element,
  processedElementList,
  handleChange,
  idx,
  hiddenValueList,
  onFocusOut,
  selectedCatalog,
  getSelectedCatalog,
}) {
  const [value, setValue] = React.useState("");
  const [newValue, setNewValue] = React.useState("");
  React.useEffect(() => {
    setValue(element.value);
  }, [element.value]);

  const handleValueChange = (value, newValue) => {
    setValue(value);
    setNewValue(newValue);
  };
  const handleSaveValue = () => {
    handleChange(value, "value", idx, newValue);
    onFocusOut();
  };

  return (
    <>
      <Row gutter={[24, 0]} className="mb-3">
        <Col md={8} xs={24}>
          <label>Formula(editable)</label>
        </Col>
        <Col md={16} xs={24}>
          <ReactMentionInput
            className="ant-furmulla-input"
            elementList={processedElementList}
            onChange={(e, newValue) => {
              e = { target: { ...e.target, name: "value" } };
              if (selectedCatalog) {
                e.target.value = e.target.value.replace(
                  selectedCatalog.display,
                  selectedCatalog.slug,
                );
                newValue = newValue.replace(
                  selectedCatalog.display,
                  selectedCatalog.slug,
                );
              }
              handleValueChange(e.target.value, newValue);
            }}
            hiddenInputList={hiddenValueList.map((hiddenValue) => ({
              display: hiddenValue.name,
              id: hiddenValue._id,
            }))}
            placeholder="Enter Formula use '@' or '#' for the dynamic values"
            value={value}
            onBlur={() => handleSaveValue()}
            disabled={element.disabled}
            isCustomInput
            customInput={element.customInput?.map((input) => ({
              display: input.name,
              id: input.id || input._id,
            }))}
            isTotalCharge
            getSelectedCatalog={getSelectedCatalog}
          />
        </Col>
      </Row>
    </>
  );
}

export default ResultEditable;
