import { manageUserRole } from "../../utils/constants/constants";

export function transformUserResponse(user) {
  return {
    id: user?._id,
    userRole: user?.role,
    contactNo: user?.contactNo,
    email: user?.email,
    name: user?.name,
    profileImage: user?.profileImage,
    companyName: user?.companyName,
    activeStatus: user?.activeStatus,
    companyImage: user?.companyImage,
    currency: user?.currency,
    timeZone: user?.timeZone,
    createdAt: user?.createdAt,
    isAdmin: manageUserRole(user?.role?.roleName),
    organization: user?.organization,
    isCompleteSetup: user?.isCompleteSetup,
    activePhoneNumber: user?.twilioNumber?.phone_number,
    forwardNumber: user?.twilioNumber?.forward_number,
    permission: user?.permission?.permissions || [],
    isAccountOwner: user?.isAccountOwner,
    isFirstTimeEstimateConvert: user.isFirstTimeEstimateConvert,
    isFirstTimeCatalogAccess: user.isFirstTimeCatalogAccess,
  };
}
