import "./style.css";

import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Image,
  Input,
  Modal,
  Tabs,
  Typography,
  Upload,
} from "antd";
import * as htmlToImage from "html-to-image";
import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

import { createValidUrl } from "../../../utils/commonFuctions";
const { TabPane } = Tabs;
const fontStyles = [
  "Seaweed Script",
  "Oleo Script",
  "Dancing Script",
  "Kaushan Script",
  "Imperial Script",
  "Euphoria Script",
  "Vujahday Script",
  "Petit Formal Script",
];
export const ESignature = ({
  isOpen,
  onClose,
  onSave,
  title,
  setcustSign,
  setIsTempElementShow,
  requiredFullName,
  isDefault,
  organizationSignature,
  contractorName,
}) => {
  const [isFontStyles, setFontStyles] = useState(false);
  const [selectedFontStyle, setSelectedFontStyle] = useState(null);
  const [fullname, setFullname] = useState(null);
  const [selectedTab, setSelectedTab] = useState("1");
  const [imageData, setImageData] = useState(null);
  const signRef = useRef();
  const [form] = Form.useForm();
  const [isSignature, setSignature] = useState(true);
  const [savingSign, setSavingSign] = useState(false);
  const [fullNameError, setFullNameError] = useState("");
  const onClearSignature = () => {
    if (signRef) {
      signRef.current.clear();
      setSignature(true);
    }
  };

  const handleCancel = () => {
    setFontStyles(false);
  };

  const onInputChange = (e) => {
    if (e.target.value) {
      setSignature(false);
    } else {
      setSignature(true);
    }
    if (setIsTempElementShow) {
      setIsTempElementShow(true);
    }
    setFullname(e.target.value);
    setSelectedFontStyle(selectedFontStyle || fontStyles[0]);
  };
  const onSignatureSave = async () => {
    if (!fullname && requiredFullName) {
      setFullNameError("Full Name is required");
      return;
    }
    switch (selectedTab) {
      case "1":
        setSavingSign(true);
        const element = document.getElementById("e-sign-type");
        const dataUrl = await new Promise((resolve) => {
          htmlToImage.toPng(element).then(function (dataUrl) {
            resolve(dataUrl);
          });
        });
        if (setcustSign) {
          setcustSign(dataUrl);
        }
        onSave(dataUrl, title, fullname, "STYLE");

        setSavingSign(false);

        break;
      case "2":
        setSavingSign(true);
        if (!signRef.current.isEmpty()) {
          onSave(signRef.current.toDataURL(), title, fullname, "DRAW");
        }
        setSavingSign(false);
        break;
      case "3":
        setSavingSign(true);
        onSave(imageData, title, fullname, "UPLOAD");
        setSavingSign(false);

        break;
      case "4":
        setSavingSign(true);
        onSave(organizationSignature, title, fullname, "DEFAULT");
        setSavingSign(false);
        break;
      default:
        break;
    }
  };

  const onImageUpload = async (e) => {
    if (e.file.status === "error") {
      const dataUrl = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(e.file.originFileObj);
      });
      if (e.fileList?.length) {
        setSignature(false);
      }
      setImageData(dataUrl);
    }
  };

  const onTabChange = (e) => {
    if (e === "4") {
      setFullname(contractorName);
      setSignature(false);
    }
    setSelectedTab(e);
    form.resetFields();
  };

  return (
    <>
      <Modal
        className="modal-radius"
        title={`${title}`}
        visible={isOpen}
        footer={null}
        onCancel={onClose}
        width={500}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            name="fullname"
            label="FULL NAME"
            rules={[{ required: true, message: "Full Name is required" }]}
          >
            <Input
              onChange={onInputChange}
              size="large"
              className="radius-30"
              value={fullname}
            />
            <span style={{ color: "red" }}>{fullNameError}</span>
          </Form.Item>
        </Form>
        <Tabs
          defaultActiveKey="1"
          onChange={onTabChange}
          className="e-signature-modal e-signature-color-class "
        >
          <TabPane tab="SELECT STYLE" key="1">
            <Card bordered={false}>
              <Typography style={{ fontWeight: "bold" }}>PREVIEW</Typography>
              {fullname && (
                <Card type="inner">
                  <Typography
                    className={
                      selectedFontStyle === "Seaweed Script"
                        ? "SeaweedScript"
                        : selectedFontStyle === "Oleo Script"
                        ? "OleoScript"
                        : selectedFontStyle === "Dancing Script"
                        ? "DancingScript"
                        : selectedFontStyle === "Kaushan Script"
                        ? "KaushanScript"
                        : selectedFontStyle === "Imperial Script"
                        ? "ImperialScript"
                        : selectedFontStyle === "Euphoria Script"
                        ? "EuphoriaScript"
                        : selectedFontStyle === "Vujahday Script"
                        ? "VujahdayScript"
                        : selectedFontStyle === "Petit Formal Script"
                        ? "PetitFormalScript"
                        : ""
                    }
                    style={{
                      fontSize: 24,
                      paddingLeft: 5,
                    }}
                    id="e-sign-type"
                  >
                    {fullname}
                  </Typography>
                </Card>
              )}
              <div className="d-flex justify-content-center mt-2">
                <Button
                  type="primary"
                  className="add-btn"
                  size="middle"
                  onClick={() => setFontStyles(true)}
                  disabled={!fullname}
                >
                  SHOW MORE
                </Button>
              </div>
            </Card>
          </TabPane>

          <TabPane tab="DRAW" key="2">
            <div className="d-flex justify-content-center">
              <SignatureCanvas
                penColor="blue"
                canvasProps={{
                  width: 400,
                  height: 200,
                  className: "sigCanvas",
                }}
                onBegin={() => setSignature(false)}
                ref={signRef}
              />
            </div>
            <div className="d-flex justify-content-center mt-2">
              <Button
                type="primary"
                className="add-btn"
                size="middle"
                onClick={() => onClearSignature()}
              >
                CLEAR
              </Button>
            </div>
          </TabPane>

          <TabPane tab="UPLOAD" key="3" className="text-center upload-message">
            <Upload
              accept="image/*"
              listType="picture"
              onChange={onImageUpload}
            >
              <Button icon={<UploadOutlined />}>BROWSE</Button>
            </Upload>
          </TabPane>
          {isDefault ? (
            <TabPane
              tab="DEFAULT"
              key="4"
              className="text-center upload-message"
            >
              <Image
                src={createValidUrl(organizationSignature)}
                preview={false}
              />
            </TabPane>
          ) : (
            ""
          )}
        </Tabs>
        <div className="d-flex justify-content-center mt-4">
          <Button
            type="primary"
            className="add-btn"
            size="middle"
            style={{ marginRight: 10 }}
            onClick={() => onSignatureSave()}
            disabled={isSignature || savingSign}
          >
            {savingSign ? "SAVING..." : "SAVE"}
          </Button>
          <Button
            type="primary"
            className="add-btn"
            size="middle"
            onClick={onClose}
          >
            CANCEL
          </Button>
        </div>
      </Modal>
      {isFontStyles && (
        <Modal
          visible={isOpen}
          onClose={() => setFontStyles(false)}
          closable={false}
          onCancel={handleCancel}
          bodyStyle={{ padding: 0 }}
          width={500}
          footer={false}
          className="modal-radius"
        >
          <div className="scroll-modal">
            {fullname &&
              fontStyles.map((font) => (
                <Card type="inner" onClick={() => setSelectedFontStyle(font)}>
                  <Typography
                    className={
                      font === "Seaweed Script"
                        ? "SeaweedScript"
                        : font === "Oleo Script"
                        ? "OleoScript"
                        : font === "Dancing Script"
                        ? "DancingScript"
                        : font === "Kaushan Script"
                        ? "KaushanScript"
                        : font === "Imperial Script"
                        ? "ImperialScript"
                        : font === "Euphoria Script"
                        ? "EuphoriaScript"
                        : font === "Vujahday Script"
                        ? "VujahdayScript"
                        : font === "Petit Formal Script"
                        ? "PetitFormalScript"
                        : ""
                    }
                    style={{ fontSize: 24, cursor: "pointer" }}
                    onClick={handleCancel}
                  >
                    {fullname}
                  </Typography>
                </Card>
              ))}
          </div>
        </Modal>
      )}
    </>
  );
};
