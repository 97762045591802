import { DownOutlined } from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Col,
  Dropdown,
  Menu,
  message,
  Row,
  Space,
} from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { cancelTrial } from "../../api/planPricing";
import userProfile from "../../images/blank-profile.png";
import store from "../../store";
import { createValidUrl } from "../../utils/commonFuctions";
import { DATE_FORMATE_FOR_FREE } from "../../utils/constants/constants";
import { Help } from "../../utils/svg.file";
// import userProfile from "../../images/profile_chat.png";
import SmallLoader from "../loader/smallLoader";
import ModalMain from "../modal/modal.component";
import NotificationModal from "./NotificationModal";
class Menubar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {},
      loading: true,
      selectedNotification: {},
      trial: false,
      deleteModal: false,
      trailEndDate: null,
      apiLoader: false,
    };
  }

  componentDidMount = async () => {
    // this.setState({ loading: true });
    const currentUser = store.getState().user;
    this.setState({ currentUser, loading: true });
    try {
      // let socket = io(`${URL}/live-tracking`, { autoConnect: false });
      // this.setState({ socket });
      // socket.connect();
      // socket.auth = { token };
      // socket.on("connect_error", (error) => {
      //   socket = "";
      //   this.setState({ socket });
      // });
      // socket.on("connect", () => {
      //   if (userId) {
      //     socket.emit("unread-notifications");
      //     socket.on("send-unread-notifications", (notifications) => {
      //       const tempArr = notifications?.filter(
      //         (notification) => !notification?.read
      //       );
      //       this.setState({
      //         ...this.state,
      //         notifications:
      //           notifications?.sort(
      //             (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
      //           ) || [],
      //         unreadNotification:
      //           tempArr?.sort(
      //             (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
      //           ) || [],
      //       });
      //     });
      //   }
      // });
    } catch (error) {
      console.log(`Notification connect_error due to ${error}`);
    }
    this.setState({
      trial: localStorage.getItem("trailAccount") || false,
    });
    this.setState({
      trailEndDate: localStorage.getItem("trailEndDate") || null,
    });
    if (localStorage.getItem("isCardDeclined") === "true") {
      this.setState({ loading: false });
    }
  };

  componentDidUpdate() {
    if (store.getState().user !== this.state.currentUser) {
      this.setState({ currentUser: store.getState().user, loading: false });
    }
  }

  logout = async () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location = "/auth";
  };

  handleSelectNotification = (data) => {
    this.setState({
      selectedNotification: data,
    });
    !data?.read && this.handleSetReadFlag(data);
  };

  handleSetReadFlag = () => {
    // if (value?._id) {
    //   this.state.socket.emit("update-read-status", {
    //     timeCardId: value?._id,
    //   });
    // }
  };

  handleSetAllReadFlag = () => {
    // this.state.socket.emit("mark-all-read");
  };

  handleCancel = () => {
    this.setState({ selectedNotification: {} });
  };

  handleCancelTrial = async () => {
    if (this.state.currentUser?.email) {
      this.setState({ apiLoader: true });
      const response = await cancelTrial({
        email: this.state.currentUser?.email,
      });
      if (response?.remote === "success") {
        message.success(
          response?.data?.message ||
            response?.message ||
            "Free trial cancelled successfully.",
          10,
        );
        this.setState({ apiLoader: false });
        this.setState({ deleteModal: false });
        this.logout();
      } else {
        message.error(
          response?.error ||
            response?.data?.error ||
            "Something went to wrong, Please refresh website and try again!",
          10,
        );
        this.setState({ apiLoader: false });
        this.setState({ deleteModal: false });
      }
    } else {
      this.setState({ apiLoader: false });
      this.setState({ deleteModal: false });
      message.error(
        "Something went to wrong! Please refresh website and try again.",
        10,
      );
    }
  };

  // showModal = () => {
  //   this.setState({
  //     isNotification: true,
  //   });
  // };

  handleOk = () => {
    // this.setState({ isNotification: false })
  };
  // handleVisibleChange = (visible) => {
  //   this.setState({ visible })
  // }

  handleChangeAccount = () => {
    const superAdminToken = localStorage.getItem("superAdminToken");
    localStorage.setItem("token", superAdminToken);
    localStorage.removeItem("superAdminToken");
    localStorage.removeItem("fromSuperAdmin");
    localStorage.setItem("userRole", "superAdmin");
    window.location.replace("/v2/crm");
  };

  render() {
    const { loading } = this.state;
    const subscriptionPlan =
      store.getState().api?.subscriptionDetails?.subscriptionPlanName;

    const menu = (
      <Menu>
        {localStorage.getItem("isSubscriptionCanceled") === "false" && (
          <Menu.Item key="0">
            <Link to={`/account-details/${this.state.currentUser.id}`}>
              {this.state.currentUser?.userRole?.roleName === "superAdmin" ||
              this.state.currentUser?.userRole?.roleName === "salesPerson" ||
              (this.state.currentUser?.userRole?.roleName === "admin" &&
                this.state.currentUser?.userRole?.superId === null)
                ? "Profile"
                : "Profile & Billing"}
            </Link>
          </Menu.Item>
        )}

        <Menu.Divider />
        <Menu.Item key="3" onClick={this.logout}>
          Log Out
        </Menu.Item>
      </Menu>
    );
    return (
      <>
        {loading ? (
          <SmallLoader />
        ) : (
          <div className="d-lg-flex align-items-center">
            {/* {loading && <Skeleton />} */}
            <div className="logo me-2 me-lg-4">
              <img
                src={
                  this.state.currentUser.organization &&
                  this.state.currentUser.organization.logo
                    ? createValidUrl(this.state.currentUser.organization.logo)
                    : loading
                  // : logo
                }
                alt=""
                // onError={(e) => {
                //   e.target.onerror = null;
                //   e.target.src = logo;
                // }}
              />
            </div>

            <h1 className="mb-0 dash-titile">
              Welcome <span>{this.state.currentUser.name}!</span>
            </h1>
          </div>
        )}

        <div className="ms-auto d-lg-flex align-items-center">
          {!loading &&
            (this.state.trial === true || this.state.trial === "true") && (
              <div className="trial-alert me-3 d-none d-lg-block">
                {" "}
                <Alert
                  message={`Your trial period will end on ${
                    this.state?.trailEndDate ? this.state?.trailEndDate : ""
                  }`}
                  type="info"
                  showIcon
                  closable
                  action={
                    <Space direction="vertical">
                      <Button
                        type="primary"
                        onClick={() => this.setState({ deleteModal: true })}
                        className="radius-30 ant-primary-btn height-39 font-15 font-bold d-none align-items-center justify-content-center d-lg-flex"
                      >
                        Cancel Trial
                      </Button>
                    </Space>
                  }
                />
              </div>
            )}
          {/* comment for soft launch commented  by jimFall */}
          {/* {this.state.currentUser &&
            (this.state.currentUser.userRole === "admin" ||
              (this.state.currentUser.isAdmin === true &&
                this.state.currentUser.userRole !== "superAdmin")) && (
              <CustomButton className="px-5">Admin</CustomButton>
            )}

          {this.state.currentUser &&
            this.state?.currentUser?.userRole?.roleName ===
              USER_ROLE.superAdmin && (
              <Button
                type="primary"
                className="radius-30 ant-primary-btn px-5 height-39 font-15 font-bold me-lg-2 d-none align-items-center justify-content-center d-lg-flex"
              >
                Super Admin
              </Button>
            )} */}

          {/* {loading ? (
            ""
          ) : ( */}
          <div className="rightside">
            <ul className="border-0 ant-menu-overflow ant-menu ant-menu-root ant-menu-horizontal ant-menu-light">
              {localStorage.getItem("fromSuperAdmin") && (
                <Button
                  style={{ margin: "auto 0", borderRadius: 8 }}
                  type="primary"
                  onClick={this.handleChangeAccount}
                >
                  Back To Super Admin
                </Button>
              )}
              {this.state.currentUser.organization &&
                this.state.currentUser.organization?.trial_end &&
                subscriptionPlan !== "essential" && (
                  <li>
                    <span className="freetrail">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: "10px" }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16 8V5L19 2L20 4L22 5L19 8H16ZM16 8L12 11.9999M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7"
                          stroke="#027A48"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {/*    {moment(
                        this.state.currentUser.organization?.trial_end
                      ).diff(moment(), "days")}{" "} */}
                      {moment(this.state.currentUser.organization?.trial_end)
                        .add(1, "days")
                        .startOf("day")
                        .diff(
                          moment().add(1, "days").startOf("day"),
                          "days",
                        )}{" "}
                      <span className="d-none d-lg-inline-block">
                        Free Trial Days Remaining
                      </span>
                    </span>
                  </li>
                )}
              {this.state.currentUser.organization &&
                this.state.currentUser.organization?.subscription_end && (
                  <li>
                    <span className="delectAccount">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        style={{ marginRight: "10px" }}
                      >
                        <path
                          d="M9.99935 6.66699V10.0003M9.99935 13.3337H10.0077M18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003Z"
                          stroke="#B42318"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>{" "}
                      <span className="d-none d-lg-inline-block">
                        {" "}
                        Account Canceled - Access Ends On
                      </span>{" "}
                      {moment(
                        this.state.currentUser.organization?.subscription_end,
                      ).format(DATE_FORMATE_FOR_FREE)}
                    </span>
                  </li>
                )}
              <li>
                <a
                  href="https://zevbit.helpcenter.io/content/step-1-general-overview"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Help width={31} height={31} />
                </a>
              </li>
              {/* comment for soft launch commented by jimFall */}
              {/* <li>
                  <Link to="/messages">
                    <Badge
                      overflowCount={999}
                      count={this.props?.groupReducer?.messageCount}
                      className="ant-badge-blue"
                    >
                      {Message}
                    </Badge>
                  </Link>
                </li>
                <li>
                  <Popover
                    placement="bottomRight"
                    overlayClassName="notification-modal"
                    content={content}
                    title={[
                      <div className="d-flex align-items-center justify-content-between xd-title-notifications p-3">
                        <h5 className="mb-0">Notifications</h5>
                        <li
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              visible: false,
                            });
                            this.handleSetAllReadFlag();
                          }}
                          style={{ color: "blue" }}
                        >
                          Mark all as read
                        </li>
                      </div>,
                    ]}
                    visible={this.state.visible}
                    trigger="click"
                    onVisibleChange={this.handleVisibleChange}
                  >
                    <Badge
                      overflowCount={999}
                      count={this.state.unreadNotification?.length}
                      className="ant-badge-red"
                    >
                      {notifation}
                    </Badge>
                  </Popover>
                </li> */}
              <li className="text-nowrap">
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  className="d-block"
                  overlayStyle={{
                    position: "fixed",
                    top: "70px",
                    right: "10px",
                  }}
                >
                  <span>
                    <Avatar
                      className="user-profile-top"
                      src={
                        createValidUrl(this.state.currentUser.profileImage) ||
                        userProfile
                      }
                    />{" "}
                    <DownOutlined />
                  </span>
                </Dropdown>
              </li>
            </ul>
          </div>
          {/* )} */}
        </div>
        <ModalMain
          handleCancel={() => this.setState({ deleteModal: false })}
          ModalVisible={this.state?.deleteModal}
          width={350}
          footer={null}
          title="Warning!"
          className="modal-radius warning-modal"
          content={
            <>
              <div className="fs-6 mb-4">
                Are you sure you want to cancel the trial?
              </div>
              <Row>
                <Col md={12} className="text-center">
                  <Button
                    type="text"
                    onClick={() => this.setState({ deleteModal: false })}
                  >
                    No
                  </Button>
                </Col>
                <Col md={12}>
                  <Button
                    type="primary"
                    onClick={() => this.handleCancelTrial()}
                    loading={this.state?.apiLoader || false}
                  >
                    Yes
                  </Button>
                </Col>
              </Row>
            </>
          }
        />
        <NotificationModal
          currentData={this.state.selectedNotification}
          isNotification={Object.keys(this.state.selectedNotification)?.length}
          handleCancel={this.handleCancel}
          handleOk={this.handleOk}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user, groupReducer } = state;
  return { user, groupReducer };
}

export default connect(mapStateToProps)(Menubar);
