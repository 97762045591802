import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, message, Progress, Row, Typography, Upload } from "antd";
import React, { useState } from "react";

import UploadFileIcon from "../../../../../images/File upload icon.png";
import { imageFileExtensions } from "../../../../../utils/constants/constants";
import { uploadAttachment } from "../../../../../utils/svg.file";

function formatFileSize(bytes) {
  if (bytes < 1024) {
    return `${bytes} bytes`;
  }
  if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(2)} KB`;
  }
  return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
}

const UploadIdCard = ({
  setFormData,
  formData,
  setFormModal,
  setConfirmModal,
}) => {
  const { Dragger } = Upload;
  const { Text } = Typography;
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errors, setErrors] = useState("");
  const checkFileSize = (file) => {
    const maxSize = 100 * 1024 * 1024;
    if (file.size > maxSize) {
      message.error("File size must be less than 100MB");
      setFormData((prev) => ({ ...prev, idCard: null }));
      return false;
    }
    return true;
  };

  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    beforeUpload: checkFileSize,
    customRequest: async ({ file, onSuccess, onError }) => {
      try {
        const formData = new FormData();
        formData.append("file", file);
        if (imageFileExtensions.includes(file?.type)) {
          setErrors("");
          setFormData((prev) => ({ ...prev, idCard: file }));
          const total = 100;
          let uploaded = 0;
          const randomInterval = Math.floor(Math.random() * (100 - 50) + 50);
          const interval = setInterval(() => {
            const increment = Math.floor(Math.random() * 10) + 1;
            uploaded += increment;
            if (uploaded > total) {
              uploaded = total;
            }
            setUploadProgress(Math.floor((uploaded / total) * 100));
            if (uploaded >= total) {
              clearInterval(interval);
              onSuccess();
            }
          }, randomInterval);
        } else {
          setErrors("Invalid file selected");
        }
      } catch (error) {
        onError(error);
      }
    },
  };

  return (
    <div>
      <div className="add-promo-code cencelnotes">
        <Row gutter={[0, 20]}>
          <Col xs={24}>
            <div className="upload-id-para mt-2 pb-0">
              Please upload a photo of the document type you have selected to
              continue with your verification.
            </div>
          </Col>
          <Col xs={24} className="upload-id-input">
            <Dragger
              {...props}
              accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon mb-0">{uploadAttachment}</p>
              <p className="ant-upload-text upload-attachment-text m-0 ant-upload-hint">
                <b>Click to upload</b> or
              </p>
              <p className="ant-upload-hint upload-attachment-text m-0">
                <small>JPG, PNG, JPEG (max. 100MB)</small>
              </p>
            </Dragger>
          </Col>
          {errors && (
            <Text
              className="text-danger d-block"
              style={{ fontSize: "14px", padding: "5px 12px 0px" }}
            >
              {errors}
            </Text>
          )}
          {formData?.idCard?.name && (
            <Col xs={24}>
              <div className="upload-Id-progressBar">
                <div className="progressBar-inner-forFlex">
                  <div className="inner-progressBar">
                    <div className="img">
                      <img src={UploadFileIcon} alt="File Icons" />
                    </div>
                    <div className="fileName" style={{ width: "100%" }}>
                      <div className="photoName">{formData?.idCard?.name}</div>
                      <div className="photoSize">
                        {formatFileSize(formData?.idCard?.size)}
                      </div>
                      {uploadProgress > 0 && (
                        <Progress percent={uploadProgress} />
                      )}
                    </div>
                  </div>
                  <DeleteOutlined
                    onClick={() => {
                      setFormData((prev) => ({ ...prev, idCard: null }));
                      setUploadProgress(0);
                    }}
                  />
                </div>
              </div>
            </Col>
          )}
          <Row className="upload-id-two-buttons">
            <Col xs={10}>
              <Button
                onClick={() => {
                  setFormData((prev) => ({ ...prev, idCard: null }));
                  setUploadProgress(0);
                  setFormModal((prev) => ({ ...prev, idCardModal: false }));
                  setErrors("");
                }}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                }}
                size="large"
                type="primary"
              >
                Cancel
              </Button>
            </Col>
            <Col xs={10}>
              <Button
                onClick={() => {
                  setFormModal((prev) => ({ ...prev, idCardModal: false }));
                  setConfirmModal(true);
                }}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                }}
                size="large"
                type="primary"
                disabled={!formData?.idCard?.name}
              >
                Upload
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default UploadIdCard;
