import { Card, Col, Row, Typography } from "antd";
import React from "react";

import { ReactComponent as TipIcon } from "../../../../images/affiliateImages/hint-icon.svg";
import { ReactComponent as RulesIcon } from "../../../../images/affiliateImages/rules-icon.svg";
import { rules, rulesCardData } from "../../util";

const { Title } = Typography;

const Rules = () => (
  <div style={{ marginTop: 20 }}>
    <Title level={4} style={{ fontWeight: "normal", marginBottom: 20 }}>
      <TipIcon style={{ marginRight: 5, color: "#3483FA" }} />
      <span style={{ verticalAlign: "middle" }}>Tips</span>
    </Title>
    <Row gutter={[24, 24]}>
      {rulesCardData.map(({ title, desc }, ind) => (
        <Col
          key={`cards-${ind}`}
          lg={Math.round(24 / rulesCardData?.length)}
          xs={24}
        >
          <Card
            title={title}
            className="removeBorder h-100"
            style={{ boxShadow: "0px 4px 4px 0px #0000001F" }}
          >
            <div style={{ color: "#667085" }}>{desc}</div>
          </Card>
        </Col>
      ))}
      <Col span={24} className="mb-5">
        <Card
          title={
            <>
              <RulesIcon style={{ color: "#3483FA" }} />
              <span>Rules</span>
            </>
          }
          className="removeBorder mt-lg-5"
          style={{
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
            background: "#FAFAFA",
          }}
        >
          <ul style={{ color: "#667085", lineHeight: "35px" }}>
            {rules.map((rule) => (
              <li>{rule}</li>
            ))}
          </ul>
        </Card>
      </Col>
    </Row>
  </div>
);

export default Rules;
