import { Card, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";

import ScheduleDemo from "./common/ScheduleDemo";
import StartFreeTrial from "./common/StartFreeTrial";

const BannerSection = ({ promoCode }) => {
  const state = useSelector((state) => state?.landingDetails);
  return (
    <Card
      id="banner-section"
      className="affiliate-landing-banner mt-5"
      style={{ borderRadius: 8 }}
    >
      <Row
        gutter={16}
        style={{ color: "#fff", textAlign: "center", alignItems: "center" }}
      >
        <p className="w-100">Congratulations!!!</p>
        <p style={{ fontSize: 45, fontWeight: 600 }} className="w-100 mb-4">
          {state?.textOfLandingPage}
        </p>
        <p style={{ fontSize: 20, width: "100%" }}>
          Don&lsquo;t miss out on this opportunity to save while gaining access
          to our amazing service!
        </p>
        <div style={{ fontSize: 45, fontWeight: 600 }} className="w-100">
          <ScheduleDemo className="mx-2" promoCode={promoCode} />
          <StartFreeTrial
            className="mx-2"
            style={{ color: "#1570EF" }}
            promoCode={promoCode}
          />
        </div>
      </Row>
    </Card>
  );
};

export default BannerSection;
