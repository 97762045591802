import "../../affiliate.css";

import { Form, Input, Typography } from "antd";
import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

import ModalDailog from "../../../../../common/components/ModalDialogue";
import { trimNumber } from "../../../../../utils/javascript";
import AffiliateFormInput from "../../../AffiliateFormInput";
import { paymentMethodLogo } from "../../../util";

const { Title, Text } = Typography;

const AffiliateCommonForm = ({ formData, label, selectDataKey, className }) => (
  <Form layout="vertical">
    {formData?.length ? (
      <>
        <label style={{ paddingBottom: 8 }}>{label}</label>
        {formData?.map((formDataObj) => (
          <AffiliateFormInput
            className={className}
            value={formDataObj[selectDataKey]}
          />
        ))}
      </>
    ) : (
      <AffiliateFormInput
        value="-"
        label={label}
        className={classNames({
          "affiliate-disable-input": !formData?.length,
        })}
      />
    )}
  </Form>
);

export const ReferralLinkModal = ({
  isVisibleLinkModal,
  handleLinkShareModalVisibility,
  referralLinks,
}) => (
  <>
    <ModalDailog
      isModalOpen={isVisibleLinkModal}
      handleCancel={() => handleLinkShareModalVisibility()}
      title="Default Referral Link"
      className="myReferal-referral-link"
      width={550}
    >
      <span className="text-secondary">
        Share this link with other Landscaping companies and friends to start
        earning money with your referrals.
      </span>
      <AffiliateCommonForm
        formData={referralLinks?.promoCodes}
        label="Referral Link"
        selectDataKey="link"
      />
    </ModalDailog>
  </>
);

export const ReferralPromoCodeModal = ({
  isVisibleCodeModal,
  handlePromoCodeModalVisibility,
  referralLinks,
}) => (
  <ModalDailog
    isModalOpen={isVisibleCodeModal}
    handleCancel={() => handlePromoCodeModalVisibility()}
    title="Default Promo Code"
    className="myReferal-referral-link"
    width={400}
  >
    <span className="text-secondary">
      Share this Promo Code with other Landscaping companies and friends to
      start earning money with your referrals.
    </span>
    <AffiliateCommonForm
      formData={referralLinks?.promoCodes}
      label="Promo Code"
      selectDataKey="couponId"
      className="change-input-color-red"
    />
  </ModalDailog>
);

export const PayoutModal = ({
  children,
  isModalOpen,
  handleCancel,
  handleChange,
  value,
  form,
  handleConfirmPayout,
  summaryData,
}) => {
  const formErrors = useSelector((state) => state.affiliate.error?.formErrors);
  const remainingBalance =
    summaryData?.myBalance - summaryData?.totalRequestedAmount;
  return (
    <ModalDailog
      isModalOpen={isModalOpen}
      handleCancel={() => handleCancel()}
      title="Payment Request"
      className="fullWidth my-referral-popup Email-Popup"
    >
      <span className="text-secondary">
        <p>
          Our team will review your withdrawal request and make the payment to
          your payment method on file. Please note if the payment method you
          have selected has a fee, that fee will be deducted from the amount. If
          you would like your payment made to a different payment method please
          update that first under the <strong>Documentation & Payments</strong>{" "}
          tab.
        </p>
      </span>
      <Title level={5} className="my-referral-default-method">
        Default Payment Method
      </Title>
      <img
        src={paymentMethodLogo.paypal}
        alt="default-payment-method"
        className="mt-2"
      />
      <div style={{ textAlign: "right" }} className="mb-4">
        <div className="d-flex justify-content-end mb-2">
          <span className="payout-balance-label">My Balance:</span>
          <span className="payout-balance-amount">
            ${trimNumber(summaryData?.myBalance)}
          </span>
        </div>
        <div className="d-flex justify-content-end mb-2">
          <span className="payout-balance-label">Withdrawable Balance:</span>
          <span className="payout-balance-amount">
            ${trimNumber(remainingBalance)}
          </span>
        </div>
        <div className="d-flex justify-content-end mb-2">
          <span className="payout-requested-label">Payout Request:</span>
          <Form
            form={form}
            onFinish={handleConfirmPayout}
            style={{ margin: 0 }}
            className="payout-request-form"
          >
            <Form.Item name="payout-request">
              <Input
                onChange={(e) => handleChange(e)}
                value={value}
                style={{ color: "#484D59" }}
                className="payout-modal-input-design text-start"
                name="payout-request"
                prefix="$"
              />
            </Form.Item>
          </Form>
        </div>

        {formErrors?.["payout-request"] && (
          <Text type="danger">{formErrors?.["payout-request"]}</Text>
        )}
      </div>
      {children}
    </ModalDailog>
  );
};
