/* eslint-disable no-unused-vars */
import { Divider, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { resetInvoiceDetailsData } from "../../../redux/invoice/invoice.action";
import SidebarModal from "../SidebarModal";
import { NAVBAR_MENU } from "./utils/helper";

const { Item, SubMenu } = Menu;
function Navbar(props) {
  const history = useNavigate();
  const { userRole, isAdmin, permission } = useSelector((state) => state.user);
  const subscriptionDetails = useSelector(
    (state) => state?.api?.subscriptionDetails,
  );

  const [prevUrl, setPrevUrl] = useState("");

  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openDialogue, setOpenDialogue] = useState({});
  const [additionClassName, setAdditionalClassName] = useState("open");
  const [popUpDesc, setPopUpDesc] = useState([]);
  const [videoLink, _] = useState({});
  const [thumbList, setThumbList] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    setAdditionalClassName(open ? "open" : "close");
  }, [open]);

  // function to generate JSX recursively for the menu
  const generateMenu = (menu) =>
    menu.map((item) => {
      if (item.hide) {
        return "";
      }

      if (Array.isArray(permission)) {
        permission.forEach((itemm) => {
          if (item.key === itemm.section && item.key !== "settings") {
            if (itemm.access === "deny") {
              item.disabled = true;
            }
          }
          if (item.key === itemm.section && itemm.section === "timeTracking") {
            itemm.modules.forEach((module) => {
              if (module.section === "webAdmin") {
                if (module.access === "deny") {
                  item.disabled = true;
                }
              }
            });
          }
          if (item.key === "settings" && itemm.section === "settings") {
            if (item.hide === true || itemm.access === "deny") {
              item.disabled = false;
            }
          }
        });
      }
      if (item.key === "calendar") {
        item.disabled = false;
      }

      if (item.disabled === true) {
        if (!location.pathname.includes(item.url)) {
          return "";
        }
        history("/v2/crm");
      }

      switch (item.type) {
        case "item":
          return (
            <Item {...item} key={item.url} style={{ overflow: "visible" }}>
              <NavLink
                to={item.url}
                {...{
                  ...(item?.needCustomStyle && { style: { marginLeft: 10 } }),
                  ...(item?.newTab && { target: "_blank" }),
                }}
              >
                {item.title}
              </NavLink>
            </Item>
          );
        case "subItem":
          const { child, ...rest } = item;
          return <SubMenu {...rest}>{generateMenu(child)}</SubMenu>;
        case "popUp":
          const { open, title, ...other } = item;
          return (
            <Item
              {...other}
              key={other.key}
              onClick={() => setOpenDialogue({ [other?.key]: true })}
            >
              {title}
            </Item>
          );
        default:
          return "";
      }
    });

  useEffect(() => {
    const menu = NAVBAR_MENU({
      userRole: userRole?.roleName,
      isAdmin,
      superId: userRole?.superId,
      subscriptionDetails,
    });
    const filteredData = menu?.filter((obj) => obj?.type === "popUp");
    setPopUpDesc([...filteredData]);
  }, [subscriptionDetails]);

  useEffect(() => {
    if (prevUrl) {
      const panel = new URLSearchParams(prevUrl).get("panel");
      if (panel === "invoicing") {
        dispatch(resetInvoiceDetailsData());
      }
    }
  }, [prevUrl]);

  const handleCancel = (key) => {
    setOpenDialogue({ [key]: false });
  };

  const handleBtnClick = (key) => {
    setOpenDialogue({ [key]: false });
    // history("/v2/affiliate-registration");
  };

  const selectBtnClick = (btnType, key) => {
    console.log("btnType", { btnType, key });
    switch (btnType) {
      case "cancel":
        return handleCancel(key);

      case "submit":
        return handleBtnClick(key);

      default:
        return null;
    }
  };

  return (
    <>
      {userRole?.roleName &&
      localStorage.getItem("isCardDeclined") !== "true" ? (
        <>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={["/v2/crm"]}
            forceSubMenuRender
            selectedKeys={[location.pathname]}
            className={`ant-siderbar-menu ${additionClassName}`}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            onClick={() => {
              setPrevUrl(location.search);
              if (props.onClose) {
                console.log("first");
                props.onClose();
              }
            }}
          >
            {generateMenu(
              NAVBAR_MENU({
                userRole: userRole?.roleName,
                isAdmin,
                superId: userRole.superId,
                subscriptionDetails,
              }),
            )}

            <Divider style={{ margin: "10px 0px" }} />
          </Menu>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default Navbar;
