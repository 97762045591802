import api from "./api";
import { transformSignUpResponse } from "./transform/auth";

export const signup = async (data) => {
  const response = await api.request({
    url: "/auth/sign-up",
    method: "POST",
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: transformSignUpResponse(response),
    };
  }
  return response;
};

// by Natasha - v3
export const updatedSignup = async (data) => {
  const response = await api.request({
    url: "/v3/auth/sign-up",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: transformSignUpResponse(response),
    };
  }
  return response;
};

export const getCdnToken = async () => {
  const response = await api.request({
    url: "/common/CDNToken",
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data.data,
    };
  }
  return response;
};
