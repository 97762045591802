import { Modal } from "antd";
import React from "react";
export default function ModalMain(props) {
  return (
    <>
      <Modal
        {...props}
        width={props.width}
        className={props.className || "modal-filter"}
        visible={props.ModalVisible}
        onOk={props.handleOk}
        centered
        onCancel={props.handleCancel}
        footer={null}
      >
        {props.content}
      </Modal>
    </>
  );
}
