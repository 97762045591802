import axios from "axios";

export const getIpAddress = async () => {
  let ip = {};
  await axios.get("https://api.ipify.org?format=json").then((resp) => {
    ip = { ...resp.data };
    return resp.data;
  });
  return ip;
};
