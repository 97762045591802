import "./index.css";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";

import App from "./App";
import ErrorBoundary from "./components/errorBoundary/errorBoundary.component";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <ErrorBoundary>
        <Provider store={store}>
          <SimpleReactLightbox>
            <App />
          </SimpleReactLightbox>
        </Provider>
      </ErrorBoundary>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
