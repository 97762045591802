import "../../../auth/paymentInformation.css";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Card, Col, Form, Input, message, Row, Space } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  checkTaxCalculationAction,
  reactivateSubscriptionAction,
} from "../../../../api/billing";
import { applyPromoCodeAPICall } from "../../../../api/planPricing";
import { getCurrentUser } from "../../../../api/user";
import card from "../../../../images/card.png";
import { plans } from "../../../../utils/constants/constants";
import { paymentInfoSchema } from "../../../../validators/auth/auth.validator";
import CodeDataWithAmountNotFree from "../../../auth/PaymentInfo/CodeDataWithAmountNotFree";
import CodeDataWithPercentageEntireNotFree from "../../../auth/PaymentInfo/CodeDataWithPercentageEntireNotFree";
import CodeDataWithPercentageNotFree from "../../../auth/PaymentInfo/CodeDataWithPercentageNotFree";
import CountryDropdown from "../../../common/CountryDropdown";
import SmallLoader from "../../../loader/smallLoader";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PRIVATE_KEY);

const initialFormData = {
  name: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  country: "",
};

function calculatePercentage(number, percentage) {
  const result = (number * percentage) / 100;
  return result.toFixed(2);
}

function PaymentForm({ setShowReactiveSub, setShowPaymentGate, selectedPlan }) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [form, setForm] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [cardError, setCardError] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = useState("");
  const userId = localStorage.getItem("userId");
  const planId =
    plans[selectedPlan || localStorage.getItem("planName")]?.planId;
  const [codeData, setCodeData] = useState({});
  const [isTax, setIsTax] = useState(false);
  const [isCountryChange, setIsCountryChange] = useState(false);
  const [isZipCode, setIsZipCode] = useState(false);
  const [taxAmount, setTaxAmount] = useState({});
  const orgData = useSelector((state) => state?.user?.organization);
  const planPrice = parseFloat(
    plans[selectedPlan || localStorage.getItem("planName")]?.planPrice,
  );
  let discountAmount =
    codeData?.discountType === "amount"
      ? codeData?.discount
      : codeData?.discountType === "percentage"
      ? parseFloat(calculatePercentage(planPrice, codeData?.discount))
      : 0;
  const freeTrialPeriod =
    codeData?.trialMonths > 0
      ? codeData?.trialMonths === 1
        ? "1 Month"
        : `${codeData?.trialMonths} Months`
      : "14 Day";

  const useOptions = () => {
    const fontSize = "16px";
    const options = React.useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: "300",
            "::placeholder": {
              color: "#bfbfbf",
            },
          },
          invalid: {
            color: "#9e2146",
          },
        },
      }),
      [fontSize],
    );
    return options;
  };

  const options = useOptions();

  const checkTaxCalculation = async () => {
    setLoader(true);
    const payload = {
      amount: parseFloat(
        plans[selectedPlan || localStorage.getItem("planName")]?.planPrice,
      ),
      zipCode: form?.zipCode,
      country: form?.country,
    };
    const result = await checkTaxCalculationAction(payload);
    if (result?.remote === "success") {
      setTaxAmount(result?.data?.data);
      setIsTax(true);
      setLoader(false);
    } else {
      message.error(result?.errors?.errors?.error, 5);
      setTaxAmount({});
      setLoader(false);
      setIsTax(false);
    }
  };

  useEffect(() => {
    if (form?.zipCode?.length === 0) {
      setTaxAmount({});
    }
  }, [form]);

  const debouncedSearch = _.debounce(async () => {
    setIsZipCode(true);
    setIsCountryChange(true);
  }, 3000);

  useEffect(() => {
    if (
      isCountryChange &&
      isZipCode &&
      form?.zipCode?.length &&
      form?.country?.length
    ) {
      checkTaxCalculation();
    }
  }, [isZipCode, isCountryChange]);

  const handleAllChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    if (e.target.name === "zipCode") {
      debouncedSearch(e.target.value);
      setIsTax(false);
      setIsZipCode(false);
      if (e.target.value?.length === 0) {
        setTaxAmount({});
      }
    }
  };

  const handleCountryChange = (code) => {
    setForm({ ...form, country: code });
    debouncedSearch(code);
    setIsCountryChange(false);
  };

  const handleSubmit = async (e) => {
    e && e?.preventDefault();
    setErrors({});
    const { isValid, errors: formError } = paymentInfoSchema(form);
    if (!isValid) {
      setErrors(formError);
    } else {
      setLoader(true);
      const cardNumberElement = elements.getElement(CardNumberElement);

      const res = await stripe.createToken(cardNumberElement, {
        name: form?.name,
        address_zip: form?.zipCode,
        address_line1: form?.address,
        address_state: form?.state,
        address_city: form?.city,
        address_country: form?.country,
      });

      if (res?.error) {
        setLoader(false);
        setCardError(res?.error);
      }

      if (res?.token?.id) {
        setLoader(true);
        const payload = codeData?.name
          ? {
              userId,
              planId,
              coupon: localStorage.getItem("promoCode") || promoCode,
              token: res?.token?.id,
              country: form?.country,
            }
          : {
              userId,
              planId,
              token: res?.token?.id,
              country: form?.country,
            };

        const response = await reactivateSubscriptionAction(payload);
        if (response?.remote === "success") {
          setLoader(false);
          message.success(
            "You have successfully reactivated your subscription!",
            10,
          );
          localStorage.setItem("isCardDeclined", false);
          localStorage.setItem("isSubscriptionCanceled", false);
          localStorage.setItem("token", response?.data?.data?.token);

          const result = await getCurrentUser();
          if (result?.data?.id) {
            const userId = result?.data?.id;
            localStorage.setItem("userId", userId);
          }
          if (result?.remote === "success") {
            dispatch({ type: "user/userLoaded", payload: result.data });
            if (!localStorage.getItem("userRole")) {
              localStorage.setItem("userRole", result.data.userRole);
            }
          }
          setShowPaymentGate(false);
          navigate("/v2/crm");
        } else if (response?.remote === "failure") {
          setLoader(false);
          message.error(
            response?.errors?.errors?.error ||
              "Sorry we have a problem processing your card, if the error persists contact your bank and try again later",
            10,
          );
        }
      }
    }
  };

  const handlePromoCodeChange = (e) => {
    const { value } = e.target;
    setPromoCode(value);
    if (value?.length > 0) {
      setPromoCodeError("");
    }
  };

  const handleTaxAfterCode = async (promoData) => {
    discountAmount =
      promoData?.discountType === "amount"
        ? promoData?.discount
        : promoData?.discountType === "percentage"
        ? calculatePercentage(planPrice, promoData?.discount)
        : 0;

    setLoader(true);
    const payload = {
      amount:
        parseFloat((planPrice - discountAmount)?.toFixed(2)) >= 0
          ? parseFloat((planPrice - discountAmount)?.toFixed(2))
          : 0,
      zipCode: form?.zipCode,
      country: form?.country,
    };
    const result = await checkTaxCalculationAction(payload);
    if (result?.remote === "success") {
      setTaxAmount(result?.data?.data);
      setIsTax(true);
      setLoader(false);
    } else {
      message.error(result?.errors?.errors?.error, 5);
      setTaxAmount({});
      setLoader(false);
      setIsTax(false);
    }
  };

  const handleApplyButton = async () => {
    if (promoCode?.length === 0) {
      setPromoCodeError("Please enter Promo Code!");
    } else {
      const payload = {
        planId,
        coupon: promoCode,
        isReactive: true,
        organizationId: orgData?._id,
      };

      setLoader(true);
      const response = await applyPromoCodeAPICall(payload);
      if (response?.remote === "success") {
        setLoader(false);
        setCodeData(response?.data?.data);
        message.success("Promo code applied successfully", 10);
        localStorage.setItem("promoCode", promoCode);
        if (form?.zipCode?.length > 0) {
          handleTaxAfterCode(response?.data?.data);
        }
      } else {
        setLoader(false);
        setPromoCode("");
        localStorage.removeItem("promoCode");
        message.error(
          response?.errors?.errors?.error || "Please enter valid Promo code",
          10,
        );
      }
    }
  };
  const handleTaxAfterPCRemove = async () => {
    setLoader(true);
    const payload = {
      amount: planPrice,
      zipCode: form?.zipCode,
      country: form?.country,
    };
    const result = await checkTaxCalculationAction(payload);
    if (result?.remote === "success") {
      setTaxAmount(result?.data?.data);
      setIsTax(true);
      setLoader(false);
    } else {
      message.error(result?.errors?.errors?.error, 5);
      setTaxAmount({});
      setLoader(false);
      setIsTax(false);
    }
  };

  const handlePromoCodeRemove = () => {
    setPromoCode("");
    setCodeData({});
    localStorage.setItem("promoCode", "");
    if (form?.zipCode?.length > 0) {
      handleTaxAfterPCRemove();
    }
  };

  return (
    <div className="bg-color py-2 py-lg-5">
      <Row justify="center">
        <Col xs={23} lg={16}>
          <Card bordered={false}>
            {loader && (
              <div className="center-loader">
                <SmallLoader />
              </div>
            )}
            <Form onSubmitCapture={handleSubmit} className="payment-card-main">
              <Row gutter={[32, 32]}>
                <Col xs={24} lg={13}>
                  <h2 className="payment-title">Payment Information</h2>
                  <Row gutter={[24, 24]} className="paymentGateway-first-row">
                    <Col xs={24} lg={16} className="mt-1">
                      <label className="ant-label-login">Name On Card</label>
                      <Input
                        value={form?.name}
                        placeholder="Name"
                        label="Name On Card"
                        type="text"
                        name="name"
                        dclass="ant-icon-space mb-0 payment-input"
                        onChange={handleAllChange}
                      />
                      <div role="alert" className="text-danger">
                        {errors.name}
                      </div>
                    </Col>
                    <Col xs={24} lg={8} className="mt-1">
                      <label className="ant-label-login">Expiration Date</label>
                      <CardExpiryElement
                        className="ant-login-input d-grid payment-input payment-gateway-input"
                        options={{ ...options, placeholder: "MM/YY" }}
                      />
                      <div role="alert" className="text-danger">
                        {(cardError?.code === "incomplete_expiry" ||
                          cardError?.code === "invalid_expiry_year_past" ||
                          cardError?.code === "invalid_expiry_year") &&
                          cardError?.message}
                      </div>
                    </Col>
                    <Col xs={24} lg={16} className="mt-3">
                      <label className="ant-label-login">Card Number</label>
                      <CardNumberElement
                        className="ant-login-input d-grid payment-input payment-gateway-input"
                        options={{
                          ...options,
                          placeholder: "Enter Card Number",
                        }}
                        style={{ fontSize: "18px" }}
                      />

                      <div role="alert" className="text-danger">
                        {(cardError?.code === "incomplete_number" ||
                          cardError?.code === "invalid_number") &&
                          cardError?.message}
                      </div>
                    </Col>
                    <Col xs={24} lg={8} className="mt-3">
                      <label className="ant-label-login">CVV</label>
                      <CardCvcElement
                        className="ant-login-input d-grid payment-input payment-gateway-input"
                        options={{ ...options, placeholder: "CVC" }}
                      />
                      <div role="alert" className="text-danger">
                        {cardError?.code === "incomplete_cvc" &&
                          cardError?.message}
                      </div>
                    </Col>
                    <Col xs={24} className="mt-3">
                      <label className="ant-label-login">Billing Address</label>
                      <Input
                        value={form?.address}
                        placeholder="Address"
                        label="Billing Address"
                        type="text"
                        name="address"
                        dclass="ant-icon-space mb-0 payment-input"
                        onChange={handleAllChange}
                      />
                      <div role="alert" className="text-danger">
                        {errors?.address}
                      </div>
                    </Col>
                    <Col xs={24} lg={12} className="mt-3">
                      <CountryDropdown
                        value={form?.country}
                        handleCountryChange={handleCountryChange}
                      />
                      <div role="alert" className="text-danger">
                        {errors?.country}
                      </div>
                    </Col>
                    <Col xs={24} lg={12} className="mt-3">
                      <label className="ant-label-login">City</label>
                      <Input
                        value={form?.city}
                        placeholder="City"
                        label="City"
                        type="text"
                        name="city"
                        dclass="ant-icon-space mb-0 payment-input"
                        onChange={handleAllChange}
                      />
                      <div role="alert" className="text-danger">
                        {errors?.city}
                      </div>
                    </Col>
                    <Col xs={24} lg={12} className="mt-3">
                      <label className="ant-label-login">State</label>
                      <Input
                        value={form?.state}
                        placeholder="State"
                        label="State / Province"
                        type="text"
                        name="state"
                        dclass="ant-icon-space mb-0 payment-input"
                        onChange={handleAllChange}
                      />
                      <div role="alert" className="text-danger">
                        {errors?.state}
                      </div>
                    </Col>
                    <Col xs={24} lg={12} className="mt-3">
                      <label className="ant-label-login">Zip</label>
                      <Input
                        value={form?.zipCode}
                        placeholder="Zip Code"
                        label="Zip Code"
                        type="text"
                        name="zipCode"
                        dclass="ant-icon-space mb-0 payment-input"
                        onChange={handleAllChange}
                      />
                      <div role="alert" className="text-danger">
                        {errors?.zipCode}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={11} className="paymentGateway-second-row">
                  <div className="creadit-card payment-info-page">
                    <Space size={[6, 10]} direction="vertical">
                      <div className="center-card-image">
                        <img
                          src={card}
                          alt="card"
                          style={{ marginBottom: "10px" }}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between payment-gateway-company">
                        <div>Company </div>
                        <div>{localStorage.getItem("companyName") || "-"}</div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between payment-gateway-order">
                        <div>Order Number</div>
                        <div>
                          {localStorage.getItem("companyNumber")
                            ? `#${localStorage.getItem("companyNumber")}`
                            : "-"}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between payment-gateway-plan">
                        <div> Plan </div>
                        <div>
                          {plans[
                            selectedPlan || localStorage.getItem("planName")
                          ]?.planName || "Estimating Package"}
                        </div>
                      </div>
                      <div className="promoCode-row">
                        <div
                          className="promoCode-input"
                          style={{ width: "67%" }}
                        >
                          <input
                            placeholder="Promo Code"
                            name="promoCode"
                            size="large"
                            style={{
                              borderRadius: "8px",
                              width: "100%",
                              color: "#29994F",
                            }}
                            value={promoCode}
                            onChange={handlePromoCodeChange}
                            className="fw-bolder"
                          />
                          <div role="alert" className="text-danger">
                            {promoCodeError}
                          </div>
                        </div>
                        <div className="promoCode-button">
                          <Button
                            onClick={
                              codeData?.name
                                ? handlePromoCodeRemove
                                : handleApplyButton
                            }
                            {...{
                              ...(codeData?.name && {
                                style: {
                                  background: "#AEAEAE",
                                },
                              }),
                            }}
                          >
                            {codeData?.name ? "Remove" : "Apply"}
                          </Button>
                        </div>
                      </div>
                      {codeData?.name &&
                        codeData?.discountType === "amount" &&
                        codeData?.duration !== "forever" && (
                          <CodeDataWithAmountNotFree
                            codeData={codeData}
                            taxAmount={taxAmount}
                            discountAmount={discountAmount}
                          />
                        )}
                      {codeData?.name &&
                        codeData?.discountType === "percentage" &&
                        codeData?.duration === "forever" && (
                          <CodeDataWithPercentageEntireNotFree
                            codeData={codeData}
                            taxAmount={taxAmount}
                            discountAmount={discountAmount}
                            calculatePercentage={calculatePercentage}
                          />
                        )}
                      {codeData?.name &&
                        codeData?.discountType === "percentage" &&
                        codeData?.duration !== "forever" && (
                          <CodeDataWithPercentageNotFree
                            codeData={codeData}
                            taxAmount={taxAmount}
                            discountAmount={discountAmount}
                            calculatePercentage={calculatePercentage}
                          />
                        )}
                      {codeData?.name &&
                        codeData?.discountType === "freeTrial" && (
                          <>
                            <div className="d-flex align-items-center justify-content-between">
                              <span>{freeTrialPeriod} Free Trial:</span>
                              <div className="pricetotal">
                                <h2>$0.00</h2>
                              </div>{" "}
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <span>Plan Price (After Trial):</span>
                              <div className="pricetotal">
                                <div className="free-trial-remove-height">
                                  <div className="free-trial-price-lineThrough-special">
                                    <p className="mb-1">${planPrice}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="forSpacing">
                              {taxAmount?.amountWithTax !== null && (
                                <div className="d-flex align-items-center justify-content-between">
                                  <span> Estimated Tax: </span>
                                  <div className="estimated-price">
                                    ${taxAmount?.taxAmount?.toFixed(2)}{" "}
                                  </div>
                                </div>
                              )}
                              <div className="d-flex align-items-center justify-content-between">
                                <span> Subscription Price: </span>
                                <h2>
                                  ${taxAmount?.amountWithTax || planPrice}
                                </h2>
                              </div>
                            </div>
                          </>
                        )}
                      {!codeData?.name && (
                        <>
                          {" "}
                          <div className="d-flex align-items-center justify-content-between">
                            <span>Plan Price:</span>
                            <div className="pricetotal">
                              $
                              {
                                plans[
                                  selectedPlan ||
                                    localStorage.getItem("planName")
                                ]?.planPrice
                              }
                            </div>
                          </div>
                          {taxAmount?.amountWithTax !== null && (
                            <div className="d-flex align-items-center justify-content-between">
                              <span> Estimated Tax: </span>
                              <div className="estimated-price">
                                ${taxAmount?.taxAmount?.toFixed(2)}{" "}
                              </div>
                            </div>
                          )}
                          <div className="d-flex align-items-center justify-content-between ">
                            <span> Subscription Price: </span>
                            <h2>
                              $
                              {taxAmount?.amountWithTax ||
                                plans[
                                  selectedPlan ||
                                    localStorage.getItem("planName")
                                ]?.planPrice}
                            </h2>
                          </div>
                        </>
                      )}
                      <div className="d-flex align-items-center justify-content-between ">
                        <a href="mailto:Support@zevbit.com">
                          If you have any questions please feel free to contact
                          ZevBit Support!
                        </a>
                      </div>
                    </Space>
                  </div>
                  <Row gutter={[12, 12]}>
                    <Col xs={12}>
                      <Button
                        style={{ borderRadius: "8px", width: "100%" }}
                        onClick={() => {
                          setForm(initialFormData);
                          setErrors({});
                          setShowPaymentGate(false);
                          setShowReactiveSub(false);
                        }}
                        size="large"
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col xs={12}>
                      <Button
                        className="free-trial-submit-button"
                        size="large"
                        htmlType="submit"
                        type="primary"
                        loading={loader || false}
                        disabled={!isTax}
                      >
                        Pay
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

function PaymentGateway({
  setShowReactiveSub,
  setShowPaymentGate,
  selectedPlan,
}) {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        setShowReactiveSub={setShowReactiveSub}
        setShowPaymentGate={setShowPaymentGate}
        selectedPlan={selectedPlan}
      />
    </Elements>
  );
}

export default PaymentGateway;
