import React from "react";

import { plans } from "../../../utils/constants/constants";

const CodeDataWithPercentageEntireNotFree = ({
  codeData,
  taxAmount,
  discountAmount,
  calculatePercentage,
}) => (
  <>
    <div className="d-flex align-items-center justify-content-between pb-0">
      <span>Plan Price:</span>
      <div className="pricetotal">
        <div className="free-trial-remove-height free-trial-price-lineThrough">
          ${plans[localStorage.getItem("planName")]?.planPrice}{" "}
          <sub style={{ fontSize: "8px" }}>/Mo</sub>
        </div>
      </div>
    </div>
    <div>
      <div className="d-flex align-items-center justify-content-between pb-0">
        <>
          <span> Discount Amount:</span>
          <div className="estimated-price">
            -$
            {calculatePercentage(
              plans[localStorage.getItem("planName")]?.planPrice,
              codeData?.discount,
            )}{" "}
            <sub style={{ fontSize: "8px" }}>/Mo</sub>
          </div>
        </>
      </div>
      {taxAmount?.taxPercentage && (
        <div className="d-flex align-items-center justify-content-between pb-0">
          <span>
            {" "}
            Estimated Tax ({parseInt(taxAmount?.taxPercentage)}
            %):{" "}
          </span>
          <div className="estimated-price">
            ${taxAmount?.taxAmount} <span> /Mo </span>
          </div>
        </div>
      )}

      <div className="d-flex align-items-center justify-content-between pt-2">
        <span> Subscription Price :</span>
        <h2 className="estimated-price">
          $
          {parseFloat(plans[localStorage.getItem("planName")]?.planPrice) -
            discountAmount +
            taxAmount?.taxAmount >
          0
            ? (
                parseFloat(plans[localStorage.getItem("planName")]?.planPrice) -
                discountAmount +
                (taxAmount?.taxAmount || 0)
              )?.toFixed(2)
            : 0.0}{" "}
          <span> /Mo </span>
        </h2>
      </div>
    </div>
    <div className="d-flex align-items-center justify-content-between pt-2">
      <div className="payment-right-para"> {codeData?.description}</div>
    </div>
  </>
);

export default CodeDataWithPercentageEntireNotFree;
