import { Button, Form, Input, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { reportDocument } from "../../../../../api/affiliate";
import { ReactComponent as InfoIcon } from "../../../../../images/warning-icon.svg";
import { setRowDetails } from "../../../../../redux/affiliate/affiliate.action";
import { AFFILIATE_TYPES } from "../../../../../redux/affiliate/affiliate.types";

const { Title } = Typography;
const { TextArea } = Input;

const getUpdatedData = (type, data) => {
  const selectKeys = {
    idCard: {
      idCardReportedIssue: data?.note,
      idCardReportedIssueDate: moment().utc(),
    },
    w9Form: {
      w9FormUrlReportedIssue: data?.note,
      w9FormUrlReportedIssueDate: moment().utc(),
    },
  };
  return selectKeys[type];
};

const ReportProblem = ({ form, type, handleCancel, callReportList }) => {
  const dispatch = useDispatch();
  const affiliateData = useSelector((state) => state?.affiliate?.profile);
  const rowData = useSelector((state) => state?.affiliate?.rowDetails?.row);
  const docData = useSelector((state) => state?.affiliate?.docNPay);
  const [loading, setLoading] = useState(false);
  const handleOk = async (data) => {
    const payload = {
      affiliateId: affiliateData?._id,
      documentType: type,
      ...data,
    };
    const response = await reportDocument(payload, setLoading);
    if (response.remote === "success") {
      dispatch(
        setRowDetails({
          hasRowDetails: true,
          row: { ...rowData, status: "Documentation Issue" },
        }),
      );
      callReportList(type);
      dispatch({
        type: AFFILIATE_TYPES.DOCUMENT_DETAIL,
        payload: { ...docData, ...getUpdatedData(type, data) },
      });
      setLoading(false);
      handleCancel();
      form?.resetFields();
    } else {
      setLoading(false);
      toast.error(response.error?.errors?.errors);
    }
  };
  return (
    <>
      <div className="text-center mb-3 warning-shadow mx-auto">
        <InfoIcon width={20} height={20} color="#ff0000" />
      </div>
      <Title level={4} className="text-center" style={{ fontWeight: "normal" }}>
        Report Problem
      </Title>
      <p className="text-center">
        Are you sure you want to report a problem? The affiliate will be
        requested to upload a new document.
      </p>
      <Form
        form={form}
        layout="vertical"
        onFinish={(data) => handleOk(data)}
        autoComplete="off"
      >
        <Form.Item
          label="Notes To Affiliate"
          name="note"
          style={{ fontWeight: 500 }}
          rules={[
            { required: true, message: "Please enter an email!" },
            () => ({
              validator(_, value) {
                if (value?.length >= 120) {
                  return Promise.reject(
                    new Error(
                      "Note limit should not be more than 120 characters",
                    ),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
          requiredMark="optional"
        >
          <TextArea
            rows={5}
            draggable={false}
            style={{ resize: "none", borderRadius: 8 }}
            placeholder="Add Note"
            maxLength={120}
          />
        </Form.Item>
        <Form.Item className="forFlex-buttons">
          <Button className="w-50" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="w-50"
            type="danger"
            htmlType="submit"
            disabled={loading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ReportProblem;
