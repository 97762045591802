import { Card, Col, Row } from "antd";
import React from "react";

import StartFreeTrial from "./common/StartFreeTrial";

const FreeTrialSection = ({ promoCode }) => (
  <Card
    style={{
      top: 40,
      zIndex: 9,
      width: "80%",
      margin: "0 auto",
      backgroundImage: "linear-gradient(134deg,#2375f6,#71f69d)",
      borderRadius: 16,
      border: "none",
    }}
    className="freetrilbox mb-3"
  >
    <Row gutter={[24, 24]}>
      <Col style={{ color: "#B2DDFF" }} xs={24} lg={20}>
        Join the community of Landscape & Hardscape Contractors already using
        ZevBit to accurately and efficiently run their business.
      </Col>
      <Col xs={24} lg={4}>
        <StartFreeTrial className="w-100 btn-affilite" promoCode={promoCode}>
          Start Free Trial
        </StartFreeTrial>
      </Col>
    </Row>
  </Card>
);

export default FreeTrialSection;
