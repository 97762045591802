import { Button, Col, Form, Input, Row } from "antd";
import { useState } from "react";

import { addNewAffiliateAction } from "../../../../api/admin";
import { addAffiliateSchema } from "../../../../validators/auth/auth.validator";
import SmallLoader from "../../../loader/smallLoader";

function AddAffiliate({
  handleCancel,
  form,
  setForm,
  sourceError,
  setSourceError,
  setAddAffiliateModel,
  getAffiliateList,
}) {
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const [loader, setLoader] = useState(false);
  const handleSubmit = async (e) => {
    e && e?.preventDefault();
    setSourceError({});
    const { isValid, errors: formError } = addAffiliateSchema(form);
    if (!isValid) {
      setSourceError(formError);
    } else {
      setLoader(true);
      const payload = {
        name: form?.name,
      };
      const result = await addNewAffiliateAction(payload);
      if (result?.remote === "success") {
        getAffiliateList();
        setLoader(false);
        setAddAffiliateModel(false);
      } else {
        setLoader(false);
      }
    }
  };

  return (
    <>
      {loader && (
        <div className="center-loader">
          <SmallLoader />
        </div>
      )}
      <div className="add-promo-code">
        <h3 className="fliter-sort-title-add-tab mb-4">Add Affiliate</h3>
        <Form layout="vertical" onSubmitCapture={handleSubmit}>
          <Row gutter={[24, 0]}>
            <Col xs={24}>
              <Form.Item>
                <Input
                  size="large"
                  placeholder="Affiliate Name"
                  name="name"
                  value={form?.name}
                  onChange={handleChange}
                  style={{ borderRadius: "8px", height: "auto" }}
                />
                <div role="alert" className="text-danger">
                  {sourceError?.name}
                </div>
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Button
                onClick={handleCancel}
                style={{ width: "100%", borderRadius: "8px" }}
                size="large"
              >
                Cancel
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                style={{ width: "100%", borderRadius: "8px" }}
                size="large"
                htmlType="submit"
                type="primary"
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
export default AddAffiliate;
