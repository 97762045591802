import { Button } from "antd";
import React from "react";

const InvoicingIntro = ({
  title,
  handleOpenPlan,
  handleCancel,
  needCancel = true,
}) => {
  const videoLink = process.env.REACT_APP_INVOICE_INTRO_LINK;
  // const videoLink =
  //   "https://www.youtube.com/embed/wug2HB0rjno?si=KJyqX6Cw7tUn2POP";
  return (
    <>
      <h2 className="change-title mb-3">{title}</h2>
      <div className="video">
        <iframe
          className="d-block"
          width="100%"
          height="500"
          src={videoLink}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <div className="d-flex mt-3 gap-3 justify-content-center">
        {needCancel && (
          <Button className="clear-filter w-215" onClick={() => handleCancel()}>
            Cancel
          </Button>
        )}
        <Button
          type="primary"
          className={`${needCancel ? "w-215" : "w-50"}`}
          size="large"
          onClick={() => handleOpenPlan()}
        >
          View Plan Details
        </Button>
      </div>
    </>
  );
};

export default InvoicingIntro;
