import { Form, Input } from "antd";
import React from "react";

export const FormInput = React.forwardRef(
  ({ name, label, placeholder, size, rules, value, onChange, ...rest }) => (
    <Form.Item label={label} name={name} rules={rules}>
      <Input
        size={size}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
        {...rest}
      />
    </Form.Item>
  ),
);
