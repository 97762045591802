import AddIcon from "@mui/icons-material/Add";
import { Button, Paper, Stack, TableContainer } from "@mui/material";
import React from "react";

import CustomButton from "../../../../globalComponents/button";
import {
  ATTACHMENT_COLUMNS_DATA,
  ATTACHMENT_ROW_DATA,
} from "../estimates-cost/table/Data";
import TableView from "../estimates-cost/table/table.view";
import EditLinkModal from "./modal/AddLinkModal";
import UploadFileModal from "./modal/UploadFileModal";
const AttachmentsFile = () => {
  const [open, setOpen] = React.useState(false);
  const [uploadopen, setUploadopen] = React.useState(false);
  const handleUploadFile = () => {
    setUploadopen(true);
  };

  const handleEditModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUploadopen(false);
  };
  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        className="mb-3"
        justifyContent="space-between"
        alignItems="center"
      >
        <div className="formulla-title">Attachments</div>
        <Stack direction="row" spacing={2} className="btn-estimates">
          <Button variant="outlined" onClick={handleEditModal}>
            <span className="me-2">
              <AddIcon />
            </span>{" "}
            <span>Add link</span>
          </Button>
          <CustomButton className="px-3" onClick={handleUploadFile}>
            <span className="me-2">
              <AddIcon />
            </span>
            <span>Add file</span>
          </CustomButton>
        </Stack>
      </Stack>
      <TableContainer component={Paper} className="attachment-table">
        <TableView
          columns={ATTACHMENT_COLUMNS_DATA}
          rows={ATTACHMENT_ROW_DATA}
        />
      </TableContainer>

      <EditLinkModal
        open={open}
        handleClose={handleClose}
        title="Upload link"
      />

      <UploadFileModal
        uploadopen={uploadopen}
        handleClose={handleClose}
        title="Upload File"
      />
    </>
  );
};
export default AttachmentsFile;
