import { CloseOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Input, Row, Typography } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as ClockIcon } from "../../../../../images/affiliateImages/clock-icon.svg";
import { ReactComponent as Unlink } from "../../../../../images/affiliateImages/unlink.svg";
import { ReactComponent as WarningTriangleIcon } from "../../../../../images/affiliateImages/warning-triangle-icon.svg";
import { DATE_FORMATE } from "../../../../../utils/constants/constants";
import AlertBanner from "../../../common/AlertBanner";
import { paymentMethodLogo } from "../../../util";
import { selectDocumentationMessage } from "../modals/utils";

const { Title } = Typography;

const AfterUpload = ({
  documentData,
  isSuperAdmin = false,
  reportList = {},
  download,
  setFormModal,
  handleDeleteId,
  setReportToggle,
  setPastReports,
  rejected,
}) => (
  <>
    <Title level={4} className="documentAndpayment-heading">
      Documentation
    </Title>
    {!isSuperAdmin && (
      <AlertBanner
        className="mt-4 mb-4"
        {...{
          ...(documentData?.status !== "DocumentsIssue"
            ? {
                ...selectDocumentationMessage?.[
                  documentData?.status?.toLowerCase()
                ],
              }
            : {
                message: (
                  <div className="mx-1">
                    <p
                      className="m-0"
                      style={{
                        fontSize: 14,
                        color: "#B54708",
                        fontWeight: 500,
                      }}
                    >
                      There Was A Problem With{" "}
                      {documentData?.idCardReportedIssue &&
                      !documentData?.w9FormUrlReportedIssue
                        ? "Your ID"
                        : ""}
                      {!documentData?.idCardReportedIssue &&
                      documentData?.w9FormUrlReportedIssue
                        ? "Your FW9 Form"
                        : ""}
                      {documentData?.idCardReportedIssue &&
                      documentData?.w9FormUrlReportedIssue
                        ? "Your ID And Your FW9 Form"
                        : ""}
                    </p>
                    <p
                      className="m-0"
                      style={{ fontSize: 14, color: "#DC6803" }}
                    >
                      Notes from ZevBit team:{" "}
                      {documentData?.idCardReportedIssue && (
                        <span>
                          <b>ID Card:</b> {documentData?.idCardReportedIssue}
                        </span>
                      )}
                      {documentData?.idCardReportedIssue &&
                        documentData?.w9FormUrlReportedIssue &&
                        ", "}
                      {documentData?.w9FormUrlReportedIssue && (
                        <span>
                          <b>FW9 Form:</b>{" "}
                          {documentData?.w9FormUrlReportedIssue}
                        </span>
                      )}
                      . If you have further questions please call ZevBit at{" "}
                      <b>(888) 703-4070</b> or email{" "}
                      <Link
                        to="mailto:info@zevbit.com"
                        style={{
                          color: "#DC6803",
                        }}
                      >
                        <b style={{ textDecoration: "underline" }}>
                          info@zevbit.com
                        </b>
                      </Link>
                      .
                    </p>
                  </div>
                ),
                type: "warning",
                icon: (
                  <WarningOutlined style={{ fontSize: 20, color: "#DC6803" }} />
                ),
                showIcon: true,
                style: { borderRadius: 8 },
              }),
        }}
      />
    )}
    <Row
      {...{
        ...((documentData?.idCardReportedIssue ||
          !documentData?.idCardImageName) && {
          style: { marginBottom: 42 },
        }),
      }}
    >
      <Col xs={24} lg={6}>
        Id Card
      </Col>
      <Col xs={24} lg={18}>
        <Row className="form-group" gutter={[24, 24]}>
          <Col xs={24} lg={9}>
            <Input
              value={
                documentData?.idCardReportedIssue && !isSuperAdmin
                  ? ""
                  : documentData?.idCardImageName || ""
              }
              style={{
                height: 44,
                borderRadius: 8,
                borderColor: "#f0f0f0",
              }}
              disabled={
                isSuperAdmin ||
                (!documentData?.idCardReportedIssue &&
                  documentData?.idCardImageName)
              }
              {...{
                ...(!documentData?.idCardReportedIssue &&
                  documentData?.idCardImageName &&
                  !isSuperAdmin && {
                    suffix: (
                      <CloseOutlined
                        onClick={() => {
                          handleDeleteId("idCard");
                        }}
                        style={{ color: "#E42626" }}
                      />
                    ),
                  }),
              }}
            />
          </Col>
          <Col>
            <Button
              onClick={() =>
                !isSuperAdmin
                  ? !documentData?.idCardReportedIssue &&
                    documentData?.idCardImageName &&
                    documentData?.idCardImageUrl
                    ? download(
                        documentData?.idCardImageUrl,
                        documentData?.idCardImageName,
                      )
                    : setFormModal((prev) => ({ ...prev, idCardModal: true }))
                  : download(
                      documentData?.idCardImageUrl,
                      documentData?.idCardImageName,
                    )
              }
              type="primary"
              style={{ width: 102 }}
              disabled={
                rejected || (!documentData?.idCardImageName && isSuperAdmin)
              }
            >
              {!isSuperAdmin
                ? !documentData?.idCardImageName ||
                  documentData?.idCardReportedIssue
                  ? "Upload"
                  : "Download"
                : "Download"}
            </Button>
          </Col>
          {isSuperAdmin && (
            <>
              <Col>
                <Button
                  icon={
                    <WarningTriangleIcon
                      style={{
                        margin: "0px 5px",
                        verticalAlign: "middle",
                      }}
                    />
                  }
                  style={{ height: 44, borderRadius: 8 }}
                  onClick={() =>
                    setReportToggle({ toggle: true, type: "idCard" })
                  }
                  disabled={rejected || !documentData?.idCardImageName}
                >
                  Report Problem
                </Button>
              </Col>
              {!!reportList?.idCard?.length && (
                <Col>
                  <Button
                    icon={
                      <ClockIcon
                        style={{
                          margin: "0px 5px",
                          verticalAlign: "middle",
                        }}
                      />
                    }
                    style={{ height: 44, borderRadius: 8 }}
                    onClick={() =>
                      setPastReports({ toggle: true, type: "idCard" })
                    }
                    disabled={rejected || !documentData?.idCardImageName}
                  >
                    Past Reports
                  </Button>
                </Col>
              )}
            </>
          )}
        </Row>
        {!documentData?.idCardReportedIssue &&
          documentData?.idCardImageName &&
          documentData?.idCardUploadedAt && (
            <p
              style={{
                color: "#667085",
                fontSize: 14,
                marginTop: 5,
              }}
            >
              Uploaded{" "}
              {moment(documentData?.idCardUploadedAt).format(DATE_FORMATE)}
            </p>
          )}
      </Col>
    </Row>
    <Row>
      <Col xs={24} lg={6}>
        FW9 Form
      </Col>
      <Col lg={18} xs={24} style={{ paddingBottom: "40px" }}>
        <Row className="form-group" gutter={[24, 24]}>
          <Col xs={24} lg={9}>
            <Input
              value={
                !isSuperAdmin
                  ? documentData?.w9FormUrlReportedIssue
                    ? ""
                    : documentData?.w9FormName || ""
                  : documentData?.w9FormName
              }
              style={{
                height: 44,
                borderRadius: 8,
                borderColor: "#f0f0f0",

                marginBottom:
                  documentData?.w9FormUploadedAt || !isSuperAdmin ? 0 : 42,
              }}
              {...{
                ...(!documentData?.w9FormUrlReportedIssue &&
                  documentData?.w9FormName &&
                  !isSuperAdmin && {
                    suffix: (
                      <CloseOutlined
                        onClick={() => {
                          handleDeleteId("w9Form");
                        }}
                        style={{ color: "#E42626" }}
                      />
                    ),
                  }),
              }}
              disabled={
                isSuperAdmin ||
                (!documentData?.w9FormUrlReportedIssue &&
                  documentData?.w9FormName)
              }
            />
          </Col>
          <Col>
            <Button
              onClick={() =>
                !isSuperAdmin
                  ? !documentData?.w9FormUrlReportedIssue &&
                    documentData?.w9FormUrl &&
                    documentData?.w9FormName
                    ? download(
                        documentData?.w9FormUrl,
                        documentData?.w9FormName,
                      )
                    : setFormModal((prev) => ({ ...prev, w9FormModal: true }))
                  : download(documentData?.w9FormUrl, documentData?.w9FormName)
              }
              type="primary"
              style={{ width: 102 }}
              disabled={rejected || (!documentData?.w9FormName && isSuperAdmin)}
            >
              {!isSuperAdmin
                ? !documentData?.w9FormName ||
                  documentData?.w9FormUrlReportedIssue
                  ? "Upload"
                  : "Download"
                : "Download"}
            </Button>
          </Col>
          {isSuperAdmin && (
            <>
              <Col style={{ paddingLeft: 12 }}>
                <Button
                  icon={
                    <WarningTriangleIcon
                      style={{
                        margin: "0px 5px",
                        verticalAlign: "middle",
                      }}
                    />
                  }
                  style={{ height: 44, borderRadius: 8 }}
                  onClick={() =>
                    setReportToggle({ toggle: true, type: "w9Form" })
                  }
                  disabled={rejected || !documentData?.w9FormName}
                >
                  Report Problem
                </Button>
              </Col>
              {!!reportList?.w9Form?.length && (
                <Col>
                  <Button
                    icon={
                      <ClockIcon
                        style={{
                          margin: "0px 5px",
                          verticalAlign: "middle",
                        }}
                      />
                    }
                    style={{ height: 44, borderRadius: 8 }}
                    onClick={() =>
                      setPastReports({ toggle: true, type: "w9Form" })
                    }
                    disabled={rejected || !documentData?.w9FormName}
                  >
                    Past Reports
                  </Button>
                </Col>
              )}
            </>
          )}
        </Row>
        {!documentData?.w9FormUrlReportedIssue &&
          documentData?.w9FormName &&
          documentData?.w9FormUploadedAt && (
            <p
              style={{
                color: "#667085",
                fontSize: 14,
                marginTop: 5,
              }}
            >
              Uploaded{" "}
              {moment(documentData?.w9FormUploadedAt).format(DATE_FORMATE)}
            </p>
          )}
      </Col>
    </Row>
    <div>
      <Title level={4} className="">
        Default Payment Method
      </Title>
    </div>
    <Divider style={{ marginTop: "15px" }} />
    <Row align="middle">
      <Col lg={6} xs={24}>
        <div className="mx-3">
          <img
            src={paymentMethodLogo[documentData?.paymentMethodType]}
            alt="payment-logo"
          />
          <p className="mt-2 mb-0" style={{ color: "#667085" }}>
            Linked on{" "}
            {moment(documentData?.paymentMethodAddedAt).format(DATE_FORMATE)}
          </p>
        </div>
      </Col>
      <Col lg={18} xs={24}>
        <Row className="form-group" gutter={[24, 24]}>
          <Col lg={9} xs={24}>
            {isSuperAdmin ||
            (documentData?.status !== "Registered" &&
              documentData?.status !== "Requested") ? (
              <Input
                value={
                  documentData?.paymentMethodType === "venmo"
                    ? documentData?.venmo?.userName
                    : documentData[documentData?.paymentMethodType]?.email
                }
                style={{
                  height: 44,
                  borderRadius: 8,
                  borderColor: "#f0f0f0",
                }}
                disabled={
                  isSuperAdmin ||
                  documentData?.status === "Approved" ||
                  documentData?.status === "DocumentsIssue"
                }
              />
            ) : (
              <span>
                {documentData?.paymentMethodType === "venmo"
                  ? documentData?.venmo?.userName
                  : documentData[documentData?.paymentMethodType]?.email}
              </span>
            )}
          </Col>

          {!isSuperAdmin &&
            (documentData?.status?.toLowerCase() === "approved" ||
              documentData?.status?.toLowerCase() === "documentsissue") && (
              <Col>
                <Button
                  icon={<Unlink style={{ marginRight: 5 }} />}
                  onClick={() =>
                    setFormModal((prev) => ({ ...prev, paymentModal: true }))
                  }
                  style={{ height: 44, borderRadius: 8, fontSize: 16 }}
                >
                  Change Account
                </Button>
              </Col>
            )}
        </Row>
      </Col>
    </Row>
  </>
);

export default AfterUpload;
