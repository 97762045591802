import { readFile } from "../utils/readFile";
import { getRole } from "../utils/role";
import api from "./api";

export async function deleteCatalog(id) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/delete-catalog/${id}`;
    }
    return `/v2/catalog/organization/delete-catalog/${id}`;
  };
  const response = await api.request({
    url: url(),
    method: "DELETE",
  });
  return response;
}

export async function deleteAllCatalog(ids) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/catalog/delete-all-catalog";
    }
    return "/v2/catalog/organization/delete-all-catalog";
  };
  const response = await api.request({
    url: url(),
    method: "POST",
    data: { catalogIds: ids },
  });
  return response;
}

export async function deleteService(id) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/delete-services/${id}`;
    }
    return `/v2/catalog/organization/delete-services/${id}`;
  };
  const response = await api.request({
    url: url(),
    method: "DELETE",
  });
  return response;
}

export async function getCatalogItemById(id) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/get-all-catalog?catalogId=${id}`;
    }
    return `/v2/catalog/organization/get-all-catalog?catalogId=${id}`;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
}

export async function getCatalogDetailsBySuperId(id) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/get-catalog-details-by-id/${id}`;
    }
    return `/v2/catalog/organization/get-catalog-details-by-id/${id}`;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
}
// new are after 2023-08-16
export async function getCatalogItem(skip, id, rest, search = "") {
  const url = () => {
    const role = getRole();
    let url = "";
    if (role === "superAdmin") {
      url = `/v2/catalog/get-all-catalog?skip=${skip}&catalogId=${id}&search=${search}&subType=${rest?.subType}`;
    } else {
      url = `/v2/catalog/organization/get-all-catalog?skip=${skip}&catalogId=${id}&search=${search}&subType=${rest?.subType}`;
    }
    if (rest?.limit) {
      url += `&limit=${rest?.limit}`;
    }
    return url;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
}
// old are before 2023-08-16
export async function getOldCatalogItem(skip, id, rest, search = "") {
  const url = () => {
    const role = getRole();
    let url = "";
    if (role === "superAdmin") {
      url = `/v2/catalog/get-all-old-catalog?skip=${skip}&catalogId=${id}&search=${search}&subType=${rest?.subType}`;
    } else {
      url = `/v2/catalog/organization/get-all-catalog?skip=${skip}&catalogId=${id}&search=${search}&subType=${rest?.subType}`;
    }
    if (rest?.limit) {
      url += `&limit=${rest?.limit}`;
    }
    return url;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
}

export async function getVariationBySuperCatalogId(
  superCatelogId,
  limit,
  pageNo,
) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/get-all-variations-by-super-catalog-id?superCatelogId=${superCatelogId}&limit=${limit}&pageNo=${pageNo}`;
    }
    return `/v2/catalog/organization/get-all-variations-by-super-catalog-id?superCatelogId=${superCatelogId}&limit=${limit}&pageNo=${pageNo}`;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
}

export async function getVariationsByCatalogId(id) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/get-all-variation-by-catalog?catalogId=${id}`;
    }
    return `/v2/catalog/organization/get-all-variation-by-catalog?catalogId=${id}`;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
}

export async function getServices(skip, search = "", rest) {
  const url = () => {
    const role = getRole();
    let url = "";
    if (role === "superAdmin") {
      url = `/v2/catalog/get-all-services?skip=${skip}&search=${search}&subType=${rest?.subType}`;
    } else {
      url = `/v2/catalog/organization/get-all-services?skip=${skip}&search=${search}&subType=${rest?.subType}`;
    }
    if (rest?.limit) {
      url += `&limit=${rest?.limit}`;
    }
    return url;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
}
export async function getOldServices(skip, rest, search = "") {
  const url = () => {
    const role = getRole();
    let url = "";
    if (role === "superAdmin") {
      url = `/v2/catalog/get-all-old-services?skip=${skip}&search=${search}&subType=${rest?.subType}`;
    } else {
      url = `/v2/catalog/organization/get-all-services?skip=${skip}&search=${search}&subType=${rest?.subType}`;
    }
    if (rest?.limit) {
      url += `&limit=${rest?.limit}`;
    }
    return url;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
}

export async function createService(data) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/catalog/create-service";
    }
    return "/v2/catalog/organization/create-service";
  };
  const response = await api.request({
    url: url(),
    method: "POST",
    data,
  });
  return response;
}

export async function updateService(serviceId, data) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/update-service/${serviceId}`;
    }
    return `/v2/catalog/organization/update-service/${serviceId}`;
  };
  const response = await api.request({
    url: url(),
    method: "PUT",
    data,
  });
  return response;
}

export async function removeServices(data) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/catalog/remove-services";
    }
    return "/v2/catalog/organization/remove-services";
  };
  const response = await api.request({
    url: url(),
    method: "post",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function createCatalogItem(data) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/catalog/create-catalog";
    }
    return "/v2/catalog/organization/create-catalog";
  };
  const response = await api.request({
    url: url(),
    method: "POST",
    data,
  });
  return response;
}

export async function createVariation(data) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/catalog/create-variation";
    }
    return "/v2/catalog/organization/create-variation";
  };
  const response = await api.request({
    url: url(),
    method: "POST",
    data,
  });
  return response;
}

export async function searchCatalogByName(name, type) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/search-catalog-by-name?searchFor=${type}&catalogName=${name}`;
    }
    return `/v2/catalog/organization/search-catalog-by-name?searchFor=${type}&catalogName=${name}`;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
}
export async function searchOldCatalogByName(name, type) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/search-old-catalog-by-name?searchFor=${type}&catalogName=${name}`;
    }
    return `/v2/catalog/organization/search-catalog-by-name?searchFor=${type}&catalogName=${name}`;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
}

export async function updateCatalog(data) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/update-catalog/${data._id}`;
    }
    return `/v2/catalog/organization/update-catalog/${data._id}`;
  };
  const response = await api.request({
    url: url(),
    method: "PUT",
    data,
  });
  return response;
}

export async function removeVariation(id) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/delete-variation/${id}`;
    }
    return `/v2/catalog/organization/delete-variation/${id}`;
  };
  const response = await api.request({
    url: url(),
    method: "DELETE",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function deleteVariations(data) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/catalog/delete-variations";
    }
    return "/v2/catalog/organization/delete-variations";
  };
  const response = await api.request({
    url: url(),
    method: "DELETE",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function updateVariation(data) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/update-variation/${data._id}`;
    }
    return `/v2/catalog/organization/update-variation/${data._id}`;
  };
  const response = await api.request({
    url: url(),
    method: "PUT",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getVariationItem(data) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/catalog/get-variation";
    }
    return "/v2/catalog/organization/get-variation";
  };
  const response = await api.request({
    url: url(),
    method: "POST",
    data,
  });
  return response;
}

export async function getBuiltInDesignCost() {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/catalog/get-built-in-data";
    }
    return "/v2/catalog/organization/get-built-in-data";
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
}

export async function createSubContractor(data) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/catalog/create-sub-contractor";
    }
    return "/v2/catalog/organization/create-sub-contractor";
  };
  const response = await api.request({
    url: url(),
    method: "POST",
    data,
  });
  return response;
}

export async function updateSubContractor(subContractorId, data) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/update-sub-contractor/${subContractorId}`;
    }
    return `/v2/catalog/organization/update-sub-contractor/${subContractorId}`;
  };
  const response = await api.request({
    url: url(),
    method: "PUT",
    data,
  });
  return response;
}

export async function deleteSubContractor(id) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/delete-sub-contractor/${id}`;
    }
    return `/v2/catalog/organization/delete-sub-contractor/${id}`;
  };
  const response = await api.request({
    url: url(),
    method: "DELETE",
  });
  return response;
}

export async function getSubContractors(skip, rest, search = "") {
  const url = () => {
    const role = getRole();
    let url = "";
    if (role === "superAdmin") {
      url = `/v2/catalog/get-sub-contractors?skip=${skip}&search=${search}&subType=${rest?.subType}`;
    } else {
      url = `/v2/catalog/organization/get-sub-contractors?skip=${skip}&search=${search}&subType=${rest?.subType}`;
    }
    if (rest?.limit) {
      url += `&limit=${rest.limit}`;
    }
    return url;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
}
export async function getOldSubContractors(skip, search = "", rest) {
  const url = () => {
    const role = getRole();
    let url = "";
    if (role === "superAdmin") {
      url = `/v2/catalog/get-old-sub-contractors?skip=${skip}&search=${search}&subType=${rest?.subType}`;
    } else {
      url = `/v2/catalog/organization/get-sub-contractors?skip=${skip}&search=${search}&subType=${rest?.subType}`;
    }
    if (rest?.limit) {
      url += `&limit=${rest.limit}`;
    }
    return url;
  };
  const response = await api.request({
    url: url(),
    method: "GET",
  });
  return response;
}

export async function uploadVariationByCsv(values, data) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/catalog/upload-variation-csv";
    }
    return "/v2/catalog/organization/upload-variation-csv";
  };
  const result = await readFile(values.file);
  const response = await api.request({
    url: url(),
    method: "POST",
    data: { csvData: result, catalog: values.catalog, ...data },
  });

  return response;
}
export async function importProductionRatesAPI(data) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/catalog/import-production-rates";
    }
    return "/v2/catalog/organization/import-production-rates";
  };
  const response = await api.request({
    url: url(),
    method: "POST",
    data,
  });

  return response;
}
export async function deleteInBukCatalog(catalogIds) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/catalog/delete-catalog-in-bulk?catalogIds=${catalogIds}`;
    }
    return `/v2/catalog/organization/delete-catalog-in-bulk?catalogIds=${catalogIds}`;
  };
  return api.request({
    url: url(),
    method: "delete",
  });
}

export async function updateCatalogViaCSV(data) {
  return api.request({
    url: "/v3/catalogs/update-materials-via-csv",
    method: "put",
    data,
  });
}
