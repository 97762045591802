import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import AffiliateLanding from "../components/affiliateLanding";
import routes from "./routes";

function PrivateRoute({ children }) {
  const auth = localStorage.getItem("token") || sessionStorage.getItem("token");
  const location = useLocation();

  const removeSlashFn = (path) =>
    path[0] === "/" ? path.slice(1)?.toLowerCase() : path?.toLowerCase();

  const isIncludedInRoute = (route, location) => {
    const modUrl = route.slice(0, route.indexOf("/:") + 1) || route;
    return location?.includes(modUrl);
  };

  const notCode = routes?.some((obj) =>
    isIncludedInRoute(
      removeSlashFn(obj?.path),
      removeSlashFn(location?.pathname),
    ),
  );
  if (!notCode) {
    return <AffiliateLanding />;
  }
  if (!auth) {
    return <Navigate to="/auth" state={{ from: location }} />;
  }

  return children;
}

export default PrivateRoute;
