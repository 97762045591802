import { message, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import React from "react";

import { allImageFilesExtension } from "../../../../../utils/constants/constants";
import { uploadAttachment } from "../../../../../utils/svg.file";
const { Dragger } = Upload;
const UploadFileView = () => {
  const props = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onDrop() {},
    customRequest: async ({ file }) => {
      if (!allImageFilesExtension.includes(file?.type))
        message.error("Invalid file selected");
    },
  };
  return (
    <>
      <ImgCrop>
        <Dragger {...props} status={false}>
          <p className="ant-upload-drag-icon mb-0">{uploadAttachment}</p>
          <p className="ant-upload-text upload-attachment-text m-0">
            <b>Click to upload</b> <span>or drag and drop</span>
          </p>
          <p className="ant-upload-hint upload-attachment-text m-0">
            <small>SVG, PNG, JPG or GIF (max. 800x400px)</small>
          </p>
        </Dragger>
      </ImgCrop>
    </>
  );
};
export default UploadFileView;
