import React from "react";

import { plans } from "../../../utils/constants/constants";

const CodeDataWithPercentageNotFree = ({
  codeData,
  taxAmount,
  discountAmount,
  calculatePercentage,
}) => {
  const prefixText = `First 
  ${codeData?.duration_in_months > 1 ? codeData?.duration_in_months : ""}
  ${codeData?.duration_in_months === 1 ? "Month" : "Months"}`;

  return (
    <>
      <div className="d-flex align-items-center justify-content-between pb-0">
        <span>Plan Price:</span>
        <div className="pricetotal">
          <div className="free-trial-remove-height free-trial-price-lineThrough">
            ${plans[localStorage.getItem("planName")]?.planPrice}{" "}
            {codeData?.duration_in_months > 1 && (
              <sub style={{ fontSize: "8px" }}>/Mo</sub>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex align-items-center justify-content-between pb-0">
          <>
            <span> {prefixText} Discount: </span>
            <div className="estimated-price">
              -$
              {calculatePercentage(
                plans[localStorage.getItem("planName")]?.planPrice,
                codeData?.discount,
              )}{" "}
              {codeData?.duration_in_months > 1 && (
                <sub style={{ fontSize: "8px" }}>/Mo</sub>
              )}
            </div>
          </>
        </div>
        {taxAmount?.taxPercentage && (
          <div className="d-flex align-items-center justify-content-between pb-0">
            <span> {prefixText} Estimated Tax : </span>
            <div className="estimated-price">
              ${(taxAmount?.taxAmount).toFixed(2)}{" "}
              {codeData?.duration_in_months > 1 && <span> /Mo </span>}
            </div>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between pt-2">
          <span> Due Today ({prefixText}):</span>
          <div className="estimated-price">
            {parseFloat(plans[localStorage.getItem("planName")]?.planPrice) -
              (discountAmount || 0) +
              (taxAmount?.taxAmount || 0) >
            0 ? (
              <h2>
                $
                {(
                  parseFloat(
                    plans[localStorage.getItem("planName")]?.planPrice,
                  ) -
                  discountAmount +
                  (taxAmount?.taxAmount || 0)
                )?.toFixed(2)}
              </h2>
            ) : (
              <h2>0.0</h2>
            )}{" "}
            {codeData?.duration_in_months > 1 && <span> /Mo </span>}
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between pt-2">
          <span>
            {`Subscription Price (After ${codeData?.duration_in_months} Mo):`}{" "}
          </span>
          <div>
            ${plans[localStorage.getItem("planName")]?.planPrice}{" "}
            <sub style={{ fontSize: "8px" }}>/Mo + Tax</sub>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between pt-2">
        <div className="payment-right-para"> {codeData?.description}</div>
      </div>
    </>
  );
};

export default CodeDataWithPercentageNotFree;
