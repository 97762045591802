import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Row,
  Select,
  Space,
  Switch,
} from "antd";

import { DATE_FORMATE } from "../../../../../utils/constants/constants";
import {
  DeleteIcon,
  dragArrow,
  dragbar,
  edit,
  eye,
} from "../../../../../utils/svg.file";

export const QUESTION_TYPE = {
  default: "default",
  standard: "standard",
  all: "all",
};

export const viewQuestions = (question) => {
  switch (question.questionInputType) {
    case "checkbox":
      return "checkbox";
    case "select":
      return "select";
    case "toggle_button":
      return "toggle_button";
    case "radio":
      return (
        <List.Item
          key={question._id}
          className={`${question?.showQuestion ? "show" : ""}`}
        >
          <span>{dragArrow}</span>
          <div
            className="panel-box"
            //  onClick={handleQuestions}
          >
            <div className="panel-title">
              <h6>{question?.questionLabel} </h6>
            </div>
            <div className="panel-action">
              <span
                className="view"
                // onClick={() => viewSingleQuestion(getQuestionsList._id, index)}
              >
                {eye}
              </span>
              <span
                className="edit"
                // onClick={() => handleEdit(getQuestionsList)}
              >
                {edit}
              </span>
              {/* {deleteLoader && deleteIndex === index ? (
                <Space size="middle">
                  <Spin size="large" />
                </Space>
              ) : ( */}
              <span
                className="delete"
                // onClick={() =>
                //   deleteSingleQuestion(getQuestionsList._id, index)
                // }
              >
                {DeleteIcon}
              </span>
              {/* )} */}
              {/* <span
                  className="delete"
                  onClick={() => deleteSingleQuestion(getQuestionsList._id)}
                >
                  {DeleteIcon}
                </span> */}
            </div>
          </div>
          <div className="selected-questions">
            <Form layout="vertical" className="px-2">
              <Form.Item label="Answer Type">
                <Select
                  // defaultValue={changeFormate(
                  //   getQuestionsList?.questionInputType
                  // )}
                  // value={
                  //   changeFormate(getQuestionsList?.questionInputType) || ""
                  // }
                  className="d-block radius-30"
                  // disabled={loader}
                ></Select>
              </Form.Item>
              <ul className="field-input mb-3">
                {question?.questionValue.map((questionValue) => (
                  <>
                    <li>
                      <Input
                        size="large"
                        className="ant-modal-input"
                        value={questionValue}
                        // readOnly={loader}
                      />
                      {/* <CloseOutlined /> */}
                    </li>
                  </>
                ))}
              </ul>
              <Form.Item>
                <label>Required</label>
                <Switch
                  className="ms-2"
                  size="small"
                  checked={question?.isMandatory}
                />
              </Form.Item>
            </Form>
          </div>
        </List.Item>
      );
    default:
      return <></>;
  }
};

export const POSSIBLE_ANSWER_TYPE = {
  dropdown: "select",
  textArea: "text_area",
  textBox: "textbox",
};

export const ANSWER_TYPES = [
  { title: "Dropdown", value: POSSIBLE_ANSWER_TYPE.dropdown },
  { title: "Text Area", value: POSSIBLE_ANSWER_TYPE.textArea },
  { title: "Text Box", value: POSSIBLE_ANSWER_TYPE.textBox },
];
const dropdownScale = [
  { label: "0k", value: 0 },
  { label: "10k", value: 10 },
  { label: "20k", value: 20 },
  { label: "30k", value: 30 },
  { label: "50k", value: 50 },
  { label: "80k", value: 80 },
  { label: "100k", value: 100 },
  { label: "200k", value: 200 },
  { label: "300k", value: 300 },
  { label: "400k", value: 400 },
  { label: "500k", value: 500 },
];
export const handleAnswerView = ({
  type,
  answers,
  handleChange,
  handleOptionAdd,
  loader,
  isDefault,
  deleteOptions,
  defaultOption,
  defaultValue,
}) => {
  const { TextArea } = Input;
  const { Option } = Select;
  answers = answers || [];
  switch (type) {
    case POSSIBLE_ANSWER_TYPE.toggleButton:
      return (
        <Form.Item>
          <Row gutter={10} className="align-items-center">
            <Col md={10}>
              Default{" "}
              <Switch
                onChange={(value) => defaultOption(value.toString())}
                checked={defaultValue === "true"}
              />
            </Col>
          </Row>
        </Form.Item>
      );

    case POSSIBLE_ANSWER_TYPE.scale:
      const dropdownList =
        dropdownScale &&
        dropdownScale.map((val, idx) => (
          <Option value={val.value} key={idx}>
            {val.label}
          </Option>
        ));

      return (
        <Form.Item>
          <Row gutter={10} className="align-items-center mb-3">
            <Col md={10}>
              <Select
                className="d-block radius-30"
                name="scaleFrom"
                value={answers[0]}
                onChange={(e) => handleChange(e, 0)}
                disabled={loader}
              >
                {dropdownList}
              </Select>
            </Col>
            <Col md={4}>
              <span className="text-center d-block">To</span>
            </Col>
            <Col md={10}>
              <Select
                className="d-block radius-30"
                name="scaleTo"
                value={dropdownScale.find(
                  (option) => option.value === answers[1],
                )}
                onChange={(e) => handleChange(e, 1)}
                disabled={loader}
              >
                {dropdownList}
              </Select>
            </Col>
          </Row>
        </Form.Item>
      );
    case POSSIBLE_ANSWER_TYPE.textArea:
      return (
        <Form.Item>
          <Row gutter={10} className="align-items-center">
            <Col md={10}>
              <TextArea
                name="textArea"
                onChange={(e) => defaultOption(e.target.value)}
                rows={4}
                value={defaultValue}
              />
            </Col>
          </Row>
        </Form.Item>
      );
    case POSSIBLE_ANSWER_TYPE.textBox:
      return (
        <Form.Item>
          <Row gutter={10} className="align-items-center">
            <Col md={10}>
              <input
                name="textbox"
                onChange={(e) => defaultOption(e.target.value)}
                rows={4}
                value={defaultValue}
                readOnly={loader}
              />
            </Col>
          </Row>
        </Form.Item>
      );
    case POSSIBLE_ANSWER_TYPE.dateTimePicker:
      return (
        <Form.Item>
          <Row gutter={12} className="align-items-center">
            <Col md={12}>
              <Space direction="vertical" size={12}>
                <DatePicker
                  name="datepicker"
                  format={DATE_FORMATE}
                  showTime={{ format: "HH:mm" }}
                  onChange={(_, dateString) => handleChange(dateString)}
                />
              </Space>
            </Col>
          </Row>
        </Form.Item>
      );
    default:
      return (
        <Form.Item>
          <ul className="field-input mb-3">
            {answers.map((value, index) => (
              <li className="optionDesign" key={index}>
                <span className="drager">{dragbar}</span>
                <Input
                  size="large"
                  className="ant-modal-input"
                  placeholder="Add Option"
                  value={value}
                  name="optionsList"
                  onChange={(e) => handleChange(e.target.value, index)}
                  autoFocus
                  readOnly={loader}
                  disabled={isDefault}
                />
                {answers.length !== 1 && (
                  <CloseOutlined onClick={() => deleteOptions(index)} />
                )}
              </li>
            ))}
            <Button
              type="primary"
              size="large"
              className="radius-30 ant-primary-btn font-15 px-5 float-end mt-3 btn-responsive"
              onClick={handleOptionAdd}
              disabled={loader}
            >
              +Add more options
            </Button>
          </ul>
        </Form.Item>
      );
  }
};

export const validateQuestion = (question) => {
  const errors = {};

  if (!question.questionLabel) {
    errors.questionLabel = "Question title is required";
  }
  if (!question.questionInputType) {
    errors.questionInputType = "Question type is required";
  }

  // I added this  question.hasOwnProperty("columnName") condition because When add question if some question not required column but I got error so I added this condition to resolved error

  if (question.hasOwnProperty("columnName") && !question.columnName) {
    errors.columnName = "Column name is required";
  }
  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};

export const getQuestionValues = (type, questionValue) => questionValue;
