import { Modal } from "antd";

function ModalDailog({
  children,
  title,
  isModalOpen,
  handleOk,
  handleCancel,
  footer = false,
  className = "",
  width,
  ...rest
}) {
  return (
    <>
      <Modal
        centered
        title={title}
        open={isModalOpen}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={footer}
        {...rest}
        className={`userlist-modal ${className}`}
        width={width}
      >
        {children}
      </Modal>
    </>
  );
}
export default ModalDailog;
