import { AFFILIATE_TYPES } from "./affiliate.types";

const initialState = {
  token: "",
  user: {},
  error: {},
  profile: {},
  isChanged: false,
  formData: {},
  loading: false,
  bannerType: "",
  showBanner: false,
  referrals: {},
  summary: {},
  overview: {},
  myReferrals: {},
  "myReferral-filters": null,
  referralDetail: {},
  referralHistory: {},
  affiliateMarketer: {},
  rowDetails: {},
  superAdminModal: {
    changeEmail: false,
    confirmEmail: false,
    changePassword: false,
    rejectAffiliate: false,
    changeCommissionPerc: false,
    insertPassword: false,
    pastReports: false,
    reportProblem: false,
    rejectPayment: false,
    paymentError: false,
    proofOfPayments: false,
    removeProof: false,
    completeUpload: false,
    paymentMethod: false,
    uploadProof: false,
  },
  linkNCode: {},
  surveyDetails: {},
  docNPay: {},
  paymentDetails: {},
};

function affiliateReducer(state = initialState, action) {
  switch (action.type) {
    case AFFILIATE_TYPES.AUTH:
      return {
        ...state,
        ...action.payload,
      };
    case AFFILIATE_TYPES.AUTH_ERROR:
      return {
        ...state,
        ...(Array.isArray(action.payload)
          ? { error: action.payload }
          : { error: { ...action.payload } }),
      };
    case AFFILIATE_TYPES.PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };
    case AFFILIATE_TYPES.FORM_CHANGE:
      return {
        ...state,
        isChanged: action.payload,
      };
    case AFFILIATE_TYPES.FORM_FIELDS_CHANGE:
      return {
        ...state,
        formData: { ...state.formData, ...action.payload },
      };
    case AFFILIATE_TYPES.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case AFFILIATE_TYPES.BANNER_TYPE:
      return {
        ...state,
        bannerType: action.payload,
      };
    case AFFILIATE_TYPES.SHOW_BANNER:
      return {
        ...state,
        showBanner: action.payload,
      };
    case AFFILIATE_TYPES.FORM_ERRORS:
      return {
        ...state,
        error: {
          ...state.error,
          formErrors: {
            ...state.error?.formErrors,
            ...action.payload,
          },
        },
      };
    case AFFILIATE_TYPES.RESET_FORM_ERROR:
      delete state.error?.formErrors?.[action.payload];
      return {
        ...state,
      };
    case AFFILIATE_TYPES.REFERRAL_LIST:
      return {
        ...state,
        referrals: {
          ...state.referrals,
          ...action.payload,
        },
      };
    case AFFILIATE_TYPES.SUMMARY:
      return {
        ...state,
        summary: {
          ...state.summary,
          ...action.payload,
        },
      };
    case AFFILIATE_TYPES.OVERVIEW:
      return {
        ...state,
        overview: {
          ...state.overview,
          ...action.payload,
        },
      };
    case AFFILIATE_TYPES.MY_REFERRALS:
      return {
        ...state,
        myReferrals: {
          ...state.myReferrals,
          ...action.payload,
        },
      };
    case AFFILIATE_TYPES.MY_REFERRALS_FILTER:
      return {
        ...state,
        "myReferral-filters": action.payload
          ? {
              ...action.payload,
            }
          : null,
      };
    case AFFILIATE_TYPES.REFERRAL_DETAILS:
      return {
        ...state,
        referralDetail: { ...action.payload },
      };
    case AFFILIATE_TYPES.REFERRAL_HISTORY:
      return {
        ...state,
        referralHistory: action.payload,
      };
    case AFFILIATE_TYPES.AFFILIATE_MARKETER:
      return {
        ...state,
        affiliateMarketer: action.payload,
      };
    case AFFILIATE_TYPES.SET_AFFILIATE_ROW_DETAILS:
      return {
        ...state,
        rowDetails: { ...action.payload },
      };
    case AFFILIATE_TYPES.SUPER_ADMIN_MODALS:
      return {
        ...state,
        superAdminModal: {
          ...state?.superAdminModal,
          ...action.payload,
        },
      };
    case AFFILIATE_TYPES.LINK_CODE:
      return {
        ...state,
        linkNCode: {
          ...state?.linkNCode,
          ...action.payload,
        },
      };
    case AFFILIATE_TYPES.SURVEY_DETAILS:
      return {
        ...state,
        surveyDetails: {
          ...state.surveyDetails,
          ...action.payload,
        },
      };
    case AFFILIATE_TYPES.DOCUMENT_DETAIL:
      return {
        ...state,
        docNPay: {
          ...state.docNPay,
          ...action.payload,
        },
      };
    case AFFILIATE_TYPES.REPORTED_LIST:
      return {
        ...state,
        docNPay: {
          ...state?.docNPay,
          reported: {
            ...state?.docNPay?.reported,
            [action.payload.type]: action.payload.data,
          },
        },
      };
    case AFFILIATE_TYPES.AFFILIATE_PAYMENT_DATA:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          ...action.payload,
        },
      };
    case AFFILIATE_TYPES.AUTH_LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default affiliateReducer;
