import "./page-not-found.css";

import { Card, Col, Row } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { createValidUrl } from "../../utils/commonFuctions";

const ReceiptNotFound = () => {
  const location = useLocation();
  const [locationDetail] = useState({
    email: location.state?.data?.email,
    logo: location.state?.data?.logo,
    phoneNumber: location.state?.data?.phoneNumber,
  });
  return (
    <div className="receipt-found py-5" style={{ minHeight: "100vh" }}>
      <Row justify="center">
        <Col xs={24} lg={19}>
          <Card
            bordered={false}
            style={{ height: "85vh" }}
            bodyStyle={{ padding: "50px " }}
          >
            <Row gutter={[24, 24]} align="center">
              <Col xs={24} lg={12}>
                <div className="page-not-title">
                  <h1>Page not found</h1>
                  <p>
                    Oops! It looks like the document {`"you're"`} looking for
                    has been deleted. We apologize for any inconvenience. If you
                    have any questions or need further assistance, please
                    contact us at Billing {locationDetail.phoneNumber} or{" "}
                    {locationDetail.email}.
                  </p>
                </div>
              </Col>
              {location.logo && (
                <Col xs={24} lg={12}>
                  <img
                    src={location.logo && createValidUrl(location.logo)}
                    alt=""
                  />
                </Col>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ReceiptNotFound;
