import "./estimatesv3.css";

import { Card, IconButton, Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { estimationTemplateServcieTitle } from "../../../api/estimation";
import { getFormulaById } from "../../../api/formula";
import { SmallLoader } from "../../../common/components/small-loader";
import { handleErrorMessage } from "../../../utils/commonFuctions";
import { getRole } from "../../../utils/role";
import { edit } from "../../../utils/svg.file";
import BreadcrumbBar from "../../breadcrumb/Breadcrumb.pages";
import EditModal from "./modal/EditModal";
import EstimateTabs from "./tabs";
const EstimatesV3 = () => {
  const [open, setOpen] = React.useState(false);
  const { search } = window.location;
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const [formulaId, setFormulaId] = React.useState(null);
  const [formulaDetails, setFormulaDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormulaId(params.get("formulaId"));
  }, [params]);

  useEffect(() => {
    if (formulaId) {
      getFormulaUsingId();
    }
  }, [formulaId]);

  const getFormulaUsingId = async () => {
    setLoading(true);
    const res = await getFormulaById(formulaId);
    if (res?.remote === "success") {
      setFormulaDetails(res?.data?.data || {});
      setLoading(false);
    } else {
      handleErrorMessage(res);
      setLoading(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateTitleAndDisplayName = async (value) => {
    const paylaod = {
      title: value?.title || "default",
      displayName: value?.displayName || "default",
      userType: "admin",
    };
    const role = getRole();
    if (role === "superAdmin") {
      paylaod.userType = "superAdmin";
    }
    setFormulaDetails({
      ...formulaDetails,
      title: value?.title,
      displayName: value?.displayName,
    });
    const res = await estimationTemplateServcieTitle(
      formulaDetails?._id,
      paylaod,
    );
    if (res?.remote === "success") {
      handleClose();
    } else {
      handleErrorMessage(res);
    }
  };

  return (
    <>
      <BreadcrumbBar
        name="Dashboard"
        subname="Estimates"
        subtitle="Create Template"
        breaclass="mb-3"
        link="/"
      />
      {loading ? (
        <SmallLoader />
      ) : (
        <Card variant="outlined">
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            className="estimates-headerbar p-3"
          >
            <h3 className="m-0">{formulaDetails?.title}</h3> <span>|</span>
            <small>Display name: {formulaDetails?.displayName}</small>
            <IconButton onClick={handleClickOpen}>{edit}</IconButton>
          </Stack>

          <EstimateTabs {...{ formulaDetails }} />
        </Card>
      )}

      <EditModal
        open={open}
        handleClose={handleClose}
        formulaDetails={formulaDetails}
        handleUpdateTitleAndDisplayName={handleUpdateTitleAndDisplayName}
      />
    </>
  );
};
export default EstimatesV3;
