import { drawDOM, exportPDF } from "@progress/kendo-drawing";

export const getBase64OfHTMLDivComponentAsPDF = async (divId, breakDivId) => {
  try {
    const gridElement = document.getElementById(divId);
    let group;
    if (gridElement) {
      group = await drawDOM(gridElement, {
        paperSize: "A4",
        margin: "0.5cm",
        scale: 0.5,
      });
    }
    if (breakDivId && group) {
      const breakElement = document.getElementById(breakDivId);

      if (breakElement) {
        const breakGroup = await drawDOM(breakElement, {
          paperSize: "A4",
          margin: "0.5cm",
          scale: 0.5,
        });
        group.children.push(breakGroup);
      }
    }
    if (group) {
      const base64 = await exportPDF(group);
      return base64;
    }
    return "";
  } catch (error) {
    throw error;
  }
};

export const downloadBase64File = (base64, fileName) => {
  const pdfData = base64.substring(base64.indexOf(",") + 1);
  if (pdfData) {
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isChrome =
      navigator.userAgent.toLowerCase().indexOf("CriOS") > -1 ||
      navigator.vendor.toLowerCase().indexOf("google") > -1;
    let iOSVersion = [];
    if (iOS) {
      iOSVersion = navigator.userAgent
        .match(/OS [\d_]+/i)[0]
        .substring(3)
        .split("_")
        .map((n) => parseInt(n));
    }
    const attachmentData = pdfData;
    const attachmentName = fileName;
    let contentType = "application/pdf";

    const binary = window.atob(attachmentData.replace(/\s/g, ""));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i += 1) {
      view[i] = binary.charCodeAt(i);
    }
    const linkElement = document.createElement("a");
    try {
      let hrefUrl = "";
      let blob = "";
      if (iOS && !isChrome && iOSVersion[0] <= 12) {
        blob = `data:application/pdf;base64,${pdfData}`;
        hrefUrl = blob;
      } else {
        if (iOS && !isChrome) {
          contentType = "application/octet-stream";
        }
        blob = new Blob([view], { type: contentType });
        hrefUrl = window.URL.createObjectURL(blob);
      }
      linkElement.setAttribute("href", hrefUrl);
      linkElement.setAttribute("target", "_self");
      if ((iOS && (iOSVersion[0] > 12 || isChrome)) || !iOS) {
        linkElement.setAttribute("download", attachmentName);
      }
      const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      linkElement.dispatchEvent(clickEvent);
    } catch (ex) {}
  }
};

export const addExtraPercentageAmount = (value, percentage) => {
  const result = (percentage * value) / 100;
  return result;
};
