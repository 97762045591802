import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Form, Input } from "antd";
import * as React from "react";

import CustomButton from "../../../../../globalComponents/button";
import { editLink } from "../../../../../utils/svg.file";

const EditLinkModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
        className="modal-mui-radius"
      >
        <DialogTitle className="title-editlink">
          <span className="d-inline-block me-2">{editLink} </span> {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Form layout="vertical">
              <Form.Item label="Link name">
                <Input placeholder="e.g. Front render" className="radius-8" />
              </Form.Item>
              <Form.Item label="Link URL">
                <Input
                  className="radius-8"
                  placeholder="e.g. https://www.figma.com/input placeholder"
                />
              </Form.Item>
              <Stack direction="row" spacing={2} className="btn-estimates">
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={props.handleClose}
                >
                  Cancel
                </Button>
                <CustomButton fullWidth onClick={props.handleClose}>
                  Save
                </CustomButton>
              </Stack>
            </Form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditLinkModal;
