import { Col, Row, Typography } from "antd";
import React from "react";

import { ReactComponent as FBIcon } from "../../images/affiliateLandingImages/socialMediaIcon/fb-icon.svg";
import { ReactComponent as InstaIcon } from "../../images/affiliateLandingImages/socialMediaIcon/insta-icon.svg";
import { ReactComponent as LinkedInIcon } from "../../images/affiliateLandingImages/socialMediaIcon/linked-in.svg";
import { ReactComponent as TwitterIcon } from "../../images/affiliateLandingImages/socialMediaIcon/twitter-logo.svg";
import { ReactComponent as ZevBitLogo } from "../../images/affiliateLandingImages/zevbit-white-logo.svg";
import Footer from "./Footer";
import FreeTrialSection from "./FreeTrialSection";
import LeadForm from "./LeadForm";

const { Title } = Typography;
const FooterContent = ({ promoCode }) => (
  <>
    <div className="position-relative">
      <div className="px-3">
        <FreeTrialSection promoCode={promoCode} />
      </div>
      <Row
        style={{
          background: "#1D2939",
          color: "#EAECF0",
          padding: "150px 64px",
        }}
        className="w-100 footer-space-af"
        justify="space-between"
      >
        <Col
          className="d-flex flex-column"
          style={{ justifyContent: "space-between" }}
        >
          <div>
            <ZevBitLogo />
          </div>
          <div className="my-3 my-lg-0">
            <div style={{ color: "#EAECF0" }}>
              <span>Email Us:</span>
              <a
                href="mailto:info@ZevBit.com"
                style={{
                  color: "#EAECF0",
                  textDecoration: "underline",
                  marginLeft: 5,
                }}
              >
                info@ZevBit.com
              </a>
            </div>
            <div style={{ color: "#EAECF0" }}>
              <span>Sales & Support</span>
              <a
                href="tel:(888) 703-4070"
                style={{
                  color: "#EAECF0",
                  textDecoration: "underline",
                  marginLeft: 5,
                }}
              >
                (888) 703-4070
              </a>
            </div>
          </div>
          <Row justify="space-between">
            <Col>
              <TwitterIcon />
            </Col>
            <Col>
              <LinkedInIcon />
            </Col>
            <Col>
              <FBIcon />
            </Col>
            <Col>
              <InstaIcon />
            </Col>
          </Row>
        </Col>
        <Col>
          <Title
            style={{ color: "#98A2B3" }}
            level={5}
            className="mt-3 mt-lg-0"
          >
            Product
          </Title>
          <p>About us</p>
          <p>Contact us</p>
          <p>F.A.Q</p>
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
        </Col>
        <Col>
          <Title style={{ color: "#EAECF0" }} level={5}>
            Looking For More Information?
          </Title>
          <LeadForm />
        </Col>
      </Row>
    </div>
    <Footer />
  </>
);

export default FooterContent;
