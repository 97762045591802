import "./referrals.css";

import { Button, Card, Col, Form, Modal, Row, Typography } from "antd";
import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { postPaymentRequest } from "../../../../../api/affiliate";
import { ReactComponent as SummaryIcon } from "../../../../../images/affiliateImages/summary.svg";
import {
  getSummaryDetailsAction,
  resetInputErrors,
  setInputErrors,
} from "../../../../../redux/affiliate/affiliate.action";
import { trimNumber } from "../../../../../utils/javascript";
import { summaryCards } from "../../../util";
import { PayoutModal } from "../modals/ReferralModals";
const { Title } = Typography;

const Summary = ({ isSuperAdmin = false }) => {
  const dispatch = useDispatch();
  const formErrors = useSelector((state) => state.affiliate.error?.formErrors);
  const [openPayout, setOpenPayout] = useState(false);
  const [value, setValue] = useState();
  const [form] = Form.useForm();
  const summaryData = useSelector((state) => state?.affiliate?.summary);
  const [loading, setLoading] = useState(false);
  const configs = {
    title: "",
    className: "modal-pop-up payment-request-modal-popup",
    centered: true,
    content: (
      <div>
        <h5 style={{ fontSize: "18px" }}>Payment Request Successful</h5>
        <span>
          <p style={{ textAlign: "justify" }}>
            Your request has been processed. This process can take 24 to 72
            hours to be reflected in the selected payment method. You can view
            the status of your request in the &ldquo;My Payments&rdquo; tab.
          </p>
        </span>
      </div>
    ),
    okText: "Confirm",
    onOk() {
      dispatch(getSummaryDetailsAction());
      handlePayoutModalVisibility();
    },
  };

  const handlePayoutModalVisibility = () => {
    setOpenPayout(!openPayout);
    dispatch(resetInputErrors("payout-request"));
    form.resetFields();
  };

  const handleConfirmPayout = async () => {
    if (!Object.keys(formErrors || {})?.length) {
      const response = await postPaymentRequest(
        {
          amount: form?.getFieldValue("payout-request"),
        },
        setLoading,
      );
      if (response.remote === "success") {
        handlePayoutModalVisibility();
        setValue("");
        dispatch(resetInputErrors("payout-request"));
        Modal.info(configs);
        form.resetFields();
        setLoading(false);
      } else {
        dispatch(
          setInputErrors({
            "payout-request": response?.error?.errors?.error,
          }),
        );
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const modifiedValue = e.target.value.toString().replace(/\B\D/g, "");
    console.log("modifiedValue", modifiedValue, +modifiedValue);
    setValue(`$${modifiedValue}`);
    if (
      +modifiedValue >=
      summaryData?.myBalance - summaryData?.totalRequestedAmount
    ) {
      dispatch(
        setInputErrors({
          [e.target.name]: "You don't have enough balance to withdraw",
        }),
      );
    } else if (modifiedValue && !+modifiedValue > 0) {
      dispatch(
        setInputErrors({
          [e.target.name]: "Request should be more than $0",
        }),
      );
    } else {
      dispatch(resetInputErrors("payout-request"));
    }
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Title level={4} style={{ fontWeight: "normal", marginBottom: 20 }}>
        <SummaryIcon style={{ marginRight: 5 }} />
        <span>Summary</span>
      </Title>
      <Row gutter={[16, 16]}>
        {summaryCards.map(({ title, name, ...rest }, ind) => (
          <Col
            // style={{ minWidth: `${100 / summaryCards.length}%` }}
            key={`cards-${ind}`}
            lg={6}
            xs={12}
            className="col-lastchild"
          >
            <Card title={title} className="removeBorder h-100">
              <Title level={4} style={{ fontWeight: "normal" }}>
                ${trimNumber(summaryData?.[name])}
              </Title>
              <div>
                {rest.hasButton && (
                  <Button
                    type="primary"
                    className={classNames("w-100", {
                      "disable-btn": isSuperAdmin,
                      "affiliate-button": !isSuperAdmin,
                    })}
                    onClick={() => handlePayoutModalVisibility()}
                    disabled={isSuperAdmin}
                  >
                    {rest.btnTitle}
                  </Button>
                )}
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      <PayoutModal
        isModalOpen={openPayout}
        handleCancel={handlePayoutModalVisibility}
        handleChange={handleChange}
        value={value}
        form={form}
        handleConfirmPayout={handleConfirmPayout}
        summaryData={summaryData}
      >
        <Row gutter={[24, 24]}>
          <Col span={12} style={{ height: 44 }}>
            <Button
              className="w-100 h-100"
              style={{ borderRadius: 8 }}
              onClick={() => handlePayoutModalVisibility()}
            >
              Cancel
            </Button>
          </Col>
          <Col span={12} style={{ height: 44 }}>
            <Button
              className="w-100 h-100 affiliate-button"
              type="primary"
              onClick={() => handleConfirmPayout()}
              htmlType="submit"
              disabled={loading}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </PayoutModal>
    </div>
  );
};

export default Summary;
