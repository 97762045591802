import regex from "../../../utils/regex";
import { POSSIBLE_ANSWER_TYPE } from "../../setting/SetupQuestion/v2/utils/helper";

export const validateLeadForm = (details) => {
  const errors = {};
  const { fullName, email, leadSource, questionsAnswers } = details;
  if (!fullName) {
    errors.fullName = "Full Name Required";
  }
  if (email) {
    if (!regex.emailRegex.test(email)) {
      errors.email = "Email is not valid";
    }
  }
  if (leadSource === undefined) {
    errors.leadSource = "Field Required";
  }
  questionsAnswers?.forEach((questions) => {
    const { _id, answer, question } = questions;
    if (
      question?.isMandatory &&
      question.questionInputType !== POSSIBLE_ANSWER_TYPE.toggleButton
    ) {
      if (!answer.length) {
        errors[_id] = "Field Required";
      }
    }
  });

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
};
