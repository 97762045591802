import React from "react";
import { ToastContainer } from "react-toastify";

import { myReferralContainer } from "../container";
import {
  ReferralLinkModal,
  ReferralPromoCodeModal,
} from "../modals/ReferralModals";
import MyReferrals from "./MyReferrals";
import Overview from "./Overview";
import ReferralHeader from "./ReferralHeader";
import Summary from "./Summary";

const Referrals = ({ isSuperAdmin = false }) => {
  const {
    referral,
    isVisibleLinkModal,
    isVisibleCodeModal,
    handleLinkShareModalVisibility,
    handlePromoCodeModalVisibility,
  } = myReferralContainer(isSuperAdmin);

  return (
    <>
      <ReferralHeader
        handleLinkShareModalVisibility={handleLinkShareModalVisibility}
        handlePromoCodeModalVisibility={handlePromoCodeModalVisibility}
        referral={referral}
        isSuperAdmin={isSuperAdmin}
      />
      <Summary isSuperAdmin={isSuperAdmin} />
      <Overview isSuperAdmin={isSuperAdmin} />
      <MyReferrals isSuperAdmin={isSuperAdmin} />
      <ReferralLinkModal
        isVisibleLinkModal={isVisibleLinkModal}
        handleLinkShareModalVisibility={handleLinkShareModalVisibility}
        referralLinks={referral}
        isSuperAdmin={isSuperAdmin}
      />
      <ReferralPromoCodeModal
        isVisibleCodeModal={isVisibleCodeModal}
        handlePromoCodeModalVisibility={handlePromoCodeModalVisibility}
        referralLinks={referral}
        isSuperAdmin={isSuperAdmin}
      />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
};

export default Referrals;
