export const transformGetOrganizationResposne = (data) => ({
  _id: data._id,
  address: data.address,
  name: data.name,
  phoneNumber: data.phoneNumber,
  coverPhoto: data.estimationCoverPhoto,
  logo: data.logo,
  teamPhoto: data.teamPhoto,
  paymentTerms: data.paymentTerms,
  termAndCondition: data.termAndCondition,
  expectedOverhead: data.expectedOverhead,
  subContractOverhead: data.subContractOverhead,
  productionRates: data.productionRates,
  createdAt: data.createdAt,
  formulaCopied: data.formulaCopied,
  formulaCopyErrorLogs: data.formulaCopyErrorLogs,
  catalogCopied: data.catalogCopied,
  catalogCopyErrorLogs: data.catalogCopyErrorLogs,
  formulaUpdateCopied: data.formulaUpdateCopied,
  formulaUpdateCopyErrorLogs: data.formulaUpdateCopyErrorLogs,
  catalogUpdateCopied: data.catalogUpdateCopied,
  catalogUpdateCopyErrorLogs: data.catalogUpdateCopyErrorLogs,
  markup: data.markup,
  coverNote: data.coverNote,
  termAndConditionCopied: data.termAndConditionCopied,
  taxDetails: data.taxDetails,
  signature: data.signature,
  paymentGatewayDetails: data.paymentGatewayDetails,
  walletBalance: data.walletBalance?.$numberDecimal || 0,
  amountSettings: data.amountSettings,
  activePhoneNumber: data.twilioNumber?.phone_number,
  socialMedia: data?.socialMedia || {},
});
