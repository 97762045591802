import urlcat from "urlcat";

import { getRole } from "../utils/role";
import api from "./api";

export async function addEstimation(data) {
  const response = await api.request({
    url: "status//estimation-status",
    method: "POST",
    data,
  });

  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function deleteEstimation(token, data) {
  const response = await api.requestByToken({
    url: `status/estimation-status/by-id?id=${data}`,
    method: "DELETE",
    token,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function updateEstimation(token, data) {
  const response = await api.requestByToken({
    url: "/status/estimation-status",
    method: "PUT",
    token,
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getAllEstimateTemplateSuperAdmin(id) {
  const response = await api.request({
    url: `/v2/formula/get-default-tasks?serviceId=${id}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function getAllEstimateTemplateOtherUser(id) {
  const response = await api.request({
    url: `/v2/formula/organization/get-default-tasks?serviceId=${id}`,
    method: "GET",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function deleteEstimationTemplate(id) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/formula/delete-template/${id}`;
    }
    return `/v2/formula/organization/delete-template/${id}`;
  };

  const response = await api.request({
    url: url(),
    method: "DELETE",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function deleteEstimationTemplateTask(id) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return `/v2/formula/delete-default-task/${id}`;
    }
    return `/v2/formula/organization/delete-default-task/${id}`;
  };

  const response = await api.request({
    url: url(),
    method: "DELETE",
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function duplicateEstimationTemplate(payload) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/formula/duplicate-template";
    }
    return "/v2/formula/organization/duplicate-template";
  };
  const response = await api.request({
    url: url(),
    method: "post",
    data: payload,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function addEstimationTemplate(payload) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/formula/add-template";
    }
    return "/v2/formula/organization/add-template";
  };
  const response = await api.request({
    url: url(),
    method: "post",
    data: payload,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function addEstimationTemplateTask(payload) {
  const url = () => {
    const role = getRole();
    if (role === "superAdmin") {
      return "/v2/formula/add-default-task";
    }
    return "/v2/formula/organization/add-default-task";
  };
  const response = await api.request({
    url: url(),
    method: "post",
    data: payload,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
export async function estimationTemplateServcieTitle(id, payload) {
  const response = await api.request({
    url: `v2/formula/organization/edit-formula/${id}`,
    method: "put",
    data: payload,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export function updateUserEstimationServices({ estimationId, payload }) {
  return api.request({
    url: urlcat("v3/user-estimate/:estimationId/service", { estimationId }),
    method: "PUT",
    data: payload,
  });
}
export function updateUserEstimationServicesFromCustomer({
  estimationId,
  payload,
  token,
}) {
  return api.request({
    url: urlcat("v3/user-estimate/customer/:estimationId/service", {
      estimationId,
      token,
    }),
    method: "PUT",
    data: payload,
  });
}
export function addChangeOrderSentDetailsToEstimationAPI({
  estimationId,
  payload,
}) {
  return api.request({
    url: urlcat("v3/user-estimate/:estimationId/sent-change-order-details", {
      estimationId,
    }),
    method: "PUT",
    data: payload,
  });
}
export function getEstimationDetailsWithSentChangeOrderDetails({
  estimationId,
  changeOrderSentId,
}) {
  return api.request({
    url: urlcat("v3/user-estimate/:estimationId/:changeOrderSentId", {
      estimationId,
      changeOrderSentId,
    }),
    method: "GET",
  });
}
export function getEstimationDetailsWithSentChangeOrderDetailsForCustomerAPI({
  estimationId,
  changeOrderSentId,
}) {
  return api.request({
    url: urlcat("v3/user-estimate/customer/:estimationId/:changeOrderSentId", {
      estimationId,
      changeOrderSentId,
    }),
    method: "GET",
  });
}

export function getEstimateProjectSummaryDetailsAPI({
  estimationId,
  uniqueIds,
}) {
  return api.request({
    url: urlcat("v3/user-estimate/project-summary-table", {
      estimationId,
      uniqueIds,
    }),
    method: "GET",
  });
}
export function getEstimateProjectSummaryDetailsForCustomerAPI({
  estimationId,
  uniqueIds,
}) {
  return api.request({
    url: urlcat("v3/user-estimate/customer/project-summary-table", {
      estimationId,
      uniqueIds,
    }),
    method: "GET",
  });
}

export function addOrganizationSignatureOnChangeOrderAPI({
  estimationId,
  sentChangeOrderId,
  data,
}) {
  return api.request({
    url: urlcat(
      "v3/user-estimate/:estimationId/change-order/:sentChangeOrderId/organization-signature",
      {
        estimationId,
        sentChangeOrderId,
      },
    ),
    method: "PUT",
    data,
  });
}

export function addCustomerSignatureOnChangeOrderAPI({
  estimationId,
  sentChangeOrderId,
  data,
}) {
  return api.request({
    url: urlcat(
      "v3/user-estimate/customer/:estimationId/change-order/:sentChangeOrderId/customer-signature",
      {
        estimationId,
        sentChangeOrderId,
      },
    ),
    method: "PUT",
    data,
  });
}

export function confirmCustomerChangeOrderSignatureAPI({
  estimationId,
  sentChangeOrderId,
  data,
}) {
  return api.request({
    url: urlcat(
      "v3/user-estimate/customer/:estimationId/change-order/:sentChangeOrderId/customer-signature/confirm",
      {
        estimationId,
        sentChangeOrderId,
      },
    ),
    method: "PUT",
    data,
  });
}
export function sendChangeOrderToCustomerAPI({
  estimationId,
  sentChangeOrderId,
  data,
}) {
  return api.request({
    url: urlcat(
      "v3/user-estimate/:estimationId/change-order/:sentChangeOrderId/send-email",
      {
        estimationId,
        sentChangeOrderId,
      },
    ),
    method: "PUT",
    data,
  });
}
export function changeOrderSummaryListAPI({ estimationId }) {
  return api.request({
    url: urlcat("v3/user-estimate/:estimationId/change-order/summary", {
      estimationId,
    }),
    method: "GET",
  });
}

export const getSingedEstimationList = async () => {
  const response = await api.request({
    url: `/v3/user-estimate/signed/list`,
    method: "GET",
  });

  return response;
};

export function updateChangeOrderContractHideOptionAPI({
  estimationId,
  changeOrderId,
  data,
}) {
  return api.request({
    url: urlcat(
      "v3/user-estimate/:estimationId/change-order/:changeOrderId/hide-details",
      {
        estimationId,
        changeOrderId,
      },
    ),
    method: "PUT",
    data,
  });
}
