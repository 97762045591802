import { Col, Row } from "antd";
import React from "react";

import crmPreview from "../../images/affiliateLandingImages/crm-preview.png";
import { crmContent } from "./utils";

const CRMSection = () => (
  <Row
    className="mt-5 crm-section"
    id="crm-section"
    gutter={[24, 24]}
    align="middle"
  >
    <Col xs={24} lg={12}>
      <img src={crmPreview} alt="crm-preview" />
    </Col>
    <Col xs={24} lg={12}>
      <div className="h-100">
        {crmContent?.map(({ title, subContent }) => (
          <>
            <h2>{title}</h2>
            {subContent?.map(({ content, needBullet = true }) => (
              <div style={{ position: "relative" }}>
                {needBullet && (
                  <span
                    style={{
                      position: "absolute",
                      top: 8,
                      left: 0,
                      height: 5,
                      width: 5,
                      borderRadius: "100%",
                      background: "#3483FA",
                    }}
                  />
                )}
                <p style={{ paddingLeft: 15 }}>{content}</p>
              </div>
            ))}
          </>
        ))}
      </div>
    </Col>
  </Row>
);

export default CRMSection;
