import leadTypes from "./lead.types";

const initialState = {
  address: "",
  distance: 0,
  leadInfo: {},
  leadStatuses: [],
  loadingLead: true,
  userEstimationList: [],
};

export const leadReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ADDRESS_AND_DISTANCE":
      return {
        ...state,
        address: action.payload.address,
        distance: action.payload.distance,
      };
    case leadTypes.LEAD_INFO:
      return {
        ...state,
        leadInfo: action.leadInfo,
        loadingLead: false,
      };
    case leadTypes.LEAD_LOADING:
      return {
        ...state,
        leadInfo: {},
        loadingLead: true,
      };
    case "LEAD_STATUSES":
      return {
        ...state,
        leadStatuses: action.leadStatuses,
      };
    case leadTypes.USER_ESTIMATION:
      return {
        ...state,
        userEstimateNumberLists: action.userEstimateListInfo,
      };
    default: {
      return {
        ...state,
      };
    }
  }
};
