/* eslint-disable no-unused-vars */
import "./v3/affiliate.css";
import "react-toastify/dist/ReactToastify.css";

import { Button, Col, Form, Image, Input, Layout, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import ModalDailog from "../../common/components/ModalDialogue";
import affiliateRegisterImage from "../../images/affiliate_login_rightImg.png";
import logo from "../../images/logo-signup.png";
import { authAction } from "../../redux/affiliate/affiliate.action";
import ResetPasswordModal from "./ResetPasswordModal";
import { getRedirectionURL } from "./util";

const { Content } = Layout;

const AffiliateLogin = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [showForgetModal, setShowForgetModal] = useState(false);
  const { user, error, loading } = useSelector((state) => state?.affiliate);
  const token = localStorage.getItem("affiliateToken");
  const handleSubmit = async (data) => {
    dispatch(authAction(`/v3/affiliate/auth/sign-in`, data));
  };

  const formKeys = Object.keys(form.getFieldsValue());

  const handleModalCancel = () => setShowForgetModal(false);

  useEffect(() => {
    if (token) {
      if (Object.keys(user)?.length) {
        const existing = user?.existingEmail;
        const hasExisting = user?.isFilledExistingEmail;
        const hadSurvey = user?.isFilledSurvey;
        const { redirectUrl, state } = getRedirectionURL({
          existing,
          hasExisting,
          hadSurvey,
        });
        history(redirectUrl, { state });
      } else {
        history("/affiliate/profile");
      }
    }
    if (!Object.keys(user)?.length && (error?.error || Array.isArray(error))) {
      if (Array.isArray(error)) {
        error?.forEach((obj) => {
          form.setFields([
            { name: obj?.path, errors: ["Please enter valid info"] },
          ]);
        });
      } else if (error?.error) {
        if (formKeys?.includes(error?.data?.field)) {
          form.setFields([
            {
              name: [error?.data?.field],
              errors: [error?.error],
            },
          ]);
        }
        if (error?.data?.field === "rejected") {
          message.error(error?.error);
        }
      } else {
        toast.error("Something went wrong!");
      }
    }
  }, [token, user, error]);

  return (
    <>
      <Row>
        <Col xs={24} lg={12} className="d-none d-sm-block">
          <img
            src={affiliateRegisterImage}
            alt="affiliate-banner"
            style={{ width: "100%", objectFit: "cover" }}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Content className="h-full afflite-market">
            <div className="w-100" style={{ textAlign: "center" }}>
              <Image
                src={logo}
                preview={false}
                style={{
                  width: "200px",
                  height: "47.07px",
                  objectFit: "cover",
                }}
              />
            </div>
            <h1>Log In To Affiliate</h1>
            <Form
              layout="vertical"
              autoComplete="off"
              onFinish={handleSubmit}
              form={form}
              className="affiliate-login-page"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                  { type: "email", message: "Please enter valid email" },
                ]}
                requiredMark="optional"
              >
                <Input className="affiliate-email-login-page" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: "Please enter your password" },
                ]}
                requiredMark="optional"
              >
                <Input.Password type="password" />
              </Form.Item>
              <span style={{ marginBottom: 10 }}>
                Forgot Password?{" "}
                <Button
                  type="link"
                  className="p-0"
                  onClick={() => setShowForgetModal(true)}
                >
                  Click Here
                </Button>
              </span>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-100 affiliate-login-button"
                  loading={loading}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
            <div style={{ textAlign: "center" }}>
              <span>
                New to ZevBit’s Affiliate Program?{" "}
                <a href="/affiliate/registration">Register</a>
              </span>
            </div>
            <div className="company-design">© ZevBit 2023</div>
          </Content>
        </Col>
      </Row>
      <ModalDailog
        isModalOpen={showForgetModal}
        handleCancel={handleModalCancel}
        title="Forgot Password"
        centered={false}
      >
        <ResetPasswordModal handleCancel={handleModalCancel} />
      </ModalDailog>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
};

export default AffiliateLogin;
