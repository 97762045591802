/* eslint-disable no-unused-vars */
import { Button, Col, Form, Input, message, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { getQBCharge, getQBToken } from "../../api/invoice";
import { VALIDATION_MESSAGE } from "../../utils/constants/constants";
import {
  cardExpiryFormat,
  cardNumber,
  formatCardNumber,
} from "../../utils/javascript";
import { NumberFormat } from "../../utils/numberFormat";
import CountryDropdown from "../common/CountryDropdown";
import { extractToken } from "../invoicing/helper";
import { BackBtn } from "../invoicing/svg.icons";

const QBPayment = () => {
  const [formProps, setFormProps] = useState({
    email: "",
    number: "",
    expiryDate: "",
    cvc: "",
    name: "",
    address: {
      country: "",
      postalCode: "",
    },
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    number: "",
    expiryDate: "",
    cvc: "",
    name: "",
    country: "",
    postalCode: "",
  });
  const organizationDetails = useSelector((state) => state?.user?.organization);
  const currentIndex = useSelector((state) => state?.invoice?.currentIndex);
  const receiptDetails = useSelector(
    (state) => state?.invoice?.sendReceipt?.invoiceList,
  )?.[currentIndex];
  const shareableLink = useSelector(
    (state) => state?.invoice?.sendReceipt?.shareableLink,
  );
  const [disableBtn, setDisableBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    setFormProps((prev) => ({
      ...prev,
      [name]: value || "",
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: !value ? VALIDATION_MESSAGE?.[name] : "",
    }));
  };

  const handleCardNumberChange = (e) => {
    const { name, value } = e?.target;

    setFormProps((prev) => ({
      ...prev,
      [name]: cardNumber(value),
    }));

    setFormErrors((prev) => ({
      ...prev,
      [name]: value?.replace(/\s/g, "")?.length === 16 ? "" : "Invalid Card",
    }));
  };

  const handleExpiryChange = (e) => {
    const { name, value } = e?.target;
    const month = value?.split("/")[0];
    const currentYear = moment().year();
    const year =
      currentYear - (currentYear % 100) + Number(value?.split("/")[1]);
    setFormProps((prev) => ({
      ...prev,
      [name]: cardExpiryFormat(value),
      expMonth: month,
      expYear: year?.toString(),
    }));
    if (
      value &&
      (Number(month) > 12 || Number(month) <= 0) &&
      currentYear > Number(year)
    ) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: "Invalid Month And Year",
      }));
      setDisableBtn(true);
    } else if (value && (Number(month) > 12 || Number(month) <= 0)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: "Invalid Month",
      }));
      setDisableBtn(true);
    } else if (value && currentYear > Number(year)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: "Invalid Year",
      }));
      setDisableBtn(true);
    } else {
      console.log("#113 first", name);
      setFormErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
      setDisableBtn(false);
    }
  };

  const handleCarCVV = (e) => {
    const { name, value } = e?.target;
    setFormProps((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: !value ? "Enter cvc" : "",
    }));
  };

  const handleCountryChange = (value) => {
    console.log("value", value);
    setFormProps((prev) => ({
      ...prev,
      address: {
        ...prev?.address,
        country: value,
      },
    }));
    setFormErrors((prev) => ({
      ...prev,
      country: !value ? "Please select the country" : "",
    }));
  };

  const handlePostalCodeChange = (e) => {
    const { name, value } = e?.target;
    setFormProps((prev) => ({
      ...prev,
      address: {
        ...prev?.address,
        [name]: value,
      },
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: !value ? "Invalid Zip code" : "",
    }));
  };

  const getFormValidation = (object) => {
    let hasError = false;
    Object.entries(object).forEach(([key, value]) => {
      if (typeof value === "object") {
        getFormValidation(value);
      }
      if (!value && !formErrors?.[key]) {
        setFormErrors((prev) => ({
          ...prev,
          [key]: VALIDATION_MESSAGE?.[key],
        }));
        hasError = true;
      }
    });
    return hasError;
  };

  const handlePayNow = async () => {
    const hasError = getFormValidation(formProps);
    console.log("hasError", hasError);
    if (!hasError) {
      const clonedPayload = {
        ...formProps,
        number: formProps?.number?.replace(/\s/g, ""),
      };

      const fetchToken = await getQBToken({ card: { ...clonedPayload } });
      if (fetchToken?.remote === "success") {
        const payload = {
          cardToken: fetchToken?.data?.value,
        };
        const token = extractToken(shareableLink);
        const response = await getQBCharge(token, payload);
        if (response.remote === "success") {
          console.log("response => ", response);
          message.success("Payment made successful");
          navigate(-1);
        } else {
          console.log("error :>> ", response);
          message.error(
            response?.errors?.errors?.message || "Something went wrong",
          );
        }
      } else {
        console.log("error :>> ", fetchToken);
        message.error(
          fetchToken?.error?.errors?.[0]?.moreInfo || "Something went wrong",
        );
      }
    }
  };

  useEffect(() => {
    if (!location.state?.invoiceId || !receiptDetails) {
      navigate(-1);
    }
  }, []);

  return (
    <Row className="p-5" style={{ justifyContent: "center" }}>
      <Col>
        <Button type="link" onClick={() => handleBack()}>
          <span>{BackBtn}</span>
          <span style={{ marginRight: "10px" }}>Back button</span>
          <span>{organizationDetails?.name}</span>
        </Button>
        <p>Deposit Payment at Signing Of Contact</p>
        {NumberFormat(
          receiptDetails?.invoiceAmount +
            (receiptDetails?.invoiceAmount * receiptDetails?.chargePercentage) /
              100,
        )}
      </Col>
      <Col>
        <label htmlFor="email">Email</label>
        <Input
          inputMode="email"
          placeholder="Email Address"
          onChange={(e) => handleInputChange(e)}
          value={formProps?.email || ""}
          name="email"
          id="email"
        />
        {formErrors?.email && (
          <p className="text-danger">{formErrors?.email}</p>
        )}
        <label htmlFor="number">Card Information</label>
        <Input
          placeholder="1234 1234 1234 1234"
          name="number"
          id="number"
          onChange={(e) => handleCardNumberChange(e)}
          maxLength={19}
          value={formProps?.number || ""}
          className="w-100"
        />
        {formErrors?.number && (
          <p className="text-danger">{formErrors?.number}</p>
        )}
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <Input
            placeholder="MM/YY"
            onChange={(e) => handleExpiryChange(e)}
            maxLength={5}
            value={formProps?.expiryDate || ""}
            className="w-100"
            name="expiryDate"
            {...{ ...(formErrors?.expiryDate && { status: "error" }) }}
          />
          <Input
            placeholder="CVC"
            onChange={(e) => handleCarCVV(e)}
            maxLength={3}
            value={formProps?.cvc || ""}
            className="w-100"
            name="cvc"
            {...{ ...(formErrors?.expiryDate && { status: "error" }) }}
          />
        </div>
        {formErrors?.expiryDate && (
          <p className="text-danger m-0">{formErrors?.expiryDate}</p>
        )}
        {formErrors?.cvc && <p className="text-danger">{formErrors?.cvc}</p>}
        <label htmlFor="name">Cardholder name</label>
        <Input
          placeholder="Full name on card"
          onChange={(e) => handleInputChange(e)}
          value={formProps?.name || ""}
          formatter={(value) => formatCardNumber(value)}
          className="w-100"
          name="name"
          id="name"
        />
        {formErrors?.name && <p className="text-danger">{formErrors?.name}</p>}
        <CountryDropdown
          value={formProps?.address?.country}
          handleCountryChange={handleCountryChange}
          label="Country or region"
        />
        {formErrors?.country && (
          <p className="text-danger">{formErrors?.country}</p>
        )}
        <Input
          placeholder="Zip Code"
          onChange={(e) => handlePostalCodeChange(e)}
          maxLength={5}
          value={formProps?.address?.postalCode || ""}
          className="w-100"
          name="postalCode"
        />
        {formErrors?.postalCode && (
          <p className="text-danger">{formErrors?.postalCode}</p>
        )}
        <Button
          className="apply-btn w-100"
          type="primary"
          onClick={() => handlePayNow()}
          disabled={
            loading ||
            disableBtn ||
            Object.values(formErrors)?.some((val) => val)
          }
          loading={loading}
        >
          Pay Now
        </Button>
        {Object.values(formErrors).every((val) => val) && (
          <p className="text-danger">
            Please fill in all card details to proceed with the payment.
          </p>
        )}
      </Col>
    </Row>
  );
};

export default QBPayment;
