import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const ScheduleDemo = ({ promoCode, ...props }) => {
  const history = useNavigate();
  const handleScheduleDemo = () => {
    history(`/schedule-demo?promoCode=${promoCode}`);
  };
  return (
    <Button onClick={handleScheduleDemo} {...props}>
      Schedule Demo
    </Button>
  );
};

export default ScheduleDemo;
