import { Button, Col, Divider, Row } from "antd";
import React from "react";

import payonerLogo from "../../../../../images/payonerLogo.png";
import paypallogo from "../../../../../images/paypallogo.png";
import venmoLogo from "../../../../../images/venmoLogo.png";

const PaymentModel = ({ setPaymentModal, setToggleModal }) => (
  <>
    <div className="add-promo-code cencelnotes mt-4">
      <Row gutter={[0, 24]}>
        <Col xs={24}>
          <div className="payment-col-one">
            <img src={paypallogo} alt="PayPal" />
            <Button
              onClick={() => {
                setPaymentModal((prev) => ({ ...prev, paymentModal: false }));
                setToggleModal((prev) => ({
                  ...prev,
                  paypal: { ...prev?.paypal, toggle: true },
                }));
              }}
              style={{ borderRadius: "8px" }}
              size="large"
              type="primary"
            >
              Set Up
            </Button>
          </div>
          <div className="Payment-inner-modal-text">
            Withdraw funds to your PayPal account. Fee of 3.49% + $0.49
          </div>
        </Col>
        <Divider style={{ margin: "0" }} />
        <Col xs={24}>
          <div className="payment-col-one">
            <img src={venmoLogo} alt="PayPal" />
            <Button
              onClick={() => {
                setPaymentModal((prev) => ({ ...prev, paymentModal: false }));
                setToggleModal((prev) => ({
                  ...prev,
                  venmo: { ...prev?.venmo, toggle: true },
                }));
              }}
              style={{ borderRadius: "8px" }}
              size="large"
              type="primary"
            >
              Set Up
            </Button>
          </div>
          <div className="Payment-inner-modal-text">
            Withdraw funds to your Venmo account. Instant Transfers – 1.75% per
            transaction ($0.25 minimum and $25 maximum)
          </div>
        </Col>
        <Divider style={{ margin: "0px" }} />
        <Col xs={24}>
          <div className="payment-col-one">
            <img src={payonerLogo} alt="PayPal" />
            <Button
              onClick={() => {
                setPaymentModal((prev) => ({ ...prev, paymentModal: false }));
                setToggleModal((prev) => ({
                  ...prev,
                  payo: { ...prev?.payo, toggle: true },
                }));
              }}
              style={{ borderRadius: "8px" }}
              size="large"
              type="primary"
            >
              Set Up
            </Button>
          </div>
          <div className="Payment-inner-modal-text">
            Withdraw funds to your Payoneer account. 2% Fee
          </div>
        </Col>
        <Divider style={{ margin: "0" }} />
        <div className="payment-cancel-button">
          <Button
            onClick={() =>
              setPaymentModal((prev) => ({ ...prev, paymentModal: false }))
            }
            style={{ borderRadius: "8px" }}
            size="large"
            type="primary"
          >
            Cancel
          </Button>
        </div>
      </Row>
    </div>
  </>
);

export default PaymentModel;
