import { Button, Col, Row } from "antd";

import WarningIcon from "../../../../images/featured-icon.png";

const IsActiveModel = ({
  children,
  handleCancel,
  handleDeactivate,
  description,
  okBtnTitle,
}) => (
  <>
    <div className="add-promo-code">
      <div className="warning-heading">
        <img src={WarningIcon} alt="Warning Icon" />
        <h3 className="fliter-sort-title-add-tab">Warning!</h3>
      </div>
      <Row gutter={[24, 0]}>
        <Col xs={24} className="promocode-warning-para">
          {description}
        </Col>
        {children}
        <Col xs={12}>
          <Button
            onClick={handleCancel}
            style={{ width: "100%", borderRadius: "8px" }}
            size="large"
          >
            Cancel
          </Button>
        </Col>
        <Col xs={12} className="promocode-warning-modal-button">
          <Button
            onClick={handleDeactivate}
            style={{ width: "100%", borderRadius: "8px" }}
            size="large"
          >
            {okBtnTitle || "Deactivate"}
          </Button>
        </Col>
      </Row>
    </div>
  </>
);
export default IsActiveModel;
