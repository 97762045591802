import api from "./api";

export async function updateCustomerDetails(data) {
  const response = await api.request({
    url: "/customer/update-contract-details",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function getDistance(data) {
  const response = await api.request({
    url: "/customer/google-address",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}

export async function updateContractDetails(data) {
  const response = await api.request({
    url: "/customer/updateContractDetails",
    method: "POST",
    data,
  });
  if (response.remote === "success") {
    return {
      remote: response.remote,
      data: response.data,
    };
  }
  return response;
}
