import { Button, message, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getSingedEstimationList } from "../../../../api/estimation";
import { generateInvoices } from "../../../../api/invoice";
import { NumberFormat } from "../../../../utils/numberFormat";
import { capIcon } from "../../../../utils/svg.file";

const cols = [
  {
    title: "Customer Name",
    dataIndex: "customerName",
  },
  {
    title: "Date Signed",
    dataIndex: "signedAt",
    render: (rec) => moment(rec).format("MM/DD/YYYY"),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (rec) => NumberFormat(rec),
  },
];

const ConvertToInvoice = ({
  handleCancel,
  setOpenHowToUse,
  setConvertEstimateToInvoice,
}) => {
  const [estimationList, setEstimationList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const currentPanel = new URLSearchParams(location.search).get("panel");
  const isEstimationSigned = useSelector(
    (state) => state?.lead?.leadInfo?.isEstimationSigned,
  );
  const hasVideoLink = process.env.REACT_APP_INVOICE_DEMO_LINK;
  const getEstimationDetails = async () => {
    const response = await getSingedEstimationList();
    if (response.remote === "success") {
      setEstimationList(
        response?.data?.data?.map((obj) => ({
          ...obj,
          key: obj?.estimationId,
        })),
      );
    }
  };

  const handleNext = async () => {
    const response = await generateInvoices(selectedRows);
    if (response.remote === "success") {
      handleSkip();
    } else {
      message.error(
        response?.errors?.errors?.errors?.message || "Somethings went wrong!",
      );
    }
  };

  const handleSkip = () => {
    handleCancel();
    if (hasVideoLink) {
      setOpenHowToUse(true);
    }
    if (currentPanel === "invoicing" && isEstimationSigned) {
      setConvertEstimateToInvoice(true);
    }
  };
  useEffect(() => {
    getEstimationDetails();
  }, []);

  return (
    <div className="convert-content">
      <div className="mb-3">{capIcon}</div>
      <h2 className="change-title">
        Congratulations! You’re On The Essentials Plan
      </h2>
      <p className="fw-semibold">
        Next, select which customers you want to start invoicing for
      </p>
      <p>
        You can also always begin invoicing for a signed estimate by going to
        that customer and clicking the invoicing tab.
      </p>
      <p>
        For estimates that are signed from today going forward, invoices will be
        generated automatically!
      </p>
      <Table
        columns={cols}
        dataSource={estimationList}
        scroll={{ y: 150 }}
        rowSelection={{
          type: "checkbox",
          hideSelectAll: true,
          onChange: (selectedRowKeys) => {
            setSelectedRows(selectedRowKeys);
          },
          getCheckboxProps: (record) => ({
            name: record?.estimationId,
            value: record?.estimationId,
          }),
        }}
        pagination={false}
        className="userTable mb-5"
      />
      <div className="d-flex gap-3">
        <Button className="clear-filter" block onClick={() => handleSkip()}>
          Skip
        </Button>
        <Button
          className="apply-btn"
          block
          type="primary"
          onClick={() => handleNext()}
          disabled={!estimationList?.length || !selectedRows?.length}
        >
          Generate Invoice
        </Button>
      </div>
    </div>
  );
};

export default ConvertToInvoice;
