import axios from "axios";

import { SERVER_URL as backendUrl } from "../config";

const SERVER_URL = backendUrl;

export const getData = async (api, data) => {
  const result = await axios({
    method: "GET",
    url: `${SERVER_URL}/${api}`,
    headers: {
      accept: "application/json",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data,
  });
  return result;
};

export const postData = async (api, data) => {
  const result = await axios({
    method: "POST",
    url: `${SERVER_URL}/${api}`,
    headers: {
      accept: "application/json",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data,
  });
  return result;
};

export const putData = async (api, data) => {
  try {
    const result = await axios({
      method: "put",
      url: `${SERVER_URL}/${api}`,
      headers: {
        accept: "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data,
    });
    return result;
  } catch (error) {
    return error;
  }
};
