import React from "react";

const Footer = () => (
  <div
    style={{
      width: "100%",
      background: "#101828",
      color: "#98A2B3",
    }}
    className="p-5"
  >
    © Copyright 2023 | ZevBit | All Rights Reserved
  </div>
);

export default Footer;
