/* eslint-disable no-unused-vars */
import { Button, Col, Divider, Form, Input, message, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setPaymentMethodAction } from "../../../../../api/admin";
import paypallogo from "../../../../../images/paypallogo.png";
import { AFFILIATE_TYPES } from "../../../../../redux/affiliate/affiliate.types";
import { payPalFormSchema } from "../../../../../validators/auth/auth.validator";

const PayPalModel = ({
  setToggleModal,
  setPaymentModal,
  setLoader,
  formData,
  errors,
  setErrors,
  isSuperAdmin = false,
  handleCancel,
}) => {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const affiliate = useSelector((state) => state?.affiliate);

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setToggleModal((prev) => ({
      ...prev,
      paypal: { ...prev?.paypal, [name]: value },
    }));
  };
  const handleSubmit = async (e) => {
    e && e?.preventDefault();
    setErrors({});
    const { isValid, errors: formError } = payPalFormSchema(formData);
    if (!isValid) {
      setErrors(formError);
    } else {
      setLoader(true);
      const payload = {
        paymentMethodType: "paypal",
        paypal: {
          email: formData?.email,
          ...(formData?.notes && { note: formData?.notes }),
        },
      };
      const result = await setPaymentMethodAction(
        payload,
        affiliate?.docNPay?.status,
      );
      if (result?.errors) {
        setLoader(false);
        message.error(
          result?.errors?.errors?.error || "Something went wrong",
          10,
        );
      } else if (result?.remote === "success") {
        setLoader(false);
        message.success(result?.data?.message || "", 10);
        setToggleModal((prev) => ({
          ...prev,
          paypal: { toggle: false, email: "", notes: "" },
        }));
        dispatch({
          type: AFFILIATE_TYPES.DOCUMENT_DETAIL,
          payload: {
            ...result?.data?.data,
          },
        });
      }
    }
  };

  return (
    <>
      {" "}
      <div className="add-promo-code cencelnotes mt-4">
        <Row gutter={[0, 24]}>
          {isSuperAdmin && (
            <Col xs={24}>
              <img src={paypallogo} alt="PayPal" />
            </Col>
          )}
          {!isSuperAdmin && (
            <>
              <Col xs={24}>
                <img src={paypallogo} alt="PayPal" />
                <div className="Payment-inner-modal-text">
                  Withdraw funds to your PayPal account. Fee of 3.49% + $0.49
                </div>
              </Col>{" "}
              <Col xs={24} className="paypal-instruction">
                <h4>Instructions</h4>
                <div className="remove-padding">
                  1. Enter the PayPal email address that will receive the funds.
                </div>
              </Col>
              <Divider style={{ margin: "0px" }} />
            </>
          )}
          <Form layout="vertical" onSubmitCapture={handleSubmit}>
            <Col xs={24} className="paypal-payment-info">
              <h3>Payment Info</h3>
            </Col>
            <Row className="paypal-two-inputs-field">
              <Col xs={8} className="paypal-payment-input-name  mb-3">
                PAYPAL EMAIL
              </Col>
              <Col xs={16} className=" mb-3">
                <Input
                  type="text"
                  name="email"
                  value={formData?.email}
                  onChange={handleFormChange}
                  disabled={isSuperAdmin}
                />
                <div role="alert" className="text-danger">
                  {errors?.email}
                </div>
              </Col>
              <Col xs={8} className="paypal-payment-input-name">
                OTHER NOTES
              </Col>
              <Col xs={16}>
                <TextArea
                  rows={4}
                  name="notes"
                  value={formData?.notes || formData?.note}
                  onChange={handleFormChange}
                  disabled={isSuperAdmin}
                />
                <div role="alert" className="text-danger">
                  {errors?.notes}
                </div>
              </Col>
            </Row>
            <Row className="paypal-two-buttons">
              <Row className="paypal-inner-two-buttons" gutter={[20, 20]}>
                <Col xs={12} lg={6}>
                  <Button
                    style={{ width: "100%", borderRadius: "8px" }}
                    size="large"
                    onClick={() => {
                      if (isSuperAdmin) {
                        handleCancel("paypal");
                      } else {
                        setToggleModal((prev) => ({
                          ...prev,
                          paypal: { ...prev?.paypal, toggle: false },
                        }));
                        setPaymentModal((prev) => ({
                          ...prev,
                          paymentModal: true,
                        }));
                        setErrors();
                        setToggleModal((prev) => ({
                          ...prev,
                          paypal: { ...prev?.paypal, email: "", notes: "" },
                        }));
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                {!isSuperAdmin && (
                  <Col lg={8} xs={12} style={{ marginLeft: "0px" }}>
                    <Button
                      style={{ width: "100%", borderRadius: "8px" }}
                      size="large"
                      htmlType="submit"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                )}
              </Row>
            </Row>
          </Form>
        </Row>
      </div>
    </>
  );
};

export default PayPalModel;
