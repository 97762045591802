import {
  getOrganizationDetails,
  updateOrganizationMarkup,
} from "../../api/organization";
import organizationTypes from "./organization.types";

export const getOrganization = () => async (dispatch) => {
  const response = await getOrganizationDetails();
  if (response.remote === "success") {
    dispatch({
      type: organizationTypes.GET_ORGANIZATION,
      payload: response.data,
    });
  }
};

export const updateMarkup = (markup) => async (dispatch) => {
  dispatch({
    type: organizationTypes.UPDATING_MARKUP,
  });
  const response = await updateOrganizationMarkup({ markup });
  if (response.remote === "success") {
    dispatch({
      type: organizationTypes.SUCCESS_MARKUP,
      payload: response.data.data.markup,
    });
  } else {
    dispatch({
      type: organizationTypes.ERROR_MARKUP,
      payload: response.errors,
    });
  }
};

export const setOrganizationQuestions = (questions) => ({
  type: organizationTypes.SET_ORGANIZATION_QUESTIONS,
  payload: questions,
});

export const setOrganizationDefaultQuestions = (questions) => ({
  type: organizationTypes.SET_ORGANIZATION_DEFAULT_QUESTIONS,
  payload: questions,
});

export const updateOrganizationQuestions = (question) => ({
  type: organizationTypes.UPDATE_ORGANIZATION_QUESTIONS,
  payload: question,
});
export const updateOrganizationDefaultQuestions = (question) => ({
  type: organizationTypes.UPDATE_ORGANIZATION_DEFAULT_QUESTIONS,
  payload: question,
});

export const addOrganizationQuestion = (question) => ({
  type: organizationTypes.ADD_ORGANIZATION_QUESTION,
  payload: question,
});
export const addOrganizationDefaultQuestion = (question) => ({
  type: organizationTypes.ADD_ORGANIZATION_DEFAULT_QUESTION,
  payload: question,
});

export const setOrganizationLeadSources = (leadSources) => ({
  type: organizationTypes.SET_LEAD_SOURCES,
  payload: leadSources,
});
