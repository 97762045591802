import { Select, Slider, Typography } from "antd";

const { Option } = Select;

export const tagRender = ({ label }) => (
  <div className="add-comma">{label}</div>
);

export const SelectFilter = ({
  filterTitle,
  value,
  onChange,
  tagRender,
  options = [],
  mode = "",
  placeholder = "Select Option",
  name,
}) => (
  <div className="mt-3">
    <Typography id="select-label">{filterTitle}</Typography>
    <Select
      className="select-phase-2 remove-select-tag"
      size="large"
      aria-label="select-label"
      style={{
        width: "100%",
      }}
      placeholder={placeholder}
      mode={mode}
      value={value}
      onChange={(val) => onChange(val, name)}
      tagRender={tagRender}
    >
      {options.map(({ value, label }) => (
        <Option value={value}>{label}</Option>
      ))}
    </Select>
  </div>
);

export const RangeFilter = ({
  filterTitle,
  needFloat = false,
  prefix = "",
  value = [0, 0],
  onChange,
  name,
}) => (
  <div className="mt-3">
    <Typography id="select-label">{filterTitle}</Typography>
    <Slider
      className="slider"
      range
      tooltip={{
        open: false,
      }}
      {...{ ...(needFloat && { step: 0.01 }) }}
      value={value}
      onChange={(val) => onChange(val, name)}
      marks={value.reduce((acc, val) => {
        acc[val] = `${prefix}${!needFloat ? val : val?.toFixed(2)}`;
        return acc;
      }, {})}
    />
  </div>
);
