import organizationTypes from "./organization.types";

const INITIAL_STATE = {
  markup: {
    initialMarkup: 1,
    jobFarnessMarkup: [],
    clientPickyMarkup: [],
    ditchWitchMarkup: { yes: 0, no: 0 },
    discount: 0,
  },
  isSavingMarkup: false,
  markupError: {},
  scheduleEstimateType: "",
  standardQuestions: [],
  defaultQuestions: [],
  leadSources: [],
};

export default function OrganizationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case organizationTypes.GET_ORGANIZATION:
      localStorage.setItem(
        "roundOff",
        action.payload?.amountSettings?.roundOff,
      );
      return { ...state, ...action.payload };
    case organizationTypes.UPDATING_MARKUP:
      return {
        ...state,
        isSavingMarkup: true,
        markupError: {},
      };
    case organizationTypes.SUCCESS_MARKUP:
      return {
        ...state,
        markup: action.payload,
        isSavingMarkup: false,
      };
    case organizationTypes.ERROR_MARKUP:
      return {
        ...state,
        markupError: action.payload,
        isSavingMarkup: false,
      };
    case organizationTypes.SCHEDULE_ESTIMATE:
      return {
        ...state,
        scheduleEstimateType: action.payload,
      };
    case organizationTypes.SET_ORGANIZATION_QUESTIONS:
      return {
        ...state,
        standardQuestions: [...action.payload],
      };
    case organizationTypes.UPDATE_ORGANIZATION_QUESTIONS:
      return {
        ...state,
        standardQuestions: state.standardQuestions.map((question) => {
          if (question._id === action.payload._id) {
            return action.payload;
          }
          return question;
        }),
      };
    case organizationTypes.ADD_ORGANIZATION_QUESTION:
      return {
        ...state,
        standardQuestions: [action.payload, ...state.standardQuestions],
      };
    case organizationTypes.SET_ORGANIZATION_DEFAULT_QUESTIONS:
      return {
        ...state,
        defaultQuestions: [...action.payload],
      };
    case organizationTypes.UPDATE_ORGANIZATION_DEFAULT_QUESTIONS:
      return {
        ...state,
        defaultQuestions: state.defaultQuestions.map((question) => {
          if (question._id === action.payload._id) {
            return action.payload;
          }
          return question;
        }),
      };
    case organizationTypes.ADD_ORGANIZATION_DEFAULT_QUESTION:
      return {
        ...state,
        defaultQuestions: [action.payload, ...state.defaultQuestions],
      };

    case organizationTypes.SET_LEAD_SOURCES:
      return {
        ...state,
        leadSources: [...action.payload],
      };
    default:
      return state;
  }
}
