import "./content-style.css";

import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Image as AntdImage, message, Switch, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  addCustomerSignatureOnChangeOrderAPI,
  addOrganizationSignatureOnChangeOrderAPI,
  confirmCustomerChangeOrderSignatureAPI,
  updateChangeOrderContractHideOptionAPI,
  updateUserEstimationServices,
  updateUserEstimationServicesFromCustomer,
} from "../../api/estimation";
import {
  updateInvoiceAPI,
  updateInvoiceForCustomerAPI,
} from "../../api/invoice";
import { getEmailTemplatesAction } from "../../redux/invoice/invoice.action";
import { createValidUrl } from "../../utils/commonFuctions";
import { userEstimationStatus } from "../../utils/constants/constants";
import { calculateEstimationOverview } from "../../utils/formula/formula";
import { NumberFormat } from "../../utils/numberFormat";
import CertificateOfCompletion from "../contractpreview/v3/certificateOfCompletion";
import {
  downloadBase64File,
  getBase64OfHTMLDivComponentAsPDF,
} from "../contractpreview/v3/helper";
import { ESignature } from "../estimates/contractPreview/e-signature.component";
import CustomModal from "../invoicing/details/CustomModal";
import SendCustomerModal from "../invoicing/details/SendCustomerModal";
import {
  invoiceEmailCategory,
  renderStatus,
  renderTableAndFooter,
} from "../invoicing/helper";
import { BackBtn } from "../invoicing/svg.icons";
import SmallLoader from "../loader/smallLoader";

const SIGNATURE_FOR = Object.freeze({
  CONTRACTOR: "Contractor",
  CUSTOMER: "Customer",
});

function Content({
  preparingUserDetails,
  selectedServices,
  projectSummaryData,
  totalChangedOrderWithoutTax,
  totalChangedTax,
  estimationDetails,
  invoices,
  estimationId,
  changeOrderSentId,
  selectedServicesIds,
  getEstimateDetails,
  signedByEmployeeId,
  token,
  actualServicesId,
  loading,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [columns, setColumns] = useState([
    {
      title: "Description",
      dataIndex: "invoiceName",
    },
    {
      title: "Invoice#",
      dataIndex: "invoiceNumber",
      render: (num) => num || "-",
    },
    {
      title: "Status",
      dataIndex: "invoiceStatus",
      render: (rec) => renderStatus(rec),
    },
    {
      title: "Due Date",
      dataIndex: "invoiceDate",
    },
    {
      title: "Old Balance",
      dataIndex: "currentBalance",
      render: (balance) => NumberFormat(balance),
    },
    {
      title: "New Balance",
      dataIndex: "newBalance",
      render: (balance) => NumberFormat(balance),
    },
  ]);

  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [hideInvOrSummary, setHideInvOrSummary] = useState({
    summary: true,
    invoice: true,
  });
  const [sendingEmail] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [eSignatureOpenFor, setESignatureOpenFor] = useState("");
  const [tempOrgSignatures, setTempOrgSignatures] = useState("");
  const [tempCustomerSignatures, setTempCustomerSignatures] = useState("");
  const [loadingHide, setLoadingHide] = useState("");
  useEffect(() => {
    if (estimationDetails) {
      setHideInvOrSummary({
        summary: !!estimationDetails?.changeOrderDetails?.hideSummary,
        invoice: !!estimationDetails?.changeOrderDetails?.hideInvoices,
      });
    }
  }, [estimationDetails]);
  const [files, setFiles] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [validFileError, setValidFileError] = useState(false);

  useEffect(() => {
    if (invoices) {
      let isNew = false;
      let isDiff = false;
      invoices.forEach((inv) => {
        if (!inv.details) {
          isNew = true;
        }
        if (inv.details && inv.difference) {
          isDiff = true;
        }
      });
      if (isNew && !isDiff) {
        setColumns((prevState) =>
          prevState.filter((st) => st.dataIndex !== "currentBalance"),
        );
      }
    }
  }, [invoices.length]);

  const handleDownloadPdf = async () => {
    setGeneratingPdf(true);
    setTimeout(async () => {
      const base64 = await getBase64OfHTMLDivComponentAsPDF(
        "divToPrint",
        "certificateOfCompletion",
      );
      downloadBase64File(base64, "FileName");
      setGeneratingPdf(false);
    }, 1000);
  };

  const handleSignatureByOrganization = async (
    signature,
    fullName,
    signatureStyle,
  ) => {
    message.loading({
      key: "savingOrganizationSignature",
      content: "Signing Contract...",
      duration: 0,
    });
    try {
      setGeneratingPdf(true);
      const pdfBase64 = await getBase64OfHTMLDivComponentAsPDF(
        "divToPrint",
        "certificateOfCompletion",
      );
      setGeneratingPdf(false);
      const data = {
        signature,
        name: fullName,
        signatureStyle,
        contractPdf: pdfBase64,
      };
      const res = await addOrganizationSignatureOnChangeOrderAPI({
        estimationId,
        sentChangeOrderId: changeOrderSentId,
        data,
      });
      if (res.remote === "success") {
        await getEstimateDetails({
          estimationId,
          changeOrderSentId,
          selectedServicesIds,
        });
        message.success({
          key: "savingOrganizationSignature",
          content: "Signed Successfully",
          duration: 2,
        });
      } else {
        message.error({
          key: "savingOrganizationSignature",
          content: "Something went wrong",
          duration: 2,
        });
      }
    } catch (error) {
      console.log({ error });
      message.error({
        key: "savingOrganizationSignature",
        content: "Error Generating PDF",
        duration: 2,
      });
    } finally {
      setTempOrgSignatures("");
    }
  };
  const handleSignatureByCustomer = async (
    signature,
    fullName,
    signatureStyle,
  ) => {
    message.loading({
      key: "savingCustomerSignature",
      content: "Signing Contract...",
      duration: 0,
    });
    try {
      const data = {
        signature,
        name: fullName,
        signatureStyle,
      };
      if (signedByEmployeeId) {
        data.signedByEmployeeId = signedByEmployeeId;
      }
      const res = await addCustomerSignatureOnChangeOrderAPI({
        estimationId,
        sentChangeOrderId: changeOrderSentId,
        data,
      });
      if (res.remote === "success") {
        message.loading({
          key: "savingCustomerSignature",
          content: "Updating Estimate...",
          duration: 0,
        });
        // update signedEstimationOverview
        const servicesUpdated = estimationDetails.services?.map((service) => {
          if (selectedServicesIds.includes(service.draggableId)) {
            service.lastAction = userEstimationStatus.signed;
            service.actualServices[0].status = userEstimationStatus.signed;
            service.actualServices[0].signedAt = new Date().toISOString();
          }
          return service;
        });
        const updatedEstimationOverview = calculateEstimationOverview({
          services: servicesUpdated,
          tax: estimationDetails.tax.percentage,
          taxStatus: estimationDetails.tax.status,
          onlySigned: true,
        });

        const updateEstimationPayload = {
          estimationId,
          updatedServices: servicesUpdated,
          updatedEstimationOverview,
        };
        let updatedEstimation;
        if (token) {
          updatedEstimation = await updateUserEstimationServicesFromCustomer({
            estimationId,
            payload: updateEstimationPayload,
            token,
          });
        } else {
          updatedEstimation = await updateUserEstimationServices({
            estimationId,
            payload: updateEstimationPayload,
          });
        }
        if (updatedEstimation.remote === "success") {
          message.loading({
            key: "savingCustomerSignature",
            content: "Updating Invoices...",
            duration: 0,
          });
          // update invoices
          const newAddedInvoices = invoices.filter(
            (invoice) => !invoice.details,
          );
          const updatedInvoices = invoices.filter(
            (invoice) =>
              (invoice.currentBalance !== invoice.newBalance ||
                invoice.isInvoiceDateChanged) &&
              invoice.details,
          );
          const invoicePayload = {
            estimationId,
            entrySource: "changeOrder",
            newInvoices: newAddedInvoices.map((invoice) => {
              const inv = {
                name: invoice.invoiceName,
                amount: invoice.newBalance,
              };
              if (invoice.isInvoiceDateChanged) {
                inv.invoiceDate = invoice.invoiceISODate;
                inv.dueDate = invoice.dueDate;
                inv.terms = invoice.terms;
              }
              return inv;
            }),
            updatedInvoices: updatedInvoices.map((invoice) => {
              const inv = {
                invoiceId: invoice.details._id,
                name: invoice.details.invoiceName,
                amount: invoice.newBalance,
              };
              if (invoice.isInvoiceDateChanged) {
                inv.invoiceDate = invoice.invoiceISODate;
                inv.dueDate = invoice.dueDate;
                inv.terms = invoice.terms;
              }
              return inv;
            }),
          };
          console.log({ invoicePayload });
          if (
            invoicePayload.newInvoices.length ||
            invoicePayload.updatedInvoices.length
          ) {
            if (token) {
              await updateInvoiceForCustomerAPI(invoicePayload, token);
            } else {
              await updateInvoiceAPI(invoicePayload);
            }
          }
        }

        await getEstimateDetails({
          estimationId,
          changeOrderSentId,
          selectedServicesIds,
        });

        message.loading({
          key: "savingCustomerSignature",
          content: "Confirming Customer Signature...",
          duration: 0,
        });

        setGeneratingPdf(true);
        const pdfBase64 = await getBase64OfHTMLDivComponentAsPDF(
          "divToPrint",
          "certificateOfCompletion",
        );
        setGeneratingPdf(false);
        const confirm = await confirmCustomerChangeOrderSignatureAPI({
          estimationId,
          sentChangeOrderId: changeOrderSentId,
          data: { contractPdf: pdfBase64 },
        });

        if (confirm.remote === "success") {
          message.success({
            key: "savingCustomerSignature",
            content: "Signed Successfully",
            duration: 2,
          });
        } else {
          message.warning({
            key: "savingCustomerSignature",
            content:
              "Change Order Signed successfully, but error confirming it",
            duration: 8,
          });
        }
      } else {
        message.error({
          key: "savingCustomerSignature",
          content:
            "Error Signing Change Order! Either Change Order is updated or not signed by organization",
          duration: 8,
        });
      }
    } catch (error) {
      console.log({ error });
      message.error({
        key: "savingCustomerSignature",
        content: "Error Generating PDF",
        duration: 2,
      });
    } finally {
      setTempCustomerSignatures("");
    }
  };

  const onSignatureSave = (e, title, fullName, signatureStyle) => {
    switch (eSignatureOpenFor) {
      case SIGNATURE_FOR.CONTRACTOR:
        if (e) {
          setTempOrgSignatures(e);
          setTimeout(() => {
            handleSignatureByOrganization(e, fullName, signatureStyle);
          }, 500);
        }
        break;
      case SIGNATURE_FOR.CUSTOMER:
        if (e) {
          setTempCustomerSignatures(e);
          setTimeout(() => {
            handleSignatureByCustomer(e, fullName, signatureStyle);
          }, 500);
        }
        break;
      default:
        break;
    }
    setESignatureOpenFor("");
  };
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (!token) {
      dispatch(getEmailTemplatesAction(invoiceEmailCategory.changeOrder));
    }
  }, []);

  const handleHideTableChange = async (field, value) => {
    setLoadingHide(field);
    const res = await updateChangeOrderContractHideOptionAPI({
      data: {
        hideInvoice: field === "invoice" ? value : hideInvOrSummary.invoice,
        hideSummary: field === "summary" ? value : hideInvOrSummary.summary,
      },
      estimationId,
      changeOrderId: changeOrderSentId,
    });
    if (res.remote === "success") {
      setHideInvOrSummary((prevState) => ({ ...prevState, [field]: value }));
      setLoadingHide("");
    }
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minHeight: "100vh",
            minWidth: "100vw",
          }}
        >
          <SmallLoader />
        </div>
      ) : (
        <>
          <div style={{ padding: "0px 5px" }}>
            {!token ? (
              <>
                <Button className="clear-filter" onClick={() => handleBack()}>
                  {BackBtn}
                  <span className="ms-2">Back</span>
                </Button>
              </>
            ) : (
              <></>
            )}

            {token ? (
              <div className="mx-973">
                <Button
                  type="primary"
                  onClick={handleDownloadPdf}
                  disabled={generatingPdf}
                  className="my-2 download-pdf-btn"
                  icon={
                    <>
                      {generatingPdf && <SmallLoader />}
                      <DownloadOutlined />
                    </>
                  }
                >
                  Download
                </Button>
              </div>
            ) : (
              <div
                className="preview-card"
                style={{ display: "table", width: "100%", boxShadow: "none" }}
              >
                <div className="preview-card mx-973">
                  <Button
                    type="primary"
                    className="my-2 download-pdf-btn"
                    onClick={() => {
                      setSendEmail(true);
                    }}
                    disabled={
                      generatingPdf ||
                      estimationDetails.changeOrderDetails?.signedByClient
                        ?._id ||
                      !estimationDetails.changeOrderDetails
                        ?.signedByOrganization?._id
                    }
                  >
                    Send Change Order to Customer
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div
            id="divToPrint"
            style={{
              width: "100%",
              boxShadow: "none",
              padding: "16px",
              float: "left",
            }}
            className="preview-card "
          >
            {estimationDetails.changeOrderDetails?.coverPhoto ||
            estimationDetails.organization?.estimationCoverPhoto ? (
              <img
                style={{ borderRadius: "25px", width: "100%" }}
                src={createValidUrl(
                  estimationDetails.changeOrderDetails?.coverPhoto ||
                    estimationDetails.organization?.estimationCoverPhoto,
                )}
                alt="coverPhoto"
              />
            ) : (
              ""
            )}
            <div
              style={{ textAlign: "right", padding: "15px 24px 0px" }}
              className="preview-title"
            >
              <h5
                style={{ margin: "0px", fontSize: "18px", fontWeight: "600" }}
              >
                {estimationDetails.organization?.name}
              </h5>
              <span>Sales: {preparingUserDetails.name}</span>
            </div>
            <div
              style={{
                width: "100%",
                float: "left",
                padding: "10px 24px 0px",
              }}
              className={generatingPdf ? "" : "preview-1"}
            >
              <div
                style={{ width: "400px", float: "left" }}
                className={generatingPdf ? "" : "preview-2"}
              >
                <span style={{ fontSize: "12px" }}>Estimation Number: </span>
                <h5
                  style={{
                    margin: "0px 0px 15px 0px",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  {estimationDetails.estimationNumber}
                </h5>
                <span style={{ fontSize: "12px" }}>Prepared For: </span>
                <h5
                  style={{
                    margin: "0px 0px 15px 0px",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  {estimationDetails.customer?.fullName}
                </h5>
                {estimationDetails.customer?.address ? (
                  <>
                    <span style={{ fontSize: "12px" }}>Located At: </span>
                    <h5
                      style={{
                        margin: "0px 0px 0px 0px",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      {estimationDetails.customer?.address}
                    </h5>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{ width: "234px", float: "right" }}
                className={generatingPdf ? "" : "preview-3"}
              >
                {estimationDetails.organization?.logo && (
                  <img
                    style={{ maxWidth: "100%" }}
                    src={createValidUrl(estimationDetails.organization?.logo)}
                    alt="companyLogo"
                  />
                )}
              </div>
            </div>
            <div
              style={{ padding: "0px 24px", float: "left", width: "100%" }}
              className={generatingPdf ? "" : "preview-4"}
            >
              <hr style={{ marginBottom: "0px" }} />
            </div>
            <div
              style={{
                width: "100%",
                float: "left",
                padding: "15px 25px 0",
              }}
              className={generatingPdf ? "" : "preview-5"}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: estimationDetails.organization?.coverNote || "",
                }}
              />
            </div>
            <div
              style={{ padding: "0px 24px", float: "left", width: "100%" }}
              className={generatingPdf ? "" : "preview-6"}
            >
              <hr style={{ marginBottom: "0px" }} />
            </div>
            <div
              style={{ width: "100%", float: "left", padding: "24px" }}
              className={generatingPdf ? "" : "preview-7"}
            >
              <h5
                style={{
                  margin: "0px 0px 15px 0px",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Change Order
              </h5>
              {selectedServices.map((item, index) => (
                <div
                  style={{
                    width: "100%",
                    float: "left",
                    background: "#e8f1fd",
                    borderRadius: "25px",
                    marginBottom: "15px",
                  }}
                  className={generatingPdf ? "" : "preview-8"}
                  key={index}
                >
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      background: "#3483fa",
                      borderRadius: "25px",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "75px",
                    }}
                    className={generatingPdf ? "" : "preview-9"}
                  >
                    <>
                      {item.photo ? (
                        <AntdImage
                          style={{
                            height: "95px",
                            width: "150px",
                          }}
                          src={createValidUrl(item.photo)}
                          alt={item.title}
                        />
                      ) : (
                        ""
                      )}
                    </>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingRight: "15px",
                        paddingLeft: "25px",
                        width: "100%",
                      }}
                      className={generatingPdf ? "" : "preview-10"}
                    >
                      <h5
                        style={{
                          fontSize: "24px",
                          color: "#fff",
                          margin: "0",
                        }}
                      >
                        {item.actualServices[0].changeOrderTitle ||
                          item.displayName ||
                          item.title}
                      </h5>
                      <h5
                        style={{
                          fontSize: "24px",
                          color: "#fff",
                          margin: "0",
                        }}
                      >
                        {NumberFormat(
                          item.actualServices[0].changedPriceBreakdown
                            .totalServiceChargeWithTax,
                        )}
                      </h5>
                    </div>
                  </div>
                  <div
                    style={{ width: "100%", float: "left", padding: "15px" }}
                    className={generatingPdf ? "" : "preview-11"}
                  >
                    {item.actualServices.length === 1 &&
                    item.actualServices[0].type !== "original" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.processedClientContract?.replaceAll(
                            "\n",
                            "<br>",
                          ),
                        }}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            item.actualServices[0].clientDescription?.replaceAll(
                              "\n",
                              "<br>",
                            ),
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
              <div
                style={{ padding: "0px 24px", float: "left", width: "100%" }}
                className={generatingPdf ? "" : "preview-6"}
              >
                <hr style={{ marginBottom: "0px" }} />
              </div>
              <div
                className={`text-right mt-4 pb-4 pe-lg-3 ${
                  ""
                  //   generatingPdf ? "" : "preview-12"
                }`}
                style={{ float: "left", width: "100%" }}
              >
                <ul className="list-inline">
                  <li>
                    <b>Subtotal</b> <abbr>:</abbr>
                    <div className="righttotal">
                      {NumberFormat(totalChangedOrderWithoutTax)}
                    </div>
                  </li>
                  {estimationDetails.tax?.status &&
                  estimationDetails.tax?.percentage > 0 ? (
                    <li>
                      <b>Tax &nbsp; {estimationDetails.tax?.percentage}%</b>
                      <abbr>:</abbr>
                      <div className="righttotal">
                        {NumberFormat(totalChangedTax)}
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <b>Change Order Total</b>
                    <abbr>:</abbr>
                    <div className="righttotal">
                      {NumberFormat(
                        estimationDetails.changeOrderDetails?.changedPrice,
                      )}
                    </div>
                  </li>
                </ul>
              </div>
              <div
                style={{
                  padding: "0px 24px",
                  float: "left",
                  width: "100%",
                  marginBottom: "25px",
                }}
                className={generatingPdf ? "" : "preview-6"}
              >
                <hr style={{ marginBottom: "0px" }} />
              </div>

              <div
                style={{
                  display:
                    generatingPdf && hideInvOrSummary.summary ? "none" : "",
                }}
              >
                {token && hideInvOrSummary.summary
                  ? ""
                  : renderTableAndFooter({
                      redirectFrom: invoiceEmailCategory.projectSummary,
                      tableData: {
                        projectSummaryTable: projectSummaryData.map((data) => {
                          if (actualServicesId?.includes(data.uniqueId)) {
                            data.description = {
                              ...data.description,
                              description: "",
                            };
                            data.date = new Date().toISOString();
                            return {
                              ...data,
                            };
                          }
                          return data;
                        }),
                      },
                      handleHideTable: (e) =>
                        handleHideTableChange("summary", !e),
                      isHidden: hideInvOrSummary.summary,
                      showToggle: !token,
                      loading: loadingHide === "summary",
                    })}
              </div>
              <div
                style={{
                  display:
                    (generatingPdf && hideInvOrSummary.invoice) ||
                    (hideInvOrSummary.invoice && token)
                      ? "none"
                      : "",
                }}
              >
                <div
                  style={{
                    margin: "35px 0px 15px 0px",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  <h5 className="float-start">Remaining Payments</h5>
                  {!token ? (
                    <Switch
                      checked={!hideInvOrSummary.invoice}
                      onChange={(e) => handleHideTableChange("invoice", !e)}
                      disabled={loadingHide === "invoice"}
                    />
                  ) : (
                    ""
                  )}
                </div>

                {hideInvOrSummary.invoice ? (
                  ""
                ) : (
                  <div>
                    <Table
                      columns={columns}
                      dataSource={invoices}
                      pagination={false}
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              style={{ float: "left", width: "100%", marginTop: "15px" }}
              className={generatingPdf ? "" : "preview-15"}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                  flexWrap: "wrap",
                }}
                className={generatingPdf ? "" : "preview-16"}
              >
                <div
                  style={{ width: "45%", float: "left" }}
                  className={generatingPdf ? "" : "preview-17"}
                >
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Customer Signature
                  </label>
                  <div id="div_2">
                    {tempCustomerSignatures ||
                    estimationDetails.changeOrderDetails?.signedByClient
                      ?.signature ? (
                      <img
                        src={
                          tempCustomerSignatures ||
                          createValidUrl(
                            estimationDetails.changeOrderDetails?.signedByClient
                              ?.signature,
                          )
                        }
                        alt="CustomerSignature"
                        style={{ height: 50 }}
                      />
                    ) : generatingPdf ? (
                      <div style={{ height: 50 }} />
                    ) : (
                      <Button
                        type="primary"
                        className="add-btn"
                        size="large"
                        disabled={
                          !estimationDetails.changeOrderDetails
                            ?.signedByOrganization?.signature
                        }
                        onClick={async () => {
                          setESignatureOpenFor(SIGNATURE_FOR.CUSTOMER);
                        }}
                      >
                        Add Customer Signature
                      </Button>
                    )}
                    <div
                      style={{
                        border: "0px",
                        width: "100%",
                        borderBottom: "1px solid #ddd",
                        color: "#000",
                        outline: "none",
                        marginTop: "5px",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{ width: "45%", float: "right" }}
                  className={generatingPdf ? "" : "preview-18"}
                >
                  <label style={{ display: "flex", marginBottom: "5px" }}>
                    Contractor Signature
                    {!generatingPdf &&
                      estimationDetails.changeOrderDetails?.signedByOrganization
                        ?.signature &&
                      !estimationDetails.changeOrderDetails?.signedByClient
                        ?.signature && (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setESignatureOpenFor(SIGNATURE_FOR.CONTRACTOR)
                          }
                        >
                          <EditOutlined />
                        </div>
                      )}
                  </label>
                  <div id="div_2">
                    {tempOrgSignatures ||
                    estimationDetails.changeOrderDetails?.signedByOrganization
                      ?.signature ? (
                      <img
                        src={
                          tempOrgSignatures ||
                          createValidUrl(
                            estimationDetails.changeOrderDetails
                              ?.signedByOrganization?.signature,
                          )
                        }
                        alt="ContractorSignature"
                        style={{ height: 50 }}
                      />
                    ) : generatingPdf ? (
                      <div style={{ height: 50 }} />
                    ) : (
                      <Button
                        type="primary"
                        className="add-btn"
                        size="large"
                        onClick={() =>
                          setESignatureOpenFor(SIGNATURE_FOR.CONTRACTOR)
                        }
                        // disabled={!editable}
                      >
                        Add Contractor Signature
                      </Button>
                    )}
                    {/* <div style={{ height: 50 }} /> */}
                    <div
                      style={{
                        border: "0px",
                        width: "100%",
                        borderBottom: "1px solid #ddd",
                        color: "#000",
                        outline: "none",
                        marginTop: "5px",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{ width: "45%", float: "left" }}
                  className={generatingPdf ? "" : "preview-19"}
                >
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Customer Name
                  </label>
                  <input
                    type="text"
                    style={{
                      border: "0px",
                      borderBottom: "1px solid #ddd",
                      width: "100%",
                      color: "#000",
                      outline: "none",
                    }}
                    value={
                      estimationDetails.changeOrderDetails?.signedByClient?.name
                    }
                  />
                </div>
                <div
                  style={{ width: "45%", float: "right" }}
                  className={generatingPdf ? "" : "preview-20"}
                >
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Company
                  </label>
                  <p
                    type="text"
                    style={{
                      border: "0px",
                      borderBottom: "1px solid #ddd",
                      width: "100%",
                      color: "#000",
                      outline: "none",
                      height: "26.8px",
                    }}
                  >
                    {estimationDetails.organization?.name}
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{ width: "45%", float: "left" }}
                  className={generatingPdf ? "" : "preview-20"}
                >
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Date
                  </label>
                  <p
                    type="text"
                    style={{
                      border: "0px",
                      borderBottom: "1px solid #ddd",
                      width: "100%",
                      color: "#000",
                      outline: "none",
                      height: "26.8px",
                    }}
                  >
                    {estimationDetails.changeOrderDetails?.signedByClient?.date
                      ? moment
                          .utc(
                            estimationDetails.changeOrderDetails?.signedByClient
                              ?.date,
                          )
                          .local()
                          .format("MM/DD/YYYY")
                      : ""}
                  </p>
                </div>
                <div
                  style={{ width: "45%", float: "right" }}
                  className={generatingPdf ? "" : "preview-21"}
                >
                  <label style={{ display: "block", marginBottom: "5px" }}>
                    Date
                  </label>
                  <p
                    type="text"
                    style={{
                      border: "0px",
                      borderBottom: "1px solid #ddd",
                      width: "100%",
                      color: "#000",
                      outline: "none",
                      height: "26.8px",
                    }}
                  >
                    {estimationDetails.changeOrderDetails?.signedByOrganization
                      ?.date
                      ? moment
                          .utc(
                            estimationDetails.changeOrderDetails
                              ?.signedByOrganization?.date,
                          )
                          .local()
                          .format("MM/DD/YYYY")
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {estimationDetails.changeOrderDetails?.signedByClient && (
            <CertificateOfCompletion
              estimationDetails={estimationDetails.changeOrderDetails || {}}
            />
          )}

          {!token && (
            <div
              className="ant-floating"
              style={{ zIndex: 999, display: "flex", flexDirection: "column" }}
            >
              <Button
                type="primary"
                onClick={handleDownloadPdf}
                disabled={generatingPdf}
              >
                {generatingPdf ? <SmallLoader /> : <DownloadOutlined />}
              </Button>
            </div>
          )}
        </>
      )}
      {!token && sendEmail ? (
        <CustomModal
          isModalOpen={sendEmail}
          handleCancel={() => {
            setFiles(null);
            setValidFileError(false);
            setFileSizeError(false);
            if (!sendingEmail) setSendEmail(false);
          }}
          closable={false}
          centered
          width={800}
        >
          <SendCustomerModal
            changeOrderSentId={changeOrderSentId}
            selectedServicesIds={selectedServicesIds}
            actualServicesId={actualServicesId}
            action={invoiceEmailCategory.changeOrder}
            defaultEmail={estimationDetails?.customer?.email || ""}
            handleCancel={() => {
              setFiles(null);
              setValidFileError(false);
              setFileSizeError(false);
              if (!sendingEmail) setSendEmail(false);
            }}
            files={files}
            setFiles={setFiles}
            setFileSizeError={setFileSizeError}
            fileSizeError={fileSizeError}
            setValidFileError={setValidFileError}
            validFileError={validFileError}
          />
        </CustomModal>
      ) : (
        ""
      )}
      {eSignatureOpenFor && (
        <ESignature
          isOpen={!!eSignatureOpenFor}
          onClose={() => setESignatureOpenFor("")}
          onSave={onSignatureSave}
          title={eSignatureOpenFor}
          requiredFullName
          // organizationSignature={organizationLogoBase64}
          contractorName={preparingUserDetails.name}
          isDefault={eSignatureOpenFor === SIGNATURE_FOR.CONTRACTOR}
        />
      )}
    </>
  );
}

export default Content;
