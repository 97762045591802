import { Button, Col, Row } from "antd";
import React from "react";

import WarningIcon from "../../../../images/featured-icon.png";

const WarningModal = ({
  children,
  description,
  handleCancel,
  handleDeactivate,
  okBtnTitle,
}) => (
  <>
    <div>
      <div className="warning-heading">
        <img src={WarningIcon} alt="Warning Icon" />
        <h3 className="fliter-sort-title-add-tab">Warning!</h3>
      </div>
      <Row gutter={[24, 0]}>
        <Col xs={24} className="warning-note">
          {description}
        </Col>
        <Col xs={24} className="mb-4">
          {children}
        </Col>
        <Col xs={12} className="warning-modal-button">
          <Button
            onClick={handleCancel}
            style={{ width: "100%", borderRadius: "8px" }}
            size="large"
          >
            Cancel
          </Button>
        </Col>
        <Col xs={12}>
          <Button
            onClick={handleDeactivate}
            style={{ width: "100%", borderRadius: "8px" }}
            size="large"
            danger
            type="primary"
          >
            {okBtnTitle}
          </Button>
        </Col>
      </Row>
    </div>
  </>
);

export default WarningModal;
