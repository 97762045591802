import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, message, Progress, Row, Typography, Upload } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { handleUploadFormAction } from "../../../../../api/admin";
import UploadFileIcon from "../../../../../images/File upload icon.png";
import { AFFILIATE_TYPES } from "../../../../../redux/affiliate/affiliate.types";
import { fileExtension } from "../../../../../utils/constants/constants";
import { uploadAttachment } from "../../../../../utils/svg.file";

function formatFileSize(bytes) {
  if (bytes < 1024) {
    return `${bytes} bytes`;
  }
  if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(2)} KB`;
  }
  return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
}

const UploadFormCard = ({
  formData,
  setFormData,
  setLoader,
  setIsFormDeleted,
  setFormModal,
}) => {
  const { Dragger } = Upload;
  const { Text } = Typography;
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const checkFileSize = (file) => {
    const maxSize = 100 * 1024 * 1024;
    if (file.size > maxSize) {
      message.error("File size must be less than 100MB");
      setFormData((prev) => ({ ...prev, w9Form: null }));
      return false;
    }
    return true;
  };

  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    beforeUpload: checkFileSize,
    customRequest: async ({ file, onSuccess, onError }) => {
      try {
        const formData = new FormData();
        formData.append("file", file);
        if (fileExtension.includes(file?.type)) {
          setError("");
          setFormData((prev) => ({ ...prev, w9Form: file }));
          const total = 100;
          let uploaded = 0;
          const randomInterval = Math.floor(Math.random() * (100 - 50) + 50);
          const interval = setInterval(() => {
            const increment = Math.floor(Math.random() * 10) + 1;
            uploaded += increment;
            if (uploaded > total) {
              uploaded = total;
            }
            setUploadProgress(Math.floor((uploaded / total) * 100));
            if (uploaded >= total) {
              clearInterval(interval);
              onSuccess();
            }
          }, randomInterval);
        } else {
          setError("Invalid file selected");
        }
      } catch (error) {
        onError(error);
      }
    },
  };

  const handleFormUpload = async () => {
    setLoader(true);
    const data = new FormData();
    data.append("w9Form", formData?.w9Form);
    const payload = data;
    const result = await handleUploadFormAction(payload);
    if (result?.remote === "success") {
      setLoader(false);
      dispatch({
        type: AFFILIATE_TYPES.DOCUMENT_DETAIL,
        payload: {
          ...result?.data?.data,
        },
      });
      setIsFormDeleted(false);
    } else {
      setLoader(false);
    }
  };

  return (
    <div>
      <div className="add-promo-code cencelnotes">
        <Row gutter={[0, 20]}>
          <Col xs={24}>
            <div className="upload-w9Form-para pb-0 mt-2">
              Please upload a PDF or photo of the signed W9 form to continue
              with your verification.
            </div>
          </Col>
          <Col xs={24} className="upload-w9Form-input">
            <Dragger
              {...props}
              accept=".pdf, .png, .jpg, .jpeg, .PNG, .JPG, .JPEG, .PDF"
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon mb-0">{uploadAttachment}</p>
              <p className="ant-upload-text upload-attachment-text m-0 ant-upload-hint">
                <b>Click to upload</b> or
              </p>
              <p className="ant-upload-hint upload-attachment-text m-0">
                <small>PDF, JPG, PNG, JPEG (max. 100MB)</small>
              </p>
            </Dragger>
          </Col>
          {error && (
            <Text
              className="text-danger d-block"
              style={{ fontSize: "14px", padding: "5px 12px 0px" }}
            >
              {error}
            </Text>
          )}
          {formData?.w9Form?.name && (
            <Col xs={24}>
              <div className="upload-w9Form-progressBar">
                <div className="progressBar-inner-forFlex">
                  <div className="inner-progressBar">
                    <div className="img">
                      <img src={UploadFileIcon} alt="File Icons" />
                    </div>
                    <div className="fileName">
                      <div className="photoName">{formData?.w9Form?.name}</div>
                      <div className="photoSize">
                        {formatFileSize(formData?.w9Form?.size)}
                      </div>
                      {uploadProgress > 0 && (
                        <Progress percent={uploadProgress} />
                      )}
                    </div>
                  </div>
                  <DeleteOutlined
                    onClick={() => {
                      setFormData((prev) => ({ ...prev, w9Form: null }));
                      setUploadProgress(0);
                    }}
                  />
                </div>
              </div>
            </Col>
          )}
          <Row className="upload-w9Form-two-buttons">
            <Col xs={10}>
              <Button
                onClick={() => {
                  setFormModal((prev) => ({ ...prev, w9FormModal: false }));
                  setFormData((prev) => ({ ...prev, w9Form: null }));
                  setError("");
                }}
                style={{ width: "100%", borderRadius: "8px" }}
                size="large"
                type="primary"
              >
                Cancel
              </Button>
            </Col>
            <Col xs={10}>
              <Button
                onClick={() => {
                  handleFormUpload();
                  setFormModal((prev) => ({ ...prev, w9FormModal: false }));
                }}
                style={{ width: "100%", borderRadius: "8px" }}
                size="large"
                type="primary"
                disabled={!formData?.w9Form?.name}
              >
                Upload
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default UploadFormCard;
