import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { getRedirectionURL } from "./util";

const AffiliateProtectedRoute = ({ children }) => {
  const affiliateToken =
    localStorage.getItem("affiliateToken") ||
    sessionStorage.getItem("affiliateToken");
  const location = useLocation();
  const { user, profile } = useSelector((state) => state?.affiliate);
  const history = useNavigate();

  useEffect(() => {
    if (affiliateToken) {
      if (Object.keys(user)?.length || Object.keys(profile)?.length) {
        const existing = user?.existingEmail || profile?.existingEmail;
        const hasExisting =
          user?.isFilledExistingEmail || profile?.isFilledExistingEmail;
        const hadSurvey = user?.isFilledSurvey || profile?.isFilledSurvey;
        const { redirectUrl, state } = getRedirectionURL({
          existing,
          hasExisting,
          hadSurvey,
        });
        history(redirectUrl, { state });
      } else {
        history("/affiliate/profile");
      }
    }
  }, [user, profile]);

  if (!affiliateToken) {
    return <Navigate to="/affiliate" state={{ from: location }} />;
  }

  return children;
};

export default AffiliateProtectedRoute;
