import { Button, Col, Divider, Form, Input, message, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setPaymentMethodAction } from "../../../../../api/admin";
import venmoLogo from "../../../../../images/venmoLogo.png";
import { AFFILIATE_TYPES } from "../../../../../redux/affiliate/affiliate.types";
import { venmoFormSchema } from "../../../../../validators/auth/auth.validator";

const VenmoModel = ({
  setToggleModal,
  setPaymentModal,
  setLoader,
  formData,
  errors,
  setErrors,
  isSuperAdmin = false,
  handleCancel,
}) => {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const affiliate = useSelector((state) => state?.affiliate);
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setToggleModal((prev) => ({
      ...prev,
      venmo: { ...prev?.venmo, [name]: value },
    }));
  };
  const handleSubmit = async (e) => {
    e && e?.preventDefault();
    setErrors({});
    const { isValid, errors: formError } = venmoFormSchema(formData);
    if (!isValid) {
      setErrors(formError);
    } else {
      const payload = {
        paymentMethodType: "venmo",
        venmo: {
          userName: formData?.userName,
          phoneNumberLast4: formData?.phone,
          ...(formData?.notes && { note: formData?.notes }),
        },
      };
      const result = await setPaymentMethodAction(
        payload,
        affiliate?.docNPay?.status,
      );
      if (result?.errors) {
        setLoader(false);
        message.error(
          result?.errors?.errors?.error || "Something went wrong",
          10,
        );
      } else if (result?.remote === "success") {
        setLoader(false);
        message.success(result?.data?.message || "", 10);
        setToggleModal((prev) => ({
          ...prev,
          venmo: {
            toggle: false,
            userName: "",
            phone: "",
            notes: "",
          },
        }));
        dispatch({
          type: AFFILIATE_TYPES.DOCUMENT_DETAIL,
          payload: {
            ...result?.data?.data,
          },
        });
      }
    }
  };
  return (
    <>
      <div className="add-promo-code cencelnotes mt-4">
        <Row gutter={[0, 24]}>
          {isSuperAdmin && (
            <Col xs={24}>
              <img src={venmoLogo} alt="Venmo" className="" />
            </Col>
          )}
          {!isSuperAdmin && (
            <>
              <Col xs={24}>
                <img src={venmoLogo} alt="Venmo" />
                <div className="Payment-inner-modal-text">
                  Withdraw funds to your Venmo account. Instant Transfers –
                  1.75% per transaction ($0.25 minimum and $25 maximum)
                </div>
              </Col>{" "}
              <Col xs={24} className="venmo-instruction">
                <h4>Instructions</h4>
                <div className="remove-padding">
                  {" "}
                  1. Enter the Venmo Username that will receive the funds.
                </div>
              </Col>
              <Divider style={{ margin: "0px" }} />
            </>
          )}
          <Form layout="vertical" onSubmitCapture={handleSubmit}>
            <Row gutter={[0, 24]}>
              <Col xs={24} className="venmo-payment-info">
                <h3 className="mb-0">Payment Info</h3>
              </Col>
              <Row className="venmo-two-inputs-field">
                <Col lg={8} xs={24} className="venmo-payment-input-name">
                  VENMO USERNAME
                </Col>
                <Col lg={16} xs={24}>
                  <Input
                    type="text"
                    name="userName"
                    onChange={handleFormChange}
                    value={formData?.userName}
                    className="w-100"
                    disabled={isSuperAdmin}
                  />
                  <div role="alert" className="text-danger">
                    {errors?.userName}
                  </div>
                </Col>
                <Col lg={8} xs={24} className="venmo-payment-input-name">
                  LAST 4 DIGITS OF LINKED PHONE NUMBER
                </Col>
                <Col lg={16} xs={24}>
                  <Input
                    type="number"
                    name="phone"
                    onChange={(e) => {
                      e.target.value.length < 5 && handleFormChange(e);
                    }}
                    value={formData?.phone || formData?.phoneNumberLast4}
                    className="w-100"
                    disabled={isSuperAdmin}
                  />
                  <div role="alert" className="text-danger">
                    {errors?.phone}
                  </div>
                </Col>
                <Col lg={8} xs={24} className="venmo-payment-input-name">
                  OTHER NOTES
                </Col>
                <Col lg={16} xs={24}>
                  <TextArea
                    rows={4}
                    name="notes"
                    onChange={handleFormChange}
                    value={formData?.notes || formData?.note}
                    className="w-100"
                    style={{ resize: "none" }}
                    disabled={isSuperAdmin}
                  />
                  <div role="alert" className="text-danger">
                    {errors?.notes}
                  </div>
                </Col>
              </Row>
              <Row className="venmo-two-buttons">
                <Row className="venmo-inner-two-buttons m-0" gutter={[20, 20]}>
                  <Col xs={12} lg={6}>
                    <Button
                      style={{ width: "100%", borderRadius: "8px" }}
                      size="large"
                      onClick={() => {
                        if (isSuperAdmin) {
                          handleCancel("venmo");
                        } else {
                          setToggleModal(() => ({
                            toggle: false,
                            userName: "",
                            phone: "",
                            notes: "",
                          }));
                          setPaymentModal((prev) => ({
                            ...prev,
                            paymentModal: true,
                          }));
                          setErrors({});
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  {!isSuperAdmin && (
                    <Col xs={12} lg={8}>
                      <Button
                        style={{ width: "100%", borderRadius: "8px" }}
                        size="large"
                        htmlType="submit"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Col>
                  )}
                </Row>
              </Row>
            </Row>
          </Form>
        </Row>
      </div>
    </>
  );
};

export default VenmoModel;
