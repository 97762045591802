import { Button, Form, Input } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

import { sendForgotPasswordLink } from "../../api/affiliate";
import { setLoading } from "../../redux/affiliate/affiliate.action";

const ResetPasswordModal = ({ handleCancel }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const handleSubmit = async (data) => {
    if (data?.email) {
      const response = await sendForgotPasswordLink(data, dispatch);
      if (response.remote === "success") {
        dispatch(setLoading(false));
        handleCancel();
      } else {
        dispatch(setLoading(false));
        form.setFields([
          { name: ["email"], errors: [response?.error?.errors?.error] },
        ]);
      }
    }
  };
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      className="mt-3"
    >
      <Form.Item
        name="email"
        label="Email"
        rules={[{ type: "email", message: "Please enter valid email" }]}
      >
        <Input />
      </Form.Item>
      <div className="d-flex px-auto" style={{ justifyContent: "end" }}>
        <Button className="mx-2 btn-affilite" onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" onClick={handleSubmit}>
          Send
        </Button>
      </div>
    </Form>
  );
};

export default ResetPasswordModal;
