import "./userlist.css";
import "./PromoCodePage.css";

import {
  EditOutlined /* , ExclamationCircleOutlined */,
} from "@ant-design/icons";
import { Avatar, Button, Card, message, Space, Switch } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

import {
  deletePromoCode,
  editIsActiveStatus,
  getPromoCodeListData,
} from "../../../api/admin";
import ModalDailog from "../../../common/components/ModalDialogue";
import { createValidUrl } from "../../../utils/commonFuctions";
import {
  deActiveNote,
  plansForPromoCode,
} from "../../../utils/constants/constants";
import {
  discountTypes,
  formatSubscriptionTypes,
} from "../../affiliates/v3/helper";
import SmallLoader from "../../loader/smallLoader";
import PromoCodeModal from "./details/PromoCodeModal";
import Filter from "./fillter/Filter";
import CodeUsers from "./modal/CodeUsers";
import DeletePromoCode from "./modal/DeletePromoCode";
import EditPromoCode from "./modal/EditPromoCode";
import InternalNotes from "./modal/InternalNotes";
import IsActiveModel from "./modal/IsActiveModel";
import PromoCode from "./modal/PromoCode";
import TableData from "./Table";

export const formatDate = (date) => {
  let dd = new Date(date).getDate();
  let mm = new Date(date).getMonth() + 1;
  const yy = new Date(date).getFullYear();

  if (dd < 10) dd = `0${dd}`;
  if (mm < 10) mm = `0${mm}`;

  return date ? `${mm}/${dd}/${yy}` : "-";
};

const initialFormData = {
  codeName: "",
  promoCode: "",
  notes: "",
  plans: [],
  subscriptionType: [],
  discountType: "",
  numberOfTime: "",
  discount: "",
  quantity: "",
  isUnlimitedQuantity: false,
  startDate: moment().format("MM/DD/YYYY HH:mm"),
  expirationDate: "",
  noExpirationDate: false,
  isActive: true,
  description: "",
  promoCodeId: "",
  trialDays: "",
  trialMonths: "",
  customTrialDays: null,
  affiliate: "",
  textOfLandingPage: "",
};

/* const initialFreeFormData = {
  codeName: "",
  promoCode: "",
  notes: "",
  plans: [],
  numberOfTime: "",
  quantity: "",
  isUnlimitedQuantity: false,
  startDate: "",
  expirationDate: "",
  noExpirationDate: false,
  isActive: true,
  trialDays: "",
}; */

/* const initialFilter = {
  status: [],
  numberOfUsers: [],
  trialDays: "",
  trialStartDate: "",
  trialEndDate: "",
  signUpStartDate: "",
  signUpEndDate: "",
  promoCodeId: [],
  leadAdded: [],
  leadConverted: [],
  plan: [],
  billingMethod: [],
  issueDateStart: "",
  issueDateEnd: "",
  lastPaymentDateStart: "",
  lastPaymentDateEnd: "",
}; */

const PromoCodePage = () => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPromoCode, setPromoCode] = useState(false);
  // const [tableColumnSetting, setTableColumnSetting] = useState(false);
  /* const columnSetting = () => {
    setTableColumnSetting(true);
  }; */
  // const [isFreeTrialCode, setIsFreeTrialCode] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  /*   const [freeTrialFormData, setFreeTrialFormData] =
    useState(initialFreeFormData); */
  const [errors, setErrors] = useState({});
  const [promoCodeList, setPromoCodeList] = useState({});
  const [currentPromoPage, setCurrentPromoPage] = useState(1);
  const [promoSearch, setPromoSearch] = useState("");
  // const [trialSearch, setTrialSearch] = useState("");
  const perPagePromoData = 5;
  const [loader, setLoader] = useState(false);

  const [showDeletePromo, setShowDeletePromo] = useState(false);
  const [showEditPromo, setShowEditPromo] = useState(false);
  const [deletePromoId, setDeletePromoId] = useState("");
  const [addedNote, setAddedNote] = useState("");
  const [notesBy, setNotesBy] = useState({});
  const [showNoteModel, setShowNoteModel] = useState(false);
  const [showCodeUserModel, setShowCodeUserModel] = useState(false);
  const [showIsActiveModel, setShowIsActiveModel] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [selectedCode, setSelectedCode] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [selectedPromoCode, setSelectedPromoCode] = useState();
  const [noteRecord, setNoteRecord] = useState();
  const [openRangeSelector, setOpenRangeSelector] = useState(false);
  const handlePromoCode = () => {
    setPromoCode(true);
    setFormData(initialFormData);
  };
  /* const handleFreeCode = () => {
    setIsFreeTrialCode(true);
  }; */

  const handleOk = () => {
    // setIsModalOpen(false);
    setPromoCode(false);
    // setTableColumnSetting(false);
    // setIsFreeTrialCode(false);
    setShowDeletePromo(false);
    setShowEditPromo(false);
    setShowCodeUserModel(false);
  };
  const handleCancel = () => {
    setFormData(initialFormData);
    // setFreeTrialFormData(initialFreeFormData);
    setErrors({});
    /*  if (isActive === 3) {
      getPromoCodeList();
    } */
    // setIsModalOpen(false);
    setPromoCode(false);
    // setTableColumnSetting(false);
    // setIsFreeTrialCode(false);
    setShowDeletePromo(false);
    setShowEditPromo(false);
    setShowCodeUserModel(false);
    setShowIsActiveModel(false);
  };

  // const [isActive, setIsActive] = useState(1);
  // const [codeActiveTab, setCodeActiveTab] = useState(1);

  const getPromoCodeList = async (value) => {
    setLoader(true);
    const result = await getPromoCodeListData(
      currentPromoPage,
      perPagePromoData,
      value || "",
    );
    if (result?.remote === "success") {
      setPromoCodeList(result?.data?.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    getPromoCodeList();
  }, [currentPromoPage]);

  /*  useEffect(() => {
    setPromoSearch("");
    // setTrialSearch("");
  }, [codeActiveTab]);
 */
  const handlePromoSearchChange = (e) => {
    const { value } = e.target;
    setPromoSearch(value);
    getPromoCodeList(value);
  };

  const handleDeletePromoCode = async () => {
    setLoader(true);
    const result = await deletePromoCode(deletePromoId);
    if (result?.remote === "success") {
      message.success(
        result?.data?.message || "Promo code deleted Successfully",
      );
      getPromoCodeList();
      setLoader(false);
    } else {
      setLoader(false);
      message.error("Something went wrong please try again later");
    }
    setShowDeletePromo(false);
  };

  const handleIsActive = async (isActive, index, id, record) => {
    setSelectedCode(record);
    if (!isActive) {
      const payload = {
        status: !isActive,
      };
      setLoader(true);
      const result = await editIsActiveStatus(id, payload);
      if (result?.remote !== "success") {
        setLoader(false);
        message.error(result?.errors?.errors?.error || "Something went wrong.");
      } else {
        setPromoCodeList({
          ...promoCodeList,
          promoCodeList: promoCodeList.promoCodeList.map((item, ind) =>
            ind === index ? { ...item, isActive: !isActive } : item,
          ),
        });
        setLoader(false);
      }
    } else {
      setShowIsActiveModel(true);
    }
  };

  const handleDeactivate = async () => {
    const payload = {
      status: false,
    };
    setLoader(true);
    const result = await editIsActiveStatus(selectedCode?._id, payload);
    if (result?.remote !== "success") {
      message.error(result?.errors?.errors?.error || "Something went wrong.");
      setLoader(false);
    } else {
      setPromoCodeList({
        ...promoCodeList,
        promoCodeList: promoCodeList.promoCodeList.map((item) =>
          item?._id === selectedCode?._id ? { ...item, isActive: false } : item,
        ),
      });
      setLoader(false);

      setShowIsActiveModel(false);
    }
  };

  const PromoCodeColumn = [
    {
      title: "Name",
      dataIndex: "name",
      className: "promocode-table-name-col",
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      title: "Code",
      dataIndex: "couponCode",
      className: "text-center",
      sorter: (a, b) => a.couponCode.localeCompare(b.couponCode),
    },
    {
      title: "Link",
      dataIndex: "link",
      sorter: (a, b) => a.link.localeCompare(b.link),
      render: (v) => (
        <a href={v} onClick={(e) => e.stopPropagation()} target="_blank">
          {v}
        </a>
      ),
    },
    {
      title: "Active",
      dataIndex: "isActive",
      render: (isActive, record, index) => (
        <Switch
          checked={isActive}
          onChange={(_, e) => {
            e.stopPropagation();
            handleIsActive(isActive, index, record._id, record);
          }}
        />
      ),
      className: "text-center",
      sorter: (a, b) =>
        a.isActive.toString().localeCompare(b.isActive).toString(),
    },
    {
      title: "Discount Type",
      className: "text-center",
      dataIndex: "discountTypes",
      sorter: (a, b) => a.discountTypes.localeCompare(b.discountTypes),
      render: (v) => discountTypes[v],
    },
    {
      title: "Subscription Type",
      className: "text-center",
      dataIndex: "validForSubscriptionInterval",
      render: (v) => formatSubscriptionTypes(v),
      sorter: (a, b) =>
        a.validForSubscriptionInterval[0].localeCompare(
          b.validForSubscriptionInterval[0],
        ),
    },
    {
      title: "Discount",
      className: "text-center",
      dataIndex: "discount",
      render: (discount, record) =>
        record?.discountTypes === "percentage"
          ? `${discount}%`
          : record?.discountTypes === "amount"
          ? `$${discount.toFixed(2)}`
          : "-",
      sorter: (a, b) => a.discount - b.discount,
    },
    {
      title: "Billing Cycles",
      className: "text-center",
      dataIndex: "duration_in_months",
      render: (duration_in_months, record) =>
        `${
          record?.duration === "forever"
            ? "Entire Subscription"
            : Number(duration_in_months) === 12
            ? 1
            : Number(duration_in_months) === 0
            ? "-"
            : duration_in_months
        } ${
          Number(duration_in_months) === 12
            ? "Year"
            : Number(duration_in_months) === 0
            ? ""
            : Number(duration_in_months) > 1 &&
              Number(duration_in_months) !== 12
            ? "Months"
            : "Month"
        }`,
      sorter: (a, b) => a.duration_in_months - b.duration_in_months,
    },
    {
      title: "Trial Days",
      className: "text-center",
      dataIndex: "trialDays",
      render: (trialDays, record) =>
        `${
          trialDays > 0
            ? trialDays
            : record?.trialMonths > 0
            ? record?.trialMonths
            : "-"
        } ${trialDays > 0 ? "Days" : record?.trialMonths > 0 ? "Months" : ""}`,
      sorter: (a, b) =>
        (a?.trialMonths ? a?.trialMonths : 0) -
        (b?.trialMonths ? b?.trialMonths : 0),
    },
    {
      title: "Affiliate",
      className: "text-center",
      dataIndex: "affiliate",
      render: (affiliate) => affiliate?.name || "-",
      sorter: (a, b) =>
        (a.affiliate?.name || "").localeCompare(b.affiliate?.name || ""),
    },
    {
      title: "Total Allowance",
      className: "text-center",
      dataIndex: "quantityOfPromoCode",
      render: (quantityOfPromoCode, record) => (
        <>
          {quantityOfPromoCode === "Unlimited" ? (
            <>Unlimited</>
          ) : (
            <div
              style={{
                color:
                  Number(quantityOfPromoCode) === record?.usedQuantity
                    ? "#E42626"
                    : "#101828",
              }}
            >
              {Number(quantityOfPromoCode)}
            </div>
          )}
        </>
      ),
      sorter: (a, b) =>
        a.quantityOfPromoCode.toString().localeCompare(b.quantityOfPromoCode),
    },
    {
      title: "Total Uses",
      className: "text-center",
      dataIndex: "usedQuantity",
      render: (usedQuantity, record) => (
        <>
          <span
            style={{
              color:
                record?.quantityOfPromoCode !== "Unlimited" &&
                Number(record?.quantityOfPromoCode) === usedQuantity
                  ? "#E42626"
                  : "#101828",
            }}
          >
            {usedQuantity}
          </span>
        </>
      ),
      sorter: (a, b) => a.usedQuantity - b.usedQuantity,
    },
    {
      title: "Plan",
      className: "text-center promocode-table-plan-col",
      dataIndex: "validForPlan",
      render: (validForPlan) => (
        <>
          {plansForPromoCode[0]?.value === validForPlan[0] &&
            plansForPromoCode[0]?.label}
          {validForPlan?.length > 1 ? `, +${validForPlan?.length - 1}` : ""}
        </>
      ),
      sorter: (a, b) => a.validForPlan[0].localeCompare(b.validForPlan[0]),
    },
    {
      title: "Internal Notes",
      className: "text-center promocode-table-internal-col",
      dataIndex: "notes",
      render: (text, record) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowNoteModel(true);
            setAddedNote(text);
            setNotesBy(record?.lastEditedBy || record?.notesCreatedBy);
            setNoteRecord(record);
          }}
          className="user-list-name"
        >
          {text || "-"}
        </div>
      ),
      sorter: (a, b) => a.notes.localeCompare(b.notes),
    },
    {
      title: "Start Date",
      className: "text-center",
      dataIndex: "startDate",
      render: (startDate) => moment(startDate)?.local()?.format("MM/DD/YYYY"),
      sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
    },
    {
      title: "Expiration Date",
      className: "text-center",
      dataIndex: "expireDate",
      render: (expireDate) =>
        expireDate
          ? moment(expireDate)?.local()?.format("MM/DD/YYYY")
          : "No Expiration",
      sorter: (a, b) => new Date(a?.expireDate) - new Date(b?.expireDate),
    },
    {
      title: "Date Created",
      className: "text-center",
      dataIndex: "createdAt",
      render: (createdAt) =>
        createdAt ? moment(createdAt).local().format("MM/DD/YYYY") : "-",
      sorter: (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt),
    },
    {
      title: "Created By",
      className: "text-center",
      dataIndex: "expireDate",
      render: (createdBy, record) => (
        <>
          {record?.createdBy?.profileImage && (
            <Avatar
              src={createValidUrl(record?.createdBy?.profileImage)}
              alt="Avatar Image"
              className="me-2"
            />
          )}
          {record?.createdBy?.name || "-"}
        </>
      ),
      sorter: (a, b) => a.createdBy?.name.localeCompare(b.createdBy?.name),
    },
    {
      title: "Last Edited",
      className: "text-center",
      dataIndex: "updatedAt",
      render: (updatedAt) =>
        updatedAt ? moment(updatedAt).format("MM/DD/YYYY") : "-",
      sorter: (a, b) => new Date(a?.updatedAt) - new Date(b?.updatedAt),
    },
    {
      title: "Last Edited By",
      className: "text-center",
      dataIndex: "expireDate",
      render: (lastEditedBy, record) => (
        <>
          {record?.lastEditedBy?.profileImage && (
            <Avatar
              src={createValidUrl(record?.lastEditedBy?.profileImage)}
              alt="Avatar Image"
              className="me-2"
            />
          )}
          {record?.lastEditedBy?.name || "-"}
        </>
      ),
      sorter: (a, b) =>
        a.lastEditedBy?.name.localeCompare(b.lastEditedBy?.name),
    },
    {
      title: "Edit",
      className: "text-end",
      dataIndex: "action",
      render: (action, record) => (
        <Space size={[2, 2]}>
          <Button
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              setShowEditPromo(true);
              setDeletePromoId(record._id);
            }}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const handleEditPromoCode = async () => {
    const editPromo = promoCodeList?.promoCodeList?.filter(
      (v) => v._id === deletePromoId,
    );
    setFormData({
      codeName: editPromo[0]?.name,
      promoCode: editPromo[0]?.couponCode,
      notes: editPromo[0]?.notes,
      plans: editPromo[0]?.validForPlan,
      subscriptionType: editPromo[0]?.validForSubscriptionInterval,
      discountType: editPromo[0]?.discountTypes,
      numberOfTime:
        editPromo[0]?.duration === "forever"
          ? "entireSubscription"
          : editPromo[0]?.duration_in_months?.toString(),
      discount: editPromo[0]?.discount,
      quantity:
        editPromo[0]?.quantityOfPromoCode !== "Unlimited"
          ? editPromo[0]?.quantityOfPromoCode
          : "",
      isUnlimitedQuantity: editPromo[0]?.quantityOfPromoCode === "Unlimited",
      startDate: editPromo[0]?.startDate,
      expirationDate: editPromo[0]?.expireDate || "",
      noExpirationDate: editPromo[0]?.expireDate === null,
      isActive: editPromo[0]?.isActive,
      description: editPromo[0]?.description,
      promoCodeId: editPromo[0]?._id,
      affiliate: editPromo[0]?.affiliate,
      textOfLandingPage: editPromo[0]?.textOfLandingPage,
      trialDays:
        editPromo[0]?.trialDays === 14
          ? editPromo[0]?.trialDays?.toString()
          : editPromo[0]?.trialMonths === 1 ||
            editPromo[0]?.trialMonths === 2 ||
            editPromo[0]?.trialMonths === 3
          ? editPromo[0]?.trialMonths?.toString()
          : editPromo[0]?.trialDays?.toString(),
      customTrialDays:
        editPromo[0]?.trialMonths > 0 && editPromo[0]?.trialMonths?.toString(),
    });
    setAddedNote(editPromo[0]?.notes);
  };

  const handleRowClick = (row) => ({
    onClick: (e) => {
      e.stopPropagation();
      setShowDetails(true);
      setSelectedPromoCode(row);
    },
  });

  const handlePromoCancel = () => {
    setShowDetails(false);
  };

  const handleRangeToggle = (toggle) => {
    setOpenRangeSelector(toggle || !openRangeSelector);
  };

  useEffect(() => {
    if (showEditPromo) {
      handleEditPromoCode();
    }
  }, [showEditPromo]);

  return (
    <div className="mt-3">
      {loader && (
        <div className="center-loader">
          <SmallLoader />
        </div>
      )}
      <Card bordered={false} className="userCard">
        <div className="fillter-section mt-20 site-space-compact-wrapper">
          <Filter
            title="Add Promo Code"
            type="primary"
            onClick={handlePromoCode}
            promoSearch={promoSearch}
            setPromoSearch={setPromoSearch}
            handlePromoSearchChange={handlePromoSearchChange}
          />
        </div>
      </Card>
      <Card
        bordered={false}
        className="mt-20 userCard"
        style={{
          boxShadow:
            "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 2px 3px 0px rgba(16, 24, 40, 0.10)",
        }}
        bodyStyle={{ padding: "0px" }}
      >
        <div className="tablecard">
          <TableData
            columns={PromoCodeColumn}
            dataSource={promoCodeList?.promoCodeList}
            currentPage={currentPromoPage}
            setCurrentPage={setCurrentPromoPage}
            perPage={perPagePromoData}
            paginationData={promoCodeList?.pagination}
            onRow={handleRowClick}
            scroll={{ x: 1300 }}
          />
        </div>
      </Card>

      {/* Promocode modal */}
      <ModalDailog
        isModalOpen={isPromoCode}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={false}
        width={1000}
      >
        <PromoCode
          handleCancel={handleCancel}
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          getPromoCodeList={getPromoCodeList}
        />
      </ModalDailog>

      <ModalDailog
        isModalOpen={showDeletePromo}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={false}
        closable={false}
      >
        <DeletePromoCode
          handleCancel={() => setShowDeletePromo(false)}
          handleConfirmDelete={handleDeletePromoCode}
        />
      </ModalDailog>

      <ModalDailog
        isModalOpen={showEditPromo}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={false}
        closable={false}
        width={1190}
      >
        <EditPromoCode
          handleCancel={() => {
            setFormData(initialFormData);
            setShowEditPromo(false);
          }}
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          addedNote={addedNote}
          getPromoCodeList={getPromoCodeList}
          hasInitialized={hasInitialized}
          setHasInitialized={setHasInitialized}
        />
      </ModalDailog>
      <ModalDailog
        isModalOpen={showNoteModel}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={false}
        closable={false}
        width={400}
        className="promocode-internal-modal"
      >
        <InternalNotes
          handleCancel={() => setShowNoteModel(false)}
          addedNote={addedNote}
          notesBy={notesBy}
          record={noteRecord}
          getPromoCodeList={getPromoCodeList}
        />
      </ModalDailog>
      <ModalDailog
        isModalOpen={showCodeUserModel}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={false}
        closable
      >
        <CodeUsers
          handleCancel={() => setShowCodeUserModel(false)}
          selectedCode={selectedCode}
        />
      </ModalDailog>
      <ModalDailog
        isModalOpen={showIsActiveModel}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={false}
        closable={false}
        width={400}
        className="promocode-warning-modal"
      >
        <IsActiveModel
          handleCancel={() => setShowIsActiveModel(false)}
          selectedCode={selectedCode}
          handleDeactivate={handleDeactivate}
          description={deActiveNote}
        />
      </ModalDailog>
      <ModalDailog
        className="promemodal-fix userlist-modal"
        isModalOpen={showDetails}
        handleOk={handlePromoCancel}
        handleCancel={handlePromoCancel}
        width={1300}
        afterClose={() => setOpenRangeSelector(false)}
        destroyOnClose
      >
        <PromoCodeModal
          selectedPromoCode={selectedPromoCode}
          handleRangeToggle={handleRangeToggle}
          openRangeSelector={openRangeSelector}
          setOpenRangeSelector={setOpenRangeSelector}
        />
      </ModalDailog>
    </div>
  );
};
export default PromoCodePage;
