import urlcat from "urlcat";

import api from "../api";

export const getUserEstimationList = async (userId, filter) => {
  let url = `/v3/user-estimation/get-user-estimation/${userId}`;
  if (filter) {
    url += `?filter=${JSON.stringify(filter)}`;
  }
  const response = await api.request({
    url,
    method: "GET",
  });
  return response;
};

export const getUserEstimationDetailsById = async (userId) => {
  const response = await api.request({
    url: `/v3/user-estimation/get-user-estimation-details-by-id/${userId}`,
    method: "GET",
  });
  return response;
};

export const updateUserEstimation = async (estimationId, data) => {
  const response = await api.request({
    url: `/v3/user-estimation/update-user-estimation/${estimationId}`,
    method: "PUT",
    data,
  });
  return response;
};

export const createUserEstimation = async (data) => {
  const response = await api.request({
    url: "/v3/user-estimation/create-user-estimation",
    method: "POST",
    data,
  });
  return response;
};
export const deleteUserEstimations = async (data) => {
  const response = await api.request({
    url: `/v3/user-estimation/delete-user-estimations?estimationIds=${JSON.stringify(
      data,
    )}`,
    method: "DELETE",
  });
  return response;
};

export const uploadEstimationContractFiles = async (data) => {
  const response = await api.request({
    url: "/v3/user-estimation/upload-pdf-contract-signed",
    method: "POST",
    data,
  });
  return response;
};

export const updateTermAndConditionOfContract = async (estimationId, data) => {
  const response = await api.request({
    url: `v3/user-estimation/update-estimation-terms/${estimationId}`,
    method: "PUT",
    data,
  });
  return response;
};

export const updateEstimateLineItemImageAPI = async (estimationId, data) => {
  const response = await api.request({
    url: urlcat("v3/user-estimate/:estimationId/line-item/image", {
      estimationId,
    }),
    method: "PUT",
    data,
  });
  return response;
};
